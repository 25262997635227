import React, { Component } from 'react'
import { Loader } from '../../../../layOuts/Loader';
import { ErrorPopup } from '../../../../layOuts/errorPopup';
import ErrorHandlePopup from '../../../ErrorHandlePopup';
import isEqual from 'lodash/isEqual';
import { connect } from "react-redux";
import debounce from 'lodash/debounce';
import * as Action from "../../../../redux/actionTypes";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import $, { event } from "jquery";
import * as Common from '../../../../utils/common';

class DeviceConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplateId: this.props?.id || "",
            template_type: this.props?.templateData?.template_type || "",
            template_name: this.props?.templateData?.template_name || "",
            template_desc: this.props?.templateData?.template_desc || "",
            device_model: this.props?.templateData?.device_model || "",
            collection_version: this.props?.templateData?.collection_version || "",
            fw_version: this.props?.templateData?.fw_version || "",
            chimay: this.props?.chimay,
            cbr: this.props?.cbr,
            mifi: this.props?.mifi,
            isAxiosError: false,
            showLoader: false,
            showRefreshModal: false,
            title: "",
            message: "",
            error: false,
            delete: false,
            device_id: this.props?.device_id,
            isWebUIEnable: true,
            view: this.props?.view || false,
            apply: this.props?.apply || false,
            reapply: this.props?.reapply || false,
            edit: this.props?.edit || false,
            info: this.props?.info || false,
            selectedModelList: this.props?.selectedModelList || "",
            selectedModel: this.props?.selectedModel || "",
            currentModel: "",
            showError: false,
            template_schema_json: {
                "webUIAccessDetail": {
                    "ConfigValid": "1",
                    "GPS": "1,0",
                    "LAN": "1,1",
                    "VPN": "1,1",
                    "Firewall": "1,0",
                    "IPSECVPN": "1,1",
                    "DataUsage": "1,1",
                    "MacFilter": "1,1",
                    "ManualDNS": "1,1",
                    "WifiGuest": "1,0",
                    "ReservedIP": "1,1",
                    "RemoteAdmin": "1,1",
                    "WifiPrimary": "1,0",
                    "CustomerCare": "1,1",
                    "WifiAdvanced": "1,0",
                    "BackupRestore": "1,1",
                    "PortFiltering": "1,0",
                    "InseegoConnect": "1,1",
                    "PortForwarding": "1,0",
                    "SoftwareUpdate": "1,1",
                    "ConnectedDevices": "1,1",
                    "DevicePreferences": "1,1"
                },
                "GPSConfig": {
                    "ConfigValid": "1",
                    "enable": "1"
                },
                "WebUIPasswordChange": {
                    "ConfigValid": "0",
                    "Password": ""
                }
            },
        };
    };

    componentDidMount() {
        if (this.props?.template_schema_json !== undefined &&
            this.props?.template_schema_json !== null &&
            this.props?.template_schema_json !== "") {
            this.setState({
                template_schema_json: this.props.template_schema_json,
                isWebUIEnable: this.state.view
                    ? false
                    : String(this.props.template_schema_json?.webUIAccessDetail?.ConfigValid) === "1"
                        ? true
                        : false
            });
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps?.applying) {
            if (Object.keys(nextProps.templateData)?.length !== 0) {
                this.setState({
                    selectedTemplateId: nextProps.id || "",
                    template_type: nextProps?.templateData?.template_type || "",
                    template_name: nextProps?.templateData?.template_name || "",
                    template_desc: nextProps?.templateData?.template_desc || "",
                    device_model: nextProps?.templateData?.device_model || "",
                    collection_version: nextProps?.templateData?.collection_version || "",
                    fw_version: nextProps?.templateData?.fw_version || "",
                    device_id: nextProps?.device_id || ""
                });
            }
            if (!isEqual(this.state?.view, nextProps?.view) ||
                !isEqual(this.state?.apply, nextProps?.apply) ||
                !isEqual(this.state?.reapply, nextProps?.reapply) ||
                !isEqual(this.state?.edit, nextProps?.edit) ||
                !isEqual(this.state?.info, nextProps?.info)
            ) {
                let currentModel = Common.getModels(nextProps?.selectedModelList);
                this.setState({
                    view: nextProps?.view || false,
                    apply: nextProps?.apply || false,
                    reapply: nextProps?.reapply || false,
                    edit: nextProps?.edit || false,
                    info: nextProps?.info || false,
                    selectedModelList: nextProps.selectedModelList || "-",
                    currentModel
                });
            }
            if (!isEqual(this.state?.chimay, nextProps?.chimay) ||
                !isEqual(this.state?.cbr, nextProps?.cbr) ||
                !isEqual(this.state?.mifi, nextProps?.mifi)) {
                this.setState({
                    chimay: nextProps.chimay,
                    cbr: nextProps.cbr,
                    mifi: nextProps.mifi
                });
            }
            if (nextProps?.template_schema_json !== undefined)
                if (!isEqual(this.state?.template_schema_json, nextProps?.template_schema_json)) {
                    this.setState({
                        template_schema_json: nextProps.template_schema_json,
                        isWebUIEnable: nextProps.view
                            ? false
                            : String(nextProps?.template_schema_json?.webUIAccessDetail?.ConfigValid) === "1"
                                ? true
                                : false
                    });
                }
            if (nextProps?.selectedModel !== undefined && !isEqual(this.state.selectedModel, nextProps?.selectedModel)) {
                this.setState({
                    selectedModel: nextProps?.selectedModel || ""
                })
            }
        }
    };

    setWebUIAccess = (event) => {
        const { checked } = event.target;
        const { template_schema_json } = this.state;
        const updatedWebUIAccessDetail = {
            ...template_schema_json?.webUIAccessDetail,
            ConfigValid: checked ? "1" : "0"
        };
        const updatedTemplateSchemaJson = {
            ...template_schema_json,
            webUIAccessDetail: updatedWebUIAccessDetail
        };
        this.setState({
            template_schema_json: updatedTemplateSchemaJson,
            isWebUIEnable: checked
        });
    };

    setWebUIAccessValues = (event, index) => {
        const { checked, name } = event.target;
        const { template_schema_json } = this.state;
        const currentValues = template_schema_json?.webUIAccessDetail?.[name]?.split(',') ?? [];
        const updatedValues = currentValues.map((val, idx) => idx === index ? (checked ? '1' : '0') : val);
        const updatedValuesString = updatedValues.join(',');
        const updatedWebUIAccessDetail = {
            ...template_schema_json?.webUIAccessDetail,
            [name]: updatedValuesString
        };
        const updatedTemplateSchemaJson = {
            ...template_schema_json,
            webUIAccessDetail: updatedWebUIAccessDetail
        };
        this.setState({ template_schema_json: updatedTemplateSchemaJson });
    };

    GPSConfig = (event) => {
        const { checked, name } = event.target;
        const template_schema_json = {
            ...this.state.template_schema_json,
            GPSConfig: {
                ...this.state.template_schema_json?.GPSConfig,
                [name]: checked ? "1" : "0"
            }
        };
        this.setState({ template_schema_json });
    };

    passwordChange = (event, field) => {
        let showError = false;
        const element = document.getElementById("Password");
        if (element) {
            element.style.display = "none";
        }
        if (field === "switch") {
            const { checked } = event.target;
            const { template_schema_json } = this.state;
            const { WebUIPasswordChange = {} } = template_schema_json;
            const updatedWebUIPasswordChange = {
                ...WebUIPasswordChange,
                ConfigValid: checked ? "1" : "0",
                Password: ""
            };
            const updatedTemplateSchemaJson = {
                ...template_schema_json,
                WebUIPasswordChange: updatedWebUIPasswordChange
            };
            this.setState({ template_schema_json: updatedTemplateSchemaJson, showError });

        } else if (field === "password") {
            const { value } = event.target;
            const updatedWebUIPasswordChange = {
                ...this.state.template_schema_json?.WebUIPasswordChange,
                Password: value
            };
            const updatedTemplateSchemaJson = {
                ...this.state.template_schema_json,
                WebUIPasswordChange: updatedWebUIPasswordChange
            };
            this.setState({ template_schema_json: updatedTemplateSchemaJson, showError });
        }
    };

    validatePassword = (event) => {
        const { value } = event.target;
        const element = document.getElementById("Password");
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/;
        const result = regex.test(value);
        let { showError } = this.state;
        if (element) {
            if (!result) {
                element.style.display = "block";
                showError = true;
            } else {
                element.style.display = "none";
                showError = false;
            }
        }
        this.setState({ showError });
    }

    checkError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        if (this.props.applying) {
            result = true;
        }
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.display === "block") {
                result = true;
            }
        }
        if (this.state.showError || this.state.template_name === "" || this.state.template_type === "") {
            result = true;
        }
        if (String(this.state.template_schema_json?.WebUIPasswordChange?.ConfigValid) === "1" &&
            this.state.template_schema_json?.WebUIPasswordChange?.Password === "") {
            result = true;
        }
        return result;
    };

    closeAll = () => {
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.props.closeAddTemplate();
    };

    saveData = () => {
        let data = {};
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model = this.state?.selectedModelList === "" ? this.state.device_model.toString() : this.state?.selectedModelList?.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_order = 17;
        data.template_schema_json = this.state.template_schema_json;
        if (this.state.selectedTemplateId === "") {
            data.template_version = {
                chimay: this.state.chimay,
                cbr: this.state.cbr,
                mifi: this.state.mifi
            }
        }
        this.setState({
            showLoader: true,
        });
        if (this.state.selectedTemplateId !== "") {
            ApiCall(
                urlMapping.updateTemplate(this.state.selectedTemplateId, data),
                (response) => {
                    this.setState({
                        showLoader: false,
                    });
                    if (response?.Success) {
                        this.props.refreshList();
                        this.props.openApplyTemplate(this.state.selectedTemplateId);
                        this.props.closeAddTemplate();
                    } else {
                        if (parseInt(response?.response?.status) === 401) {
                            this.setState({ showRefreshModal: true });
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"));
                        }
                        else {
                            this.setState(
                                {
                                    title: "Update Template",
                                    message: response?.message,
                                    show: true,
                                    error: true,
                                }, () => $("#errorMessage").modal("show"));
                        }
                    }
                }
            );
        } else {
            ApiCall(urlMapping.createTemplate(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.props.refreshList();
                    this.props.openApplyTemplate(response?.data?.id);
                    this.props.closeAddTemplate();
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"));
                    }
                    else {
                        this.setState(
                            {
                                title: "Create Template",
                                message: response?.message,
                                show: true,
                                error: true,
                            }, () => $("#errorMessage").modal("show"));
                    }
                }
            });
        }
    };

    applyTemplate = () => {
        this.props.dispatch({
            type: Action.SET_APPLY_TEMPLATE,
        });
        this.props.applyTemplate(this.state.template_schema_json);
    };

    debounceApply = debounce((fn, event) => {
        fn(event);
    }, 500);

    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                {!this.state.info && <div className="page-sub-title border-bottom mb-2">
                    <div>
                        <strong>Device Configuration</strong>
                    </div>
                </div>}
                <div className="tab-content mt-3">
                    {
                        (this.state.currentModel !== "vCPE" &&
                            this.state.selectedModel !== "vCPE" &&
                            this.state.currentModel !== "CHIMAY" &&
                            this.state.selectedModel !== "CHIMAY")
                        && <div>
                            <div className="row">
                                <div className="col-md-10">
                                    <strong>Web UI Access control</strong>
                                </div>
                                <div className="col-md-2 mt-2">
                                    <label className="switch" >
                                        <input
                                            type="checkbox"
                                            name="ConfigValid"
                                            checked={String(this.state.template_schema_json.webUIAccessDetail.ConfigValid) === "1"}
                                            onChange={(event) => this.state.view
                                                ? undefined
                                                : this.setWebUIAccess(event)}
                                        />
                                        <span className={this.state.view ? "slider round disabled" : "slider round"}></span>
                                    </label>
                                </div>
                            </div>
                            <div className="row mt-1 ml-2" style={{ alignItems: 'center', display: 'flex', fontStyle: 'italic' }}>
                                <span>  <strong>Note:&nbsp;</strong>  Not all web pages apply to all devices. Please refer to the user manual.<br />
                                    <strong>Read only: </strong> Select both the 'Editable' and 'Read Only' checkboxes for the  respective page. <br />
                                    <strong>Hidden:  </strong>Un-select both the 'Editable' and 'Read Only' checkboxes for the respective page. <br />
                                    <strong>Writable:  </strong> Select the 'Editable' check box and un-select the 'Read Only' check box for the page
                                </span>
                            </div>
                            <div className={this.state.isWebUIEnable ? 'border rounded p-2 mt-2' : 'border rounded p-2 mt-2 disabled'}>
                                <div className="row">
                                    <div className="col-md-4"><strong>Page</strong></div>
                                    <div className="col-md-4"><strong>Editable</strong></div>
                                    <div className="col-md-4"><strong>Read Only</strong></div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Backup Restore</div>
                                    <div className="col-md-4">
                                        <input
                                            name='BackupRestore'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail.BackupRestore?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='BackupRestore'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.BackupRestore?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Connected Devices</div>
                                    <div className="col-md-4">
                                        <input
                                            name='ConnectedDevices'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.ConnectedDevices?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='ConnectedDevices'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.ConnectedDevices?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Customer Care</div>
                                    <div className="col-md-4">
                                        <input
                                            name='CustomerCare'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.CustomerCare?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='CustomerCare'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.CustomerCare?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Data Usage</div>
                                    <div className="col-md-4">
                                        <input
                                            name='DataUsage'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.DataUsage?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='DataUsage'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.DataUsage?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Device Preferences</div>
                                    <div className="col-md-4">
                                        <input
                                            name='DevicePreferences'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.DevicePreferences?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='DevicePreferences'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.DevicePreferences?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Firewall</div>
                                    <div className="col-md-4">
                                        <input
                                            name='Firewall'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.Firewall?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='Firewall'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.Firewall?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">GPS</div>
                                    <div className="col-md-4">
                                        <input
                                            name='GPS'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.GPS?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='GPS'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.GPS?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">IPSEC VPN</div>
                                    <div className="col-md-4">
                                        <input
                                            name='IPSECVPN'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.IPSECVPN?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='IPSECVPN'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.IPSECVPN?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Inseego Connect</div>
                                    <div className="col-md-4">
                                        <input
                                            name='InseegoConnect'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.InseegoConnect?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='InseegoConnect'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.InseegoConnect?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">LAN</div>
                                    <div className="col-md-4">
                                        <input
                                            name='LAN'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.LAN?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='LAN'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.LAN?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Mac Filter</div>
                                    <div className="col-md-4">
                                        <input
                                            name='MacFilter'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.MacFilter?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='MacFilter'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.MacFilter?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Manual DNS</div>
                                    <div className="col-md-4">
                                        <input
                                            name='ManualDNS'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.ManualDNS?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='ManualDNS'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.ManualDNS?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Port Filtering</div>
                                    <div className="col-md-4">
                                        <input
                                            name='PortFiltering'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.PortFiltering?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='PortFiltering'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.PortFiltering?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Port Forwarding</div>
                                    <div className="col-md-4">
                                        <input
                                            name='PortForwarding'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.PortForwarding?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='PortForwarding'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.PortForwarding?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Remote Admin</div>
                                    <div className="col-md-4">
                                        <input
                                            name='RemoteAdmin'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.RemoteAdmin?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='RemoteAdmin'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.RemoteAdmin?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Reserved IP</div>
                                    <div className="col-md-4">
                                        <input
                                            name='ReservedIP'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.ReservedIP?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='ReservedIP'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.ReservedIP?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Software Update</div>
                                    <div className="col-md-4">
                                        <input
                                            name='SoftwareUpdate'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.SoftwareUpdate?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='SoftwareUpdate'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.SoftwareUpdate?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">VPN</div>
                                    <div className="col-md-4">
                                        <input
                                            name='VPN'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.VPN?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='VPN'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.VPN?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Wifi Advanced</div>
                                    <div className="col-md-4">
                                        <input
                                            name='WifiAdvanced'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.WifiAdvanced?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='WifiAdvanced'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.WifiAdvanced?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Wifi Guest</div>
                                    <div className="col-md-4">
                                        <input
                                            name='WifiGuest'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.WifiGuest?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='WifiGuest'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.WifiGuest?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">Wifi Primary</div>
                                    <div className="col-md-4">
                                        <input
                                            name='WifiPrimary'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.WifiPrimary?.split(",")?.[0]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 0) : undefined}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name='WifiPrimary'
                                            type='checkbox'
                                            style={{ cursor: !this.state.isWebUIEnable ? "not-allowed" : "pointer" }}
                                            checked={parseInt(this.state.template_schema_json?.webUIAccessDetail?.WifiPrimary?.split(",")?.[1]) === 1}
                                            onChange={(event) => this.state.isWebUIEnable ? this.setWebUIAccessValues(event, 1) : undefined}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        (this.state.currentModel !== "vCPE" &&
                            this.state.selectedModel !== "vCPE") &&
                        <div>
                            <div className="row mt-3">
                                <div className="col-md-10">
                                    <strong>GPS Configuration</strong>
                                </div>
                                <div className="col-md-2 mt-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="ConfigValid"
                                            checked={String(this.state.template_schema_json?.GPSConfig.ConfigValid) === "1"}
                                            onChange={(event) => this.state.view ? undefined : this.GPSConfig(event)}
                                        />
                                        <span className={this.state.view ? "slider round disabled" : "slider round"}></span>
                                    </label>
                                </div>
                            </div>
                            <div className={
                                (String(this.state.template_schema_json?.GPSConfig.ConfigValid) === "1" && !this.state.view)
                                    ? 'border rounded p-2'
                                    : 'border rounded p-2 disabled'
                            }>
                                <div className="row">
                                    <div className="col-md-4">Enable</div>
                                    <div className="col-md-4 mt-1">
                                        <input
                                            name='enable'
                                            type='checkbox'
                                            checked={String(this.state.template_schema_json?.GPSConfig?.enable) === "1"}
                                            onChange={
                                                (event) => (String(this.state.template_schema_json?.GPSConfig.ConfigValid) === "1" && !this.state.view)
                                                    ? this.GPSConfig(event)
                                                    : undefined
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row mt-3">
                        <div className="col-md-10">
                            <strong>Web UI Password Change</strong>
                        </div>
                        <div className="col-md-2 mt-2">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name="ConfigValid"
                                    checked={String(this.state.template_schema_json?.WebUIPasswordChange.ConfigValid) === "1"}
                                    onChange={(event) => this.state.view
                                        ? undefined
                                        : this.passwordChange(event, "switch")}
                                />
                                <span className={this.state.view ? "slider round disabled" : "slider round"}></span>
                            </label>
                        </div>
                    </div>
                    {String(this.state.template_schema_json?.WebUIPasswordChange.ConfigValid) === "1" &&
                        <div className="row">
                            <div className="col-md-4 mt-2">
                                New Password
                                {!this.state.view && <span className="red-txt">*</span>}
                            </div>
                            <div className="col-md-8 mt-2">
                                <input
                                    type="text"
                                    name="Password"
                                    className='form-control'
                                    value={String(this.state.template_schema_json.WebUIPasswordChange.Password)}
                                    onChange={(event) => this.state.view
                                        ? undefined
                                        : this.passwordChange(event, "password")}
                                    onBlur={(event) => this.validatePassword(event)}
                                />
                                <div className="error-txt" id="Password">The password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.</div>
                            </div>
                        </div>}
                </div>
                {(!this.state.info && !this.state.view) && <div className="s-footer">
                    <button
                        className="btn btn-light"
                        onClick={
                            this.state.apply && !this.state.edit
                                ? () => {
                                    this.props.closeAddTemplate()
                                }
                                : () => this.closeAll()
                        }
                    >
                        {this.state.apply && !this.state.edit && !this.state.reapply ? "Back" : "Cancel"}
                    </button>
                    <button
                        className="btn btn-primary"
                        disabled={this.checkError()}
                        onClick={
                            this.state.apply
                                ? (event) => this.debounceApply(this.applyTemplate, event)
                                : () => this.saveData()
                        }
                    >
                        {this.state.apply
                            ? "Apply"
                            : this.state.selectedTemplateId !== ""
                                ? "Update"
                                : "Save"}
                    </button>
                </div>}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying
    }
};

export default connect(mapStateToProps)(DeviceConfiguration);