/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Action from "../../../../redux/actionTypes";
import isEqual from 'lodash/isEqual';

class CaptivePortalView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template_schema_json: {
                "data": {
                    "captive_portal": []
                }
            },
            networkViewData: []
        };
    }
    componentDidMount() {
        if (this.props.template_schema_json !== undefined) {
            this.setState({
                template_schema_json: this.props.template_schema_json
            });
        }
        if (this.props.networkViewData) {
            if (this.props.apply) {
                let { networkViewData } = this.props;
                networkViewData = networkViewData?.filter(item => ((item.network_zone === 'lan') && (item["802_1x_enabled"] !== "1")));
                this.setState({
                    networkViewData
                });
            }
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.apply) {
            if (!isEqual(this.props.template_schema_json, nextProps.template_schema_json) ||
                !isEqual(this.props.networkViewData, nextProps.networkViewData)) {
                if (nextProps.template_schema_json &&
                    nextProps.networkViewData) {
                    let { networkViewData } = nextProps;
                    let template_schema_json = JSON.parse(JSON.stringify(nextProps.template_schema_json));
                    networkViewData = nextProps.networkViewData.filter(item => ((item.network_zone === 'lan') && (item["802_1x_enabled"] !== "1")))
                    this.setState({
                        networkViewData,
                        template_schema_json
                    });
                }
            }
        }
        if (nextProps.info) {
            if (nextProps.template_schema_json) {
                if (!isEqual(this.props.template_schema_json, nextProps.template_schema_json)) {
                    let template_schema_json = JSON.parse(JSON.stringify(nextProps.template_schema_json));
                    this.setState({ template_schema_json })
                }
            }
        }
    }
    handleChange = (e, row) => {
        let { template_schema_json } = this.state;
        template_schema_json.data.captive_portal?.map((item) => {
            item.interface = [];
            item.networks = [];
            if (item.profile_name === row.profile_name) {
                const selectedNetwork = this.state.networkViewData?.filter((item) => item.networkName === e.target.id)?.[0];
                let value = selectedNetwork.bridgeStatus === "Enabled" ? selectedNetwork.Bridge_name : selectedNetwork.interfaceName;
                if (e.target.checked) {
                    item.interface?.push(value);
                    item.networks?.push(e.target.id);
                } else {
                    item.interface = item.interface?.filter(i => i !== value);
                    item.networks = item.networks?.filter(network => network !== e.target.id);
                }
            }
            return item;
        });
        this.setState({
            template_schema_json
        })
    }
    applyTemplate = () => {
        let { template_schema_json } = this.state;
        this.props.dispatch({
            type: Action.SET_APPLY_TEMPLATE,
        });
        this.props.applyTemplate(template_schema_json);
    }
    getNetworkName = (name) => {
        const network = this.state.networkViewData?.filter(item => item.networkName === name)?.[0];
        let networkName = '';
        if (network)
            networkName = network.name;
        return networkName;
    }
    checkError = () => {
        let result = false;
        if (this.props.applying)
            result = true;
        if (this.props.apply) {
            this.state.template_schema_json.data.captive_portal?.filter(item => item.isdeleted !== '1')?.length > 0 &&
                this.state.template_schema_json.data.captive_portal?.filter(item => item.isdeleted !== '1')?.map((item) => {
                    if (item.interface.length === 0) {
                        result = true;
                    }
                }
                )
        }
        return result;
    }
    render() {
        return (
            <div>
                <div className="bgp-view">
                    <div className="row">
                        <div className="form-group col-md-6">
                        </div>
                    </div>
                    <div className="gray-bg rounded p-3">
                        <div className="page-sub-title border-0">
                            <div>
                                <strong>Profile</strong>
                                <div
                                    className="tooltip-c"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Captive Portal Profile name"
                                >
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        {!this.props.info && <th>Network{!this.props.view && <span className="red-txt">*</span>}</th>}
                                        <th>Advance Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.template_schema_json.data.captive_portal?.filter(item => item?.isdeleted !== '1')?.map(
                                        (item) => (
                                            <tr>
                                                <>
                                                    <td>{item?.profile_name}</td>
                                                    {/* !this.props.info ? <td>
                                                        {item.interface[0]}
                                                        </td> :  */}
                                                    {!this.props.info &&
                                                        <td width={125}>
                                                            {!this.props.view ?
                                                                <div className="dropdown">
                                                                    <ul className="chip-list z-index-9 radius">
                                                                        {item?.networks?.map((network) => {
                                                                            return (<li>
                                                                                {this.getNetworkName(network)}
                                                                                {<img
                                                                                    src="/images/svg-icons/cancel.svg"
                                                                                    alt=""
                                                                                    id={network}
                                                                                    onClick={(e) => this.handleChange(e, item)} />}
                                                                            </li>)
                                                                        })}
                                                                    </ul>
                                                                    <div
                                                                        className="chip-box add-arrow"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false">
                                                                    </div>
                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                        <ul>{
                                                                            this.state.networkViewData?.map((network) => {
                                                                                return (<li>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={network?.networkName}
                                                                                        checked={item?.networks?.filter(i => i === network?.networkName)?.length > 0}
                                                                                        onClick={(e) => this.handleChange(e, item)}
                                                                                    />
                                                                                    {network.name}
                                                                                </li>)
                                                                            })
                                                                        }
                                                                        </ul>
                                                                    </div>
                                                                </div> :
                                                                <div className="gray-bg rounded">
                                                                    <ul className="chip-list w-100">
                                                                        {item.networks?.map((network) => {
                                                                            return (<li>{this.getNetworkName(network)}</li>)
                                                                        })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </td>}
                                                    <td>
                                                        <ul className="h-list width40">
                                                            <li className="head-list">Global Configuration:</li>
                                                            <li>
                                                                <span>Splash URL:</span>
                                                                <span className='splash_url' title={item?.splash_url}>{item?.splash_url}</span>
                                                            </li>
                                                            <li>
                                                                <span>Keyword:</span>
                                                                <span className='splash_url' title={item?.keyword}>{item?.keyword || "-"}</span>
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    <strong>
                                                                        RADIUS Configuration:
                                                                    </strong>
                                                                </span>
                                                                <label className="switch gray">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item?.pRadius_enable === "1"}
                                                                    />
                                                                    <label className="slider round cp-switch" style={{ cursor: "not-allowed" }}></label>
                                                                </label>
                                                            </li>
                                                            {item?.pRadius_enable === "1" && <>
                                                                {/* <li>
                                                                     <strong>Primary Server:</strong>
                                                                     </li> */}
                                                                <li><span>Authentication IP:</span>
                                                                    <span>{item?.pAuth_server_ip}</span>
                                                                </li>
                                                                <li><span>Authentication Port:</span>
                                                                    <span>{item?.pAuth_server_port}</span>
                                                                </li>
                                                                <li><span>Authentication Secret:</span>
                                                                    <span>{item?.pAuth_server_pass}</span>
                                                                </li>
                                                                {/* <li>
                                                                    <span><strong>Secondary Server:</strong></span>
                                                                    <label className="switch gray">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={item.sRadius_enable === "1"}
                                                                        />
                                                                        <label className="slider round cp-switch" style={{ cursor: "not-allowed" }}></label>
                                                                    </label>
                                                                </li>
                                                                {item.sRadius_enable === '1' && <>
                                                                    <li><span>Authentication IP:</span>
                                                                        <span>{item.sAuth_server_ip}</span>
                                                                    </li>
                                                                    <li><span>Authentication Port:</span>
                                                                        <span>{item.sAuth_server_port}</span>
                                                                    </li>
                                                                    <li><span>Authentication Secret:</span>
                                                                        <span>{item.sAuth_server_pass}</span></li>
                                                                </>} */}
                                                            </>}
                                                            <li>
                                                                <span><strong>Walled Garden:</strong></span>
                                                                <label className="switch gray">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item?.walled_garden_enable === "1"}
                                                                    />
                                                                    <label className="slider round cp-switch" style={{ cursor: "not-allowed" }}></label>
                                                                </label>
                                                            </li>
                                                            {item?.walled_garden_enable === "1" &&
                                                                <ul className="chip-list d-flex">
                                                                    {item?.walled_garden?.map((network) => {
                                                                        return (<li className="p-2">{network}</li>)
                                                                    })
                                                                    }
                                                                </ul>
                                                            }
                                                            <li className="head-list">
                                                                <span>Enabled:</span>
                                                                <label className="switch gray">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item?.isEnabled === "1"}
                                                                    />
                                                                    <label className="slider round cp-switch" style={{ cursor: "not-allowed" }}></label>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {!this.props.view && (
                        <div className="s-footer">
                            <button
                                className="btn btn-light"
                                onClick={
                                    this.props.apply &&
                                        !this.props.edit &&
                                        !this.props.reapply
                                        ? () => this.props.closeAddTemplate()
                                        : () => this.props.closeApplyTemplate()
                                }
                            >
                                {this.props.apply && !this.props.reapply ? "Back" : "Cancel"}
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={
                                    this.props.apply
                                        ? () => this.applyTemplate()
                                        : ""
                                }
                                disabled={this.checkError()}
                            >
                                {this.props.apply
                                    ? "Apply" : "Save"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying,
    };
};
export default connect(mapStateToProps)(CaptivePortalView);
