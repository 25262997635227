import React, { Component } from "react";
import { connect } from "react-redux";
import * as Common from "../../../../utils/common";
import * as Action from "../../../../redux/actionTypes";
import isEqual from 'lodash/isEqual';

class WAView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplateId: this.props.id ? this.props.id : "",
      template_schema_json: {
        'triggers': [
          {
            "trigger_type": "Ethernet interface discovery",
            "Client Connected": {
              "status": "1",
              "severity": "INFO"
            },
            "Client Disconnected": {
              "status": "1",
              "severity": "INFO"
            }
          },
          {
            "trigger_type": "Mounted filesystem discovery",
            "Disk space is critically low": {
              "status": "1",
              "macro": "{$VFS.FS.PUSED.MAX.CRIT}",
              "threshold": "90",
              "severity": "HIGH"
            },
            "70% of disk space is utilized": {
              "status": "1",
              "macro": "{$VFS.FS.PUSED.MAX.WARN}",
              "threshold": "70",
              "severity": "WARNING"
            },
            "50% of disk space is utilized": {
              "status": "1",
              "macro": "{$VFS.FS.PUSED.MAX.INFO}",
              "threshold": "50",
              "severity": "INFO"
            },
          },
          {
            "trigger_type": "Network Interface Discovery",
            "High error rate": {
              "status": "1",
              "severity": "WARNING"
            },
            "Operation status": {
              "Link down": {
                "status": "1",
                "severity": "WARNING"
              },
              "Link UP": {
                "status": "1",
                "severity": "INFO"
              }
            }
          },
          {
            "trigger_type": "Memory utilization",
            "High memory utilization": {
              "macro": "{$MEMORY.UTIL.MAX}",
              "status": "1",
              "threshold": "90",
              "severity": "HIGH"
            },
            "memory_above50": {
              "status": "1",
              "macro": "{$MEMORY.UTIL.AVG}",
              "threshold": "70",
              "severity": "WARNING"
            },
            "memory_below50": {
              "status": "1",
              "macro": "{$MEMORY.UTIL.INFO}",
              "threshold": "50",
              "severity": "INFO"
            },
          },
          {
            "trigger_type": "System local time",
            "System time is out of sync": {
              "status": "1",
              "severity": "WARNING"
            }
          },
          // {
          //   "trigger_type": "CPU utilization",
          //   "high_cpu_utilization": {
          //     "macro": "{$CPU.UTIL.CRIT}",
          //     "status": "1",
          //     "threshold": "90",
          //     "severity": "HIGH"
          //   },
          //   "cpu_above50": {
          //     "macro": "{$CPU.UTIL.WARN}",
          //     "status": "1",
          //     "threshold": "70",
          //     "severity": "WARNING"
          //   },
          //   "cpu_below50": {
          //     "macro": "{$CPU.UTIL.INFO}",
          //     "status": "1",
          //     "threshold": "50",
          //     "severity": "INFO"
          //   },
          // },
          {
            "trigger_type": "Data usage",
            "Cellular: Data Usage": {
              "data_usage": {
                "status": "1",
                "threshold": "100M",
                "severity": "INFO"
              },
              "average_data_usage": {
                "status": "1",
                "threshold": "500M",
                "severity": "WARNING"
              },
              "high_data_usage": {
                "status": "1",
                "threshold": "1000M",
                "severity": "HIGH"
              }
            },
            "Control tunnel: Data Usage": {
              "data_usage": {
                "status": "1",
                "threshold": "100M",
                "severity": "INFO"
              },
              "average_data_usage": {
                "status": "1",
                "threshold": "500M",
                "severity": "WARNING"
              },
              "high_data_usage": {
                "status": "1",
                "threshold": "1000M",
                "severity": "HIGH"
              }
            },
            "Data tunnel: Data Usage": {
              "data_usage": {
                "status": "1",
                "threshold": "100M",
                "severity": "INFO"
              },
              "average_data_usage": {
                "status": "1",
                "threshold": "500M",
                "severity": "WARNING"
              },
              "high_data_usage": {
                "status": "1",
                "threshold": "1000M",
                "severity": "HIGH"
              }
            },
            "WAN:  Data Usage": {
              "data_usage": {
                "status": "1",
                "threshold": "100M",
                "severity": "INFO"
              },
              "average_data_usage": {
                "status": "1",
                "threshold": "500M",
                "severity": "WARNING"
              },
              "high_data_usage": {
                "status": "1",
                "threshold": "1000M",
                "severity": "HIGH"
              }
            },
            "WiFi : Data Usage": {
              "data_usage": {
                "status": "1",
                "threshold": "100M",
                "severity": "INFO"
              },
              "average_data_usage": {
                "status": "1",
                "threshold": "500M",
                "severity": "WARNING"
              },
              "high_data_usage": {
                "status": "1",
                "threshold": "1000M",
                "severity": "HIGH"
              }
            },
            "WiFi_2.4GHz: Data Usage": {
              "data_usage": {
                "status": "1",
                "threshold": "100M",
                "severity": "INFO"
              },
              "average_data_usage": {
                "status": "1",
                "threshold": "500M",
                "severity": "WARNING"
              },
              "high_data_usage": {
                "status": "1",
                "threshold": "1000M",
                "severity": "HIGH"
              }
            },
            "WiFi_5GHz : Data Usage": {
              "data_usage": {
                "status": "1",
                "threshold": "100M",
                "severity": "INFO"
              },
              "average_data_usage": {
                "status": "1",
                "threshold": "500M",
                "severity": "WARNING"
              },
              "high_data_usage": {
                "status": "1",
                "threshold": "1000M",
                "severity": "HIGH"
              }
            },
          },
          {
            "trigger_type": "device_tunnel_discovery",
            "Link down": {
              "status": "1",
              "severity": "WARNING"
            },
            "Link UP": {
              "status": "1",
              "severity": "INFO"
            }
          },
          {
            "trigger_type": "Wifi interface discovery",
            "Wifi Client Connected": {
              "status": "1",
              "severity": "INFO"
            },
            "Wifi Client Disconnected": {
              "status": "1",
              "severity": "INFO"
            }
          },
          {
            "trigger_type": "VPN status",
            "device_offline": {
              "status": "1",
              "severity": "WARNING"
            },
            "Device Online": {
              "status": "1",
              "severity": "INFO"
            },
          },
        ]
      }
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      let template_schema_json = JSON.parse(JSON.stringify(this.props.template_schema_json));
      let vCPE_template_schema_json;
      let mifi_template_schema_json;
      if (this.props.selectedModel === 'vCPE') {
        vCPE_template_schema_json = template_schema_json.triggers.filter(item => item.trigger_type !== "Wifi interface discovery")
        vCPE_template_schema_json = vCPE_template_schema_json.map(item => {
          if (item.trigger_type === "Data usage") {
            delete item["Cellular: Data Usage"];
            delete item["WiFi : Data Usage"];
            delete item["WiFi_2.4GHz: Data Usage"];
            delete item["WiFi_5GHz : Data Usage"];
            return item;
          }
          else {
            return item;
          }
        })
        template_schema_json.triggers = JSON.parse(JSON.stringify(vCPE_template_schema_json))
      }
      else if (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
        || this.props.selectedModel === 'Stormbreaker' || this.props.selectedModel === 'Firestorm') {
        mifi_template_schema_json = template_schema_json.triggers.filter(item => item.trigger_type !== "Ethernet interface discovery")
        mifi_template_schema_json = mifi_template_schema_json.filter(item => item.trigger_type !== "System local time")
        template_schema_json.triggers = JSON.parse(JSON.stringify(mifi_template_schema_json))
      }
      this.setState({
        template_schema_json,
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id) {
      this.setState({
        selectedTemplateId: nextProps.id ? nextProps.id : "",
      });
    }
    if (!isEqual(this.props.template_schema_json, nextProps.template_schema_json)) {
      this.setState({
        template_schema_json: nextProps.template_schema_json
      })
    }
  }
  applyTemplate = () => {
    const { template_schema_json } = this.state;
    this.props.applyTemplate(template_schema_json);
  }
  render() {
    return (
      <div className="gray-bg rounded p-3 mt-2">
        <div className="page-sub-title p-0 mb-3 mt-0 border-0"><strong>Trigger</strong></div>
        <table className="table">
          <thead>
            <tr>
              <th>Trigger Type</th>
              <th>Module</th>
              <th>Status</th>
              <th>Severity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Data Tunnel Link</td>
              <td>
                <ul className="trigger-list">
                  <li>Link Down</li>
                  <li>Link Up</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'device_tunnel_discovery')?.[0]?.["Link down"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'device_tunnel_discovery')?.[0]?.["Link UP"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'device_tunnel_discovery')?.[0]?.["Link down"]?.severity)}</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'device_tunnel_discovery')?.[0]?.["Link UP"]?.severity)}</li>
                </ul>
              </td>
            </tr>
            {/* Trigger Configuration View & Edit issue fix */}
            {(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Ethernet interface discovery')?.length > 0) &&
              (this.props.selectedModel === 'vCPE' || this.props.selectedModel === 'CHIMAY') &&
              <>
                <tr>
                  <td>Client Connection Status</td>
                  <td>
                    <ul className="trigger-list">
                      <li>Ethernet Client Connect</li>
                      <li>Ethernet Client Disconnect</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Ethernet interface discovery')?.[0]?.["Client Connected"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Ethernet interface discovery')?.[0]?.["Client Disconnected"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Ethernet interface discovery')?.[0]?.["Client Connected"]?.severity)}</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Ethernet interface discovery')?.[0]?.["Client Disconnected"]?.severity)}</li>
                    </ul>
                  </td>
                </tr>
              </>}
            <tr>
              <td>Disk Space</td>
              <td>
                <ul className="trigger-list">
                  <li>Low Disk Space</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["Disk space is critically low"]?.threshold}%</strong></li>
                  <li>Disk Space Above 50%</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["70% of disk space is utilized"]?.threshold}%</strong></li>

                  <li>50% Disk Space</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["50% of disk space is utilized"]?.threshold}%</strong></li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["Disk space is critically low"]?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                </ul>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["70% of disk space is utilized"]?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                </ul>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["50% of disk space is utilized"]?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["Disk space is critically low"]?.severity)}</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["70% of disk space is utilized"]?.severity)}</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Mounted filesystem discovery')?.[0]?.["50% of disk space is utilized"]?.severity)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Interface Status</td>
              <td>
                <ul className="trigger-list">
                  <li>Link Down</li>
                  <li>Link Up</li>
                  <li>High Error Rate</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Network Interface Discovery')?.[0]?.['Operation status']?.["Link down"]?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Network Interface Discovery')?.[0]?.['Operation status']?.["Link UP"]?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Network Interface Discovery')?.[0]?.["High error rate"]?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Network Interface Discovery')?.[0]?.['Operation status']?.["Link down"]?.severity)}</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Network Interface Discovery')?.[0]?.['Operation status']?.["Link UP"]?.severity)}</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Network Interface Discovery')?.[0]?.["High error rate"]?.severity)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Memory</td>
              <td>
                <ul className="trigger-list">
                  <li>High Memory Utilisation</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Memory utilization')?.[0]?.["High memory utilization"]?.threshold}%</strong></li>
                  <li>Avg Memory Utilisation</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Memory utilization')?.[0]?.["memory_above50"]?.threshold}%</strong></li>
                  <li>Lack of Available Memory</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Memory utilization')?.[0]?.["memory_below50"]?.threshold}%</strong></li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Memory utilization')?.[0]?.['High memory utilization']?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Memory utilization')?.[0]?.['memory_above50']?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Memory utilization')?.[0]?.['memory_below50']?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Memory utilization')?.[0]?.['High memory utilization']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Memory utilization')?.[0]?.['memory_above50']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Memory utilization')?.[0]?.['memory_below50']?.severity)}</li>
                </ul>
              </td>
            </tr>
            {/* Trigger Configuration View & Edit issue fix */}
            {(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'System local time').length > 0) &&
              (this.props.selectedModel === 'vCPE' || this.props.selectedModel === 'CHIMAY') &&
              <>
                <tr>
                  {/* LONG-2331: In trigger configuration "System" not as per wire frame */}
                  <td>System Local Time</td>
                  <td>
                    <ul className="trigger-list">
                      <li>Host Time Sync</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'System local time')?.[0]?.['System time is out of sync']?.status === '1' ?
                        'Enabled' : 'Disabled'}</li>
                    </ul>
                  </td>
                  <td>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'System local time')?.[0]?.['System time is out of sync']?.severity)}</li>

                    </ul>
                  </td>
                </tr>
              </>}
            {/* <tr>
              <td>CPU</td>
              <td>
                <ul className="trigger-list">
                  <li>High CPU Utilisation</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.["high_cpu_utilization"]?.threshold}%</strong></li>
                  <li>Avg CPU Utilisation</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.["cpu_above50"]?.threshold}%</strong></li>
                  <li>Lack of Available CPU</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.["cpu_below50"]?.threshold}%</strong></li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['high_cpu_utilization']?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['cpu_above50']?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['cpu_below50']?.status === '1' ?
                    'Enabled' : 'Disabled'}</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['high_cpu_utilization']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['cpu_above50']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'CPU utilization')?.[0]?.['cpu_below50']?.severity)}</li>
                </ul>
              </td>
            </tr> */}
            {/* Trigger Configuration View & Edit issue fix */}
            {
              (this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Wifi interface discovery').length > 0) &&
              (this.props.selectedModel === 'CHIMAY' || this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' ||
                this.props.selectedModel === 'Stormbreaker' || this.props.selectedModel === 'Firestorm') &&
              <tr>
                <td>WiFi Connection Status</td>
                <td>
                  <ul className="trigger-list">
                    <li>WiFi Client Connect</li>
                    <li>WiFi Client Disconnect</li>
                  </ul>
                </td>
                <td>
                  <ul className="trigger-list">
                    <li>{this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Wifi interface discovery')?.[0]?.["Wifi Client Connected"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                    <li>{this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Wifi interface discovery')?.[0]?.["Wifi Client Disconnected"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  </ul>
                </td>
                <td>
                  <ul className="trigger-list">
                    <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Wifi interface discovery')?.[0]?.["Wifi Client Connected"]?.severity)}</li>
                    <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Wifi interface discovery')?.[0]?.["Wifi Client Disconnected"]?.severity)}</li>
                  </ul>
                </td>
              </tr>}
            <tr>
              {/* LONG-2331: In trigger configuration "System" not as per wire frame */}
              <td>System Status</td>
              <td>
                <ul className="trigger-list">
                  <li>Device Offline</li>
                  <li>Device Online</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'VPN status')?.[0]?.["device_offline"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'VPN status')?.[0]?.["Device Online"]?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                </ul>
              </td>
              <td>
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'VPN status')?.[0]?.["device_offline"]?.severity)}</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'VPN status')?.[0]?.["Device Online"]?.severity)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Data Usage</td>
              <td>
                {(Object.keys(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Data usage')?.[0])?.length > 3) && this.props.selectedModel !== 'vCPE' &&
                  <ul className="trigger-list">
                    <li>Cellular Data Usage</li>
                    <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['data_usage']?.threshold}B</strong></li>
                    <li>Cellular Avg Data Usage</li>
                    <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['average_data_usage']?.threshold}B</strong></li>
                    <li>Cellular High Data Usage</li>
                    <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['high_data_usage']?.threshold}B</strong></li>
                  </ul>}
                <ul className="trigger-list">
                  <li>Mgmt. Plane Data Usage</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['data_usage']?.threshold}B</strong></li>
                  <li>Mgmt. Plane Avg Data Usage</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['average_data_usage']?.threshold}B</strong></li>
                  <li>Mgmt. Plane High Data Usage</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['high_data_usage']?.threshold}B</strong></li>
                </ul>
                <ul className="trigger-list">
                  <li>Data tunnel Data Usage</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['data_usage']?.threshold}B</strong></li>
                  <li>Data tunnel Avg Data Usage</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['average_data_usage']?.threshold}B</strong></li>
                  <li>Data tunnel High Data Usage</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['high_data_usage']?.threshold}B</strong></li>
                </ul>
                <ul className="trigger-list">
                  <li>WAN Data Usage</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['data_usage']?.threshold}B</strong></li>
                  <li>WAN Avg Data Usage</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['average_data_usage']?.threshold}B</strong></li>
                  <li>WAN High Data Usage</li>
                  <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['high_data_usage']?.threshold}B</strong></li>
                </ul>
                {this.props.selectedModel !== 'vCPE' &&
                  (Object.keys(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Data usage')?.[0])?.length > 3) &&
                  <>
                    <ul className="trigger-list">
                      <li>WiFi Data Usage</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['data_usage']?.threshold}B</strong></li>
                      <li>WiFi Avg Data Usage</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['average_data_usage']?.threshold}B</strong></li>
                      <li>WiFi High Data Usage</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['high_data_usage']?.threshold}B</strong></li>
                    </ul>
                    <ul className="trigger-list">
                      <li>WiFi 2.4GHz Data Usage</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['data_usage']?.threshold}B</strong></li>
                      <li>WiFi 2.4GHz Avg Data Usage</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['average_data_usage']?.threshold}B</strong></li>
                      <li>WiFi 2.4GHz High Data Usage</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['high_data_usage']?.threshold}B</strong></li>
                    </ul>
                    <ul className="trigger-list">
                      <li>WiFi 5GHz Data Usage</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['data_usage']?.threshold}B</strong></li>
                      <li>Wifi 5GHz Avg Data Usage</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['average_data_usage']?.threshold}B</strong></li>
                      <li>WiFi 5GHz High Data Usage</li>
                      <li><strong>Threshold:{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['high_data_usage']?.threshold}B</strong></li>
                    </ul>
                  </>
                }
              </td>
              <td>
                {this.props.selectedModel !== 'vCPE' && (this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Mounted filesystem discovery').length > 0) &&
                  <ul className="trigger-list">
                    <li>{this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                    <li>&nbsp;</li>
                    <li>{this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                    <li>&nbsp;</li>
                    <li>{this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                    <li>&nbsp;</li>
                  </ul>}
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                </ul>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                </ul>
                <ul className="trigger-list">
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                  <li>{this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                  <li>&nbsp;</li>
                </ul>
                {/* Apply issue for CBR bootstrap template */}
                {this.props.selectedModel !== 'vCPE' && (this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Network Interface Discovery').length > 0) &&
                  <>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]["WiFi_5GHz : Data Usage"]?.['data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['average_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                      <li>{this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['high_data_usage']?.status === '1' ? 'Enabled' : 'Disabled'}</li>
                      <li>&nbsp;</li>
                    </ul>
                  </>
                }
              </td>
              <td>
                {/* Apply issue for CBR bootstrap template */}
                {this.props.selectedModel !== 'vCPE' && (this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Memory utilization').length > 0) &&
                  <ul className="trigger-list">
                    <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['data_usage'].severity)}</li>
                    <li>&nbsp;</li>
                    <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['average_data_usage']?.severity)}</li>
                    <li>&nbsp;</li>
                    <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                      item.trigger_type === 'Data usage')?.[0]?.["Cellular: Data Usage"]?.['high_data_usage']?.severity)}</li>
                    <li>&nbsp;</li>
                  </ul>}
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['data_usage']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['average_data_usage']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Control tunnel: Data Usage"]?.['high_data_usage']?.severity)}</li>
                  <li>&nbsp;</li>
                </ul>
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['data_usage']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['average_data_usage']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["Data tunnel: Data Usage"]?.['high_data_usage']?.severity)}</li>
                  <li>&nbsp;</li>
                </ul>
                <ul className="trigger-list">
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['data_usage']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['average_data_usage']?.severity)}</li>
                  <li>&nbsp;</li>
                  <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                    item.trigger_type === 'Data usage')?.[0]?.["WAN:  Data Usage"]?.['high_data_usage']?.severity)}</li>
                  <li>&nbsp;</li>
                </ul>
                {/* Apply issue for CBR bootstrap template */}
                {this.props.selectedModel !== 'vCPE' && (Object.keys(this.state.template_schema_json.triggers.filter(item => item.trigger_type === 'Data usage')[0]).length > 3) &&
                  <>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['average_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi : Data Usage"]?.['high_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['average_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_2.4GHz: Data Usage"]?.['high_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                    </ul>
                    <ul className="trigger-list">
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['average_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                      <li>{Common.getSeverity(this.state.template_schema_json.triggers.filter(item =>
                        item.trigger_type === 'Data usage')?.[0]?.["WiFi_5GHz : Data Usage"]?.['high_data_usage']?.severity)}</li>
                      <li>&nbsp;</li>
                    </ul>
                  </>
                }
              </td>
            </tr>
            {!this.props?.info && !this.props?.view &&
              (
                <div className="s-footer">
                  <button
                    className="btn btn-light"
                    onClick={(this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
                      () => this.props.closeApplyTemplate()}
                  >
                    {this.props.apply && !this.props.reapply ? "Back" : "Cancel"}
                  </button>
                  <button
                    className="btn btn-primary"
                    disabled={this.props.applying}
                    onClick={
                      this.props.apply
                        ? () => {
                          this.props.dispatch({
                            type: Action.SET_APPLY_TEMPLATE,
                          });
                          this.applyTemplate()
                        }
                        : ""
                    }
                  >
                    {this.props.apply
                      ? "Apply"
                      : this.state.selectedTemplateId !== ""
                        ? "Update"
                        : "Save"}
                  </button>
                </div>
              )}
          </tbody>
        </table>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(WAView);
