/* eslint-disable array-callback-return */
import React, { Component } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import { ErrorPopup } from '../../../../layOuts/errorPopup';
import { Loader } from '../../../../layOuts/Loader';
import * as Common from '../../../../utils/common';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import { Netmask } from 'netmask';
import * as Action from "../../../../redux/actionTypes";
import ErrorHandlePopup from "../../../ErrorHandlePopup";
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
class NetworkConfigCR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showError: false,
            show: false,
            hide: true,
            showLoader: false,
            multipleSelect: '',
            toggledClearRows: false,
            openReservation: false,
            openAddNetwork: false,
            update: false,
            applied: false,
            selectedNetwork: [],
            customPopup: false,
            selectedInterface: '',
            selectedNetname: '',
            ipChanged: false,
            customNetwork: {},
            "auth_server": "",
            "auth_port": "",
            "auth_secret": "",
            customInterfaces: {
                interfaceName: '',
                vlanID: '',
                usedCustomInterfaces: []
            },
            showCusIntError: false,
            selectedTemplateId: this.props?.id ? this.props?.id : "",
            template_type: this.props?.templateData?.template_type || "",
            template_name: this.props?.templateData?.template_name || "",
            template_desc: this.props?.templateData?.template_desc || "",
            device_model: this.props?.templateData?.device_model || "",
            collection_version: this.props?.templateData?.collection_version || "",
            fw_version: this.props?.templateData?.fw_version || "",
            chimay: this.props.chimay,
            cbr: this.props.cbr,
            mifi: this.props.mifi,
            keyUpdate: '',
            device_mac: this?.props?.device_mac || '',
            isAxiosError: false,
            template_schema_json: {
                lanConfig: [
                    {
                        id: Math.random().toString(36).substring(2, 11).toUpperCase(),
                        name: 'Default',
                        interfaceName: '',
                        vlanID: '',
                        networkName: "lan",
                        protocol: "staticAddr",
                        bridgeStatus: "1",
                        ipAddress: '192.168.1.1',
                        subnet: '24',
                        netmask: "255.255.255.0",
                        overrideNetmask: "",
                        gateway: "",
                        dnsAddress: [],
                        dhcpServer: "1",
                        startAddress: "192.168.1.2",
                        endAddress: "192.168.1.100",
                        leaseTime: "1440",
                        dnsServer: "useDNSFromISP",
                        dns1: "",
                        dns2: "",
                        zone: "lan",
                        isdeleted: "0",
                        is_loopback: "0",
                        loopback_id: "",
                        is_auto_increment: "0",
                        is_network_ip_changed: "0",
                        no_of_subnets: "200",
                        "802_1x_enabled": "0",
                        "auth_server": "",
                        "auth_port": "",
                        "auth_secret": "",
                        "radius_server": "",
                        "prim_auth_server": "",
                        "prim_auth_port": "",
                        "prim_auth_secret": "",
                        "sec_auth_server": "",
                        "sec_auth_port": "",
                        "sec_auth_secret": "",
                        masquerade: '0',
                        staticRouteDestinationIP: '',
                        mtu: "1500",
                        isedit: "1"
                    }

                ],
                reservations: [],
                wanConfig: [
                    {
                        id: Math.random().toString(36).substring(2, 11).toUpperCase(),
                        name: "Ethernet WAN",
                        networkName: "wan",
                        interfaceName: "eth2",
                        protocol: "dhcpclient",
                        bridgeStatus: "0",
                        dhcpClient: "1",
                        ipAddress: "",
                        subnet: '',
                        netmask: "",
                        gateway: "",
                        overrideNetmask: "",
                        dnsAddress: [],
                        zone: "wan",
                        isdeleted: "0",
                        is_loopback: "0",
                        loopback_id: "",
                        "802_1x_enabled": "0",
                        "auth_server": "",
                        "auth_port": "",
                        "auth_secret": "",
                        "radius_server": "",
                        "prim_auth_server": "",
                        "prim_auth_port": "",
                        "prim_auth_secret": "",
                        "sec_auth_server": "",
                        "sec_auth_port": "",
                        "sec_auth_secret": "",
                        mtu: "1500",
                        isedit: "1"
                    }]
            },
            networkData: {
                id: Math.random().toString(36).substring(2, 11).toUpperCase(),
                name: '',
                vlanID: '',
                networkName: "lan",
                interfaceName: '',
                protocol: "staticAddr",
                bridgeStatus: "0",
                ipAddress: '',
                subnet: '',
                netmask: "",
                overrideNetmask: "",
                gateway: "",
                dnsAddress: [],
                dhcpServer: "0",
                startAddress: "",
                endAddress: "",
                leaseTime: "2",
                dnsServer: "useDNSasBelow",
                dns1: "",
                dns2: "",
                zone: "lan",
                isdeleted: "0",
                is_loopback: "0",
                loopback_id: "",
                "802_1x_enabled": "0",
                "auth_server": "",
                "auth_port": "",
                "auth_secret": "",
                masquerade: '0',
                staticRouteDestinationIP: '',
                mtu: "1500",
                radius_server: "",
                "prim_auth_server": "",
                "prim_auth_port": "",
                "prim_auth_secret": "",
                "sec_auth_server": "",
                "sec_auth_port": "",
                "sec_auth_secret": "",
                isedit: "1"
            },
            servers: [],
            server_data: [],
            reservationData: {
                id: Math.random().toString(36).substring(2, 11).toUpperCase(),
                hostname: '',
                network: "",
                macaddr: "",
                ipaddr: "",
                isdeleted: "0"
            },
            reservationColumns: [
                {
                    name: "Host name",
                    selector: "hostname",
                    cell: (row) => (
                        <span title={row.hostname} style={{ width: "auto" }} className={(this.props.edit) ? "cursor-pointer" : ''}
                        >{(this.props.edit) ? <Link to={{}} className="txt-blue" onClick={() => this.openAddReservation(row)} >{" "}{row.hostname}
                        </Link> : row.hostname}</span>
                    ),
                },
                {
                    name: "Network",
                    selector: "network",
                    cell: (row) =>
                    (<span title={row.network} style={{ width: 'auto' }}
                    >{row.network}
                    </span>
                    ),
                },
                {
                    name: "MAC Address",
                    selector: "macaddr",
                    cell: (row) =>
                    (<span title={row.macaddr.toUpperCase()} style={{ width: 'auto' }}
                    >{row.macaddr.toUpperCase()}
                    </span>
                    ),
                },
                {
                    name: "IP Address",
                    selector: "ipaddr",
                    cell: (row) =>
                    (<span title={row.ipaddr} style={{ width: 'auto' }}
                    > {row.ipaddr}
                    </span>
                    ),
                },
            ],
            lanInterfaces: [],
            interfaces: [],
            availableInterfaces: [],
            usedList: [],
            customList: [],
            sameIPError: false
        }
    }
    componentDidMount() {
        this.getConfigInfo();
        this.getExternalServers();
        //LONG-2930- EM - Front-end Issues 7 ,newly created interface are not showing in edit page 
        if (this.props.template_schema_json !== undefined && !this.props.edit) {
            this.setState({
                template_schema_json: this.undefinedValueCheck(this.props.template_schema_json)
            })
        }
        if (this.props.template_schema_json !== undefined) {
            let availableInterfaces = this.props.interfaces;
            let { interfaces } = this.props;
            let { usedList } = this.state;
            let { customList } = this.state;
            let { customInterfaces } = this.state;
            let template_schema_json = this.undefinedValueCheck(JSON.parse(JSON.stringify(this.props.template_schema_json)));
            template_schema_json.wanConfig = template_schema_json.wanConfig.filter(item => item.name !== 'Cellular');
            if (this.props.selectedModel !== 'Moretti_Model' && this.props.selectedModel !== 'Duvel_Model') {
                template_schema_json.wanConfig.filter(item => item.isdeleted !== '1').map((item) => {
                    if (item.interfaceName !== '') {
                        usedList.push(item.interfaceName);
                    }
                })
            }
            usedList.map((item) => {
                if (availableInterfaces.includes(item)) {
                    customList = availableInterfaces.filter(value => value !== item)
                }
            })
            if (this.props.group_id) {
                template_schema_json.lanConfig.filter(item => item.name === 'Default').map((item) => {
                    item.is_network_ip_changed = "0"
                });
            }
            // Long-1579 mifi usb changes
            template_schema_json.lanConfig.filter(item => item.isdeleted !== '1').map((item) => {
                if ((item.name !== 'Default' && item.interfaceName !== '')) {
                    const values = item.interfaceName.split(' ');
                    values.map((value) => (
                        usedList.push(value)
                    ));
                }
            });
            usedList.map((item) => {
                if (availableInterfaces.includes(item)) {
                    availableInterfaces = availableInterfaces.filter(value => value !== item)
                }
            })
            template_schema_json.lanConfig.filter(item => item.isdeleted !== '1').map((item) => {
                const interfaces = item.interfaceName.split(' ');
                interfaces.map((value) => {
                    if (value.includes('.')) {
                        customInterfaces.usedCustomInterfaces.push(value);
                    }
                })
            })
            if (this.props.selectedModel === 'vCPE') {
                // network template changes for CR
                customList = ['eth1', 'eth2', 'eth3', 'eth4', 'eth5', 'eth6', 'eth7'];
                interfaces = ['eth0', 'eth1', 'eth2', 'eth3', 'eth4', 'eth5', 'eth6', 'eth7'];
                availableInterfaces = ['eth0', 'eth1', 'eth2', 'eth3', 'eth4', 'eth5', 'eth6', 'eth7'];
                // Long-1382 interfaces should not be added in default in case of apply only
                if (!this.props.edit && !this.props.view && !this.props.reapply && (this.props.templateData.default_type === '' ||
                    this.props.templateData.default_type === null)) {
                    template_schema_json.lanConfig.map((item) => {
                        if (item.name === 'Default') {
                            item.interfaceName = 'eth1 eth2 eth3 eth4 eth5 eth6 eth7';
                            availableInterfaces = ['eth1', 'eth2', 'eth3', 'eth4', 'eth5', 'eth6', 'eth7'];
                            interfaces = ['eth0', 'eth1', 'eth2', 'eth3', 'eth4', 'eth5', 'eth6', 'eth7'];
                        }
                        else {
                            item.interfaceName = ""
                        }
                    });
                    template_schema_json.wanConfig.map((item) => {
                        if (item.name === 'Ethernet WAN') {
                            item.interfaceName = 'eth0';
                            item.networkName = 'wan'
                        }
                    })
                } else {
                    // Long-1382 in edit and reapply interfaces should remain as applied
                    template_schema_json.lanConfig.filter(item => item.isdeleted !== '1').map((item) => {
                        if (item.name !== 'Default' && item.interfaceName !== '') {
                            const values = item.interfaceName.split(' ');
                            values.map((value) => {
                                usedList.push(value);
                            })
                        }
                    });
                    usedList.map((item) => {
                        if (availableInterfaces.includes(item)) {
                            availableInterfaces = availableInterfaces.filter(value => value !== item)
                        }
                    })
                    template_schema_json.lanConfig.filter(item => item.isdeleted !== '1').map((item) => {
                        const interfaces = item.interfaceName.split(' ');
                        interfaces.map((value) => {
                            if (value.includes('.')) {
                                customInterfaces.usedCustomInterfaces.push(value);
                            }
                        })
                    })
                }
                template_schema_json.wanConfig = template_schema_json.wanConfig.filter(item => item.name !== 'Cellular');
            }
            template_schema_json.lanConfig.map((item) => {
                if (item.protocol === 'staticAddr') {
                    item.masquerade = '0';
                    item.staticRouteDestinationIP = '';
                }
            })
            this.setState({
                template_schema_json,
                availableInterfaces,
                interfaces,
                usedList,
                customList,
                customInterfaces
            })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.templateData).length !== 0) {
            this.setState({
                selectedTemplateId: nextProps.id ? nextProps.id : '',
                template_type: nextProps?.templateData?.template_type || "",
                template_name: nextProps?.templateData?.template_name || "",
                template_desc: nextProps?.templateData?.template_desc || "",
                device_model: nextProps?.templateData?.device_model || "",
                collection_version: nextProps?.templateData?.collection_version || "",
                fw_version: nextProps?.templateData?.fw_version || "",
                device_mac: nextProps?.device_mac || '',
            });
        }
        //Long- 1191 Previously Selected device template view page is showing in another device
        if (nextProps.edit) {
            if (!isEqual(this.props?.template_schema_json, nextProps?.template_schema_json)) {
                this.setState({
                    template_schema_json: this.undefinedValueCheck(nextProps.template_schema_json),
                    availableInterfaces: nextProps.interfaces,
                    interfaces: nextProps.interfaces
                }, () => this.getAllLists())
            }
        }
        if (nextProps.view) {
            this.setState({
                template_schema_json: this.undefinedValueCheck(nextProps.template_schema_json),
                keyUpdate: Math.random()
            })
        }
        if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
            !isEqual(this.props?.cbr, nextProps?.cbr) ||
            !isEqual(this.props?.mifi, nextProps?.mifi)) {
            this.setState({
                chimay: nextProps.chimay,
                cbr: nextProps.cbr,
                mifi: nextProps.mifi
            })
        }
    }
    componentWillUnmount = () => {
        this.setState({ customPopup: false })
    }
    undefinedValueCheck = (value) => {
        if (value) {
            let { template_schema_json } = this.state;
            template_schema_json.lanConfig = [];
            Array.isArray(value?.lanConfig) && value?.lanConfig?.map((item) => {
                if (item?.id !== "" && item?.name !== "") {
                    let lanConfig = {
                        id: item?.id || "",
                        name: item?.name || '',
                        interfaceName: item?.interfaceName || '',
                        vlanID: item?.vlanID || '',
                        networkName: item?.networkName || "lan",
                        protocol: item?.protocol || "",
                        bridgeStatus: item?.bridgeStatus || "",
                        ipAddress: item?.ipAddress || '',
                        subnet: item?.subnet || '',
                        netmask: item?.netmask || "",
                        overrideNetmask: item?.overrideNetmask || "",
                        gateway: item?.gateway || "",
                        dnsAddress: item?.dnsAddress || [],
                        dhcpServer: item?.dhcpServer || "",
                        startAddress: item?.startAddress || "",
                        endAddress: item?.endAddress || "",
                        leaseTime: item?.leaseTime || "1440",
                        dnsServer: item?.dnsServer || "",
                        dns1: item?.dns1 || "",
                        dns2: item?.dns2 || "",
                        zone: item?.zone || "lan",
                        isdeleted: item?.isdeleted || "0",
                        is_loopback: item?.is_loopback || "0",
                        loopback_id: item?.loopback_id || "",
                        is_auto_increment: item?.is_auto_increment || "0",
                        is_network_ip_changed: item?.is_network_ip_changed || "0",
                        no_of_subnets: item?.no_of_subnets || "200",
                        "802_1x_enabled": item?.["802_1x_enabled"] || "0",
                        "auth_server": item?.["auth_server"] || "",
                        "auth_port": item?.auth_port || "",
                        "auth_secret": item?.auth_secret || "",
                        "radius_server": item?.radius_server || "",
                        "prim_auth_server": item?.prim_auth_server || "",
                        "prim_auth_port": item?.prim_auth_port || "",
                        "prim_auth_secret": item?.prim_auth_secret || "",
                        "sec_auth_server": item?.sec_auth_server || "",
                        "sec_auth_port": item?.sec_auth_port || "",
                        "sec_auth_secret": item?.sec_auth_secret || "",
                        masquerade: item?.masquerade || '0',
                        "macacl": item?.macacl || [],
                        hostname: item?.hostname || "",
                        staticRouteDestinationIP: item?.staticRouteDestinationIP || [],
                        mtu: item?.mtu || "1500",
                        isedit: item?.isedit || "1"
                    }
                    template_schema_json.lanConfig.push(lanConfig);
                }
            })
            template_schema_json.wanConfig = [];
            Array.isArray(value?.wanConfig) && value?.wanConfig?.map((item) => {
                if (item?.id !== "" && item?.name !== "") {
                    let wanConfig = {
                        id: item?.id || "",
                        name: item?.name || "",
                        networkName: item?.networkName || "wan",
                        interfaceName: item?.interfaceName || "",
                        dhcpClient: item?.dhcpClient || "",
                        protocol: item?.protocol || "",
                        bridgeStatus: item?.bridgeStatus || "",
                        ipAddress: item?.ipAddress || "",
                        subnet: item?.subnet || '',
                        netmask: item?.netmask || "",
                        overrideNetmask: item?.overrideNetmask || "",
                        gateway: item?.gateway || "",
                        dnsAddress: item?.dnsAddress || [],
                        zone: item?.zone || "wan",
                        "is_loopback": item?.is_loopback || "0",
                        "loopback_id": item?.loopback_id || '',
                        isdeleted: item?.isdeleted || "",
                        "802_1x_enabled": item?.["802_1x_enabled"] || "0",
                        "auth_server": item?.auth_server || "",
                        "auth_port": item?.auth_port || "",
                        "auth_secret": item?.auth_secret || "",
                        "radius_server": item?.radius_server || "",
                        "prim_auth_server": item?.prim_auth_server || "",
                        "prim_auth_port": item?.prim_auth_port || "",
                        "prim_auth_secret": item?.prim_auth_secret || "",
                        "sec_auth_server": item?.sec_auth_server || "",
                        "sec_auth_port": item?.sec_auth_port || "",
                        "sec_auth_secret": item?.sec_auth_secret || "",
                        mtu: item?.mtu || "1500",
                        isedit: item?.isedit || "1"
                    }
                    template_schema_json.wanConfig.push(wanConfig);
                }
            })
            template_schema_json.reservations = [];
            Array.isArray(value?.reservations) && value?.reservations?.map((item) => {
                if (item?.hostname !== "" && item?.hostname !== "") {
                    let reservations = {
                        hostname: item?.hostname || "",
                        id: item?.id || "",
                        ipaddr: item?.ipaddr || "",
                        isdeleted: item?.isdeleted || "",
                        macaddr: item?.macaddr || "",
                        network: item?.network || ""
                    }
                    template_schema_json.reservations.push(reservations);
                }
            })
            template_schema_json.defaultConfig = value.defaultConfig ? value.defaultConfig : [];
            return template_schema_json
        }
    }
    getConfigInfo = () => {
        this.setState({
            showLoader: true
        })
        ApiCall(urlMapping.getConfigInfo(), (response) => {
            this.setState({
                showLoader: false,
            })
            if (response?.success) {
                this.setState({
                    is_auth_server_configured: response?.data?.is_auth_server_configured
                })
                this.getAuthServerConfiguration();
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    getExternalServers = () => {
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.getServer(), (response) => {
            if (response?.success) {
                if (response?.data) {
                    this.setState({
                        showLoader: false,
                        server_data: response?.data,
                    }, () => this.getServersData());
                } else {
                    this.setState({
                        showLoader: false,
                        server_data: []
                    }, () => this.getServersData());
                }
            } else {
                this.setState({
                    showLoader: false,
                    server_data: []
                }, () => this.getServersData());
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                    })
                }
            }
        })
    }
    getServersData = () => {
        let servers = [];
        // EMGT-1036 radius server set as blank if its deleted from external servers
        let template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
        template_schema_json.lanConfig.map((item) => {
            if (this.state.server_data?.filter(server => server.profile_name === item.radius_server).length < 1) {
                item.radius_server = '';
                item.prim_auth_server = "";
                item.prim_auth_port = "";
                item.prim_auth_secret = "";
                item.sec_auth_server = "";
                item.sec_auth_port = "";
                item.sec_auth_secret = "";
            }
        });
        this.state.server_data.map((server) => {
            servers.push(server.profile_name)
        })
        this.setState({
            servers,
            template_schema_json
        })
    }
    getAuthServerConfiguration = () => {
        this.setState({
            showLoader: true,
        })
        ApiCall(urlMapping.authServerConfiguration(), (response) => {
            this.setState({
                showLoader: false,
            });

            if (response?.success) {
                if (response?.data) {
                    this.setState({
                        auth_server: response?.data?.local?.server_ip,
                        auth_port: response?.data?.[response?.data?.settings?.enable]?.port,
                        auth_secret: response?.data?.settings?.secret_passphrase
                    }, () => this.getServersData())
                }
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    }
    checkReservationError = () => {
        let result = false;
        const elements = document.getElementsByClassName('error-txt static');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.reservationData.hostname === '' ||
            this.state.reservationData.network === '' ||
            this.state.reservationData.ipaddr === '' ||
            this.state.reservationData.macaddr === '') {
            result = true;
        }
        return result;
    }
    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    }
    deleteReservation = () => {
        let data = this.state.template_schema_json;
        if (this.props.edit) {
            this.state.multipleSelect.map((item) => {
                let found = false;
                this.props.template_schema_json.reservations.map((reservation) => {
                    if (item.id === reservation.id) {
                        this.state.template_schema_json.reservations.filter(value => value.id === item.id)[0].isdeleted = "1";
                        found = true;
                    }
                    return reservation;
                })

                if (found === false) {
                    data.reservations = this.state.template_schema_json.reservations.filter(finalValue => finalValue.id !== item.id);
                }
            })
        } else {
            this.state.multipleSelect.map((item) => (
                data.reservations = this.state.template_schema_json.reservations.filter(finalValue => finalValue.id !== item.id)
            ));
        }
        this.setState({
            template_schema_json: data,
            toggledClearRows: !this.state.toggledClearRows,
            keyUpdateRes: Math.random()
        })
    }
    checkIPAddress = (e) => {
        let found = false;
        const reservations = this.props.edit ?
            this.state.template_schema_json.reservations.filter(item => item.isdeleted !== "1") :
            this.state.template_schema_json.reservations;
        reservations.map((item) => {
            if (item.ipaddr === e.target.value && item.id !== this.state.reservationData.id) {
                found = true;
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = "IP address cannot be same for two reservations!";
                }
            }
        })
        return found;
    }
    checkMacAddress = (e) => {
        let found = false;
        const reservations = this.props.edit ?
            this.state.template_schema_json.reservations.filter(item => item.isdeleted !== "1") :
            this.state.template_schema_json.reservations;
        reservations.map((item) => {
            if (item.macaddr === e.target.value)
                found = true
        })
        return found;
    }
    validateHostname = (e) => {
        if (!Common.validateAlphaNumeric(e))
            this.setState({
                showError: true
            })
    }
    handleDNSAddress = item => {
        let data = this.state.networkData;
        data.dnsAddress = data.dnsAddress.filter(i => i !== item)
        this.setState({
            networkData: data,
        });
    };
    handleDNSKeyDown = evt => {
        Common.removeError(evt);
        let valid = false;
        if (this.state.networkData.dnsAddress.length < 2) {
            if (evt.target.value && (["Enter", "Tab", " ", ","].includes(evt.key) || evt.type === "blur")) {
                evt.preventDefault();
                const items = evt.target.name;
                let ip;
                if (evt.target.value === '0.0.0.0' ||
                    evt.target.value === '255.255.255.255' || !Common.validateIPaddress(evt)) {
                    const element = document.getElementById(evt.target.name);
                    if (element) {
                        element.style.display = 'block';
                        element.innerHTML = 'Invalid DNS address';
                        this.setState({
                            showError: true,
                        })
                    }
                } else {
                    let data = this.state.networkData;
                    if (evt.target.value && !data.dnsAddress.includes(evt.target.value)) {
                        data.dnsAddress = [...data.dnsAddress, evt.target.value];
                        this.setState({
                            networkData: data,
                        });
                        $("textarea[name=dnsAddress]").val("");
                    }
                }
            }
        }
    };
    validateDNS = (e) => {
        Common.removeError(e);
        if (e.target.value !== "" && !(this.state.networkData.dnsAddress.length < 2)) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'Maximum of 2 DNS addresses allowed';
                this.setState({
                    showError: true,
                })
            }
        }
        if (e.target.value === "" && this.state.networkData.dnsAddress.length === 0) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'DNS Address cannot be blank';
                this.setState({
                    showError: true,
                })
            }
        }
    }
    validateIP = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
        }
        if (e.target.name === 'dns1' || e.target.name === 'dns2') {
            if (!Common.validateIPaddress(e)) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({
                    showError: true
                })
            }
            else {
                const element = document.getElementById(e.target.name);
                element.style.display = 'none';
                this.setState({
                    showError: false
                })
            }
        }
        if (e.target.name === 'ipAddress') {
            const data = this.state.networkData;
            data.startAddress = '';
            data.endAddress = '';
            let validLan = true;
            // this.state.template_schema_json.lanConfig.filter(item => item.ipAddress === e.target.value && item.isdeleted !== "1" && item.id !== this.state.networkData?.id).length
            let validWan = true;
            // this.state.template_schema_json.wanConfig.filter(item => item.ipAddress === e.target.value && item.isdeleted !== "1" && item.id !== this.state.networkData?.id).length
            if (this.state.networkData.ipAddress !== '' && this.state.networkData.subnet !== '') {
                element.style.display = "none";
                this.state.template_schema_json.lanConfig.filter(item => item.isdeleted !== "1" && item.id !== this.state.networkData?.id).map((network) => {
                    if (this.state.networkData.ipAddress === network.ipAddress && this.state.networkData.subnet === network.subnet)
                        validLan = false;
                })
                this.state.template_schema_json.wanConfig.filter(item => item.isdeleted !== "1" && item.id !== this.state.networkData?.id).map((network) => {
                    if (this.state.networkData.ipAddress === network.ipAddress && this.state.networkData.subnet === network.subnet)
                        validWan = false;
                })
                let elementIP = document.getElementById("ipAddress");
                if (validLan === false || validWan === false) {
                    elementIP.style.display = "block";
                    elementIP.innerHTML = "IP Address/subnet already exists!"
                    this.setState({
                        showError: true,
                        sameIPError: true
                    })
                }
                else {
                    if (this.state.sameIPError) {
                        elementIP.style.display = "none";
                        this.setState({
                            showError: false,
                            sameIPError: false
                        })
                    }
                }
            }

            if (Common.validateIPaddress(e) === false || e.target.value === '0.0.0.0' ||
                e.target.value === '255.255.255.255') {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Invalid IP';
                    this.setState({
                        showError: true,
                        networkData: data
                    })
                }
            }
            //LONG-2930-EM - Front-end Issues 5,Able to create two networks with same IP Address
            // if (e.target.name === 'ipAddress') {
            //     let validLan = this.state.template_schema_json.lanConfig.filter(item => item.ipAddress === e.target.value && item.isdeleted !== "1" && item.id !== this.state.networkData?.id).length
            //     let validWan = this.state.template_schema_json.wanConfig.filter(item => item.ipAddress === e.target.value && item.isdeleted !== "1" && item.id !== this.state.networkData?.id).length
            //     if (validLan > 0 || validWan > 0) {
            //         const element = document.getElementById(e.target.name);
            //         if (element) {
            //             element.style.display = 'block';
            //             element.innerHTML = 'IP Address already exists!';
            //             this.setState({
            //                 showError: true,
            //             })
            //         }
            //     }
            // }
            // else if (Common.validatePrivateIP(e) === false) {
            //     const element = document.getElementById(e.target.name);
            //     if (element) {
            //         element.style.display = 'block';
            //         element.innerHTML = 'This is restricted IP address';
            //         this.setState({
            //             showError: true,
            //         })
            //     }
            //     else {
            //         this.setState({
            //             showError: true,
            //             networkData: data
            //         })
            //     }
            // }
            else {
                this.getNetmask();
            }
        }
        if (e.target.name === 'staticRouteDestinationIP') {
            if (Common.validateIPaddress(e) === false || e.target.value === '0.0.0.0' ||
                e.target.value === '255.255.255.255') {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Invalid value';
                    this.setState({
                        showError: true,
                    })
                }
            }
        }
        if (e.target.name === 'ipaddr') {
            const network = this.state.template_schema_json.lanConfig.filter(item =>
                item.name === this.state.reservationData.network)[0];
            try {
                const block = new Netmask(network.ipAddress + '/' + network.subnet);
                if (block.contains(e.target.value) === false || block.broadcast === e.target.value) {
                    const element = document.getElementById(e.target.name);
                    if (element) {
                        element.style.display = 'block';
                        element.innerHTML = "IP address should be in the same range of network IP address!";
                        this.setState({
                            showError: true
                        })
                    }
                } else {
                    this.checkIPAddress(e);
                }
            } catch {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = "Invalid value";
                    this.setState({
                        showError: true
                    })
                }
            }
        } if (e.target.name === 'gateway') {
            if (e.target.value !== '') {
                if (Common.validateIPaddress(e) === false || e.target.value === '255.255.255.255') {
                    const element = document.getElementById(e.target.name);
                    if (element) {
                        element.style.display = 'block';
                        element.innerHTML = 'Invalid value';
                        this.setState({
                            showError: true,
                        })
                    }
                }
                //  else if (e.target.value !== '' && e.target.name === 'gateway' && Common.validatePrivateIP(e) === false) {
                //     const element = document.getElementById(e.target.name);
                //     if (element) {
                //         element.style.display = 'block';
                //         element.innerHTML = 'This is restricted IP address';
                //         this.setState({
                //             showError: true,
                //         })
                //     }
                //     else {
                //         this.setState({
                //             showError: false
                //         })
                //     }
                // }
            }

        }
        if (e.target.name === 'endAddress' || e.target.name === 'startAddress') {
            let element = document.getElementById('endAddress');
            if (element)
                element.style.display = 'none';
            element = document.getElementById('startAddress');
            if (element)
                element.style.display = 'none';
            this.setState({
                showError: false
            });
            if (Common.validateIPaddress(e) === false || e.target.value === '0.0.0.0' || e.target.value === '255.255.255.255') {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Invalid value';
                    this.setState({
                        showError: true,
                    })
                }
            }
            try {
                const block = new Netmask(this.state.networkData.ipAddress + '/' + this.state.networkData.subnet);
                if (this.state.networkData.startAddress !== '' && (this.state.networkData.startAddress === this.state.networkData.endAddress)) {
                    const element = document.getElementById(e.target.name);
                    element.innerHTML = 'Start and end IP should not be same';
                    element.style.display = 'block';
                    this.setState({
                        showError: true
                    })
                }
                else {
                    let found = false;
                    if (block.contains(e.target.value) && block.broadcast !== e.target.value) {
                        found = true
                    }
                    if (found === false) {
                        const element = document.getElementById(e.target.name);
                        element.innerHTML = 'Invalid value';
                        element.style.display = 'block';
                        this.setState({
                            showError: true
                        })
                    } else if (this.state.networkData.startAddress !== '' && this.state.networkData.endAddress !== '') {
                        //LONG-1886-Error message when editing correctly for DHCP server configuration

                        const [startIP1, startIP2, startIP3, startIP4] = this.state.networkData.startAddress.split('.'),
                            [endIP1, endIP2, endIP3, endIP4] = this.state.networkData.endAddress.split('.')
                        // [ip4] = this.state.networkData.ipAddress.split('.')
                        if (parseInt(startIP4) < 1 || parseInt(endIP4) > 254) {
                            const element = document.getElementById(e.target.name);
                            element.innerHTML = 'Invalid Value';
                            element.style.display = 'block';
                            this.setState({
                                showError: true
                            })
                        }
                        if (parseInt(endIP1) < parseInt(startIP1)) {
                            const element = document.getElementById(e.target.name);
                            element.innerHTML = 'End IP should be greater than the start IP';
                            element.style.display = 'block';
                            this.setState({
                                showError: true
                            })
                        }
                        else if (parseInt(endIP2) < parseInt(startIP2)) {
                            const element = document.getElementById(e.target.name);
                            element.innerHTML = 'End IP should be greater than the start IP';
                            element.style.display = 'block';
                            this.setState({
                                showError: true
                            })
                        }
                        else if (parseInt(endIP3) < parseInt(startIP3)) {
                            const element = document.getElementById(e.target.name);
                            element.innerHTML = 'End IP should be greater than the start IP';
                            element.style.display = 'block';
                            this.setState({
                                showError: true
                            })
                        }
                        else if ((parseInt(endIP4) < parseInt(startIP4))) {

                            if (parseInt(endIP3) > parseInt(startIP3)) {
                                this.setState({
                                    showError: false
                                })
                            }
                            else {

                                const element = document.getElementById(e.target.name);
                                element.innerHTML = 'End IP should be greater than the start IP';
                                element.style.display = 'block';
                                this.setState({
                                    showError: true
                                })

                            }
                        }
                    }
                }
            } catch (error) {
            }
        }
    }
    validateMac = (e) => {
        if (!Common.validateMac(e))
            this.setState({
                showError: true
            })
    }
    openAddReservation = (data) => {
        if (data) {
            const rowData = JSON.parse(JSON.stringify(data));
            this.setState({
                reservationData: rowData,
                openReservation: true,
                update: true
            })
        } else {
            const elements = document.getElementsByClassName('error-txt static');
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.cssText = "display: none;"
            }
            this.setState({
                reservationData: {
                    id: Math.random().toString(36).substring(2, 11).toUpperCase(),
                    hostname: "",
                    network: "",
                    macaddr: "",
                    ipaddr: "",
                    isdeleted: "0"
                },
                openReservation: true,
            })
        }
    }
    setDHCPData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        const name = e.target.name;
        if (name === 'macaddr' && this.checkMacAddress(e) === true) {
            const element = document.getElementById(name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = "MAC address cannot be same for two reservations!";
                this.setState({
                    showError: true
                })
            }
        }
        if (name === 'ipaddr' && this.checkIPAddress(e) === true) {
            const element = document.getElementById(name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = "IP address cannot be same for two reservations!";
                this.setState({
                    showError: true
                })
            }
        }
        const data = this.state.reservationData;
        if (name === 'network') {
            data['ipaddr'] = ''
        }

        data[name] = e.target.value;
        this.setState({
            reservationData: data
        })

    }
    addDHCPValues = () => {
        let found = false;
        const data = this.state.template_schema_json;
        data.reservations.map((item, index) => {
            if (item.id === this.state.reservationData.id) {
                found = true;
                data.reservations[index] = this.state.reservationData;
            }
        })
        if (!found)
            data.reservations.push(this.state.reservationData);
        this.setState({
            template_schema_json: data,
            openReservation: false,
            keyUpdateRes: Math.random(),
        }, () => this.closeAddReservation())
    };
    closeAddReservation = () => {
        this.setState({
            update: false,
            openReservation: false,
            reservationData: {
                id: Math.random().toString(36).substring(2, 11).toUpperCase(),
                hostname: '',
                network: "",
                macaddr: "",
                ipaddr: "",
                isdeleted: "0"
            }
        })
    }
    openAddNetworkData = (data) => {

        if (data) {
            let rowData = JSON.parse(JSON.stringify(data));;
            this.setState({
                networkData: rowData,
                openAddNetwork: true,
                update: true,
            })
        } else {
            const elements = document.getElementsByClassName('error-txt lan');
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.cssText = "display: none;"
            }
            this.setState({
                selectedNetwork: [],
                networkData: {
                    id: Math.random().toString(36).substring(2, 11).toUpperCase(),
                    name: '',
                    vlanID: '',
                    networkName: "",
                    interfaceName: '',
                    protocol: "staticAddr",
                    bridgeStatus: "0",
                    ipAddress: '',
                    subnet: '',
                    netmask: "",
                    overrideNetmask: "",
                    gateway: "",
                    dnsAddress: [],
                    dhcpServer: "0",
                    startAddress: "",
                    endAddress: "",
                    leaseTime: "2",
                    dnsServer: "useDNSFromISP",
                    dns1: "",
                    dns2: "",
                    zone: "lan",
                    isdeleted: "0",
                    is_network_ip_changed: this.props.group_id ? "0" : undefined,
                    "is_loopback": "0",
                    "loopback_id": '',
                    "auth_server": "",
                    "auth_port": "",
                    "auth_secret": "",
                    "802_1x_enabled": "0",
                    masquerade: '0',
                    staticRouteDestinationIP: '',
                    mtu: "1500",
                    "radius_server": "",
                    "prim_auth_server": "",
                    "prim_auth_port": "",
                    "prim_auth_secret": "",
                    "sec_auth_server": "",
                    "sec_auth_port": "",
                    "sec_auth_secret": "",
                    isedit: "1"
                },
                openAddNetwork: true,
            })
        }
    }
    closeAddNetworkData = () => {
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;"
        }
        this.setState({
            selectedNetwork: [],
            openAddNetwork: false,
            update: false,
        })
    }
    getOldConfig = (value) => {
        this.setState({
            showLoader: true,
        })
        let data = {
            device_mac: this.state.device_mac,
            configuration: value
        }
        ApiCall(urlMapping.get_oldConfigs(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                let { template_schema_json } = this.state;
                template_schema_json.lanConfig = template_schema_json.lanConfig.filter(item => item.name === 'Default');
                template_schema_json.lanConfig.map((item, index) => {
                    template_schema_json.lanConfig[index].id = response?.data?.id
                })
                let value = response?.data
                if (value.protocol === 'staticAddr') {
                    value.masquerade = '0'
                    value.staticRouteDestinationIP = ''
                }
                this.setState({
                    networkData: value,
                    template_schema_json
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    setNetworkData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true
            })
        }
        const data = this.state.networkData;
        // const customInterfaces = this.state.customInterfaces;
        // customInterfaces.usedCustomInterfaces = [];
        const value = (e.target.type === "checkbox") ?
            e.target.name === "isedit" ?
                (e.target.checked === true ? "0" : "1") :
                (e.target.checked === true ? "1" : "0")
            : (e.target.name === "mtu"
                ? (e.target.value).trim()
                : e.target.value);
        // this.setState({
        //     customInterfaces: customInterfaces
        // })
        if (this.props.apply && this.state.networkData.name === "Default") {
            if (e.target.value === 'staticAddr') {
                if (this.state.networkData.protocol !== e.target.value) {
                    this.getOldConfig(e.target.value)
                }
            }
            // if (e.target.value === 'ippt') {
            //     if (this.state.networkData.protocol !== e.target.value) {
            //         this.getOldConfig(e.target.value)
            //     }
            // }
        }
        if (e.target.name === "ipAddress") {
            if (this.props.group_id) {
                data.is_network_ip_changed = "1"
                this.setState({
                    networkData: data,
                    ipChanged: true
                });
            }
        }
        const name = e.target.name;
        //Long-1791 D- Minimum Lease time for DHCP client was not setting properly
        if ((e.target.name === 'leaseTime') &&
            (e.target.value === '' || parseInt(e.target.value) > 2880 || parseInt(e.target.value) < 2)) {
            data[name] = e.target.value;
            const element = document.getElementById(e.target.name);
            element.style.display = 'block';
            this.setState({
                showError: true
            })
        } else {
            if (e.target.name === 'subnet') {
                if (Common.validateNumeric(e)) {
                    data.subnet = e.target.value
                }
            }
            else {
                if (e.target.name !== 'zone') {
                    data[name] = name === 'mtu' ? value.trim() : value;
                }
            }
        }
        if (e.target.name === 'is_auto_increment') {
            const value1 = (e.target.type === "checkbox") ?
                (e.target.checked === true ? "1" : "0") : e.target.value;
            data.is_auto_increment = value1;
            data.no_of_subnets = "200";
            this.setState({
                networkData: data
            }, () => this.getNetmask())
        }
        if (e.target.name === 'dnsServer') {
            if (e.target.value === 'useDNSFromISP') {
                const elements = document.getElementsByClassName('error-txt lan dns');
                for (let i = 0; i < elements.length; i++) {
                    elements[i].style.cssText = "display: none;"
                }
                data.dns1 = ''
                data.dns2 = ''
            }
        }
        if (e.target.name === 'zone' && e.target.type === "checkbox") {
            const value1 = e.target.checked === true ? "wan" : "lan";
            const data = this.state.networkData;
            // if (this.state.networkData.protocol === 'ippt') {
            //     data.protocol = 'staticAddr'
            // }
            data.zone = value1;
            if (value1 === "wan") {
                data.dhcpServer = "0";
                data.is_loopback = "0";
                data["802_1x_enabled"] = "0";
                data.auth_server = "";
                data.auth_port = "";
                data.auth_secret = "";
                data.dnsServer = '';
                data.radius_server = "";
                data.prim_auth_server = "";
                data.prim_auth_port = "";
                data.prim_auth_secret = "";
                data.sec_auth_server = "";
                data.sec_auth_port = "";
                data.sec_auth_secret = "";
                data.dnsAddress = "";
                data.isedit = "1";
            }
            this.setState({
                networkData: data
            })
        }
        if (e.target.name === 'is_loopback') {
            if (value === "1") {
                data.bridgeStatus = '0';
                data.dhcpServer = '0';
                data.startAddress = '';
                data.endAddress = '';
                data.gateway = '';
                data.dnsAddress = [];
                data["802_1x_enabled"] = "0";
                data.auth_server = "";
                data.auth_port = "";
                data.auth_secret = "";
                data.radius_server = "";
                data.prim_auth_server = "";
                data.prim_auth_port = "";
                data.prim_auth_secret = "";
                data.sec_auth_server = "";
                data.sec_auth_port = "";
                data.sec_auth_secret = ""
                this.setState({
                    networkData: data
                })
            }
        }
        if (e.target.name === '802_1x_enabled') {
            data.auth_server = value === '1' ? this.state.auth_server : '';
            data.auth_port = value === '1' ? this.state.auth_port : '';
            data.auth_secret = value === '1' ? this.state.auth_secret : '';
        }
        if (e.target.name === 'dhcpServer') {
            if (value === "1") {
                data.startAddress = '';
                data.endAddress = '';
                this.setState({
                    networkData: data
                }, () => this.getNetmask())
            }
            else {
                data.dnsServer = ''
            }
        }
        if ((e.target.name === "no_of_subnets") && (e.target.value === '' || parseInt(e.target.value) > 200 || parseInt(e.target.value) < 1)) {
            data[name] = e.target.value;
            const element = document.getElementById(e.target.name);
            element.style.display = 'block';
            this.setState({
                showError: true
            })
        }
        //EMGT-289 IP Address remains the same while configuring WAN as LAN
        if (e.target.name === 'protocol') {
            if (e.target.value === 'staticAddr') {
                if (this.state.networkData.zone === 'lan')
                    data.masquerade = '0';
                data.staticRouteDestinationIP = '';
            }
            if (e.target.value === 'dhcpclient') {
                data.ipAddress = '';
                data.subnet = '';
                data.netmask = '';
                data.overrideNetmask = "";
                data.startAddress = '';
                data.endAddress = '';
                data.loopback_id = "";
                data.mtu = "1500";
                data.dnsAddress = [];
                data.gateway = "";
                data.no_of_subnets = "200";
                data.dhcpServer = "0";
                data.leaseTime = "1440";
                data.dnsServer = "useDNSFromISP";
                data.dns1 = "";
                data.dns2 = "";
                data.is_loopback = "0";
                data.bridgeStatus = "0";
                const elements = document.getElementsByClassName('error-txt lan');
                for (let i = 0; i < elements.length; i++) {
                    elements[i].style.cssText = "display: none;"
                }
            }
            // if (e.target.value === 'ippt') {
            //     data.bridgeStatus = '0';
            //     data.dnsAddress = [];
            //     data.dhcpServer = '0';
            //     data["802_1x_enabled"] = "0";
            //     data.auth_server = "";
            //     data.auth_port = "";
            //     data.auth_secret = "";
            //     data.ipAddress = '';
            //     data.subnet = '';
            //     data.netmask = '';
            //     data.startAddress = '';
            //     data.endAddress = '';
            //     data.masquerade = '1'
            //     data.is_auto_increment = "0";
            //     data.netmask = "255.255.255.0";
            //     data.zone = 'lan';
            //     const elements = document.getElementsByClassName('error-txt lan');
            //     for (let i = 0; i < elements.length; i++) {
            //         elements[i].style.cssText = "display: none;"
            //     }
            // }
        }
        if ((e.target.name === 'mtu') && (isNaN(e.target.value) ||
            (parseInt(e.target.value) > 1500 || parseInt(e.target.value) < 576))) {
            data[name] = (e.target.value).trim();
            const element = document.getElementById(e.target.name);
            element.style.display = 'block';
            this.setState({
                showError: true
            })
        }
        if (e.target.name === 'isedit' && value === '0') {
            const network = this.state.template_schema_json.defaultConfig.filter(val => val.network === this.state.networkData.networkName)[0];
            if (network) {
                data.protocol = network.proto;
                data.ipAddress = network.ipaddr ? network.ipaddr : "";
                data.gateway = network.gateway ? network.gateway : "";
                data.netmask = network.netmask ? network.netmask : "";
                data.dnsAddress = network.dns ? network.dns : [];
                data.subnet = this.calculateSubnet(network.netmask);
                data.mtu = network.mtu ? network.mtu : "";
            }
        }
        this.setState({
            networkData: data
        })
        if (e.target.value === "staticAddr") {
            setTimeout(() => {
                this.getNetmask()
            }, 100);
        }
    }
    calculateSubnet = (netmask) => {
        let subnet = "";
        switch (netmask) {
            case "0.0.0.0":
                return subnet = "0";
            case "128.0.0.0":
                return subnet = "1";
            case "192.0.0.0":
                return subnet = "2";
            case "224.0.0.0":
                return subnet = "3";
            case "240.0.0.0":
                return subnet = "4";
            case "248.0.0.0":
                return subnet = "5";
            case "252.0.0.0":
                return subnet = "6";
            case "254.0.0.0":
                return subnet = "7";
            case "255.0.0.0":
                return subnet = "8";
            case "255.128.0.0":
                return subnet = "9";
            case "255.192.0.0":
                return subnet = "10";
            case "255.224.0.0":
                return subnet = "11";
            case "255.240.0.0":
                return subnet = "12";
            case "255.248.0.0":
                return subnet = "13";
            case "255.252.0.0":
                return subnet = "14";
            case "255.254.0.0":
                return subnet = "15";
            case "255.255.0.0":
                return subnet = "16";
            case "255.255.128.0":
                subnet = "17";
            case "255.255.192.0":
                return subnet = "18";
            case "255.255.224.0":
                return subnet = "19";
            case "255.255.240.0":
                return subnet = "20";
            case "255.255.248.0":
                return subnet = "21";
            case "255.255.252.0":
                return subnet = "22";
            case "255.255.254.0":
                return subnet = "23";
            case "255.255.255.0":
                return subnet = "24";
            case "255.255.255.128":
                return subnet = "25";
            case "255.255.255.192":
                return subnet = "26";
            case "255.255.255.224":
                return subnet = "27";
            case "255.255.255.240":
                return subnet = "28";
            case "255.255.255.248":
                return subnet = "29";
            case "255.255.255.252":
                return subnet = "30";
            case "255.255.255.254":
                return subnet = "31";
            case "255.255.255.255":
                return subnet = "";
            default:
                return subnet = "";
        }
    }
    getNetmask = () => {
        if (this.state.networkData.ipAddress !== '' && this.state.networkData.subnet !== '') {
            let element = document.getElementById("subnet");
            element.style.display = "none";
            let validLan = true;
            let validWan = true;
            this.state.template_schema_json.lanConfig.filter(item => item.isdeleted !== "1" && item.id !== this.state.networkData?.id).map((network) => {
                if (this.state.networkData.ipAddress === network.ipAddress && this.state.networkData.subnet === network.subnet)
                    validLan = false;
            })
            this.state.template_schema_json.wanConfig.filter(item => item.isdeleted !== "1" && item.id !== this.state.networkData?.id).map((network) => {
                if (this.state.networkData.ipAddress === network.ipAddress && this.state.networkData.subnet === network.subnet)
                    validWan = false;
            })
            let elementIP = document.getElementById("ipAddress");
            if (validLan === false || validWan === false) {
                elementIP.style.display = "block";
                elementIP.innerHTML = "IP Address/subnet already exists!"
                this.setState({
                    showError: true,
                    sameIPError: true
                })
            }
            else {
                if (this.state.sameIPError) {
                    elementIP.style.display = "none";
                    this.setState({
                        showError: false,
                        sameIPError: false
                    })
                }
            }
            const ipAddress =
                this.state.networkData.ipAddress +
                "/" +
                this.state.networkData.subnet;
            try {
                if (this.state.networkData.is_auto_increment === "1") {
                    let element = document.getElementById("subnet");
                    if (this.state.networkData.subnet < 8 || this.state.networkData.subnet > 32) {
                        if (element) {
                            element.style.display = "block";
                            element.innerHTML = "Subnet value must be between 8 and 32";
                            this.setState({
                                showError: true,
                            });
                        }
                        return;
                    }
                } else {
                    //LONG-2930- 6,In network template subnet  0 should not be allowed
                    if (this.state.networkData.subnet < 1 || this.state.networkData.subnet > 32) {
                        let element = document.getElementById("subnet");
                        element.style.display = "block";
                        element.innerHTML =
                            "Subnet value must be between 1 and 32";
                        this.setState({
                            showError: true,
                        });
                        return;
                    }
                }
                let result = false;
                const elements = document.getElementsByClassName('error-txt lan ip');
                for (let i = 0; i < elements.length; i++) {
                    if (elements[i].style.cssText === "display: block;")
                        result = true;
                }
                if (!result) {
                    const block = new Netmask(ipAddress);
                    const data = this.state.networkData;
                    if (String(this.state.networkData.ipAddress) === String(block.broadcast)) {
                        element.style.display = "block";
                        element.innerHTML = "IP Address must not be broadcast address"
                        this.setState({ showError: true })
                    }
                    else {
                        data.netmask = block.mask;
                        let startAddressTemp = this.state.networkData.ipAddress.split('.').map(Number);
                        startAddressTemp[3] = (startAddressTemp[3] + 1) % 255;
                        startAddressTemp[3] = startAddressTemp[3] === 0 ? 1 : startAddressTemp[3]
                        data.startAddress = startAddressTemp.join('.');
                        data.endAddress = block.last;
                        element = document.getElementById("startAddress");
                        if (element)
                            element.style.display = "none";
                        element = document.getElementById("endAddress");
                        if (element)
                            element.style.display = "none";
                        this.setState({
                            networkData: data,
                        });
                    }
                }
            } catch (err) {
                let element = document.getElementById("subnet");
                if (element) {
                    element.style.display = "block";
                    element.innerHTML =
                        "Subnet value must be between 1 and 32";
                    this.setState({
                        showError: true,
                    });
                }
            }
        }
        else {
            if (this.state.networkData.ipAddress !== '' && this.state.networkData.subnet === '') {
                let element = document.getElementById("subnet");
                if (element) {
                    element.style.display = "block";
                    element.innerHTML =
                        this.state.networkData.is_auto_increment === "1"
                            ? "Subnet value must be between 8 and 32"
                            : "Subnet value must be between 1 and 32";
                    this.setState({
                        showError: true,
                    });
                }
            }
        }
        // if (this.state.networkData.protocol === 'ippt' && this.state.networkData.staticRouteDestinationIP !== '' && this.state.networkData.subnet !== '') {
        //     let element1 = document.getElementById("staticRouteDestinationIP");
        //     if (this.state.networkData.subnet < 1 || this.state.networkData.subnet > 32) {
        //         element1.style.display = "block";
        //         element1.innerHTML =
        //             "Subnet value must be between 1 and 32";
        //         this.setState({
        //             showError: true,
        //         });
        //     }
        //     else {
        //         element1.style.display = "none";
        //         this.setState({
        //             showError: false,
        //         });
        //     }
        // }
    }
    addNetworkData = () => {
        let result = false;
        let errorFlag = false;
        const { networkData } = this.state;
        if (this.state.networkData.zone === "lan") {
            if (
                (this.state.update &&
                    this.state.template_schema_json.lanConfig.length > 12)
            ) {
                errorFlag = true;
            } else
                if (
                    (!this.state.update &&
                        this.state.template_schema_json.lanConfig.length > 11)
                ) {
                    errorFlag = true;
                }
        } else if (this.state.networkData.zone === "wan") {
            //LONG-2629-After deleting the device WAN network in network template Not able to add the WAN network in CBR
            let data = this.state.template_schema_json.wanConfig.filter(item => item.isdeleted === "0")
            if (
                (this.state.update &&
                    data.length > 8)
            ) {
                errorFlag = true;
            } else if (
                (!this.state.update &&
                    data.length > 7)
            ) {
                errorFlag = true;
            }
        }
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (result === false) {
            if (errorFlag) {
                this.setState(
                    {
                        title: "Create Template",
                        message:
                            this.state.networkData.zone === "lan"
                                ? "Only up to 12 LAN networks can be added!"
                                : "Only up to 8 WAN networks can be added!",
                        show: true,
                        error: true,
                        delete: false,
                        hide: false,
                    },
                    () => $("#errorMessage").modal("show")
                );
            } else {
                const data = this.state.template_schema_json;
                let found = false;
                data.wanConfig.map((item, index) => {
                    if (item.id === this.state.networkData.id) {
                        found = true;

                        if (
                            item.bridgeStatus !==
                            this.state.networkData.bridgeStatus
                        ) {
                            networkData.interfaceName = "";
                        }
                        if (item.zone !== this.state.networkData.zone) {
                            let updateFlag = true;
                            //long- 1539 -MA - [Network Template] - Interface is getting removed after mapping tagged interface to a network
                            networkData.interfaceName = '';
                            item.isdeleted = "1";
                            if (updateFlag) {
                                //LONG-2639-wrong network name json was sending from edge manager
                                if (networkData.zone === 'wan') {
                                    networkData.networkName = this.getNetworkName();
                                } else {
                                    networkData.networkName = `net${Math.floor(Math.random().toFixed(5) * 100000 + 1)}`;

                                }
                                let count = data.lanConfig.filter((item) => item.id === networkData.id)
                                count.length !== 0 ? data.lanConfig.filter((item) => item.id !== networkData.id).push(networkData) : data.lanConfig.push(networkData);
                            }
                        } else {
                            data.wanConfig[index] = networkData;
                        }
                    }
                });
                data.lanConfig.map((item, index) => {
                    if (item.id === this.state.networkData.id) {
                        found = true;
                        const { networkData } = this.state;
                        if (item.bridgeStatus !== this.state.networkData.bridgeStatus) {
                            networkData.interfaceName = '';
                        }
                        if (item.zone !== this.state.networkData.zone) {
                            let updateFlag = true;
                            //long- 1539 -MA - [Network Template] - Interface is getting removed after mapping tagged interface to a network
                            networkData.interfaceName = '';
                            item.isdeleted = "1";
                            if (updateFlag) {
                                //LONG-2639-wrong network name json was sending from edge manager
                                if (this.state.networkData.zone === 'wan') {
                                    networkData.networkName = this.getNetworkName();
                                } else {
                                    networkData.networkName = `net${Math.floor(Math.random().toFixed(5) * 100000 + 1)}`;

                                }
                                let count = data.wanConfig.filter((item) => item.id === networkData.id)
                                count.length !== 0 ? data.wanConfig.filter((item) => item.id !== networkData.id).push(networkData) : data.wanConfig.push(networkData);
                            }
                        } else {
                            data.lanConfig[index] = networkData;
                        }
                    }
                })
                if (!found) {

                    if (this.state.networkData.zone === 'wan') {
                        networkData.networkName = this.getNetworkName();
                    } else {
                        networkData.networkName = `net${Math.floor(Math.random().toFixed(5) * 100000 + 1)}`;

                    }
                    let netData = this.state.networkData;
                    netData.interfaceName = '';
                    if (netData.zone === 'wan') {
                        data.wanConfig.push(netData);
                    } else {
                        data.lanConfig.push(netData);
                    }
                }
                this.setState({
                    template_schema_json: data,
                    keyUpdate: Math.random(),
                    selectedInterface: '',
                    selectedNetname: '',
                    selectedNetwork: [],
                    networkData: {
                        id: Math.random().toString(36).substring(2, 11).toUpperCase(),
                        name: '',
                        vlanID: '',
                        networkName: "lan",
                        interfaceName: '',
                        protocol: "staticAddr",
                        bridgeStatus: "0",
                        ipAddress: '',
                        subnet: '',
                        netmask: "",
                        overrideNetmask: "",
                        gateway: "",
                        dnsAddress: [],
                        dhcpServer: "0",
                        startAddress: "",
                        endAddress: "",
                        leaseTime: "2",
                        dnsServer: "useDNSasBelow",
                        dns1: "",
                        dns2: "",
                        zone: "lan",
                        isdeleted: "0",
                        "is_loopback": "0",
                        "loopback_id": '',
                        "802_1x_enabled": "0",
                        "auth_server": "",
                        "auth_port": "",
                        "auth_secret": "",
                        "radius_server": "",
                        "prim_auth_server": "",
                        "prim_auth_port": "",
                        "prim_auth_secret": "",
                        "sec_auth_server": "",
                        "sec_auth_port": "",
                        "sec_auth_secret": "",
                        masquerade: '0',
                        staticRouteDestinationIP: '',
                        mtu: "1500"
                    },
                }, () => this.closeAddNetworkData());
            }
        }
        this.getAllLists();
    }
    getNetworkName = () => {
        let network = this.state.networkData;
        let networkName = '';
        if (network.zone === 'wan') {
            for (let i = 1; i <= 8; i++) {
                let getNetwork = true;
                const data = this.state.template_schema_json.wanConfig.filter(network => network.name !== 'Cellular' && network.networkName !== 'wan_1G');
                data.map((item) => {
                    if (parseInt(item.networkName.split('wan')[1]) === i) {
                        getNetwork = false;
                    }
                })
                if (getNetwork) {
                    networkName = 'wan' + i;
                    break;
                }
            }
        }

        return networkName;
    }
    applyTemplate = () => {
        let { template_schema_json } = this.state;
        let ipptCount = template_schema_json.lanConfig.filter((item) => item.protocol === 'ippt').length
        if (ipptCount > 0) {
            this.setState({
                title: 'Apply Template',
                message: 'IPPT protocol can be applied to MIFI devices only, please change the protocol to apply it on this device!',
                show: true,
                error: true,
            }, () => $("#errorMessage").modal("show"));
        }
        else {
            this.props.dispatch({
                type: Action.SET_APPLY_TEMPLATE,
            });
            let { ipChanged } = this.state;
            if (ipChanged === false) {
                template_schema_json.lanConfig.filter(item => item.name === 'Default').map((item) => {
                    delete item['is_network_ip_changed'];
                });
            }
            template_schema_json.wanConfig = template_schema_json.wanConfig.filter(item => item.name !== 'Cellular');
            let usedIds = [];
            template_schema_json.lanConfig.map((item) => {
                if (item.is_loopback === '1' && item.loopback_id !== '') {
                    usedIds.push(item.loopback_id);
                }
            });
            let count = usedIds.length > 0 ? Math.max(...usedIds) + 1 : 1;
            template_schema_json.lanConfig.map((item) => {
                if (item.is_loopback === '1' && item.loopback_id === '') {
                    item.loopback_id = count;
                    count++;
                }
            })
            template_schema_json.lanConfig.map((network) => {
                const interfaces = network.interfaceName.split(' ');
                interfaces.map((value) => {
                    if (value.includes('.')) {
                        let vlanID = value.split('.')[1];
                        if (!network.vlanID.includes(vlanID)) {
                            network.vlanID = network.vlanID === '' ? vlanID : network.vlanID + ',' + vlanID
                        }
                    }
                });
                return network;
            });
            template_schema_json.lanConfig.map((item) => {
                if (item.auth_server = item["802_1x_enabled"] === '1') {
                    if (item.radius_server === 'SD-Edge_ZTNA-B') {
                        item.auth_server = this.state.auth_server;
                        item.auth_port = this.state.auth_port;
                        item.auth_secret = this.state.auth_secret;
                        item.prim_auth_server = "";
                        item.prim_auth_port = "";
                        item.prim_auth_secret = "";
                        item.sec_auth_server = "";
                        item.sec_auth_port = "";
                        item.sec_auth_secret = ""
                    } else {
                        this.state.server_data.map((server) => {
                            if (server.profile_name === item.radius_server) {
                                item.auth_server = "";
                                item.auth_port = "";
                                item.auth_secret = "";
                                item.prim_auth_server = server.primary_server.prim_auth_server;
                                item.prim_auth_port = server.primary_server.prim_auth_port;
                                item.prim_auth_secret = server.primary_server.prim_auth_secret;
                                item.sec_auth_server = server.secondary_server.sec_auth_server;
                                item.sec_auth_port = server.secondary_server.sec_auth_port;
                                item.sec_auth_secret = server.secondary_server.sec_auth_secret
                            }
                        })
                    }
                }
                else {
                    item.radius_server = "";
                    item.auth_server = "";
                    item.auth_port = "";
                    item.auth_secret = "";
                    item.prim_auth_server = "";
                    item.prim_auth_port = "";
                    item.prim_auth_secret = "";
                    item.sec_auth_server = "";
                    item.sec_auth_port = "";
                    item.sec_auth_secret = ""
                }
                return item;
            })
            template_schema_json.wanConfig.map((item) => {
                item.auth_server = '';
                item.auth_port = '';
                item.auth_secret = '';
                item.prim_auth_server = "";
                item.prim_auth_port = "";
                item.prim_auth_secret = "";
                item.sec_auth_server = "";
                item.sec_auth_port = "";
                item.sec_auth_secret = "";
                item.startAddress = "";
                item.endAddress = "";
                return item;
            })
            template_schema_json.wanConfig.map((item) => {
                if (item.protocol === "dhcpclient") {
                    item.gateway = ""
                }
                return item
            })
            this.props.applyTemplate(template_schema_json);
        }
    }
    debounceApply = debounce((fn, event) => {
        fn(event)
    }, 500);
    selectNetwork = (e, network) => {
        let { selectedNetwork } = this.state;
        if (e.target.checked === true) {
            selectedNetwork.push(network);
        } else {
            selectedNetwork = selectedNetwork.filter(item => item.id !== network.id);
        }
        this.setState({
            selectedNetwork
        })
    }
    checkNetworkName = (e) => {
        let valid = true;
        if (Common.validateAlphaNumeric(e)) {
            if (
                this.state.template_schema_json.lanConfig.filter(
                    (item) => (item.name.toUpperCase() === e.target.value.toUpperCase() && item.id !== this.state.networkData.id &&
                        item.name === e.target.value && item.isdeleted !== "1")
                ).length > 0
            ) {
                valid = false;
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = "network already exists!";
                this.setState({
                    showError: true,
                });
            }
            else if (e.target.value.length < 1 || e.target.value.length > 32) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = "Enter 1 to 32 characters";
                this.setState({
                    showError: true,
                });

            } else if (
                this.state.template_schema_json.wanConfig.filter(
                    (item) =>
                        item.name.toUpperCase() === e.target.value.toUpperCase() && item.id !== this.state.networkData.id &&
                        item.name === e.target.value && item.isdeleted !== "1"
                    // && item.name === e.target.value.toUpperCase()
                ).length > 0
            ) {
                valid = false;
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = "network already exists!";
                this.setState({
                    showError: true,
                });
            } else {
                const element = document.getElementById(e.target.name);
                element.style.display = "none";
                element.innerHTML = "";
                this.setState({
                    showError: false,
                });
            }
            const data = this.state.networkData;
            data[e.target.name] = e.target.value;
            this.setState({
                networkData: data
            })
        }
        return valid;
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
            if (this.state.hide) {
                this.props.refreshList();
                this.props.closeAddTemplate();
            }
        });
    }
    checkNetworkError = () => {
        let result = false;
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;") {
                result = true;
            }
        }
        if (this.state.networkData.protocol !== 'ippt') {
            if (this.state.networkData.mtu === '')
                result = true;
        }
        if (this.state.networkData.protocol === 'staticAddr') {
            if (this.state.networkData.name === '' ||
                this.state.networkData.ipAddress === '' ||
                this.state.networkData.netmask === '' ||
                (this.state.networkData.dhcpServer !== '0' && (this.state.networkData.startAddress === '' ||
                    this.state.networkData.endAddress === '')) ||
                (this.state.networkData.dnsServer === 'useDNSasBelow' && (this.state.networkData.dns1 === '' ||
                    this.state.networkData.dns2 === '')) ||
                (this.state.networkData.is_auto_increment === "1" && this.state.networkData.no_of_subnets === '')) {
                result = true;
            }
        }
        // else if (this.state.networkData.protocol === 'ippt') {
        //     if (this.state.networkData.name === '' || this.state.networkData.leaseTime === '') {
        //         result = true;
        //     }
        // }
        else {
            if (this.state.networkData.name === '')
                result = true;
        }
        // added validation for radius server
        if (this.state.networkData["802_1x_enabled"] === '1' && this.state.networkData.radius_server === '') {
            result = true;
        }
        if (this.state.networkData.protocol === "staticAddr" && this.state.networkData.dnsAddress.length === 0 && this.state.networkData.zone === "wan") {
            result = true;
        }
        return result;
    }
    checkError = () => {
        let result = false;
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.template_name === '' ||
            this.state.template_type === '')
            result = true;
        return result;
    }
    saveData = () => {
        let data = {};
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model = this.state.device_model.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_schema_json = this.state.template_schema_json;
        this.setState({
            showLoader: true,
        });
        if (this.state.selectedTemplateId !== '') {
            let usedIds = [];
            data.template_schema_json.lanConfig.map((item) => {
                if (item.is_loopback === '1' && item.loopback_id !== '') {
                    usedIds.push(item.loopback_id);
                }
            });
            let count = usedIds.length > 0 ? Math.max(...usedIds) + 1 : 1;
            data.template_schema_json.lanConfig.map((item) => {
                if (item.is_loopback === '1' && item.loopback_id === '') {
                    item.loopback_id = count;
                    count++;
                }
            })
            data.template_schema_json.wanConfig.map((item) => {
                if (item.protocol === "staticAddr") {
                    item.startAddress = '';
                    item.endAddress = '';
                }
            });
            ApiCall(urlMapping.updateTemplate(this.state.selectedTemplateId, data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.Success) {
                    this.props.refreshList();
                    this.props.closeAddTemplate();
                    this.props.openApplyTemplate(this.state.selectedTemplateId);
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        this.setState({
                            title: 'Update Template',
                            message: response?.message,
                            show: true,
                            error: true,
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            });
        } else {
            let count = 1;
            data.template_schema_json.lanConfig.map((item) => {
                if (item.is_loopback === '1') {
                    item.loopback_id = count;
                    count++;
                }
            })
            data.template_schema_json.wanConfig.map((item) => {
                if (item.protocol === "staticAddr") {
                    item.startAddress = '';
                    item.endAddress = '';
                }
                if (item.is_loopback === '1') {
                    item.loopback_id = count;
                    count++;
                }
            })
            ApiCall(urlMapping.createTemplate(data), (response) => {
                this.setState({
                    showLoader: false,
                });

                if (response?.success) {
                    this.props.refreshList();
                    this.props.closeAddTemplate();
                    this.props.openApplyTemplate(response?.data?.id);
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Create Template',
                            message: response?.message,
                            show: true,
                            error: true,
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            });
        }
    }
    deleteNetwork = () => {
        let data = this.state.template_schema_json;
        const { customInterfaces } = this.state;
        let { availableInterfaces } = this.state;
        // edit and reapply
        if (this.props.edit) {
            //for all selected networks
            this.state.selectedNetwork.map((item) => {
                let found = false;
                //in case of lan network
                if (item.zone === 'lan') {
                    this.props.template_schema_json.lanConfig.map((network) => {
                        // if matches
                        if (item.id === network.id) {
                            this.state.template_schema_json.lanConfig.filter(value => value.id === item.id)[0].isdeleted = "1";
                            found = true;
                        }
                        return network;
                    })

                    if (found === false) {
                        data.lanConfig = this.state.template_schema_json.lanConfig.filter(finalValue => finalValue.id !== item.id);
                    }
                } else {
                    this.props.template_schema_json.wanConfig.map((network) => {
                        if (item.id === network.id) {
                            this.state.template_schema_json.wanConfig.filter(value => value.id === item.id)[0].isdeleted = "1";
                            found = true;
                        }
                        return network;
                    })

                    if (found === false) {
                        data.wanConfig = this.state.template_schema_json.wanConfig.filter(finalValue => finalValue.id !== item.id);
                    }
                }
            })
        } else {
            this.state.selectedNetwork.map((item) => {
                if (item.zone === 'lan')
                    data.lanConfig = this.state.template_schema_json.lanConfig.filter(finalValue => finalValue.id !== item.id);
                else
                    data.wanConfig = this.state.template_schema_json.wanConfig.filter(finalValue => finalValue.id !== item.id);
            })
        }
        this.state.selectedNetwork.map((item) => {
            const interfaces = item.interfaceName.split(' ');
            interfaces.map((value) => {
                if (value.includes('.')) {
                    customInterfaces.usedCustomInterfaces = customInterfaces.usedCustomInterfaces.filter(item => item !== value);
                } else {
                    availableInterfaces.push(value);
                }
            })
        });
        this.setState({
            template_schema_json: data,
            toggledClearNetworkRows: !this.state.toggledClearNetworkRows,
            keyUpdate: Math.random(),
            selectedNetwork: [],
            selectedInterface: '',
            selectedNetname: '',
            customInterfaces,
            availableInterfaces
        }, () => this.getAllLists());
    }
    removePort = (value, network) => {
        const { template_schema_json } = this.state;
        if (network.zone === 'wan') {
            template_schema_json.wanConfig.map((item) => {
                if (item.name === network.name) {
                    let interfaces = item.interfaceName.split(' ').filter(interfaceValue => interfaceValue !== value);
                    item.interfaceName = interfaces.join(' ');
                    if (!value.includes('.')) {
                        template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName =
                            template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName === '' ? value :
                                template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName + ' ' + value;
                    } else {
                        const { customInterfaces } = this.state;
                        customInterfaces.usedCustomInterfaces = customInterfaces.usedCustomInterfaces.filter(item => item !== value);
                        this.setState({
                            customInterfaces
                        })
                    }
                }
                return item;
            })
            this.setState({
                template_schema_json,
                selectedInterface: '',
                selectedNetname: '',
            }, () => this.getAllLists())
        } else {
            const data = this.state.template_schema_json.lanConfig;
            data.map((item) => {
                if (item.name === network.name) {
                    let interfaces = item.interfaceName.split(' ').filter(interfaceValue => interfaceValue !== value);
                    item.interfaceName = interfaces.join(' ');
                    if (item.name !== 'Default') {
                        if (!value.includes('.')) {
                            data.filter(network => network.name === 'Default')[0].interfaceName =
                                data.filter(network => network.name === 'Default')[0].interfaceName === '' ? value :
                                    data.filter(network => network.name === 'Default')[0].interfaceName + ' ' + value;
                        } else {
                            const { customInterfaces } = this.state;
                            customInterfaces.usedCustomInterfaces = customInterfaces.usedCustomInterfaces.filter(item => item !== value);
                            this.setState({
                                customInterfaces
                            })
                        }
                    }
                    else {
                        const { customInterfaces } = this.state;
                        customInterfaces.usedCustomInterfaces = customInterfaces.usedCustomInterfaces.filter(item => item !== value);
                        this.setState({
                            customInterfaces
                        })
                    }
                }
                return item;
            })
            template_schema_json.lanConfig = data;
            this.setState({
                template_schema_json,
                selectedInterface: '',
                selectedNetname: '',
            }, () => this.getAllLists())
        }
    }
    addInterface = (e, value, network) => {
        const { template_schema_json } = this.state;
        let data;
        $("#exampleModalNetwork").modal("hide")
        if (e.target.checked === true) {
            let { customInterfaces } = this.state;
            if ((this.props.selectedModel === 'Duvel_Model' || this.props.selectedModel === 'Moretti_Model')) {
                template_schema_json.lanConfig.map((item) => {
                    // if (item.protocol === 'ippt') {
                    //     if (item.name === network.name && !item.interfaceName.split(' ').includes(value)) {
                    //         template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName =
                    //             template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName.split(' ').filter(item => item !== value).join(' ');

                    //         template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName =
                    //             template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName === '' ? item.interfaceName :
                    //                 template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName + ' ' + item.interfaceName;

                    //         item.interfaceName = value;
                    //     }
                    // } else {
                    item.interfaceName = item.interfaceName === '' ? value : item.interfaceName + ' ' + value;
                    // }
                })
            }
            else if (network.zone === 'wan') {
                template_schema_json.wanConfig.map((item) => {
                    if (item.name === network.name && !item.interfaceName.split(' ').includes(value)) {
                        if (network.bridgeStatus === '1') {
                            //if in default remove it
                            if (template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName.split(' ').includes(value)) {
                                template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName =
                                    template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName.split(' ').filter(item => item !== value).join(' ');
                            }
                            // add if bridge enabled              
                            item.interfaceName = item.interfaceName === '' ? value : item.interfaceName + ' ' + value;
                        } else {
                            // else replace it   
                            template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName =
                                template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName.split(' ').filter(item => item !== value).join(' ');

                            template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName =
                                template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName === '' ? item.interfaceName :
                                    template_schema_json.lanConfig.filter(network => network.name === 'Default')[0].interfaceName + ' ' + item.interfaceName;
                            item.interfaceName = value;
                        }
                    }
                    return item;
                });

                this.setState({
                    template_schema_json,
                    selectedInterface: value,
                    selectedNetname: network.name
                }, () => this.getAllLists())

            } else {
                data = this.state.template_schema_json.lanConfig;
                //if not default network
                if (network.name !== 'Default') {
                    data.map((item) => {
                        if (item.name === network.name && !item.interfaceName.split(' ').includes(value)) {
                            if (network.bridgeStatus === '1') {
                                //if in default remove it
                                if (data.filter(network => network.name === 'Default')[0].interfaceName.split(' ').includes(value)) {
                                    data.filter(network => network.name === 'Default')[0].interfaceName =
                                        data.filter(network => network.name === 'Default')[0].interfaceName.split(' ').filter(item => item !== value).join(' ');
                                }
                                // add if bridge enabled              
                                item.interfaceName = item.interfaceName === '' ? value : item.interfaceName + ' ' + value;
                            } else {
                                // else replace it   
                                data.filter(network => network.name === 'Default')[0].interfaceName =
                                    data.filter(network => network.name === 'Default')[0].interfaceName.split(' ').filter(item => item !== value).join(' ');

                                if (!item.interfaceName.includes('.')) {

                                    data.filter(network => network.name === 'Default')[0].interfaceName =
                                        data.filter(network => network.name === 'Default')[0].interfaceName === '' ? item.interfaceName :
                                            data.filter(network => network.name === 'Default')[0].interfaceName + ' ' + item.interfaceName;
                                }
                                else
                                    customInterfaces.usedCustomInterfaces = customInterfaces.usedCustomInterfaces.filter(value => value !== item.interfaceName);

                                item.interfaceName = value;
                            }
                        }
                    });
                } else {
                    data.map((item) => {
                        if (item.name === network.name && !item.interfaceName.split(' ').includes(value)) {
                            item.interfaceName = item.interfaceName === '' ? value : item.interfaceName + ' ' + value;
                        }
                    });
                }
                template_schema_json.lanConfig = data;

            }
            this.setState({
                template_schema_json,
                selectedInterface: value,
                customInterfaces,
                customPopup: false,
                selectedNetname: network.name
            }, () => { this.getAllLists(); })
        }
    }
    getAllLists = () => {
        let availableInterfaces = [];
        switch (this.props.selectedModel) {
            case 'vCPE':
                availableInterfaces = ['eth0', 'eth1', 'eth2', 'eth3', 'eth4', 'eth5', 'eth6', 'eth7'];
                break;
            case 'Moretti_Model':
                availableInterfaces = ['eth.usb'];
                break;
            case 'Duvel_Model':
                availableInterfaces = ['eth0', 'eth.usb'];
                break;
            default:
                availableInterfaces = this.state.interfaces;
                break;
        }
        let usedList = [];
        // network template changes for CR
        let customList = this.props.selectedModel === 'vCPE' ? ['eth0', 'eth1', 'eth2', 'eth3', 'eth4', 'eth5', 'eth6', 'eth7',] : [];
        const { customInterfaces } = this.state;
        // network template changes for CR
        this.state.template_schema_json.wanConfig.filter(item => item.isdeleted !== '1').map((item) => {
            if (item.interfaceName !== '') {
                const values = item.interfaceName.split(' ');
                values.map((value) => {
                    usedList.push(value);
                })
            }
        })
        //LONG-2716-not able to configure VLAN in Device group
        let filteredList = []
        this.state.interfaces.map((item) => {
            if (!usedList.includes(item)) {
                filteredList.push(item)
            }
        })
        customList = filteredList
        //LONG-2537-EM - Able to configure VLAN for WAN network

        this.state.template_schema_json.lanConfig.filter(item => item.isdeleted !== '1').map((item) => {
            if (item.name !== 'Default' && item.interfaceName !== ''
            ) {
                const values = item.interfaceName.split(' ');
                values.map((value) => {
                    usedList.push(value);
                })
            }
        });
        this.state.interfaces.map((item) => {
            if (usedList.includes(item)) {
                availableInterfaces = availableInterfaces.filter(value => value !== item)
            }
        })
        //long- 1539 -MA - [Network Template] - Interface is getting removed after mapping tagged interface to a network
        usedList.length > 0 && usedList.map((item) => {
            if (item.includes('.')) {
                customInterfaces.usedCustomInterfaces.push(item);
            }
        })

        this.setState({
            availableInterfaces,
            usedList,
            customList
        })
    }
    checkBridgeStatus = (network) => {
        let { customInterfaces } = this.state
        customInterfaces.interfaceName = ''
        customInterfaces.vlanID = ''
        this.setState({
            customInterfaces
        })
        if (network.bridgeStatus === '0' && network.interfaceName !== '') {
            $("#exampleModalNetwork").modal("hide");
            this.setState({
                customPopup: false,
                title: 'Custom Interface',
                message: 'This network doesn\'t have bridge enabled. Please remove existing network or enable bridge interface.',
                show: true,
                error: true,
                hide: false
            }, () => {
                $("#errorMessage").modal("show")
            });
        } else {
            $("#exampleModalNetwork").modal("show");
            this.setState({
                customNetwork: network,
                customPopup: true
            });
        }
    }
    setInterface = (e) => {
        let { customInterfaces } = this.state;
        if (e.target.name === "vlanID" && (/^[0-9]*$/.test(e.target.value))) {
            const element = document.getElementById(e.target.name);
            element.style.display = 'none';
            this.setState({
                showCusIntError: false
            })
            customInterfaces[e.target.name] = e.target.value;
            this.setState({
                customInterfaces
            })
        }
        if (e.target.name === "interfaceName") {
            customInterfaces[e.target.name] = e.target.value;
            this.setState({
                customInterfaces
            })
        }
    }
    checkInterface = (e) => {
        if (e.target.name === "vlanID") {
            if (parseInt(e.target.value) < 1 || parseInt(e.target.value) > 4094) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({
                    showCusIntError: true
                })
            }
            else {
                const element = document.getElementById(e.target.name);
                element.style.display = 'none';
                this.setState({
                    showCusIntError: false
                })
            }
        }
    }
    addCustomInterface = () => {
        let { customInterfaces } = this.state;
        const value = customInterfaces.interfaceName + '.' + customInterfaces.vlanID;
        let found = false;
        customInterfaces.usedCustomInterfaces.map((item) => {
            if (value === item)
                found = true;
        });
        if (!found) {
            customInterfaces.usedCustomInterfaces.push(value);
            customInterfaces.interfaceName = '';
            customInterfaces.vlanID = '';
            let { template_schema_json } = this.state;
            const data = this.state.customNetwork.zone === 'wan' ? template_schema_json.wanConfig :
                template_schema_json.lanConfig;
            data.map((network) => {
                if (network.name === this.state.customNetwork.name) {
                    network.interfaceName = network.interfaceName === '' ? value : network.interfaceName + ' ' + value;
                }
                return network;
            });
            if (this.state.customNetwork.zone === 'wan')
                template_schema_json.wanConfig = data;
            else
                template_schema_json.lanConfig = data;
            this.setState({
                template_schema_json,
                customInterfaces,
                customPopup: false
            }, () => {
                $("#exampleModalNetwork").modal("hide")
            })
        } else {
            this.setState({
                title: 'Custom Interface',
                message: 'This interface is already used',
                show: true,
                error: true,
                hide: false
            }, () => $("#errorMessage").modal("show"));
        }
    }
    checkApplyError = () => {
        let error = false;
        if (this.props.applying) {
            error = true;
        }
        // network template changes for CR
        if (this.props.selectedModel !== 'Moretti_Model' && this.props.selectedModel !== 'Duvel_Model' &&
            this.props.selectedModel !== 'vCPE'
        ) {
            this.state.template_schema_json.wanConfig.map((network) => {
                if (network.interfaceName === '' && network.isdeleted === '0')
                    error = true;
            });
            this.state.template_schema_json.lanConfig.map((network) => {
                if (network.protocol === 'dhcpclient' && network.interfaceName === '' && network.isdeleted === '0' &&
                    this.props.selectedModel !== 'vCPE')
                    error = true;
            });
        }
        // LONG-3181 : User can able to apply network template with WAN interface none for default WAN network
        this.state.template_schema_json.wanConfig.map((network) => {
            if (network.isdeleted === '0' && network.interfaceName === '') {
                error = true;
            }
        })
        return error;
    }
    // Long - 1539 -MA - [Network Template] - Interface is getting removed after mapping tagged interface to a network 
    checkWanInterface = (value) => {
        let found = false;
        if (this.state.customInterfaces.usedCustomInterfaces.length === 0) {
            found = true;
        } else {
            for (const item of this.state.customInterfaces.usedCustomInterfaces) {
                const test = item.split('.')[0];
                if (test === value) {
                    found = false;
                    break;
                } else found = true;
            }
        }
        return found;
    }

    checkDHCPInterface = (availableInterface, network) => {
        let found = true;
        this.state.template_schema_json.wanConfig.map((item) => {
            if (item.isdeleted !== '1') {
                // all interfaces assigned to the network
                const interfaces = item.interfaceName.split(' ');
                interfaces.map((addedValue) => {
                    // check if custom interface is assigned to the same network
                    if (addedValue === availableInterface) {
                        if (item.protocol === 'dhcpclient') {
                            found = false;
                        }
                    }
                })
            }
        })
        return found;
    }
    closeAll = () => {
        //LONG-3014-EM - Issue in checkbox enable/disable case.
        if (this.props.template_schema_json !== undefined) {
            this.setState({
                template_schema_json: this.props.template_schema_json,
                applied: false,
                toggledClearRows: '',
                selectedNetwork: []
            })
        }
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.props.closeAddTemplate()
    }

    checkEnabled = () => {
        let result = true;
        if (this.state.selectedNetwork.length > 0) {

            const networks = this.state.selectedNetwork.filter(item => item.name === 'Default' || item.name === 'Cellular' ||
                item.name === 'Ethernet WAN');
            if (networks.length > 0) {
                result = false;
            } else {
                if (this.props.templateData.default_type === ''
                    || this.props.templateData.default_type === null)
                    result = true;
                else
                    result = false;
            }
            return result;
        }
    }
    //LONG-3014: EM - Issue in checkbox enable/disable case.
    selectedTabChange = (name) => {
        (name === 'Network') ? this.setState({ selectedNetwork: [] }) : this.setState({ toggledClearRows: [], });
    }

    render() {
        return (
            <div className="tab-box">
                {this.state.showLoader && <Loader />}
                <div className={this.state.openAddNetwork || this.state.openReservation ? "disabled-slide" : ""}></div>
                <ul className="tab-nav nav nav-pills bg-gray" id="firewallTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="Network-tab" data-toggle="tab" href="#Network" role="tab" aria-controls="Network" aria-selected="true" onClick={() => this.selectedTabChange('Network')}>Network</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="s-dhcp-tab" data-toggle="tab" href="#s-dhcp" role="tab" aria-controls="s-dhcp" aria-selected="false" onClick={() => this.selectedTabChange('s-dhcp')}>Static DHCP</a>
                    </li>
                </ul>

                <div className="tab-content mt-2">
                    <div className="tab-pane active" id="Network" role="tabpanel" aria-labelledby="Network-tab">
                        <div className="page-sub-title border-0 pb-0">
                            <div><strong>Network</strong>
                                {!this.props.info && !this.props.view && !this.props.reapply &&
                                    <img src="/images/svg-icons/add.svg"
                                        className={(this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model') || (this.props.templateData.default_type !== ''
                                            && this.props.templateData.default_type !== null) ?
                                            "add-btn ml-2 disabled" : "add-btn ml-2"} alt=""
                                        onClick={(this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model') || (this.props.templateData.default_type !== ''
                                            && this.props.templateData.default_type !== null) ?
                                            undefined : () => this.openAddNetworkData()} />}
                            </div>
                            {!this.props.info && !this.props.view && !this.props.reapply && <div className="device-action">
                                <span className={(this.state.selectedNetwork.length === 1) ?
                                    "icon-box" : "icon-box disabled"}><img src="/images/svg-icons/edit.svg" alt=""
                                        onClick={(this.state.selectedNetwork.length === 1) ? () =>
                                            this.openAddNetworkData(this.state.selectedNetwork[0])
                                            : undefined} /></span>
                                <span className={this.checkEnabled() ?
                                    "icon-box" : "icon-box disabled"}><img src="/images/svg-icons/delete.svg" alt=""
                                        onClick={this.checkEnabled() ?
                                            () => this.deleteNetwork() : undefined} /></span>
                            </div>}
                        </div>
                        <div className="gray-bg rounded p-3 mt-2">
                            <div className="">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {!this.props.info && !this.props.view && !this.props.reapply && <th>
                                            </th>}
                                            <th>Name</th>
                                            {this.props.apply && <th>Interface Name</th>}
                                            <th>Zone</th>
                                            <th>Bridge</th>
                                            <th>Loopback</th>
                                            <th>Protocol</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.template_schema_json.lanConfig.map((network) => {
                                            return (network.isdeleted !== '1' &&
                                                <tr className={((this.props.apply) &&
                                                    (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model') &&
                                                    network.name !== 'Default') ? "disabled" : ''}>
                                                    {(!this.props.info && !this.props.view && !this.props.reapply) && <td>
                                                        <input type="checkbox" checked={this.state.selectedNetwork.filter(item => (item.id === network.id)).length > 0} onClick={(e) => this.selectNetwork(e, network)} /></td>}
                                                    <td key={this.state.keyUpdate}><span title={network.name} style={{ width: "auto" }} className={(this.props.edit) ? "cursor-pointer" : ''}
                                                    // LONG-1657-Hyperlink issue fixed
                                                    >{(!this.props.info && !this.props.view && !this.props.reapply) ? <Link to={{}} className="txt-blue" onClick={() => this.openAddNetworkData(network)} >{" "}{network.name}
                                                    </Link> : network.name}</span></td>
                                                    {/* Long-1579 mifi usb changes */}
                                                    {(this.props.apply && (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model')) ? <td>
                                                        {!this.props.info && !this.props.view && !this.props.reapply &&
                                                            <div className="dropdown c-dropdown mb-1">
                                                                <div className="db-value" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    Interface Name
                                                                </div>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    <ul>
                                                                        {network.name === "Default" ?
                                                                            this.state.availableInterfaces.map((item) => {
                                                                                return (!network.interfaceName.split(' ').includes(item) && <li><input id={item} type="checkbox"
                                                                                    checked={network.interfaceName.split(' ').includes(item)}
                                                                                    onChange={(e) => this.addInterface(e, item, network)} />
                                                                                    {item}</li>)
                                                                            })
                                                                            : this.state.availableInterfaces.map((item) => {
                                                                                return (<li><input id={item} type="checkbox"
                                                                                    checked={network.interfaceName.split(' ').includes(item)}
                                                                                    onChange={(e) => this.addInterface(e, item, network)} />
                                                                                    {item}</li>)
                                                                            })}
                                                                    </ul>
                                                                </div>
                                                            </div>}
                                                        <div className="gray-bg rounded p-2">
                                                            <ul className="chip-list w-100">
                                                                {network.interfaceName.split(' ').length > 0 && network.interfaceName.split(' ').map((item) => {
                                                                    if (item) {
                                                                        return !this.props.info && !this.props.view && !this.props.reapply ? <li>{item}{item === 'eth.usb' && <img src="/images/svg-icons/cancel.svg" alt=""
                                                                            onClick={() => { this.removePort(item, network) }} />}</li> :
                                                                            <li>{item}</li>
                                                                    }
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                        :
                                                        this.props.apply && <td>
                                                            {!this.props.info && !this.props.view && !this.props.reapply &&
                                                                <div className={network.is_loopback === "1" ? "dropdown c-dropdown mb-1 disabled" : "dropdown c-dropdown mb-1"}>
                                                                    <div className="db-value" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        Interface Name
                                                                    </div>
                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                        <ul>
                                                                            {network.name === "Default" ?
                                                                                this.state.availableInterfaces.map((item) => {
                                                                                    return (!network.interfaceName.split(' ').includes(item) && <li><input id={item} type="checkbox"

                                                                                        onChange={(e) => this.addInterface(e, item, network)} />
                                                                                        {item}</li>)
                                                                                })
                                                                                : this.state.availableInterfaces.map((item) => {
                                                                                    return (<li><input id={item} type="checkbox"
                                                                                        checked={this.state.selectedInterface === item && this.state.selectedNetname === network.name}
                                                                                        onChange={(e) => this.addInterface(e, item, network)} />
                                                                                        {item}</li>)
                                                                                })}
                                                                            {(this.props.apply &&
                                                                                (this.props.selectedModel === 'CHIMAY' || this.props.selectedModel === 'vCPE')) && <li
                                                                                    data-toggle="modal"
                                                                                    data-target="#exampleModalNetwork"
                                                                                    onClick={() => this.checkBridgeStatus(network)}
                                                                                    className="btn btn-primary btn-block mt-3">
                                                                                    + Custom Interface</li>}
                                                                        </ul>
                                                                    </div>
                                                                </div>}
                                                            <div className="gray-bg rounded p-2">
                                                                <ul className="chip-list w-100">
                                                                    {network.interfaceName.split(' ').length > 0 && network.interfaceName.split(' ').map((item) => {
                                                                        if (item) {
                                                                            return !this.props.info && !this.props.view && !this.props.reapply ? <li>{item}{<img src="/images/svg-icons/cancel.svg" alt=""
                                                                                onClick={() => { this.removePort(item, network) }} />}</li> :
                                                                                <li>{item}</li>
                                                                        }
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </td>}
                                                    <td>LAN</td>
                                                    <td>
                                                        <label className="switch gray"><input type="checkbox"
                                                            checked={network.bridgeStatus === '1'} />
                                                            <span className="slider round"></span></label>
                                                    </td>
                                                    <td>
                                                        <label className="switch gray"><input type="checkbox"
                                                            checked={network.is_loopback === '1'} />
                                                            <span className="slider round"></span></label>
                                                    </td>
                                                    <td>{network.protocol === 'staticAddr' ? "Static Address" :
                                                        // network.protocol !== "ippt" ? 
                                                        "DHCP Client"
                                                        // : "IP Passthrough"
                                                    }</td>
                                                    {/* {network.protocol !== "ippt" && */}
                                                    <td>
                                                        <div className="sa-box">
                                                            <ul>
                                                                <li>
                                                                    <span>MTU</span>
                                                                    <span>{network.mtu}</span>
                                                                </li></ul>
                                                        </div>
                                                        {network.protocol !== 'dhcpclient' && <div className="sa-box">
                                                            <ul>
                                                                <li>
                                                                    <span>IP Address/Subnet</span>
                                                                    <span>{network.ipAddress}/{network.subnet}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Netmask</span>
                                                                    <span>{network.netmask}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Gateway</span>
                                                                    <span>{network.gateway ? network.gateway : '--'}</span>
                                                                </li>
                                                                {network.zone === 'wan' && <li>
                                                                    <span>DNS Address</span>
                                                                    <span>
                                                                        {Array.isArray(network?.dnsAddress)
                                                                            ? network?.dnsAddress?.length === 2
                                                                                ? network?.dnsAddress?.[0] + "," + network.dnsAddress?.[1]
                                                                                : network?.dnsAddress.length === 1
                                                                                    ? network?.dnsAddress?.[0]
                                                                                    : "--"
                                                                            : "--"}
                                                                    </span>
                                                                </li>}
                                                                <li>
                                                                    <span>No. of Subnets</span>
                                                                    <span>{network.no_of_subnets ? network.no_of_subnets : '--'}</span>
                                                                </li>
                                                            </ul>
                                                            <div className="gray-bg">
                                                                <ul>
                                                                    <li>
                                                                        <span>DHCP Server</span>
                                                                        <label className="switch gray"><input type="checkbox" checked={network.dhcpServer === '1'} /><span className="slider round"></span></label>
                                                                    </li>
                                                                    {network.dhcpServer === '1' && <> <li>
                                                                        <span>Start Address</span>
                                                                        <span>{network.startAddress}</span>
                                                                    </li>
                                                                        <li>
                                                                            <span>End Address</span>
                                                                            <span>{network.endAddress}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span>Lease Time</span>
                                                                            <span>{network.leaseTime} mins</span>
                                                                        </li>
                                                                        <li>
                                                                            <span>DNS Server</span>
                                                                            {/* LONG-1793-DNS option is showing not properly devic template page */}
                                                                            <span>{network.dnsServer === 'useDNSasBelow' ? 'Use DNS as Below' : 'Use DNS From ISP'}</span>
                                                                        </li></>}
                                                                    {network.dhcpServer === '1' && network.dnsServer === 'useDNSasBelow' && <> <li>
                                                                        <span>DNS Address1</span>
                                                                        <span>{network.dns1 ? network.dns1 : '--'}</span>
                                                                    </li>
                                                                        <li>
                                                                            <span>DNS Address2</span>
                                                                            <span>{network.dns2 ? network.dns2 : '--'}</span>
                                                                        </li></>}
                                                                </ul>
                                                            </div>
                                                        </div>}
                                                    </td>
                                                    {/* } */}
                                                    {/* {
                                                        network.protocol === "ippt" && <td> <div className="sa-box">
                                                            <ul>
                                                                <li>
                                                                    <span>Static Route Destination IP</span>
                                                                    <span>{network.staticRouteDestinationIP}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Netmask</span>
                                                                    <span>{network.netmask}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Gateway</span>
                                                                    <span>{network.gateway}</span>
                                                                </li>
                                                                <li>
                                                                    <span>DNS Server</span>
                                                                    <span>Use DNS as Below</span>
                                                                </li>
                                                                <li>
                                                                    <span>Lease Time</span>
                                                                    <span>{network.leaseTime} mins</span>
                                                                </li>
                                                                <li>
                                                                    <span>Masquerade</span>
                                                                    <label className="switch gray"><input type="checkbox" checked={network.masquerade === '1'} /><span className="slider round" style={{ cursor: "not-allowed" }}></span></label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        </td>
                                                    } */}
                                                </tr>)

                                        })}
                                        {this.state.template_schema_json.wanConfig.map((network) => {
                                            // network template changes for CR
                                            return (network.isdeleted !== '1' && network.name !== 'Cellular' && <tr className={(network.name === 'Cellular' ||
                                                (this.props.apply && (this.props.selectedModel === 'Moretti_Model' ||
                                                    this.props.selectedModel === 'Duvel_Model'))) ? "disabled" : ''}>

                                                {(!this.props.info && !this.props.view && !this.props.reapply) && <td><input type="checkbox"
                                                    checked={this.state.selectedNetwork.filter(item => (item.id === network.id)).length > 0} disabled={network.name === 'Cellular' || (this.props.selectedModel === 'Moretti_Model' ||
                                                        this.props.selectedModel === 'Duvel_Model')}
                                                    onClick={network.name !== 'Cellular' || (this.props.selectedModel === 'Moretti_Model' ||
                                                        this.props.selectedModel === 'Duvel_Model') ?
                                                        (e) => this.selectNetwork(e, network) : undefined} /></td>}

                                                <td><span title={network.name} style={{ width: "auto" }} className={''}
                                                >{(network.name !== 'Cellular' && !this.props.info && !this.props.view && !this.props.reapply) ? <Link to={{}} className="txt-blue" onClick={() => this.openAddNetworkData(network)} >{" "}{network.name}
                                                </Link> : network.name}</span>
                                                </td>
                                                {(network.name === 'Cellular' ||
                                                    (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model')
                                                ) && this.props.apply ? <td>{network.interfaceName}</td> :
                                                    this.props.apply && <td>
                                                        {/* LOng 1882 MA - Able to map the interface For Restricted Network Template Configuration */}
                                                        {!this.props.info && !this.props.view && !this.props.reapply && <div className={(this.props.apply &&
                                                            network.name === 'Cellular')
                                                            ? "dropdown c-dropdown mb-1 disabled" : "dropdown c-dropdown mb-1"}>
                                                            <div className="db-value" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Interface Name
                                                            </div>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <ul>
                                                                    {this.state.availableInterfaces.map((item) => {
                                                                        if (this.checkWanInterface(item)) { //long- 1539 -MA - [Network Template] - Interface is getting removed after mapping tagged interface to a network
                                                                            return (<li><input id={item} type="checkbox"
                                                                                checked={this.state.selectedInterface === item && this.state.selectedNetname === network.name}
                                                                                onChange={(e) => this.addInterface(e, item, network)} />
                                                                                {item}</li>)
                                                                        }
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>}
                                                        <div className="gray-bg rounded p-2">
                                                            <ul className="chip-list w-100">
                                                                {network.interfaceName.split(' ').length > 0 && network.interfaceName.split(' ').map((item) => {
                                                                    if (item) {
                                                                        return !this.props.info && !this.props.view && !this.props.reapply ? <li>{item}
                                                                            {(network.bridgeStatus === '1' && network.interfaceName.split(' ').length > 1)
                                                                                // LONG-3181 : User can able to apply network template with WAN interface none for default WAN network
                                                                                || network.name !== 'Ethernet WAN' ? <img src="/images/svg-icons/cancel.svg" alt=""
                                                                                    onClick={() => { this.removePort(item, network) }} /> : ""}
                                                                        </li> : <li> {item} </li>
                                                                    }
                                                                })}

                                                            </ul>
                                                        </div>
                                                    </td>}
                                                <td>WAN</td>
                                                <td>
                                                    <label className="switch gray"><input type="checkbox" checked={network.bridgeStatus === '1'} /><span className="slider round"></span></label>
                                                </td>
                                                <td>
                                                    <label className="switch gray"><input type="checkbox" checked={network.is_loopback === '1'} />
                                                        <span className="slider round"></span></label>
                                                </td>
                                                <td>{network.protocol === 'staticAddr' ? "Static Address" :
                                                    //  (network.protocol !== "ippt" ? 
                                                    "DHCP Client"
                                                    //  : "IP Passthrough")
                                                } </td>
                                                {/* {network.protocol !== "ippt" && */}
                                                <td>
                                                    <div className="sa-box">
                                                        <ul>  <li>
                                                            <span>MTU</span>
                                                            <span>{network.mtu}</span>
                                                        </li></ul></div>
                                                    {network.protocol !== 'dhcpclient' && <div className="sa-box">
                                                        <ul>
                                                            <li>
                                                                <span>IP Address/Subnet</span>
                                                                <span>{network.ipAddress ? `${network.ipAddress}/${network.subnet}` : '--'}</span>
                                                            </li>
                                                            <li>
                                                                <span>Netmask</span>
                                                                <span>{network.netmask ? network.netmask : '--'}</span>
                                                            </li>
                                                            <li>
                                                                <span>Gateway</span>
                                                                <span>{network.gateway ? network.gateway : '--'}</span>
                                                            </li>
                                                            {network.zone === 'wan' && <li>
                                                                <span>DNS Address</span>
                                                                <span>
                                                                    {Array.isArray(network?.dnsAddress)
                                                                        ? network?.dnsAddress?.length === 2
                                                                            ? network?.dnsAddress?.[0] + "," + network.dnsAddress?.[1]
                                                                            : network?.dnsAddress.length === 1
                                                                                ? network?.dnsAddress?.[0]
                                                                                : "--"
                                                                        : "--"}
                                                                </span>
                                                            </li>}
                                                        </ul>
                                                    </div>}
                                                </td>
                                                {/* }
                                                {
                                                    network.protocol === "ippt" && <td> <div className="sa-box">
                                                        <ul>
                                                            <li>
                                                                <span>Static Route Destination IP</span>
                                                                <span>{network.staticRouteDestinationIP}</span>
                                                            </li>
                                                            <li>
                                                                <span>Netmask</span>
                                                                <span>{network.netmask}</span>
                                                            </li>
                                                            <li>
                                                                <span>Gateway</span>
                                                                <span>{network.gateway}</span>
                                                            </li>
                                                            <li>
                                                                <span>DNS Server</span>
                                                                <span>Use DNS as Below</span>
                                                            </li>
                                                            <li>
                                                                <span>Lease Time</span>
                                                                <span>{network.leaseTime} mins</span>
                                                            </li>
                                                            <li>
                                                                <span>Masquerade</span>
                                                                <label className="switch gray"><input type="checkbox" checked={network.masquerade === '1'} /><span className="slider round" style={{ cursor: "not-allowed" }}></span></label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    </td>
                                                } */}
                                            </tr>)
                                        })}
                                        {/* modal popup */}
                                        {this.state.customPopup &&
                                            <div className="modal fade" id="exampleModalNetwork" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="exampleModalLabel">New Interface Name</h5>
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({ customPopup: false })}>
                                                                <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="form-group mb-1">
                                                                <label>Interface Name</label>
                                                                <div className="d-flex align-items-center">
                                                                    <select className="form-control"
                                                                        name="interfaceName"
                                                                        onChange={(e) => this.setInterface(e)}
                                                                        value={this.state.customInterfaces.interfaceName}>
                                                                        <option value="">Select Interface</option>
                                                                        {this.state.customList.map((item) => {
                                                                            if (item && this.checkDHCPInterface(item, this.state.customNetwork))
                                                                                return <option value={item}>{item}</option>
                                                                        })}
                                                                    </select>
                                                                    <i className="dot"></i>
                                                                    <input type="text"
                                                                        className="form-control w-40"
                                                                        name="vlanID"
                                                                        value={this.state.customInterfaces.vlanID}
                                                                        onChange={(e) => this.setInterface(e)}
                                                                        onBlur={(e) => this.checkInterface(e)} />
                                                                </div>
                                                            </div>
                                                            <p>Create custom virtual interface for VLAN ex: ethx.yz</p>
                                                            <div className="error-txt" id="vlanID">VLAN must be between 1 to 4094</div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-light" data-dismiss="modal" onClick={() => this.setState({ customPopup: false })}>Cancel</button>
                                                            <button type="button" className="btn btn-primary" data-dismiss="modal"
                                                                disabled={this.state.customInterfaces.interfaceName === '' || this.state.customInterfaces.vlanID === '' || this.state.showCusIntError}
                                                                onClick={this.addCustomInterface}>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        {/* modal popup */}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* second slide Add new Lan */}
                        <div className={this.state.openAddNetwork ? "sidebar-slide w-350 r-757 second-open" : "sidebar-slide w-350 r-757"}>
                            <div className="s-header">
                                <h1 className="ss-title">{this.state.update ? 'Update LAN' : 'Add New LAN'}</h1>
                                <button>
                                    <img src="/images/svg-icons/s-cancel.svg" alt=""
                                        onClick={() => this.closeAddNetworkData()}
                                    />
                                </button>
                            </div>
                            <div className="s-body">
                                <div className="form-group">
                                    <label>Network Name
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Display name"><img src="/images/svg-icons/info.svg" alt="" /></div>
                                    </label>
                                    <input type="text" className="form-control"
                                        name="name"
                                        onChange={this.setNetworkData}
                                        disabled={this.state.networkData.id ===
                                            this.state.template_schema_json.lanConfig.filter(item => item.name === 'Default')[0].id ||
                                            this.state.networkData.id ===
                                            this.state.template_schema_json.wanConfig.filter(item => item.name === 'Ethernet WAN')[0].id}
                                        minLength='1'
                                        maxLength='32'
                                        onBlur={(e) => this.checkNetworkName(e)}
                                        value={this.state.networkData.name} />
                                    <div className="error-txt" id="name">Invalid value</div>
                                </div>
                                <div className="form-group">
                                    <label>Protocol
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Specifies the interface protocol">
                                            <img src="/images/svg-icons/info.svg" alt="" /></div>
                                    </label>
                                    <select className="form-control" name='protocol'
                                        onChange={this.setNetworkData}
                                        value={this.state.networkData.protocol}>
                                        <option value='staticAddr'>Static Address</option>
                                        {this.state.networkData.name !== 'Default' && <option value='dhcpclient'>DHCP Client</option>}
                                        {/* {this.state.networkData.zone !== 'wan' && <option value='ippt'>IP Passthrough</option>}                                     */}
                                    </select>
                                </div>
                                {/* {this.state.networkData.protocol !== 'ippt' && <> */}
                                {this.state.networkData.protocol === 'staticAddr' &&
                                    <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                                        <span className="mr-5">Create Loopback alias
                                            <span className="red-txt">*</span>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable toggle bar to create loopback alias interface">
                                                <img src="/images/svg-icons/info.svg" alt="" /></div></span>
                                        <label className="switch">
                                            <input type="checkbox"
                                                name='is_loopback'
                                                onChange={this.setNetworkData}
                                                disabled={this.state.networkData.name === 'Default' || this.state.networkData.zone === 'wan' ||
                                                    this.state.networkData.name === 'Ethernet WAN' || this.state.networkData.name === 'Guest'}
                                                checked={this.state.networkData.is_loopback === '1'} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                }
                                <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                                    <span className="mr-5">Create Bridge Interface
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Creates a bridge containing the given interfaces">
                                            <img src="/images/svg-icons/info.svg" alt="" /></div></span>
                                    <label className="switch">
                                        <input type="checkbox"
                                            name='bridgeStatus'
                                            onChange={this.setNetworkData}
                                            disabled={this.state.networkData.name === 'Default' || this.state.networkData.is_loopback === '1'}
                                            checked={this.state.networkData.bridgeStatus === '1'} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                {this?.props?.group_id && this.state.networkData.name === 'Default' && (this.props.apply === true ||
                                    (this.props.reapply === true && this.props.edit === true)) &&
                                    <>
                                        <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                                            <span className="mr-5">Incremental IP
                                            </span>
                                            <label className="switch">
                                                <input type="checkbox"
                                                    name='is_auto_increment'
                                                    onChange={this.setNetworkData}
                                                    checked={this.state.networkData.is_auto_increment === '1'}
                                                    //LONG-3103:Disable Auto Increment field in apply Network Template flow on device
                                                    disabled={!(this.props.group_id && this.props.group_id !== '')}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        {this.state.networkData.is_auto_increment === "1" &&
                                            <>
                                                <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                                                    <span className="mr-5">Number of Subnets
                                                    </span>
                                                    <label>
                                                        <input type="number"
                                                            name='no_of_subnets'
                                                            className="form-control"
                                                            onChange={this.setNetworkData}
                                                            min="0"
                                                            max="200"
                                                            value={parseInt(this.state.networkData.no_of_subnets)}
                                                        />
                                                    </label>
                                                </div>
                                                <div className="error-txt lan" id="no_of_subnets">Invalid value</div>
                                            </>
                                        }
                                    </>
                                }
                                {this.state.networkData.protocol === 'staticAddr' && <> <div className="form-group"
                                >
                                    <label>IP Address/Subnet
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Address for the specific interface">
                                            <img src="/images/svg-icons/info.svg" alt="" /></div>
                                    </label>
                                    <div className="d-flex align-items-center justify-content-between ip-address">
                                        <input type="text" className="form-control"
                                            name="ipAddress"
                                            onChange={this.setNetworkData}
                                            onBlur={(e) => this.validateIP(e)}
                                            value={this.state.networkData.ipAddress} />
                                        <i>/</i>
                                        <input
                                            name="subnet" className="form-control"
                                            onChange={this.setNetworkData}
                                            onBlur={() => this.getNetmask()}
                                            value={this.state.networkData.subnet} />
                                    </div>
                                    <div className="error-txt lan ip" id="ipAddress" >Invalid IP</div>
                                    <div className="error-txt lan ip" id="subnet" >Invalid subnet</div>
                                </div>
                                    <div className="form-group">
                                        <label>Netmask
                                            <span className="red-txt">*</span>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Specifies the interface netmask">
                                                <img src="/images/svg-icons/info.svg" alt="" /></div>
                                        </label>
                                        <input type="text"
                                            className="form-control"
                                            name="netmask"
                                            value={this.state.networkData.netmask}
                                            onChange={this.setNetworkData}
                                            disabled
                                            onBlur={() => this.getNetmask()}
                                            readOnly={this.props.view || this.props.apply || this.props.reapply}
                                        />
                                        <div className="error-txt lan" id="netmask">Invalid value</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Gateway
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Passage between two networks">
                                                <img src="/images/svg-icons/info.svg" alt="" /></div>
                                        </label>
                                        <input type="text"
                                            className="form-control"
                                            name="gateway"
                                            value={this.state.networkData.gateway}
                                            onChange={this.setNetworkData}
                                            onBlur={(e) => this.validateIP(e)}
                                            readOnly={(this.state.networkData.protocol === 'staticAddr' &&
                                                this.state.networkData.is_loopback === '1')}
                                        />
                                        <div className="error-txt lan" id="gateway">Invalid value</div>
                                    </div>
                                    {this.state.networkData.zone === "wan" && <div className="form-group">
                                        <label>DNS Address
                                            <span className="red-txt">*</span>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Internet's primary address book">
                                                <img src="/images/svg-icons/info.svg" alt="" /></div>
                                        </label>
                                        <div className="chip-list-border cp">
                                            {this.state.networkData.dnsAddress && Array.isArray(this.state.networkData.dnsAddress) && this.state.networkData.dnsAddress.map((item, index) => {
                                                return (
                                                    (item !== null && item !== 'null' && item !== '') && <div className="tag-item" key={item}
                                                    >{item}
                                                        <button
                                                            type="button"
                                                            className="button"
                                                            onClick={() => this.handleDNSAddress(item)}
                                                        >
                                                            <img src="/images/svg-icons/cancel.svg" alt="" />
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                            <textarea type="text"
                                                name="dnsAddress"
                                                onKeyDown={(e) => this.handleDNSKeyDown(e)}
                                                onBlur={(e) => { this.handleDNSKeyDown(e); this.validateDNS(e) }}
                                                autoComplete="off"
                                            />
                                        </div><div className="error-txt lan" id="dnsAddress" >Valid IP address/subnet is required</div>
                                    </div>}
                                </>}
                                <div className="form-group">
                                    <label className="mr-5">MTU
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Largest size of a single data packet that can be transmitted over a network">
                                            <img src="/images/svg-icons/info.svg" alt="" /></div></label>

                                    <input type="text"
                                        className="form-control"
                                        name='mtu'
                                        onChange={this.setNetworkData}
                                        value={this.state.networkData.mtu} />
                                    <span className="units">bytes</span>
                                    <div className="error-txt lan" id="mtu">Value must be between 576 to 1500 bytes</div>
                                </div>
                                {this.state.networkData.protocol === 'staticAddr' && <div className="gray-bg rounded p-3">
                                    {this.state.networkData.protocol === 'staticAddr' &&
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <span className="mr-5"><strong>DHCP Server</strong><div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Need DHCP server?"><img src="/images/svg-icons/info.svg" alt="" /></div></span>
                                            <label className="switch">
                                                <input type="checkbox"
                                                    name='dhcpServer'
                                                    onChange={this.setNetworkData}
                                                    disabled={(this.state.networkData.protocol === 'staticAddr' &&
                                                        this.state.networkData.is_loopback === '1') || this.state.networkData.zone === 'wan'}
                                                    checked={this.state.networkData.dhcpServer === '1'} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    }
                                    {this.state.networkData.protocol === 'staticAddr' && this.state.networkData.dhcpServer === '1' &&
                                        <div>
                                            <div className="form-group">
                                                <label>Start Address
                                                    <span className="red-txt">*</span>
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Starting IP address">
                                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    readOnly={this.props.view || this.props.reapply}
                                                    onBlur={(e) => this.validateIP(e)}
                                                    onChange={this.setNetworkData}
                                                    name="startAddress"
                                                    value={this.state.networkData.startAddress}
                                                />
                                                <div className="error-txt lan" id="startAddress">Invalid value</div>
                                            </div>
                                            <div className="form-group">
                                                <label>End Address
                                                    <span className="red-txt">*</span>
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Ending IP address"><img src="/images/svg-icons/info.svg" alt="" /></div>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={this.setNetworkData}
                                                    readOnly={this.props.view || this.props.reapply}
                                                    onBlur={(e) => this.validateIP(e)}
                                                    name="endAddress"
                                                    value={this.state.networkData.endAddress} />
                                                <div className="error-txt lan" id="endAddress">Invalid value</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Lease Time
                                                    <span className="red-txt">*</span>
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Lease time of addresses handed out to clients">
                                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                                </label>
                                                <input type="number" className="form-control" name="leaseTime"
                                                    onChange={this.setNetworkData}
                                                    min='2'
                                                    max='2880'
                                                    value={parseInt(this.state.networkData.leaseTime)} />
                                                <div className="error-txt lan" id="leaseTime">Invalid value</div>
                                                <span className="units">Minutes</span>
                                            </div>
                                        </div>
                                    }
                                    {this.state.networkData.protocol === 'staticAddr' && this.state.networkData.dhcpServer === '1' &&
                                        <div className="form-group mb-0">
                                            <label>DNS Server
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Select the DNS option">
                                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                                            </label>
                                            <select className="form-control" name='dnsServer'
                                                onChange={this.setNetworkData}
                                                value={this.state.networkData.dnsServer}>
                                                <option value='useDNSFromISP'>Use DNS From ISP</option>
                                                <option value='useDNSasBelow'>Use DNS as Below</option>
                                            </select>
                                        </div>
                                    }
                                    {this.state.networkData.protocol === 'staticAddr' && this.state.networkData.dhcpServer === '1' &&
                                        this.state.networkData.dnsServer === 'useDNSasBelow' &&
                                        <div>
                                            <div className="form-group mt-3">
                                                <label>Static DNS 1
                                                    <span className="red-txt">*</span>
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Internet's primary address book in DHCP">
                                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                                </label>
                                                <input type="text" className="form-control"
                                                    onChange={this.setNetworkData}
                                                    name='dns1'
                                                    onBlur={(e) => this.validateIP(e)}
                                                    value={this.state.networkData.dns1} />
                                                <div className="error-txt lan dns" id="dns1">Invalid value</div>
                                            </div>
                                            <div className="form-group mb-0">
                                                <label>Static DNS 2
                                                    <span className="red-txt">*</span>
                                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Internet's primary address book in DHCP">
                                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                                </label>
                                                <input type="text" className="form-control"
                                                    onChange={this.setNetworkData}
                                                    name='dns2'
                                                    onBlur={(e) => this.validateIP(e)}
                                                    value={this.state.networkData.dns2} />
                                                <div className="error-txt lan dns" id="dns2">Invalid value</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                }
                                <div className="form-group mt-2">
                                    <label>Type
                                        {/* LONG-2930- EM - Front-end Issues 2,WAN info icon content need to change  */}
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Mapping interfaces into wan zone">
                                            <img src="/images/svg-icons/info.svg" alt="" /></div>
                                    </label>
                                    <div>
                                        <input type="checkbox" id="zonename" name="zone"
                                            onChange={this.setNetworkData}
                                            disabled={this.state.networkData.name === 'Default' ||
                                                this.state.networkData.name === 'Ethernet WAN' ||
                                                this.state.networkData.is_loopback === '1'}
                                            checked={this.state.networkData.zone === 'wan'}
                                        />&nbsp;
                                        <label for="zone">IS WAN</label>
                                    </div>
                                    {this.state.networkData.zone === 'wan' &&
                                        // <div className="form-group mt-2">                                           
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="isedit"
                                                onChange={this.setNetworkData}
                                                checked={this.state.networkData.isedit === '0'}
                                            />&nbsp;
                                            <label for="isedit">Keep Default Configuration</label>
                                            <div
                                                className="tooltip-c"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title="Select the 'Keep Default Configuration' checkbox to preserve the current WAN interface settings in the device and prevent them from being overwritten by the template configuration"
                                            >
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                            {/* </div> */}
                                        </div>}
                                    <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                                        <span className="mr-5">802.1x Authentication
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable/Disable 802.1x authentication">
                                                <img src="/images/svg-icons/info.svg" alt="" /></div></span>
                                        <label className="switch">
                                            <input type="checkbox"
                                                name='802_1x_enabled'
                                                onChange={this.setNetworkData}
                                                disabled={this.state.networkData.is_loopback === '1' || this.state.networkData.zone === 'wan'
                                                    // || this.state.is_auth_server_configured === false
                                                }
                                                checked={this.state.networkData["802_1x_enabled"] === '1'} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    {/* {!this.state.is_auth_server_configured && <div>Packetfence switch is not configured</div>} */}
                                </div>
                                {this.state.networkData["802_1x_enabled"] === '1' &&
                                    <div className="form-group mb-0">
                                        <label>Server
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Select RADIUS server">
                                                <img src="/images/svg-icons/info.svg" alt="" /><span className="red-txt">*</span></div>
                                        </label>
                                        <select
                                            className="form-control"
                                            name='radius_server'
                                            onChange={this.setNetworkData}
                                            value={this.state.networkData.radius_server}>
                                            <option value="">Select server</option>
                                            {this.state.is_auth_server_configured && <option value="SD-Edge_ZTNA-B">SD-EDGE ZTNA-B</option>}
                                            {this.state.servers.map((server) => {
                                                return (<option value={server}>{server}</option>)
                                            })}
                                        </select>
                                    </div>
                                }
                            </div>
                            <div className="s-footer">
                                <button className="btn btn-light"
                                    onClick={() => this.closeAddNetworkData()}
                                >Cancel</button>
                                {<button className="btn btn-primary"
                                    disabled={this.checkNetworkError()}
                                    onClick={this.addNetworkData}
                                >{this.state.update ? 'Update' : 'Add'}</button>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="s-dhcp" role="tabpanel" aria-labelledby="s-dhcp-tab">
                        <div className="page-sub-title border-0 pb-0">
                            <div><strong>Static DHCP Reservation</strong>
                                {this.props.edit && <img src="/images/svg-icons/add.svg" className={
                                    (this.props.templateData.default_type !== ''
                                        && this.props.templateData.default_type !== null) ? "add-btn ml-2 disabled" : "add-btn ml-2"} alt=""
                                    onClick={(this.props.templateData.default_type !== ''
                                        && this.props.templateData.default_type !== null) ? undefined : () => this.openAddReservation()} />}</div>
                            {this.props.edit && <div className="device-action">
                                <span className={(this.state.multipleSelect === '' || this.state.multipleSelect.length > 1) ?
                                    "icon-box disabled" : "icon-box"}><img src="/images/svg-icons/edit.svg" alt="" onClick={(this.state.multipleSelect === '' ||
                                        this.state.multipleSelect.length > 1) ? undefined :
                                        () => this.openAddReservation(this.state.template_schema_json.reservations.filter(reservation =>
                                            reservation.id === this.state.multipleSelect[0].id)[0])} /></span>
                                <span className={this.state.multipleSelect === '' || (this.props.templateData.default_type !== ''
                                    && this.props.templateData.default_type !== null) ?
                                    "icon-box disabled" : "icon-box"}>
                                    <img src="/images/svg-icons/delete.svg" alt="" onClick={this.state.multipleSelect === '' || (this.props.templateData.default_type !== ''
                                        && this.props.templateData.default_type !== null) ? undefined :
                                        () => this.deleteReservation()} /></span>
                            </div>}
                        </div>
                        <div key={this.state.keyUpdateRes}
                        >
                            {this.state.template_schema_json.reservations.length > 0 &&
                                <DataTable
                                    columns={this.state.reservationColumns}
                                    data={this.state.template_schema_json.reservations.filter(item => item.isdeleted !== "1")}
                                    selectableRows={(this.props.edit) ? true : false}
                                    fixedHeader
                                    onSelectedRowsChange={(data) => this.multipleSelect(data)}
                                    clearSelectedRows={this.state.toggledClearRows}
                                />}
                        </div>
                        {/* slide Add new reservation */}
                        <div className={this.state.openReservation ? "sidebar-slide w-350 r-757 second-open" : "sidebar-slide w-350 r-757"}>
                            <div className="s-header">
                                <h1 className="ss-title">{this.state.update ? 'Update Reservation' : 'Add New Reservation'}</h1>
                                <button>
                                    <img src="/images/svg-icons/s-cancel.svg" alt=""
                                        onClick={() => this.closeAddReservation()} />
                                </button>
                            </div>
                            <div className="s-body">
                                <div className="form-group">
                                    <label>Host Name
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Hostname of the device">
                                            <img src="/images/svg-icons/info.svg" alt="" /></div>
                                    </label>
                                    <input type="text" className="form-control"
                                        name="hostname" onChange={(e) => this.setDHCPData(e)}
                                        readOnly={!this.props.edit}
                                        value={this.state.reservationData.hostname}
                                        onBlur={(e) => this.validateHostname(e)} />
                                    <div className="error-txt static" id='hostname'>Invalid value</div>
                                </div>
                                <div className="form-group">
                                    <label>Network
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="List of Networks">
                                            <img src="/images/svg-icons/info.svg" alt="" /></div>
                                    </label>
                                    <select
                                        className="form-control"
                                        name='network'
                                        readOnly={!this.props.edit}
                                        onChange={(e) => this.setDHCPData(e)}
                                        value={this.state.reservationData.network}>
                                        <option value="">Select</option>
                                        {this.state.template_schema_json.lanConfig.map((item) => {
                                            if (item.dhcpServer === '1' && item.isdeleted !== '1')
                                                return <option value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    <div className="error-txt static" id="network">Invalid value</div>
                                </div>
                                <div className="form-group">
                                    <label>IP Address
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="IP address to be used for this host">
                                            <img src="/images/svg-icons/info.svg" alt="" /></div>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="ipaddr"
                                        onChange={(e) => this.setDHCPData(e)}
                                        onBlur={(e) => this.validateIP(e)}
                                        readOnly={!this.props.edit}
                                        disabled={this.state.reservationData.network === ''}
                                        value={this.state.reservationData.ipaddr} />
                                    <div className="error-txt static" id="ipaddr">Invalid value</div>
                                </div>
                                <div className="form-group">
                                    <label>MAC Address
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Hardware address of this host ">
                                            <img src="/images/svg-icons/info.svg" alt="" /></div>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="macaddr"
                                        value={this.state.reservationData.macaddr}
                                        onChange={(e) => this.setDHCPData(e)}
                                        onBlur={(e) => this.validateMac(e)}
                                        readOnly={!this.props.edit}
                                    />
                                    <div className="error-txt static" id="macaddr">Invalid value</div>
                                </div>
                                <div className="s-footer">
                                    <button className="btn btn-light" onClick={() => this.closeAddReservation()}>Cancel</button>
                                    {<button className="btn btn-primary" disabled={this.checkReservationError()}
                                        onClick={() => this.addDHCPValues()}
                                    >{this.state.update ? 'Update' : 'Add'}</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!this.props.view && <div className="s-footer">
                    <button className="btn btn-light" onClick={
                        (this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
                            () => this.closeAll()}>
                        {/* 1665 reapply page issue */}
                        {(this.props.apply && !this.props.edit && !this.props.reapply) ? 'Back' : 'Cancel'}</button>
                    {/* LONG-1691 If Apply is given continuously,Template are getting pushed more times-Issue fixed */}
                    <button className="btn btn-primary"
                        disabled={this.props.apply ? this.checkApplyError() : this.checkError()}
                        onClick={
                            this.props.apply ? (event) => this.debounceApply(this.applyTemplate, event) :
                                () => this.saveData()}
                    >{this.props.apply ? 'Apply' : (this.state.selectedTemplateId !== '' ? 'Update' : 'Save')}</button>
                </div>}
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>)
    }
}
const mapStateToProps = (state) => {
    return ({
        openApplyNetwork: state.displayAddTemplate.openApplyNetworkTemplate,
        applying: state.displayAddTemplate.applying,
    });
}
export default connect(mapStateToProps)(NetworkConfigCR);
