import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Loader } from '../../layOuts/Loader';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from 'jquery';
import ErrorHandlePopup from "../ErrorHandlePopup";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import * as Common from "../../utils/common";
import * as Action from '../../redux/actionTypes';
// import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import "./DataTableDemo.css";
import DataTable from "react-data-table-component";
import AddNewServer from "./AddNewServer";
class ExternalServer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            showRefreshModal: false,
            toggledClearRows: false,
            multipleSelect: "",
            show: false,
            error: false,
            delete: false,
            isAxiosError: false,
            columns: [
                {
                    name: "Profile Name",
                    selector: "profile_name",
                    value: "Profile Name",
                    cell: (row) => (
                        <div id={`col${row.profile_name}`}
                        // className={row.profile_name ? "server-first-row" : ""}
                        >
                            <span className="txt-blue" style={{ cursor: "pointer" }}
                                onClick={() => this.openAddServer(row)}>
                                {row.profile_name}</span>
                        </div>
                    ),
                },
                {
                    name: "Type",
                    value: "Type",
                    allowMerging: true,
                    cell: (row) => (<span>
                        {row.profile_name ? "Primary Auth Server" : "Secondary Auth Server"}</span>
                    ),
                },
                {
                    name: "IP Address",
                    value: "IP Address",
                    allowMerging: true,
                    cell: (row) => (<span title={row.ip_address} style={{ width: 'auto' }}>
                        {row.ip_address}</span>
                    ),
                },
                {
                    name: "Port",
                    selector: "port",
                    value: "Port",
                    allowMerging: true,
                    cell: (row) => (<span title={row.port} style={{ width: 'auto' }}>
                        {row.port}</span>
                    ),
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div
                        // className={row.profile_name ? "da-box server-first-row" : ""}
                        >
                            {row.profile_name && <>
                                <img className="a-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
                                <div className="drop-box dropdown-menu">
                                    <ul>
                                        <li
                                            className={
                                                (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")
                                                    ? "disabled"
                                                    : ""
                                            }
                                            onClick={() =>
                                                (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")
                                                    ? undefined
                                                    : this.openAddServer(row)}>
                                            <img className="edit" src="/images/svg-icons/edit.svg" alt="" />Edit</li>
                                        <li
                                            className={
                                                (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")
                                                    ? "disabled"
                                                    : ""
                                            }
                                            onClick={() =>
                                                (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")
                                                    ? undefined
                                                    : this.setState({ multipleSelect: [row] }, () => this.confirmDelete())
                                            }>
                                            <img className="delete" src="/images/svg-icons/delete.svg" alt="" /> Delete</li>
                                    </ul>
                                </div></>}
                        </div>
                    ),
                    allowOverflow: true,
                    button: true,
                    width: "56px",
                },
                // {
                //     name: "Password",
                //     selector: "secret_passphrase",
                //     value: "Password",
                //     allowMerging: true,
                //     cell: (row) => (<span title={row.secret_passphrase} style={{ width: 'auto' }}>
                //         {row.secret_passphrase}</span>
                //     ),
                // },
            ],
            server_data: [
                {
                    profile_name: '',
                    primary_server: {
                        prim_auth_server: '',
                        prim_auth_port: '',
                        prim_auth_secret: '',
                    },
                    secondary_server: {
                        sec_auth_server: '',
                        sec_auth_port: '',
                        sec_auth_secret: ''
                    }
                },
                {
                    profile_name: '',
                    primary_server: {
                        prim_auth_server: '',
                        prim_auth_port: '',
                        prim_auth_secret: '',
                    },
                    secondary_server: {
                        sec_auth_server: '',
                        sec_auth_port: '',
                        sec_auth_secret: ''
                    }
                }
            ]
        };
    }
    componentDidMount() {
        this.getServerData();
    }
    getTableData = () => {
        let tableData = [];
        this.state.server_data && this.state.server_data.map((item) => {
            let row1 = {};
            // let row2 = {};
            row1.server_id = item.server_id;
            // row1.checkbox = false
            row1.profile_name = item.profile_name;
            row1.ip_address = item.primary_server.prim_auth_server;
            row1.port = item.primary_server.prim_auth_port;
            row1.secret_passphrase = item.primary_server.prim_auth_secret;
            // row2.ip_address = item.secondary_server.sec_auth_server;
            // row2.port = item.secondary_server.sec_auth_port;
            // row2.secret_passphrase = item.secondary_server.sec_auth_secret;
            // tableData.push(row1, row2);
            tableData.push(row1);
        })
        this.setState({ tableData })
    }
    // resetPopup = () => {
    //     this.setState({
    //         show: false,
    //         error: false,
    //         passwordChange: false,
    //         preSharedKeyChange: false,
    //     }, () => {
    //         $("#errorMessage").modal("hide");
    //     })
    //     if (this.state.openSlide) {
    //         this.setState({
    //             openSlide: false,
    //         },
    //             () => this.getAuthServerConfiguration())
    //     }
    // }
    openAddServer = (row) => {
        const serverData = row ?
            this.state.server_data.filter(item => item.server_id === row.server_id)[0] :
            {
                profile_name: '',
                primary_server: {
                    prim_auth_server: '',
                    prim_auth_port: '',
                    prim_auth_secret: '',
                },
                secondary_server: {
                    sec_auth_server: '',
                    sec_auth_port: '',
                    sec_auth_secret: ''
                }
            };

        this.setState({
            server_id: row ? row.server_id : '',
            serverData
        }, () => {
            this.props.dispatch({
                type: Action.OPEN_ADD_SERVER
            });
        })
    }
    // closeAddServer = () => {
    //     this.props.dispatch({
    //         type: Action.CLOSE_ADD_SERVER
    //     });
    // }
    validateIP = (e) => {
        if (Common.validateIPaddress(e) === false) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'Invalid IP address';
                this.setState({
                    showError: true,
                })
            }
        }
    }
    getServerData = () => {
        this.setState({
            showLoader: true,
            toggledClearRows: !this.state.toggledClearRows
        });

        ApiCall(urlMapping.getServer(), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    server_data: response?.data,
                    // count: response?.count,
                    refresh: false
                }, () =>
                    this.getTableData());
            }
            else {
                this.setState({
                    showLoader: false,
                    server_data: [],
                    count: 0,
                    refresh: false
                }, () =>
                    this.getTableData()
                )
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Server Data',
                        message: response?.message,
                        show: true,
                        error: true,
                        refresh: false
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    showPass = () => {
        const x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }
    multipleSelect = (rows) => {
        if (!rows.selectedRows.length) this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows });
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            multipleSelect: '',
        }, () => {
            if (this.state.refresh)
                this.getServerData();
        })
    }
    confirmDelete = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Delete Servers' : 'Delete Server',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to delete Servers?' : 'Are you sure you want to delete Server?',
            show: true,
            error: true,
            delete: true,
            refresh: true,
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    deleteServer = () => {
        this.setState({
            showLoader: true,
            show: false,
            error: false,
            delete: false,
        })
        let data = {}
        let serverId = []
        this.state.multipleSelect.map((item) => {
            if (item.server_id)
                serverId.push(item.server_id)
        })
        data = { server_id: serverId }
        ApiCall(urlMapping.deleteServer(data), (response) => {
            if (response?.success) {
                this.setState({
                    title: this.state.multipleSelect.length > 1 ? 'Delete Servers' : 'Delete Server',
                    message: response?.message,
                    show: true,
                    showLoader: false,
                    delete: false,
                    error: false
                }, () => {
                    $("#errorMessage").modal("show");
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true,
                        delete: false
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: this.state.multipleSelect.length > 1 ? 'Delete Servers' : 'Delete Server',
                        message: parseInt(response?.response?.status) === 403 ? response?.response?.data?.message?.split("::")?.[1] : response?.message,
                        showLoader: false,
                        show: true,
                        delete: false,
                        error: true,
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })

    }
    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                {/* <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}> */}
                {/* <Header /> */}
                < div className="white-bg" >
                    <div className="ta-box">
                        <h1 className="page-title">
                            Server Configuration{" "}
                            <img
                                src="/images/svg-icons/add.svg"
                                className={
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? "add-btn disabled"
                                        : "add-btn"
                                }
                                alt=""
                                onClick={() =>
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? undefined
                                        : this.openAddServer()}
                            /></h1>
                        <div className="action-btn">
                            <button className="button-lst" onClick={() => this.getServerData()} ><img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>
                            <button
                                className="button-lst"
                                disabled={
                                    this.state.multipleSelect.length !== 1 ||
                                    (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")
                                }
                                onClick={() =>
                                    this.openAddServer(this.state.multipleSelect?.[0])} ><img src="/images/svg-icons/edit.svg" alt="" /> Edit</button>
                            <button
                                className="button-lst"
                                disabled={
                                    this.state.multipleSelect === "" ||
                                    (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")
                                }
                                onClick={() => this.confirmDelete()} >
                                <img src="/images/svg-icons/delete.svg" alt="" /> Delete</button>
                        </div>
                    </div>
                    <div className="sc-table">
                        <DataTable
                            columns={this.state.columns}
                            data={this.state.tableData}
                            highlightOnHover
                            fixedHeader
                            selectableRows
                            onSelectedRowsChange={(data) => this.multipleSelect(data)}
                            clearSelectedRows={this.state.toggledClearRows}
                        />
                    </div>
                </div >
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                        onSubmit={() => this.deleteServer()}
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {
                    this.props.openAddServer && (
                        <AddNewServer
                            server_id={this.state.server_id}
                            serverData={this.state.serverData}
                            getServerData={() => this.getServerData()}
                        />
                    )
                }
                {/* </section> */}
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div >
        );

    }
}
const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        openAddServer: state.auth.openAddServer,
        userRole: String(state.auth.role)
    };
};
export default withRouter(connect(mapStateToProps)(ExternalServer));
