/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Common from "../../../../utils/common";
import * as Action from "../../../../redux/actionTypes";

class OSPFView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networkViewData: [],
      selectedTemplateId: this.props.id ? this.props.id : "",
      selectedNetworks: ["all"],
      availableNetworks: [],
      template_schema_json: {
        network: "",
        interface: "",
        local_ASN: "",
        neighbor_details: [],
        redistribution: {
          protocol: "",
        },
        distanceConfiguration: {
          ibgp_routes: "",
          ebgp_routes: "",
          local_routes: "",
        },
      },
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      this.setState({
        template_schema_json: this.props.template_schema_json
      });
      if (this.props.apply || (this.props.view && this.props.networkViewData)) {
        if (this.props.networkViewData) {
          let { availableNetworks } = this.state;
          // LONG-1836 : Removed networks with blank ip address in case of device group only
          if (this.props?.group_id) {
            availableNetworks = this.props?.networkViewData?.filter(
              (element) => element.ip_address !== ""
            );
            availableNetworks = availableNetworks?.filter(
              (element) => element.name !== "Control Tunnel"
            );
            // Long-1884 show data tunnel in OSPF, BGP and Firewall templates in apply phase
          } else {
            availableNetworks = this.props?.networkViewData?.filter(
              (element) => element.name !== "Control Tunnel"
            );
          }
          this.setState({ availableNetworks });
        }
        //LONG-1780 issue fixed for network is not showing up
        let selectedNetworks = [];
        // LONG-2158 : Fix for incorrect network dropdown for edit and Re-apply page
        this.props?.template_schema_json?.network.split(",").map((item) => {
          if (item) {
            //LONG-1836 : Directly using the name in network field of json
            let network = item;
            selectedNetworks.push(network);
          }
        });
        if (selectedNetworks.length === 0) {
          selectedNetworks = ["all"];
        }
        this.setState({ selectedNetworks });
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id) {
      this.setState({
        selectedTemplateId: nextProps?.id ? nextProps?.id : ""
      })
    }
    if (nextProps.view && nextProps.template_schema_json !== undefined) {
      this.setState({
        template_schema_json: nextProps.template_schema_json,
      });
    }
    if (nextProps.networkViewData) {
      this.setState({
        networkViewData: nextProps.networkViewData,
      });
    }
    //LONG-1780 issue fixed for network is not showing up
    let selectedNetworks = [];
    if (nextProps.template_schema_json) {
      // LONG-2158 : Fix for incorrect network dropdown for edit and Re-apply page
      nextProps.template_schema_json?.network?.split(",")?.map((item) => {
        if (item) {
          //LONG-1836 : Directly using the name in network field of json
          let network = item;
          selectedNetworks.push(network);
        }
      });
    }
    if (selectedNetworks.length === 0) {
      selectedNetworks = ["all"];
    }
    this.setState({
      selectedNetworks,
    });
  }
  handleChange = (e) => {
    let { selectedNetworks } = this.state;
    let { availableNetworks } = this.state;
    if (e.target.checked) {
      if (e.target.id === "all") {
        selectedNetworks.map((value) => {
          const network = this.props.networkViewData.filter(
            (item) => item.name === value
          )[0];
          availableNetworks.push(network);
        });
        selectedNetworks = ["all"];
        availableNetworks = availableNetworks.filter(
          (item) => item.name !== e.target.id
        );
      } else {
        selectedNetworks = selectedNetworks.filter((item) => item !== "all");
        let found = false;
        availableNetworks.map((network) => {
          if (network.name === "all") found = true;
        });
        if (!found) availableNetworks.push({ name: "all", ip_address: "" });
        if (!selectedNetworks.includes(e.target.id)) {
          selectedNetworks.push(e.target.id);
          availableNetworks = availableNetworks.filter(
            (item) => item.name !== e.target.id
          );
        }
      }
    } else {
      selectedNetworks = selectedNetworks.filter(
        (item) => item !== e.target.id
      );
      const network =
        e.target.id === "all"
          ? { name: e.target.id, ip_address: "" }
          : this.props.networkViewData.filter(
            (item) => item.name === e.target.id
          )[0];
      if (network) {
        const alreadyPresent = availableNetworks?.find(
          (i) => i?.networkName === network?.networkName
        );
        if (!alreadyPresent) availableNetworks.push(network);
      }
    }
    this.setState({
      selectedNetworks,
      availableNetworks,
    });
  };
  applyTemplate = () => {
    const { template_schema_json } = this.state;
    let interfaces = "";
    template_schema_json.network = "";
    this.state.selectedNetworks.map((value) => {
      const network = this.props.networkViewData.filter(
        (item) => item.name === value
      )[0];
      if (network) {
        template_schema_json.network =
          template_schema_json.network === ""
            ? network.name
            : template_schema_json.network + "," + network.name;
        //LONG-2160-Data tunnel network is not included in BGP configuration json file
        //Adding interface name for data tunnel network zones in operation logs
        if (
          network.bridgeStatus === "Disabled" ||
          network.network_zone === "datatunnel"
        ) {
          if (interfaces === "") {
            interfaces = network.interfaceName;
          } else {
            interfaces = interfaces + " " + network.interfaceName;
          }
        } else if (network.bridgeStatus === "Enabled") {
          if (interfaces === "") {
            interfaces = "br-" + network.networkName;
          } else {
            interfaces = interfaces + " br-" + network.networkName;
          }
        }
      }
      template_schema_json.interface = interfaces;
    });
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(template_schema_json);
  };
  render() {
    return (
      <div>
        <div className="bgp-view">
          <div className="row">
            <div className="form-group col-md-6">
              {!this.props.info && (
                <>
                  <label>Network</label>
                  <div className="dropdown">
                    {
                      <ul
                        className={
                          this.props.view || this.props.reapply
                            ? "dm-list d-block"
                            : "chip-list z-index-9 bg-white radius"
                        }
                      >
                        {this.state.selectedNetworks?.map((network) => {
                          return (
                            <li>
                              {network === "all" ? "None" : network}
                              {!this.props.view && !this.props.reapply && (
                                <img
                                  src="/images/svg-icons/cancel.svg"
                                  alt=""
                                  id={network}
                                  onClick={(e) => this.handleChange(e)}
                                />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    }
                    {this.props.info && (
                      <ul className="dm-list d-block">
                        {this.state.template_schema_json?.network?.split(",")
                          ?.length > 0 &&
                          this.state.template_schema_json?.network
                            ?.split(",")
                            ?.map((item) => {
                              //LONG-1836 : Directly using the name in network field of json
                              return <li>{item === "" ? "All" : item}</li>;
                            })}
                      </ul>
                    )}
                    {!this.props.view && !this.props.reapply && (
                      <>
                        {" "}
                        <div
                          className="chip-box add-arrow"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></div>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <ul>
                            {this.state.availableNetworks?.map((item) => {
                              return (
                                <li>
                                  <input
                                    type="checkbox"
                                    id={item?.name}
                                    checked={this.state.selectedNetworks?.includes(
                                      item?.name
                                    )}
                                    onClick={(e) => this.handleChange(e)}
                                  />
                                  {item?.name === "all" ? "None" : item?.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="gray-bg rounded p-3">
            <div className="page-sub-title border-0">
              <div>
                <strong>Neighbor</strong>
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Neighbor to whom the router want to share the routes"
                >
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 mt-2">
                <label>Local ASN</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={this.state.template_schema_json?.local_ASN}
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Neighbor IP</th>
                    <th>Neighbor Type</th>
                    <th>Loopback</th>
                    <th>Remote ASN</th>
                    <th>Advance Options</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.template_schema_json?.neighbor_details?.map(
                    (item) => (
                      <tr>
                        <>
                          <td>{item.neighbor_ip}</td>
                          {/* LONG-1759 : BGP types is wrong while applying from template page */}
                          <td>
                            {item?.remote_ASN ===
                              this.state.template_schema_json?.local_ASN
                              ? "IBGP"
                              : "EBGP"}
                          </td>
                          <td>
                            {" "}
                            <label className="switch gray mt-2">
                              <input
                                type="checkbox"
                                checked={item?.loopback === "1"}
                              />
                              <span className="slider round" style={{ cursor: "not-allowed" }}></span>
                            </label>
                          </td>
                          <td>{item.remote_ASN}</td>
                          <td>
                            <div className="sa-box">
                              <ul>
                                <li>
                                  <span>Next-Hop-Self</span>
                                  <span>
                                    {item?.next_hop_self === "1"
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </li>
                                <li>
                                  <span>EBGP-Multihop</span>
                                  <span>
                                    {item?.EBGP_multihop === "1"
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </li>
                                <li>
                                  <span>MD5 Authentication</span>
                                  <span>
                                    {item?.md5_authentication === "1"
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </li>
                                <li>
                                  <span>Password</span>
                                  <span className="mask">{item?.password}</span>
                                </li>
                                <li>
                                  <span>Keep Alive Time</span>
                                  <span>{item?.keepalive_time} Seconds</span>
                                </li>
                                <li>
                                  <span>Hold Time</span>
                                  <span>{item?.hold_time} Seconds</span>
                                </li>
                                <li>
                                  <span>Loopback</span>
                                  <span>
                                    {item?.loopback === "1"
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </li>
                                <li>
                                  <span>Route Reflector</span>
                                  <span>
                                    {item?.remote_ASN !==
                                      this.state.template_schema_json?.local_ASN
                                      ? "Disabled"
                                      : item?.rReflector === "1"
                                        ? "Enabled"
                                        : "Disabled"}
                                  </span>
                                </li>
                                <li>
                                  <span>Route Reflector Client</span>
                                  <span>
                                    {item?.remote_ASN !==
                                      this.state.template_schema_json?.local_ASN
                                      ? "Disabled"
                                      : item?.rReflectorClient === "1"
                                        ? "Enabled"
                                        : "Disabled"}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </td>{" "}
                        </>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="gray-bg rounded p-3 mt-2">
            <div className="page-sub-title border-0 mt-0">
              <div>
                <strong>Redistribute</strong>
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Redistribute routes of the specified protocol into BGP"
                >
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 mb-0">
                <label>Protocol</label>
                <ul className="dm-list d-block">
                  {
                    // LONG-2158 : Fix for incorrect network dropdown for edit and Re-apply page
                    this.state.template_schema_json.redistribution?.protocol?.split(
                      ","
                    )?.length > 0 &&
                    this.state.template_schema_json.redistribution?.protocol
                      ?.split(",")
                      ?.map((item) => {
                        return <li>{Common.getProtocol(item)}</li>;
                      })
                  }
                </ul>
              </div>
            </div>
          </div>
          <div className="gray-bg rounded p-3 mt-2">
            <div className="page-sub-title border-0 mt-0">
              <div>
                <strong>Distance Configuration</strong>
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title=" Distance for routes"
                >
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    IBGP Routes
                    <div
                      className="tooltip-c"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Distance for routes internal to the AS"
                    >
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={
                      this.state.template_schema_json.distanceConfiguration?.ibgp_routes
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    EBGP Routes
                    <div
                      className="tooltip-c"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Distance for routes external to the AS"
                    >
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={
                      this.state.template_schema_json.distanceConfiguration?.ebgp_routes
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Local Routes
                    <div
                      className="tooltip-c"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Distance for local routes"
                    >
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={
                      this.state.template_schema_json.distanceConfiguration?.local_routes
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {!this.props.view && (
            <div className="s-footer">
              <button
                className="btn btn-light"
                onClick={
                  this.props.apply && !this.props.edit && !this.props.reapply
                    ? () => this.props.closeAddTemplate()
                    : () => this.props.closeApplyTemplate()
                }
              >
                {this.props.apply && !this.props.reapply ? "Back" : "Cancel"}
              </button>
              <button
                className="btn btn-primary"
                onClick={
                  this.props.apply
                    ? () => {
                      this.applyTemplate();
                    }
                    : ""
                }
                disabled={
                  this.state.selectedNetworks.length === 0 || this.props.applying
                }
              >
                {this.props.apply
                  ? "Apply"
                  : this.state.selectedTemplateId !== ""
                    ? "Update"
                    : "Save"}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(OSPFView);
