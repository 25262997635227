import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { Loader } from '../../../layOuts/Loader';
import moment from 'moment';
import $ from 'jquery';
import DataTable from "react-data-table-component";
import { ErrorPopup } from "../../../layOuts/errorPopup";
import ErrorHandlePopup from "../../ErrorHandlePopup";
class DeviceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            show: false,
            group_id: props.group_id,
            pageNo: 1,
            pageSize: 10,
            columns: [
                {
                    name: 'Name',
                    cell: (row) =>
                    (
                        <span
                            title={row.device_name}
                            style={{ width: 'auto' }}
                            className="cursor-pointer"
                        >
                            <Link
                                to={{
                                    pathname: '/devicedetails',
                                    state: {
                                        data: row,
                                        backTo: true
                                    }
                                }}
                                className="txt-blue"
                            >{row.device_name}</Link>
                            {row.templates_need_to_apply !== "" ? (
                                <img src="/images/svg-icons/failed-temp-alert.svg" alt=""
                                    style={{ width: "13px" }}
                                    title={"Some of the templates are not in sync with the group templates.To sync it click on Sync button(device must be onine)."} />
                            ) : (
                                ""
                            )}
                        </span>
                    ),
                },
                {
                    name: 'Status',
                    selector: row => row.device_status === 1 ? 'Online' : 'Offline',
                    cell: (row) => (
                        <div id={`col${row.device_id}`}>
                            <span id={`icon${row.device_id}`}>
                                <i className={row.device_status === 1 ? "status online" : "status offline"}></i>
                                {row.device_status === 1 ? 'Online' : 'Offline'}
                            </span>
                        </div>
                    ),
                },
                {
                    name: 'Model',
                    selector: row => row.device_model,
                },
                {
                    name: 'MAC Address',
                    selector: row => row.device_mac,
                },
                {
                    name: 'Failed Operations',
                    cell: (row) =>
                    (
                        <span
                            title={row.Failed_operations}
                            style={{ width: 'auto' }}
                            className={row.Failed_operations !== 0 ? "cursor-pointer" : ""}
                        >
                            {
                                row.Failed_operations !== 0
                                    ? <Link
                                        to={{
                                            pathname: '/operations',
                                            state: {
                                                failedOperation: row,
                                                group_uuid: this.state.group_uuid
                                            }
                                        }}
                                        className="txt-blue"
                                    >{row.Failed_operations}</Link> : row.Failed_operations
                            }
                        </span>
                    ),
                },
                {
                    name: "Sync",
                    value: "Sync",
                    center: true,
                    cell: (row) => (
                        <label className="switch">
                            <input type="checkbox"
                                name='sync'
                                // onChange={this.setNetworkData}
                                disabled={!(row.templates_need_to_apply !== "" && row.device_status === 1)}
                                // checked={this.state.networkData.bridgeStatus === '1'} 
                                onClick={row.templates_need_to_apply !== "" && row.device_status === 1 ?
                                    (e) => this.getDeviceSync(e, row) : undefined}
                            />
                            <span className="slider round"></span>
                        </label>
                    ),
                }
            ],
        }
    }
    componentDidMount() {
        this.getDeviceGroupDetails();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.group_id !== this.props.group_id) {
            this.setState(
                {
                    group_id: nextProps.group_id,
                    pageNo: 1
                },
                () => this.getDeviceGroupDetails()
            );
        }
    }
    // Get DeviceGroup Info
    getDeviceGroupDetails = async () => {
        this.setState({
            showLoader: true
        });
        const data = {
            group_id: this.state.group_id,
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize
        }
        await ApiCall(urlMapping.deviceGroupDeviceList(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.results) {
                // let model = Common.getModels(response?.data?.attached_devices);
                this.setState({
                    deviceData: response?.results
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };

    getDeviceSync = async (e, row) => {
        if (e.target.checked) {
            this.setState({
                showLoader: true
            });
            const data = {
                device_id: row.device_id,
                group_id: this.state.group_id,
                template_ids: row.templates_need_to_apply
            }
            await ApiCall(urlMapping.syncWithGroup(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response.success) {
                    this.setState({
                        title: 'Device Sync',
                        message: response?.message,
                        show: true,
                        delete: false,
                        error: false
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            show: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            });
        }
    };
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
        }, () => {
            $("#errorMessage").modal("hide");
            this.getDeviceGroupDetails();
        })
    }
    render() {
        return (
            <>
                {" "}
                {this.state.showLoader && <Loader />}
                <div>
                    <DataTable
                        columns={this.state.columns}
                        data={this.state.deviceData}
                        pagination
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    />
                </div>

                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {
                    this.state.show &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={this.resetPopup}
                    />
                }
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        SsoUser: state.auth.SsoUser,
        userRole: String(state.auth.role)
    };
};
export default withRouter(connect(mapStateToProps)(DeviceList));