import * as Action from "../actionTypes";

const initialState = {
    openAddDevice: false,
    openAssUser: false,
    deviceIdWhenDropChanges: ''
};

export const displayAddDevice = (state = initialState, action) => {
    switch (action.type) {
        case Action.OPEN_ADD_DEVICE:
            return {
                ...state,
                openAddDevice: true
            }
        case Action.CLOSE_ADD_DEVICE:
            return {
                ...state,
                openAddDevice: false
            }
        case Action.OPEN_ADD_USER:
            return {
                ...state,
                openAddUser: true
            }
        case Action.CLOSE_ADD_USER:
            return {
                ...state,
                openAddUser: false
            }
        case Action.DROPDOWN_CHANGE:
            return {
                ...state,
                deviceIdWhenDropChanges: action.payload
            }
        default:
            return state;
    }
};