import React, { Component } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  LayersControl,
  useMap
} from "react-leaflet";
import L from "leaflet";

const LayerController = ({ onLayerChange }) => {
  const map = useMap();
  React.useEffect(() => {
    map.on('baselayerchange', (e) => {
      onLayerChange(e);
    });
    return () => {
      map.off('baselayerchange');
    };
  }, [map, onLayerChange]);
  return null;
};

export default class Mapnew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon1: L.icon({
        iconUrl: "marker-icon.png",
      }),
      position: [23.2599, 77.4126],
      center: [23.2599, 77.4126],
    };
  };

  static getDerivedStateFromProps = (props, state) => {
    if (props.center.length > 0) {
      state.center = props?.center;
    }
    state.selectedLayer = props?.selectedLayer || "Default";
    return state;
  };

  handleLayerChange = (e) => {
    this.props?.setSelectedLayer(e?.name || "Default");
  };

  render() {
    return (
      <MapContainer
        id="mymap"
        className="map"
        center={this.state.center}
        zoom={this.props.zoom === 0 ? 16 : this.props.zoom}
        scrollWheelZoom={
          this.props.scrollWheelZoom ? this.props.scrollWheelZoom : true
        }
      >
        <LayerController
          onLayerChange={this.handleLayerChange}
        />
        <LayersControl position="topright">
          <LayersControl.BaseLayer
            checked={this.state.selectedLayer === 'Default'}
            name="Default"
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer
            checked={this.state.selectedLayer === 'Satellite View'}
            name="Satellite View"
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              subdomains={["mt1", "mt2", "mt3"]}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <Marker
          position={this.state.center}
          icon={this.state.icon1}
        ></Marker>
      </MapContainer>
    );
  }
}