import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
export default class DefaultTemplate extends Component {
    render() {
        return (
            <div id="DefaultTemplates" className="container">
                <h3>Bootstrap Templates</h3>

                {/* template step 1 */}
                <Carousel showThumbs={false}
                    showArrows={true}
                    centerMode={false}
                    showStatus={false}>
                    <div className="template-step-1">
                        <p className="text-left">The templates maintained under this section are ZTP bootstrap templates which will be pushed to the devices after the completion of the ZTP process while onboarding. Bootstrap templates are maintained for each model separately. </p>
                        <p className="text-left">Click on Templates &rarr; Bootstrap Templates to see the bootstrap template configurations. Currently, Network, Wireless, Firewall, and WAN Priority are applied as a bootstrap template.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/default-template-1.png" alt="" />
                        </div>
                    </div>
                    {/* close template step 1 */}

                    {/* template step 2 */}
                    <div className="template-step-2">
                        <p className="text-left">User can view the bootstrap template configurations and can modify the value as required in the existing fields.</p>
                        <p className="text-left">Click on View &rarr; Edit to modify any values. For next on-boarding device, the updated bootstrap templates will be applied.</p>
                        <div className="img-box mt-2">
                            <img src="/images/user-guide/default-template-2.png" alt="" />
                        </div>
                    </div>
                    {/* close template step 2 */}

                    {/* template step 3 */}
                    <div className="template-step-3">
                        <p className="text-left">User has the provision to completely replace the bootstrap templates sets with the templates applied to the devices already.</p>
                        <p className="text-left">Go to Device Dashboard &rarr; Templates &rarr; Click on Set As Bootstrap. This shall convert all the templates applied to this device as ZTP bootstrap templates and a new device that is onboarding will have these templates as bootstrap templates. Currently, Network, Wireless, Firewall, and WAN Priority are applied as a bootstrap template.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/default-template-3.png" alt="" />
                        </div>
                    </div>
                    {/* template step 4 */}
                    <div className="template-step-4">
                        <div className="img-box pt-5 mt-4">
                            <img src="/images/user-guide/default-template-4.png" alt="" />
                        </div>
                    </div>
                </Carousel>
                {/* close template step 3 */}

                <div className="text-right">
                    <button className="btn btn-light mr-3" onClick={() => { this.props.goToStep(5); this.props.stepChange(5) }}>Skip</button>
                    <button className="btn btn-light mr-3" onClick={() => { this.props.goToStep(3); this.props.stepChange(3) }}>Back</button>
                    <button className="btn btn-primary" onClick={() => { this.props.nextStep(5); this.props.stepChange(5) }}>Next</button>
                </div>
            </div>
        )
    }
}
