import React, { Component } from 'react';
import StepWizard from "react-step-wizard";
import Template from './Template';
import DefaultTemplate from './DefaultTemplate';
import Device from './Device';
import Tunnel from './Tunnel';
import Overview from './Overview';

export default class IntroPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props && this.props.showIntro ? this.props.showIntro : true,
            selected: 1
        }
    }
    stepChange = (value) => {
        this.setState({
            selected: value
        })
    }
    render() {
        return (
            <div className="overview-section">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a className={this.state.selected === 1 ? "nav-link active" : "nav-link"} onClick={() =>this.stepChange(1)} data-toggle="tab" href="#overview">Overview</a>
                    </li>
                    <li className="nav-item">
                        <a className={this.state.selected === 2 ? "nav-link active" : "nav-link"} onClick={() =>this.stepChange(2)} data-toggle="tab" href="#devices">Devices</a>
                    </li>
                    <li className="nav-item">
                        <a className={this.state.selected === 3 ? "nav-link active" : "nav-link"} onClick={() =>this.stepChange(3)} data-toggle="tab" href="#templates">Templates</a>
                    </li>
                    <li className="nav-item">
                        <a className={this.state.selected === 4 ? "nav-link active" : "nav-link"} onClick={() =>this.stepChange(4)} data-toggle="tab" href="#DefaultTemplates">Bootstrap Templates</a>
                    </li>
                    <li className="nav-item">
                        <a className={this.state.selected === 5 ? "nav-link active" : "nav-link"} onClick={() =>this.stepChange(5)} data-toggle="tab" href="#tunnels">Tunnels</a>
                    </li>
                </ul>
                {this.state.show &&
                    <div className="s-body create-tunnel-body">
                        {/* LONG-EM - Quick Start Page Navigation is not as Expected */}
                        <StepWizard>
                           {this.state.selected === 1 ? <Overview stepChange={(value) => this.stepChange(value)} />:false}
                           {this.state.selected === 2 ? < Device stepChange={(value) => this.stepChange(value)} />:false}
                           {this.state.selected === 3 ? <Template stepChange={(value) => this.stepChange(value)} />:false}
                            {this.state.selected === 4 ?<DefaultTemplate stepChange={(value) => this.stepChange(value)} />:false}
                            {this.state.selected === 5 ?<Tunnel callIntro={this.props.callIntro} stepChange={(value) => this.stepChange(value)} />:false}
                        </StepWizard>
                    </div>
                }
            </div>
        )
    }
}
