import React, { Component } from 'react'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more'
import moment from 'moment';
HighchartsMore(Highcharts)
Exporting(Highcharts);
class ConGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            graphdata: [],
            conTime: '24 Hrs.',
            status: '',
            device_status: this.props?.device_status || 0
        }
    }
    componentDidMount = () => {
        this.renderGraph();
    }
    shouldComponentUpdate = (nextProps) => {
        if (this.props.data !== nextProps.data) {
            return true;
        } else {
            return false;
        }
    }
    static getDerivedStateFromProps = (props, state) => {
        if (props.data) {
            state.graphdata = props.data;
            state.conTime = props.selected_con_time;
        }
        else if (props.device_status) {
            state.device_status = props?.device_status || 0;
        }
        return state;
    }
    getTime = (time) => {
        time = new Date(time * 1000).toISOString()
        let date = moment.utc(time).valueOf();
        return date
    }
    getStatus = (status) => {
        if (status === 'red')
            return 'Offline';
        if (status === 'green')
            return 'Online';
    }
    getTotalTime = (start, end) => {
        start = new Date(start).toISOString();
        start = moment.utc(start);
        start = start.local();
        end = new Date(end).toISOString();
        end = moment.utc(end);
        end = end.local();
        const duration = moment.duration(end.diff(start));
        let date = duration._data.days + " days " + duration._data.hours + " hours " + duration._data.minutes + " minutes " + duration._data.seconds + " seconds "
        return date;
    }
    getTooltip = (value, date, status) => {
        date = new Date(date).toISOString();
        date = moment.utc(date);
        date = date.local();
        if (value === "low") {
            this.setState({ startDate: date._d })
        }
        if (value === "high") {
            this.setState({ endDate: date._d })
        }
        this.setState({
            time: {
                "startDate": this.state.startDate ? this.state.startDate : "",
                "endDate": this.state.endDate ? this.state.endDate : ""
            },
            status
        })
        return date.format('DD MMMM HH:mm');
    }
    renderGraph = () => {
        let graphValue = [];
        let result = {
            x: 0,
            low: this.getTime(this.state.graphdata[0].clock),
            status: this.state.graphdata[0].value,
            color: this.state.graphdata[0].value === "0" ? "red" : "green"
        }
        for (let i = 0; i < this.state.graphdata.length; i++) {
            if (i < this.state.graphdata.length - 1) {
                if (this.state.graphdata[i].value !== this.state.graphdata[i + 1].value) {
                    result.high = this.getTime(this.state.graphdata[i + 1].clock)
                    graphValue.push(result)
                    result = {
                        x: 0,
                        low: this.getTime(this.state.graphdata[i + 1].clock),
                        status: this.state.graphdata[i + 1].value,
                        color: this.state.graphdata[i + 1].value === "0" ? "red" : "green"
                    }
                }
            }

            else {
                result.high = this.getTime(this.state.graphdata[i].clock)
                graphValue.push(result)
            }
        }
        const offlineElement = document.getElementById('offline')
        if (offlineElement)
            offlineElement.innerText = graphValue.filter(val => val.status === "0").length
        const obj = this;
        Highcharts.chart('container', {
            chart: {
                type: 'columnrange',
                inverted: true,
                height: 150,
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            title: {
                text: ''
            },
            xAxis: {
                visible: false,
                labels: {
                    enabled: false
                },
                categories: [
                    '',],

            },
            yAxis: {
                type: 'datetime',
                visible: false,
                labels: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                dateTimeLabelFormats: {
                    hour: '%H:%M',
                },

            },
            tooltip: {
                enabled: true,
                crosshairs: true,
                headerFormat: '<b>{point.x}</b>',
                formatter: function () {
                    return this.x +
                        '<b>Status: </b>' +
                        '<div className="dot-circle">&#9679;</div>' +
                        obj.getStatus(this.point.color) + ' ' +
                        '<br /><b>Duration: </b>' +
                        obj.getTotalTime(this.point.low, this.point.high) + ' ' +
                        '<br /><b>Interval: </b>' +
                        obj.getTooltip("low", this.point.low, this.point.color) + '-' + obj.getTooltip("high", this.point.high, this.point.color);
                },
                positioner: function (labelWidth, labelHeight, point) {
                    var tooltipX = point.plotX + 20;
                    var tooltipY = point.plotY - labelHeight - 50;
                    var chart = this.chart;
                    if (tooltipX < chart.plotLeft) {
                        tooltipX = chart.plotLeft;
                    } else if (tooltipX + labelWidth > chart.plotLeft + chart.plotWidth) {
                        tooltipX = chart.plotLeft + chart.plotWidth - labelWidth;
                    }
                    if (tooltipY < chart.plotTop) {
                        tooltipY = chart.plotTop;
                    } else if (tooltipY + labelHeight > chart.plotTop + chart.plotHeight) {
                        tooltipY = chart.plotTop + chart.plotHeight - labelHeight;
                    }
                    return {
                        x: tooltipX,
                        y: tooltipY
                    };
                }
            },
            plotOptions: {
                columnrange: {
                    pointPadding: 0.39
                }
            },
            legend: {
                enabled: false
            },
            series: [
                {
                    name: '',
                    color: '#795548',
                    data: graphValue,
                    // events: {
                    //     click: function (event) {
                    //         alert(
                    //             this.name + ' clicked\n' +
                    //             'Color: ' + event.point.color + '\n' +
                    //             'Low:' + event.point.low + '\n' +
                    //             'High:' + event.point.high
                    //         );
                    //     }
                    // }
                }
            ]
        });
        const totalData = document.getElementById('container')
        if (totalData && graphValue.length > 1000) {
            totalData.innerText = "Unable to show the graph due to huge data"
            totalData.classList.add("no-data-key");
            totalData.onclick = null;
        }
        else {
            totalData.classList.remove("no-data-key");
        }
    }
    componentDidUpdate(nextProps) {
        if (nextProps.data.length > 0) {
            this.renderGraph()
        }
    }
    render() {
        return (
            <>
                <div className="congraph-container">
                    <div className="device-status start-section">
                        <span>Device Status:</span>
                        <span className='no'>
                            <i className={this.state.device_status === 1 ? "status online" : "status offline"}></i>
                            {this.state.device_status === 1 ? "Online" : "Offline"}
                        </span>
                    </div>
                    <div className="emergency end-section">
                        <span>Total number of times the device went offline:</span>
                        <span id='offline' className='no'></span>
                    </div>
                </div>
                <div id="container" onClick={() => this.props.redirect(this.state.time, this.state.status)}></div>
            </>
        )
    }
}
export default React.memo(ConGraph)
