/* eslint-disable array-callback-return */
/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from "../../layOuts/Loader";
import DataTable from "react-data-table-component";
import * as Constant from "../../utils/constants";
import * as Common from './../../utils/common';
import ErrorHandlePopup from "../ErrorHandlePopup";
import $ from "jquery";
import { ErrorPopup } from "../../layOuts/errorPopup";

class ConnectedClientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      showLoader: false,
      selectedTab: "Dashboard",
      multipleSelect: "",
      title: "",
      message: "",
      show: false,
      error: false,
      delete: false,
      option: {},
      id: this.props.data.device_id,
      status: 0,
      connectedClientData: [],
      deviceData: props.deviceData,
      selectedOption: [],
      isAxiosError: false,
      clientColumns: [
        {
          name: "Connection",
          selector: "connection",
          value: "Connection",
          center: true,
          maxWidth: "20px",
          cell: (row) => (
            <span className="icon-size">
              {row.connection === "5g" && (
                <img src={"/images/svg-icons/5GHz.svg"} alt="" />
              )}
              {row.connection === "2g" && (
                <img src={"/images/svg-icons/2.4GHz.svg"} alt="" />
              )}
              {row.connection === "eth" && (
                <img
                  className="port"
                  src={"/images/svg-icons/port-green.svg"}
                  alt=""
                />
              )}
              {row.connection === "usb" && (
                <img
                  src={"/images/svg-icons/usb-green.svg"}
                  alt=""
                />
              )}
            </span>
          ),
        },
        {
          name: "Device Name",
          selector: "device_name",
          value: "Device Name",
          center: true,
          cell: (row) => (
            <span title={row.device_name} style={{ width: "auto" }}>
              {row.device_name}
            </span>
          ),
        },
        {
          name: "MAC Address",
          selector: "device_mac",
          value: "MAC Address",
          sortable: true,
          center: true,
          cell: (row) => (
            <span title={row.device_mac.toUpperCase()} style={{ width: "auto" }}>
              {row.device_mac.toUpperCase()}
            </span>
          ),
        },
        {
          name: "SSID/Interface",
          selector: "SSID",
          value: "SSID/Interface",
          center: true,
          cell: (row) => (
            <span title={row.SSID} style={{ width: "auto" }}>
              {String(row.SSID) === "rndis0" ? "USB" : row.SSID}
            </span>
          ),
        },
        {
          name: "Data Usage",
          value: "Data Usage",
          cell: (row) => (
            <span
              className="dusages">
              {row.connection === "eth" ? '-' :
                (row.rx_bytes && row.tx_bytes) && <><span className="d-data">
                  <img src="../images/svg-icons/downs-arrow.svg" alt="" />
                  <span>{row.rx_bytes !== '0' ? Common.getConvertedData(row.rx_bytes) : '-'}</span>
                </span>
                  <span className="u-data">
                    <img src="../images/svg-icons/up-arrow.svg" alt="" />
                    <span>{row.tx_bytes !== '0' ? Common.getConvertedData(row.tx_bytes) : '-'}</span>
                  </span></>}
            </span>
          ),
        },
        {
          name: "IP Address",
          selector: "device_ip",
          value: "IP Address",
          center: true,
          cell: (row) => (
            <span title={row.device_ip} style={{ width: "auto" }}>
              {row.device_ip}
            </span>
          ),
        },
        {
          name: "RSSI",
          selector: "rssi",
          value: "RSSI",
          center: true,
          cell: (row) => (
            <span title={row.rssi} style={{ width: "auto" }}>
              {row.rssi}
            </span>
          ),
        },
        {
          name: "Authentication Method",
          selector: "auth_type",
          value: "Authentication Method",
          center: true,
          cell: (row) => (
            <span title={row.auth_type} style={{ width: "auto" }}>
              {row.auth_type}
            </span>
          ),
        },
        {
          name: "Encryption Method",
          selector: "enc_type",
          value: "Encryption Method",
          center: true,
          cell: (row) => (
            <span title={row.enc_type} style={{ width: "auto" }}>
              {row.enc_type}
            </span>
          ),
        },
        {
          name: "Band",
          selector: "band",
          value: "Band",
          center: true,
          cell: (row) => (
            <span title={row.band} style={{ width: "auto" }}>
              {row.band}
            </span>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    // this.getConnectedClient();
    this.getConnectionStatus();
    if (
      Common.getModels(this.state.deviceData.device_model) === 'Moretti_Model' ||
      Common.getModels(this.state.deviceData.device_model) === 'Duvel_Model'
    ) {
      this.setState({
        clientColumns: this.state.clientColumns.filter(item => item.name !== 'Data Usage')
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.deviceData).length !== 0) {
      let { clientColumns } = this.state;
      if (
        Common.getModels(nextProps.deviceData.device_model) === 'Moretti_Model' ||
        Common.getModels(nextProps.deviceData.device_model) === 'Duvel_Model'
      ) {
        clientColumns = this.state.clientColumns.filter(item => item.name !== 'Data Usage')
      }
      this.setState({
        deviceData: nextProps.deviceData,
        clientColumns
      });
    }
    if (nextProps?.data?.device_id) {
      this.setState({
        id: nextProps.data.device_id
      }
        // , () => this.getConnectedClient()
        , () => this.getConnectionStatus()
      )
    }
  }
  componentDidUpdate() {
    let newArray = this.state.clientColumns;
    let localValue = [];
    localValue = JSON.parse(
      localStorage.getItem(Constant.CONNECTED_CLIENT_LIST)
    );
    if (localValue !== null) {
      newArray = [];
      localValue.map((item) => {
        const column = this.valueReturn(item);
        if (column !== undefined) newArray.push(column);
      });
    }
    if (localValue === null) {
      newArray = this.state.clientColumns;
    }
    this.state.selectedOption = newArray;
  }
  getConnectionStatus = () => {
    this.setState({
      showLoader: true,
    });
    let data = {
      device_id: this.props.data.device_id ? this.props.data.device_id : "",
    }
    ApiCall(urlMapping.getPollingData(data), (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.success) {
        // let clientStatus = response?.data.connected_client_info.status
        // if (clientStatus["wired_clients"] === 1 && clientStatus["wifi_clients"] === 1) {
        //   this.setState({
        //     wiredClients: 1,
        //     wifiClients: 1
        //   })
        // }
        // else if (clientStatus["wifi_clients"] === 1) {
        //   this.setState({
        //     wifiClients: 1
        //   })
        // } else if (clientStatus["wired_clients"] === 1) {
        //   this.setState({
        //     wiredClients: 1
        //   })
        // }
        this.setState({
          status: response?.data?.connected_client_info?.status
        })
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
    this.getConnectedClient()
  }
  getConnectedClient = () => {
    this.setState({
      showLoader: true,
      selectedTab: "ConnectedClient",
    });
    let deviceId = {
      device_id: this.state.id,
      hostname: this.state.deviceData.hostname,
      is_headendcpe: this.state.deviceData.is_headendcpe,
      device_model: this.state.deviceData.device_model,
    };
    ApiCall(urlMapping.connectedClient(deviceId), (response) => {
      if (response?.success) {
        this.savedData();
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showLoader: false, showRefreshModal: true })
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState({
            showLoader: false,
          });
        }
      }
    });
  };
  savedData = () => {
    let data = {
      device_id: this.state.id,
      data_key: "client_info"
    };
    this.setState({ showLoader: true });
    ApiCall(urlMapping.savedData(data), (response) => {
      if (response?.success) {
        let connectedClientData = [];
        const data = response?.data;
        Object.keys(data)?.map((nameKey) => {
          let connection = "";
          switch (nameKey) {
            case "connected_clients_5g_info":
              connection = "5g";
              break;
            case "connected_clients_2g_info":
              connection = "2g";
              break;
            case "connected_clients_eth_info":
            case "connected_clients_vlan_info":
              connection = "eth";
              break;
            case "connected_clients_usb_info":
              connection = "usb";
              break;
            default:
              connection = nameKey;
          }
          Object.keys(data?.[nameKey])?.map((key) => {
            if (Object.keys(data?.[nameKey]?.[key])?.length > 0) {
              Object.keys(data?.[nameKey]?.[key])?.map((netKey) => {
                if (Object.keys(data?.[nameKey]?.[key]?.[netKey])?.length > 0) {
                  Object.keys(data?.[nameKey]?.[key]?.[netKey])?.map((item, index) => {
                    connectedClientData.push({
                      connection: connection,
                      device_mac: Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index],
                      device_name:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.device_name,
                      SSID: netKey,
                      device_ip:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.device_ip,
                      rx_bytes:
                        data?.[nameKey]?.[key]?.[netKey]?.[Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.rx_bytes,
                      tx_bytes:
                        data?.[nameKey]?.[key]?.[netKey]?.[Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.tx_bytes,
                      rssi:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.rssi,
                      auth_type:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.auth_type,
                      band:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.band,
                      enc_type:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.enc_type
                    });
                  })
                }
              });
            }
          });
        });
        this.setState({
          connectedClientData,
          showLoader: false
        });
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  handleChange = (e) => {
    let array = [];
    this.state.selectedOption.map((option) => (
      array.push(option.value)
    ));
    const result = e.target.value;
    if (result === "selectAll") {
      array = [];
      if (e.target.checked) {
        if (this.state.selectedTab === "ConnectedClient") {
          this.state.clientColumns.map((column) => (
            array.push(column.value)
          ));
        } else {
          this.state.columns.map((column) => (
            array.push(column.value)
          ));
        }
      } else {
        array = this.state.selectedTab === "ConnectedClient" ? [] : ["Action"];
      }
    } else {
      if (e.target.checked) array.push(result);
      else array.splice(array.indexOf(result), 1);
    }
    let availableColumns = [
      "Connection",
      "Device Name",
      "MAC Address",
      "SSID/Interface",
      "Data Usage",
      "IP Address",
      "RSSI",
      "Authentication Method",
      "Encryption Method",
      "Band"
    ];
    array = availableColumns?.filter(field => array?.includes(field));
    const selectedVlaues = [];
    array.map((item) => {
      const column = this.valueReturn(item);
      selectedVlaues.push(column);
    });
    const listName =
      this.state.selectedTab === "ConnectedClient"
        ? Constant.CONNECTED_CLIENT_LIST
        : Constant.APPLIED_TEMPLATE_LIST;
    localStorage.setItem(listName, JSON.stringify(array));
    this.setState({ selectedOption: selectedVlaues });
  };
  valueReturn = (item) => {
    if (this.state.selectedTab === "ConnectedClient")
      return this.state.clientColumns.filter(
        (column) => column.value === item
      )[0];
  };
  refreshIntervals = (value) => {
    this.setState({ showLoader: true });
    let data = {
      device_ip: this.props.data.device_control_tunnel_ip,
      key: value,
      device_id: this.state.id,
      device_model: this.state.deviceData.device_model
    }
    ApiCall(urlMapping.getTelemetry(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        this.setState({
          showLoader: false,
        });
        let connectedClientData = [];
        const data = response?.data;
        Object.keys(data)?.map((nameKey) => {
          let connection = "";
          switch (nameKey) {
            case "connected_clients_5g_info":
              connection = "5g";
              break;
            case "connected_clients_2g_info":
              connection = "2g";
              break;
            case "connected_clients_eth_info":
            case "connected_clients_vlan_info":
              connection = "eth";
              break;
            case "connected_clients_usb_info":
              connection = "usb";
              break;
            default:
              connection = nameKey;
          }
          Object.keys(data?.[nameKey])?.map((key) => {
            if (Object.keys(data[nameKey]?.[key])?.length > 0) {
              Object.keys(data?.[nameKey]?.[key])?.map((netKey) => {
                if (Object.keys(data?.[nameKey]?.[key]?.[netKey])?.length > 0) {
                  Object.keys(data?.[nameKey]?.[key]?.[netKey])?.map((item, index) => {
                    connectedClientData.push({
                      connection: connection,
                      device_mac: Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index],
                      device_name:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey][key]?.[netKey])?.[index]
                        ]?.[0]?.device_name,
                      SSID: netKey,
                      device_ip:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.device_ip,
                      rx_bytes:
                        data?.[nameKey]?.[key]?.[netKey]?.[Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.rx_bytes,
                      tx_bytes:
                        data?.[nameKey]?.[key]?.[netKey]?.[Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.tx_bytes,
                      rssi:
                        data?.[nameKey]?.[key]?.[netKey][
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.rssi,
                      auth_type:
                        data?.[nameKey]?.[key]?.[netKey][
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.auth_type,
                      band:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.band,
                      enc_type:
                        data?.[nameKey]?.[key]?.[netKey]?.[
                          Object.keys(data?.[nameKey]?.[key]?.[netKey])?.[index]
                        ]?.[0]?.enc_type
                    });
                  })

                }
              });
            }
          });
        });
        this.setState({
          showLoader: false,
          connectedClientData,
        });
      }
      else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }
  render() {
    return (
      <>
        {" "}
        {this.state.showLoader && <Loader />}
        <div className="white-bg">
          <div className="ta-box set-pos mb-0">
            <div className="action-btn">
              {/* <button
                className="refreshT"
                title="Refresh Telemetry data"
                onClick={this.refreshTelemetry}
              >
                <img
                  style={{ width: "20px", left: "-3px" }}
                  src="/images/refreshT.png" 
                  alt=""
                />
              </button> */}
              <img
                className="mr-2"
                style={{ width: "25px", cursor: "pointer" }}
                src="/images/refreshT.png"
                title="Refresh Telemetry data"
                onClick={() => this.refreshIntervals("client_list")}
              />
              <button
                className="short-btn"
                title="Refresh"
                onClick={
                  // () => this.getConnectedClient()
                  () => this.getConnectionStatus()
                }>
                <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
              </button>
              <button
                className="dropdown-toggle filter-btn short-btn"
                title="Columns"
                data-toggle="dropdown"
              >
                <img src="/images/svg-icons/columns.svg" alt="" /> Columns
              </button>
              <div
                className="filter-section dropdown-menu dropdown-menu-right"
                onChange={(e) => this.handleChange(e)}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="selectAll"
                    checked={
                      this.state.selectedOption.length ===
                      this.state.clientColumns.length
                    }
                    style={{ cursor: "pointer" }}
                  />
                  <label className="form-check-label">Select All</label>
                </div>
                {this.state.clientColumns.map((item, index) => {
                  if (item.value !== "Action")
                    return (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={item.value}
                          checked={JSON.stringify(
                            this.state.selectedOption
                          ).includes(item.value)}
                          style={{ cursor: "pointer" }}
                        />
                        <label className="form-check-label">{item.value}</label>
                      </div>
                    );
                })}
              </div>
            </div>
          </div>
          {this.state.status === 0 ?
            // this.state.wiredClients === 0 && this.state.wifiClients === 0) ?
            <div className="white-bg no-data">Configurations are disabled</div>
            :
            // (this.state.wiredClients === 0) ?
            //   <>
            //     <DataTable
            //       columns={this.state.selectedOption}
            //       data={this.state.connectedClientData}
            //       fixedHeader
            //       highlightOnHover
            //     />
            //     <div>Configurations are disabled for eth and usb</div>
            //   </>
            //   :
            //   (this.state.wifiClients === 0) ?
            //     <>
            //       <DataTable
            //         columns={this.state.selectedOption}
            //         data={this.state.connectedClientData}
            //         fixedHeader
            //         highlightOnHover
            //       />
            //       <div>Configurations are disabled for 2g and 5g</div>
            //     </>
            // :
            <div className="ConnectedClientTable">
              <DataTable
                columns={this.state.selectedOption}
                data={this.state.connectedClientData}
                fixedHeader
                highlightOnHover
              />
            </div>
          }
        </div>
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
  };
};
export default withRouter(connect(mapStateToProps)(ConnectedClientList));