import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as Action from "../../redux/actionTypes";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import { Loader } from '../../layOuts/Loader';
import DataTable from "react-data-table-component";
import apiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import AddNewUser from "./addNewUser";
import { ErrorPopup } from '../../layOuts/errorPopup';
import ErrorHandlePopup from '../ErrorHandlePopup';

import $ from "jquery";
class UserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userList: "",
            userId: '',
            userFirstName: "",
            userLastName: "",
            userEmailId: "",
            multipleSelect: '',
            show: false,
            showLoader: false,
            showFilter: false,
            showRefreshModal: false,
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            title: "",
            error: false,
            delete: false,
            message: "",
            isAxiosError: false,
            columns: [
                {

                    name: 'User Name',
                    center: true,
                    selector: row => row.username ? row.username : "--"
                },

                {
                    name: 'Email Id',
                    center: true,
                    cell: (row) => (
                        <span title={row.email}>
                            {row.email ? row.email : "--"}</span>
                    ),
                    selector: ''
                },
                {
                    name: 'First Name',
                    center: true,
                    cell: (row) => (
                        <span title={row.first_name}>
                            {row.first_name ? row.first_name : "--"}</span>
                    ),
                    selector: ''
                },
                {
                    name: 'Second Name',
                    center: true,
                    cell: (row) => (
                        <span title={row.last_name}>
                            {row.last_name ? row.last_name : "--"}</span>
                    ),
                    selector: ''
                },
                {
                    name: 'Role',
                    center: true,
                    cell: (row) => (

                        <span title={
                            row.is_superuser
                                ? "Super Admin"
                                : (
                                    row.group_name !== ""
                                        ? (
                                            row.group_name === "Viewer_Group"
                                                ? "Viewer"
                                                : (
                                                    row.group_name === "Operator_Group"
                                                        ? "Operator"
                                                        : (
                                                            row.group_name === "Admin_Group"
                                                                ? "Admin"
                                                                : "--"
                                                        )
                                                )
                                        )
                                        : "Admin"
                                )
                        }
                        >
                            {
                                row.is_superuser
                                    ? "Super Admin"
                                    : (
                                        row.group_name !== ""
                                            ? (
                                                row.group_name === "Viewer_Group"
                                                    ? "Viewer"
                                                    : (
                                                        row.group_name === "Operator_Group"
                                                            ? "Operator"
                                                            : (
                                                                row.group_name === "Admin_Group"
                                                                    ? "Admin"
                                                                    : "--"
                                                            )
                                                    )
                                            )
                                            : "Admin"
                                    )
                            }</span>
                    ),
                    selector: ''
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div className="da-box">
                            <img className="a-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
                            <div className="drop-box dropdown-menu">
                                <ul>
                                    <li
                                        className={!this.props.isSuperAdmin ? "disabled" : ""}
                                        onClick={() =>
                                            !this.props.isSuperAdmin
                                                ? undefined
                                                : this.setState({
                                                    userId: row.id,
                                                    userFirstName: row.first_name,
                                                    userLastName: row.last_name,
                                                    userEmailId: row.email ? row.email : ""
                                                },
                                                    () => this.getUpdateList([row]))
                                        }>
                                        <img className="edit" src="/images/svg-icons/edit.svg" alt="" />Edit</li>
                                    <li
                                        className={!this.props.isSuperAdmin ? "disabled" : ""}
                                        onClick={() =>
                                            !this.props.isSuperAdmin
                                                ? undefined
                                                : this.setState({ multipleSelect: [row.id] },
                                                    () => this.confirmDelete())}
                                    >
                                        <img className="delete" src="/images/svg-icons/delete.svg" alt="" /> Delete</li>
                                </ul>
                            </div>
                        </div>
                    ),
                    allowOverflow: true,
                    button: true,
                    width: "56px",
                },
            ],
        }
    }
    componentDidMount() {
        this.UserList();
    }
    getUpdateList = (row) => {
        this.setState({ multipleSelect: [row] });
        this.addNewUser();

    }
    deleteUser = () => {

        this.setState({ showLoader: true })
        let userId = this.state.multipleSelect
        apiCall(urlMapping.deleteUser(userId), (response) => {
            if (response?.success) {
                this.setState({
                    title: 'Delete User',
                    message: response?.message,
                    show: true,
                    showLoader: false,
                    delete: false,
                    error: false
                }, () => $("#errorMessage").modal("show"))
                this.UserList();
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true,
                        delete: false
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Delete User',
                        // Wrong message displayed if 403 error is returned by API
                        message: parseInt(response?.response?.status) === 403 ? response?.response?.data?.message?.split("::")?.[1] : response?.message,
                        showLoader: false,
                        show: true,
                        delete: false,
                        error: true,
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })

    }
    componentDidUpdate() {
        if (this.state.pageNo - 1 * this.state.pageSize === this.state.totalCount)
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.pageNo = this.state.pageNo - 1;
    }
    UserList = () => {
        this.setState({ showLoader: true })
        let data = {
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize,
            all: 0
        }
        apiCall(urlMapping.getUserList(data), (response) => {
            if (response?.results) {
                const data = response?.results;
                let newData = data.filter((item) => item.username !== "ma_admin")
                const totalCount = response?.count;
                this.setState({
                    showLoader: false,
                    userList: data,
                    totalCount: totalCount,
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    confirmDelete = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Delete Users' : 'Delete User',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to delete users ?' : 'Are you sure you want to delete user?',
            show: true,
            error: true,
            delete: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    chooseAction = (deleteAct) => {
        if (deleteAct) {
            this.deleteUser();
        }

    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            // Long-1629 Reboot and Factory reset buttons become disable when clicked cancel in delete conformation
            multipleSelect: '',
            toggledClearRows: !this.state.toggledClearRows
        }, () => $("#errorMessage").modal("hide"))
        this.UserList();
    }
    getFilterUserList = () => {
        this.setState(prevState => ({ showFilter: !prevState.showFilter }));
    }
    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    }
    addNewUser = () => {
        this.props.dispatch({
            type: Action.OPEN_ADD_USER,
        });
    };
    closeAddUser = () => {
        this.props.dispatch({
            type: Action.CLOSE_ADD_USER,
        })
        this.setState({
            userId: "",
            multipleSelect: "",
            userFirstName: "",
            userLastName: "",

        })
    }
    paginationChange = (e) => {
        this.setState({
            pageNo: e
        },
            () => {
                this.UserList();
            }
        );

    };
    pageSizeChange = (e) => {
        this.setState({ pageSize: e }, () => {
            this.UserList();
        });
    };
    resetValue = () => {
        this.setState({ user: "", deviceId: "", deviceName: [] }, () => this.getDeviceList())

    }
    hideDropdown = (e) => {
        var popup = $("#parentDiv");
        if (
            !$("#open").is(e.target) &&
            !popup.is(e.target) &&
            popup.has(e.target).length === 0
        ) {
            this.setState({ showFilter: false })
        }
    };

    render() {
        const { isSuperAdmin } = this.props
        return (
            <>{(this.props?.userRole === "Viewer_Group" ||
                this.props?.userRole === "Operator_Group")
                ? <></>
                : <div onMouseDown={(e) => this.hideDropdown(e)}>
                    <SideMenu />
                    {this.state.showLoader && <Loader />}
                    <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                        <Header />
                        <div className="white-bg page-height">
                            <div className="ta-box">
                                <h1 className="page-title">User {!isSuperAdmin ?
                                    (<img src="/images/svg-icons/add.svg" className="add-btn disabled" alt="" title="No permission to create User" />) :
                                    (<img src="/images/svg-icons/add.svg" className="add-btn" alt=""
                                        onClick={() =>
                                            this.setState({
                                                userEmailId: ""
                                            }, () =>
                                                this.addNewUser())} />)}</h1>
                                <div className="action-btn">
                                    <button onClick={() => this.UserList()}><img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>
                                </div>
                            </div>

                            <DataTable
                                columns={this.state.columns}
                                data={this.state.userList}
                                // selectableRows
                                highlightOnHover
                                fixedHeader
                                pagination
                                paginationServer
                                paginationTotalRows={this.state.totalCount}
                                onChangePage={(e) => this.paginationChange(e)}
                                onChangeRowsPerPage={(e) => this.pageSizeChange(e)}
                                onSelectedRowsChange={(data) => this.multipleSelect(data)}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            />
                            {this.props.openAddUser &&
                                <AddNewUser
                                    userId={this.state.userId}
                                    multiselect={this.state.multipleSelect}
                                    userFirstName={this.state.userFirstName}
                                    userLastName={this.state.userLastName}
                                    userEmailId={this.state.userEmailId}
                                    UserList={() => this.UserList()}
                                    closeAddUser={() => this.closeAddUser()}
                                />
                            }
                        </div>

                        {/* Add new user slide close */}
                    </section>
                    {(this.state.show) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.resetPopup()}
                            onSubmit={() => this.chooseAction(this.state.delete, this.state.factoryReset, this.state.reboot)}
                        />
                    }
                    {(this.state.isAxiosError) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.setState({ isAxiosError: false })}
                        />
                    }
                    {(this.state.showRefreshModal) &&
                        <ErrorHandlePopup
                        />
                    }
                </div>
            }</>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openAddUser: state.displayAddDevice.openAddUser,
        isSuperAdmin: state.auth.isSuperAdmin,
        userRole: String(state.auth.role)
    });
}
export default withRouter(connect(mapStateToProps)(UserList));

