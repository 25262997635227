import * as Action from "../actionTypes";

const initialState = {
  openAddTemplate: false,
  openWGTemplate: false,
  openStaticTemplate: false,
  deviceModel: "",
  openApplyTemplate: false,
  openApplyNetworkTemplate: false,
  openSSID: false,
  applying: false,
};

export const displayAddTemplate = (state = initialState, action) => {
  switch (action.type) {
    case Action.OPEN_ADD_TEMPLATE:
      return {
        ...state,
        openAddTemplate: true,
      };
    case Action.CLOSE_ADD_TEMPLATE:
      return {
        ...state,
        openAddTemplate: false,
        openApplyNetworkTemplate: false,
        deviceModel: "",
      };
    case Action.OPEN_WG_TEMPLATE:
      return {
        ...state,
        openWGTemplate: true,
        openStaticTemplate: false,
      };
    case Action.CLOSE_WG_TEMPLATE:
      return {
        ...state,
        openWGTemplate: false,
        openStaticTemplate: false,
      };
    case Action.OPEN_STATIC_TEMPLATE:
      return {
        ...state,
        openStaticTemplate: true,
        openWGTemplate: false,
      };
    case Action.CLOSE_STATIC_TEMPLATE:
      return {
        ...state,
        openStaticTemplate: false,
        openWGTemplate: false,
      };
    case Action.SET_DEVICE_MODEL:
      return {
        ...state,
        deviceModel: action.payload,
      };
    case Action.OPEN_APPlY_TEMPLATE:
      return {
        ...state,
        openApplyTemplate: true,
      };
    case Action.CLOSE_APPLY_TEMPLATE:
      return {
        ...state,
        openApplyTemplate: false,
        openApplyNetworkTemplate: false,
        openSSID: false,
      };
    case Action.OPEN_SSID:
      return {
        ...state,
        openSSID: true,
      };
    case Action.CLOSE_SSID:
      return {
        ...state,
        openSSID: false,
      };
    case Action.OPEN_ATTACH:
      return {
        ...state,
        openAttach: true,
      };
    case Action.CLOSE_ATTACH:
      return {
        ...state,
        openAttach: false,
      };
      case Action.OPEN_APPLY_NETWORK_TEMPLATE:
      return {
        ...state,
        openApplyNetworkTemplate: true,
      };
    case Action.CLOSE_APPLY_NETWORK_TEMPLATE:
      return {
        ...state,
        openApplyNetworkTemplate: false,
        applying: false,
      };
      case Action.SET_APPLY_TEMPLATE:
        return{
          ...state,
          applying: true,
        };
        case Action.RESET_APPLY_TEMPLATE:
          return{
            ...state,
            applying: false,
          };
    default:
      return state;
  }
};
