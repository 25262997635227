/* eslint-disable array-callback-return */
import React, { Component } from "react";
import * as Action from "../../../redux/actionTypes";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import * as Common from "../../../utils/common";
import apiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { ErrorPopup } from "../../../layOuts/errorPopup";
import { LinkPopup } from "../../../layOuts/linkPopup";
import StaticTemplate from "./StaticRoute/StaticTemplate";
import WanConfig from "./WanTemplates/WanConfig";
import WiFiView from "../ViewTemplates/WiFiView";
import WiFiTemplate from "./WiFiTemplate";
import StaticRoute from "./StaticRoute/StaticRoute";
import $ from "jquery";
import OSPFView from "./OSPF/OSPFView";
import NetworkConfiguration from "./NetworkTemplates/NetworkConfiguration";
import OSPFTemplate from "./OSPF/OSPFTemplate";
import BGPTemplate from "./BGP/BGPTemplate";
import BGPView from "./BGP/BGPView";
import FirewallTemplate from "../Firewall/FirewallTemplate";
import FirewallView from "../Firewall/FirewallView";
import FirewallMIFITemplate from "../Firewall/FirewallMIFITemplate";
import FirewallMIFIView from "../Firewall/FirewallMIFIView";
import WanConfigCR from "./WanTemplates/WanConfigCR";
import NetworkConfigCR from "./NetworkTemplates/NetworkConfigCR";
import QOSView from "./QOS/QOSView";
import ErrorHandlePopup from "../../ErrorHandlePopup";
import QOSTemplate from "./QOS/QOSTemplate";
import WAView from "./WarningAlertsConfig/WAView";
import WATemplate from "./WarningAlertsConfig/WATemplate";
import HubSpokeTemplate from "./Hub&Spoke/HubSpokeTemplate";
import DNSTemplate from "./DNS/DNSTemplate";
import DNSView from "./DNS/DNSView";
import SystemTemplate from "./SystemTemplate/SystemTemplate";
import SystemTemplateView from "./SystemTemplate/SystemTemplateView";
import ModemTemplate from "./modemTemplate/ModemTemplate";
import CaptivePortalView from "./CaptivePortal/CaptivePortalView";
import CaptivePortal from "./CaptivePortal/CaptivePortal";
import IPSecTemplate from "./IPSec/IPSecTemplate";
import { Loader } from "../../../layOuts/Loader";
import DeviceConfiguration from "./DeviceConfiguration/DeviceConfiguration";
import { isEqual } from "lodash";

class ApplyTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            title: '',
            message: '',
            show: false,
            showLink: false,
            error: false,
            adding: false,
            multipleSelect: "",
            multipleSelect1: "",
            toggledClearDeviceRows: false,
            toggledClearSelectedDeviceRows: false,
            applytemplate: false,
            devicesTemplate: false,
            selectedData: 'devices',
            deviceGroupTemplate: false,
            applyTemplateData: {},
            deviceList: [],
            deviceGroupList: [],
            ShowTemplate: true,
            selectedDeviceData: [],
            selectedGroupDeviceData: [],
            networkViewData: [],
            hide: true,
            deleted: [],
            searchVal: "",
            group_id: '',
            disableApply: false,
            modelName: '',
            keyUpdate: '',
            interfaces: props.interfaces ? props.interfaces : [],
            usedInterfaces_deviceA: [],
            usedInterfaces_deviceB: [],
            usedEndpoints_deviceA: [],
            usedEndpoints_deviceB: [],
            device_id: "",
            olderDevice: false,
            firmwareUpgrade: false,
            newTemplate: false,
            isAxiosError: false,
            deviceDetails: "",
            externalTemplate: false,
            ipsecDeviceANetwork: [],
            deviceListColumns: [{
                name: "Device Name",
                selector: "device_name",
                value: "Device Name",
                center: false,
                sortable: true,
                minWidth: "150px",
                cell: (row) => (
                    <span className="dnamemac" id={`icon${row?.device_id}`} title={row?.device_name}>
                        <span className="hname"><i className="status online"></i>{row?.device_name}</span>
                        <span className="maca">{row?.device_mac?.toUpperCase()}</span>
                        <span className={row?.group_name ? "maca-grp dg-text ml-2" : ""}>{row?.group_name}</span>
                    </span>
                ),
            },
            {
                name: "Model",
                selector: "device_model",
                value: "Device Model",
                center: true,
                sortable: true,
                cell: (row) => (<span title={row?.device_model}>
                    {row?.device_model}</span>
                ),
            },
            ],
            deviceListColumnsDiff: [
                {
                    name: "Device Name",
                    selector: "device_name",
                    value: "Device Name",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.device_name}>
                        {row?.device_name}</span>
                    ),
                },
                {
                    name: "MAC Address ",
                    selector: "device_mac",
                    value: "Mac Address",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.device_mac?.toUpperCase()}>
                        {row?.device_mac?.toUpperCase()}</span>
                    ),
                },
                {
                    name: "Firmware ",
                    selector: "device_firmware_version",
                    value: "Firmware",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.device_firmware_version}>
                        {row?.device_firmware_version}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "device_model",
                    value: "Device Model",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.device_model}>
                        {row?.device_model}</span>
                    ),
                },
                {
                    name: "Device Status",
                    value: "Device Status",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={`icon${row?.status}`}>
                        {" "}
                        <i className="status online"></i>
                        {"Online"}
                    </span>
                    ),
                },
            ],
            deviceListGroupColumnsDiff: [
                {
                    name: "Group Name",
                    selector: "group_name",
                    value: "Group Name",
                    sortable: true,
                    cell: (row) => (<span title={row?.group_name}>
                        {row?.group_name}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "supported_models",
                    value: "supported_models",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.supported_models}>
                        {row?.supported_models}</span>
                    ),
                }

            ],
            deviceGroupListColumns: [
                {
                    name: "Group Name",
                    selector: "group_name",
                    value: "Group Name",
                    sortable: true,
                    cell: (row) => (<span title={row?.group_name}>
                        {row?.group_name}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "supported_models",
                    value: "supported_models",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.supported_models}>
                        {row?.supported_models}</span>
                    ),
                }
            ],
            selectedModel: '',
            hub_data: [],
            ipsec_data: [],
            IPSecColumn: [
                {
                    name: "Device Name",
                    selector: "hub_device_name",
                    value: "Device Name",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.ipsec_device_name}>
                        {row?.ipsec_device_name}</span>
                    ),
                },
                {
                    name: "MAC Address ",
                    selector: "hub_device_mac",
                    value: "Mac Address",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.ipsec_device_mac?.toUpperCase()}>
                        {row?.ipsec_device_mac?.toUpperCase()}</span>
                    ),
                },
                {
                    name: "Firmware ",
                    selector: "hub_fw_version",
                    value: "Firmware",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.ipsec_fw_version}>
                        {row?.ipsec_fw_version}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "hub_device_model",
                    value: "Device Model",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.ipsec_device_model}>
                        {row?.ipsec_device_model}</span>
                    ),
                },
                {
                    name: "Device Status",
                    selector: "hub_status",
                    value: "Device Status",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={`icon${row?.ipsec_status}`}>
                        {" "}
                        <i className={this.returnClassStatus(row?.ipsec_status)}></i>
                        {this.returnStatus(row?.ipsec_status)}
                    </span>
                    ),
                },
            ],
            hubInfo: [
                {
                    name: "Device Name",
                    selector: "hub_device_name",
                    value: "Device Name",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.hub_device_name}>
                        {row?.hub_device_name}</span>
                    ),
                },
                {
                    name: "MAC Address ",
                    selector: "hub_device_mac",
                    value: "Mac Address",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.hub_device_mac?.toUpperCase()}>
                        {row?.hub_device_mac?.toUpperCase()}</span>
                    ),
                },
                {
                    name: "Firmware ",
                    selector: "hub_fw_version",
                    value: "Firmware",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.hub_fw_version}>
                        {row?.hub_fw_version}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "hub_device_model",
                    value: "Device Model",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.hub_device_model}>
                        {row?.hub_device_model}</span>
                    ),
                },
                {
                    name: "Device Status",
                    selector: "hub_status",
                    value: "Device Status",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={`icon${row?.hub_status}`}>
                        {" "}
                        <i className={this.returnClassStatus(row?.hub_status)}></i>
                        {this.returnStatus(row?.hub_status)}
                    </span>
                    ),
                },
            ],
            isHubInfo: false,
            macAddress: []
        }
    }
    componentDidMount() {
        this.props.dispatch({
            type: Action.RESET_APPLY_TEMPLATE,
        });
        if (this.props?.tunnelEdit) {
            this.setState({
                applyTemplateData: this.props.data,
                ShowTemplate: false
            })
        }
        else {
            if (this.props.data) {
                if (this.props.view || this.props.apply || this.props.edit || this.props.info || this.props.reapply) {
                    this.getNetworkView(this.props?.data?.device_id);
                    this.setState({
                        ShowTemplate: false,
                    })
                }
                const hub_details = this.props?.data?.hub_details ? [this.props?.data?.hub_details] : [];
                const ipsec_details = this.props?.data?.ipsec_details ? [this.props?.data?.ipsec_details] : [];
                this.setState({
                    applyTemplateData: this.props.data,
                    hub_data: hub_details,
                    ipsec_data: ipsec_details
                }, () => {
                    if (this.props.data.template_type === "TunnelConfiguration") {
                        this.getUsedInterfaces();
                    }
                    if (ipsec_details?.[0]?.device_id !== undefined && this.props?.data?.template_type === "IPSecConfiguration") {
                        this.getIPSecDeviceANetwork(ipsec_details?.[0]?.device_id);
                    }
                })
            }
            if (this.props?.deviceDetails) {
                this.setState({
                    deviceDetails: this.props.deviceDetails
                })
            }
        }
    }
    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.data).length !== 0) {
            this.setState({
                applyTemplateData: nextProps.data
            }, () => {
                if (nextProps.data.template_type === "TunnelConfiguration") {
                    this.getUsedInterfaces();
                }
            })
        }
        if (nextProps.view || nextProps.edit || nextProps.info || nextProps.reapply) {
            if (this.props.deviceGroup) {
                if (nextProps.props?.id !== this.props?.props?.id)
                    this.getDeviceNetworkView(nextProps.props.id)
            }
            else {
                if (nextProps.data?.device_id !== this.props?.data?.device_id)
                    this.getNetworkView(nextProps.data.device_id);
            }
            this.setState({
                ShowTemplate: false,
                interfaces: nextProps.interfaces ? nextProps.interfaces : []
            })
        }
        if (nextProps.networkViewData) {
            this.setState({ networkViewData: nextProps.networkViewData })
        }
        if (nextProps?.deviceDetails) {
            this.setState({
                deviceDetails: nextProps.deviceDetails
            })
        }
        if (
            !isEqual(this.props?.data?.ipsec_details, nextProps?.data?.ipsec_details) &&
            nextProps?.data?.template_type === "IPSecConfiguration"
        ) {
            const ipsec_details = nextProps?.data?.ipsec_details ? [nextProps?.data?.ipsec_details] : [];
            this.setState({
                ipsec_data: ipsec_details
            }, () => {
                if (ipsec_details?.[0]?.device_id) {
                    this.getIPSecDeviceANetwork(ipsec_details?.[0]?.device_id);
                }
            })
        }
    }
    returnClassStatus = (device_status) => {
        switch (device_status) {
            case 0:
                return "status offline";
            case 1:
                return "status online";
            case 2:
                return "status added";
            case 3:
                return "status available";
            case 4:
                return "status approve";
            default:
                return "status offline";
        }
    };
    returnStatus = (device_status) => {
        switch (device_status) {
            case 0:
                return "Offline";
            case 1:
                return "Online";
            case 2:
                return "Added";
            case 3:
                return "Available";
            case 4:
                return "Auto Approved";
            default:
                return "Offline";
        }
    };

    getIPSecDeviceANetwork = (val) => {
        const data = {
            device_id: parseInt(val)
        }
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true })
            apiCall(urlMapping.getNetworkView(data), (response) => {
                this.setState({
                    showLoader: false,
                })
                if (response?.Success) {
                    this.setState({
                        ipsecDeviceANetwork: response?.data,
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Apply Template',
                            message: 'No Networks found on device. Network template must be applied to apply template on device',
                            show: true,
                            error: true,
                            showLoader: false,
                            disableApply: true
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        }
    }
    getModulesData = async () => {
        this.setState({
            showLoader: true,
        });
        await apiCall(urlMapping.getModuleList(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                Object.keys(response?.data?.models_interface)?.map(item => {
                    if (this.state.multipleSelect?.[0]?.group_id) {
                        if (item === this.state.multipleSelect?.[0]?.supported_models) {
                            this.setState({
                                interfaces: response?.data?.models_interface?.[item]
                            })
                        }
                    }
                    else if (item === this.state.multipleSelect?.[0]?.device_model) {
                        this.setState({
                            interfaces: response?.data?.models_interface?.[item]
                        })
                    }
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };
    checkDeviceTask = async () => {
        let deviceMac = [];
        if (this.state.selectedData && this.state.selectedData === 'groups' && this.state.selectedGroupDeviceData.length > 0) {
            this.state.selectedGroupDeviceData.map(item => {
                deviceMac = [...item.device_macs];
            })
            if (this.state.applyTemplateData.template_type === "TunnelConfiguration") {
                deviceMac.push(this.state.applyTemplateData.device_mac)
            }
        }
        else if (this.state.selectedData && this.state.selectedData === 'devices' && this.state.selectedDeviceData.length > 0) {
            this.state.selectedDeviceData.map(item => {
                deviceMac.push(item.device_mac)
            })
            if (this.state.applyTemplateData.template_type === "TunnelConfiguration") {
                deviceMac.push(this.state.applyTemplateData.device_mac)
            }
        }
        else {
            if (this.props && this.props.props && this.props.props.deviceData && this.props.props.deviceData.device_mac) {
                deviceMac.push(this.props.props.deviceData.device_mac);
            } else if (this.props && this.props.props && this.props.props.deviceData && this.props.props.deviceData.device_macs) {
                deviceMac = this.props.props.deviceData.device_macs;
            } else {
                deviceMac.push('test');
            }
        }
        let data = { device_mac: deviceMac, operation_type: ['Create Tunnel', 'Update Tunnel', 'TunnelConfiguration', 'Firmware Upgrade', 'BGPConfiguration'] }
        this.setState({
            showLoader: true
        });
        const trueArr = [];
        await apiCall(urlMapping.checkDeviceTask(data), response => {
            $("#errorMessage").modal("hide");
            this.setState({
                showLoader: false,
                show: false,
                hide: true
            });
            if (response?.success === true) {
                const data = response?.data;
                Object?.keys(data)?.forEach(item => {
                    if (data?.[item]?.status === true) {
                        trueArr?.push(data?.[item]);
                    }
                });
                if (trueArr?.length > 0) {
                    this.setState({
                        title: 'Device Task',
                        // message: trueArr[0].message,
                        message: "An operation on similar/dependent template is in-progress, please wait until the operation completes",
                        show: true,
                        error: true,
                        delete: false,
                        toggledClearRows: !this.state.toggledClearRows,
                    }, () => $("#errorMessage").modal("show"));
                }
                else {
                    this.setState({
                        show: false,
                        error: false,
                        delete: false
                    })
                }
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Device Task',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
        return trueArr;
    }
    getDeviceNetworkView = (id) => {
        let data = {
            group_id: id
        }
        if (String(data.group_id) !== "") {
            this.setState({ showLoader: true })
            apiCall(urlMapping.groupNetworks(data), (response) => {
                if (response?.success) {
                    this.setState({
                        showLoader: false, networkViewData: response?.data,
                    })
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showLoader: false, showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        }
    }
    closeApplyTemplate = () => {
        this.setState({
            applyTemplateData: this.props.data,
        })
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        if (this.props.configEdit) {
            this.props.configRefresh();
        }
    }
    checkVersions = (rows) => {
        const templateType = this.state.applyTemplateData.template_type === 'TunnelConfiguration' ? 'DataTunnel' : (
            this.state.applyTemplateData.template_type === 'DNSFiltering' ? 'DNSFilteringConfiguration'
                : this.state.applyTemplateData.template_type);
        const Model = rows.selectedRows[0].group_id ? rows.selectedRows[0].supported_models : rows.selectedRows[0].device_model;
        // Validation is added to check if template version of the template which we are applying to the device and matches with the latest device version for CHIMAY device
        if (Common.getModels(Model) === 'CHIMAY') {
            if (rows?.selectedRows[0]?.supported_templates[templateType]) {
                if (this.state?.applyTemplateData?.template_version?.chimay !== rows?.selectedRows[0]?.supported_templates[templateType]) {
                    this.setState(
                        {
                            title: "Version Mismatch",
                            message: this.state?.applyTemplateData?.template_version?.chimay < rows?.selectedRows[0]?.supported_templates[templateType] ?
                                'Template version is older and might not be compatible with device, please create new template to proceed further' :
                                'Device Firmware is out of sync with EDGE Manager. Please upgrade the device firmware.',
                            showPopup: true,
                            error: true,
                            delete: true,
                            template_type: templateType,
                            firmwareUpgrade: this.state?.applyTemplateData?.template_version?.chimay < rows?.selectedRows[0]?.supported_templates[templateType] ? false : true,
                            newTemplate: this.state?.applyTemplateData?.template_version?.chimay !== rows?.selectedRows[0]?.supported_templates[templateType] ? true : false
                        },
                        () => {
                            $("#versionErrorMessage").modal("show");
                        }
                    );
                }
            } else {
                //Show popup if value is missing means the device is older
                this.setState(
                    {
                        title: "Version Mismatch",
                        message: `Device firmware is older and its not supporting ${templateType}. Please upgrade your device.`,
                        showPopup: true,
                        error: true,
                        delete: true,
                        firmwareUpgrade: true
                    },
                    () => {
                        $("#versionErrorMessage").modal("show");
                    }
                );
            }
        }
        // Validation is added to check if template version of the template which we are applying to the deviceand matches with the latest device version for CBR device
        else if (Common.getModels(Model) === 'vCPE') {
            if (rows?.selectedRows[0]?.supported_templates[templateType]) {
                if (this.state?.applyTemplateData?.template_version?.cbr !== rows?.selectedRows[0]?.supported_templates[templateType]) {
                    this.setState(
                        {
                            title: "Version Mismatch",
                            message: this.state?.applyTemplateData?.template_version?.cbr < rows?.selectedRows[0]?.supported_templates[templateType] ?
                                'Template version is older and might not be compatible with device, please create new template to proceed further' :
                                'Device Firmware is out of sync with EDGE Manager. Please upgrade the device firmware.',
                            showPopup: true,
                            error: true,
                            delete: true,
                            template_type: templateType,
                            multipleSelect: rows.selectedRows[0],
                            firmwareUpgrade: this.state?.applyTemplateData?.template_version?.cbr < rows?.selectedRows[0]?.supported_templates[templateType] ? false : true,
                            newTemplate: this.state?.applyTemplateData?.template_version?.cbr < rows?.selectedRows[0]?.supported_templates[templateType] ? true : false
                        },
                        () => {
                            $("#versionErrorMessage").modal("show");
                        }
                    );
                }
            } else {
                //Show popup if value is missing means the device is older
                this.setState(
                    {
                        title: "Version Mismatch",
                        message: `Device firmware is older and its not supporting ${templateType}. Please upgrade your device.`,
                        showPopup: true,
                        error: true,
                        delete: true,
                        firmwareUpgrade: true
                    },
                    () => {
                        $("#versionErrorMessage").modal("show");
                    }
                );
            }
        }
        // Validation is added to check if template version of the template which we are applying to the deviceand matches with the latest device version for all types of MIFI devices
        else if (Common.getModels(Model) === 'Moretti_Model' || Common.getModels(Model) === 'Duvel_Model' || Common.getModels(Model) === 'Stormbreaker' || Common.getModels(Model) === 'Firestorm' ||
            Common.getModels(Model) === 'Bradshaw' || Common.getModels(Model) === 'Orval' || Common.getModels(Model) === 'Sander') {
            if (rows?.selectedRows[0]?.supported_templates[templateType]) {
                if (this.state?.applyTemplateData?.template_version?.mifi !== rows?.selectedRows[0]?.supported_templates[templateType]) {
                    this.setState(
                        {
                            title: "Version Mismatch",
                            message: this.state?.applyTemplateData?.template_version?.mifi < rows?.selectedRows[0]?.supported_templates[templateType] ?
                                'Template version is older and might not be compatible with device, please create new template to proceed further' :
                                'Device Firmware is out of sync with EDGE Manager. Please upgrade the device firmware.',
                            showPopup: true,
                            error: true,
                            delete: true,
                            template_type: templateType,
                            firmwareUpgrade: this.state?.applyTemplateData?.template_version?.mifi < rows?.selectedRows[0]?.supported_templates[templateType] ? false : true,
                            newTemplate: this.state?.applyTemplateData?.template_version?.mifi < rows?.selectedRows[0]?.supported_templates[templateType] ? true : false
                        },
                        () => {
                            $("#versionErrorMessage").modal("show");
                        }
                    );
                }
            }
            else {
                //Show popup if value is missing means the device is older
                this.setState(
                    {
                        title: "Version Mismatch",
                        message: `Device firmware is older and its not supporting ${templateType}. Please upgrade your device.`,
                        showPopup: true,
                        error: true,
                        delete: true,
                        firmwareUpgrade: true
                    },
                    () => {
                        $("#versionErrorMessage").modal("show");
                    }
                );
            }
        }
    }
    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "", selectedModel: "" });
        else {
            const templateType = this.state.applyTemplateData.template_type
            const Model = rows.selectedRows[0].group_id ? rows.selectedRows[0].supported_models : rows.selectedRows[0].device_model;
            // Validation added to check if supported_templates key is empty which means device is old 
            // if (rows?.selectedRows[0]?.supported_templates) {
            if (rows?.selectedRows?.[0]?.is_ippt && this.state.applyTemplateData.template_type === "NetworkConfiguration") {
                this.setState(
                    {
                        title: "IPPT Protocol",
                        message: "Network configuration cannot be applied from here as the device uses the IPPT protocol",
                        showPopup: true,
                        error: true,
                    },
                    () => {
                        $("#versionErrorMessage").modal("show");
                    }
                );
            }
            else if (rows?.selectedRows[0]?.supported_templates !== undefined && rows?.selectedRows[0]?.supported_templates !== null
                && Object.keys(rows?.selectedRows[0]?.supported_templates).length > 0 && templateType !== 'TriggerConfiguration') {
                this.checkVersions(rows);
            }
            else {
                // Show popup if value is missing means the device is older
                if (templateType !== 'TriggerConfiguration') {
                    const model = Common.getModels(Model);
                    const olderDevice = templateType === 'ModemConfiguration' &&
                        (
                            model === 'Moretti_Model' ||
                            model === 'Duvel_Model' ||
                            model === 'Stormbreaker' ||
                            model === 'Firestorm' ||
                            model === 'Bradshaw' ||
                            model === 'Orval' ||
                            model === 'Sander'
                        )
                        ? false : true;
                    this.setState(
                        {
                            title: "Version Mismatch",
                            message: olderDevice ? `Device firmware is older and might not be compatible with the latest template, some feature might not work properly` :
                                `Device firmware is older and its not supporting ${templateType}. Please upgrade your device.`,
                            showPopup: true,
                            error: true,
                            delete: true,
                            olderDevice
                        },
                        () => {
                            $("#versionErrorMessage").modal("show");
                        }
                    );
                }
            }
            // let selectedData = this.state.selectedDeviceData;
            // rows.selectedRows.map((item) => {
            //     if(selectedData.filter(device => device.id === item.id).length === 0) {
            //         selectedData.push(item)
            //     }
            // })
            this.setState({
                multipleSelect: rows.selectedRows,
                selectedModel: rows.selectedRows[0].group_id ? '' : Model,
                modelName: Model,
                // selectedDeviceData
            }, () => {
                if (this.state.applyTemplateData.template_type === 'NetworkConfiguration') {
                    this.getModulesData();
                }
            })
        }
    }
    multipleSelect1 = (rows) => {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect1: "" });
        else {
            this.setState({ multipleSelect1: rows.selectedRows }
            )
        }
    }
    closeDeviceTemplate = () => {
        this.setState({
            // LONG-3194-EM - Filtered device should be reset after cancel/close operation 
            searchVal: "",
            devicesTemplate: false,
            deviceGroupTemplate: false,
            deviceList: [],
            deviceGroupList: [],
            multipleSelect: "",
            toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
        });
    };
    getDeviceByModel = (device_model) => {
        let model = device_model;
        let commonDevice1 = this.state.selectedDeviceData;
        let data = { device_model: model };
        this.setState({
            showLoader: true,
            toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
        });
        apiCall(urlMapping.getDeviceLookUp(data), (response) => {
            if (response?.results) {
                let onlineDevices = response?.results
                // A comparer used to determine if two entries are equal.
                const isSameUser = (onlineDevices, commonDevice1) => onlineDevices?.device_id === commonDevice1?.device_id;

                // Get items that only occur in the left array,
                // using the compareFunction to determine equality.
                const onlyInLeft = (left, right, compareFunction) =>
                    left.filter(leftValue =>
                        !right.some(rightValue =>
                            compareFunction(leftValue, rightValue)));

                const onlyInA = onlyInLeft(onlineDevices, commonDevice1, isSameUser);
                const onlyInB = onlyInLeft(commonDevice1, onlineDevices, isSameUser);
                onlineDevices = [...onlyInA, ...onlyInB];
                this.setState({
                    showLoader: false,
                    deviceList: onlineDevices,
                    selectedData: 'devices',
                    selectedModel: commonDevice1?.length > 0 ? commonDevice1?.[0].device_model : ''
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    });
                }
            }
        });
    };
    getDeviceGroupByModel = (device_model) => {
        let model = device_model;
        let commonDevice2 = this.state.selectedGroupDeviceData;
        let data = { device_model: model };
        this.setState({
            showLoader: true,
        });
        apiCall(urlMapping.getDeviceGroupByModel(data), (response) => {
            if (response?.count) {
                //LONG-2017 MA - Getting success message when applying templates to the offline devices group
                let groupDevices = response?.results.filter((item) => item.is_all_device_online === 1);
                // A comparer used to determine if two entries are equal.
                const isSameUser = (groupDevices, commonDevice2) => groupDevices.group_id === commonDevice2.group_id;
                const onlyInLeft = (left, right, compareFunction) =>
                    left.filter(leftValue =>
                        !right.some(rightValue =>
                            compareFunction(leftValue, rightValue)));
                const onlyInA = onlyInLeft(groupDevices, commonDevice2, isSameUser);
                const onlyInB = onlyInLeft(commonDevice2, groupDevices, isSameUser);
                groupDevices = [...onlyInA, ...onlyInB];
                this.setState({
                    showLoader: false,
                    deviceGroupList: groupDevices,
                    selectedData: 'groups',
                    deviceGroupTemplate: true,
                    selectedModel: commonDevice2?.length > 0 ? commonDevice2?.[0]?.supported_models : '',
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                    });
                }
            }
        });
    };
    addgroupNetworks = () => {
        if (this.state.group_id) {
            let data = {
                group_id: this.state.group_id
            }
            if (this.state.applyTemplateData.template_type !== 'NetworkConfiguration') {
                if (String(data.group_id) !== "") {
                    this.setState({
                        showLoader: true
                    });
                    apiCall(urlMapping.groupNetworks(data), (response) => {
                        if (response?.success) {
                            // Long-1798 no wan data in CBR
                            if (this.state.applyTemplateData.template_type === 'WANConfiguration' &&
                                response?.data?.filter(item => item.network_zone === "wan")?.length < 1) {
                                this.setState({
                                    title: 'Apply Template',
                                    message: 'No WAN Network found with this device group! Please apply network configuration with WAN networks first.',
                                    showLoader: false,
                                    show: true,
                                    error: true,
                                    hide: false,
                                    adding: false
                                }, () => $("#errorMessage").modal("show"));
                            } else {
                                this.setState({
                                    showLoader: false,
                                    networkViewData: response?.data,
                                    deviceList: [],
                                    ShowTemplate: false,
                                    modelName: this.state.selectedGroupDeviceData?.[0].supported_models
                                }, () => {
                                    this.closeDeviceTemplate();
                                });
                            }
                        } else {
                            if (parseInt(response?.response?.status) === 401) {
                                this.setState({ showLoader: false, showRefreshModal: true })
                            } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                this.setState({
                                    title: 'Network Error',
                                    message: 'Something went wrong. Please try again later.',
                                    showLoader: false,
                                    isAxiosError: true,
                                    delete: false,
                                    error: true
                                }, () => $("#errorMessage").modal("show"))
                            } else {
                                this.setState({
                                    title: 'Apply Template',
                                    message: 'No Network found with this device group.Please apply network configuration first.',
                                    showLoader: false,
                                    show: true,
                                    error: true,
                                    hide: false,
                                    adding: false
                                }, () => $("#errorMessage").modal("show"));
                            }
                        }
                    });
                }
            } else {
                this.setState({
                    deviceList: [],
                    ShowTemplate: false,
                    modelName: this.state.selectedGroupDeviceData[0].supported_models
                }, () => {
                    this.closeDeviceTemplate();
                });
            }
        }
    }
    deviceGroupAdd = () => {
        this.setState({
            deviceGroupTemplate: true
        });
        if (
            this.state.applyTemplateData.template_type === 'IPSecConfiguration' &&
            this.state.selectedDeviceData.length > 0
        ) {
            this.setState({
                title: 'Apply Template',
                message: 'You can add only one device',
                show: true,
                error: true,
                hide: false
            }, () => $("#errorMessage").modal("show"));
        }
        else if (this.state.applyTemplateData.template_type === 'NetworkConfiguration') {
            let { selectedGroupDeviceData } = this.state;
            if (this.state.multipleSelect.length > 0)
                this.state.multipleSelect.map((item) => { selectedGroupDeviceData.push(item) })
            this.setState({
                group_id: this.state.multipleSelect?.[0]?.group_id,
                selectedDeviceData: [],
                networkViewData: [],
                selectedGroupDeviceData,
                deviceList: [],
                multipleSelect: "",
                keyUpdate: Math.random(),
                toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
            }, () => this.closeDeviceTemplate())
        } else {
            if (
                this.state.multipleSelect.length > 1 &&
                (this.state.applyTemplateData.template_type === 'TunnelConfiguration' ||
                    this.state.applyTemplateData.template_type === 'IPSecConfiguration'
                )
            ) {
                this.setState({
                    title: 'Apply Template',
                    message: 'You can add only one device',
                    show: true,
                    error: true,
                    hide: false
                }, () => $("#errorMessage").modal("show"));
            }
            else if (this.state.multipleSelect.length < 2) {
                this.setState({
                    group_id: this.state.multipleSelect?.[0]?.group_id,
                    selectedGroupDeviceData: this.state.multipleSelect,
                    deviceList: [],
                    multipleSelect: "",
                    keyUpdate: Math.random(),
                    toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
                }, () => this.closeDeviceTemplate())
            }
            else {
                this.setState({
                    title: 'Apply Template',
                    message: 'You can add only one device group',
                    show: true,
                    error: true,
                    hide: false
                }, () => $("#errorMessage").modal("show"));
            }
        };
    }
    deviceAdd = () => {
        if (this.state.multipleSelect.length === 1) {
            if (
                this.state.applyTemplateData.template_type === 'IPSecConfiguration' &&
                this.state.selectedDeviceData.length > 0
            ) {
                this.setState({
                    title: 'Apply Template',
                    message: 'You can add only one device',
                    show: true,
                    error: true,
                    hide: false
                }, () => $("#errorMessage").modal("show"));
            }
            else if (this.state.applyTemplateData.template_type !== 'NetworkConfiguration' &&
                this.state.applyTemplateData.template_type !== 'ModemConfiguration') {
                let data = {
                    device_id: this.state.multipleSelect?.[0]?.device_id,
                }
                if (String(data.device_id) !== "") {
                    this.setState({
                        showLoader: true,
                        adding: true,
                        exactModel: this.state.multipleSelect?.[0]?.device_model
                    })
                    apiCall(urlMapping.getNetworkView(data), (response) => {
                        if (response?.Success) {
                            // Long-1798 no wan data in CBR
                            if (this.state.applyTemplateData.template_type === 'WANConfiguration' &&
                                response?.data.filter(item => item.network_zone === "wan")?.length < 1) {
                                this.setState({
                                    title: 'Apply Template',
                                    message: 'No WAN Network found with this device! Please apply network configuration with WAN networks first.',
                                    show: true,
                                    showLoader: false,
                                    error: true,
                                    hide: false,
                                    adding: false
                                }, () => {
                                    this.closeDeviceTemplate();
                                    $("#errorMessage").modal("show")
                                });
                            } else {
                                this.setState({
                                    showLoader: false,
                                    networkViewData: response?.data,
                                    selectedDeviceData: this.state?.multipleSelect,
                                    deviceList: [],
                                    multipleSelect: "",
                                    adding: false,
                                    toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
                                }, () => this.closeDeviceTemplate());
                            }
                        }
                        else {
                            if (parseInt(response?.response?.status) === 401) {
                                this.setState({ showLoader: false, showRefreshModal: true })
                            }
                            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                this.setState({
                                    title: 'Network Error',
                                    message: 'Something went wrong. Please try again later.',
                                    showLoader: false,
                                    isAxiosError: true,
                                    delete: false,
                                    error: true
                                }, () => $("#errorMessage").modal("show"))
                            }
                            if (this.state.applyTemplateData.template_type !== 'NetworkConfiguration' &&
                                this.state.applyTemplateData.template_type !== 'TunnelConfiguration') {
                                this.setState({
                                    title: 'Apply Template',
                                    message: 'No Network found with this device.Please apply network configuration first.',
                                    showLoader: false,
                                    show: true,
                                    error: true,
                                    hide: false,
                                    adding: false
                                }, () => $("#errorMessage").modal("show"));
                            } else {
                                this.setState({
                                    showLoader: false,
                                    selectedDeviceData: this.state.multipleSelect,
                                    deviceList: [],
                                    multipleSelect: "",
                                    adding: false,
                                    toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
                                }, () => this.closeDeviceTemplate());
                            }
                        }
                    });
                }
            }
            else {
                if (this.state.applyTemplateData.template_type === 'ModemConfiguration') {
                    this.setState({
                        device_id: this.state.multipleSelect[0].device_id
                    })
                }
                this.setState({
                    networkViewData: [],
                    selectedDeviceData: this.state.multipleSelect,
                    deviceList: [],
                    multipleSelect: "",
                    toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
                }, () => this.closeDeviceTemplate())
            }
        } else {
            if (
                this.state.multipleSelect.length > 1 &&
                (this.state.applyTemplateData.template_type === 'TunnelConfiguration' ||
                    this.state.applyTemplateData.template_type === 'IPSecConfiguration'
                )
            ) {
                this.setState({
                    title: 'Apply Template',
                    message: 'You can add only one device',
                    show: true,
                    error: true,
                    hide: false
                }, () => $("#errorMessage").modal("show"));
            }
            else if (this.state.selectedDeviceData.length < 2 ||
                this.state.applyTemplateData.template_type === 'NetworkConfiguration') {
                let data = {
                    device_id: this.state.multipleSelect[0].device_id
                }
                let selectedData = JSON.parse(JSON.stringify(this.state.selectedDeviceData));
                this.state.multipleSelect.map((item) => {
                    if (selectedData.filter(device => device.id === item.id).length === 0) {
                        selectedData.push(item)
                    }
                })
                if (this.state.multipleSelect.length > 1 && this.state.selectedDeviceData.length > 0) {
                    this.setState({
                        title: 'Apply Template',
                        message: 'You can add only one device',
                        show: true,
                        error: true,
                        hide: false
                    }, () => $("#errorMessage").modal("show"));
                } else {
                    if (String(data.device_id) !== "") {
                        this.setState({ showLoader: true })
                        apiCall(urlMapping.getNetworkView(data), (response) => {
                            if (response?.Success) {
                                this.setState({
                                    showLoader: false,
                                    networkViewData: response?.data,
                                    selectedDeviceData: selectedData,
                                    deviceList: [],
                                    multipleSelect: "",
                                    toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
                                }, () => this.closeDeviceTemplate());
                            }
                            else {
                                if (parseInt(response?.response?.status) === 401) {
                                    this.setState({ showLoader: false, showRefreshModal: true })
                                }
                                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                    this.setState({
                                        title: 'Network Error',
                                        message: 'Something went wrong. Please try again later.',
                                        showLoader: false,
                                        isAxiosError: true,
                                        delete: false,
                                        error: true
                                    }, () => $("#errorMessage").modal("show"))
                                }
                                else {
                                    this.setState({
                                        networkViewData: [],
                                        selectedDeviceData: this.state.multipleSelect,
                                        deviceList: [],
                                        multipleSelect: "",
                                        toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
                                        showLoader: false
                                    }, () => this.closeDeviceTemplate())
                                }
                            }
                        });
                    }
                }
            }
            else {
                this.setState({
                    title: 'Apply Template',
                    message: 'You can add only one device',
                    show: true,
                    error: true,
                    hide: false
                }, () => $("#errorMessage").modal("show"));
            }
        };
    }
    getNetworkView = async (id) => {
        if (id) {
            let data = {
                device_id: id
            }
            if (String(data.device_id) !== "") {
                this.setState({ showLoader: true })
                await apiCall(urlMapping.getNetworkView(data), (response) => {
                    if (response?.Success) {
                        this.setState({
                            showLoader: false, networkViewData: response?.data,
                            ShowTemplate: this.props.reapply ? false : this.state.ShowTemplate
                        })
                    } else {
                        if (parseInt(response?.response?.status) === 401) {
                            this.setState({ showRefreshModal: true, showLoader: false })
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                        else {
                            this.setState({ showLoader: false })
                        }
                    }
                })
            }
        }
    }
    resetPopup = () => {
        if (this.props.nonSync) {
            this.closeApplyTemplate();
        }
        else if (this.state.showPopup === true) {
            if (this.state.firmwareUpgrade)
                this.firmwareUpgrade();
            else if (this.state.newTemplate) {
                this.openAddTemplate();
            }
            else if (!this.state.olderDevice) {
                this.setState({
                    showPopup: false,
                    multipleSelect: '',
                    toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
                }, () => {
                    $("#versionErrorMessage").modal("hide")
                })
            }
            else {
                this.setState({
                    showPopup: false,
                    olderDevice: false
                }, () => {
                    $("#versionErrorMessage").modal("hide")
                })
            }
        }
        else {
            this.setState(
                {
                    show: false,
                    error: false,
                    showPopup: false,
                },
                () => {
                    $("#errorMessage").modal("hide");
                    if (this.state.hide)
                        this.closeApplyTemplate();
                    if (this.state.isReorder) {
                        this.props?.networkReorder?.();
                    }
                }
            );
        }
    };
    resetInfoPopup = () => {
        this.setState({
            showInfoPopup: false,
            info: false
        }, () => {
            $("#versionInfoMessage").modal("hide")
        })
    }
    resetNetworkPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
            }
        );
    };
    deleteSelectedDevice = () => {
        let newList = [...this.state.selectedDeviceData];
        if (this.state.multipleSelect1.length > 0) {
            this.state.multipleSelect1.map((item) => {
                newList.splice(newList.indexOf(item), 1);
            });
            this.multipleSelect({ selectedRows: [] })
        }
        this.setState({
            selectedDeviceData: newList,
            toggledClearSelectedDeviceRows: !this.state.toggledClearSelectedDeviceRows,
            multipleSelect1: "",
            multipleSelect: ""
        }, () => {
            if (newList.length === 1) {
                this.getNetworkView(newList[0].device_id)
            }
        }
        );

    };
    deleteSelectedGroupDevice = () => {
        let newList = [...this.state.selectedGroupDeviceData];
        if (this.state.multipleSelect1.length > 0) {
            this.state.multipleSelect1.map((item) => {
                newList.splice(newList.indexOf(item), 1);
            });
        }
        this.setState({
            selectedGroupDeviceData: newList,
            toggledClearSelectedDeviceRows: !this.state.toggledClearSelectedDeviceRows,
            multipleSelect1: "",
            group_id: newList.length === 0 ? "" : this.state.group_id
        }, () => {
            if (newList.length === 1) {
                this.getNetworkView(newList[0].device_id)
            }
        }
        );

    };
    openAddTemplate = () => {
        if (this.state.applyTemplateData.template_type === "TunnelConfiguration") {
            this.getTunnelDeviceDetails();
            this.getUsedInterfaces();
        }
        else {
            if (this.state.selectedGroupDeviceData.length > 0)
                this.addgroupNetworks(); //calling this API only in case of selected group devices
            else {
                if (this.state.newTemplate) {
                    let { applyTemplateData } = this.state;
                    applyTemplateData.template_id = ""
                    applyTemplateData.template_name = ""
                    applyTemplateData.id = ""
                    this.setState({
                        ShowTemplate: false,
                        modelName: this.state.selectedModel,
                    }, () => {
                        // this.deviceAdd();
                        this.closeApplyTemplate();
                        this.props.handleAddTemplate(applyTemplateData);
                    });
                } else
                    this.setState({ ShowTemplate: false, modelName: this.state.selectedDeviceData?.[0]?.device_model });
            }
        }

    };

    getUsedInterfaces = () => {
        const device_mac = [];
        let group_id;
        if (this.props.deviceGroup || this.state.group_id) {
            if (this.props.deviceGroup) {
                this.getNetworksDeviceA(this.state.applyTemplateData.template_schema_json.device_A.device_id)
                device_mac.push(this.state.applyTemplateData.template_schema_json.device_A.device_mac);
                group_id = this.state.applyTemplateData.template_schema_json.group_id
            } else {
                device_mac.push(this.state.applyTemplateData.hub_details.hub_device_mac);
                group_id = this.state.group_id
            }

            const data = { device_mac, group_id }
            this.setState({
                showLoader: true,
            });
            apiCall(urlMapping.getTunnelUsedInterfaces(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.setState({
                        usedInterfaces_deviceA: response?.data?.device_A?.used_tunnel_link_interface,
                        usedInterfaces_deviceB: response?.data?.device_B?.used_tunnel_link_interface,
                        usedEndpoints_deviceA: response?.data?.device_A?.used_tunnel_endpoint,
                        usedEndpoints_deviceB: response?.data?.device_B?.used_tunnel_endpoint,
                    })
                } else if (response?.data) {
                    this.setState({
                        macAddress: Object.keys(response?.data).filter((item) => response?.data?.[item] == null),
                        title: 'Hub & Spoke',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show")
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Hub & Spoke',
                            message: response?.message,
                            show: true,
                            error: true,
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        } else if (this.props.edit || this.props.reapply) {
            device_mac.push(this.state.applyTemplateData.template_schema_json.device_A.device_mac);
            device_mac.push(this.state.applyTemplateData.template_schema_json.device_B.device_mac);
            const data = { device_mac, group_id }
            this.setState({
                showLoader: true,
            });
            apiCall(urlMapping.getTunnelUsedInterfaces(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.setState({
                        usedInterfaces_deviceA: response?.data?.device_A?.used_tunnel_link_interface,
                        usedInterfaces_deviceB: response?.data?.device_B?.used_tunnel_link_interface,
                        usedEndpoints_deviceA: response?.data?.device_A?.used_tunnel_endpoint,
                        usedEndpoints_deviceB: response?.data?.device_B?.used_tunnel_endpoint,
                    })
                } else if (response?.data) {
                    this.setState({
                        macAddress: Object.keys(response?.data).filter((item, key) => response?.data?.[item] == null),
                        title: 'Hub & Spoke',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show")
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        this.setState({
                            title: 'Hub & Spoke',
                            message: response?.message,
                            show: true,
                            error: true,
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
            this.getNetworksDeviceA(this.state.applyTemplateData.template_schema_json.device_A.device_id)
            this.getNetworksDeviceB(this.state.applyTemplateData.template_schema_json.device_B.device_id)
        }
    };

    getTunnelDeviceDetails = () => {
        const device_mac = [];
        let group_id;
        device_mac.push(this.state.applyTemplateData.device_mac);
        if (this.state.selectedDeviceData.length > 0) {
            device_mac.push(this.state.selectedDeviceData[0].device_mac);
        }
        if (this.state.selectedGroupDeviceData.length > 0) {
            group_id = this.state.selectedGroupDeviceData[0].group_id
        }
        const data = { device_mac, group_id };
        if (this.state.externalTemplate) {
            data.external_endpoint = true;
        }
        this.setState({
            showLoader: true,
        });
        apiCall(urlMapping.getTunnelDeviceDetails(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success
                && Object.entries(response?.data?.device_A)?.length !== 0 && Object.entries(response?.data?.device_B)?.length !== 0
            ) {
                if (response?.data?.device_A?.device_id) {
                    this.getNetworksDeviceA(response?.data?.device_A?.device_id);
                }
                if (!this.state.externalTemplate) {
                    if (response?.data?.device_B?.device_id) {
                        this.getNetworksDeviceB(response?.data?.device_B?.device_id);
                    }
                }
                this.setState({
                    tunnelDevicesData: response?.data,
                    pass: true
                }, () => {
                    if (this.state.selectedGroupDeviceData.length > 0)
                        this.addgroupNetworks(); //calling this API only in case of selected group devices
                    else
                        this.setState({
                            ShowTemplate: false,
                            modelName: this.state.selectedDeviceData?.[0]?.device_model
                        });
                })

            } else if (response?.data) {
                this.setState({
                    macAddress: Object.keys(response?.data).filter((item, key) => response?.data?.[item] == null),
                    tunnelDevicesData: undefined,
                    title: 'Hub & Spoke',
                    message: response?.data?.message,
                    show: true,
                    error: true,
                    pass: false,
                    newTemplate: false
                }, () => {
                    $("#errorMessage").modal("show")
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        tunnelDevicesData: undefined,
                        title: 'Hub & Spoke',
                        message: response?.message,
                        show: true,
                        error: true,
                        pass: false
                    }, () => {
                        $("#errorMessage").modal("show")
                    });
                }
            }
        });
    }
    getNetworksDeviceA = (id) => {
        let data = {
            device_id: id
        }
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true })
            apiCall(urlMapping.getNetworkView(data), (response) => {
                this.setState({
                    showLoader: false,
                })
                if (response?.Success) {
                    this.setState({
                        deviceA_networks: response?.data,
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Apply Template',
                            message: 'No Networks found on device. Network template must be applied to apply template on device',
                            show: true,
                            error: true,
                            showLoader: false,
                            disableApply: true
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        }
    }
    getNetworksDeviceB = (id) => {
        let data = {
            device_id: id
        }
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true })
            apiCall(urlMapping.getNetworkView(data), (response) => {
                if (response?.Success) {
                    this.setState({
                        showLoader: false,
                        deviceB_networks: response?.data
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true, showLoader: false })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Apply Template',
                            message: 'No Networks found on device. Network template must be applied to apply template on device',
                            show: true,
                            error: true,
                            showLoader: false,
                            disableApply: true
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        }
    }
    closeAddTemplate = () => {
        this.setState({ ShowTemplate: true });
        if (this.props.configEdit) {
            this.props.configRefresh();
        }
    };
    applyTemplate = async (jsonDta) => {
        const haveTask = await this.checkDeviceTask();
        if (haveTask?.filter(val => val?.message?.includes("Task is already in In-Progress for the device."))?.length === 0) {
            let deviceIdArray = [];
            let deviceGroupId = [];
            if (!this.props.deviceGroup) {
                if (this.state.selectedDeviceData.length > 0) {
                    this.state.selectedDeviceData.map((item) => {
                        if (item)
                            deviceIdArray.push(item.device_id);
                    });
                } else {
                    if (this.state.applyTemplateData.template_type === 'IPSecConfiguration' && this.props.edit) {
                        deviceIdArray.push(this.state.applyTemplateData?.ipsec_json?.vpnConfig?.deviceB?.device_id)
                    }
                    else if (this.state.applyTemplateData.device_id)
                        deviceIdArray.push(this.state.applyTemplateData.device_id);
                }
            }
            if (this.state.selectedGroupDeviceData.length > 0) {
                this.state.selectedGroupDeviceData.map((item) => {
                    deviceGroupId.push(item.group_id);
                });
            } else if (this.props.group_id) {
                deviceGroupId.push(this.props.group_id);
            }
            let data = {
                template_id:
                    this.props.edit || this.props.reapply
                        ? this.state.applyTemplateData.template_id
                        : this.state.applyTemplateData.id,
                template_group_id: '',
                device_id: deviceIdArray,
                group_id: deviceGroupId,
                template_schema_json: jsonDta
                    ? jsonDta
                    : this.state.applyTemplateData.template_schema_json,
                template_operation_tag: "",
                tunnel_id: "",
                hub_and_spoke_uuid: "",
                ...(this?.props?.edit && {
                    edit_and_reapply: "1",
                    config_type: this.props?.data?.applied_from || ""
                }),
            };

            if (this.state.applyTemplateData.template_type === 'NetworkConfiguration' ||
                this.state.applyTemplateData.template_type === 'CaptivePortal' ||
                this.state.applyTemplateData.template_type === 'IPSecConfiguration') {
                data.template_operation_tag = this.props.edit && this.props.exactModel !== 'V2000' ? "add" : "add,delete";
            }
            if (this.state.applyTemplateData.template_type === 'BGPConfiguration' ||
                this.state.applyTemplateData.template_type === 'OSPFConfiguration')
                data.template_operation_tag = "add,delete";
            if (this.state.applyTemplateData.template_type === 'FirewallConfiguration' ||
                this.state.applyTemplateData.template_type === 'QoSConfiguration' ||
                this.state.applyTemplateData.template_type === 'DNSFiltering')
                data.template_operation_tag = "add,delete";
            if (this.state.applyTemplateData.template_type === 'WANConfiguration' ||
                this.state.applyTemplateData.template_type === 'WiFiConfiguration' ||
                this.state.applyTemplateData.template_type === 'ModemConfiguration' ||
                this.state.applyTemplateData.template_type === 'DeviceConfiguration')
                data.template_operation_tag = "add";
            if (this.state.applyTemplateData.template_type === 'SystemConfiguration')
                data.template_operation_tag = "add,delete"
            if (this.state.applyTemplateData.template_type === "StaticRoute") {
                let vtyEnabled = false;
                let vtyDisabled = false;
                data.template_schema_json.details.map((item) => {
                    if (item.isvtysh === '1')
                        vtyEnabled = true;
                    else
                        vtyDisabled = true;
                });
                if (vtyEnabled === true && vtyDisabled === true) {
                    data.template_operation_tag = 'vtysh,add,delete';
                }
                else if (vtyEnabled === true) {
                    data.template_operation_tag = 'vtysh';
                } else {
                    data.template_operation_tag = "add,delete";
                }
            }
            if (this.state.applyTemplateData.template_type === "TunnelConfiguration") {
                if ((this.props.edit || this.props.reapply) && this.props.deviceGroup) {
                    data.hub_and_spoke_uuid = this.state.applyTemplateData.hub_and_spoke_uuid
                }
                data.tunnel_id = this.state.applyTemplateData.tunnel_id;
            }
            if (this.state.applyTemplateData.template_type === "NetworkConfiguration") {
                if (this.props.deviceGroup && data.template_schema_json.lanConfig.filter(network => network.protocol === 'ippt' && network.isdeleted !== '1').length > 0) {
                    data.ippt_mac = 'IPPT'
                } else {
                    data.ippt_mac = data.template_schema_json.lanConfig.filter(network => network.protocol === 'ippt' && network.isdeleted !== '1').length > 0 ?
                        (this.props?.device_mac ? this.props?.device_mac : this.state?.selectedDeviceData[0]?.device_mac) : "";
                }
            }
            data.template_schema_json.device_model = this.props.exactModel ? this.props.exactModel : this.state.selectedModel;
            this.setState({
                showLoader: true,
            });
            if (haveTask.length === 0) {
                if (this.props.reapply === true && !this.props.edit) {
                    let operationId = [];
                    operationId.push(this.props.data.operation_id)
                    let data = { operation_ids: operationId };
                    apiCall(urlMapping.retryOperation(data), (response) => {
                        this.props.dispatch({
                            type: Action.RESET_APPLY_TEMPLATE,
                        });
                        if (response?.success) {
                            this.setState({
                                title: 'Re-apply Template',
                                message: response?.message,
                                showLoader: false,
                                show: true,
                                error: false,
                                isReorder: this.state.applyTemplateData.template_type === 'NetworkConfiguration' ? true : false
                            }, () => {
                                $("#errorMessage").modal("show");
                            });
                        }
                        else {
                            if (parseInt(response?.response?.status) === 401) {
                                this.setState({ showRefreshModal: true, showLoader: false })
                            }
                            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                this.setState({
                                    title: 'Network Error',
                                    message: 'Something went wrong. Please try again later.',
                                    showLoader: false,
                                    isAxiosError: true,
                                    delete: false,
                                    error: true
                                }, () => $("#errorMessage").modal("show"))
                            }
                            else {
                                this.setState({
                                    title: 'Re-apply Template',
                                    message: response?.message,
                                    showLoader: false,
                                    show: true,
                                    error: true,
                                }, () => $("#errorMessage").modal("show"));
                            }
                        }
                    })
                }
                else {
                    apiCall(urlMapping.applyTemplate(data), (response) => {
                        this.props.dispatch({
                            type: Action.RESET_APPLY_TEMPLATE,
                        });
                        if (response?.success) {
                            if (this.props?.nonSync) {
                                if (this.state.applyTemplateData.template_type === "FirewallConfiguration") {
                                    this.setState(
                                        {
                                            showLoader: false,
                                            title: "Apply Template",
                                            message: 'Apply Template Initiated Successfully',
                                            showLink: true,
                                            id: response?.data?.length > 0 ? response?.data?.[0]?.device_operation_uuid : '',
                                        },
                                        () => {
                                            $("#exampleModal").modal("show");
                                        }
                                    );
                                }
                                else {
                                    this.closeNonSync();
                                }
                            }
                            else {
                                this.setState(
                                    {
                                        showLoader: false,
                                        title: this.props?.tunnelEdit ? "Edit Tunnel" : "Apply Template",
                                        message: this.state.applyTemplateData.template_type === 'NetworkConfiguration' ?
                                            'Apply Template Initiated Successfully. \n \n NOTE: Map, newly created network to the respective zone through Firewall Template. \n \n To view the Operation logs '
                                            : ((this.props?.tunnelEdit ? 'Edit Tunnel' : 'Apply Template') + ' Initiated Successfully. To view the Operation logs '),
                                        showLink: true,
                                        id: response?.data?.length > 0 ? response?.data?.[0]?.device_operation_uuid : '',
                                        isReorder: this.state.applyTemplateData.template_type === 'NetworkConfiguration' ? true : false
                                    },
                                    () => {
                                        this.props.refreshList();
                                        $("#exampleModal").modal("show");
                                    }
                                );
                                return true;
                            }
                        } else {
                            if (parseInt(response?.response?.status) === 401) {
                                this.setState({ showLoader: false, showRefreshModal: true })
                            }
                            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                this.setState({
                                    title: 'Network Error',
                                    message: 'Something went wrong. Please try again later.',
                                    showLoader: false,
                                    isAxiosError: true,
                                    delete: false,
                                    error: true
                                }, () => $("#errorMessage").modal("show"))
                            } else {
                                this.setState(
                                    {
                                        showLoader: false,
                                        title: "Device Applied",
                                        message: response?.message,
                                        show: true,
                                        error: true,
                                    },
                                    () => {
                                        this.closeApplyTemplate();
                                        $("#errorMessage").modal("show")
                                    }
                                );
                            }
                        }
                    });
                }
            } else {
                if (!this.props?.nonSync) {
                    this.props.dispatch({
                        type: Action.RESET_APPLY_TEMPLATE,
                    });
                    this.setState({
                        showLoader: false,
                    }, () => this.closeApplyTemplate());
                }
            }
        }
    };
    openOperationList = (value) => {
        $("#exampleModal").modal("hide");
        $("#errorMessage").modal("hide");
        // this.props.dispatch({
        //     type: Action.CLOSE_APPLY_TEMPLATE,
        // });
        this.closeApplyTemplate();
        this.props.props.history.push({
            pathname: '/operations',
            state: { data: value }
        })
    }
    closeLinkpopup = () => {
        $("#exampleModal").modal("hide");
        $("#exampleModalCopy").modal("hide");
        $("#exampleModalNetwork").modal("hide");
        $("#errorMessage").modal("hide");
        this.setState({ showLink: false, id: '' }
            , () => {
                this.closeApplyTemplate();
                if (this.state.isReorder) {
                    this.props?.networkReorder?.();
                }
            }
        );
    }
    closeNonSync = () => {
        $("#exampleModal").modal("hide");
        $("#exampleModalCopy").modal("hide");
        $("#exampleModalNetwork").modal("hide");
        $("#errorMessage").modal("hide");
        this.setState({
            showLink: false,
            id: ''
        }
            , async () => {
                await this.props.dispatch({
                    type: Action.CLOSE_APPLY_TEMPLATE,
                });
                await this.props.refreshList();
            })
    }
    isSubstring = (s1) => {
        this.setState({ searchVal: s1 })
    }
    getModel = () => {
        let model = Common.getModels(this.state.modelName);
        return model;
    }
    firmwareUpgrade = () => {
        $("#exampleModal").modal("hide");
        $("#errorMessage").modal("hide");
        $("#versionErrorMessage").modal("hide")
        this.closeApplyTemplate();
        this.props.props.history.push({
            pathname: '/deviceFirmware',
            state: { data: this.state.modelName }
        })
    }
    closeVersionError = () => {
        this.setState({
            firmwareUpgrade: false,
            newTemplate: false,
            show: false,
            error: false,
            multipleSelect: ''
        })
    }
    regenerateKey = () => {
        let data = {
            device_macs: this.state.macAddress
        }
        this.setState({ showLoader: true })
        apiCall(urlMapping.keyRegeneration(data), (response) => {
            this.setState({
                showLoader: false,
            })
            if (response?.success) {
                this.setState({
                    title: 'Hub & Spoke',
                    message: 'Public Key Added',
                    show: true,
                    error: false,
                    showLoader: false,
                    hide: false
                }, () => {
                    $("#errorMessage").modal("show")
                });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                this.setState({
                    title: 'Network Error',
                    message: 'Something went wrong. Please try again later.',
                    showLoader: false,
                    isAxiosError: true,
                    delete: false,
                    error: true
                }, () => $("#errorMessage").modal("show"))
            }
            else {
                this.setState({
                    title: 'Create Tunnel',
                    message: response?.message,
                    show: true,
                    error: true,
                    showLoader: false
                }, () => {
                    $("#errorMessage").modal("show")
                });
            }
        }
        );
    }
    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div
                    className={this.props.openApplyTemplate ? "slide-overlay open" : "slide-overlay"}
                ></div>
                <div
                    className={this.props.openApplyTemplate ? "sidebar-slide w-750 open" : "sidebar-slide w-750"}
                >
                    <div className={this.state.devicesTemplate ? "disabled-slide" : ""}></div>
                    <div className={this.state.deviceGroupTemplate ? "disabled-slide" : ""}></div>
                    <div className="s-header">
                        <h1 className="ss-title">{
                            this.props?.reorderEnable
                                ? "Reorder Template"
                                : this.props?.tunnelEdit
                                    ? "Edit Tunnel"
                                    : (this.props?.configEdit
                                        ? "Edit Template"
                                        : "Apply Template"
                                    )
                        }</h1>
                        <button
                            style={this.props?.disableCancel ? { cursor: "not-allowed" } : {}}
                            disabled={this.props?.disableCancel || false}
                            onClick={() => this.closeApplyTemplate()}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    {this.state.showPopup && (
                        <div className="modal fade errorMessage" id="versionErrorMessage" tabIndex="-1" data-backdrop="static"
                            role="dialog" aria-labelledby="errorMessageTitle" aria-hidden="true"
                        >
                            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-white border-0">
                                    </div>
                                    <div className="modal-body pt-5">
                                        <div className="error-msg">
                                            <span className={this.state.error ? "error-icon" : "success-icon"}>{this.state.error ? (this.state.info ? 'i' : '!') : ''}</span>
                                            <div className="txt1">{this.state.title}</div>
                                            <div className="txt2">{this.state.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-top-0 justify-content-center pb-4">
                                        {(this.state.firmwareUpgrade || this.state.newTemplate) &&
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                data-dismiss="modal"
                                                onClick={this.closeVersionError}
                                            >
                                                Cancel</button>}
                                        {(this.state.delete || this.state.error) && <button type="button" className={this.state.error ? "btn btn-danger" : "btn btn-primary"}
                                            data-dismiss="modal"
                                            // disabled={this.state.scheduleLater === true && this.state.startDate === null}
                                            onClick={() => this.resetPopup()}>
                                            {'OK'}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.showInfoPopup && (
                        <div className="modal fade errorMessage" id="versionInfoMessage" tabIndex="-1" data-backdrop="static"
                            role="dialog" aria-labelledby="errorMessageTitle" aria-hidden="true"
                        >
                            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-white border-0">
                                    </div>
                                    <div className="modal-body pt-5">
                                        <div className="error-msg">
                                            <span className={this.state.error ? "error-icon" : "success-icon"}>{this.state.error ? (this.state.info ? 'i' : '!') : ''}</span>
                                            <div className="txt1">{this.state.title}</div>
                                            <div className="txt2">{this.state.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-top-0 justify-content-center pb-4">
                                        {/* {(this.state.delete || this.state.approve) && <button type="button" className="btn btn-light"
                    data-dismiss="modal"
                    onClick={() => this.resetPopup()}>
                    {'Cancel'}</button>} */}
                                        {(this.state.info) && <button type="button" className={this.state.error ? "btn btn-danger" : "btn btn-primary"}
                                            data-dismiss="modal"
                                            // disabled={this.state.scheduleLater === true && this.state.startDate === null}
                                            onClick={() => this.resetInfoPopup()}>
                                            {'OK'}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.ShowTemplate ? (
                        <div>
                            <div className="s-body apply-template-first">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Template Name</label>
                                            <input
                                                type="text"
                                                readOnly
                                                className="form-control"
                                                value={this.state.applyTemplateData.template_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="d-flex align-items-center justify-content-end ddg">

                                        </div>
                                    </div>
                                </div>
                                {/* {this.state.isHubInfo && */}
                                {
                                    (this.state.applyTemplateData.template_type === "TunnelConfiguration")
                                    && <>
                                        <div className="page-sub-title">
                                            <div className="dropdown network-action">
                                                <strong>{this.state.hub_data.hub_device_name}</strong>
                                            </div>
                                        </div>
                                        <div className=""
                                            key={this.state.keyUpdate}
                                        >
                                            <DataTable
                                                columns={this.state.hubInfo}
                                                data={this.state.hub_data}
                                                highlightOnHover
                                                fixedHeader
                                                onSelectedRowsChange={(data) => this.multipleSelect1(data)}
                                                clearSelectedRows={this.state.toggledClearSelectedDeviceRows}
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    (this.state.applyTemplateData.template_type === "IPSecConfiguration")
                                    && <>
                                        <div className="page-sub-title">
                                            <div className="dropdown network-action">
                                                <strong>{this.state?.ipsec_data?.device_name}</strong>
                                            </div>
                                        </div>
                                        <div className=""
                                            key={this.state.keyUpdate}
                                        >
                                            <DataTable
                                                columns={this.state.IPSecColumn}
                                                data={this.state.ipsec_data}
                                                highlightOnHover
                                                fixedHeader
                                            />
                                        </div>
                                    </>
                                }
                                <div className="page-sub-title">
                                    <div className="dropdown network-action">
                                        <strong>Devices</strong>
                                        <img
                                            src="/images/svg-icons/add.svg"
                                            className="add-btn ml-2"
                                            alt="" data-toggle="dropdown" />
                                        <ul className="dropdown-menu"
                                            style={{
                                                ...(this.state.applyTemplateData.template_type === "TunnelConfiguration" || this.state.applyTemplateData.template_type === "IPSecConfiguration") && {
                                                    width: "190px",
                                                    minHeight: "110px"
                                                }
                                            }}
                                        >
                                            {/* LONG-2353  */}
                                            {(this.state.selectedGroupDeviceData.length > 0)
                                                ? <li className="disabled">
                                                    <img
                                                        src="/images/svg-icons/add-white.svg"
                                                        className="add-btn disabled"
                                                        alt=""
                                                    /> Devices</li>
                                                : <li
                                                    onClick={() => {
                                                        this.setState({
                                                            devicesTemplate: true,
                                                            externalTemplate: false
                                                        });
                                                        this.getDeviceByModel(
                                                            this.state.applyTemplateData.device_model
                                                        );
                                                    }}>
                                                    <img
                                                        src="/images/svg-icons/add-white.svg"
                                                        className="add-btn"
                                                        alt=""
                                                    /> Devices</li>
                                            }
                                            {
                                                (this.state.selectedDeviceData.length > 0 || this.state.applyTemplateData.template_type === 'ModemConfiguration')
                                                    ? <li className="disabled">
                                                        <img
                                                            src="/images/svg-icons/add-white.svg"
                                                            className="add-btn disabled"
                                                            alt=""
                                                        /> Device Group</li>
                                                    : <li
                                                        onClick={() => {
                                                            this.setState({
                                                                deviceGroupTemplate: true,
                                                                externalTemplate: false
                                                            });
                                                            this.getDeviceGroupByModel(
                                                                this.state.applyTemplateData.device_model
                                                            );
                                                        }}>
                                                        <img
                                                            src="/images/svg-icons/add-white.svg"
                                                            className="add-btn"
                                                            alt=""
                                                        /> Device Group</li>
                                            }
                                            {
                                                (this.state.applyTemplateData.template_type === "TunnelConfiguration" ||
                                                    this.state.applyTemplateData.template_type === "IPSecConfiguration") &&
                                                (
                                                    (this.state.selectedDeviceData.length > 0 || this.state.selectedGroupDeviceData.length > 0)
                                                        ? <li className="disabled">
                                                            <img
                                                                src="/images/svg-icons/add-white.svg"
                                                                className="add-btn disabled"
                                                                alt=""
                                                            /> External Endpoint</li>
                                                        : <li
                                                            className={
                                                                (
                                                                    (this.state.applyTemplateData?.template_type === "TunnelConfiguration" &&
                                                                        this.state.hub_data?.[0]?.hub_status !== 1) ||
                                                                    (this.state.applyTemplateData?.template_type === "IPSecConfiguration" &&
                                                                        this.state.ipsec_data?.[0]?.ipsec_status !== 1)
                                                                )
                                                                    ? "disabled"
                                                                    : ""
                                                            }
                                                            onClick={() =>
                                                                this.setState({
                                                                    externalTemplate: true,
                                                                }, () =>
                                                                    (
                                                                        (this.state.applyTemplateData?.template_type === "TunnelConfiguration" &&
                                                                            this.state.hub_data?.[0]?.hub_status !== 1) ||
                                                                        (this.state.applyTemplateData?.template_type === "IPSecConfiguration" &&
                                                                            this.state.ipsec_data?.[0]?.ipsec_status !== 1)
                                                                    )
                                                                        ? undefined
                                                                        : this.openAddTemplate()
                                                                )
                                                            }>
                                                            <img
                                                                src="/images/svg-icons/add-white.svg"
                                                                className={
                                                                    (
                                                                        (this.state.applyTemplateData?.template_type === "TunnelConfiguration" &&
                                                                            this.state.hub_data?.[0]?.hub_status !== 1) ||
                                                                        (this.state.applyTemplateData?.template_type === "IPSecConfiguration" &&
                                                                            this.state.ipsec_data?.[0]?.ipsec_status !== 1)
                                                                    )
                                                                        ? "add-btn disabled"
                                                                        : "add-btn"
                                                                }
                                                                alt=""
                                                            /> External Endpoint</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    {this.state.selectedData === 'groups' ? <div className="device-action">
                                        <span className="text-muted">
                                            {this.state.selectedGroupDeviceData.length} device group added
                                        </span>
                                        <span className={(this.state.selectedGroupDeviceData.length > 0 && this.state.multipleSelect1.length > 0) ? "icon-box" : "icon-box disabled"}>
                                            <img
                                                src="/images/svg-icons/delete.svg"
                                                alt=""
                                                onClick={this.deleteSelectedGroupDevice}
                                            />
                                        </span>
                                    </div> : <div className="device-action">
                                        <span className="text-muted">
                                            {this.state.selectedDeviceData.length} devices added
                                        </span>
                                        <span className={(this.state.selectedDeviceData.length > 0 && this.state.multipleSelect1.length > 0) ? "icon-box" : "icon-box disabled"}>
                                            <img
                                                src="/images/svg-icons/delete.svg"
                                                alt=""
                                                onClick={(e) => this.deleteSelectedDevice(e)}
                                            />
                                        </span>
                                    </div>}
                                </div>
                                {this.state.selectedDeviceData.length > 0 ? <> <div className="device-table"
                                    key={this.state.keyUpdate}
                                >
                                    <DataTable
                                        columns={this.state.deviceListColumnsDiff}
                                        data={this.state.selectedDeviceData}
                                        selectableRows
                                        highlightOnHover
                                        fixedHeader
                                        onSelectedRowsChange={(data) => this.multipleSelect1(data)}
                                        clearSelectedRows={this.state.toggledClearSelectedDeviceRows}
                                    />
                                </div> </> :
                                    <div className="device-table"
                                        key={this.state.keyUpdate}
                                    >
                                        <DataTable
                                            columns={this.state.deviceListGroupColumnsDiff}
                                            data={this.state.selectedGroupDeviceData}
                                            selectableRows
                                            highlightOnHover
                                            fixedHeader
                                            onSelectedRowsChange={(data) => this.multipleSelect1(data)}
                                            clearSelectedRows={this.state.toggledClearSelectedDeviceRows}
                                        />
                                    </div>}
                            </div>
                            <div className="s-footer">
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeApplyTemplate()}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary"
                                    disabled={
                                        (this.state.selectedDeviceData.length === 0 &&
                                            this.state.selectedGroupDeviceData.length === 0) ||
                                        (this.state.applyTemplateData.template_type === "TunnelConfiguration" &&
                                            this.state.hub_data?.[0].hub_status !== 1) ||
                                        (this.state.applyTemplateData.template_type === "IPSecConfiguration" &&
                                            this.state.ipsec_data?.[0].ipsec_status !== 1)
                                    }
                                    onClick={() => this.openAddTemplate()}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="s-body apply-template-second">
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Template Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={this.state.applyTemplateData.template_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Feature Type</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={Common.getTemplateType(this.state.applyTemplateData.template_type)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {this.state.applyTemplateData.template_type ===
                                    "StaticRoute" &&
                                    this.props.edit === true && (
                                        <StaticRoute
                                            templateData={this.state.applyTemplateData}
                                            networkViewData={this.state.networkViewData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            disableCancel={this.props?.disableCancel}
                                            reorderEnable={this.props?.reorderEnable}
                                            apply={true}
                                            view={this.props.view}
                                            edit={this.props.edit}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {(this.state.applyTemplateData.template_type ===
                                    "StaticRoute" &&
                                    this.props.edit !== true) ? (
                                    <StaticTemplate
                                        templateData={this.state.applyTemplateData}
                                        apply={true}
                                        view={this.props.view}
                                        link={true}
                                        networkViewData={this.state.networkViewData}
                                        selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                        exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                        template_schema_json={
                                            this.state.applyTemplateData.template_schema_json
                                        }
                                        closeAddTemplate={() => this.closeAddTemplate()}
                                        applyTemplate={(data) => this.applyTemplate(data)}
                                        refreshList={() => this.props.refreshList()}
                                        closeApplyTemplate={() => this.closeApplyTemplate()}
                                        reapply={this.props.reapply}
                                    />

                                ) : ""}
                                {this.state.applyTemplateData.template_type ===
                                    "NetworkConfiguration" && ((this.props.selectedModel && this.props.selectedModel === 'vCPE') ||
                                        this.getModel() === 'vCPE') ? (
                                    <NetworkConfigCR
                                        templateData={this.state.applyTemplateData}
                                        configAdd={this?.props?.configAdd}
                                        configEdit={this.props?.configEdit}
                                        configDeviceId={this.props?.configDeviceId}
                                        configModels={this?.props?.configModels}
                                        disableCancel={this.props?.disableCancel}
                                        apply={true}
                                        view={this.props.view}
                                        edit={this.props.edit}
                                        link={true}
                                        is_network_ip_changed={this.props.group_id ? "1" : "0"}
                                        exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                        interfaces={this.state.interfaces}
                                        group_id={this.state.group_id ? this.state.group_id : this.props.group_id}
                                        selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                        template_schema_json={
                                            this.state.applyTemplateData.template_schema_json
                                        }
                                        closeAddTemplate={() => this.closeAddTemplate()}
                                        applyTemplate={(data) => this.applyTemplate(data)}
                                        refreshList={() => this.props.refreshList()}
                                        configNetworkReorder={() => this.props?.configNetworkReorder?.()}
                                        reapply={this.props.reapply}
                                        device_mac={this.props?.device_mac ? this.props?.device_mac : this.state?.selectedDeviceData[0]?.device_mac || ''}
                                    />
                                ) :
                                    this.state.applyTemplateData.template_type ===
                                    "NetworkConfiguration" && (
                                        <NetworkConfiguration
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            disableCancel={this.props?.disableCancel}
                                            apply={true}
                                            view={this.props.view}
                                            edit={this.props.edit}
                                            group_id={this.state.group_id ? this.state.group_id : this.props.group_id}
                                            device_group_uuid={this.props.device_group_uuid ? this.props.device_group_uuid : ""}
                                            is_network_ip_changed={this.props.group_id ? "1" : "0"}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            interfaces={this.state.interfaces}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            configNetworkReorder={() => this.props?.configNetworkReorder?.()}
                                            reapply={this.props.reapply}
                                            device_mac={this.props?.device_mac ? this.props?.device_mac : this.state?.selectedDeviceData[0]?.device_mac || ''}
                                            deviceDetails={this.state.deviceDetails}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "WANConfiguration" && ((this.props.selectedModel && this.props.selectedModel === 'vCPE') ||
                                        this.getModel() === 'vCPE') ?
                                    <WanConfigCR
                                        templateData={this.state.applyTemplateData}
                                        configAdd={this?.props?.configAdd}
                                        configEdit={this.props?.configEdit}
                                        configDeviceId={this.props?.configDeviceId}
                                        configModels={this?.props?.configModels}
                                        disableCancel={this.props?.disableCancel}
                                        reorderEnable={this.props?.reorderEnable}
                                        apply={true}
                                        view={this.props.view}
                                        edit={this.props.edit}
                                        deviceGroup={this.props.deviceGroup}
                                        group_id={this.state.group_id}
                                        networkViewData={this.state.networkViewData}
                                        selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                        exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                        template_schema_json={
                                            this.state.applyTemplateData.template_schema_json
                                        }
                                        closeAddTemplate={() => this.closeAddTemplate()}
                                        applyTemplate={(data) => this.applyTemplate(data)}
                                        refreshList={() => this.props.refreshList()}
                                        closeApplyTemplate={() => this.closeApplyTemplate()}
                                        reapply={this.props.reapply}
                                    /> :
                                    this.state.applyTemplateData.template_type ===
                                    "WANConfiguration" &&
                                    <WanConfig
                                        templateData={this.state.applyTemplateData}
                                        configAdd={this?.props?.configAdd}
                                        configEdit={this.props?.configEdit}
                                        configDeviceId={this.props?.configDeviceId}
                                        configModels={this?.props?.configModels}
                                        disableCancel={this.props?.disableCancel}
                                        reorderEnable={this.props?.reorderEnable}
                                        apply={true}
                                        view={this.props.view}
                                        edit={this.props.edit}
                                        deviceGroup={this.props.deviceGroup}
                                        group_id={this.state.group_id}
                                        networkViewData={this.state.networkViewData}
                                        selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                        exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                        template_schema_json={
                                            this.state.applyTemplateData.template_schema_json
                                        }
                                        closeAddTemplate={() => this.closeAddTemplate()}
                                        applyTemplate={(data) => this.applyTemplate(data)}
                                        refreshList={() => this.props.refreshList()}
                                        closeApplyTemplate={() => this.closeApplyTemplate()}
                                        reapply={this.props.reapply}
                                    />
                                }
                                {this.state.applyTemplateData.template_type ===
                                    "WiFiConfiguration" &&
                                    this.props.edit === true && (
                                        <WiFiTemplate
                                            templateData={this.state.applyTemplateData}
                                            networkViewData={this.state.networkViewData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            disableCancel={this.props?.disableCancel}
                                            reorderEnable={this.props?.reorderEnable}
                                            apply={true}
                                            view={this.props.view}
                                            edit={this.props.edit}
                                            reapply={this.props.reapply}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "WiFiConfiguration" &&
                                    this.props.edit !== true && (
                                        <WiFiView
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            reapply={this.props.reapply}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "OSPFConfiguration" &&
                                    this.props.edit !== true && (
                                        <OSPFView
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            view={this.props.view}
                                            link={true}
                                            // LONG-1836 : Need group id when removing ip address blank condition in case of device group
                                            group_id={this.state.group_id}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            reapply={this.props.reapply}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "OSPFConfiguration" &&
                                    this.props.edit === true && (
                                        <OSPFTemplate
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            disableCancel={this.props?.disableCancel}
                                            reorderEnable={this.props?.reorderEnable}
                                            apply={true}
                                            view={this.props.view}
                                            reapply={this.props.reapply}
                                            edit={true}
                                            link={true}
                                            // LONG-1836 : Need group id when removing ip address blank condition in case of device group
                                            group_id={this.state.group_id}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "BGPConfiguration" &&
                                    this.props.edit !== true && (
                                        <BGPView
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            view={this.props.view}
                                            link={true}
                                            group_id={this.state.group_id}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            info={this.props.info}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            reapply={this.props.reapply}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "BGPConfiguration" &&
                                    this.props.edit === true && (
                                        <BGPTemplate
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            disableCancel={this.props?.disableCancel}
                                            reorderEnable={this.props?.reorderEnable}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={true}
                                            view={this.props.view}
                                            link={true}
                                            group_id={this.state.group_id}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "FirewallConfiguration" &&
                                    this.props.edit !== true && ((this.props.selectedModel && (this.props.selectedModel === "Moretti_Model" || this.props.selectedModel === "Duvel_Model"
                                        || this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === "Firestorm" ||
                                        this.props.selectedModel === 'Bradshaw' || this.props.selectedModel === 'Orval' || this.props.selectedModel === 'Sander')) ||
                                        this.getModel() === 'Moretti_Model' || this.getModel() === 'Duvel_Model'
                                        || this.getModel() === "Stormbreaker" || this.getModel() === "Firestorm" ||
                                        this.getModel() === 'Bradshaw' || this.getModel() === 'Orval' || this.getModel() === 'Sander') ?
                                    <FirewallMIFIView
                                        templateData={this.state.applyTemplateData}
                                        apply={true}
                                        view={this.props.view}
                                        link={true}
                                        selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                        exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                        info={this.props.info}
                                        networkViewData={this.state.networkViewData}
                                        template_schema_json={
                                            this.state.applyTemplateData.template_schema_json
                                        }
                                        group_id={this.state.group_id ? this.state.group_id : this.props.group_id}
                                        closeAddTemplate={() => this.closeAddTemplate()}
                                        applyTemplate={(data) => this.applyTemplate(data)}
                                        refreshList={() => this.props.refreshList()}
                                        reapply={this.props.reapply}
                                        closeApplyTemplate={() => this.closeApplyTemplate()}
                                        applied={this.props.applied}
                                    />
                                    :
                                    this.state.applyTemplateData.template_type ===
                                    "FirewallConfiguration" &&
                                    this.props.edit !== true && (
                                        <FirewallView
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            info={this.props.info}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            // LONG-2298 : Restriction of blank IP address removed for device group from the networks of firewall template
                                            group_id={this.state.group_id ? this.state.group_id : this.props.group_id}
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            reapply={this.props.reapply}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={this.props.applied}
                                        />
                                    )
                                }
                                {this.state.applyTemplateData.template_type ===
                                    "FirewallConfiguration" &&
                                    this.props.edit === true && ((this.props.selectedModel && (this.props.selectedModel === "Moretti_Model" || this.props.selectedModel === "Duvel_Model"
                                        || this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === "Firestorm" ||
                                        this.props.selectedModel === 'Bradshaw' || this.props.selectedModel === 'Orval' || this.props.selectedModel === 'Sander')) ||
                                        this.getModel() === 'Moretti_Model' || this.getModel() === 'Duvel_Model'
                                        || this.getModel() === "Stormbreaker" || this.getModel() === "Firestorm" ||
                                        this.getModel() === 'Bradshaw' || this.getModel() === 'Orval' || this.getModel() === 'Sander') ?
                                    <FirewallMIFITemplate
                                        templateData={this.state.applyTemplateData}
                                        configAdd={this?.props?.configAdd}
                                        configEdit={this.props?.configEdit}
                                        configDeviceId={this.props?.configDeviceId}
                                        configModels={this?.props?.configModels}
                                        disableCancel={this.props?.disableCancel}
                                        reorderEnable={this.props?.reorderEnable}
                                        apply={true}
                                        reapply={this.props.reapply}
                                        edit={true}
                                        view={this.props.view}
                                        link={true}
                                        selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                        exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                        networkViewData={this.state.networkViewData}
                                        template_schema_json={
                                            JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                        }
                                        group_id={this.state.group_id ? this.state.group_id : this.props.group_id}
                                        closeAddTemplate={() => this.closeAddTemplate()}
                                        applyTemplate={(data) => this.applyTemplate(data)}
                                        refreshList={() => this.props.refreshList()}
                                        closeApplyTemplate={() => this.closeApplyTemplate()}
                                        applied={false}
                                    />
                                    :
                                    this.state.applyTemplateData.template_type ===
                                    "FirewallConfiguration" &&
                                    this.props.edit === true && (
                                        <FirewallTemplate
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            disableCancel={this.props?.disableCancel}
                                            reorderEnable={this.props?.reorderEnable}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                            }
                                            // LONG-2298 : Restriction of blank IP address removed for device group from the networks of firewall template
                                            group_id={this.state.group_id ? this.state.group_id : this.props.group_id}
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={false}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "TriggerConfiguration" &&
                                    this.props.edit !== true && (
                                        <WAView
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            info={this.props.info}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            reapply={this.props.reapply}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={this.props.applied}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "TriggerConfiguration" &&
                                    this.props.edit === true && (
                                        <WATemplate
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={false}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type === 'QoSConfiguration' &&
                                    this.props.edit !== true && (
                                        <QOSView
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={false}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type === 'QoSConfiguration' &&
                                    this.props.edit === true && (
                                        <QOSTemplate
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            disableCancel={this.props?.disableCancel}
                                            reorderEnable={this.props?.reorderEnable}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={false}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "TunnelConfiguration" && (
                                        <HubSpokeTemplate
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            view={this.props.view}
                                            edit={this.props.edit}
                                            group_id={this.state.group_id ? this.state.group_id : this.props.group_id}
                                            group_name={this.state.selectedGroupDeviceData.length > 0 ? this.state.selectedGroupDeviceData[0].group_name :
                                                (this.props.group_name ? this.props.group_name : "")}
                                            link={true}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            interfaces={this.state.interfaces}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            //Page blank issue in add & apply template
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            reapply={this.props.reapply}
                                            devices={this.state.tunnelDevicesData}
                                            deviceA_networks={this.state.deviceA_networks}
                                            deviceB_networks={this.state.deviceB_networks}
                                            usedInterfaces_deviceA={this.state.usedInterfaces_deviceA}
                                            usedInterfaces_deviceB={this.state.usedInterfaces_deviceB}
                                            usedEndpoints_deviceA={this.state.usedEndpoints_deviceA}
                                            usedEndpoints_deviceB={this.state.usedEndpoints_deviceB}
                                            networkViewData={this.state.networkViewData}
                                            disableApply={this.state.disableApply}
                                            isExternalEndPoint={this.state.externalTemplate}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "DNSFiltering" &&
                                    this.props.edit !== true && (
                                        <DNSView
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            view={this.props.view}
                                            link={true}
                                            group_id={this.state.group_id}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            info={this.props.info}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            reapply={this.props.reapply}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type === 'DNSFiltering' &&
                                    this.props.edit === true &&
                                    (
                                        <DNSTemplate
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={false}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "SystemConfiguration" &&
                                    this.props.edit !== true && (
                                        <SystemTemplateView
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            view={this.props.view}
                                            link={true}
                                            group_id={this.state.group_id}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            info={this.props.info}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            reapply={this.props.reapply}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type === 'SystemConfiguration' &&
                                    this.props.edit === true &&
                                    (
                                        <SystemTemplate
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={false}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type === 'ModemConfiguration' &&
                                    // this.props.edit === true && 
                                    (
                                        <ModemTemplate
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={this.props.edit}
                                            view={this.props.view}
                                            link={true}
                                            info={this.props.info}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={false}
                                            device_id={this.props.edit || this.props.reapply || this.props.view ? this.props.device_id :
                                                this.state.device_id}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type === 'CaptivePortal' &&
                                    this.props.edit !== true &&
                                    (
                                        <CaptivePortalView
                                            templateData={this.state.applyTemplateData}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={this.props.edit}
                                            view={this.props.view}
                                            link={true}
                                            info={this.props.info}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={false}
                                            device_id={this.props.edit || this.props.reapply || this.props.view ? this.props.device_id :
                                                this.state.device_id}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type === 'CaptivePortal' &&
                                    this.props.edit === true &&
                                    (
                                        <CaptivePortal
                                            templateData={this.state.applyTemplateData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            disableCancel={this.props?.disableCancel}
                                            reorderEnable={this.props?.reorderEnable}
                                            apply={true}
                                            reapply={this.props.reapply}
                                            edit={true}
                                            view={this.props.view}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            networkViewData={this.state.networkViewData}
                                            template_schema_json={
                                                JSON.parse(JSON.stringify(this.state.applyTemplateData.template_schema_json))
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                            applied={false}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "IPSecConfiguration" && (
                                        <IPSecTemplate
                                            tunnelEdit={this?.props?.tunnelEdit || false}
                                            templateData={this.state.applyTemplateData}
                                            deviceANetwork={this.state.ipsecDeviceANetwork}
                                            deviceBNetwork={this.state.networkViewData}
                                            ipsec_data={this.state.ipsec_data?.[0]}
                                            selectedDeviceData={this.state.selectedDeviceData?.[0]}
                                            selectedGroupDeviceData={this.state.selectedGroupDeviceData?.[0]}
                                            isExternalEndPoint={this.state.externalTemplate}
                                            apply={true}
                                            view={this.props.view}
                                            edit={this.props.edit}
                                            reapply={this.props.reapply}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            template_schema_json={
                                                this.state.applyTemplateData
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                                {this.state.applyTemplateData.template_type ===
                                    "DeviceConfiguration" && (
                                        <DeviceConfiguration
                                            templateData={this.state.applyTemplateData}
                                            networkViewData={this.state.networkViewData}
                                            configAdd={this?.props?.configAdd}
                                            configEdit={this.props?.configEdit}
                                            configDeviceId={this.props?.configDeviceId}
                                            configModels={this?.props?.configModels}
                                            apply={true}
                                            view={this.props.view}
                                            edit={this.props.edit}
                                            reapply={this.props.reapply}
                                            link={true}
                                            selectedModel={this.props.selectedModel ? this.props.selectedModel : this.getModel()}
                                            exactModel={this.props.exactModel ? this.props.exactModel : this.state.exactModel}
                                            template_schema_json={
                                                this.state.applyTemplateData.template_schema_json
                                            }
                                            closeAddTemplate={() => this.closeAddTemplate()}
                                            applyTemplate={(data) => this.applyTemplate(data)}
                                            refreshList={() => this.props.refreshList()}
                                            closeApplyTemplate={() => this.closeApplyTemplate()}
                                        />
                                    )}
                            </div>
                        </div>
                    )}
                </div>
                {/* end first slide */}
                <div
                    className={
                        this.state.devicesTemplate
                            ? "sidebar-slide zindex10 w-350 second-open r-757"
                            : "sidebar-slide zindex10 w-350"
                    }
                >
                    <div className="s-header">
                        <h1 className="ss-title">Devices</h1>
                        <button onClick={() => this.closeDeviceTemplate()}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="filter-box d-flex align-items-center">
                            <div className="search-box2 mr-2">
                                {/* LONG-3194-EM - Filtered device should be reset after cancel/close operation */}
                                <input type="text" placeholder="Search.." value={this.state.searchVal} onChange={(e) => this.isSubstring((e.target.value.toLowerCase()))} />
                                <img src="/images/svg-icons/search.svg" alt="" />
                            </div>
                        </div>
                        <div className="device-table">
                            <DataTable
                                columns={this.state.deviceListColumns}
                                data={this.state.deviceList.filter((item) => item.device_name.toLowerCase().includes(this.state.searchVal) && item.device_mac !== this.props.data.device_mac)}
                                selectableRows
                                highlightOnHover
                                fixedHeader
                                // LONG-1528 Replaced supported model with device model (replace supported model to device model because all model in group device is same )
                                selectableRowDisabled={(row) => (this.state.selectedModel !== '' && this.state.selectedModel !== row.device_model) ||
                                    (row.group_name !== "" && row.group_name !== null && row.group_name !== undefined) ||
                                    (
                                        row.is_ippt &&
                                        this.state.applyTemplateData.template_type !== "TriggerConfiguration" &&
                                        this.state.applyTemplateData.template_type !== "ModemConfiguration" &&
                                        this.state.applyTemplateData.template_type !== "SystemConfiguration" &&
                                        this.state.applyTemplateData.template_type !== "NetworkConfiguration"
                                    )
                                }
                                onSelectedRowsChange={(data) => this.multipleSelect(data)}
                                clearSelectedRows={this.state.toggledClearDeviceRows}
                            />
                        </div>
                    </div>
                    <div className="s-footer">
                        <button
                            className="btn btn-light"
                            onClick={() => this.closeDeviceTemplate()}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={this.state.multipleSelect === '' || this.state.adding}
                            onClick={() => {
                                this.deviceAdd();
                            }}
                        >
                            Add
                        </button>
                    </div>
                </div>
                {/*Device Group HTML*/}
                <div
                    className={
                        this.state.deviceGroupTemplate
                            ? "sidebar-slide zindex10 w-350 second-open r-757"
                            : "sidebar-slide zindex10 w-350"
                    }
                >
                    <div className="s-header">
                        <h1 className="ss-title">Device Group</h1>
                        <button onClick={() => this.closeDeviceTemplate()}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="filter-box d-flex align-items-center">
                            <div className="search-box2 mr-2">
                                {/* LONG-3194-EM - Filtered device should be reset after cancel/close operation */}
                                <input type="text" placeholder="Search.." value={this.state.searchVal} onChange={(e) => this.isSubstring((e.target.value.toLowerCase()))} />
                                <img src="/images/svg-icons/search.svg" alt="" />
                            </div>
                        </div>
                        <div className="device-table">
                            <DataTable
                                columns={this.state.deviceGroupListColumns}
                                data={this.state.deviceGroupList.filter(item => item.group_name.toLowerCase().includes(this.state.searchVal))}
                                selectableRows
                                highlightOnHover
                                fixedHeader
                                onSelectedRowsChange={(data) => this.multipleSelect(data)}
                                selectableRowDisabled={(row) => this.state.selectedModel !== '' && this.state.selectedModel !== row.device_model ||
                                    (
                                        row.is_ippt &&
                                        this.state.applyTemplateData.template_type !== "TriggerConfiguration" &&
                                        this.state.applyTemplateData.template_type !== "ModemConfiguration" &&
                                        this.state.applyTemplateData.template_type !== "SystemConfiguration" &&
                                        this.state.applyTemplateData.template_type !== "NetworkConfiguration"
                                    ) ||
                                    (
                                        this.state.applyTemplateData.template_type === 'CaptivePortal' &&
                                        Common.getModels(row.supported_models) !== 'CHIMAY'
                                    )
                                }
                                clearSelectedRows={this.state.toggledClearDeviceRows}
                            />
                        </div>
                    </div>
                    <div className="s-footer">
                        <button
                            className="btn btn-light"
                            onClick={() => this.closeDeviceTemplate()}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={this.state.multipleSelect === ''}
                            onClick={() => {
                                this.deviceGroupAdd();
                            }}
                        >
                            Add
                        </button>
                    </div>
                </div>
                {
                    this.state.show && (
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            nonSync={this.props.nonSync}
                            wait={() => this.props.dispatch({
                                type: Action.RESET_APPLY_TEMPLATE
                            })}
                            onSubmit={() => this.regenerateKey()}
                            reset={this.state.disableApply ? () => this.resetNetworkPopup() : () => this.resetPopup()}
                        />
                    )
                }
                {
                    this.state.showLink && (
                        <LinkPopup
                            title={this.state.title}
                            message={this.state.message}
                            nonSync={this.props.nonSync}
                            open={() => this.openOperationList(this.state.id)}
                            close={this.props.nonSync ? () => this.closeNonSync() : () => this.closeLinkpopup()}
                        />
                    )
                }
                {
                    (this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        openApplyTemplate: state.displayAddTemplate.openApplyTemplate,
        openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
    };
};
export default connect(mapStateToProps)(ApplyTemplate);