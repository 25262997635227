/* eslint-disable array-callback-return */
import React, { Component } from "react";
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
var addNetwork = require('highcharts/modules/networkgraph');

export default class TunnelSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topologyData: [],
            device_A_name:
                (this.props?.tunnel_type !== "IPSec Tunnel")
                    ? (this.props?.data?.device_A?.device_name || "-")
                    : (this.props?.data?.deviceA?.device_name || "-"),
            device_B_name:
                (this.props?.tunnel_type !== "IPSec Tunnel")
                    ? (this.props?.data?.device_B?.device_name || "-")
                    : (this.props?.data?.deviceB?.device_name || "-"),
            tunnel_type: this.props?.tunnel_type || "",
        }
    };

    componentDidMount() {
        let data = [];
        if (this.props?.data) {
            if (this.state.tunnel_type !== "IPSec Tunnel") {
                if (this.props?.data?.device_A?.allowed_ips?.length > 0) {
                    this.props.data.device_A.allowed_ips.map((item) => {
                        if (this.props?.data?.device_A?.allowed_ips?.[0] === this.props?.data?.device_B?.allowed_ips?.[0]) {
                            if (item !== '') {
                                const value = [];
                                value.push(this.state.device_B_name, item + '_DeviceB');
                                data.push(value);
                            }
                        }
                        else {
                            if (item !== '') {
                                const value = [];
                                value.push(this.state.device_B_name, item);
                                data.push(value);
                            }
                        }
                    });
                }
                data.push([this.state.device_A_name, this.state.device_B_name]);
                if (this.props?.data?.device_B?.allowed_ips?.length > 0) {
                    this.props?.data?.device_B?.allowed_ips?.map((item) => {
                        if (this.props?.data?.device_B?.allowed_ips?.[0] === this.props?.data?.device_A?.allowed_ips[0]) {
                            if (item !== '') {
                                const value = [];
                                value.push(this.state.device_A_name, item + '_DeviceA');
                                data.push(value);
                            }
                        }
                        else {
                            if (item !== '') {
                                const value = [];
                                value.push(this.state.device_A_name, item);
                                data.push(value);
                            }
                        }
                    });
                }
            }
            else {
                if (this.props?.data?.deviceB?.local_ip) {
                    const value = [];
                    value.push(this.state.device_B_name, this.props?.data?.deviceB?.local_ip + '_DeviceB');
                    data.push(value);
                    data.push([this.state.device_A_name, this.state.device_B_name]);
                }
                else if (this.props?.data?.group?.local_ip) {
                    const value = [];
                    value.push(this.props?.data?.group?.group_name, this.props?.data?.group?.local_ip + '_Group');
                    data.push(value);
                    data.push([this.state.device_A_name, this.props?.data?.group?.group_name]);
                }
                if (this.props.data.deviceA?.local_ip) {
                    const value = [];
                    value.push(this.state.device_A_name, this.props.data.deviceA?.local_ip + '_DeviceA');
                    data.push(value);
                }
            }
        }
        this.setState({
            topologyData: data
        }, () =>
            this.renderTopology());
    }

    renderTopology = () => {
        const deviceA = this.state.device_A_name;
        const deviceB = this.state.device_B_name;
        addNetwork(Highcharts);
        Highcharts.addEvent(
            Highcharts.Series,
            'afterSetOptions',
            function (e) {
                let nodes = {};
                if (
                    this instanceof Highcharts.seriesTypes.networkgraph &&
                    e.options.id === 'lang-tree'
                ) {
                    e.options.data.forEach(function (link) {
                        if (link[0] === deviceA && link[1] === deviceB) {
                            nodes[link[0]] = {
                                id: link[0],
                                marker: {
                                    symbol: 'url(/images/dd-img.png)',
                                    width: 20,
                                    height: 40,
                                },
                                color: '#e41d34'
                            };
                            nodes[link[1]] = {
                                id: link[1],
                                marker: {
                                    symbol: 'url(/images/dd-img.png)',
                                    width: 20,
                                    height: 40,
                                },
                                color: '#e41d34'
                            };
                        } else if (link[0] === deviceA || link[0] === deviceB) {
                            nodes[link[0]] = {
                                id: link[0],
                                marker: {
                                    symbol: 'url(/images/dd-img.png)',
                                    width: 20,
                                    height: 40,
                                },
                                color: '#e41d34'
                            };

                            nodes[link[1]] = {
                                id: link[1],
                                marker: {
                                    radius: 10
                                },
                                color: "#0083bf"
                            };
                        }
                        else {
                            if (nodes[link[1]] !== undefined) {
                                nodes[link[1]] = {
                                    id: link[1],
                                    color: "#0083bf"
                                };
                            }
                        }
                    });
                }
                e.options.nodes = Object.keys(nodes).map(function (id) {
                    return nodes[id];
                });

            });
        Highcharts.chart('container', {
            chart: {
                type: 'networkgraph',
                height: '85%'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false
            },
            plotOptions: {
                networkgraph: {
                    keys: ['from', 'to'],
                    layoutAlgorithm: {
                        enableSimulation: true,
                        friction: -0.9
                    },
                }
            },
            series: [{
                dataLabels: {
                    enabled: true,
                    linkFormat: ''
                },
                id: 'lang-tree',
                data: this.state.topologyData
            }]
        });
    };

    render() {
        return (
            <>
                <figure className="highcharts-figure">
                    <div id="container"></div>
                </figure>
            </>
        )
    }
}