import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from "../../layOuts/SideMenu";
import { Loader } from "../../layOuts/Loader";
import Header from "../../layOuts/Header";
import { ErrorPopup } from "../../layOuts/errorPopup";
import ErrorHandlePopup from "../ErrorHandlePopup";
import urlMapping from "../../redux/API/api";
import ApiCall from "../../redux/API/apiCall";
import SyncWithPlaybooks from "../configuration/SyncWithPlaybooks";
import * as Action from '../../redux/actionTypes';
import { setIP } from '../../redux/API/pfAPI';
import Notifications from "./Notifications";
import SMTP_Server from "./SMTP_Server";
import ZTNAServer from "./Packetfence/ZTNAServer";
import ExternalServer from "./ExternalServer";
import $ from "jquery";
class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      title: "",
      message: "",
      show: false,
      error: false,
      showLoader: false,
      openModal: false,
      selectedTrigger: {},
      modeSliderValue: {},
      data: {},
      responseData: {},
      selectedTab: "Playbooks",
      pfData: [],
      is_auth_server_configured: false,
      isAxiosError: false,
    };
  }
  componentDidMount() {
    this.getServerDetails();
    this.getConfigInfo();
  }
  getServerDetails = () => {
    this.setState({
      showLoader: true
    })
    ApiCall(urlMapping.getPFServerDetails(), (response) => {
      this.setState({
        showLoader: false
      })
      if (response?.success) {
        if (response?.data) {
          this.setState({
            pfData: response?.data
          })
          if (response?.data?.[0]?.device_public_ip !== '') {
            setIP(response?.data?.[0]?.device_public_ip);
            this.props.dispatch({
              type: Action.GET_AUTH_DETAILS,
              payload: response?.data?.[0],
            });
          }
        }
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  }
  getConfigInfo = () => {
    this.setState({
      showLoader: true
    })
    ApiCall(urlMapping.getConfigInfo(), (response) => {
      this.setState({
        showLoader: false,
      })
      if (response?.success) {
        this.setState({
          is_auth_server_configured: response?.data?.is_auth_server_configured
        })
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  }
  resetPopup = () => {
    this.setState({
      selectedTrigger: {},
      openModal: false
    });
  };
  render() {
    return (
      <div>
        <SideMenu />
        {this.state.showLoader && <Loader />}
        <section
          id="rhsMain"
          className={this.props.open ? "rhs-main" : "rhs-main expand-w"}
        >
          <Header />
          <div className="no-bg page-height">
            <div className="page-header device-head d-flex justify-content-between">
              <ul
                className="tab-nav nav nav-pills ml-0 mr-0"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    data-toggle="pill"
                    id="pills-ma-playbook-tab"
                    href="#pills-ma-playbook-tab"
                    role="tab"
                    aria-controls="pills-ma-playbook-tab"
                    aria-selected="false"
                    onClick={() =>
                      this.setState({ selectedTab: "Playbooks" })
                    }
                  >
                    Collections
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    data-toggle="pill"
                    id="pills-ma-pf-tab"
                    href="#pills-ma-pf-tab"
                    role="tab"
                    aria-controls="pills-ma-pf-tab"
                    aria-selected="false"
                    onClick={() =>
                      this.setState({ selectedTab: "Packetfence" })
                    }
                  >
                    ZTNA-B
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    data-toggle="pill"
                    id="pills-ma-pf-tab"
                    href="#pills-ma-server-tab"
                    role="tab"
                    aria-controls="pills-ma-server-tab"
                    aria-selected="false"
                    onClick={() =>
                      this.setState({ selectedTab: "server" })
                    }
                  >
                    RADIUS Servers
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    data-toggle="pill"
                    id="pills-ma-Notifications-tab"
                    href="#pills-ma-Notifications-tab"
                    role="tab"
                    aria-controls="pills-ma-Notifications-tab"
                    aria-selected="false"
                    onClick={() =>
                      this.setState({ selectedTab: "Notifications" })
                    }
                  >
                    Notifications
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    data-toggle="pill"
                    id="pills-ma-SMTP_Server-tab"
                    href="#pills-ma-SMTP_Server-tab"
                    role="tab"
                    aria-controls="pills-ma-SMTP_Server-tab"
                    aria-selected="false"
                    onClick={() =>
                      this.setState({ selectedTab: "SMTP_Server" })
                    }
                  >
                    SMTP Server
                  </a>
                </li>
              </ul>
              {
                this.state.pfData?.length !== 0 &&
                <div className="d-flex align-items-center right-78">
                  {(this.state.is_auth_server_configured || this.state.pfData.length === 0) && this.state.selectedTab === "Packetfence" &&
                    <span className="red-txt mr-3">Please don't refresh the page</span>}
                  {(this.state.selectedTab === "Packet" || this.state.selectedTab === "Packetfence") && <div className="d-inline-flex mr-3">
                    <div className="ds-txt mr-2">Device Status</div>
                    <div className="d-status">
                      <span>
                        <i
                          className={
                            this.state.pfData[0]?.device_status === 1
                              ? "status online"
                              : "status offline"
                          }
                        ></i>
                        {this.state.pfData[0]?.device_status === 1
                          ? "Online"
                          : "Offline"}
                      </span>
                    </div>
                  </div>}
                </div>
              }
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-content" id="myTabContent">
                {this.state.selectedTab === "Playbooks" && (
                  <SyncWithPlaybooks />
                )}
                {this.state.selectedTab === "Packetfence" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-ma-packetfence"
                    role="tabpanel"
                    aria-labelledby="pills-ma-packetfence-tab"
                  >
                    <ZTNAServer />
                  </div>
                )}
                {this.state.selectedTab === "server" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-ma-server"
                    role="tabpanel"
                    aria-labelledby="pills-ma-server-tab"
                  >
                    <ExternalServer />
                  </div>
                )}
                {this.state.selectedTab === "Notifications" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-ma-Notifications"
                    role="tabpanel"
                    aria-labelledby="pills-ma-Notifications-tab"
                  >
                    <Notifications />
                  </div>
                )}
                {this.state.selectedTab === "SMTP_Server" && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-ma-SMTP_Server"
                    role="tabpanel"
                    aria-labelledby="pills-ma-SMTP_Server-tab"
                  >
                    <SMTP_Server />
                  </div>
                )
                }
              </div>
            </div>
            {this.state.show && (
              <ErrorPopup
                title={this.state.title}
                message={this.state.message}
                error={this.state.error}
                reset={() => this.resetPopup()}
              />
            )}
            {(this.state.isAxiosError) &&
              <ErrorPopup
                title={this.state.title}
                message={this.state.message}
                error={this.state.error}
                delete={this.state.delete}
                reset={() => this.setState({ isAxiosError: false })}
              />
            }
          </div>
        </section>
        {this.state.showRefreshModal && <ErrorHandlePopup />}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isSuperAdmin: state.auth.isSuperAdmin,
    user: state.auth.user,
    open: state.displaySidebarMenu.openSide,
    is_auth_server_configured: state.auth.is_configured
  };
};
export default withRouter(connect(mapStateToProps)(Configuration));
