/* eslint-disable array-callback-return */
import React, { Component, Fragment } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import * as Common from "../../../utils/common";
import Select, { components } from 'react-select';
import * as Action from "../../../redux/actionTypes";
import isEqual from 'lodash/isEqual';
const ButtonComponent = (props, onClickHandler, id) => {
    return (
        <Fragment>
            <components.MenuList {...props}>
                <div>{props.children}</div>
                <button data-toggle="modal" data-target={id} onClick={onClickHandler} name="addIP" className="btn btn-primary btn-block">+ Add IP</button>
            </components.MenuList>
        </Fragment>
    );
};
class FirewallMIFIView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            customIP: false,
            networkViewData: [],
            selectedTemplateId: this.props.id ? this.props.id : "",
            availableNetworks: [],
            availableIP: [],
            validIP: false,
            customIPAddress: '',
            template_schema_json: {
                "zoneInfo": [],
                "portForward": [],
                "trafficRules": [],
                "natRules": []
            },
            options: []
        };
    }
    componentDidMount() {
        if (this.props.template_schema_json !== undefined) {
            this.setState({
                template_schema_json: this.props.template_schema_json,
                view: this.props.view,
                info: this.props.info
            });
            if ((this.props.apply || this.props.view) && this.props?.networkViewData) {
                let { availableNetworks } = this.state;
                let availableIP = ['any'];
                if (!this.props.group_id) {
                    availableNetworks = this.props?.networkViewData?.filter(element => (element.ip_address !== ""
                        && element.name !== "Control Tunnel"));
                }
                else {
                    availableNetworks = this.props?.networkViewData?.filter(element => (element.name !== "Control Tunnel"));
                }
                availableNetworks.map((networks) => {
                    availableIP.push(networks.ip_address);
                });
                let { template_schema_json } = this.props;
                let lanNetworks = '';
                let wanNetworks = '';
                let tunnelNetworks = '';
                availableNetworks.map((network) => {
                    if (network.network_zone === 'lan' && network.name !== "Guest")
                        lanNetworks = lanNetworks === '' ? network.networkName : lanNetworks + ' ' + network.networkName;
                    if (network.network_zone === 'wan')
                        wanNetworks = wanNetworks === '' ? network.networkName : wanNetworks + ' ' + network.networkName;
                    if (network.network_zone === 'datatunnel')
                        tunnelNetworks = tunnelNetworks === '' ? network.networkName : tunnelNetworks + ' ' + network.networkName;
                })
                if (!this.props.edit && !this.props.view && (this.props.templateData.default_type === '' ||
                    this.props.templateData.default_type === null)) {
                    //LONG-2538  Default template for WAN/Network/Firewall should be as per device model
                    if (this.props.selectedModel === "Moretti_Model" || this.props.selectedModel === "Duvel_Model"
                        || this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === "Firestorm") {
                        template_schema_json.zoneInfo = template_schema_json.zoneInfo.filter(item => item.name !== "guest")
                    }
                    template_schema_json.zoneInfo.map((zone) => {
                        if (zone.name.toLowerCase() === 'lan') {
                            zone.network = lanNetworks;
                            availableNetworks = availableNetworks.filter(item => item.network_zone !== 'lan')
                        }
                        if (zone.name.toLowerCase() === 'wan') {
                            zone.network = wanNetworks;
                            availableNetworks = availableNetworks.filter(item => item.network_zone !== 'wan')
                        }
                        if (zone.name.toLowerCase() === 'datatunnel') {
                            zone.network = tunnelNetworks;
                            availableNetworks = availableNetworks.filter(item => item.network_zone !== 'datatunnel')
                        }
                    })
                }
                let { options } = this.state;
                availableIP.length > 0 && availableIP.map((item) => {
                    options.push({ label: item, value: item })
                });
                this.setState({
                    availableNetworks,
                    availableIP,
                    template_schema_json,
                    networkViewData: this.props.networkViewData,
                    options
                })
            }
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id) {
            this.setState({
                selectedTemplateId: nextProps.id ? nextProps.id : "",
            });
        }
        if (!isEqual(this.props.networkViewData, nextProps.networkViewData)) {
            this.setState({
                networkViewData: nextProps.networkViewData
            })
        }
        let { availableIP, options } = this.state;
        availableIP.length > 0 && availableIP.map((item) => {
            options.push({ label: item, value: item });
        })
    }
    getDestinationZone = (zoneName) => {
        let check = ['lan', 'wan'];
        // LONG-1704 : Fix for Zone forward not showing in View page for firewall template
        let zones = zoneName.forwardTo.split(' ').filter(item => item !== 'none' && item.isdeleted !== "1").join(' ');
        this.state.template_schema_json.zoneInfo.filter(item => item.isdeleted !== "1").map((zone) => {
            zone.forwardFrom.split(' ').map((item) => {
                if (item === zoneName.name) {
                    zone.name = (check.includes((zone.name).toLowerCase())) ? (zone.name).toUpperCase() : zone.name
                    zones = zones === '' ? zone.name : ((check.includes(zones.toLowerCase())) ?
                        zones.toUpperCase() : zones) + ' ' + zone.name;
                }
            })
        })
        if (zones === '')
            zones = 'none';
        zones = (check.includes(zones.toLowerCase())) ? zones.toUpperCase() : zones
        return zones;
    }
    getProtocol = (protocol) => {
        let protocols = '';
        protocol.split(' ').map((item) => {
            item = item === 'any' ? 'Any' : item.toUpperCase();
            protocols = protocols === '' ? item : protocols + ' ' + item;
        })
        return protocols;
    }
    handleChange = (e, zone) => {
        const { template_schema_json } = this.state;
        let { availableNetworks } = this.state;
        if (e.target.checked) {
            template_schema_json.zoneInfo.map((item) => {
                if (item.cfg === zone.cfg) {
                    item.network = item.network === '' ? e.target.id : item.network + ' ' + e.target.id;
                }
                return item;
            });
        } else {
            template_schema_json.zoneInfo.map((item) => {
                if (item.cfg === zone.cfg) {
                    const networks = item.network.split(' ');
                    item.network = networks.filter(value => value !== e.target.id).join(' ');
                    const network = this.state.networkViewData.filter(item => item.networkName === e.target.id)[0];
                    if (network)
                        availableNetworks.push(network)
                }
                return item;
            });
        }
        this.setState({
            template_schema_json,
            availableNetworks
        })
    }
    getNetworkName = (name) => {
        const network = this.state.networkViewData.filter(item => item.networkName === name)[0];
        let networkName = ''
        if (network)
            networkName = network.name;
        return networkName;
    }
    getAvailableNetwork = () => {
        let networks = this.state.availableNetworks;
        let used = [];
        this.state.template_schema_json.zoneInfo.map((zone) => {
            const values = zone.network.split(' ');
            values.map((item) => {
                used.push(item);
            })
        })
        used.map((value) => {
            if (value)
                networks = networks.filter(item => item.networkName !== value);
        })
        return networks;
    }
    handlePortSourceIP = (e, port) => {
        let { template_schema_json } = this.state;
        template_schema_json.portForward.map((item) => {
            if (item.cfg === port.cfg) {
                item.sourceIP = e && e.value ? e.value : '';
            }
            return item;
        })
        this.setState({
            template_schema_json
        })
    }
    handlePortDestIP = (e, port) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
        }
        if (e.target.name === 'ipAddress1') {
            if (Common.validateIPaddress(e) === false || e.target.value === '0.0.0.0' || e.target.value === '255.255.255.255') {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Invalid value';
                    this.setState({
                        showError: true,
                    })
                }
            }
            // else if (Common.validatePrivateIP(e) === false) {
            //     const element = document.getElementById(e.target.name);
            //     if (element) {
            //         element.style.display = 'block';
            //         element.innerHTML = 'This is restricted IP address';
            //         this.setState({
            //             showError: true,
            //         })
            //     }
            //     else {
            //         this.setState({
            //             showError: false
            //         })
            //     }
            // }

        }
        let { template_schema_json } = this.state;
        template_schema_json.portForward.map((item) => {
            if (item.cfg === port.cfg) {
                item.destIP = e.target.value
            }
            return item;
        })
        this.setState({
            template_schema_json
        })
    }
    validateIP = (e) => {
        if (e.target.name === 'ipAddress') {
            // if (Common.validatePrivateIP(e) === false) {
            //     const element = document.getElementById(e.target.name);
            //     if (element) {
            //         element.style.display = 'block';
            //         element.innerHTML = 'This is restricted IP address';
            //         this.setState({
            //             showError: true,
            //         })
            //     }
            // }
            // else 
            if (Common.validateIPaddressWithSubnetMask(e)) {
                this.setState({
                    showError: false,
                    validIP: true,
                    customIPAddress: e.target.value
                })
            } else {
                this.setState({
                    showError: true
                })
            }
        }
        else {
            this.setState({
                showError: false,
            })
        }

    }
    addIP = () => {
        let { availableIP, options } = this.state;
        if (this.state.validIP) {
            if (!availableIP.includes(this.state.customIPAddress)) {
                availableIP.push(this.state.customIPAddress);
                options.push({ label: this.state.customIPAddress, value: this.state.customIPAddress });
            }
            this.setState({
                customIP: false,
                customIPAddress: '',
                options,
                validIP: false
            }, () => {
                $("#errorMessage").modal("hide")
            })
        }
    }
    cancelIP = () => {
        let element = document.getElementById('ipAddress');
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
        }
        this.setState({
            customIP: false
        })
    }
    handleTrafficSourceIP = (e, rule) => {
        let { template_schema_json } = this.state;
        if (e.target.checked === true) {
            template_schema_json.trafficRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    if (!item.sourceIP.split(' ').includes(e.target.id)) {
                        item.sourceIP = e.target.id === 'any' ? '' : item.sourceIP;
                        item.sourceIP = item.sourceIP === 'any' ? '' : item.sourceIP;
                        item.sourceIP = item.sourceIP === '' ? e.target.id : item.sourceIP + " " + e.target.id;
                    }
                }
            })
        } else {
            template_schema_json.trafficRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    if (item.sourceIP?.split(' ')?.includes(e.target.id)) {
                        item.sourceIP = item.sourceIP?.split(' ')?.filter(element => element !== e.target.id)?.join(' ');
                    }
                }
            })
        }
        this.setState({ template_schema_json })
    }
    handleTrafficDestIP = (e, rule) => {
        let { template_schema_json } = this.state;
        if (e.target.checked === true) {
            template_schema_json.trafficRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    if (!item.destIP.split(' ').includes(e.target.id)) {
                        item.destIP = e.target.id === 'any' ? '' : item.destIP;
                        item.destIP = item.destIP === 'any' ? '' : item.destIP;
                        item.destIP = item.destIP === '' ? e.target.id : item.destIP + " " + e.target.id;
                    }
                }
            })
        } else {
            template_schema_json.trafficRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    if (item.destIP?.split(' ')?.includes(e.target.id)) {
                        item.destIP = item.destIP?.split(' ')?.filter(element => element !== e.target.id)?.join(' ');
                    }
                }
            })
        }
        this.setState({ template_schema_json })
    }
    handleNatSourceIP = (e, rule) => {
        let { template_schema_json } = this.state;
        template_schema_json.natRules.map((item) => {
            if (item.cfg === rule.cfg) {
                item.sourceIP = e && e.value ? e.value : '';
            }
            return item;
        })
        this.setState({
            template_schema_json
        })
    }
    handleNatDestIP = (e, rule) => {
        let { template_schema_json } = this.state;
        template_schema_json.natRules.map((item) => {
            if (item.cfg === rule.cfg) {
                item.destIP = e && e.value ? e.value : '';
            }
            return item;
        })
        this.setState({
            template_schema_json
        })
    }
    handleSnatIP = (e, rule) => {
        if (Common.validateIPaddress(e)) {
            let { template_schema_json } = this.state;
            template_schema_json.natRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    item.snatIP = e.target.value
                }
                return item;
            })
            this.setState({
                showError: false,
                template_schema_json,
            })
        } else {
            this.setState({ showError: true })
        }
    }
    removeErrorIP = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true
            })
        }
    }
    checkError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        this.state.template_schema_json.portForward.map((port) => {
            if (port.destIP === '')
                result = true;
        })
        this.state.template_schema_json.trafficRules.map((rule) => {
            if (rule.sourceIP === '')
                result = true;
        })
        return result;
    };
    changeIp = () => {
        this.setState({
            customIP: !this.state.customIP
        })
    }
    render() {
        return (
            <div className="firewall-view firewall-view-custom">
                <div className="gray-bg rounded p-2">
                    <div className="mb-2">
                        <strong>Zone</strong>
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of the zone">
                            <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                    </div>
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th className="w-80">
                                    <div className="zone-forwarding">
                                        <span>Source</span>
                                        <span className="arrow-sorting">&rarr;</span>
                                        <span>Forwarding</span>
                                    </div>
                                </th>
                                {!this.props.info &&
                                    <th>Source Zone Networks
                                    </th>}
                                <th>Input</th>
                                <th>Output</th>
                                <th>Forward</th>
                                <th>Masquerading</th>
                                <th>MSS Clamping</th>
                            </tr>
                        </thead>
                        <tbody className="c-grayCursor">
                            {this.state.template_schema_json.zoneInfo?.filter(item => item.isdeleted !== '1')?.map((zone) => {
                                return <><tr>
                                    <td>
                                        <div className="zone-forwarding">
                                            <span>{((zone?.name).toLowerCase() === "wan" || (zone?.name).toLowerCase() === "lan") ? (zone?.name).toUpperCase() : zone?.name}</span>
                                            <span className="arrow-sorting">&rarr;</span>
                                            <span>{this.getDestinationZone(zone)}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        {zone?.network ? zone?.network?.split(' ')?.map((item) => {
                                            if (item)
                                                return <li>{this.getNetworkName(item)}</li>
                                        }) : ""}</td>

                                    <td>{Common.getAction(zone?.input)}</td>
                                    <td>{Common.getAction(zone?.output)}</td>
                                    <td>{Common.getAction(zone?.forward)}</td>
                                    <td>{zone?.masq === '1' ? 'Enabled' : 'Disabled'}</td>
                                    <td>{zone?.mssClamping === '1' ? 'Enabled' : 'Disabled'}</td>
                                </tr>
                                </>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="gray-bg rounded p-2 mt-2">
                    <div className="mb-2">
                        <strong>Port Forwarding</strong>
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of the Port Forwarding rule">
                            <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                    </div>
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th className="w-80">Name</th>
                                <th className="w-150">Match</th>
                                {!this.props.info &&
                                    <th>Source IP<span className="red-txt">*</span></th>}
                                <th>Forward to</th>
                                {!this.props.info &&
                                    <th>DNAT IP<span className="red-txt">*</span></th>}
                                <th>Enabled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.template_schema_json.portForward?.filter(item => item.isdeleted !== '1')?.map((port) => {
                                return <><tr>
                                    <td>{port.name}</td>
                                    <td className="c-gray">
                                        <ul>
                                            <li><strong>Protocol: </strong><span> {this.getProtocol(port?.proto)}</span></li>
                                            <li><strong>Source Zone: </strong><span> {Common.getZoneName(port?.sourceZone)}</span></li>
                                            <li><strong>Port: </strong><span> {port?.sourcePort ? port?.sourcePort : '-'}</span></li>
                                        </ul>
                                    </td>
                                    {!this.props.info &&
                                        <td className="c-gray">
                                            {!this.props.view ?
                                                <Select
                                                    isClearable
                                                    components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP') }}
                                                    isSearchable
                                                    className="custom-dropdown w-105"
                                                    classNamePrefix="react-select-container"
                                                    name="customip"
                                                    options={this.state.options}
                                                    onChange={(e) => this.handlePortSourceIP(e, port)}
                                                />
                                                :
                                                <div>{port?.sourceIP}</div>}
                                        </td>}
                                    <td className="lblue-bg">
                                        <ul>
                                            <li><strong>Forward to Destination Zone: </strong><span> {Common.getZoneName(port?.destZone)}</span></li>
                                            <li><strong>Port: </strong><span> {port?.destPort ? port?.destPort : '-'}</span></li>
                                        </ul>
                                    </td>
                                    {!this.props.info &&
                                        <td className="lblue-bg">
                                            {!this.props.view ?
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control w-105"
                                                        name="ipAddress1"
                                                        value={port?.destIP}
                                                        onChange={(e) => this.handlePortDestIP(e, port)} />
                                                    <div className="error-txt" id="ipAddress1" >Invalid IP address</div>
                                                </div>
                                                : <div>{port?.destIP}</div>}
                                        </td>}
                                    <td>{port?.enabled === '1' ? 'Yes' : 'No'}</td>
                                </tr></>
                            })}

                        </tbody>
                    </table>
                </div>
                <div className="gray-bg rounded p-2 mt-2">
                    <div className="mb-2">
                        <strong>Traffic Rules</strong>
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of the Traffic Rule">
                            <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                    </div>
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th className="w-80">Name</th>
                                <th className="w-150">Match</th>
                                {!this.props.info &&
                                    <>
                                        <th>Source IP<span className="red-txt">*</span></th>
                                        <th>Dest. IP</th>
                                    </>}
                                <th>Action</th>
                                <th>Enabled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.template_schema_json.trafficRules?.filter(item => item.isdeleted !== '1')?.map((rule) =>
                                <tr>
                                    <td>{rule.name}</td>
                                    <td className="c-gray">
                                        <ul>
                                            <li>
                                                <strong>Protocol: </strong><span>{this.getProtocol(rule?.proto)}</span>
                                            </li>
                                            <li>
                                                <strong>Source Zone: </strong><span>{Common.getZoneName(rule?.sourceZone)}</span>
                                            </li>
                                            <li>
                                                <strong>Source IP: </strong><span>{rule?.sourceIP}</span>
                                            </li>
                                            <li>
                                                <strong>Source Port: </strong><span>{rule?.sourcePort}</span>
                                            </li>
                                            <li>
                                                <strong>Destination Zone: </strong><span>{Common.getZoneName(rule?.destZone)}</span>
                                            </li>
                                            <li>
                                                <strong>Destination IP: </strong><span>{rule?.destIP}</span>
                                            </li>
                                            <li>
                                                <strong>Destination Port: </strong><span>{rule?.destPort}</span>
                                            </li>
                                        </ul>
                                    </td>

                                    {!this.props.info &&
                                        <>
                                            <td className="c-gray">
                                                {!this.props.view ?
                                                    <div className="dropdown">
                                                        <ul className="chip-list z-index-9 radius">
                                                            {rule.sourceIP?.split(' ')?.map((item) => {
                                                                if (item)
                                                                    return <li>{item}<img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                                        onClick={(e) => this.handleTrafficSourceIP(e, rule)} /></li>
                                                            })}
                                                        </ul>
                                                        <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        </div>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <ul>{
                                                                this.state.availableIP.length > 0 && this.state.availableIP.map((item) => {
                                                                    return (<li><input id={item} type="checkbox"
                                                                        checked={rule.sourceIP?.split(' ')?.includes(item)}
                                                                        onChange={(e) => this.handleTrafficSourceIP(e, rule)}
                                                                    />
                                                                        {item}</li>)
                                                                })}
                                                                <li
                                                                    data-toggle="modal"
                                                                    data-target="#exampleModalIP"
                                                                    onClick={() => this.setState({ customIP: true })}
                                                                    className="btn btn-primary btn-block mt-3">
                                                                    + Add IP
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    : <div>{this.state.template_schema_json.trafficRules?.[0]?.sourceIP}</div>}
                                            </td>
                                            <td className="c-gray">
                                                {!this.props.view ?
                                                    <div className="dropdown">
                                                        <ul className="chip-list z-index-9 radius">
                                                            {rule.destIP?.split(' ')?.map((item) => {
                                                                if (item)
                                                                    return <li>{item}<img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                                        onClick={(e) => this.handleTrafficDestIP(e, rule)} /></li>
                                                            })}
                                                        </ul>
                                                        <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                                        </div>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <ul>{
                                                                this.state.availableIP.length > 0 && this.state.availableIP.map((item) => {
                                                                    return (<li>
                                                                        <input
                                                                            id={item}
                                                                            type="checkbox"
                                                                            checked={rule?.destIP?.split(' ')?.includes(item)}
                                                                            onChange={(e) => this.handleTrafficDestIP(e, rule)}
                                                                        />
                                                                        {item}</li>)
                                                                })}
                                                                <li
                                                                    data-toggle="modal"
                                                                    data-target="#exampleModalIP"
                                                                    onClick={() => this.setState({ customIP: true })}
                                                                    className="btn btn-primary btn-block mt-3">
                                                                    + Add IP
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    : <div>{this.state.template_schema_json?.trafficRules?.[0]?.destIP}</div>}
                                            </td>
                                        </>}
                                    <td>{Common.getAction(rule?.action)}</td>
                                    <td>{rule?.enabled === '1' ? 'Yes' : 'No'}</td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className="gray-bg rounded p-2 mt-2">
                    <div className="mb-2">
                        <strong>NAT Rules</strong>
                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of NAT Rule">
                            <img src="/images/svg-icons/info.svg" alt="" />
                        </div>
                    </div>
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th className="w-80">Name</th>
                                <th className="w-150">Match</th>
                                {!this.props.info &&
                                    <>
                                        <th>Source IP
                                        </th>
                                        <th>Dest. IP
                                        </th>
                                    </>}
                                <th>Action</th>
                                {!this.props.info && <th>Rewrite IP Address
                                </th>}
                                <th>Enabled</th>
                            </tr>
                        </thead>
                        <tbody className="c-grayCursor">
                            {this.state.template_schema_json.natRules.filter(item => item.isdeleted !== '1')?.map((rule) =>
                                <tr>
                                    <td>{rule.name}</td>
                                    <td className="c-gray">
                                        <ul>
                                            <li><strong>Protocol:</strong><span>{rule?.proto}</span></li>
                                            <li><strong>Source IP:</strong><span>{rule?.sourceIP}</span></li>
                                            <li><strong>Destination IP:</strong><span>{rule?.destIP}</span></li>
                                            <li><strong>Outbound Zone:</strong><span>{((rule?.outboundZone).toLowerCase() === "lan" || (rule?.outboundZone).toLowerCase() === "wan") ? (rule.outboundZone).toUpperCase() : rule.outboundZone}</span></li>
                                        </ul>
                                    </td>
                                    {!this.props.info &&
                                        <>
                                            <td className="c-gray">
                                                {!this.props.view ?
                                                    <Select
                                                        isDisabled={true}
                                                        isClearable
                                                        components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP') }}
                                                        isSearchable
                                                        className="custom-dropdown w-105"
                                                        classNamePrefix="react-select-container"
                                                        name="customip"
                                                        options={this.state.options}
                                                        onChange={(e) => this.handleNatSourceIP(e, rule)}
                                                    />
                                                    : <div>{rule?.sourceIP}</div>}
                                            </td>
                                            <td className="c-gray">
                                                {!this.props.view ?
                                                    <Select
                                                        isDisabled={true}
                                                        isClearable
                                                        components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP') }}
                                                        isSearchable
                                                        className="custom-dropdown w-105"
                                                        classNamePrefix="react-select-container"
                                                        name="customip"
                                                        options={this.state.options}
                                                        onChange={(e) => this.handleNatDestIP(e, rule)}
                                                    />
                                                    : <div>{rule?.destIP}</div>}
                                            </td>
                                        </>}
                                    <td className="c-grayCursor">{rule?.action === 'snat' ? 'Statically Rewrite Source IP' : 'Automatically Rewrite Source IP'}</td>
                                    {!this.props.info &&
                                        <td className="c-grayCursor">
                                            {!this.props.view && rule.action === 'snat' ?
                                                <>
                                                    <input
                                                        type="text"
                                                        readOnly
                                                        className="form-control"
                                                        name="snatipAddress"
                                                        onChange={(e) => this.removeErrorIP(e)}
                                                        onBlur={(e) => this.handleSnatIP(e, rule)}
                                                        defaultValue={rule?.snatIP || ''} />
                                                    <div className="error-txt" id="snatipAddress"></div>
                                                </>
                                                // LONG-1704 : Fix for Rewrite IP address field not showing in NAT Rules when viewing Firewall Template
                                                : <input type="text" readOnly className='form-control' value={rule.snatIP} />}
                                        </td>}
                                    <td>{rule?.enabled === '1' ? 'Yes' : 'No'}</td>g
                                </tr>)}
                        </tbody>
                    </table>
                </div>
                {!this.props.view && (
                    <div className="s-footer">
                        {/* 1665 reapply page issue */}
                        <button className="btn btn-light" onClick={(this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
                            () => this.props.closeApplyTemplate()}>
                            {/* 1665 reapply page issue */}
                            {this.props.apply && !this.props.reapply ? "Back" : "Cancel"}
                        </button>
                        <button className="btn btn-primary" onClick={this.props.apply ? () => {
                            this.props.dispatch({
                                type: Action.SET_APPLY_TEMPLATE,
                            });
                            this.props.applyTemplate(this.state.template_schema_json);
                        } : ""}
                            disabled={
                                this.checkError() ||
                                this.props.applying}
                        >
                            {this.props.apply ? "Apply" : this.state.selectedTemplateId !== "" ? "Update" : "Save"}
                        </button>
                    </div>
                )}
                {this.state.customIP &&
                    <div className="modal fade" id="exampleModalIP" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add IP</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => this.setState({ customIP: false })}>
                                        <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group mb-1">
                                        <label>IP address/Subnet</label>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="text"
                                                className="form-control w-100"
                                                name="ipAddress"
                                                onBlur={(e) => this.validateIP(e)}
                                                onChange={(e) => this.removeErrorIP(e)}
                                            />
                                        </div>
                                        <div className="error-txt" id="ipAddress" >Invalid IP address</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-light" data-dismiss="modal"
                                        onClick={() => this.cancelIP()}>Cancel</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.state.validIP || this.state.showError}
                                        onClick={this.addIP}
                                    >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying,
        openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
    };
};
export default connect(mapStateToProps)(FirewallMIFIView);
