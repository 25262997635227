/* eslint-disable array-callback-return */
import React, { Component, Fragment } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import * as Action from "../../../../redux/actionTypes";
import * as Common from "../../../../utils/common";
import ErrorHandlePopup from "../../../ErrorHandlePopup";
import Select, { components } from 'react-select';
import debounce from 'lodash/debounce';
import { Netmask } from 'netmask';
import isEqual from 'lodash/isEqual';
import DataTable from "react-data-table-component";
import { Loader } from "../../../../layOuts/Loader";

const ButtonComponent = (props, onClickHandler, id, name, selected) => {
    return (
        <Fragment>
            <components.MenuList {...props}>
                <div>{props?.children}</div>
                <button
                    data-toggle="modal"
                    data-target={id}
                    onClick={() => onClickHandler(name, selected)}
                    name="addIP"
                    className="btn btn-primary btn-block mt-3"
                >{
                        name === 'customSubnet'
                            ? '+ Add Subnet'
                            : '+ Add IP'
                    }
                </button>
            </components.MenuList>
        </Fragment>
    );
};

class IPSecTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplateId: this.props?.id || "",
            template_type: this.props?.templateData?.template_type || "",
            template_name: this.props?.templateData?.template_name || "",
            template_desc: this.props?.templateData?.template_desc || "",
            device_model: this.props?.templateData?.device_model || "",
            collection_version: this.props?.templateData?.collection_version || "",
            fw_version: this.props?.templateData?.fw_version || "",
            chimay: this.props.chimay || "",
            cbr: this.props.cbr || "",
            mifi: this.props.mifi || "",
            view: this.props?.view || false,
            apply: this.props?.apply || false,
            reapply: this.props?.reapply || false,
            edit: this.props?.edit || false,
            info: this.props?.info || false,
            showRefreshModal: false,
            showError: false,
            show: false,
            showPassword: false,
            customIP: false,
            customIPAddress: '',
            customSubnet: '',
            subnet: false,
            validSubnet: false,
            validIP: false,
            remoteIP: false,
            isAxiosError: false,
            hide: false,
            selectedDeviceData: [],
            deviceList: [],
            searchVal: "",
            openDeviceSlide: false,
            toggledClearDeviceRows: false,
            selectedCustom: "",
            availableDeviceALocalIPNetworks: ['auto'],
            availableDeviceALocalSubnetNetworks: [],
            availableDeviceARemoteIPNetworks: ['auto'],
            deviceA_LocalIP_Options: [],
            deviceA_RemoteIP_Options: [],
            deviceA_LocalSubnet_Options: [],
            availableDeviceBLocalIPNetworks: ['auto'],
            availableDeviceBLocalSubnetNetworks: [],
            availableDeviceBRemoteIPNetworks: ['auto'],
            deviceB_LocalIP_Options: [],
            deviceB_RemoteIP_Options: [],
            deviceB_LocalSubnet_Options: [],
            availableGroupRemoteIPNetworks: [],
            group_RemoteIP_Options: [],
            template_schema_json:
            {
                "vpn_enable": "1",
                "vpnConfig": {
                    "deviceA": {
                        "device_id": "",
                        "device_mac": "",
                        "device_name": "",
                        "local_ip": "",
                        "local_network": [],
                        "remote_network": [],
                        "remote_ip": "",
                        "cert_id": "",
                        "cert_file": "",
                        "local_id": "",
                        "remote_id": ""
                    },
                    "deviceB": {
                        "device_id": "",
                        "device_mac": "",
                        "device_name": "",
                        "local_ip": "",
                        "local_network": [],
                        "remote_network": [],
                        "remote_ip": "",
                        "cert_id": "",
                        "cert_file": "",
                        "local_id": "",
                        "remote_id": ""
                    },
                    "group": {
                        "group_id": "",
                        "group_name": "",
                        "local_ip": "auto",
                        "local_network": ["0.0.0.0/0"],
                        "remote_network": [],
                        "remote_ip": "",
                        "cert_id": "",
                        "cert_file": "",
                        "local_id": "",
                        "remote_id": ""
                    },
                    "vpn_data": {
                        "tunnel_id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                        "start_tunnel": "1",
                        "enable_tunnel": "1",
                        "tunnel_name": "",
                        "authentication": "psk",
                        "pre_shared_key": "",
                        "keylife1": "28800",
                        "hash_algo1": 'md5',
                        "enc_algo1": 'aes256',
                        "dh_group1": 'modp1024',
                        "keylife2": "3600",
                        "hash_algo2": 'md5',
                        "enc_algo2": 'aes256',
                        "dh_group2": 'modp1024',
                        "dpd_enable": "1",
                        "dpd_delay": "15",
                        "dpd_action": "clear",
                        "dpd_timeout": "20",
                        "isdeleted": "0",
                        "isedit": "0"
                    },
                }
            },
            deviceA: {
                "device_id": "",
                "device_mac": "",
                "device_name": "",
                "local_ip": "",
                "local_network": [],
                "remote_network": [],
                "remote_ip": "",
                "cert_id": "",
                "cert_file": "",
                "local_id": "",
                "remote_id": ""
            },
            deviceB: {
                "device_id": "",
                "device_mac": "",
                "device_name": "",
                "local_ip": "",
                "local_network": [],
                "remote_network": [],
                "remote_ip": "",
                "cert_id": "",
                "cert_file": "",
                "local_id": "",
                "remote_id": ""
            },
            group: {
                "group_id": "",
                "group_name": "",
                "local_ip": "auto",
                "local_network": ["0.0.0.0/0"],
                "remote_network": [],
                "remote_ip": "",
                "cert_id": "",
                "cert_file": "",
                "local_id": "",
                "remote_id": ""
            },
            VPNData: {
                "tunnel_id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "start_tunnel": "1",
                "enable_tunnel": "1",
                "tunnel_name": "",
                "authentication": "psk",
                "pre_shared_key": "",
                "keylife1": "28800",
                "hash_algo1": 'md5',
                "enc_algo1": 'aes256',
                "dh_group1": 'modp1024',
                "keylife2": "3600",
                "hash_algo2": 'md5',
                "enc_algo2": 'aes256',
                "dh_group2": 'modp1024',
                "dpd_enable": "1",
                "dpd_delay": "15",
                "dpd_action": "clear",
                "dpd_timeout": "20",
                "isdeleted": "0",
                "isedit": "0"
            },
            availableEncryptions: [
                'aes256',
                'aes128',
                '3des',
                'blowfish'
            ],
            availableHash: [
                'md5',
                'sha1',
                'sha256'
            ],
            availableDHGroups: [
                'modp1024',
                'modp1536',
                'modp2048',
                'modp3072',
                'modp4096',
                'modp6144',
                'modp8192'
            ],
            availableEncryptions2: [
                'aes256',
                'aes128',
                '3des',
                'blowfish',
                'cast'
            ],
            availableHash2: [
                'md5',
                'sha1',
                'sha256'
            ],
            availableDHGroups2: [
                'modp1024',
                'modp1536',
                'modp2048',
                'modp3072',
                'modp4096',
                'modp6144',
                'modp8192'
            ],
            deviceListColumns: [{
                name: "Device Name",
                selector: "device_name",
                value: "Device Name",
                center: false,
                sortable: true,
                minWidth: "150px",
                cell: (row) => (
                    <span
                        className="dnamemac"
                        id={`icon${row?.device_id}`}
                        title={row?.device_name}>
                        <span className="hname">
                            <i className="status online"></i>
                            {row?.device_name}
                        </span>
                        <span className="maca">{row?.device_mac.toUpperCase()}</span>
                        <span
                            className={row?.group_name ? "maca-grp dg-text ml-2" : ""}
                        >{row?.group_name}</span>
                    </span>
                ),
            },
            {
                name: "Model",
                selector: "device_model",
                value: "Device Model",
                center: true,
                sortable: true,
                cell: (row) => (<span title={row?.device_model}>
                    {row?.device_model}</span>
                ),
            }
            ],
            deviceListColumnsDiff: [
                {
                    name: "Device Name",
                    selector: "device_name",
                    value: "Device Name",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.device_name || row?.ipsec_device_name}>
                        {row?.device_name || row?.ipsec_device_name}</span>
                    ),
                },
                {
                    name: "MAC Address ",
                    selector: "device_mac",
                    value: "Mac Address",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.device_mac?.toUpperCase() || row?.ipsec_device_mac?.toUpperCase()}>
                        {row?.device_mac?.toUpperCase() || row?.ipsec_device_mac?.toUpperCase()}</span>
                    ),
                },
                {
                    name: "Firmware ",
                    selector: "device_firmware_version",
                    value: "Firmware",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.device_firmware_version || row?.ipsec_fw_version}>
                        {row?.device_firmware_version || row?.ipsec_fw_version}</span>
                    ),
                },
                {
                    name: "Model",
                    selector: "device_model",
                    value: "Device Model",
                    center: true,
                    sortable: true,
                    cell: (row) => (<span title={row?.device_model || row?.ipsec_device_model}>
                        {row?.device_model || row?.ipsec_device_model}</span>
                    ),
                },
            ],
        };
    };

    componentDidMount() {
        if (
            (this.state.edit || this.state.view) &&
            this.props?.template_schema_json !== undefined
        ) {
            const template_schema_json = this.props?.template_schema_json?.ipsec_json ? JSON?.parse(JSON.stringify(this.props?.template_schema_json?.ipsec_json)) : "";
            this.setState({ showLoader: true }, () =>
                this.getValues(template_schema_json)
            )
        }
        if (!this.state?.edit && this.state?.apply) {
            let {
                VPNData,
                availableEncryptions,
                availableEncryptions2,
                availableHash,
                availableHash2,
                availableDHGroups,
                availableDHGroups2
            } = this.state;
            availableEncryptions = availableEncryptions?.filter(val => !(VPNData?.enc_algo1?.split('-')).includes(val));
            availableEncryptions2 = availableEncryptions2?.filter(val => !(VPNData?.enc_algo2?.split('-')).includes(val));
            availableHash = availableHash?.filter(val => !(VPNData?.hash_algo1?.split('-')).includes(val));
            availableHash2 = availableHash2?.filter(val => !(VPNData?.hash_algo2?.split('-')).includes(val));
            availableDHGroups = availableDHGroups?.filter(val => !(VPNData?.dh_group1?.split('-')).includes(val));
            availableDHGroups2 = availableDHGroups2?.filter(val => !(VPNData?.dh_group2?.split('-')).includes(val));
            this.setState({
                availableEncryptions,
                availableEncryptions2,
                availableHash,
                availableHash2,
                availableDHGroups,
                availableDHGroups2
            });
            if (
                this.props?.deviceANetwork !== undefined &&
                this.props?.ipsec_data !== undefined &&
                this.props?.selectedGroupDeviceData === undefined
            ) {
                let { deviceA } = this.state;
                let deviceA_LocalIP_Options = [];
                let deviceA_LocalSubnet_Options = [];
                let deviceA_RemoteIP_Options = [];
                let { availableDeviceALocalIPNetworks } = this.state;
                let { availableDeviceALocalSubnetNetworks } = this.state;
                let { availableDeviceARemoteIPNetworks } = this.state;
                this.props?.deviceANetwork.filter(element => (element.network_zone === 'wan' &&
                    element.ip_address !== '')).map((network) => {
                        availableDeviceALocalIPNetworks.push(network.ip_address);
                    })
                this.props?.deviceANetwork.filter(element => (element?.network_zone === 'lan' &&
                    element?.ip_address !== ''))?.map((network) => {
                        // EMGT-2477 network address must be in dropdown of local subnet
                        const block = new Netmask(`${network?.ip_address}/${network?.subnet}`);
                        availableDeviceALocalSubnetNetworks?.push(`${block?.base}/${network?.subnet}`);
                    })
                availableDeviceALocalIPNetworks?.length > 0 && availableDeviceALocalIPNetworks?.map((item) => {
                    if (!deviceA_LocalIP_Options?.find(val => val?.value === item)) {
                        if (item === 'auto') {
                            deviceA_LocalIP_Options?.push({ label: 'Auto', value: item });
                        } else
                            deviceA_LocalIP_Options?.push({ label: item, value: item });
                    }
                })
                availableDeviceALocalSubnetNetworks?.length > 0 && availableDeviceALocalSubnetNetworks?.map((item) => {
                    if (!deviceA_LocalSubnet_Options?.find(val => val.value === item)) {
                        deviceA_LocalSubnet_Options?.push({ label: item, value: item });
                    }
                })
                availableDeviceARemoteIPNetworks?.length > 0 && availableDeviceARemoteIPNetworks?.map((item) => {
                    if (!deviceA_RemoteIP_Options?.find(val => val?.value === item)) {
                        if (item === 'auto') {
                            deviceA_RemoteIP_Options?.push({ label: 'Auto', value: item });
                        } else
                            deviceA_RemoteIP_Options?.push({ label: item, value: item });
                    }
                })
                deviceA.device_id = this.props?.ipsec_data?.device_id;
                deviceA.device_mac = this.props?.ipsec_data?.ipsec_device_mac;
                deviceA.device_name = this.props?.ipsec_data?.ipsec_device_name;
                deviceA.device_status = "1";
                this.setState({
                    deviceA,
                    availableDeviceALocalIPNetworks,
                    availableDeviceALocalSubnetNetworks,
                    deviceA_LocalIP_Options,
                    deviceA_LocalSubnet_Options,
                    deviceA_RemoteIP_Options
                })
            }
            if (
                this.props?.deviceANetwork !== undefined &&
                this.props?.ipsec_data !== undefined &&
                this.props?.selectedGroupDeviceData !== undefined
            ) {
                let { deviceA } = this.state;
                let deviceA_LocalIP_Options = [""];
                let deviceA_LocalSubnet_Options = [];
                let deviceA_RemoteIP_Options = [];
                let { availableDeviceALocalIPNetworks } = this.state;
                let { availableDeviceALocalSubnetNetworks } = this.state;
                let { availableDeviceARemoteIPNetworks } = this.state;
                availableDeviceALocalIPNetworks = ["auto"];
                availableDeviceARemoteIPNetworks = ["auto"];
                this.props?.deviceANetwork?.filter(element => (element?.network_zone === 'lan' &&
                    element?.ip_address !== ''))?.map((network) => {
                        // EMGT-2477 network address must be in dropdown of local subnet
                        const block = new Netmask(`${network?.ip_address}/${network?.subnet}`);
                        availableDeviceALocalSubnetNetworks?.push(`${block.base}/${network?.subnet}`);
                    })
                availableDeviceALocalIPNetworks?.length > 0 && availableDeviceALocalIPNetworks?.map((item) => {
                    if (!deviceA_LocalIP_Options?.find(val => val?.value === item)) {
                        if (item === 'auto') {
                            deviceA_LocalIP_Options?.push({ label: 'Auto', value: item });
                        } else
                            deviceA_LocalIP_Options?.push({ label: item, value: item });
                    }
                })
                availableDeviceALocalSubnetNetworks?.length > 0 && availableDeviceALocalSubnetNetworks?.map((item) => {
                    if (!deviceA_LocalSubnet_Options?.find(val => val?.value === item)) {
                        deviceA_LocalSubnet_Options.push({ label: item, value: item });
                    }
                })
                availableDeviceARemoteIPNetworks?.length > 0 && availableDeviceARemoteIPNetworks?.map((item) => {
                    if (!deviceA_RemoteIP_Options?.find(val => val?.value === item)) {
                        if (item === 'auto') {
                            deviceA_RemoteIP_Options?.push({ label: 'Auto', value: item });
                        } else
                            deviceA_RemoteIP_Options?.push({ label: item, value: item });
                    }
                })
                deviceA.device_id = this.props?.ipsec_data?.device_id;
                deviceA.device_mac = this.props?.ipsec_data?.ipsec_device_mac;
                deviceA.device_name = this.props?.ipsec_data?.ipsec_device_name;
                deviceA.device_status = "1";
                deviceA.local_ip = "auto";
                deviceA.remote_ip = "auto";
                deviceA.remote_network = ["0.0.0.0/0"];
                this.setState({
                    deviceA,
                    availableDeviceALocalIPNetworks,
                    availableDeviceALocalSubnetNetworks,
                    deviceA_LocalIP_Options,
                    deviceA_LocalSubnet_Options,
                    deviceA_RemoteIP_Options
                })
            }
            if (
                this.props?.deviceBNetwork !== undefined &&
                this.props?.selectedDeviceData !== undefined
            ) {
                let { deviceB } = this.state;
                let deviceB_LocalIP_Options = [];
                let deviceB_LocalSubnet_Options = [];
                let deviceB_RemoteIP_Options = [];
                let { availableDeviceBLocalIPNetworks } = this.state;
                let { availableDeviceBLocalSubnetNetworks } = this.state;
                let { availableDeviceBRemoteIPNetworks } = this.state;
                this.props?.deviceBNetwork?.filter(element => (element?.network_zone === 'wan' &&
                    element.ip_address !== '')).map((network) => {
                        availableDeviceBLocalIPNetworks?.push(network?.ip_address);
                    })
                this.props?.deviceBNetwork?.filter(element => (element?.network_zone === 'lan' &&
                    element.ip_address !== ''))?.map((network) => {
                        // EMGT-2477 network address must be in dropdown of local subnet
                        const block = new Netmask(`${network?.ip_address}/${network?.subnet}`);
                        availableDeviceBLocalSubnetNetworks?.push(`${block.base}/${network?.subnet}`)
                    })
                availableDeviceBLocalIPNetworks?.length > 0 && availableDeviceBLocalIPNetworks?.map((item) => {
                    if (!deviceB_LocalIP_Options?.find(val => val?.value === item)) {
                        if (item === 'auto') {
                            deviceB_LocalIP_Options?.push({ label: 'Auto', value: item });
                        } else
                            deviceB_LocalIP_Options?.push({ label: item, value: item });
                    }
                })
                availableDeviceBLocalSubnetNetworks?.length > 0 && availableDeviceBLocalSubnetNetworks?.map((item) => {
                    if (!deviceB_LocalSubnet_Options?.find(val => val.value === item)) {
                        deviceB_LocalSubnet_Options?.push({ label: item, value: item });
                    }
                })
                availableDeviceBRemoteIPNetworks?.length > 0 && availableDeviceBRemoteIPNetworks?.map((item) => {
                    if (!deviceB_RemoteIP_Options?.find(val => val.value === item)) {
                        if (item === 'auto') {
                            deviceB_RemoteIP_Options?.push({ label: 'Auto', value: item });
                        } else
                            deviceB_RemoteIP_Options?.push({ label: item, value: item });
                    }
                })
                deviceB.device_id = this.props?.selectedDeviceData?.device_id;
                deviceB.device_mac = this.props?.selectedDeviceData?.device_mac;
                deviceB.device_name = this.props?.selectedDeviceData?.device_name;
                deviceB.device_status = "1";
                this.setState({
                    deviceB,
                    availableDeviceBLocalIPNetworks,
                    availableDeviceBLocalSubnetNetworks,
                    deviceB_LocalIP_Options,
                    deviceB_LocalSubnet_Options,
                    deviceB_RemoteIP_Options
                })
            }
            else if (this.props?.selectedGroupDeviceData !== undefined) {
                let { group } = this.state;
                group.group_id = this.props?.selectedGroupDeviceData?.group_id;
                group.group_name = this.props?.selectedGroupDeviceData?.group_name;
                group.group_status = "1";
                this.setState({
                    group
                })
            }
        }
        if (this.state.info && this.props.templateData?.ipsec_details !== undefined) {
            let selectedDeviceData = [];
            selectedDeviceData?.push(this.props?.templateData?.ipsec_details)
            this.setState({ selectedDeviceData: selectedDeviceData })
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            (nextProps?.edit || nextProps?.view) &&
            nextProps?.template_schema_json !== undefined
        ) {
            if (!isEqual(this.props?.template_schema_json?.ipsec_json, nextProps?.template_schema_json?.ipsec_json)) {
                const template_schema_json = JSON.parse(JSON.stringify(nextProps?.template_schema_json?.ipsec_json))
                this.getValues(template_schema_json);
            }
        }
        if (!nextProps?.edit && nextProps?.apply) {
            let {
                VPNData,
                availableEncryptions,
                availableEncryptions2,
                availableHash,
                availableHash2,
                availableDHGroups,
                availableDHGroups2
            } = this.state;
            availableEncryptions = availableEncryptions?.filter(val => !(VPNData?.enc_algo1?.split('-')).includes(val));
            availableEncryptions2 = availableEncryptions2?.filter(val => !(VPNData?.enc_algo2?.split('-')).includes(val));
            availableHash = availableHash?.filter(val => !(VPNData?.hash_algo1?.split('-')).includes(val));
            availableHash2 = availableHash2?.filter(val => !(VPNData?.hash_algo2?.split('-')).includes(val));
            availableDHGroups = availableDHGroups?.filter(val => !(VPNData?.dh_group1?.split('-')).includes(val));
            availableDHGroups2 = availableDHGroups2?.filter(val => !(VPNData?.dh_group2?.split('-')).includes(val));
            this.setState({
                availableEncryptions,
                availableEncryptions2,
                availableHash,
                availableHash2,
                availableDHGroups,
                availableDHGroups2
            });
            if (
                nextProps?.deviceANetwork !== undefined &&
                nextProps?.ipsec_data !== undefined &&
                nextProps?.selectedGroupDeviceData === undefined
            ) {
                if (
                    !isEqual(this.props?.deviceANetwork, nextProps?.deviceANetwork) ||
                    !isEqual(this.props?.ipsec_data, nextProps?.ipsec_data)
                ) {
                    let { deviceA } = this.state;
                    let deviceA_LocalIP_Options = [];
                    let deviceA_LocalSubnet_Options = [];
                    let deviceA_RemoteIP_Options = [];
                    let { availableDeviceALocalIPNetworks } = this.state;
                    let { availableDeviceALocalSubnetNetworks } = this.state;
                    let { availableDeviceARemoteIPNetworks } = this.state;
                    nextProps?.deviceANetwork?.filter(element => (element?.network_zone === 'wan' &&
                        element?.ip_address !== '')).map((network) => {
                            availableDeviceALocalIPNetworks?.push(network?.ip_address);
                        })
                    nextProps?.deviceANetwork?.filter(element => (element?.network_zone === 'lan' &&
                        element?.ip_address !== ''))?.map((network) => {
                            // EMGT-2477 network address must be in dropdown of local subnet
                            const block = new Netmask(`${network?.ip_address}/${network?.subnet}`);
                            availableDeviceALocalSubnetNetworks?.push(`${block.base}/${network?.subnet}`);
                        })
                    availableDeviceALocalIPNetworks?.length > 0 && availableDeviceALocalIPNetworks?.map((item) => {
                        if (!deviceA_LocalIP_Options?.find(val => val?.value === item)) {
                            if (item === 'auto') {
                                deviceA_LocalIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceA_LocalIP_Options?.push({ label: item, value: item });
                        }
                    })
                    availableDeviceALocalSubnetNetworks?.length > 0 && availableDeviceALocalSubnetNetworks?.map((item) => {
                        if (!deviceA_LocalSubnet_Options?.find(val => val?.value === item)) {
                            deviceA_LocalSubnet_Options?.push({ label: item, value: item });
                        }
                    })
                    availableDeviceARemoteIPNetworks?.length > 0 && availableDeviceARemoteIPNetworks?.map((item) => {
                        if (!deviceA_RemoteIP_Options?.find(val => val.value === item)) {
                            if (item === 'auto') {
                                deviceA_RemoteIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceA_RemoteIP_Options?.push({ label: item, value: item });
                        }
                    })
                    deviceA.device_id = nextProps?.ipsec_data?.device_id;
                    deviceA.device_name = nextProps?.ipsec_data?.ipsec_device_name;
                    deviceA.device_mac = nextProps?.ipsec_data?.ipsec_device_mac;
                    deviceA.device_status = "1";
                    this.setState({
                        deviceA,
                        availableDeviceALocalIPNetworks,
                        availableDeviceALocalSubnetNetworks,
                        deviceA_LocalIP_Options,
                        deviceA_LocalSubnet_Options,
                        deviceA_RemoteIP_Options
                    })
                }
            }
            if (
                nextProps?.deviceANetwork !== undefined &&
                nextProps?.ipsec_data !== undefined &&
                nextProps?.selectedGroupDeviceData !== undefined
            ) {
                if (
                    !isEqual(this.props?.deviceANetwork, nextProps?.deviceANetwork) ||
                    !isEqual(this.props?.ipsec_data, nextProps?.ipsec_data)
                ) {
                    let { deviceA } = this.state;
                    let deviceA_LocalIP_Options = [""];
                    let deviceA_LocalSubnet_Options = [];
                    let deviceA_RemoteIP_Options = [];
                    let { availableDeviceALocalIPNetworks } = this.state;
                    let { availableDeviceALocalSubnetNetworks } = this.state;
                    let { availableDeviceARemoteIPNetworks } = this.state;
                    availableDeviceALocalIPNetworks = ["auto"];
                    availableDeviceARemoteIPNetworks = ["auto"];
                    nextProps?.deviceANetwork?.filter(element => (element?.network_zone === 'lan' &&
                        element.ip_address !== ''))?.map((network) => {
                            // EMGT-2477 network address must be in dropdown of local subnet
                            const block = new Netmask(`${network?.ip_address}/${network?.subnet}`);
                            availableDeviceALocalSubnetNetworks?.push(`${block.base}/${network?.subnet}`)
                        })
                    availableDeviceALocalIPNetworks?.length > 0 && availableDeviceALocalIPNetworks?.map((item) => {
                        if (!deviceA_LocalIP_Options?.find(val => val?.value === item)) {
                            if (item === 'auto') {
                                deviceA_LocalIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceA_LocalIP_Options?.push({ label: item, value: item });
                        }
                    })
                    availableDeviceALocalSubnetNetworks?.length > 0 && availableDeviceALocalSubnetNetworks?.map((item) => {
                        if (!deviceA_LocalSubnet_Options?.find(val => val?.value === item)) {
                            deviceA_LocalSubnet_Options?.push({ label: item, value: item });
                        }
                    })
                    availableDeviceARemoteIPNetworks?.length > 0 && availableDeviceARemoteIPNetworks?.map((item) => {
                        if (!deviceA_RemoteIP_Options?.find(val => val?.value === item)) {
                            if (item === 'auto') {
                                deviceA_RemoteIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceA_RemoteIP_Options?.push({ label: item, value: item });
                        }
                    })
                    deviceA.device_id = nextProps?.ipsec_data?.device_id;
                    deviceA.device_mac = nextProps?.ipsec_data?.ipsec_device_mac;
                    deviceA.device_name = nextProps?.ipsec_data?.ipsec_device_name;
                    deviceA.device_status = "1";
                    deviceA.local_ip = "auto";
                    deviceA.remote_ip = "auto";
                    deviceA.remote_network = ["0.0.0.0/0"];
                    this.setState({
                        deviceA,
                        availableDeviceALocalIPNetworks,
                        availableDeviceALocalSubnetNetworks,
                        deviceA_LocalIP_Options,
                        deviceA_LocalSubnet_Options,
                        deviceA_RemoteIP_Options
                    })
                }
            }
            if (nextProps?.deviceBNetwork !== undefined && nextProps?.selectedDeviceData !== undefined) {
                if (!isEqual(this.props?.deviceBNetwork, nextProps?.deviceBNetwork) || !isEqual(this.props?.selectedDeviceData, nextProps?.selectedDeviceData)) {
                    let { deviceB } = this.state;
                    let deviceB_LocalIP_Options = [];
                    let deviceB_LocalSubnet_Options = [];
                    let deviceB_RemoteIP_Options = [];
                    let { availableDeviceBLocalIPNetworks } = this.state;
                    let { availableDeviceBLocalSubnetNetworks } = this.state;
                    let { availableDeviceBRemoteIPNetworks } = this.state;
                    nextProps?.deviceBNetwork?.filter(element => (element?.network_zone === 'wan' &&
                        element.ip_address !== ''))?.map((network) => {
                            availableDeviceBLocalIPNetworks?.push(network?.ip_address)
                        })
                    nextProps?.deviceBNetwork?.filter(element => (element?.network_zone === 'lan' &&
                        element?.ip_address !== ''))?.map((network) => {
                            // EMGT-2477 network address must be in dropdown of local subnet
                            const block = new Netmask(`${network?.ip_address}/${network?.subnet}`);
                            availableDeviceBLocalSubnetNetworks?.push(`${block.base}/${network?.subnet}`)
                        })
                    availableDeviceBLocalIPNetworks?.length > 0 && availableDeviceBLocalIPNetworks?.map((item) => {
                        if (!deviceB_LocalIP_Options?.find(val => val?.value === item)) {
                            if (item === 'auto') {
                                deviceB_LocalIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceB_LocalIP_Options?.push({ label: item, value: item });
                        }
                    })
                    availableDeviceBLocalSubnetNetworks?.length > 0 && availableDeviceBLocalSubnetNetworks?.map((item) => {
                        if (!deviceB_LocalSubnet_Options?.find(val => val?.value === item)) {
                            deviceB_LocalSubnet_Options.push({ label: item, value: item });
                        }
                    })
                    availableDeviceBRemoteIPNetworks?.length > 0 && availableDeviceBRemoteIPNetworks?.map((item) => {
                        if (!deviceB_RemoteIP_Options?.find(val => val?.value === item)) {
                            if (item === 'auto') {
                                deviceB_RemoteIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceB_RemoteIP_Options?.push({ label: item, value: item });
                        }
                    })
                    deviceB.device_id = nextProps?.selectedDeviceData?.device_id;
                    deviceB.device_mac = nextProps?.selectedDeviceData?.device_mac;
                    deviceB.device_name = nextProps?.selectedDeviceData?.device_name;
                    deviceB.device_status = "1";
                    this.setState({
                        deviceB,
                        availableDeviceBLocalIPNetworks,
                        availableDeviceBLocalSubnetNetworks,
                        deviceB_LocalIP_Options,
                        deviceB_LocalSubnet_Options,
                        deviceB_RemoteIP_Options
                    })
                }
            }
            else if (
                nextProps?.selectedGroupDeviceData !== undefined &&
                !isEqual(this.props?.selectedGroupDeviceData, nextProps?.selectedGroupDeviceData)
            ) {
                let { group } = this.state;
                group.group_id = nextProps?.selectedGroupDeviceData?.group_id;
                group.group_name = nextProps?.selectedGroupDeviceData?.group_name;
                group.group_status = "1";
                this.setState({
                    group
                })
            }
        }
        if (nextProps.info && nextProps.templateData?.ipsec_details !== undefined) {
            if (!isEqual(this.props?.templateData?.ipsec_details, nextProps?.templateData?.ipsec_details)) {
                let selectedDeviceData = [];
                selectedDeviceData?.push(nextProps?.templateData?.ipsec_details)
                this.setState({ selectedDeviceData: selectedDeviceData })
            }
        }
        if (Object.keys(nextProps?.templateData).length !== 0) {
            this.setState({
                selectedTemplateId: nextProps.id || "",
                template_type: nextProps?.templateData?.template_type || "",
                template_name: nextProps?.templateData?.template_name || "",
                template_desc: nextProps?.templateData?.template_desc || "",
                device_model: nextProps?.templateData?.device_model || "",
                collection_version: nextProps?.templateData?.collection_version || "",
                fw_version: nextProps?.templateData?.fw_version || ""
            });
        }
        if (!isEqual(this.state?.chimay, nextProps?.chimay) ||
            !isEqual(this.state?.cbr, nextProps?.cbr) ||
            !isEqual(this.state?.mifi, nextProps?.mifi)) {
            this.setState({
                chimay: nextProps.chimay || "",
                cbr: nextProps.cbr || "",
                mifi: nextProps.mifi || ""
            })
        }
        if (!isEqual(this.state?.view, nextProps?.view) ||
            !isEqual(this.state?.apply, nextProps?.apply) ||
            !isEqual(this.state?.reapply, nextProps?.reapply) ||
            !isEqual(this.state?.edit, nextProps?.edit) ||
            !isEqual(this.state?.info, nextProps?.info)
        ) {
            this.setState({
                view: nextProps?.view || false,
                apply: nextProps?.apply || false,
                reapply: nextProps?.reapply || false,
                edit: nextProps?.edit || false,
                info: nextProps?.info || false
            });
        }
        if (
            nextProps?.create &&
            nextProps?.selectedModelList !== null &&
            nextProps?.selectedModelList !== "" &&
            nextProps?.selectedModelList !== undefined
        ) {
            this.setState({
                selectedDeviceData: [],
                toggledClearSelectedDeviceRows: !this.state.toggledClearSelectedDeviceRows,
                multipleSelect: "",
                adding: false
            });
        }
    };

    getValues = async (value) => {
        try {
            if (value?.vpnConfig) {
                const { deviceA, deviceB, group, vpn_data } = value.vpnConfig;
                const promises = [];
                if (deviceA) promises.push(this.getDeviceANetworks(deviceA));
                if (deviceB) promises.push(this.getDeviceBNetworks(deviceB));
                if (group) {
                    promises.push(this.getGroupNetworks(group));
                    promises.push(this.getGroupDetails(group.group_id));
                }
                promises.push(this.getDeviceDetails(deviceA?.device_id, deviceB?.device_id));
                promises.push(this.getVPNDataValues(vpn_data));
                await Promise.all(promises);
            }
            await this.setState({
                showLoader: false
            });
        } catch (error) {
            this.setState({
                showLoader: false
            });
        }
    };

    getDeviceANetworks = async (value) => {
        const data = {
            device_id: parseInt(value?.device_id)
        }
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true })
            await ApiCall(urlMapping.getNetworkView(data), (response) => {
                if (response?.Success) {
                    const data = response?.data || [];
                    let deviceA_LocalIP_Options = [];
                    let deviceA_LocalSubnet_Options = [];
                    let deviceA_RemoteIP_Options = [];
                    let { deviceA } = this.state;
                    let { availableDeviceALocalIPNetworks } = this.state;
                    let { availableDeviceALocalSubnetNetworks } = this.state;
                    let { availableDeviceARemoteIPNetworks } = this.state;
                    data?.filter(element => (element?.network_zone === 'wan' &&
                        element?.ip_address !== '')).map((network) => {
                            availableDeviceALocalIPNetworks.push(network?.ip_address)
                        })
                    data.filter(element => (element?.network_zone === 'lan' &&
                        element?.ip_address !== ''))?.map((network) => {
                            // EMGT-2477 network address must be in dropdown of local subnet
                            const block = new Netmask(`${network?.ip_address}/${network?.subnet}`);
                            availableDeviceALocalSubnetNetworks?.push(`${block.base}/${network?.subnet}`)
                        })
                    !availableDeviceALocalIPNetworks?.includes(value?.local_ip) && availableDeviceALocalIPNetworks?.push(value?.local_ip)
                    availableDeviceALocalSubnetNetworks = Array?.from(new Set([...availableDeviceALocalSubnetNetworks, ...value?.local_network]));
                    !availableDeviceARemoteIPNetworks?.includes(value?.remote_ip) && availableDeviceALocalIPNetworks?.push(value?.remote_ip)
                    availableDeviceALocalIPNetworks?.length > 0 && availableDeviceALocalIPNetworks?.map((item) => {
                        if (!deviceA_LocalIP_Options?.find(value => value.value === item)) {
                            if (item === 'auto') {
                                deviceA_LocalIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceA_LocalIP_Options?.push({ label: item, value: item });
                        }
                    })
                    availableDeviceALocalSubnetNetworks?.length > 0 && availableDeviceALocalSubnetNetworks?.map((item) => {
                        if (!deviceA_LocalSubnet_Options?.find(value => value.value === item)) {
                            deviceA_LocalSubnet_Options?.push({ label: item, value: item });
                        }
                    })
                    availableDeviceARemoteIPNetworks?.length > 0 && availableDeviceARemoteIPNetworks?.map((item) => {
                        if (!deviceA_RemoteIP_Options?.find(value => value.value === item)) {
                            if (item === 'auto') {
                                deviceA_RemoteIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceA_RemoteIP_Options?.push({ label: item, value: item });
                        }
                    })
                    deviceA.local_ip = value?.local_ip || "";
                    deviceA.remote_ip = value?.remote_ip || "";
                    deviceA.local_network = value?.local_network || [];
                    deviceA.remote_network = value?.remote_network || [];
                    deviceA.cert_id = value?.cert_id || "";
                    deviceA.cert_file = value?.cert_file || "";
                    deviceA.local_id = value?.local_id || "";
                    deviceA.remote_id = value?.remote_id || "";
                    this.setState({
                        deviceA,
                        availableDeviceALocalIPNetworks,
                        availableDeviceALocalSubnetNetworks,
                        deviceA_LocalIP_Options,
                        deviceA_LocalSubnet_Options,
                        deviceA_RemoteIP_Options
                    })
                } else {
                    this.setState({
                        showLoader: false,
                    })
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (
                        response?.isAxiosError &&
                        response?.success === undefined &&
                        response?.response?.data?.success === undefined
                    ) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: this.props?.tunnelEdit ? "Edit Tunnel" : "Apply Template",
                            message: this.props?.tunnelEdit
                                ? 'No Networks found on device. Network template must be applied to apply template on device'
                                : 'No Networks found on device. Network template must be applied to edit tunnel',
                            show: true,
                            error: true,
                            showLoader: false,
                            disableApply: true
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        }
    };

    getDeviceBNetworks = async (value) => {
        const data = {
            device_id: parseInt(value?.device_id)
        }
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true })
            await ApiCall(urlMapping.getNetworkView(data), (response) => {
                if (response?.Success) {
                    const data = response?.data || [];
                    let deviceB_LocalIP_Options = [];
                    let deviceB_LocalSubnet_Options = [];
                    let deviceB_RemoteIP_Options = [];
                    let { deviceB } = this.state;
                    let { availableDeviceBLocalIPNetworks } = this.state;
                    let { availableDeviceBLocalSubnetNetworks } = this.state;
                    let { availableDeviceBRemoteIPNetworks } = this.state;
                    data?.filter(element => (element?.network_zone === 'wan' &&
                        element?.ip_address !== '')).map((network) => {
                            availableDeviceBLocalIPNetworks?.push(network?.ip_address)
                        })
                    data?.filter(element => (element?.network_zone === 'lan' &&
                        element?.ip_address !== ''))?.map((network) => {
                            // EMGT-2477 network address must be in dropdown of local subnet
                            const block = new Netmask(`${network?.ip_address}/${network?.subnet}`);
                            availableDeviceBLocalSubnetNetworks?.push(`${block.base}/${network?.subnet}`)
                        })
                    !availableDeviceBLocalIPNetworks?.includes(value?.local_ip) && availableDeviceBLocalIPNetworks?.push(value?.local_ip)
                    availableDeviceBLocalSubnetNetworks = Array?.from(new Set([...availableDeviceBLocalSubnetNetworks, ...value?.local_network]));
                    !availableDeviceBRemoteIPNetworks?.includes(value?.remote_ip) && availableDeviceBLocalIPNetworks?.push(value?.remote_ip)
                    availableDeviceBLocalIPNetworks?.length > 0 && availableDeviceBLocalIPNetworks?.map((item) => {
                        if (!deviceB_LocalIP_Options?.find(value => value?.value === item)) {
                            if (item === 'auto') {
                                deviceB_LocalIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceB_LocalIP_Options?.push({ label: item, value: item });
                        }
                    })
                    availableDeviceBLocalSubnetNetworks?.length > 0 && availableDeviceBLocalSubnetNetworks?.map((item) => {
                        if (!deviceB_LocalSubnet_Options?.find(value => value.value === item)) {
                            deviceB_LocalSubnet_Options?.push({ label: item, value: item });
                        }
                    })
                    availableDeviceBRemoteIPNetworks?.length > 0 && availableDeviceBRemoteIPNetworks?.map((item) => {
                        if (!deviceB_RemoteIP_Options?.find(value => value.value === item)) {
                            if (item === 'auto') {
                                deviceB_RemoteIP_Options?.push({ label: 'Auto', value: item });
                            } else
                                deviceB_RemoteIP_Options?.push({ label: item, value: item });
                        }
                    })
                    deviceB.local_ip = value?.local_ip || "";
                    deviceB.remote_ip = value?.remote_ip || "";
                    deviceB.local_network = value?.local_network || [];
                    deviceB.remote_network = value?.remote_network || [];
                    deviceB.cert_id = value?.cert_id || "";
                    deviceB.cert_file = value?.cert_file || "";
                    deviceB.local_id = value?.local_id || "";
                    deviceB.remote_id = value?.remote_id || "";
                    this.setState({
                        deviceB,
                        availableDeviceBLocalIPNetworks,
                        availableDeviceBLocalSubnetNetworks,
                        deviceB_LocalIP_Options,
                        deviceB_LocalSubnet_Options,
                        deviceB_RemoteIP_Options
                    })
                } else {
                    this.setState({
                        showLoader: false,
                    })
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Apply Template',
                            message: 'No Networks found on device. Network template must be applied to apply template on device',
                            show: true,
                            error: true,
                            showLoader: false,
                            disableApply: true
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        }
    };

    getGroupNetworks = (value) => {
        let { group, availableGroupRemoteIPNetworks, group_RemoteIP_Options } = this.state;
        !availableGroupRemoteIPNetworks?.includes(value?.remote_ip) &&
            availableGroupRemoteIPNetworks?.push(value?.remote_ip);
        availableGroupRemoteIPNetworks?.length > 0 && availableGroupRemoteIPNetworks?.map((item) => {
            if (!group_RemoteIP_Options?.find(value => value?.value === item)) {
                if (item === 'auto') {
                    group_RemoteIP_Options?.push({ label: 'Auto', value: item });
                } else
                    group_RemoteIP_Options?.push({ label: item, value: item });
            }
        })
        group.local_ip = value?.local_ip || "auto";
        group.remote_ip = value?.remote_ip || "";
        group.local_network = value?.local_network || ["0.0.0.0/0"];
        group.remote_network = value?.remote_network || [];
        group.cert_id = value?.cert_id || "";
        group.cert_file = value?.cert_file || "";
        group.local_id = value?.local_id || "";
        group.remote_id = value?.remote_id || "";
        this.setState({
            group,
            group_RemoteIP_Options,
            availableGroupRemoteIPNetworks
        })
    };

    getDeviceDetails = (device_A, device_B) => {
        let data = {
            device_id: []
        };
        device_A && data?.device_id?.push(device_A);
        device_B && data?.device_id?.push(device_B);
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.getDeviceLookUp(data), (response) => {
            if (response?.results) {
                if (device_A) {
                    const data = response?.results?.filter((val) => val?.device_id === device_A)?.[0];
                    if (data) {
                        let { deviceA } = this.state;
                        deviceA.device_id = data.device_id;
                        deviceA.device_mac = data.device_mac;
                        deviceA.device_name = data.device_name;
                        deviceA.device_status = data.device_status;
                        this.setState({ deviceA });
                    }
                }
                if (device_B) {
                    const data = response?.results?.filter((val) => val?.device_id === device_B)?.[0];
                    if (data) {
                        let { deviceB } = this.state;
                        deviceB.device_id = data.device_id;
                        deviceB.device_mac = data.device_mac;
                        deviceB.device_name = data.device_name;
                        deviceB.device_status = data.device_status;
                        this.setState({ deviceB });
                    }
                }
            } else {
                this.setState({ showLoader: false })
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                return false
            }
        });
    };

    getGroupDetails = (value) => {
        const data = { group_id: parseInt(value) };
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.getDeviceGroupByModel(data), (response) => {
            if (response?.results) {
                if (value) {
                    let { group } = this.state;
                    group.group_id = response?.results?.[0]?.group_id;
                    group.group_name = response?.results?.[0]?.group_name;
                    group.group_status = response?.results?.[0]?.is_all_device_online;
                    this.setState({ group });
                }
            } else {
                this.setState({
                    showLoader: false,
                });
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                    });
                }
            }
        });
    }

    getVPNDataValues = (value) => {
        let {
            VPNData,
            availableEncryptions,
            availableEncryptions2,
            availableHash,
            availableHash2,
            availableDHGroups,
            availableDHGroups2
        } = this.state;
        VPNData.tunnel_id = value?.tunnel_id || Math.random().toString(36).substring(2, 11).toUpperCase();
        VPNData.start_tunnel = value?.start_tunnel || "1";
        VPNData.enable_tunnel = value?.enable_tunnel || "1";
        VPNData.tunnel_name = value?.tunnel_name || "";
        VPNData.authentication = value?.authentication || "psk";
        VPNData.pre_shared_key = value?.pre_shared_key || "";
        VPNData.keylife1 = value?.keylife1 || "28800";
        VPNData.hash_algo1 = value?.hash_algo1 || 'md5';
        VPNData.enc_algo1 = value?.enc_algo1 || 'aes256';
        VPNData.dh_group1 = value?.dh_group1 || 'modp1024';
        VPNData.keylife2 = value?.keylife2 || "3600";
        VPNData.hash_algo2 = value?.hash_algo2 || 'md5';
        VPNData.enc_algo2 = value?.enc_algo2 || 'aes256';
        VPNData.dh_group2 = value?.dh_group2 || '';
        VPNData.dpd_enable = value?.dpd_enable || "1";
        VPNData.dpd_delay = value?.dpd_delay || "15";
        VPNData.dpd_action = value?.dpd_action || "clear";
        VPNData.dpd_timeout = value?.dpd_timeout || "20";
        VPNData.isdeleted = value?.isdeleted || "0";
        VPNData.isedit = "1";
        availableEncryptions = availableEncryptions?.filter(val => !(VPNData?.enc_algo1?.split('-')).includes(val));
        availableEncryptions2 = availableEncryptions2?.filter(val => !(VPNData?.enc_algo2?.split('-')).includes(val));
        availableHash = availableHash?.filter(val => !(VPNData?.hash_algo1?.split('-')).includes(val));
        availableHash2 = availableHash2?.filter(val => !(VPNData?.hash_algo2?.split('-')).includes(val));
        availableDHGroups = availableDHGroups?.filter(val => !(VPNData?.dh_group1?.split('-')).includes(val));
        availableDHGroups2 = availableDHGroups2?.filter(val => !(VPNData?.dh_group2?.split('-')).includes(val));
        this.setState({
            VPNData,
            availableEncryptions,
            availableEncryptions2,
            availableHash,
            availableHash2,
            availableDHGroups,
            availableDHGroups2
        });
    };

    handleFileUpload = (deviceType, e) => {
        const device = this.state[deviceType];
        const file = e?.target?.files?.[0] || undefined;
        if (
            file !== undefined
        ) {
            const formData = new FormData();
            formData.append("file", file);
            this.setState({ showLoader: true })
            ApiCall(urlMapping.deviceListUpload(formData), (response) => {
                if (response?.success) {
                    device.cert_file = response?.file_name;
                    this.setState({
                        [deviceType]: device,
                        showLoader: false
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        device.cert_file = ""
                        this.setState({
                            [deviceType]: device,
                            showRefreshModal: true,
                            showLoader: false,
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        device.cert_file = "";
                        this.setState({
                            [deviceType]: device,
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        device.cert_file = ""
                        this.setState(
                            {
                                [deviceType]: device,
                                title: "Upload Files",
                                message: response?.message,
                                show: true,
                                error: true,
                                showLoader: false,
                            },
                            () => $("#errorMessage").modal("show")
                        );
                    }
                }
            })
        }
        else {
            device.cert_file = ""
            this.setState({
                [deviceType]: device
            });
        }
    };

    setCertId = (e) => {
        let { deviceA, deviceB, group } = this.state;
        const elements = document.getElementsByClassName(`error-txt deviceA`);
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = "none";
        }
        deviceA.local_id = '';
        deviceA.remote_id = '';
        deviceA.cert_id = e.target.value;
        deviceB.local_id = '';
        deviceB.remote_id = '';
        deviceB.cert_id = e.target.value;
        group.local_id = '';
        group.remote_id = '';
        group.cert_id = e.target.value;
        this.setState({
            deviceA, deviceB, group
        });
    };

    setTunnelValues = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        const name = e.target.name;
        const value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
        const data = this.state.VPNData;
        if (
            name === "dpd_delay" ||
            name === "dpd_timeout" ||
            name === "keylife1" ||
            name === "keylife2"
        ) {
            if (Common.validateNumeric(e))
                data[name] = value;
        }
        else {
            data[name] = value;
        }
        if (name === "dpd_enable") {
            const elements = document.getElementsByClassName("error-txt dpd_enable")
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.display = 'none';
            }
            data.dpd_action = "clear";
            data.dpd_delay = "15";
            data.dpd_timeout = "20"
        }
        if (name === "authentication") {
            let { deviceA, deviceB, group } = this.state;
            data.pre_shared_key = '';
            deviceA.cert_id = "";
            deviceA.cert_file = "";
            deviceA.local_id = '';
            deviceA.remote_id = '';
            deviceB.cert_id = "";
            deviceB.cert_file = "";
            deviceB.local_id = '';
            deviceB.remote_id = '';
            group.cert_id = "";
            group.cert_file = "";
            group.local_id = '';
            group.remote_id = '';
            if (value === "pubkey-cert") {
                deviceA.cert_id = "fqdn";
                deviceB.cert_id = "fqdn";
                group.cert_id = "fqdn";
            }
            this.setState({
                deviceA,
                deviceB,
                group
            })
        }
        this.setState({
            VPNData: data
        })
    };

    checkTunnelName = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            const regex = /^[a-zA-Z0-9_-]*$/;
            const result = regex.test(e.target.value);
            if (
                !result ||
                e.target.value === "" ||
                e.target.value.length < 3 ||
                e.target.value.length > 20
            ) {
                element.style.display = 'block';
                element.innerHTML = '3-20 alphanumeric characters, hyphens, and underscores allowed without spaces';
                this.setState({
                    showError: true
                })
            }
            else {
                element.style.display = 'none';
                this.setState({
                    showError: false
                })
            }
        }
    };

    checkPreSharedKey = (e) => {
        const element = document.getElementById(e?.target?.name);
        if (element) {
            if (
                e?.target?.value?.length < 8 ||
                e?.target?.value?.length > 64
            ) {
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
            else {
                element.style.display = 'none';
                this.setState({
                    showError: false
                })
            }
        }
    };

    checkCertificate = (e) => {
        if (this.state?.deviceA?.cert_id === "fqdn") {
            const regex = /^\@[a-zA-Z0-9]+\.[a-zA-Z0-9]+\.([a-zA-Z0-9]{2,5})+$/;
            const result = regex.test(e?.target?.value);
            if (!result) {
                const element = document.getElementById(e?.target?.name);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true
                    })
                }
            }
        }
        if (this.state?.deviceA?.cert_id === "ufqdn") {
            const regex = /^[a-zA-Z0-9]+\@[a-zA-Z0-9]+\.([a-zA-Z0-9]{2,5})+$/;
            const result = regex.test(e?.target?.value);
            if (!result) {
                const element = document.getElementById(e?.target?.name);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true
                    })
                }
            }
        }
        if (this.state?.deviceA?.cert_id === "dn") {
            const regex = /^([A-Z]+=([a-zA-Z]+))(,([A-Z]+=([a-zA-Z]+)))*$/;
            const result = regex.test(e?.target?.value);
            if (!result) {
                const element = document.getElementById(e.target?.name);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true
                    })
                }
            }
        }
    };

    saveData = () => {
        let data = {};
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model =
            this.props?.selectedModelList
                ? this.props?.selectedModelList?.toString()
                : this.state.device_model.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_order = -1;
        if (this.state.selectedTemplateId === "") {
            data.template_version = {
                chimay: this.state.chimay,
                cbr: this.state.cbr,
                mifi: this.state.mifi
            }
        }
        data.template_schema_json = {};
        data.device_mac = this.state.selectedDeviceData?.[0].device_mac;
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.createTemplate(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                this.props?.refreshList();
                this.props?.closeAddTemplate();
                this.props?.openApplyTemplate(response?.data?.id, response?.message);
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState(
                        {
                            title: "Create Template",
                            message: response?.message,
                            show: true,
                            error: true,
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        });
    };

    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
                if (!this.state.hide) {
                    this.props?.refreshList();
                    this.props?.closeAddTemplate();
                }
            }
        );
    };

    closeAll = () => {
        this.props?.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.props?.closeAddTemplate()
    };


    validate = (e) => {
        if (!Common.validateNumeric(e)) {
            const element = document.getElementById(e?.target?.name);
            if (element) {
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
        if (
            (
                e?.target?.name === 'keylife1' ||
                e?.target?.name === 'keylife2'
            ) && (
                (parseInt(e?.target?.value) < 3600) ||
                (parseInt(e?.target?.value) > 28800)
            )
        ) {
            const element = document.getElementById(e?.target?.name);
            if (element) {
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
        if (
            (e.target.name === 'dpd_delay') && (
                parseInt(e?.target?.value) < 2 ||
                parseInt(e?.target?.value) > 30
            )
        ) {
            const element = document.getElementById(e?.target?.name);
            if (element) {
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
        if (
            (e?.target?.name === 'dpd_timeout') && (
                (parseInt(e?.target?.value) < 10) ||
                (parseInt(e?.target?.value) > 3600)
            )
        ) {
            const element = document.getElementById(e?.target?.name);
            if (element) {
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
    };

    removeErrorIP = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true
            })
        }
    };

    checkError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;") {
                result = true;
            }
        }
        if (
            this.state.template_name === "" ||
            this.state.template_type === ""
        ) {
            result = true;
        }
        if (this.state.apply && this.props?.applying) {
            result = true;
        }
        if (this.state.deviceA?.local_ip === "" ||
            this.state.deviceA?.remote_ip === "" ||
            this.state.deviceA?.local_network?.length === 0 ||
            this.state.deviceA?.remote_network?.length === 0) {
            result = true;
        }
        if (this.state.deviceB?.device_id !== "") {
            if (this.state.deviceB?.local_ip === "" ||
                this.state.deviceB?.remote_ip === "" ||
                this.state.deviceB?.local_network?.length === 0 ||
                this.state.deviceB?.remote_network?.length === 0) {
                result = true;
            }
        }
        if (this.state.group?.group_id !== "") {
            if (
                this.state.group?.local_ip === "" ||
                this.state.group?.remote_ip === "" ||
                this.state.group?.local_network?.length === 0 ||
                this.state.group?.remote_network?.length === 0
            ) {
                result = true;
            }
        }
        if (this.state.VPNData?.authentication === 'psk' && this.state.VPNData?.pre_shared_key === '')
            result = true;
        if (this.state.VPNData?.authentication === 'pubkey-cert') {
            if
                (
                this.state.deviceA?.cert_file === '' ||
                this.state.deviceA?.cert_id === '' ||
                this.state.deviceA?.local_id === '' ||
                this.state.deviceA?.remote_id === ''
            ) {
                result = true;
            }
            if
                (
                (
                    this.state.group.group_id === "" &&
                    this.state.deviceB.device_id !== ""
                ) &&
                (
                    this.state.deviceB?.cert_file === '' ||
                    this.state.deviceB?.cert_id === '' ||
                    this.state.deviceB?.local_id === '' ||
                    this.state.deviceB?.remote_id === ''
                )
            ) {
                result = true;
            }
            if
                (
                this.state.group.group_id !== ""
                &&
                (
                    this.state.group?.cert_file === '' ||
                    this.state.group?.cert_id === '' ||
                    this.state.group?.local_id === '' ||
                    this.state.group?.remote_id === ''
                )
            ) {
                result = true;
            }
        }
        if (
            this.state.VPNData?.tunnel_name === '' ||
            this.state.VPNData?.keylife1 === '' ||
            this.state.VPNData?.keylife2 === '' ||
            this.state.VPNData?.hash_algo1 === '' ||
            this.state.VPNData?.hash_algo2 === '' ||
            this.state.VPNData?.enc_algo1 === '' ||
            this.state.VPNData?.enc_algo2 === '' ||
            this.state.VPNData?.dh_group1 === ''
        ) {
            result = true;
        }
        if (
            (this.state.VPNData?.dpd_enable === '1') &&
            (this.state.VPNData?.dpd_delay === '' ||
                this.state.VPNData?.dpd_timeout === '' ||
                this.state.VPNData?.dpd_action == '')
        ) {
            result = true;
        }
        return result;
    };

    checkSaveError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (
            this.state?.template_name === "" ||
            this.state?.template_type === ""
        ) {
            result = true;
        }
        if (this.state.selectedDeviceData?.length !== 1) {
            result = true;
        }
        return result;
    };

    applyTemplate = () => {
        let { template_schema_json, deviceA, deviceB, VPNData, group } = this.state;
        let template_schema_json_copy = { ...template_schema_json };
        let deviceA_copy = { ...deviceA };
        let deviceB_copy = { ...deviceB };
        let VPNData_copy = { ...VPNData };
        let group_copy = { ...group };
        template_schema_json_copy.vpn_enable = "1";
        delete deviceA_copy.device_status;
        template_schema_json_copy.vpnConfig.deviceA = deviceA_copy;
        if (deviceB_copy?.device_id === "" && group_copy?.group_id === "") {
            delete template_schema_json_copy.vpnConfig.deviceB;
            delete template_schema_json_copy.vpnConfig.group;
        }
        else if (deviceB_copy?.device_id !== "") {
            delete template_schema_json_copy.vpnConfig.group;
            delete deviceB_copy.device_status;
            template_schema_json_copy.vpnConfig.deviceB = deviceB_copy;
        }
        else {
            delete template_schema_json_copy.vpnConfig.deviceB;
            delete group_copy.group_status;
            template_schema_json_copy.vpnConfig.group = group_copy;
        }
        template_schema_json_copy.vpnConfig.vpn_data = VPNData_copy;
        this.props?.dispatch({
            type: Action.SET_APPLY_TEMPLATE,
        });
        this.props?.applyTemplate(template_schema_json_copy);
    };

    debounceApply = debounce((fn, event) => {
        fn(event)
    }, 500);

    handleChange = (e, name) => {
        let { VPNData } = this.state;
        let { availableEncryptions } = this.state;
        let { availableHash } = this.state;
        let { availableDHGroups } = this.state;
        let { availableEncryptions2 } = this.state;
        let { availableHash2 } = this.state;
        let { availableDHGroups2 } = this.state;
        let values = '';
        switch (name) {
            case 'enc_algo1':
                values = VPNData?.enc_algo1;
                break;
            case 'hash_algo1':
                values = VPNData?.hash_algo1;
                break;
            case 'dh_group1':
                values = VPNData?.dh_group1;
                break;
            case 'enc_algo2':
                values = VPNData?.enc_algo2;
                break;
            case 'hash_algo2':
                values = VPNData?.hash_algo2;
                break;
            case 'dh_group2':
                values = VPNData?.dh_group2;
                break;
        }
        let enc_values = [];
        if (values !== '') {
            if (values.includes('-')) {
                enc_values = values?.split('-');
            } else {
                enc_values?.push(values);
            }
        }
        const req_length = name?.includes('hash_algo') ? 2 : 3;
        if (e?.target?.checked) {
            if (enc_values?.filter(item => item === e?.target?.id)?.length === 0) {
                enc_values?.push(e.target?.id);
                if (enc_values?.length <= req_length) {
                    const element = document.getElementById(name);
                    if (element) {
                        element.style.display = "none"
                        this.setState({
                            showError: false
                        })
                    }
                } else {
                    const element = document.getElementById(name);
                    if (element) {
                        element.style.display = "block"
                        this.setState({
                            showError: true
                        })
                    }
                }
                switch (name) {
                    case 'enc_algo1':
                        availableEncryptions = availableEncryptions?.filter(item => item !== e?.target?.id);
                        break;
                    case 'hash_algo1':
                        availableHash = availableHash?.filter(item => item !== e?.target?.id);
                        break;
                    case 'dh_group1':
                        availableDHGroups = availableDHGroups?.filter(item => item !== e?.target?.id);
                        break;
                    case 'enc_algo2':
                        availableEncryptions2 = availableEncryptions2?.filter(item => item !== e?.target?.id);
                        break;
                    case 'hash_algo2':
                        availableHash2 = availableHash2?.filter(item => item !== e?.target?.id);
                        break;
                    case 'dh_group2':
                        availableDHGroups2 = availableDHGroups2?.filter(item => item !== e?.target?.id);
                        break;
                }
            } else {
                enc_values = enc_values?.filter(item => item !== e?.target?.id);
                switch (name) {
                    case 'enc_algo1':
                        availableEncryptions?.push(e?.target?.id);
                        break;
                    case 'hash_algo1':
                        availableHash?.push(e?.target?.id);
                        break;
                    case 'dh_group1':
                        availableDHGroups?.push(e?.target?.id);
                        break;
                    case 'enc_algo2':
                        availableEncryptions2?.push(e?.target?.id);
                        break;
                    case 'hash_algo2':
                        availableHash2?.push(e?.target?.id);
                        break;
                    case 'dh_group2':
                        availableDHGroups2?.push(e?.target?.id);
                        break;
                }
            }
        } else {
            enc_values = enc_values?.filter(item => item !== e?.target?.id);
            if (enc_values?.length <= req_length) {
                const element = document.getElementById(name);
                if (element) {
                    element.style.display = "none"
                    this.setState({
                        showError: false
                    })
                }
            }
            switch (name) {
                case 'enc_algo1':
                    availableEncryptions?.push(e?.target?.id);
                    break;
                case 'hash_algo1':
                    availableHash?.push(e?.target?.id);
                    break;
                case 'dh_group1':
                    availableDHGroups?.push(e?.target?.id);
                    break;
                case 'enc_algo2':
                    availableEncryptions2?.push(e?.target?.id);
                    break;
                case 'hash_algo2':
                    availableHash2?.push(e?.target?.id);
                    break;
                case 'dh_group2':
                    availableDHGroups2?.push(e?.target?.id);
                    break;
            }
        }
        VPNData[name] = enc_values?.join('-');
        this.setState({
            VPNData,
            availableEncryptions,
            availableDHGroups,
            availableHash,
            availableEncryptions2,
            availableHash2,
            availableDHGroups2
        })
    };

    showPassword = () => {
        const x = document.getElementById('password');
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    validateCustomIP = (e) => {
        if (this.state.subnet) {
            if (
                !Common.validateIPaddressWithSubnetMask(e) ||
                !e.target.value.includes('/') ||
                e.target.value.split('/')[1] < 8 ||
                e.target.value.split('/')[1] > 32
            ) {
                const element = document.getElementById(`${e.target.name}`);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Invalid IP address/subnet';
                    this.setState({
                        showError: true,
                        validSubnet: false
                    })
                }
            } else {
                this.setState({
                    validSubnet: true,
                    customSubnet: e.target.value
                })
            }
        } else {
            if (!Common.validateIPaddress(e)) {
                const element = document.getElementById(`${e.target.name}`);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Invalid IP address';
                    this.setState({
                        showError: true,
                        validIP: false,
                    })
                }
            }
            else if (e.target.value === '0.0.0.0' ||
                e.target.value === '255.255.255.255') {
                const element = document.getElementById(`${e.target.name}`);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'This is restricted IP address';
                    this.setState({
                        showError: true,
                        validIP: false,
                    })
                }
            }
            else {
                this.setState({
                    validIP: true,
                    customIPAddress: e.target.value
                })
            }
        }
    };

    addIP = () => {
        let {
            deviceA_RemoteIP_Options,
            deviceA_LocalSubnet_Options,
            availableDeviceALocalSubnetNetworks,
            availableDeviceARemoteIPNetworks,
            deviceB_RemoteIP_Options,
            deviceB_LocalSubnet_Options,
            availableDeviceBLocalSubnetNetworks,
            availableDeviceBRemoteIPNetworks,
            availableGroupRemoteIPNetworks,
            group_RemoteIP_Options
        } = this.state;
        if (this.state.subnet && this.state.validSubnet) {
            if (this.state.selectedCustom === "deviceA") {
                if (!availableDeviceALocalSubnetNetworks.includes(this.state.customSubnet)) {
                    availableDeviceALocalSubnetNetworks.push(this.state.customSubnet);
                    deviceA_LocalSubnet_Options.push({ label: this.state.customSubnet, value: this.state.customSubnet });
                }
            }
            if (this.state.selectedCustom === "deviceB") {
                if (!availableDeviceBLocalSubnetNetworks.includes(this.state.customSubnet)) {
                    availableDeviceBLocalSubnetNetworks.push(this.state.customSubnet);
                    deviceB_LocalSubnet_Options.push({ label: this.state.customSubnet, value: this.state.customSubnet });
                }
            }
        }
        if (this.state.remoteIP && this.state.validIP) {
            if (this.state.selectedCustom === "deviceA") {
                if (!availableDeviceARemoteIPNetworks.includes(this.state.customIPAddress)) {
                    availableDeviceARemoteIPNetworks.push(this.state.customIPAddress);
                    deviceA_RemoteIP_Options.push({ label: this.state.customIPAddress, value: this.state.customIPAddress });
                }
            }
            if (this.state.selectedCustom === "deviceB") {
                if (!availableDeviceBRemoteIPNetworks.includes(this.state.customIPAddress)) {
                    availableDeviceBRemoteIPNetworks.push(this.state.customIPAddress);
                    deviceB_RemoteIP_Options.push({ label: this.state.customIPAddress, value: this.state.customIPAddress });
                }
            }
            if (this.state.selectedCustom === "group") {
                if (!availableGroupRemoteIPNetworks.includes(this.state.customIPAddress)) {
                    availableGroupRemoteIPNetworks.push(this.state.customIPAddress);
                    group_RemoteIP_Options.push({ label: this.state.customIPAddress, value: this.state.customIPAddress });
                }
            }
        }
        this.setState({
            customIP: false,
            subnet: false,
            remoteIP: false,
            validIP: false,
            validSubnet: false,
            customIPAddress: '',
            customSubnet: '',
            deviceA_RemoteIP_Options,
            deviceA_LocalSubnet_Options,
            availableDeviceALocalSubnetNetworks,
            availableDeviceARemoteIPNetworks,
            deviceB_RemoteIP_Options,
            deviceB_LocalSubnet_Options,
            availableDeviceBLocalSubnetNetworks,
            availableDeviceBRemoteIPNetworks,
        }, () => {
            $("#errorMessage").modal("hide")
        })
    };

    changeIp = (name, value) => {
        if (name === 'customSubnet') {
            this.setState({ subnet: true })
        }
        if (name === 'remote_ip') {
            this.setState({ remoteIP: true })
        }
        this.setState({
            customIP: !this.state.customIP,
            selectedCustom: value
        })
    };

    cancelIP = () => {
        let element = document.getElementById('ipAddress');
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
        }
        this.setState({
            customIP: false,
            subnet: false
        })

    };

    validateDeviceA = () => {
        if (this.state.group.group_id === "") {
            const element = document.getElementById(`deviceA_remote_network_error`);
            const element2 = document.getElementById(`deviceA_remote_IP_error`);
            if (element) {
                element.style.display = 'none';
                element.innerHTML = "";
                this.setState({
                    showError: false
                })
                if (
                    this.state.deviceA.remote_ip !== 'auto' &&
                    this.state.deviceA.remote_ip !== '' &&
                    this.state.deviceA.remote_network.length !== 0
                ) {
                    this.state.deviceA.remote_network.map((item) => {
                        const block = new Netmask(item);
                        if (block.contains(this.state.deviceA.remote_ip) === true) {
                            element.style.display = 'block';
                            element.innerHTML = "Remote IP address should not be in the same range as remote subnets!";
                            this.setState({
                                showError: true
                            })
                        }
                    })
                }
                if (
                    this.state.deviceA.local_network.length !== 0 &&
                    this.state.deviceA.remote_network.length !== 0
                ) {
                    this.state.deviceA.local_network.map((item) => {
                        this.state.deviceA.remote_network.map((val) => {
                            const block = new Netmask(val);
                            if (block.contains(item.split('/')[0]) === true) {
                                element.style.display = 'block';
                                element.innerHTML = "Remote subnets should not be in the same range as local subnets!";
                                this.setState({
                                    showError: true
                                })
                            }
                        })
                    })
                }
            }
            if (element2) {
                element2.style.display = 'none';
                element2.innerHTML = "";
                this.setState({
                    showError: false
                })
                if (
                    this.state.deviceA.local_ip !== "auto" && this.state.deviceA.remote_ip !== "auto" &&
                    this.state.deviceA.local_ip === this.state.deviceA.remote_ip
                ) {
                    element2.style.display = 'block';
                    element2.innerHTML = "Both the Local IP and Remote IP cannot be the same.";
                    this.setState({
                        showError: true
                    })
                }
                if (this.state.deviceA.remote_ip === 'auto' &&
                    this.state.deviceB.remote_ip === 'auto') {
                    element2.style.display = 'block';
                    element2.innerHTML = "Remote IP address of both devices should not be auto!";
                    this.setState({
                        showError: true
                    })
                }
            }
        }
    };

    validateDeviceB = () => {
        const element = document.getElementById(`deviceB_remote_network_error`);
        const element2 = document.getElementById(`deviceA_remote_IP_error`);
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
            if (
                this.state.deviceB.remote_ip !== 'auto' &&
                this.state.deviceB.remote_ip !== '' &&
                this.state.deviceB.remote_network.length !== 0
            ) {
                this.state.deviceB.remote_network.map((item) => {
                    const block = new Netmask(item);
                    if (block.contains(this.state.deviceB.remote_ip) === true) {
                        element.style.display = 'block';
                        element.innerHTML = "Remote IP address should not be in the same range as remote subnets!";
                        this.setState({
                            showError: true
                        })
                    }
                })
            }
            if (
                this.state.deviceB.local_network.length !== 0 &&
                this.state.deviceB.remote_network.length !== 0
            ) {
                this.state.deviceB.local_network.map((item) => {
                    this.state.deviceB.remote_network.map((val) => {
                        const block = new Netmask(val);
                        if (block.contains(item.split('/')[0]) === true) {
                            element.style.display = 'block';
                            element.innerHTML = "Remote subnets should not be in the same range as local subnets!";
                            this.setState({
                                showError: true
                            })
                        }
                    })
                })
            }
        }
        if (element2) {
            element2.style.display = 'none';
            element2.innerHTML = "";
            this.setState({
                showError: false
            })
            if (
                this.state.deviceB.local_ip !== "auto" && this.state.deviceB.remote_ip !== "auto" &&
                this.state.deviceB.local_ip === this.state.deviceB.remote_ip
            ) {
                element2.style.display = 'block';
                element2.innerHTML = "Both the Local IP and Remote IP cannot be the same.";
                this.setState({
                    showError: true
                })
            }
            if (this.state.deviceA.remote_ip === 'auto' &&
                this.state.deviceB.remote_ip === 'auto') {
                element2.style.display = 'block';
                element2.innerHTML = "Remote IP address of both devices should not be auto!";
                this.setState({
                    showError: true
                })
            }
        }
    };

    validateGroup = () => {
        const element = document.getElementById(`group_remote_network_error`);
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
            if (
                this.state.group.remote_ip !== 'auto' &&
                this.state.group.remote_ip !== '' &&
                this.state.group.remote_network.length !== 0
            ) {
                this.state.group.remote_network.map((item) => {
                    const block = new Netmask(item);
                    if (block.contains(this.state.group.remote_ip) === true) {
                        element.style.display = 'block';
                        element.innerHTML = "Remote IP address should not be in the same range as remote subnets!";
                        this.setState({
                            showError: true
                        })
                    }
                })
            }
            else {
                element.style.display = 'none';
                element.innerHTML = "";
                this.setState({
                    showError: false
                })
            }
        }
    }

    validateIPaddressWithSubnetMask = (e) => {
        if (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-9]|0[0-9]|1[0-9]|2[0-9]|3[0-2])$/.test(e))
            return false;
        return true;
    };

    deviceARemoteSubnetOptions = (e, key) => {
        if (e.key === 'Enter' || key === "blur") {
            let value = e.target.value.trim();
            const element = document.getElementById(`deviceA_remote_network_error`);
            if (element) {
                if (value.trim() !== "") {
                    if (this.validateIPaddressWithSubnetMask(value) || !value.includes('/')
                        || value.split('/')[1] < 8 || value.split('/')[1] > 32) {
                        element.style.display = 'block';
                        element.innerHTML = 'Invalid subnet';
                        this.setState({
                            showError: true,
                        })
                    }
                    else if (this.state.deviceA.remote_network.filter(val => val === value).length !== 0) {
                        element.style.display = 'block';
                        element.innerHTML = 'Entered subnet already added!';
                        this.setState({
                            showError: true,
                        })
                    }
                    else {
                        element.style.display = 'none';
                        element.innerHTML = "";
                        this.setState({
                            showError: false
                        })
                        this.setState(prevState => ({
                            deviceA: {
                                ...prevState.deviceA,
                                remote_network: [...prevState.deviceA.remote_network, value]
                            }
                        }), () => {
                            this.validateDeviceA();
                        });
                        $("textarea[name=deviceA_remote_network]").val("");
                    }
                }
                else {
                    element.style.display = 'none';
                    element.innerHTML = "";
                    this.setState({
                        showError: false
                    }, () => {
                        this.validateDeviceA();
                    })
                }
            }
        }
    };

    deviceBRemoteSubnetOptions = (e, key) => {
        if (e.key === 'Enter' || key === "blur") {
            let value = e.target.value.trim();
            const element = document.getElementById(`deviceB_remote_network_error`);
            if (element) {
                if (value.trim() !== "") {
                    if (this.validateIPaddressWithSubnetMask(value) || !value.includes('/')
                        || value.split('/')[1] < 8 || value.split('/')[1] > 32) {
                        element.style.display = 'block';
                        element.innerHTML = 'Invalid subnet';
                        this.setState({
                            showError: true,
                        })
                    }
                    else if (this.state.deviceB.remote_network.filter(val => val === value).length !== 0) {
                        element.style.display = 'block';
                        element.innerHTML = 'Entered subnet already added!';
                        this.setState({
                            showError: true,
                        })
                    }
                    else {
                        element.style.display = 'none';
                        element.innerHTML = "";
                        this.setState({
                            showError: false
                        })
                        this.setState(prevState => ({
                            deviceB: {
                                ...prevState.deviceB,
                                remote_network: [...prevState.deviceB.remote_network, value]
                            }
                        }), () => {
                            this.validateDeviceB();
                        });
                        $("textarea[name=deviceB_remote_network]").val("");
                    }
                }
                else {
                    element.style.display = 'none';
                    element.innerHTML = "";
                    this.setState({
                        showError: false
                    }, () => {
                        this.validateDeviceB();
                    })
                }
            }
        }
    };

    groupRemoteSubnetOptions = (e, key) => {
        if (e.key === 'Enter' || key === "blur") {
            let value = e.target.value.trim();
            const element = document.getElementById(`group_remote_network_error`);
            if (element) {
                if (value.trim() !== "") {
                    if (this.validateIPaddressWithSubnetMask(value) || !value.includes('/')
                        || value.split('/')[1] < 8 || value.split('/')[1] > 32) {
                        element.style.display = 'block';
                        element.innerHTML = 'Invalid subnet';
                        this.setState({
                            showError: true,
                        })
                    }
                    else if (this.state.group.remote_network.filter(val => val === value).length !== 0) {
                        element.style.display = 'block';
                        element.innerHTML = 'Entered subnet already added!';
                        this.setState({
                            showError: true,
                        })
                    }
                    else {
                        element.style.display = 'none';
                        element.innerHTML = '';
                        this.setState({
                            showError: false
                        })
                        this.setState(prevState => ({
                            group: {
                                ...prevState.group,
                                remote_network: [...prevState.group.remote_network, value]
                            }
                        }), () => {
                            this.validateGroup();
                        });
                        $("textarea[name=group_remote_network]").val("");
                    }
                }
                else {
                    element.style.display = 'none';
                    element.innerHTML = "";
                    this.setState({
                        showError: false
                    }, () => {
                        this.validateGroup();
                    })
                }
            }
        }
    };

    deviceAdd = () => {
        if (this.state.multipleSelect.length === 1) {
            this.setState({
                exactModel: this.state.multipleSelect[0].device_model,
                selectedDeviceData: this.state.multipleSelect,
                multipleSelect: "",
                deviceList: [],
                hide: false,
                toggledClearDeviceRows: !this.state.toggledClearDeviceRows,
            }, () => this.closeDeviceTemplate())
        } else {
            this.setState({
                title: 'Create Template',
                message: 'You can add only one device',
                show: true,
                error: true,
                hide: true
            }, () => $("#errorMessage").modal("show"));
        }
    };

    handleMultipleSelect = (rows) => {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    };

    isSubstring = (s1) => {
        this.setState({ searchVal: s1 })
    };

    closeDeviceTemplate = () => {
        this.setState({
            searchVal: "",
            openDeviceSlide: false,
            deviceList: [],
            multipleSelect: "",
            hide: false,
            toggledClearDeviceRows: !this.state.toggledClearDeviceRows
        });
    };

    getDeviceByModel = () => {
        let data = { device_model: this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString() };
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.getDeviceLookUp(data), (response) => {
            this.setState({
                showLoader: false
            });
            if (response?.results) {
                let onlineDevices = response?.results
                this.setState({
                    deviceList: onlineDevices,
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };

    deleteSelectedDevice = () => {
        let newList = [...this.state.selectedDeviceData];
        if (this.state.multipleSelect.length > 0) {
            this.state.multipleSelect.map((item) => {
                newList?.splice(newList?.indexOf(item), 1);
            });
        }
        this.setState({
            selectedDeviceData: newList,
            toggledClearSelectedDeviceRows: !this.state.toggledClearSelectedDeviceRows,
            multipleSelect: "",
            adding: false
        });
    };

    render() {
        return (
            <div className="tab-box firewall">
                {this.state.showLoader && <Loader />}
                {
                    (!this.state.apply && !this.state.info && !this.state.view)
                    && <>
                        <div className="page-sub-title">
                            <div>
                                <strong>Device</strong>
                                <img
                                    src="/images/svg-icons/add.svg"
                                    className={
                                        (this.state.selectedDeviceData?.length === 1)
                                            ? "add-btn ml-2 disabled"
                                            : "add-btn ml-2"
                                    }
                                    onClick={() =>
                                        (this.state.selectedDeviceData?.length === 1)
                                            ? undefined
                                            : this.setState({
                                                openDeviceSlide: true
                                            }, () => this.getDeviceByModel())
                                    }
                                    alt="" />
                            </div>
                            <div className="device-action">
                                <span className="text-muted">
                                    {this.state.selectedDeviceData?.length} devices added
                                </span>
                                <span
                                    className={
                                        (this.state.selectedDeviceData?.length > 0 && this.state.selectedDeviceData?.length === 1)
                                            ? "icon-box"
                                            : "icon-box disabled"
                                    }>
                                    <img
                                        src="/images/svg-icons/delete.svg"
                                        alt=""
                                        onClick={(e) => this.deleteSelectedDevice(e)}
                                    />
                                </span>
                            </div>
                        </div>
                        {
                            this.state.selectedDeviceData?.length > 0 &&
                            <>
                                <div className="device-table">
                                    <DataTable
                                        columns={this.state.deviceListColumnsDiff}
                                        data={this.state.selectedDeviceData}
                                        selectableRows
                                        highlightOnHover
                                        fixedHeader
                                        onSelectedRowsChange={(data) => this.handleMultipleSelect(data)}
                                        clearSelectedRows={this.state.toggledClearSelectedDeviceRows}
                                    />
                                </div>
                            </>
                        }
                        <div className={
                            (this.state?.openDeviceSlide)
                                ? "disabled-slide"
                                : ""
                        }>
                        </div>
                        <div
                            className={
                                this.state.openDeviceSlide
                                    ? "sidebar-slide zindex10 w-350 second-open r-757"
                                    : "sidebar-slide zindex10 w-350"
                            }
                        >
                            <div className="s-header">
                                <h1 className="ss-title">Devices</h1>
                                <button
                                    onClick={() => this.closeDeviceTemplate()}
                                >
                                    <img src="/images/svg-icons/s-cancel.svg" alt="" />
                                </button>
                            </div>
                            <div className="s-body">
                                <div className="filter-box d-flex align-items-center">
                                    <div className="search-box2 mr-2">
                                        <input
                                            type="text"
                                            placeholder="Search.."
                                            value={this.state.searchVal}
                                            onChange={(e) => this.isSubstring((e.target.value.toLowerCase()))}
                                        />
                                        <img src="/images/svg-icons/search.svg" alt="" />
                                    </div>
                                </div>
                                <div className="device-table">
                                    <DataTable
                                        columns={this.state.deviceListColumns}
                                        data={this.state.deviceList.filter(item => item.device_name.toLowerCase().includes(this.state.searchVal))}
                                        selectableRows
                                        highlightOnHover
                                        fixedHeader
                                        selectableRowDisabled={(row) => (row.group_name !== "" && row.group_name !== null)}
                                        onSelectedRowsChange={(data) => this.handleMultipleSelect(data)}
                                        clearSelectedRows={this.state.toggledClearDeviceRows}
                                    />
                                </div>
                            </div>
                            <div className="s-footer">
                                <button
                                    className="btn btn-light"
                                    onClick={() => this.closeDeviceTemplate()}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary"
                                    disabled={this.state.multipleSelect === ''}
                                    onClick={() => {
                                        this.deviceAdd();
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </>
                }
                {
                    (!this.state.apply && this.state.info)
                    &&
                    <>
                        {
                            this.state.selectedDeviceData?.length > 0 &&
                            <>
                                <div>
                                    <DataTable
                                        columns={this.state.deviceListColumnsDiff}
                                        data={this.state.selectedDeviceData}
                                    />
                                </div>
                            </>
                        }
                    </>
                }
                {
                    (this.state.apply ||
                        this.state.reapply)
                    && <div>
                        <div>
                            {
                                <>
                                    <div className="graybg-title d-flex align-items-center justify-content-between mt-3">
                                        <div>General Settings</div>
                                    </div>
                                    <div>
                                        <div class="row form-group">
                                            <div className='mt-1 col-md-3'>
                                                <label>Start Tunnel
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Mode of tunnel establishment"
                                                    >
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='col-md-3'>
                                                <select
                                                    className="form-control"
                                                    name=""
                                                    disabled
                                                    value={this.state.VPNData?.start_tunnel}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1">Automatically</option>
                                                    <option value="0">Manually</option>
                                                </select>
                                            </div>
                                            <div className='mt-1 col-md-3'>
                                                <label>Enable Tunnel
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Start the IPSec tunnel by default">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                            </div>
                                            <div
                                                className='col-md-3'
                                                style={{ "padding-top": "7px" }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={String(this.state.VPNData?.enable_tunnel) === "1"}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div className='mt-1 col-md-3'>
                                                <label>Tunnel Name</label>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                                <div
                                                    className="tooltip-c"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="Name of the Tunnel"
                                                >
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <input
                                                    type="name"
                                                    class="form-control"
                                                    name="tunnel_name"
                                                    disabled={this.state.view}
                                                    value={this.state.VPNData?.tunnel_name}
                                                    onChange={this.setTunnelValues}
                                                    onBlur={(e) => this.checkTunnelName(e)}
                                                    title={this.state.VPNData?.tunnel_name}
                                                />
                                                <div className="col-md-12 error-txt" id="tunnel_name">3-20 Alphanumeric charaters allowed without space</div>
                                            </div>
                                            <div className='mt-1 col-md-3'>
                                                <label>Authentication
                                                    {!this.state.view && <span className="red-txt">*</span>}
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Local authentication method for creating a tunnel"
                                                    >
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='col-md-3'>
                                                <select
                                                    className="form-control"
                                                    name="authentication"
                                                    disabled={this.state.view}
                                                    onChange={this.setTunnelValues}
                                                    value={this.state.VPNData?.authentication}
                                                >
                                                    <option value="psk">Preshared key</option>
                                                    <option value="pubkey-cert">Certificate</option>
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            (this.state.VPNData?.authentication === "psk")
                                            &&
                                            <div class="row form-group">
                                                <div className='mt-1 col-md-3'>
                                                    <label>Pre shared key</label>
                                                    {!this.state.view && <span className="red-txt">*</span>}
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Password for the preshared key authentication"
                                                    >
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <input
                                                        type="password"
                                                        class="form-control showIconSpacing"
                                                        name="pre_shared_key"
                                                        id="password"
                                                        disabled={this.state.view}
                                                        value={this.state.VPNData?.pre_shared_key}
                                                        onChange={this.setTunnelValues}
                                                        onBlur={(e) => this.checkPreSharedKey(e)}
                                                    />
                                                    <div className='sh-icon-smtp mt-1'>
                                                        <img
                                                            src={
                                                                this.state.showPassword
                                                                    ? "/images/show.png"
                                                                    : "/images/hide.png"
                                                            }
                                                            alt=""
                                                            onClick={() => this.showPassword()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 error-txt" id="pre_shared_key">Value must be in range between 8 and 64</div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {
                                <>
                                    <div className="graybg-title d-flex align-items-center justify-content-between mt-3">
                                        <div>Device A</div>
                                    </div>
                                    <div>
                                        <div className="row ">
                                            <div className="col-md-5">
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="device-icon">
                                                        <img src="/images/svg-icons/wifi-router.svg" alt="" />
                                                        <div
                                                            className={
                                                                (String(this.state?.deviceA?.device_status) === "1")
                                                                    ? "status online"
                                                                    : "status offline"
                                                            }
                                                        ></div>
                                                    </div>
                                                    <div className="d-title flex-grow-1 ml-3" title={this.state.deviceA?.device_name}>
                                                        <strong>{this.state.deviceA?.device_name}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="mt-1 col-md-3">
                                                <span>Local IP:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    isClearable
                                                    isSearchable
                                                    className="custom-dropdown w-155 h-27"
                                                    classNamePrefix="react-select-container"
                                                    name="deviceA_customip"
                                                    isDisabled={this.state.view || this.state.group.group_id !== ""}
                                                    options={this.state.deviceA_LocalIP_Options}
                                                    value={
                                                        {
                                                            label: this.state.deviceA?.local_ip === 'auto'
                                                                ? 'Auto'
                                                                : this.state.deviceA?.local_ip,
                                                            value: this.state.deviceA?.local_ip
                                                        }
                                                    }
                                                    onChange={(e) =>
                                                        this.setState(prevState => ({
                                                            deviceA: {
                                                                ...prevState?.deviceA,
                                                                local_ip: e?.value
                                                            }
                                                        }), () => this.validateDeviceA())}
                                                />
                                            </div>
                                            <div className="mt-1 col-md-3">
                                                <span>Remote IP:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    isClearable
                                                    components={
                                                        {
                                                            MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP', 'remote_ip', 'deviceA')
                                                        }
                                                    }
                                                    isSearchable
                                                    className="custom-dropdown w-155 h-27"
                                                    classNamePrefix="react-select-container"
                                                    name="deviceA_remote_ip"
                                                    isDisabled={this.state.view || this.state.group.group_id !== ""}
                                                    options={this.state.deviceA_RemoteIP_Options}
                                                    value={
                                                        {
                                                            label: this.state.deviceA?.remote_ip === 'auto'
                                                                ? 'Auto'
                                                                : this.state.deviceA?.remote_ip,
                                                            value: this.state.deviceA?.remote_ip
                                                        }
                                                    }
                                                    onChange={(e) =>
                                                        this.setState(prevState => ({
                                                            deviceA: {
                                                                ...prevState?.deviceA,
                                                                remote_ip: e?.value
                                                            }
                                                        }), () => this.validateDeviceA())} />
                                                <div className="error-txt" id={`deviceA_remote_IP_error`} >Invalid IP</div>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="mt-1 col-md-3">
                                                <span>Local Subnet:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    components={
                                                        {
                                                            MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP', 'customSubnet', 'deviceA')
                                                        }
                                                    }
                                                    isSearchable
                                                    className={
                                                        this.state.view
                                                            ? "localSubnet custom-dropdown w-155 h-27 disabled"
                                                            : "localSubnet custom-dropdown w-155 h-27"
                                                    }
                                                    classNamePrefix="react-select-container"
                                                    name="deviceA_customSubnet"
                                                    isMulti
                                                    isDisabled={this.state.view}
                                                    options={this.state.deviceA_LocalSubnet_Options}
                                                    value={
                                                        (this.state.deviceA?.local_network).map((val) => {
                                                            return { label: val, value: val }
                                                        })
                                                    }
                                                    onChange={(e) => {
                                                        const arr = e.map(val => val?.value);
                                                        this.setState(prevState => ({
                                                            deviceA: {
                                                                ...prevState?.deviceA,
                                                                local_network: arr
                                                            }
                                                        }), () => this.validateDeviceA());
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-1 col-md-3">
                                                <span>Remote Subnet:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <div className="w-155">
                                                    <div className={(this.state.view || this.state.group.group_id !== "")
                                                        ? "chip-list-border cp disabled"
                                                        : "chip-list-border cp"}>
                                                        {this.state.deviceA?.remote_network?.map(i => <div className="chip-set remoteNetwork "><div
                                                            className="remoteNetworklist"
                                                            id={i}
                                                            title={i}>
                                                            {i}

                                                        </div>
                                                            <div className="remoteNetworkImage">
                                                                <img
                                                                    src="/images/svg-icons/cancel.svg"
                                                                    id={i}
                                                                    alt=""
                                                                    style={{ cursor: (this.state.view || this.state.group.group_id !== "") && "not-allowed" }}
                                                                    onClick={() => (this.state.view || this.state.group.group_id !== "")
                                                                        ? undefined
                                                                        : this.setState(prevState => ({
                                                                            deviceA: {
                                                                                ...prevState?.deviceA,
                                                                                remote_network: prevState?.deviceA?.remote_network?.filter(val => val !== String(i))
                                                                            }
                                                                        }), () => this.validateDeviceA())}
                                                                />
                                                            </div>
                                                        </div>)}
                                                        <textarea
                                                            name="deviceA_remote_network"
                                                            type="text"
                                                            disabled={this.state.view || this.state.group.group_id !== ""}
                                                            onKeyDown={(e) => this.deviceARemoteSubnetOptions(e)}
                                                            onBlur={(e) => this.deviceARemoteSubnetOptions(e, "blur")}
                                                            className="w-115"
                                                        />
                                                    </div>
                                                    <div className="error-txt" id={`deviceA_remote_network_error`} >Invalid subnet</div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (this.state.VPNData?.authentication === "pubkey-cert")
                                            &&
                                            <div>
                                                <div class="row form-group">
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Certificate File</label>
                                                        {!this.state.view && <span className="red-txt">*</span>}
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Upload the openSSL Certificate for authentication"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            ref="fileInputDeviceA"
                                                            accept={".tar"}
                                                            onChange={(e) => this.state.view ? undefined : this.handleFileUpload("deviceA", e)}
                                                            type="file"
                                                            style={{ display: "none" }}
                                                        />
                                                        <button
                                                            className="btn btn-primary"
                                                            style={{ cursor: this.state.view && "not-allowed" }}
                                                            onClick={() => this.refs.fileInputDeviceA.click()}
                                                            disabled={this.state.view}
                                                        >Browse</button>
                                                        {
                                                            (this.state.deviceA.cert_file !== "")
                                                            &&
                                                            <div className="droped-files">
                                                                <img className="csv" src="/images/svg-icons/tar-file.png" alt="" />
                                                                <span className="ipSecFile">{this.state.deviceA?.cert_file}</span>
                                                                {!this.state.view && <img
                                                                    className={this.state.view ? "delete-icon disabled" : "delete-icon"}
                                                                    src="/images/svg-icons/delete-red.svg"
                                                                    alt=''
                                                                    style={{ cursor: this.state.view && "not-allowed" }}
                                                                    onClick={() => this.state.view ? undefined : this.handleFileUpload("deviceA")}
                                                                />}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Certificate ID Type</label>
                                                        {!this.state.view && <span className="red-txt">*</span>}
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Identity to use for the certificate authentication of the local device"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <select
                                                            className="form-control"
                                                            name="cert_id"
                                                            disabled={this.state.view}
                                                            onChange={(e) => this.setCertId(e)}
                                                            value={this.state.deviceA?.cert_id}
                                                        >
                                                            <option value="fqdn">{"Fully Qualified Domain Name(FQDN)"}</option>
                                                            <option value="ufqdn">{"User FQDN"}</option>
                                                            <option value="dn">{"Distinguished Name (DN)"}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Local ID</label>
                                                        {!this.state.view && <span className="red-txt">*</span>}
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Identity to use for the certificate authentication of the local device"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            type="name"
                                                            class="form-control"
                                                            name="local_id_deviceA"
                                                            value={this.state.deviceA?.local_id}
                                                            onChange={(e) => {
                                                                const value = e.target.value || "";
                                                                const element = document.getElementById("local_id_deviceA");
                                                                element.style.display = 'none';
                                                                this.setState(prevState => ({
                                                                    deviceA: {
                                                                        ...prevState.deviceA,
                                                                        local_id: value
                                                                    },
                                                                    deviceB: {
                                                                        ...prevState.deviceB,
                                                                        remote_id: value
                                                                    },
                                                                    group: {
                                                                        ...prevState.group,
                                                                        remote_id: value
                                                                    },
                                                                    showError: false
                                                                }));
                                                            }}
                                                            disabled={this.state.view}
                                                            onBlur={(e) => this.checkCertificate(e)}
                                                            title={this.state.deviceA?.local_id}
                                                        />
                                                        <div className="error-txt deviceA" id="local_id_deviceA">Invalid value</div>
                                                    </div>
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Remote ID</label>
                                                        {!this.state.view && <span className="red-txt">*</span>}
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Identity to use for the certificate authentication of the Remote device"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            type="name"
                                                            class="form-control"
                                                            name="remote_id_deviceA"
                                                            value={this.state.deviceA?.remote_id}
                                                            onChange={(e) => {
                                                                const value = e.target.value || "";
                                                                const element = document.getElementById("remote_id_deviceA");
                                                                element.style.display = 'none';
                                                                this.setState(prevState => ({
                                                                    deviceA: {
                                                                        ...prevState.deviceA,
                                                                        remote_id: value
                                                                    },
                                                                    deviceB: {
                                                                        ...prevState.deviceB,
                                                                        local_id: value
                                                                    },
                                                                    group: {
                                                                        ...prevState.group,
                                                                        local_id: value
                                                                    },
                                                                    showError: false
                                                                }));
                                                            }}
                                                            disabled={this.state.view}
                                                            onBlur={(e) => this.checkCertificate(e)}
                                                            title={this.state.deviceA?.remote_id}
                                                        />
                                                        <div className="error-txt deviceA" id="remote_id_deviceA">Invalid value</div>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {
                                ((this.state.group.group_id === "") && (this.state.deviceB.device_id !== ""))
                                &&
                                <>
                                    <div className="graybg-title d-flex align-items-center justify-content-between mt-3">
                                        <div>Device B</div>
                                    </div>
                                    <div>
                                        <div className="row ">
                                            <div className="col-md-5">
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="device-icon">
                                                        <img src="/images/svg-icons/wifi-router.svg" alt="" />
                                                        <div
                                                            className={
                                                                (String(this.state?.deviceB?.device_status) === "1")
                                                                    ? "status online"
                                                                    : "status offline"
                                                            }
                                                        ></div>
                                                    </div>
                                                    <div className="d-title flex-grow-1 ml-3" title={this.state.deviceB?.device_name}>
                                                        <strong>{this.state.deviceB?.device_name}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="mt-1 col-md-3">
                                                <span>Local IP:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    isClearable
                                                    isSearchable
                                                    className="custom-dropdown w-155 h-27"
                                                    classNamePrefix="react-select-container"
                                                    name="deviceA_customip"
                                                    isDisabled={this.state.view}
                                                    options={this.state.deviceB_LocalIP_Options}
                                                    value={
                                                        {
                                                            label: this.state.deviceB?.local_ip === 'auto'
                                                                ? 'Auto'
                                                                : this.state.deviceB?.local_ip,
                                                            value: this.state.deviceB?.local_ip
                                                        }
                                                    }
                                                    onChange={(e) =>
                                                        this.setState(prevState => ({
                                                            deviceB: {
                                                                ...prevState?.deviceB,
                                                                local_ip: e?.value
                                                            }
                                                        }), () => this.validateDeviceB())}
                                                />
                                            </div>
                                            <div className="mt-1 col-md-3">
                                                <span>Remote IP:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    isClearable
                                                    components={
                                                        {
                                                            MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP', 'remote_ip', 'deviceB')
                                                        }
                                                    }
                                                    isSearchable
                                                    className="custom-dropdown w-155 h-27"
                                                    classNamePrefix="react-select-container"
                                                    name="deviceA_remote_ip"
                                                    isDisabled={this.state.view}
                                                    options={this.state.deviceB_RemoteIP_Options}
                                                    value={
                                                        {
                                                            label: this.state.deviceB?.remote_ip === 'auto'
                                                                ? 'Auto'
                                                                : this.state.deviceB?.remote_ip,
                                                            value: this.state.deviceB?.remote_ip
                                                        }
                                                    }
                                                    onChange={(e) =>
                                                        this.setState(prevState => ({
                                                            deviceB: {
                                                                ...prevState?.deviceB,
                                                                remote_ip: e?.value
                                                            }
                                                        }), () => this.validateDeviceB())} />
                                                <div className="error-txt" id={`deviceB_remote_IP_error`} >Invalid IP</div>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="mt-1 col-md-3">
                                                <span>Local Subnet:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    components={
                                                        {
                                                            MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP', 'customSubnet', 'deviceB')
                                                        }
                                                    }
                                                    isSearchable
                                                    className={
                                                        this.state.view
                                                            ? "localSubnet custom-dropdown w-155 h-27 disabled"
                                                            : "localSubnet custom-dropdown w-155 h-27"
                                                    }
                                                    classNamePrefix="react-select-container"
                                                    name="deviceA_customSubnet"
                                                    isMulti
                                                    isDisabled={this.state.view}
                                                    options={this.state.deviceB_LocalSubnet_Options}
                                                    value={
                                                        (this.state.deviceB?.local_network).map((val) => {
                                                            return { label: val, value: val }
                                                        })
                                                    }
                                                    onChange={(e) => {
                                                        const arr = e.map(val => val?.value);
                                                        this.setState(prevState => ({
                                                            deviceB: {
                                                                ...prevState?.deviceB,
                                                                local_network: arr
                                                            }
                                                        }), () => this.validateDeviceB());
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-1 col-md-3">
                                                <span>Remote Subnet:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <div className="w-155">
                                                    <div className={this.state.view
                                                        ? "chip-list-border cp disabled"
                                                        : "chip-list-border cp"}>
                                                        {this.state.deviceB?.remote_network?.map(i => <div className="chip-set remoteNetwork ">
                                                            <div
                                                                className="remoteNetworklist"
                                                                id={i}
                                                                title={i}>
                                                                {i}

                                                            </div>
                                                            <div className="remoteNetworkImage">
                                                                <img
                                                                    src="/images/svg-icons/cancel.svg"
                                                                    id={i}
                                                                    alt=""
                                                                    style={{ cursor: this.state.view && "not-allowed" }}
                                                                    onClick={() => (this.state.view)
                                                                        ? undefined
                                                                        : this.setState(prevState => ({
                                                                            deviceB: {
                                                                                ...prevState?.deviceB,
                                                                                remote_network: prevState?.deviceB?.remote_network?.filter(val => val !== String(i))
                                                                            }
                                                                        }), () => this.validateDeviceB())}
                                                                />
                                                            </div>
                                                        </div>)}
                                                        <textarea
                                                            name="deviceB_remote_network"
                                                            type="text"
                                                            disabled={this.state.view}
                                                            onKeyDown={(e) => this.deviceBRemoteSubnetOptions(e)}
                                                            onBlur={(e) => this.deviceBRemoteSubnetOptions(e, "blur")}
                                                            className="w-115"
                                                        />
                                                    </div>
                                                    <div className="error-txt" id={`deviceB_remote_network_error`} >Invalid subnet</div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (this.state.VPNData?.authentication === "pubkey-cert")
                                            &&
                                            <div>
                                                <div class="row form-group">
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Certificate File</label>
                                                        {!this.state.view && <span className="red-txt">*</span>}
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Upload the openSSL Certificate for authentication"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            ref="fileInputDeviceB"
                                                            accept={".tar"}
                                                            onChange={(e) => this.state.view ? undefined : this.handleFileUpload("deviceB", e)}
                                                            type="file"
                                                            style={{ display: "none" }}
                                                        />
                                                        <button
                                                            className="btn btn-primary"
                                                            style={{ cursor: this.state.view && "not-allowed" }}
                                                            onClick={() => this.refs.fileInputDeviceB.click()}
                                                            disabled={this.state.view}
                                                        >Browse</button>
                                                        {
                                                            (this.state.deviceB?.cert_file !== "")
                                                            &&
                                                            <div className="droped-files">
                                                                <img className="csv" src="/images/svg-icons/tar-file.png" alt="" />
                                                                <span className="ipSecFile">{this.state.deviceB?.cert_file}</span>
                                                                {!this.state.view && <img
                                                                    className={this.state.view ? "delete-icon disabled" : "delete-icon"}
                                                                    src="/images/svg-icons/delete-red.svg"
                                                                    alt=''
                                                                    style={{ cursor: this.state.view && "not-allowed" }}
                                                                    onClick={() => this.state.view ? undefined : this.handleFileUpload("deviceB")}
                                                                />}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Certificate ID Type</label>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Identity to use for the certificate authentication of the local device"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <select
                                                            className="form-control"
                                                            name="cert_id"
                                                            disabled={true}
                                                            style={{ cursor: "not-allowed" }}
                                                            value={this.state.deviceB?.cert_id}
                                                        >
                                                            <option value="fqdn">{"Fully Qualified Domain Name(FQDN)"}</option>
                                                            <option value="ufqdn">{"User FQDN"}</option>
                                                            <option value="dn">{"Distinguished Name (DN)"}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Local ID</label>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Identity to use for the certificate authentication of the local device"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            type="name"
                                                            class="form-control"
                                                            name="local_id_deviceB"
                                                            value={this.state.deviceB?.local_id}
                                                            disabled={true}
                                                            style={{ cursor: "not-allowed" }}
                                                            title={this.state.deviceB?.local_id}
                                                        />
                                                    </div>
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Remote ID</label>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Identity to use for the certificate authentication of the Remote device"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            type="name"
                                                            class="form-control"
                                                            name="remote_id_deviceB"
                                                            value={this.state.deviceB?.remote_id}
                                                            disabled={true}
                                                            style={{ cursor: "not-allowed" }}
                                                            title={this.state.deviceB?.remote_id}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {
                                (this.state.group.group_id !== "")
                                &&
                                <>
                                    <div className="graybg-title d-flex align-items-center justify-content-between mt-3">
                                        <div>Group</div>
                                    </div>
                                    <div>
                                        <div className="row ">
                                            <div className="col-md-5">
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="device-icon">
                                                        <img src="/images/svg-icons/wifi-router.svg" alt="" />
                                                        <div
                                                            className={
                                                                (String(this.state?.group?.group_status) === "1")
                                                                    ? "status online"
                                                                    : "status offline"
                                                            }
                                                        ></div>
                                                    </div>
                                                    <div className="d-title flex-grow-1 ml-3" title={this.state.group?.group_name}>
                                                        <strong>{this.state.group?.group_name}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="mt-1 col-md-3">
                                                <span>Local IP:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    className="custom-dropdown w-155 h-27"
                                                    classNamePrefix="react-select-container"
                                                    name="deviceA_customip"
                                                    isDisabled={true}
                                                    options={[{ label: 'Auto', value: 'auto' }]}
                                                    value={
                                                        {
                                                            label: 'Auto',
                                                            value: 'auto'
                                                        }
                                                    }
                                                />
                                            </div>
                                            <div className="mt-1 col-md-3">
                                                <span>Remote IP:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    isClearable
                                                    components={
                                                        {
                                                            MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP', 'remote_ip', 'group')
                                                        }
                                                    }
                                                    isSearchable
                                                    className="custom-dropdown w-155 h-27"
                                                    classNamePrefix="react-select-container"
                                                    name="deviceA_remote_ip"
                                                    isDisabled={this.state.view}
                                                    options={this.state.group_RemoteIP_Options}
                                                    value={
                                                        {
                                                            label: this.state.group?.remote_ip,
                                                            value: this.state.group?.remote_ip
                                                        }
                                                    }
                                                    onChange={(e) =>
                                                        this.setState(prevState => ({
                                                            group: {
                                                                ...prevState?.group,
                                                                remote_ip: e?.value
                                                            }
                                                        }), () => this.validateGroup())} />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="mt-1 col-md-3">
                                                <span>Local Subnet:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    isSearchable
                                                    className={
                                                        "localSubnet custom-dropdown w-155 h-27 disabled"
                                                    }
                                                    classNamePrefix="react-select-container"
                                                    name="deviceA_customSubnet"
                                                    isMulti
                                                    isDisabled={true}
                                                    options={[{ label: '0.0.0.0/0', value: '0.0.0.0/0' }]}
                                                    value={
                                                        {
                                                            label: '0.0.0.0/0',
                                                            value: '0.0.0.0/0'
                                                        }
                                                    }
                                                />
                                            </div>
                                            <div className="mt-1 col-md-3">
                                                <span>Remote Subnet:</span>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                            </div>
                                            <div className="col-md-3">
                                                <div className="w-155">
                                                    <div className={this.state.view
                                                        ? "chip-list-border cp disabled"
                                                        : "chip-list-border cp"}>
                                                        {this.state.group?.remote_network?.map(i => <div className="chip-set remoteNetwork ">
                                                            <div
                                                                className="remoteNetworklist"
                                                                id={i}
                                                                title={i}>
                                                                {i}

                                                            </div>
                                                            <div className="remoteNetworkImage">
                                                                <img
                                                                    src="/images/svg-icons/cancel.svg"
                                                                    id={i}
                                                                    alt=""
                                                                    style={{ cursor: this.state.view && "not-allowed" }}
                                                                    onClick={() => (this.state.view)
                                                                        ? undefined
                                                                        : this.setState(prevState => ({
                                                                            group: {
                                                                                ...prevState?.group,
                                                                                remote_network: prevState?.group?.remote_network?.filter(val => val !== String(i))
                                                                            }
                                                                        }), () => this.validateGroup())}
                                                                />
                                                            </div>
                                                        </div>)}
                                                        <textarea
                                                            name="group_remote_network"
                                                            type="text"
                                                            disabled={this.state.view}
                                                            onKeyDown={(e) => this.groupRemoteSubnetOptions(e)}
                                                            onBlur={(e) => this.groupRemoteSubnetOptions(e, "blur")}
                                                            className="w-115"
                                                        />
                                                    </div>
                                                    <div className="error-txt" id={`group_remote_network_error`} >Invalid subnet</div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (this.state.VPNData?.authentication === "pubkey-cert")
                                            &&
                                            <div>
                                                <div class="row form-group">
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Certificate File</label>
                                                        {!this.state.view && <span className="red-txt">*</span>}
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Upload the openSSL Certificate for authentication"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            ref="fileInputGroup"
                                                            accept={".tar"}
                                                            onChange={(e) => this.state.view ? undefined : this.handleFileUpload("group", e)}
                                                            type="file"
                                                            style={{ display: "none" }}
                                                        />
                                                        <button
                                                            className="btn btn-primary"
                                                            style={{ cursor: this.state.view && "not-allowed" }}
                                                            onClick={() => this.refs.fileInputGroup.click()}
                                                            disabled={this.state.view}
                                                        >Browse</button>
                                                        {
                                                            (this.state.group.cert_file !== "")
                                                            &&
                                                            <div className="droped-files">
                                                                <img className="csv" src="/images/svg-icons/tar-file.png" alt="" />
                                                                <span className="ipSecFile">{this.state.group?.cert_file}</span>
                                                                {!this.state.view && <img
                                                                    className={this.state.view ? "delete-icon disabled" : "delete-icon"}
                                                                    src="/images/svg-icons/delete-red.svg"
                                                                    alt=''
                                                                    style={{ cursor: this.state.view && "not-allowed" }}
                                                                    onClick={() => this.state.view ? undefined : this.handleFileUpload("group")}
                                                                />}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Certificate ID Type</label>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Identity to use for the certificate authentication of the local device"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <select
                                                            className="form-control"
                                                            name="cert_id"
                                                            disabled={true}
                                                            style={{ cursor: "not-allowed" }}
                                                            value={this.state.group?.cert_id}
                                                        >
                                                            <option value="fqdn">{"Fully Qualified Domain Name(FQDN)"}</option>
                                                            <option value="ufqdn">{"User FQDN"}</option>
                                                            <option value="dn">{"Distinguished Name (DN)"}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Local ID</label>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Identity to use for the certificate authentication of the local device"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            type="name"
                                                            class="form-control"
                                                            name="local_id_group"
                                                            value={this.state.group?.local_id}
                                                            disabled={true}
                                                            style={{ cursor: "not-allowed" }}
                                                            title={this.state.group?.local_id}
                                                        />
                                                    </div>
                                                    <div className='mt-1 col-md-3'>
                                                        <label>Remote ID</label>
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Identity to use for the certificate authentication of the Remote device"
                                                        >
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <input
                                                            type="name"
                                                            class="form-control"
                                                            name="remote_id_group"
                                                            value={this.state.group?.remote_id}
                                                            disabled={true}
                                                            style={{ cursor: "not-allowed" }}
                                                            title={this.state.group?.remote_id}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            {
                                <div>
                                    <div className="graybg-title d-flex align-items-center justify-content-between mt-3">
                                        <div>VPN Tunnel Configuration</div>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <span className="mr-5  ml-2">
                                                <strong>IKE Phase 1</strong>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div className='mt-1 col-md-3'>
                                            <label>Key Lifetime</label>
                                            {!this.state.view && <span className="red-txt">*</span>}
                                            <div
                                                className="tooltip-c"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title="Duration for cryptographic key exchange in IKE phase 1"
                                            >
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <input
                                                type="text"
                                                class="form-control pr-7"
                                                name="keylife1"
                                                disabled={this.state.view}
                                                value={this.state.VPNData?.keylife1}
                                                onChange={this.setTunnelValues}
                                                onBlur={(e) => this.validate(e)}
                                            />
                                            <span className="mt-1 time">Seconds</span>
                                            <div className="col-md-12 error-txt" align='end' id="keylife1">Value from 3600 to 28800 allowed</div>
                                        </div>
                                        <div className='mt-1 col-md-3'>
                                            <label>Encryption
                                                {!this.state.view && <span className="red-txt">*</span>}
                                                <div
                                                    className="tooltip-c"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="To provide security during data transfer">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className="dropdown">
                                                <ul className={this.state.view ? "chip-list z-index-9 bg-white radius disabled" : "chip-list z-index-9 bg-white radius"}>
                                                    {
                                                        this.state.VPNData?.enc_algo1?.split('-')?.map((item) => {
                                                            return (
                                                                (item !== '')
                                                                &&
                                                                <li>{Common.getEncrytion(item)}
                                                                    {<img
                                                                        src="/images/svg-icons/cancel.svg"
                                                                        alt=""
                                                                        id={item}
                                                                        style={{ cursor: this.state.view && "not-allowed" }}
                                                                        onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'enc_algo1')}
                                                                    />}
                                                                </li>)
                                                        })
                                                    }
                                                </ul>
                                                <div
                                                    className={this.state.view ? "chip-box add-arrow disabled" : "chip-box add-arrow"}
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false">
                                                </div>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <ul>
                                                        {this.state?.availableEncryptions?.map((item) => {
                                                            return (<li >
                                                                <input
                                                                    type="checkbox"
                                                                    id={item}
                                                                    disabled={this.state.view}
                                                                    checked={this.state.VPNData?.enc_algo1?.split('-')?.filter(val => val === item)?.length > 0}
                                                                    style={{ cursor: this.state.view && "not-allowed" }}
                                                                    onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'enc_algo1')}
                                                                />
                                                                {Common.getEncrytion(item)}</li>)
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-12 error-txt"
                                            align="end"
                                            id="enc_algo1"
                                        >Maximum 3 values allowed</div>
                                    </div>
                                    <div class="row form-group">
                                        <div className='mt-1 col-md-3'>
                                            <label>Hash
                                                {!this.state.view && <span className="red-txt">*</span>}
                                                <div
                                                    className="tooltip-c"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="To encrypt the data with additional keys">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className="dropdown">
                                                <ul className={this.state.view ? "chip-list z-index-9 bg-white radius disabled" : "chip-list z-index-9 bg-white radius"}>
                                                    {
                                                        this.state.VPNData?.hash_algo1?.split('-')?.map((item) => {
                                                            return (
                                                                (item !== '')
                                                                &&
                                                                <li>{Common.getHash(item)}
                                                                    {
                                                                        <img
                                                                            src="/images/svg-icons/cancel.svg"
                                                                            alt=""
                                                                            id={item}
                                                                            style={{ cursor: this.state.view && "not-allowed" }}
                                                                            onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'hash_algo1')}
                                                                        />
                                                                    }
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div
                                                    className={this.state.view ? "chip-box add-arrow disabled" : "chip-box add-arrow"}
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                </div>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <ul>
                                                        {this.state.availableHash?.map((item) => {
                                                            return (
                                                                <li>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={item}
                                                                        disabled={this.state.view}
                                                                        checked={this.state.VPNData?.hash_algo1?.split('-')?.filter(val => val === item)?.length > 0}
                                                                        style={{ cursor: this.state.view && "not-allowed" }}
                                                                        onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'hash_algo1')}
                                                                    />
                                                                    {Common.getHash(item)}</li>)
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-12 error-txt"
                                                align="end"
                                                id="hash_algo1"
                                            >Maximum 2 values allowed</div>
                                        </div>
                                        <div className='mt-1 col-md-3'>
                                            <label style={{ width: "200px" }}>Diffie-Hellman group
                                                {!this.state.view && <span className="red-txt">*</span>}
                                                <div
                                                    className="tooltip-c"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="To establish session keys">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className="dropdown">
                                                <ul className={this.state.view ? "chip-list z-index-9 bg-white radius disabled" : "chip-list z-index-9 bg-white radius"}>
                                                    {
                                                        this.state.VPNData?.dh_group1?.split('-')?.map((item) => {
                                                            return (
                                                                (item !== '')
                                                                &&
                                                                <li>{Common.getGroupValue(item)}
                                                                    {
                                                                        <img
                                                                            src="/images/svg-icons/cancel.svg"
                                                                            alt=""
                                                                            id={item?.toLowerCase()?.replace(' ', '')}
                                                                            style={{ cursor: this.state.view && "not-allowed" }}
                                                                            onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'dh_group1')}
                                                                        />
                                                                    }
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div
                                                    className={this.state.view ? "chip-box add-arrow disabled" : "chip-box add-arrow"}
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false">
                                                </div>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <ul>
                                                        {this.state.availableDHGroups?.map((item) => {
                                                            return (
                                                                <li>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={(item)?.replace(' ', '')?.toLowerCase()}
                                                                        disabled={this.state.view}
                                                                        checked={this.state.VPNData?.dh_group1?.split('-')?.filter(val => val === item)?.length > 0}
                                                                        style={{ cursor: this.state.view && "not-allowed" }}
                                                                        onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'dh_group1')}
                                                                    />
                                                                    {Common.getGroupValue(item)}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-12 error-txt"
                                                align="end"
                                                id="dh_group1"
                                            >Maximum 3 values allowed</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-group">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <span className="mr-5 ml-2 ">
                                                    <strong>IKE Phase 2</strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div className='mt-1 col-md-3'>
                                                <label>Key Lifetime</label>
                                                {!this.state.view && <span className="red-txt">*</span>}
                                                <div
                                                    className="tooltip-c"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="Duration for cryptographic key exchange in IKE phase 2">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <input
                                                    type="text"
                                                    class="form-control pr-7"
                                                    name="keylife2"
                                                    disabled={this.state.view}
                                                    onChange={this.setTunnelValues}
                                                    value={this.state.VPNData?.keylife2}
                                                    onBlur={this.validate}
                                                />
                                                <span className="mt-1 time">Seconds</span>
                                                <div
                                                    className="col-md-12 error-txt"
                                                    align='end'
                                                    id="keylife2"
                                                >Value from 3600 to 28800 allowed</div>
                                            </div>
                                            <div className='mt-1 col-md-3'>
                                                <label>Encryption
                                                    {!this.state.view && <span className="red-txt">*</span>}
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="To provide security during data transfer">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className="dropdown">
                                                    <ul className={this.state.view ? "chip-list z-index-9 bg-white radius disabled" : "chip-list z-index-9 bg-white radius"}>
                                                        {
                                                            this.state.VPNData?.enc_algo2?.split('-')?.map((item) => {
                                                                return (
                                                                    (item !== '') &&
                                                                    <li>{Common.getEncrytion(item)}
                                                                        {
                                                                            <img
                                                                                src="/images/svg-icons/cancel.svg"
                                                                                alt=""
                                                                                id={item}
                                                                                style={{ cursor: this.state.view && "not-allowed" }}
                                                                                onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'enc_algo2')}
                                                                            />
                                                                        }
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    <div
                                                        className={this.state.view ? "chip-box add-arrow disabled" : "chip-box add-arrow"}
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                    </div>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <ul>
                                                            {this.state.availableEncryptions2?.map((item) => {
                                                                return <li >
                                                                    <input
                                                                        type="checkbox"
                                                                        id={item}
                                                                        disabled={this.state.view}
                                                                        checked={this.state.VPNData?.enc_algo2?.split('-')?.filter(val => val === item)?.length > 0}
                                                                        style={{ cursor: this.state.view && "not-allowed" }}
                                                                        onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'enc_algo2')}
                                                                    />
                                                                    {Common.getEncrytion(item)}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-12 error-txt"
                                                align="end"
                                                id="enc_algo2"
                                            >Maximum 3 values allowed</div>
                                        </div>
                                        <div class="row form-group">
                                            <div className='mt-1 col-md-3'>
                                                <label>Hash
                                                    {!this.state.view && <span className="red-txt">*</span>}
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="To encrypt the data with additional keys">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className="dropdown">
                                                    <ul className={this.state.view ? "chip-list z-index-9 bg-white radius disabled" : "chip-list z-index-9 bg-white radius"}>
                                                        {
                                                            this.state.VPNData?.hash_algo2?.split('-')?.map((item) => {
                                                                return (
                                                                    (item !== '')
                                                                    &&
                                                                    <li>{Common.getHash(item)}
                                                                        {<img
                                                                            src="/images/svg-icons/cancel.svg"
                                                                            alt=""
                                                                            id={item}
                                                                            style={{ cursor: this.state.view && "not-allowed" }}
                                                                            onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'hash_algo2')}
                                                                        />
                                                                        }
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    <div
                                                        className={this.state.view ? "chip-box add-arrow disabled" : "chip-box add-arrow"}
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                    </div>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <ul>
                                                            {this.state.availableHash2?.map((item) => {
                                                                return (
                                                                    <li>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={item}
                                                                            disabled={this.state.view}
                                                                            checked={this.state.VPNData?.hash_algo2?.split('-')?.filter(val => val === item)?.length > 0}
                                                                            style={{ cursor: this.state.view && "not-allowed" }}
                                                                            onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'hash_algo2')}
                                                                        />
                                                                        {Common.getHash(item)}</li>)
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-md-12 error-txt"
                                                    align="end"
                                                    id="hash_algo2"
                                                >Maximum 2 values allowed</div>
                                            </div>
                                            <div className='mt-1 col-md-3'>
                                                <label style={{ width: "200px" }}>
                                                    Diffie-Hellman group
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="To establish shared secret keys">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className="dropdown">
                                                    <ul className={this.state.view ? "chip-list z-index-9 bg-white radius disabled" : "chip-list z-index-9 bg-white radius"}>
                                                        {
                                                            this.state.VPNData?.dh_group2?.split('-')?.map((item) => {
                                                                return (
                                                                    (item !== '')
                                                                    &&
                                                                    <li>{Common.getGroupValue(item)}
                                                                        {<img
                                                                            src="/images/svg-icons/cancel.svg"
                                                                            alt=""
                                                                            id={item?.toLowerCase()?.replace(' ', '')}
                                                                            style={{ cursor: this.state.view && "not-allowed" }}
                                                                            onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'dh_group2')}
                                                                        />}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    <div
                                                        className={this.state.view ? "chip-box add-arrow disabled" : "chip-box add-arrow"}
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                    </div>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <ul>
                                                            {this.state.availableDHGroups2?.map((item) => {
                                                                return (<li>
                                                                    <input
                                                                        type="checkbox"
                                                                        disabled={this.state.view}
                                                                        id={(item)?.replace(' ', '')?.toLowerCase()}
                                                                        checked={this.state.VPNData?.dh_group2?.split('-')?.filter(val => val === item)?.length > 0}
                                                                        style={{ cursor: this.state.view && "not-allowed" }}
                                                                        onClick={(e) => this.state.view ? undefined : this.handleChange(e, 'dh_group2')}
                                                                    />
                                                                    {Common.getGroupValue(item)}</li>)
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-md-12 error-txt"
                                                align="end"
                                                id="dh_group2"
                                            >Maximum 3 values allowed</div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <span className="mr-5 ml-2"><strong>Dead Peer Detection</strong>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <span className="mr-5 ml-2">Enable
                                                <div
                                                    className="tooltip-c"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title="Enable to detect the aliveness of the peer">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </span>
                                            <label className={this.state.view ? "switch disabled" : "switch"}>
                                                <input
                                                    type="checkbox"
                                                    name="dpd_enable"
                                                    disabled={this.state.view}
                                                    onChange={this.setTunnelValues}
                                                    checked={this.state.VPNData?.dpd_enable === '1'} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        (this.state.VPNData?.dpd_enable === '1') &&
                                        <div className="gray-bg p-2">
                                            <div class="row form-group">
                                                <div className='mt-1 col-md-3'>
                                                    <label>DPD Action
                                                        {!this.state.view && <span className="red-txt">*</span>}
                                                        <div
                                                            className="tooltip-c"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            title="Method of a dead pear detection">
                                                            <img src="/images/svg-icons/info.svg" alt="" />
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <select
                                                        className="form-control"
                                                        name="dpd_action"
                                                        disabled={this.state.view}
                                                        onChange={this.setTunnelValues}
                                                        value={this.state.VPNData?.dpd_action}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="clear">Clear</option>
                                                        <option value="hold">Hold</option>
                                                        <option value="restart">Restart</option>
                                                    </select>
                                                </div>
                                                <div className='mt-1 col-md-3'>
                                                    <label>DPD delay</label>
                                                    {!this.state.view && <span className="red-txt">*</span>}
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Time interval to send the messages when the IPSec traffic is idle.">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <input
                                                        type="text"
                                                        class="form-control pr-7"
                                                        name="dpd_delay"
                                                        value={this.state.VPNData?.dpd_delay}
                                                        onChange={this.setTunnelValues}
                                                        disabled={this.state.view}
                                                        onBlur={(e) => this.validate(e)}
                                                    />
                                                    <span className="mt-1 time">Seconds</span>
                                                    <div
                                                        className="col-md-12 error-txt dpd_enable"
                                                        align='end'
                                                        id="dpd_delay"
                                                    >Value from 2 to 30 allowed</div>
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div className='mt-1 col-md-3'>
                                                    <label>DPD timeout</label>
                                                    {!this.state.view && <span className="red-txt">*</span>}
                                                    <div
                                                        className="tooltip-c"
                                                        data-toggle="tooltip"
                                                        data-placement="right"
                                                        title="Waiting time to consider the peer to be dead if no response is received">
                                                        <img src="/images/svg-icons/info.svg" alt="" />
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <input
                                                        type="text"
                                                        class="form-control pr-7"
                                                        name="dpd_timeout"
                                                        value={this.state.VPNData?.dpd_timeout}
                                                        onChange={this.setTunnelValues}
                                                        disabled={this.state.view}
                                                        onBlur={(e) => this.validate(e)}
                                                    />
                                                    <span className="mt-1 time">Seconds</span>
                                                    <div
                                                        className="col-md-12 error-txt dpd_enable"
                                                        align='end'
                                                        id="dpd_timeout"
                                                    >Value from 10 to 3600 allowed</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    (!this.state.info && !this.state.view)
                    &&
                    <div className="s-footer">
                        <button
                            className="btn btn-light"
                            onClick={
                                (this.state.apply && !this.state.edit && !this.state.reapply)
                                    ? () => this.props.closeAddTemplate()
                                    : () => this.closeAll()
                            }>
                            {this.state.apply && !this.state.edit && !this.state.reapply ? 'Back' : 'Cancel'}
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={this.state.apply ? this.checkError() : this.checkSaveError()}
                            onClick={
                                this.state.apply
                                    ? (event) => this.debounceApply(this.applyTemplate, event)
                                    : () => this.saveData()
                            }>
                            {this.state.apply
                                ? (this.props?.tunnelEdit
                                    ? "Update"
                                    : 'Apply')
                                : 'Save'
                            }
                        </button>
                    </div>
                }
                {
                    this.state.customIP
                    && <div
                        className="modal fade"
                        id="exampleModalIP"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5
                                        className="modal-title"
                                        id="exampleModalLabel"
                                    >
                                        {this.state.subnet ? 'Add Subnet' : 'Add IP'}
                                    </h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => this.cancelIP()}>
                                        <span aria-hidden="true">
                                            <img src="/images/svg-icons/cancel.svg" alt="" />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group mb-1">
                                        <label>{this.state.subnet ? 'IP address/Subnet' : 'IP address'}</label>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="text"
                                                className="form-control w-100"
                                                name="ipAddress"
                                                onBlur={(e) => this.validateCustomIP(e)}
                                                onChange={(e) => this.removeErrorIP(e)}
                                            />
                                        </div>
                                        <div
                                            className="error-txt ip"
                                            id="ipAddress"
                                        >
                                            {this.state.customSubnet
                                                ? 'Invalid IP address/Subnet'
                                                : 'Invalid IP address'
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                        onClick={() => this.cancelIP()}
                                    >Cancel</button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-dismiss="modal"
                                        disabled={!this.state.validIP && !this.state.validSubnet}
                                        onClick={this.addIP}
                                    >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.show &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {
                    this.state.isAxiosError &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {
                    this.state.showRefreshModal && <ErrorHandlePopup />
                }
            </div >
        );
    }
};

const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying,
    };
};

export default connect(mapStateToProps)(IPSecTemplate);