export const LinkPopup = (props) => {
    return (
        // EMGT-51 : Able to apply templates multiple times to the device at a time
        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body pt-5">
                        <div className="error-msg">
                            <span className="success-icon"></span>
                            <div className="txt1">{props.title}</div>
                            <div className="txt2">{props.message} {!
                            props.nonSync && <span
                                onClick={() => props.open(props.id)}>click here</span>}</div>
                        </div>
                    </div>
                    <div className="modal-footer border-top-0 justify-content-center pb-4">
                        <button type="button" className="btn btn-light" data-dismiss="modal"
                            onClick={() => props.close()}>OK</button>

                    </div>
                </div>
            </div>
        </div>
    )
}