/* eslint-disable array-callback-return */
import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import * as Action from "../../../../redux/actionTypes";
import * as Common from "../../../../utils/common";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import ErrorHandlePopup from "../../../ErrorHandlePopup";
import debounce from 'lodash/debounce';
import { Netmask } from 'netmask';
import isEqual from 'lodash/isEqual';
class StaticRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      showError: false,
      keyUpdate: "",
      update: false,
      networkViewData: [],
      multipleSelect: "",
      toggledClearRows: false,
      show: false,
      openAddTemplate: false,
      selectedTemplateId: this.props?.id ? this.props?.id : "",
      template_type: this.props?.templateData?.template_type || "",
      template_name: this.props?.templateData?.template_name || "",
      template_desc: this.props?.templateData?.template_desc || "",
      device_model: this.props?.templateData?.device_model || "",
      collection_version: this.props?.templateData?.collection_version || "",
      fw_version: this.props?.templateData?.fw_version || "",
      chimay: this.props.chimay,
      cbr: this.props.cbr,
      mifi: this.props.mifi,
      isAxiosError: false,
      staticRouteData: {
        routename: Math.random().toString(36).substring(2, 11).toUpperCase(),
        type: "IPv4",
        target: "",
        netmask: "",
        gateway: "",
        metric: "",
        network: "",
        isvtysh: "0",
        isdeleted: "0",
      },
      templateData: {
        details: [],
      },
      columns: [
        {
          name: "Destination Network ",
          selector: "target",
          cell: (row) => (
            <span
              title={row.target}
              style={{ width: "auto" }}
              className="cursor-pointer"
            >
              <Link
                to={{}}
                className="txt-blue"
                onClick={() => this.openStaticTemplate(row)}
              >
                {" "}
                {row.target}
              </Link>
            </span>
          ),
        },
        {
          name: "Destination Type",
          selector: "type",
          cell: (row) => <span title={row?.type}>{row?.type}</span>,
        },
        {
          name: "Netmask",
          selector: "type",
          cell: (row) => <span title={row?.netmask}>{row?.netmask}</span>,
        },
        {
          name: "Gateway",
          selector: "gateway",
          cell: (row) => <span title={row?.gateway}>{row?.gateway}</span>,
        },
        {
          name: "Metric",
          selector: "metric",
          cell: (row) => <span title={row?.metric}>{row?.metric}</span>,
        },
      ],
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      this.props?.template_schema_json?.details?.map((item) => {
        if (this.props?.networkViewData) {
          let availableNetworks = this.props?.networkViewData?.filter(element => element?.ip_address !== "" && element?.name !== "Control Tunnel"
            && element?.name !== "Data Tunnel");
          let newArr = []
          newArr = availableNetworks.filter(e => e.networkName === item.network)
          if (newArr.length !== 0)
            return item.network = newArr?.[0].networkName;
          else
            return item.network = "";
        }
      })
      this.setState({
        templateData: JSON.parse(
          JSON.stringify(this.props?.template_schema_json)
        ),
      });
    }
    if (this.props.networkViewData) {
      this.setState({
        networkViewData: this.props?.networkViewData?.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
          && element.name !== "Data Tunnel")
      })
    }
    if (this.props.edit && this.props.networkViewData?.length > 0) {
      let { columns } = this.state;
      let networkViewData = this.props?.networkViewData?.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
        && element.name !== "Data Tunnel");
      const index = columns.findIndex(item => item.name === 'Network');
      if (index < 0) {
        columns.splice(1, 0, {
          name: "Network",
          selector: "network",
          minWidth: '150px',
          cell: (row) => {
            return (!this.props.edit
              ? (<span>{this.getNetworkName(row.network)}</span>)
              // Long-1339 value added for edit case
              : (<select
                className="form-control"
                onChange={(e) => this.handleChange(e, row)}
                // LONG-1889 : Network dropdown of static route updated incorrectly
                value={row.network}>
                <option value="">Select</option>
                {
                  (networkViewData.length > 0 && networkViewData.map((item) => {
                    return (<option value={item.networkName}>{item.name}</option>)
                  })
                  )}
              </select>))
          }
        });
        this.setState({ keyUpdate: Math.random() })
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.templateData).length !== 0) {
      this.setState({
        selectedTemplateId: nextProps.id ? nextProps.id : '',
        template_type: nextProps?.templateData?.template_type || "",
        template_name: nextProps?.templateData?.template_name || "",
        template_desc: nextProps?.templateData?.template_desc || "",
        device_model: nextProps?.templateData?.device_model || "",
        collection_version: nextProps?.templateData?.collection_version || "",
        fw_version: nextProps?.templateData?.fw_version || ""
      });
    }
    //LONG-2889-Wrongs Values shown in wrong device after pushing template
    if (nextProps.edit) {
      if (!isEqual(this.props.template_schema_json, nextProps.template_schema_json) ||
        !isEqual(this.props?.networkViewData, nextProps?.networkViewData)) {
        if (nextProps.template_schema_json && nextProps?.networkViewData) {
          nextProps?.template_schema_json?.details?.map((item) => {
            let availableNetworks = nextProps?.networkViewData?.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
              && element.name !== "Data Tunnel");
            let newArr = []
            newArr = availableNetworks.filter(e => e.networkName === item.network)
            if (newArr.length !== 0)
              return item.network = newArr[0].networkName;
            else
              return item.network = "";
          })
          this.setState({
            templateData: nextProps.template_schema_json
          });
        }
        if (nextProps.networkViewData?.length > 0) {
          let { columns } = this.state;
          let networkViewData = nextProps.networkViewData?.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
            && element.name !== "Data Tunnel");
          const index = columns.findIndex(item => item.name === 'Network');
          if (index < 0) {
            columns.splice(1, 0, {
              name: "Network",
              selector: "network",
              minWidth: '150px',
              cell: (row) => {
                return (!nextProps.edit ? (<span>{this.getNetworkName(row.network)}</span>) :
                  // Long-1339 value added for edit case
                  (<select className="form-control" onChange={(e) => this.handleChange(e, row)}
                    // LONG-1889 : Network dropdown of static route updated incorrectly
                    value={row.network}>
                    <option value="">Select</option>
                    {
                      (networkViewData.length > 0 && networkViewData.map((item) => {
                        return (<option value={item.networkName}>{item.name}</option>)
                      })
                      )}
                  </select>))
              }
            });
            this.setState({ keyUpdate: Math.random() })
          }
        }
      }
    }
    if (!isEqual(this.props?.networkViewData, nextProps?.networkViewData))
      if (nextProps.networkViewData) {
        this.setState({
          networkViewData: nextProps.networkViewData?.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
            && element.name !== "Data Tunnel")
        })
      }
    if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
      !isEqual(this.props?.cbr, nextProps?.cbr) ||
      !isEqual(this.props?.mifi, nextProps?.mifi)) {
      this.setState({
        chimay: nextProps.chimay,
        cbr: nextProps.cbr,
        mifi: nextProps.mifi
      })
    }
  }
  getNetworkName = (name) => {
    const network = this.state.networkViewData.filter(item => item.networkName === name)?.[0];
    let networkName = '';
    if (network)
      networkName = network.name;
    return networkName;
  }
  handleChange = (e, row) => {
    let { templateData } = this.state;
    templateData?.details?.map((item) => {
      if (item.routename === row.routename)
        item.network = e.target.value;
      return item;
    });
    this.setState({
      templateData,
      // LONG-1889 : Network dropdown of static route updated incorrectly
      keyUpdate: Math.random(),
    })
  }
  setValue = (e) => {
    if (Common.removeError(e)) {
      this.setState({
        showError: true,
      });
    }
    const name = e.target.name;
    if (name === "target" || name === "netmask") {
      const element = document.getElementById('target');
      const element2 = document.getElementById('netmask');
      if (element && element2) {
        element.style.display = 'none';
        element.innerHTML = '';
        element2.style.display = 'none';
        element2.innerHTML = '';
        this.setState({
          showError: false,
        })
      }
    }
    let value = (e.target.type === "checkbox") ?
      (e.target.checked === true ? "1" : "0") : e.target.value;
    let data = this.state.staticRouteData;
    if (
      (name === "target" || name === "gateway") &&
      (value === "0.0.0.0" || value === "255.255.255.255")
    ) {
      const element = document.getElementById(name);
      if (element) {
        element.style.display = "block";
        this.setState({
          showError: true,
        });
      }
    }
    if ((name === "gateway" || name === "target")) {
      if (value === this.state.staticRouteData.target || value === this.state.staticRouteData.gateway) {
        const element = document.getElementById(name);
        if (element) {
          element.innerHTML = "Destination network and Gateway can't be same";
          element.style.display = "block";
          this.setState({
            showError: true,
          });
        }
      }
      else {
        const elements = document.getElementsByClassName("error-txt same");
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.cssText = "display: none;"
        }
        this.setState({
          showError: false,
        });
      }
    }
    else if (name === "metric" && (parseInt(value) > 9999 || (data.isvtysh === "1" && value === ''))) {
      const element = document.getElementById(name);
      if (element) {
        element.style.display = "block";
        this.setState({
          showError: true,
        });
      }
    } else if (name === "netmask" && !this.checkRange(value)) {
      const element = document.getElementById(name);
      if (element) {
        element.style.display = "block";
        this.setState({
          showError: true,
        });
      }
    } else if (value.includes("/")) {
      const element = document.getElementById(name);
      if (element) {
        element.style.display = "block";
        this.setState({
          showError: true,
        });
      }
    }
    data[name] = value;
    this.setState({ staticRouteData: data });
  };
  checkRange = (data) => {
    // let a = "255.0.0.0";
    // let b = "255.255.255.255";
    // let x = data.split(".");
    // a = a.split(".");
    // b = b.split(".");
    // for (let i = 0; i < 4; i++) {
    //   if (!(a[i] <= x[i] && b[i] >= x[i])) {
    //     return true;
    //   }
    // }
    // return false;
    if (/^(255\.(254|252|248|240|224|192|128|0)\.0\.0|255\.255\.(254|252|248|240|224|192|128|0)\.0|255\.255\.255\.(255|254|252|248|240|224|192|128|0))$/.test(data))
      return true;
    else
      return false;
  };
  saveData = () => {
    let data = {};
    data.template_name = this.state.template_name;
    data.template_desc = this.state.template_desc;
    data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
    data.template_type = this.state.template_type;
    data.collection_version = this.state.collection_version;
    data.fw_version = this.state.fw_version;
    data.template_order = 4;
    data.template_schema_json = this.state.templateData;
    if (this.state.selectedTemplateId === "") {
      data.template_version = {
        chimay: this.state.chimay,
        cbr: this.state.cbr,
        mifi: this.state.mifi
      }
    }
    this.setState({
      showLoader: true,
    });
    if (this.state.selectedTemplateId !== "") {
      ApiCall(
        urlMapping.updateTemplate(this.state.selectedTemplateId, data),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.Success) {
            this.props.refreshList();
            this.props.openApplyTemplate(this.state.selectedTemplateId);
            this.props.closeAddTemplate();
          } else {
            $("#errorMessage").modal("hide");
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true })
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else {
              this.setState(
                {
                  title: "Update Template",
                  message: response?.message,
                  show: true,
                  error: true,
                },
                () => $("#errorMessage").modal("show")
              );
            }
          }
        }
      );
    } else {
      ApiCall(urlMapping.createTemplate(data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.success) {
          this.props.refreshList();
          this.props.openApplyTemplate(response?.data?.id);
          this.props.closeAddTemplate();
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          $("#errorMessage").modal("hide");
          if (parseInt(response?.response?.status) === 401) {
            this.setState({ showRefreshModal: true })
          } else {
            this.setState(
              {
                title: "Create Template",
                message: response?.message,
                show: true,
                error: true,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    }
  };
  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
      },
      () => {
        $("#errorMessage").modal("hide");
        this.props.refreshList();
        this.props.closeAddTemplate();
      }
    );
  };
  validateIP = (evt) => {
    if (!Common.validateIPaddress(evt)) {
      this.setState({ showError: true });
    } else {
      if (this.state.staticRouteData.target !== '' &&
        this.state.staticRouteData.netmask !== '') {
        try {
          const block = new Netmask(this.state.staticRouteData.target, this.state.staticRouteData.netmask);
          if (this.state.staticRouteData.target !== block?.base) {
            const element = document.getElementById(evt.target.name);
            if (element) {
              element.style.display = 'block';
              element.innerHTML = `IP address doesn't match this subnet length. You can use ${block.base} for this subnet`;
              this.setState({
                showError: true,
              })
            }
          }
        } catch (error) {
          const element = document.getElementById(evt.target.name);
          if (element) {
            element.style.display = 'block';
            element.innerHTML = `Invalid netmask, IP address match`;
            this.setState({
              showError: true,
            })
          }
        }
      } else {
        const element = document.getElementById('target');
        const element2 = document.getElementById('netmask');
        if (element && element2) {
          element.style.display = 'none';
          element.innerHTML = '';
          element2.style.display = 'none';
          element2.innerHTML = '';
          this.setState({
            showError: false,
          })
        }
      }
    }
  };
  checkError = () => {
    let result = false;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;") result = true;
    }
    if (this.props.apply) {
      this.state.templateData.details.map((route) => {
        if (route.network === "" && route.isdeleted !== "1")
          result = true;
      })
    } else if (
      this.state.template_name === "" || this.state.template_type === "" ||
      this.state.templateData.details.length === 0
    )
      result = true;
    return result;
  };
  addStaticRoute = () => {
    if (this.state.staticRouteData.target !== "") {
      let result = false;
      const elements = document.getElementsByClassName("error-txt");
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].style.cssText === "display: block;") result = true;
      }
      if (result === false) {
        const data = this.state.templateData;
        let found = false;
        data?.details?.map((item, index) => {
          if (item.routename === this.state.staticRouteData.routename) {
            found = true;
            data.details[index] = this.state.staticRouteData;
          }
        });
        if (!found) data.details?.push(this.state.staticRouteData);
        this.setState(
          {
            templateData: data,
            keyUpdate: Math.random(),
            staticRouteData: {
              routename: Math.random()
                .toString(36)
                .substring(2, 11)
                .toUpperCase(),
              type: "IPv4",
              target: "",
              netmask: "",
              gateway: "",
              metric: "",
              network: "",
              isvtysh: "0",
              isdeleted: "0"
            },
          },
          () => this.closeStaticRoute()
        );
      }
    } else {
      const element = document.getElementById("target");
      if (element) {
        element.style.display = "block";
        this.setState({
          showError: true,
        });
      }
    }
  };
  checkStaticError = () => {
    let result = false;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (this.state.staticRouteData.gateway === '' ||
      this.state.staticRouteData.netmask === '' ||
      this.state.staticRouteData.target === '' ||
      (this.state.staticRouteData.isvtysh === '1' && this.state.staticRouteData.metric === '')
    ) {
      result = true;
    }
    return result;
  }
  openStaticTemplate = (data) => {
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.cssText = "display: none;";
    }
    if (data) {
      this.setState({
        staticRouteData: data,
        openAddTemplate: true,
        update: true,
      });
    } else {
      this.setState({
        staticRouteData: {
          routename: Math.random().toString(36).substring(2, 11).toUpperCase(),
          type: "IPv4",
          target: "",
          netmask: "",
          gateway: "",
          metric: "",
          network: "",
          isvtysh: "0",
          isdeleted: "0",
        },
        openAddTemplate: true,
      });
    }
    if (this.props.edit) {
      this.props.dispatch({
        type: Action.OPEN_ADD_TEMPLATE,
      });
    }
  };
  deleteStaticRoute = () => {
    let data = this.state.templateData;
    if (this.props.edit) {
      this.state.multipleSelect.map((item) => {
        let found = false;
        this.props?.template_schema_json?.details?.map((key) => {
          if (item.routename === key.routename) {
            this.state.templateData.details.filter(
              (value) => value.routename === item.routename
            )[0].isdeleted = "1";
            found = true;
          }
          return key;
        });

        if (found === false) {
          data.details = this.state.templateData.details.filter(
            (finalValue) => finalValue.routename !== item.routename
          );
        }
      });
    } else {
      this.state.multipleSelect.map((item) => {
        data.details = this.state.templateData.details.filter(
          (finalValue) => finalValue.routename !== item.routename
        );
      });
    }
    this.setState({
      templateData: data,
      toggledClearRows: !this.state.toggledClearRows,
    });
  };
  multipleSelect(rows) {
    if (!rows.selectedRows.length) this.setState({ multipleSelect: "" });
    else {
      this.setState({ multipleSelect: rows.selectedRows });
    }
  }
  closeStaticRoute = () => {
    this.setState({
      openAddTemplate: false,
      update: false,
    });
  };
  cancelStaticRoute = () => {
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.cssText = "display: none;";
    }
    //Long_1794 Static route tab is not closing if cancel is clicked  
    this.setState({
      openAddTemplate: false,
      update: false,
      // Long-1794 static route cancel issue fixed
      // templateData: JSON.parse(
      //   JSON.stringify(this.props.template_schema_json)
      // ),
    });
  }
  closeApplyTemplate = () => {
    this.setState(
      {
        templateData: JSON.parse(
          JSON.stringify(this.props?.template_schema_json)
        ),
      },
      () => {
        this.closeStaticRoute();
        this.props.closeApplyTemplate();
      }
    );
  };
  applyTemplate = () => {
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(this.state.templateData);
  }
  debounceApply = debounce((fn, event) => {
    fn(event)
  }, 500);
  render() {
    return (
      <div>
        <div className="static-route-template">
          <div className={this.state.openAddTemplate ? "disabled-slide" : ""}></div>
          <div className="page-sub-title">
            <div>
              <strong>Static Route</strong>
              <img
                src="/images/svg-icons/add.svg"
                className={(this.props?.templateData?.default_type !== ''
                  && this.props?.templateData?.default_type !== null) ? "add-btn ml-2 disabled" : "add-btn ml-2"}
                alt=""
                onClick={
                  (this.props?.templateData?.default_type !== ''
                    && this.props?.templateData?.default_type !== null)
                    ? undefined
                    : () => this.openStaticTemplate()}
              />
            </div>
            <div className="device-action">
              <span className={this.state.multipleSelect?.length !== 1 ?
                "icon-box disabled" : "icon-box"}>
                <img
                  src="/images/svg-icons/edit.svg"
                  alt=""
                  disabled={this.state.multipleSelect?.length !== 1}
                  onClick={this.state.multipleSelect?.length === 1 ? () => this.openStaticTemplate(this.state?.multipleSelect?.[0]) : undefined}
                />
              </span>
              <span className={this.state.multipleSelect === '' || (this.props?.templateData?.default_type !== ''
                && this.props?.templateData?.default_type !== null) ?
                "icon-box disabled" : "icon-box"}>
                <img
                  src="/images/svg-icons/delete.svg"
                  alt=""
                  onClick={
                    this.state.multipleSelect === "" || (this.props?.templateData?.default_type !== ''
                      && this.props?.templateData?.default_type !== null)
                      ? undefined
                      : () => this.deleteStaticRoute()
                  }
                />
              </span>
            </div>
          </div>
          <div className="staticRoute-table" key={this.state.keyUpdate}>
            {this.state.templateData?.details?.length > 0 && (
              <DataTable
                columns={this.state.columns}
                data={
                  this.props.edit
                    ? this.state.templateData?.details?.filter(
                      (item) => item?.isdeleted !== "1"
                    )
                    : this.state.templateData?.details
                }
                selectableRows
                fixedHeader
                onSelectedRowsChange={(data) => this.multipleSelect(data)}
                clearSelectedRows={this.state.toggledClearRows}
              />
            )}
          </div>
          {
            <div className="s-footer">
              <button
                className="btn btn-light"
                onClick={
                  this.props.apply && this.props.edit
                    ? () => {
                      this.closeApplyTemplate();
                    }
                    : () => this.props.closeAddTemplate()
                }
              >
                {this.props.apply && !this.props.edit && !this.props.reapply ? "Back" : "Cancel"}
              </button>
              <button
                className="btn btn-primary"
                disabled={this.checkError() || this.props.applying}
                onClick={
                  this.props.apply
                    ? (event) => this.debounceApply(this.applyTemplate, event)
                    : () => this.saveData()
                }
              >
                {this.props.apply
                  ? "Apply"
                  : this.state.selectedTemplateId !== ""
                    ? "Update"
                    : "Save"}
              </button>
            </div>
          }
        </div>
        <div
          className={
            (
              this.props.edit
                ? this.state.openAddTemplate === true
                : this.state.openAddTemplate
            )
              ? "sidebar-slide  zindex10 w-350 second-open r-757"
              : "sidebar-slide  zindex10 w-350"
          }
        >
          <div className="s-header">
            <h1 className="ss-title">
              {this.state.update ? "Edit Static Route" : "Add New Static Route"}
            </h1>
            <button>
              <img
                src="/images/svg-icons/s-cancel.svg"
                alt=""
                onClick={() => this.closeStaticRoute()}
              />
            </button>
          </div>
          <div className="s-body">
            <div className="form-group">
              <label>
                Destination Type
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Internet protocol version"
                >
                  <img src="/images/svg-icons/info.svg" alt='' />
                </div>
              </label>
              <input
                type="text"
                className="form-control"
                name="type"
                value={this.state.staticRouteData?.type}
                onChange={(e) => this.setValue(e)}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label>
                Destination Network
                <span className="red-txt">*</span>
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Target network address of the route"
                >
                  <img src="/images/svg-icons/info.svg" alt='' />
                </div>
              </label>
              <input
                type="text"
                className="form-control"
                name="target"
                value={this.state.staticRouteData?.target}
                onChange={(e) => this.setValue(e)}
                onBlur={(e) => this.validateIP(e)}
              />
              <div className="error-txt same" id="target">
                Invalid value
              </div>
            </div>
            <div className="form-group">
              <label className="d-block">
                Netmask
                <span className="red-txt">*</span>
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Specifies the route netmask"
                >
                  <img src="/images/svg-icons/info.svg" alt='' />
                </div>
              </label>
              <input
                type="text"
                className="form-control"
                name="netmask"
                value={this.state.staticRouteData?.netmask}
                title="Specifies the route netmask"
                onChange={(e) => this.setValue(e)}
                onBlur={(e) => this.validateIP(e)}
              />
              <div className="error-txt" id="netmask">
                Invalid value
              </div>
            </div>
            <div className="form-group">
              <label>
                Gateway
                <span className="red-txt">*</span>
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Passage between two networks"
                >
                  <img src="/images/svg-icons/info.svg" alt='' />
                </div>
              </label>
              <input
                type="text"
                className="form-control"
                name="gateway"
                value={this.state.staticRouteData?.gateway}
                onChange={(e) => this.setValue(e)}
                onBlur={(e) => this.validateIP(e)}
              />
              <div className="error-txt same" id="gateway">
                Invalid value
              </div>
            </div>
            <div className="form-group">
              <label>
                {this.state.staticRouteData.isvtysh !== '1' ? "Metric (Optional)" :
                  "Metric"}
                {this.state.staticRouteData.isvtysh === '1' && <span className="red-txt">*</span>}
                <div
                  className="tooltip-c"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Cost of the route"
                >
                  <img src="/images/svg-icons/info.svg" alt='' />
                </div>
              </label>
              <input
                type="number"
                className="form-control"
                name="metric"
                min="0"
                max="9999"
                value={this.state.staticRouteData?.metric}
                onChange={(e) => this.setValue(e)}
              />
              <div className="error-txt" id="metric">
                Invalid value
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <span className="mr-5"><strong>Add route via VTY shell</strong>
                  <div
                    className="tooltip-c"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="VTY shell add the route in quagga routing table.The quagga daemon chose the best path among route with same destination and different gateway and  will added it to kernel routing table">
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    name='isvtysh'
                    onChange={(e) => this.setValue(e)}
                    disabled={this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Firestorm' ||
                      this.props.selectedModel === 'Duvel_Model' || this.props.selectedModel === "Stormbreaker"}
                    checked={this.state.staticRouteData?.isvtysh === '1'} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="s-footer">
            <button
              className="btn btn-light"
              onClick={() => this.cancelStaticRoute()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              disabled={this.checkStaticError()}
              onClick={() => this.addStaticRoute()}
            >
              {this.state.update ? "Update" : "Add"}
            </button>
          </div>
        </div>
        {this.state.show && (
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.resetPopup()}
          />
        )}
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(StaticRoute);
