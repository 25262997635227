// let configURL = process.env.REACT_APP_PF_URL;
// let configURL = "https://54.200.18.189:1443/api/v1/configurator/";
// let pfConfigURL = "https://34.210.84.53:1443/api/v1/";

let baseURL = "https://34.220.120.194:1443/api/v1/";
let configURL = baseURL + "configurator/";
export const setIP = (ip) => {
    baseURL = `https://${ip}:1443/api/v1/`;
    configURL = baseURL + "configurator/";
}

const pfUrlMapping = {
    getGateway: function () {
        return {
            url:
                configURL +
                `config/system/gateway`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    getHostname: function () {
        return {
            url:
                configURL +
                `config/system/hostname`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    getDNS: function () {
        return {
            url:
                configURL +
                `config/system/dns_servers`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    getInterfaces: function () {
        return {
            url:
                configURL +
                `config/interfaces?sort=id&fields=is_running,id,ipaddress,netmask,ipv6_address,ipv6_prefix,network,type,additional_listening_daemons,high_availability,buttons&limit=1000`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    setAsManagement: function (data) {
        return {
            url: configURL + `config/interface/${data.name}`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    setDNS: function (data) {
        return {
            url: configURL + `config/system/dns_servers`,
            method: "PUT",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    start: function (data) {
        return {
            url: configURL + `system_service/packetfence-mariadb/start`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    getSummary: function () {
        return {
            url:
                configURL +
                `system_summary`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    getGeneralData: function () {
        return {
            url:
                configURL +
                `config/base/general`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    getAlertingData: function () {
        return {
            url:
                configURL +
                `config/base/alerting`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    getAdminData: function () {
        return {
            url:
                configURL +
                `user/admin`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    getStatusData: function () {
        return {
            url:
                configURL +
                `service/tracking-config/status`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    getDBData: function () {
        return {
            url:
                configURL +
                `config/base/database`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        };
    },
    test: function (data) {
        return {
            url: configURL + `config/base/database/test`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    secureInstallation: function (data) {
        return {
            url: configURL + `config/base/database/secure_installation`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    createDB: function (data) {
        return {
            url: configURL + `config/base/database/create`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    startPolling: function (id) {
        return {
            url: configURL + `pfqueue/task/${id}/status/poll`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    systemSummary: function () {
        return {
            url: configURL + `system_summary`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    assign: function (data) {
        return {
            url: configURL + `config/base/database/assign`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    savePassword: function (data) {
        return {
            url: configURL + `user/admin/password`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    startConfig: function (data) {
        return {
            url: configURL + `system_service/packetfence-config/restart`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    updateSystem: function (data) {
        return {
            url: configURL + `service/pf/update_systemd`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    restart: function (data) {
        return {
            url: configURL + `service/haproxy-admin/restart`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    startDB: function () {
        return {
            url: configURL + `system_service/packetfence-mariadb/start`,
            method: "POST",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    getAdvanced: function () {
        return {
            url: configURL + `config/base/advanced`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    restartPF: function () {
        return {
            url: configURL + `system_service/packetfence-config/restart`,
            method: "POST",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    setAdvanced: function (data) {
        return {
            url: configURL + `config/base/advanced`,
            method: "PATCH",
            dataType: "json",
            data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    login: function (data) {
        return {
            url: baseURL + `login`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
        }
    },
    updatePassword: function (data, id, auth_token) {
        return {
            url: baseURL + `user/${id}/password`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    updatePreSharedKey: function (data, ip, auth_token) {
        return {
            url: baseURL + `config/${ip}`,
            method: "PUT",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    remoteService: function (data, auth_token) {
        return {
            url: baseURL + `config/sources`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    updateNULLrealM: function (data, auth_token) {
        return {
            url: baseURL + `config/realm/NULL`,
            method: "PUT",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    removeNULLrealM: function (data, auth_token) {
        return {
            url: baseURL + `config/realm/NULL`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    updateRemoteService: function (data, source_id, auth_token) {
        return {
            url: baseURL + `config/source/${source_id}`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    createUser: function (data, auth_token) {
        return {
            url: baseURL + `users`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    createUserPassword: function (data, auth_token) {
        return {
            url: baseURL + `user/${data.pid}/password`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    createSwitch: function (data, auth_token) {
        return {
            url: baseURL + `config/switches`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    switchAuth: function (data, auth_token) {
        return {
            url: baseURL + `config/base/radius_configuration`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    hashPasswords: function (data, auth_token) {
        return {
            url: baseURL + `config/base/advanced`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    switchPassword: function (data, auth_token) {
        return {
            url: baseURL + `config/switch_group/5GEdge-SG`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    restartRadius: function (auth_token) {
        return {
            url: baseURL + `service/radiusd-auth/restart`,
            method: "POST",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }        
    },
    createRealm: function (data, auth_token) {
        return {
            url: baseURL + `config/realms`,
            method: "POST",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    updateRealm: function (data, auth_token) {
        return {
            url: baseURL + `config/realm/${data.id}`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    restartWinBindService: function (auth_token) {
        return {
            url: baseURL + `service/winbindd/restart`,
            method: "POST",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    getLocalUserDetails: function (auth_token) {
        return {
            url: baseURL + `user/5gEdge`,
            method: "GET",
            dataType: "json",
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
    setLocalPassword:  function (data, auth_token) {
        return {
            url: baseURL + `user/5gEdge/password`,
            method: "PATCH",
            dataType: "json",
            data: data,
            cache: false,
            contentType: "application/json;",
            timeout: 40000,
            headers: {
                'Authorization': auth_token,
            }
        }
    },
}
export default pfUrlMapping;