import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { Loader } from "../../../layOuts/Loader";
// import * as Common from "../../utils/common";
import * as Action from '../../../redux/actionTypes';
import { setIP } from '../../../redux/API/pfAPI';
import { ErrorPopup } from "../../../layOuts/errorPopup";
import $ from "jquery";
import ErrorHandlePopup from "../../ErrorHandlePopup";
import AAAServer from "./AAAServer";
import Packetfence from "../Packetfence";
class ZTNAServer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      title: "",
      message: "",
      show: false,
      error: false,
      showLoader: false,
      pfData: [],
      is_auth_server_configured: false,
      isAxiosError: false,
    };
  }
  componentDidMount() {
    this.getConfigInfo();
  }
  getConfigInfo = () => {
    this.setState({
      showLoader: true
    })
    ApiCall(urlMapping.getConfigInfo(), (response) => {
      if (response?.success) {
        this.setState({
          is_auth_server_configured: response?.data?.is_auth_server_configured
        }, () => {
          this.getServerDetails()
        })
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
            showLoader: false
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.getServerDetails()
        }
      }
    })
  }
  getServerDetails = () => {
    this.setState({
      showLoader: true
    })
    ApiCall(urlMapping.getPFServerDetails(), (response) => {
      this.setState({
        showLoader: false
      })
      if (response?.success) {
        if (response?.data) {
          this.setState({
            pfData: response?.data
          }, () => {
            if (!this.state.is_auth_server_configured && this.state.pfData.length > 0) {
              this.setState({ selectedTab: "Packetfence" })
            }
            else if (!this.state.is_auth_server_configured && this.state.pfData.length === 0) {
              this.setState({ selectedTab: "noData" })
            }
            else {
              this.setState({ selectedTab: "Packet" })
            }
          })
          if (response?.data?.[0]?.device_public_ip !== '') {
            setIP(response?.data?.[0]?.device_public_ip);
            this.props.dispatch({
              type: Action.GET_AUTH_DETAILS,
              payload: response?.data?.[0],
            });
          }
        }
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({
            showRefreshModal: true,
          });
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({ selectedTab: "noData" })
        }
      }
    })
  }
  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
      },
      () => {
        $("#errorMessage").modal("hide");
      }
    );
  };
  setSelectedTab = () => {
    this.getConfigInfo()
  }
  render() {
    return (
      <div>
        {/* <SideMenu /> */}
        {this.state.showLoader && <Loader />}
        <div className="no-bg page-height">
          <div className="page-header device-head">
            <ul
              className="tab-nav nav nav-pills ml-0 mr-0"
              id="pills-tab"
              role="tablist"
            >
              <li className={
                ((!this.state.is_auth_server_configured && this.state.pfData.length > 0) &&
                  (this.props?.userRole !== "Viewer_Group" && this.props?.userRole !== "Operator_Group"))
                  ? "nav-item"
                  : "nav-item disabled"
              }
                role="presentation">
                <a
                  className={
                    ((!this.state.is_auth_server_configured && this.state.pfData.length > 0)
                      &&
                      (this.props?.userRole !== "Viewer_Group" && this.props?.userRole !== "Operator_Group"))
                      ? "nav-link active"
                      : "nav-link disabled"
                  }
                  id="pills-ma-packetfence-tab"
                  data-toggle="pill"
                  href="#pills-ma-packetfence"
                  role="tab"
                  aria-controls="pills-ma-packetfence"
                  aria-selected="false"
                  onClick={
                    (!this.state.is_auth_server_configured && this.state.pfData.length > 0)
                      ? () => this.setState({ selectedTab: "Packetfence" })
                      : undefined
                  }
                >
                  Packetfence
                </a>
              </li>
              <li className={
                (!this.state.is_auth_server_configured || this.state.pfData.length === 0)
                  ? "nav-item disabled"
                  : "nav-item"}
                role="presentation">
                <a
                  className={
                    (!this.state.is_auth_server_configured || this.state.pfData.length === 0)
                      ? "nav-link disabled"
                      : "nav-link active"
                  }
                  id="pills-ma-packet-tab"
                  data-toggle="pill"
                  href="#pills-ma-packet"
                  role="tab"
                  aria-controls="pills-ma-packet"
                  aria-selected="false"
                  onClick={
                    (!this.state.is_auth_server_configured || this.state.pfData.length === 0) ? undefined :
                      () =>
                        this.setState({ selectedTab: "Packet" })
                  }
                >
                  AAA Server
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-content" id="myTabContent">
              {((this.props?.userRole !== "Viewer_Group" && this.props?.userRole !== "Operator_Group") &&
                this.state.selectedTab === "Packetfence") && (
                  <div
                    className="tab-pane fade show active"
                    id="pills-ma-packetfence"
                    role="tabpanel"
                    aria-labelledby="pills-ma-packetfence-tab"
                  >
                    <AAAServer
                      setSelectedTab={() => this.setSelectedTab()}
                    />
                  </div>
                )}

              {this.state.selectedTab === "Packet" && <div
                className="tab-pane fade show active"
                id="pills-ma-packet"
                role="tabpanel"
                aria-labelledby="pills-ma-packet-tab"
              >
                <Packetfence />
              </div>}

              {this.state.selectedTab === "noData" && (
                <div className="white-bg no-data">
                  No server found for configuration.
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.show && (
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            reset={() => this.resetPopup()}
          />
        )}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
        {this.state.showRefreshModal && <ErrorHandlePopup />}
      </div >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    deviceIdWhenDropChanges: state.displayAddDevice.deviceIdWhenDropChanges,
    userRole: String(state.auth.role)
  };
};
export default withRouter(connect(mapStateToProps)(ZTNAServer));
