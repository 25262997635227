import React from 'react'
import { Graph } from "react-d3-graph";
function D3TopoLogy(props) {
  const data = {
    nodes: props.location !== undefined ? props.location.state.data :
      props.data,

    links: props.location !== undefined ? props.location.state.link :
      props.link

  };
  const myConfig = {
    "height": props.fullView ? 600 : 250,
    "width": props.fullView ? 800 : 500,
    d3: {

      gravity: -100,
      "linkLength": props.fullView ? 200 : 150,

    },
    nodeHighlightBehavior: true,
    node: {
      color: "lightgreen",
      size: 120,
      fontWeight: "bold",
    },
    link: {
      "renderLabel": true,
      fontWeight: "bold",
      fontSize: 9

    },
  };

  return (
    <div>
      <Graph
        id="graph-id" // id is mandatory


        data={data}
        config={myConfig}
        collapsible={true}
      />
    </div>
  )
}

export default React.memo(D3TopoLogy);
