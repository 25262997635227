/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Action from "../../../../redux/actionTypes";
import { debounce } from "lodash";
import { Loader } from "../../../../layOuts/Loader";
import DataTable from "react-data-table-component";
class DNSView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UpstreamServersData: [
        { title: 'None', value: 'none' },
        { title: '1.1.1.1, 1.0.0.1  (Cloudflare - Public DNS)', value: '1.1.1.1, 1.0.0.1' },
        { title: '1.1.1.2, 1.0.0.2  (Cloudflare - No Malware)', value: '1.1.1.2, 1.0.0.2' },
        { title: '1.1.1.3, 1.0.0.3  (Cloudflare - No Malware and Adult Content)', value: '1.1.1.3, 1.0.0.3' },
        { title: '208.67.222.123, 208.67.220.123  (OpenDNS - FamilyShield)', value: '208.67.222.123, 208.67.220.123' }
      ],
      selectedUpStream: "none",
      dns_data: this.props.template_schema_json?.dns_data ? this.props.template_schema_json?.dns_data : {
        UpstreamServers: ["none"],
        mode: "blacklist",
        BlDomains: [],
        WlDomains: [],
      },
      isBlackList: true,
      toggledClearRows: false,
      template_type: this.props?.templateData?.template_type || "",
      template_name: this.props?.templateData?.template_name || "",
      template_schema_json: {
        dns_data: {
          UpstreamServers: ["none"],
          mode: "blacklist",
          BlDomains: [],
          WlDomains: [],
        },
        "dns_forwarding": []
      },
      ForwardColumns: [
        {
          name: "Domain name",
          selector: "DomainName",
          cell: (row) => (
            <span title={row?.DomainName} style={{ width: "auto" }}
            >{row?.DomainName}</span>
          ),
        },
        {
          name: "Enable",
          selector: "enable",
          cell: (row) =>
          (<label className="switch gray"><input type="checkbox"
            checked={row?.enable === '1'} />
            <span className="slider round" style={{ cursor: "not-allowed" }}>
            </span>
          </label>
          ),
        },
        {
          name: "IP Address",
          selector: "ipaddr",
          cell: (row) =>
          (<span title={row?.ipaddr} style={{ width: 'auto' }}
          > {row?.ipaddr}
          </span>
          ),
        },
      ],
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json?.dns_data) {
      this.setState({
        dns_data: this.props?.template_schema_json?.dns_data,
        isBlackList: this.props?.template_schema_json?.dns_data?.mode === 'whitelist' ? false : true,
        selectedUpStream: this.props?.template_schema_json?.dns_data?.UpstreamServers?.[0],
        template_schema_json: JSON.parse(JSON.stringify(this.props?.template_schema_json))
      })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.templateData).length !== 0) {
      if (nextProps.templateData !== undefined) {
        this.setState({
          template_type: nextProps?.templateData?.template_type || "",
          template_name: nextProps?.templateData?.template_name || ""
        });
      }
    }
    if (nextProps.edit && nextProps.template_schema_json !== undefined) {
      this.setState({
        template_schema_json: JSON.parse(JSON.stringify(nextProps?.template_schema_json)),
        dns_data: nextProps?.template_schema_json?.dns_data,
        selectedUpStream: nextProps.template_schema_json.dns_data?.UpstreamServers?.[0],
        isBlackList: nextProps.template_schema_json?.dns_data?.mode === 'whitelist' ? false : true
      });
    }
  }
  closeAll = () => {
    this.props.dispatch({
      type: Action.CLOSE_APPLY_TEMPLATE,
    });
    this.props.closeAddTemplate();
  };
  checkError = () => {
    let result = false;
    if (this.props.applying)
      result = true;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (
      this.state.template_name === "" ||
      this.state.template_type === ""
    )
      result = true;
    return result;
  };
  applyTemplate = () => {
    let template_schema_json = {
      dns_data: this.state.dns_data,
      dns_forwarding: this.state.template_schema_json?.dns_forwarding ? this.state.template_schema_json?.dns_forwarding : []
    }
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(template_schema_json)
  }
  debounceApply = debounce((fn, event) => {
    fn(event)
  }, 500);
  render() {
    return (
      <div className="tab-box">
        {this.state.showLoader && <Loader />}
        {/* <div className={this.state.openAddNetwork || this.state.openReservation ? "disabled-slide" : ""}></div> */}
        <ul className="tab-nav nav nav-pills bg-gray" id="firewallTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a className="nav-link active" id="DNS-Filter-tab" data-toggle="tab" href="#DNSFiltering" role="tab" aria-controls="DNSFiltering" aria-selected="true"
            // onClick={() => this.setState({selectedTab:'DNSFiltering'})}
            >DNS Filtering</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="DNSForward-tab" data-toggle="tab" href="#DNSForward" role="tab" aria-controls="DNSForward" aria-selected="false"
            // onClick={() => this.setState({selectedTab:'DNSForwarding'})}
            >DNS Forwarding</a>
          </li>
        </ul>
        <div className="tab-content mt-2">
          <div className="tab-pane active" id="DNSFiltering" role="tabpanel" aria-labelledby="DNS-Filter-tab">
            <div>
              <div className="DNS-template">
                <div className="row">
                  <div className="form-group col-sm-6">
                    <label>Upstream DNS Servers
                      <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="All DNS request will be sent to the selected DNS servers. DHCP provided DNS server will not be used, if any server is selected.">
                        <img src="/images/svg-icons/info.svg" alt="" />
                      </div>
                    </label>
                    <select
                      disabled
                      onChange={e => this.setState({ selectedUpStream: e.target.value })}
                      value={this.state.selectedUpStream}
                      className="form-control">
                      {this.state.UpstreamServersData.filter((item) => item?.value === this.state.selectedUpStream)?.length > 0 ?
                        this.state.UpstreamServersData?.map(i => <option value={i?.value}>{i?.title}</option>) :
                        <option value={this?.state?.selectedUpStream}>{this?.state?.selectedUpStream}</option>}
                    </select>
                  </div>
                </div>
                <div className="gray-bg rounded p-2">
                  <div className="form-group mb-2">
                    <label>
                      <strong>Mode</strong>
                      <div
                        className="tooltip-c"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Select the operation mode"
                      >
                        <img src="/images/svg-icons/info.svg" alt="" />
                      </div>
                    </label>
                  </div>
                  <div className="mb-2 border-bottom pb-1">
                    <div className="d-inline-flex align-items-center">
                      <span className={`mr-3 ${this.state.isBlackList ? 'text-bold' : ''}`}>Blacklist</span>
                      <label className="switch both-cheked">
                        <input
                          type="checkbox"
                          disabled
                          name="advancedOptions"
                          checked={!this.state.isBlackList}
                          onChange={e => this.setState({ isBlackList: !e.target.checked, dns_data: { ...this.state.dns_data, mode: e.target.checked ? 'whitelist' : 'blacklist' } })} />
                        <span className="slider round"></span>
                      </label>
                      <span className={`ml-3 ${!this.state.isBlackList ? 'text-bold' : ''}`}>Whitelist</span>
                    </div>
                  </div>
                  <p className="mb-1 mt-1">
                    Blacklist
                    <div
                      className="tooltip-c"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Added domains will be blocked from resolving the DNS"
                    >
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </p>
                  <div className={`chip-list-border ${!this.state?.isBlackList ? 'disabled-chip' : ''}`}>
                    {this.state?.dns_data?.BlDomains?.map(i => <div className="chip-set" id={i}>
                      {i} <img src="/images/svg-icons/cancel.svg" className="disabled" id={i} alt="" />
                    </div>)}
                  </div>
                  <p className="mb-1 mt-3">
                    Whitelist
                    <div
                      className="tooltip-c"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Added domains will be allowed to resolve the DNS. All other domains will be blocked from resolving the DNS"
                    >
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </p>
                  <div className={`chip-list-border ${this.state.isBlackList ? 'disabled-chip' : ''}`}>
                    {this.state?.dns_data?.WlDomains?.map(i => <div className="chip-set" id={i}>
                      {i} <img src="/images/svg-icons/cancel.svg" className="disabled" id={i} alt="" />
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane" id="DNSForward" role="tabpanel" aria-labelledby="DNSForward-tab">
            <div className="page-sub-title border-0 pb-0">
              <div><strong>DNS Forwarding</strong>
              </div>
            </div>
            <div key={this.state.keyUpdateRes}
            >
              {this.state.template_schema_json?.dns_forwarding?.length > 0 &&
                <DataTable
                  columns={this.state.ForwardColumns}
                  data={this.state.template_schema_json?.dns_forwarding?.filter(item => item?.isdeleted !== "1")}
                  selectableRows={false}
                  fixedHeader
                  onSelectedRowsChange={(data) => this.multipleSelect(data)}
                  clearSelectedRows={this.state.toggledClearRows}
                />}
            </div>
          </div></div>
        {!this.props.view &&
          <div className="s-footer">
            {/* 1665 reapply page issue */}
            <button
              className="btn btn-light"
              onClick={
                this.props.apply && !this.props.edit && !this.props.reapply
                  ? () => this.props.closeAddTemplate()
                  : () => this.props.closeApplyTemplate()
              }
            >
              {/* 1665 reapply page issue */}
              {this.props.apply && !this.props.reapply
                ? "Back"
                : "Cancel"}
            </button>
            <button
              className="btn btn-primary"
              disabled={this.checkError()}
              onClick={
                this.props.apply
                  ? (event) => {
                    this.debounceApply(this.applyTemplate, event)
                  }
                  : ""
              }
            >
              {this.props.apply
                ? "Apply"
                : "Save"}
            </button>
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
  }
};
export default connect(mapStateToProps)(DNSView);
