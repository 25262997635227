import React, { Component } from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Cookies from 'universal-cookie';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import * as Action from '../../redux/actionTypes';
import { Loader } from '../../layOuts/Loader';
import {Buffer} from 'buffer';

const cookies = new Cookies();

class Login extends Component {

    constructor(props) {
        super(props);
        // Setting the initial state
        this.state = {
            username: '',
            password: '',
            rememberMeChecked: false,
            title: '',
            message: '',
            showError: false,
            showLoader: false,
            changed: false,
            showPassword: false
        };

        this.rememberMe = cookies.get('rememberMe');
        if (this.rememberMe) {
            this.rememberMe = JSON.parse(atob(this.rememberMe));
            this.state = {
                username: this.rememberMe,
                changed: false
            };
        }
        this.state = {
            rememberMeChecked: !!this.rememberMe,
        };
    }
    static getDerivedStateFromProps = (props, state) => {
        if (props.loginResult) {
            props?.history?.push("/dashboard");
        }
        let rememberMe = cookies.get('rememberMe');
        if (state.rememberMeChecked && rememberMe && !state.changed) {
            rememberMe = JSON.parse(atob(rememberMe));
            state.username = rememberMe;
        }
        return state;
    }
    // Function to check or uncheck Remember Me checkbox
    onClickRemember = (e) => this.setState({
        rememberMeChecked: !this.state.rememberMeChecked,
    });

    //Function call on input field change
    setValue(e) {
        var value = e.target.value;
        var name = e.target.name;
        this.setState({ [name]: value, changed: true, showError: false });
    }
    handleKeyPress = (e) => {
        if (e.key === 'Enter' && this.state.username !== '' && this.state.password !== '') {
            this.saveData();
        }
    }
    saveData = () => {
        const data = Buffer.from(`${this.state.username}:${this.state.password}`, 'utf8').toString('base64');
        this.setState({
            showLoader: true
        })
        ApiCall(urlMapping.login(data), (result) => {
            this.setState({
                showLoader: false
            })
            if (result?.access) {
                localStorage.setItem("token", result.access);
                localStorage.setItem("refresh", result?.refresh);
                localStorage.setItem("username", this.state.username)
                cookies.set('showShellAccessUI', process.env.REACT_APP_SHOW_SHELL_ACCESS_UI);
                if (this.state.rememberMeChecked) {
                    cookies.set('rememberMe', btoa(JSON.stringify(this.state.username)));
                }
                else {
                    cookies.remove('rememberMe');
                }
                this.props.dispatch({
                    type: Action.LOGIN_SUCCESS,
                    superuser: result?.is_superuser,
                    user: result?.user,
                    role: result?.role || ""
                });
            } else {
                this.setState({
                    title: 'Login',
                    message: result?.response?.data?.detail || "Something went wrong. Please try again later.",
                    showError: true
                })
            }
        });
    }
    showPass = () => {
        const x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }
    render() {
        return (
            <div className="login-box">
                {this.state.showLoader && <Loader />}
                <div className="login-nav d-flex justify-content-between">
                    <div className="logo-box">
                        <img src="/images/logo.png" alt="" />
                        <div className="logo-txt"> 5G SD EDGE Manager </div>
                    </div>
                    {/* <ul>
                        <li><button>Help</button></li>
                        <li><button>Privacy</button></li>
                        <li><button>Terms</button></li>
                    </ul> */}
                </div>
                <div className="container-fluid h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-md-6 d-none d-md-block"></div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="login-form-box">

                                <h1>Login</h1>
                                {this.state.showError && <div className="text-danger mb-3">{this.state.message}</div>}
                                <div className="login-form">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input autoFocus={!this.state.rememberMeChecked} className="form-control" type="text" placeholder="" maxLength="120"
                                            defaultValue={this.state.username} name="username" id="username"
                                            onChange={(e) => this.setValue(e)} onKeyPress={(e) => this.handleKeyPress(e)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input autoFocus={this.state.rememberMeChecked} className="form-control" type="password" id="password" placeholder="" maxLength="120"
                                            defaultValue={this.state.password} name="password" onChange={(e) => this.setValue(e)} onKeyPress={(e) => this.handleKeyPress(e)} />
                                        <div className="sh-icon">
                                            <img src={this.state.showPassword ? "/images/show.png" : "/images/hide.png"} alt="" onClick={() => this.showPass()} />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-4">
                                        <div>
                                            <label className="checkbox">
                                                <input type="checkbox" checked={this.state.rememberMeChecked} onChange={this.onClickRemember} tabIndex="-1" />
                                                <div className="check"></div> Remember me
                                            </label>
                                        </div>
                                        {/* <div>
                                            <button className='bg-none' tabIndex="-1">Forgot password?</button>
                                        </div> */}
                                    </div>
                                    <button className="btn btn-primary btn-block"
                                        onClick={() => this.saveData()}
                                        disabled={!this.state.username || !this.state.password}>Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        loginResult: state.auth.authenticated,
    });
}
export default withRouter(connect(mapStateToProps)(Login));