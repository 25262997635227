import React, { Component } from 'react'
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import moment from "moment";
import ErrorHandlePopup from '../ErrorHandlePopup';
import { Loader } from '../../layOuts/Loader';
import $ from 'jquery';
import { ErrorPopup } from '../../layOuts/errorPopup';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Calendar } from 'primereact/calendar';
import DataTable from "react-data-table-component";
var interval = null
export default class Chronology extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device_id: this?.props?.id || "",
            device_mac: this?.props?.device_mac || "",
            pageNo: 1,
            pageSize: 10,
            first: 1,
            last: 10,
            next: true,
            prev: true,
            count: 0,
            chronologyData: [],
            showLoader: false,
            message: '',
            emptyData: false,
            operationData: "",
            isAxiosError: false,
            showIcon: false,
            showFilter: false,
            type: "",
            severityType: "",
            operationStatus: "",
            startDate: "",
            endDate: "",
            columns: [
                {
                    name: "Timestamp",
                    selector: "start_time",
                    value: "Timestamp",
                    cell: (row) => (
                        <span
                            title={this.getTimeExact(row?.operation_start_time)}
                            style={{ width: 'auto' }}
                        >
                            {this.getTimeExact(row?.operation_start_time)}
                        </span>
                    ),
                },
                {
                    name: (
                        <div>
                            Event/Operation Name
                            <div className="tooltip-c"
                                data-toggle="tooltip"
                                data-placement="right"
                                title={"E-Events, OP-Operations"}>
                                <img src="/images/svg-icons/info.svg" alt="" />
                            </div>
                        </div>
                    ),
                    selector: "event_or_operation_name",
                    value: "Event/Operation Name",
                    minWidth: "450px",
                    cell: (row) => (
                        <span
                            title={row?.device_operation_name || row?.event_name || "-"}
                            style={{ display: 'flex', alignItems: 'center' }}                        >
                            <span
                                style={{
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexShrink: 0,
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '50%',
                                    backgroundColor: 'blue',
                                    color: 'white',
                                }}
                            >
                                {row?.event_name ? "E" : "OP"}
                            </span>
                            <span>
                                &nbsp;
                                {row?.device_operation_name || row?.event_name || "-"}
                            </span>
                        </span>
                    ),
                },
                {
                    name: "Operation Type / Event Severity",
                    selector: "operation_type_or_severity",
                    value: "Operation Type / Event Severity",
                    minWidth: "210px",
                    cell: (row) => (
                        <span
                            title={row?.device_operation_type || this.returnSeverity(row?.severity) || "-"}
                            style={{ width: 'auto' }}
                        >
                            {row?.device_operation_type
                                ? row?.device_operation_type
                                : this.returnSeverity(row?.severity) || "-"}
                        </span>
                    ),
                },
                {
                    name: "Diff from Previous",
                    selector: "diff_from_previous",
                    value: "Diff from Previous",
                    center: true,
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                    },
                    cell: (row) => (
                        <div style={{ width: 'auto', display: 'flex', justifyContent: 'center' }}>
                            {(
                                row?.device_operation_type &&
                                this.returnTemplate(row?.device_operation_type)
                            ) ?
                                <button
                                    className='btn btn-primary'
                                    onClick={() => this.getOperation(row)}
                                    style={{ whiteSpace: 'nowrap' }}
                                    disabled={this.state.toggleStatus === true}
                                >
                                    Diff from Previous
                                </button>
                                : <span style={{}}>-</span>}
                        </div>
                    ),
                },
                {
                    name: "Status",
                    selector: "status",
                    value: "Status",
                    cell: (row) => (
                        <span
                            title={row?.cmd_status || "-"}
                            style={{ width: 'auto' }}
                        >
                            {row?.cmd_status || "-"}
                        </span>
                    ),
                }
            ]
        }
    };

    componentDidMount = () => {
        if (this.props?.id) {
            this.setState({
                device_id: this?.props?.id || "",
                device_mac: this?.props?.device_mac || "",
                pageNo: 1,
                pageSize: 10,
            }, () => {
                this.getChronology()
            })
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.id !== this.props.id) {
            this.setState({
                device_id: nextProps?.id || "",
                device_mac: nextProps?.device_mac || "",
                pageNo: 1
            }, () => {
                this.getChronology()
            })
        }
    };

    getChronology = () => {
        this.setState({
            showLoader: true
        })
        const { device_id, pageNo, pageSize, type, severityType, operationStatus, startDate, endDate } = this.state;
        const data = {
            device_id,
            pageNo,
            pageSize,
            ...(type !== "" && { type }),
            ...(severityType !== "" && { severityType }),
            ...(operationStatus !== "" && { operationStatus }),
            ...(startDate !== "" && { startDate: parseInt(new Date(startDate).getTime().toString().slice(0, 10)) }),
            ...(endDate !== "" && { endDate: parseInt(new Date(endDate).getTime().toString().slice(0, 10)) }),
        };
        ApiCall(urlMapping.getChronology(data), (response) => {
            if (response?.results) {
                const count = response?.count;
                const first = this.state.pageSize * (this.state.pageNo - 1) + 1;
                const last = response?.next
                    ? this.state.pageSize * this.state.pageNo
                    : response?.count;
                const startValue = (pageNo - 1) * pageSize + 1;
                const updatedResults = response?.results.map((item, index) => ({
                    ...item,
                    s_no: String(parseInt(startValue) + parseInt(index))
                }));
                this.setState({
                    chronologyData: updatedResults,
                    count: count,
                    next: response?.next ? false : true,
                    prev: response?.previous ? false : true,
                    first: first,
                    last: last,
                    showLoader: false,
                    showFilter: false
                })
            }
            else {
                this.setState({
                    showIcon: false,
                    showFilter: false,
                    pageNo: 1,
                    type: "",
                    severityType: "",
                    operationStatus: "",
                    startDate: "",
                    endDate: "",
                    chronologyData: []
                })
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    })
                }
            }
        })
    };

    getTimeExact = (time) => {
        if (time) {
            let date = moment(time);
            date = date.tz(moment.tz.guess());
            return date.format('DD MMMM YYYY HH:mm:ss A');
        }
        return "-";
    };

    resetValue = () => {
        this.setState({
            showIcon: false,
            showFilter: false,
            pageNo: 1,
            type: "",
            severityType: "",
            operationStatus: "",
            startDate: "",
            endDate: ""
        }, () => this.getChronology())
    };

    returnTemplate = (templates) => {
        switch (templates) {
            case "StaticRoute":
            case "OSPFConfiguration":
            case "BGPConfiguration":
            case "FirewallConfiguration":
            case "WANConfiguration":
            case "NetworkConfiguration":
            case "WiFiConfiguration":
            case "QoSConfiguration":
            case "TriggerConfiguration":
            case "TunnelConfiguration":
            case "DeviceProvision":
            case "DNSFiltering":
            case "SystemConfiguration":
            case "ModemConfiguration":
            case "CaptivePortal":
                //EMGT-2504 Diff from previous required for tunnels operations
            case "Update Tunnel":
            case "IPSecConfiguration":
            case "Create Tunnel":
                return true;
            default:
                return false;
        }
    };

    returnSeverity = (severity) => {
        switch (severity) {
            case "0":
            case "1":
                return "Information";
            case "2":
                return "Low"
            case "3":
                return "Medium"
            case "4":
                return "High"
            case "5":
                return "Critical"
            default:
                return "-";
        }
    };

    setStartTime = (e) => {
        this.setState({
            startDate: e.value ? e.value : '',
            endDate: ''
        })
    };

    setEndTime = (e) => {
        this.setState({
            endDate: e.value ? e.value : '',
        })
    };

    checkApply = () => {
        let result = false;
        if (
            this.state.type === '' &&
            this.state.startDate === '' &&
            this.state.endDate === ''
        ) {
            result = true;
        }
        if (this.state.startDate !== '' &&
            this.state.endDate === '') {
            result = true;
        }
        return result;
    };

    getOperation = (row) => {
        this.setState({ showLoader: true })
        let data = {
            device_mac: this.state.device_mac,
            operation_type: row.device_operation_type,
            operation_start_date: row.operation_start_time
        }
        ApiCall(urlMapping.getOperationsDelta(data), (response) => {
            if (response?.message) {
                this.setState({
                    emptyData: true,
                    showLoader: false,
                    message: response?.message
                }, () => {
                    $("#operation").modal("show");
                })
            }
            else {
                if (response?.success) {
                    this.setState({
                        emptyData: false,
                        operationData: response?.data,
                        showLoader: false,
                        message: ''
                    }, () => {
                        $("#operation").modal("show");
                    })
                }
                else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true, showLoader: false });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            showLoader: false,
                        })
                    }
                }
            }
        })
    };

    paginationChange = (e) => {
        this.setState({
            pageNo: e
        }, () => this.getChronology());
    }

    pageSizeChange = (e) => {
        this.setState({
            pageSize: e
        }, () => this.getChronology());
    }

    manageAutoRefresh = (e) => {
        if (e.target.checked === true) {
            interval = setInterval(() => {
                this.setState({ toggleStatus: true })
                this.getChronology();
            }, 5000)
        } else {
            clearInterval(interval)
            interval = null
            this.setState({ toggleStatus: false, keyUpdate: Math.random() })
        }
    }

    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div className="white-bg page-height">
                    <div className="ta-box justify-content-end">
                        <div className="action-btn">
                            <button
                                onClick={() => this.getChronology()}>
                                <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>
                            <div className="btn-group">
                                <button
                                    type="button"
                                    className="dropdown-toggle  filter-btn"
                                    onClick={() => this.setState({
                                        showFilter: true
                                    })}
                                    disabled={this.state.toggleStatus === true}
                                >
                                    {(this.state.showIcon) ?
                                        <img src="/images/svg-icons/info-icon.svg" alt="" />
                                        :
                                        <img src="/images/svg-icons/filter.svg" alt="" />
                                    }
                                    Filter
                                </button>
                                {this.state.showFilter && <div
                                    className={
                                        this.state.showFilter
                                            ? "dropdown-menu show  dropdown-menu-right filter-section width360 overflow-visible"
                                            : "dropdown-menu dropdown-menu-right filter-section width360 overflow-visible"
                                    }
                                    id="parentDiv"
                                >
                                    <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <label>Type: </label>
                                        </div>
                                        <select
                                            className='form-control'
                                            value={this.state.type}
                                            onChange={(e) => this.setState({
                                                type: e.target.value,
                                                severityType: "",
                                                operationStatus: ""
                                            })}>
                                            <option value="">Select</option>
                                            <option value="operations">Operations</option>
                                            <option value="events">Events</option>
                                        </select>
                                    </div>
                                    {this.state.type === "events"
                                        && <div className="form-group">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label>Severity: </label>
                                            </div>
                                            <select
                                                className='form-control'
                                                value={this.state.severityType}
                                                onChange={(e) =>
                                                    this.setState({
                                                        severityType: e.target.value
                                                    })
                                                }>
                                                <option value="">Select</option>
                                                <option value="1">Information</option>
                                                <option value="2">Low</option>
                                                <option value="3">Medium</option>
                                                <option value="4">High</option>
                                                <option value="5">Critical</option>
                                            </select>
                                        </div>}
                                    {this.state.type === "operations"
                                        && <div className="form-group">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label>Operation Status: </label>
                                            </div>
                                            <select
                                                className='form-control'
                                                value={this.state.operationStatus}
                                                onChange={(e) => this.setState({
                                                    operationStatus: e.target.value
                                                })}>
                                                <option value="">Select</option>
                                                <option value="Successful">Successful</option>
                                                <option value="Failed">Failed</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Cancelled">Cancelled</option>
                                            </select>
                                        </div>}
                                    <div className="d-flex">
                                        <div className="form-group mr-2" id="schedulerDiv">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label>Start Time</label>
                                            </div>
                                            <div className="pt-2">
                                                <Calendar
                                                    id="navigatorstemplate"
                                                    value={this.state.startDate}
                                                    onChange={(e) => this.setStartTime(e)}
                                                    showTime
                                                    hourFormat="12"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    hideOnDateTimeSelect={true}
                                                    yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group ml-2" id="schedulerDiv">
                                            <div className={this.state.startDate ? "d-flex align-items-center justify-content-between" : "disabled"}>
                                                <label>End Time
                                                    {this.state.startDate !== "" && <span className="red-txt">*</span>}
                                                </label>
                                            </div>
                                            <div className="pt-2">
                                                <Calendar
                                                    id="navigatorstemplate"
                                                    value={this.state.endDate}
                                                    onChange={(e) => this.setEndTime(e)}
                                                    disabled={this.state.startDate === '' ? true : false}
                                                    showTime
                                                    hourFormat="12"
                                                    monthNavigator={true}
                                                    yearNavigator={true}
                                                    hideOnDateTimeSelect={true}
                                                    yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                                                    minDate={this.state.startDate}
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="action-box">
                                        <button
                                            className="btn btn-light"
                                            onClick={() => this.resetValue()}
                                        >
                                            Reset
                                        </button>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => this.setState({ pageNo: 1, showIcon: true }, () => this.getChronology())}
                                            disabled={this.checkApply()}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>}
                            </div>
                            <div className="auto-refresh">
                                <label className="switch mr-2 ml-3">
                                    <input type="checkbox" onClick={(e) => this.manageAutoRefresh(e)}></input>
                                    <span className="slider round"></span>
                                </label>
                                <span>Auto Refresh</span>
                            </div>
                        </div>
                    </div>
                    <div className="card" key={this.state.keyUpdate}>
                        <DataTable
                            columns={this.state.columns}
                            data={this.state.chronologyData}
                            highlightOnHover
                            fixedHeader
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.count}
                            paginationDefaultPage={this.state.pageNo}
                            onChangePage={(e) => this.paginationChange(e)}
                            onChangeRowsPerPage={(e) => this.pageSizeChange(e)}
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        />
                    </div>
                </div>
                <div
                    className="modal fade operation"
                    id="operation"
                    tabIndex="-1"
                    data-backdrop="static"
                    role="dialog"
                    aria-labelledby="operation"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-lg modal-dialog-centered"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Operation Details</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        <img src="/images/svg-icons/cancel.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            {this.state.emptyData ?
                                <div className="modal-body pt-5">
                                    <div className="error-msg">
                                        <div className="txt1">{this.state.message}</div>
                                    </div>
                                </div>
                                :
                                <div className="modal-body pt-5 operationDetailsTabel">
                                    <table class="table table-striped ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Key</th>
                                                <th scope="col">Old Value</th>
                                                <th scope="col">New Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this?.state?.operationData && Object.keys(this?.state?.operationData).map((item) => {
                                                    return (
                                                        <tr>
                                                            <th scope="row">{item.replaceAll("][", "-").replaceAll("root", "").replaceAll('[', "").replaceAll(']', "")}</th>
                                                            <td>{this?.state?.operationData?.[item]?.old_value ? this?.state?.operationData?.[item]?.old_value : '-'}</td>
                                                            <td>{this?.state?.operationData?.[item]?.new_value ? this?.state?.operationData?.[item]?.new_value : '- '}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>}
                            <div className="modal-footer border-top-0 justify-content-center pb-4">
                                <button type="button" className="btn btn-primary"
                                    data-dismiss="modal">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div >
        )
    }
};