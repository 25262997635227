import React, { Component } from "react";
import urlMapping from "../../../redux/API/api";
import apiCall from "../../../redux/API/apiCall";
import { Loader } from "../../../layOuts/Loader";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { ErrorPopup } from "../../../layOuts/errorPopup";

export default class DeviceGroupTunnelView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id || "",
      wireguardData: [],
      ipsecData: [],
      selectedTab: "Wireguard",
      showLoader: false,
      isAxiosError: false,
    };
  };

  componentDidMount() {
    this.getTunnelsView();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.state.id) {
      this.setState(
        {
          id: nextProps.id,
        },
        () => this.getTunnelsView()
      );
    }
  };

  getTunnelsView = () => {
    const data = {
      group_id: this.state.id,
    };
    this.setState({ showLoader: true });
    apiCall(urlMapping.getGroupTunnelsView(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        const { wireguard_tunnels, ipsec_tunnels } = response?.data || {};
        this.setState({
          wireguardData: wireguard_tunnels || [],
          ipsecData: ipsec_tunnels || [],
          showLoader: false
        });
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true });
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          let tunnelsData = [];
          this.setState({ tunnelsData });
        }
      }
    });
  };

  render() {
    return (
      <>
        {this.state.showLoader && <Loader />}
        <div className="ta-box set-pos mb-0">
          <div className="action-btn d-flex align-items-center">
            <button onClick={() => this.getTunnelsView()}>
              <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
            </button>
          </div>
        </div>
        <div className="no-bg page-height">
          <div className="page-header device-head">
            <ul
              className="tab-nav nav nav-pills troubleshootResponsive ml-0 mr-0"
              id="pills-tab"
              role="tablist"
            >
              {
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="pills-wireguard-tab"
                    data-toggle="pill"
                    href="#pills-wireguard"
                    role="tab"
                    aria-controls="pills-wireguard"
                    aria-selected="true"
                    onClick={() =>
                      this.setState({
                        selectedTab: "Wireguard",
                      })
                    }
                  >
                    Wireguard
                  </a>
                </li>
              }
              {
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="pills-ipsec-tab"
                    data-toggle="pill"
                    href="#pills-ipsec"
                    role="tab"
                    aria-controls="pills-ipsec"
                    aria-selected="false"
                    onClick={() => this.setState({ selectedTab: "IPSec" })
                    }
                  >
                    IPSec
                  </a>
                </li>
              }
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-content" id="myTabContent">
              <div
                className={this.state.selectedTab === "Wireguard" ? "tab-pane fade show active" : "tab-pane fade show"}
                id="pills-diagnostic"
                role="tabpanel"
                aria-labelledby="pills-diagnostic-tab"
              >
                {
                  this.state.wireguardData?.length > 0
                    ? (
                      <>
                        <div className="white-bg pl-2 pr-2">
                          <div className="networks-view">
                            <div className="row no-gutters">
                              {this.state.wireguardData?.map((item, index) => {
                                return (
                                  <div key={index} className="col-md-3 mb-3">
                                    <div className="network-card rounded">
                                      <div className="network-header tunnel-head rounded-top d-flex justify-content-between p-2">
                                        <div className="">
                                          <Link
                                            to={{
                                              pathname: "/tunnels",
                                              state: {
                                                selectedHub: item?.hub_mac,
                                                group_id: this.state?.id
                                              },
                                            }}
                                            className="txt-blue"
                                          >
                                            Detail View
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="p-2 tunnel-body">
                                        <ul>
                                          <li className="system-connection dotted-line">
                                            <span className="">
                                              <img
                                                src={
                                                  "/images/svg-icons/hub.svg"
                                                } alt=""
                                              />
                                              <div>{item?.hub_name || "-"}</div>
                                            </span>
                                            <span>
                                              <img
                                                src={
                                                  "/images/svg-icons/computer.svg"
                                                } alt=""
                                              />
                                              <div>{item?.group_name || "-"}</div>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                    : (
                      <div className="white-bg pl-2 pr-2 no-tunnels">
                        No tunnels found for this device group
                      </div>
                    )
                }
              </div>
              <div
                className={this.state.selectedTab === "IPSec" ? "tab-pane fade show active" : "tab-pane fade show"}
                id="pills-ipsec"
                role="tabpanel"
                aria-labelledby="pills-ipsec-tab"
              >
                {
                  this.state.ipsecData?.length > 0
                    ? (
                      <>
                        <div className="white-bg pl-2 pr-2">
                          <div className="networks-view">
                            <div className="row no-gutters">
                              {this.state.ipsecData?.map((item, index) => {
                                return (
                                  <div key={index} className="col-md-3 mb-3">
                                    <div className="network-card rounded">
                                      <div className="network-header tunnel-head rounded-top d-flex justify-content-between p-2">
                                        <div className="">
                                          <Link
                                            to={{
                                              pathname: "/tunnels",
                                              state: {
                                                selectedHub: item?.hub_mac,
                                                group_id: this.state?.id
                                              },
                                            }}
                                            className="txt-blue"
                                          >
                                            Detail View
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="p-2 tunnel-body">
                                        <ul>
                                          <li className="system-connection dotted-line">
                                            <span className="">
                                              <img
                                                src={
                                                  "/images/svg-icons/hub.svg"
                                                } alt=""
                                              />
                                              <div>{item?.hub_name || "-"}</div>
                                            </span>
                                            <span>
                                              <img
                                                src={
                                                  "/images/svg-icons/computer.svg"
                                                } alt=""
                                              />
                                              <div>{item?.group_name || "-"}</div>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                    : (
                      <div className="white-bg pl-2 pr-2 no-tunnels">
                        No tunnels found for this device group
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </div>
        {
          this.state.isAxiosError
          &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </>
    );
  }
};