/* eslint-disable array-callback-return */
import React, { Component } from "react";
import $ from 'jquery';
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { Loader } from '../../../layOuts/Loader';
import { ErrorPopup } from '../../../layOuts/errorPopup';
import * as Action from '../../../redux/actionTypes';
import * as Common from '../../../utils/common';
import { Link } from 'react-router-dom';
import ErrorHandlePopup from "../../ErrorHandlePopup";
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
class WiFiTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            addSSID: false,
            multipleSelect: "",
            toggledClearRows: false,
            keyUpdate: "",
            title: '',
            message: '',
            update: false,
            show: false,
            error: false,
            delete: false,
            showError: false,
            hide: true,
            showPassword: false,
            showLink: false,
            networkViewData: [],
            templateID: '',
            enabledSSID: [],
            enabled: false,
            selectedTemplateId: this.props.id ? this.props.id : '',
            template_type: this.props?.templateData?.template_type || '',
            template_name: this.props?.templateData?.template_name || '',
            template_desc: this.props?.templateData?.template_desc || '',
            device_model: this.props?.templateData?.device_model || '',
            collection_version: this.props?.templateData?.collection_version || '',
            fw_version: this.props?.templateData?.fw_version || '',
            chimay: this.props?.chimay,
            cbr: this.props?.cbr,
            mifi: this.props?.mifi,
            "auth_server": "",
            "auth_port": "",
            "auth_secret": "",
            servers: [],
            server_data: [],
            isAxiosError: false,
            configId: "",
            templateData: {
                "radio": {
                    "twog": {
                        "mode": "11axg",
                        "channel": "auto",
                        "bw": "HT20",
                        "enable": "1"
                    },
                    "fiveg": {
                        "mode": "11axa",
                        "channel": "auto",
                        "bw": "HT80",
                        "enable": "1"
                    }
                },
                "ssids": []
            },
            ssidData: {
                "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "radio": "both",
                "network": "",
                "hidden": "0",
                "enabled": "1",
                "ssid": "",
                "encryption": "none",
                "password": "",
                "isolate": "0",
                "isdeleted": "0",
                "isedited": "0",
                "auth_server": "",
                "auth_port": "",
                "auth_secret": "",
                "radius_server": "",
                "prim_auth_server": "",
                "prim_auth_port": "",
                "prim_auth_secret": "",
                "sec_auth_server": "",
                "sec_auth_port": "",
                "sec_auth_secret": "",
            },
            columns: [
                {
                    name: "SSID ",
                    selector: "ssid",
                    cell: (row) =>
                    (<span
                        title={row.ssid}
                        style={{ width: 'auto' }}
                        className={(this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' || this.props.selectedModel === "Stormbreaker")
                            && !this.state.enabledSSID.includes(row.id) ? "" : "cursor-pointer"}
                    >
                        {(this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' ||
                            this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm')
                            && !this.state.enabledSSID.includes(row.id) ? row.ssid :
                            <Link to={{}} className="txt-blue" onClick={() => this.openSSID(row)}> {row.ssid}</Link>}
                    </span>
                    ),
                },
                {
                    name: "Security ",
                    selector: "encryption",
                    cell: (row) => (<span title={Common.getEncrytionValue(row.encryption)}>
                        {Common.getEncrytionValue(row.encryption)}</span>
                    ),
                },
                {
                    name: "Passphrase ",
                    selector: "password",
                    cell: (row) => (<span className={row.password !== "" ? "mask" : ""}>
                        {(row.password !== "" ? row.password : '-')}</span>
                    ),
                },
                {
                    name: "Broadcast ",
                    selector: "hidden",
                    maxWidth: "20px",
                    cell: (row) => (
                        <label className="switch">
                            <input type="checkbox"
                                checked={row.hidden === "0"}
                            />
                            <span className="slider round"></span>
                        </label>
                    )
                },
                {
                    name: "Radio ",
                    selector: "radio",
                    maxWidth: "10px",
                    cell: (row) => (<span title={Common.getRadioValues(row.radio)}>
                        {Common.getRadioValues(row.radio)}</span>
                    ),
                },
                {
                    name: "Enable ",
                    selector: "enabled",
                    cell: (row) => (
                        <label className="switch">
                            <input type="checkbox"
                                checked={row.enabled === "1"}
                            />
                            <span className="slider round"></span>
                        </label>
                    )
                },
            ]
        }
    }
    componentDidMount() {
        this.getConfigInfo();
        this.getExternalServers();
        if (this.props.template_schema_json !== undefined) {
            this.setState({
                templateData: this.undefinedValueCheck(JSON.parse(JSON.stringify(this.props.template_schema_json))),
            });
            if (this.props.edit && this.props.networkViewData?.length > 0) {
                const { columns } = this.state;
                let networkViewData = this.props.networkViewData.filter(item =>
                    (item.network_zone === 'lan' && item.bridgeStatus === 'Enabled'));
                if (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' ||
                    this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm')
                    this.filterSSID(this.undefinedValueCheck(this.props.template_schema_json));
                networkViewData = (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' ||
                    this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm') ?
                    networkViewData.filter(item => item.name === 'Default') : networkViewData;
                this.setState({
                    networkViewData
                }, () => {
                    const index = columns.findIndex(item => item.name === 'Network Name');
                    if (index < 0) {
                        columns.splice(1, 0,
                            {
                                name: "Network Name",
                                selector: "network",
                                minWidth: '150px',
                                cell: (row) => {
                                    return (
                                        <select
                                            className="form-control"
                                            value={row.network} id={row.id}
                                            onChange={(e) => this.handleChange(e, row)}>
                                            <option value="">Select Network</option>
                                            {
                                                (this.state.networkViewData).map((item) => {
                                                    return (<option value={item.networkName}>{item.name}</option>)
                                                })
                                            }
                                        </select>)
                                }
                            })
                    }
                    this.setState({
                        columns,
                        keyUpdate: Math.random(),
                    })
                })
            }
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.templateData).length !== 0) {
            this.setState({
                selectedTemplateId: nextProps.id ? nextProps.id : '',
                template_type: nextProps?.templateData?.template_type || "",
                template_name: nextProps?.templateData?.template_name || "",
                template_desc: nextProps?.templateData?.template_desc || "",
                device_model: nextProps?.templateData?.device_model || "",
                collection_version: nextProps?.templateData?.collection_version || "",
                fw_version: nextProps?.templateData?.fw_version || ""
            });
            if (nextProps.networkViewData !== this.props.networkViewData) {
                if (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' ||
                    this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm')
                    //EMGT-20-EM - Cancel operation issue in Wifi templates
                    this.filterSSID(this.undefinedValueCheck(nextProps.template_schema_json));
                this.setState({
                    networkViewData: nextProps.networkViewData.filter(item => (item.network_zone === 'lan' && item.bridgeStatus === 'Enabled'))
                }, () => {
                    const { columns } = this.state;
                    const index = columns.findIndex(item => item.name === 'Network Name');
                    if (index < 0) {
                        columns.splice(1, 0, {
                            name: "Network Name",
                            selector: "network",
                            minWidth: '150px',
                            cell: (row) => {
                                return (
                                    <select
                                        className="form-control"
                                        value={row.network}
                                        id={row.id}
                                        onChange={(e) => this.handleChange(e, row)}>
                                        <option value="">Select Network</option>
                                        {
                                            (this.state.networkViewData).map((item) => {
                                                return (<option value={item.networkName}>{item.name}</option>)
                                            })
                                        }
                                    </select>)
                            }
                        })
                    }
                    this.setState({
                        columns,
                        keyUpdate: Math.random()
                    })
                })
            }
        }
        if (nextProps.edit && nextProps.template_schema_json !== undefined &&
            nextProps.template_schema_json !== this.props.template_schema_json) {
            this.setState({
                templateData: this.undefinedValueCheck(nextProps.template_schema_json)
            });
        }
        if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
            !isEqual(this.props?.cbr, nextProps?.cbr) ||
            !isEqual(this.props?.mifi, nextProps?.mifi)) {
            this.setState({
                chimay: nextProps.chimay,
                cbr: nextProps.cbr,
                mifi: nextProps.mifi
            })
        }
    }
    undefinedValueCheck = (value) => {
        if (value) {
            let { templateData } = this.state;
            templateData.radio.twog.mode = value?.radio?.twog?.mode || "11axg";
            templateData.radio.twog.channel = value?.radio?.twog?.channel || "auto";
            templateData.radio.twog.bw = value?.radio?.twog?.bw || "HT20";
            templateData.radio.twog.enable = value?.radio?.twog?.enable || "1";
            templateData.radio.fiveg.mode = value?.radio?.fiveg?.mode || "11axa";
            templateData.radio.fiveg.channel = value?.radio?.fiveg?.channel || "auto";
            templateData.radio.fiveg.bw = value?.radio?.fiveg?.bw || "HT80";
            templateData.radio.fiveg.enable = value?.radio?.fiveg?.enable || "1";
            templateData.ssids = [];
            Array.isArray(value?.ssids) && value?.ssids?.map((item) => {
                if (item) {
                    let ssids = {
                        "id": item?.id || Math.random().toString(36).substring(2, 11).toUpperCase(),
                        "radio": item?.radio || "both",
                        "network": item?.network || "",
                        "hidden": item?.hidden || "0",
                        "enabled": item?.enabled || "1",
                        "ssid": item?.ssid || "",
                        "encryption": item?.encryption || "none",
                        "password": item?.password || "",
                        "isolate": item?.isolate || "0",
                        "isdeleted": item?.isdeleted || "0",
                        "isedited": item?.isedited || "0",
                        "auth_server": item?.auth_server || "",
                        "auth_port": item?.auth_port || "",
                        "auth_secret": item?.auth_secret || "",
                        "radius_server": item?.radius_server || "",
                        "prim_auth_server": item?.prim_auth_server || "",
                        "prim_auth_port": item?.prim_auth_port || "",
                        "prim_auth_secret": item?.prim_auth_secret || "",
                        "sec_auth_server": item?.sec_auth_server || "",
                        "sec_auth_port": item?.sec_auth_port || "",
                        "sec_auth_secret": item?.sec_auth_secret || ""
                    }
                    templateData.ssids.push(ssids);
                }
            })
            return templateData;
        }
    }
    filterSSID = (template_schema_json) => {
        let enabledSSID = [];
        if (template_schema_json.ssids.length > 0) {
            enabledSSID = [template_schema_json.ssids[0].id];
            if (template_schema_json.ssids[0].radio === '2g' && template_schema_json.ssids.length > 1) {
                if (template_schema_json.ssids[1].radio === '5g') {
                    enabledSSID.push(template_schema_json.ssids[1].id);
                }
            } else if (template_schema_json.ssids[0].radio === '5g' && template_schema_json.ssids.length > 1) {
                if (template_schema_json.ssids[1].radio === '2g') {
                    enabledSSID.push(template_schema_json.ssids[1].id);
                }
            }
        }
        this.setState({ enabledSSID });
    }
    checkEnabled = () => {
        let enabled = true;
        //LONG-EM-Issues in Wireless Template SSID Adition 
        if (this.state.enabledSSID.length === 2 || this.state.templateData.ssids?.length === 2)
            enabled = false;
        else {
            if (this.state.enabledSSID.length > 0) {
                const ssid = this.state.templateData.ssids.filter(item => item.id === this.state.enabledSSID[0])[0];
                if (ssid.radio === 'both')
                    enabled = false;
            }
        }
        return enabled;
    }
    getExternalServers = () => {
        this.setState({ showLoader: true });
        ApiCall(urlMapping.getServer(), (response) => {
            if (response?.success && response?.data) {
                this.setState({
                    showLoader: false,
                    server_data: response?.data,
                }, () => this.getServersData());
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        showLoader: false,
                        server_data: []
                    }, () => this.getServersData());
                }
            }
        })
    }
    getServersData = () => {
        let servers = [];
        let templateData = JSON.parse(JSON.stringify(this.state.templateData));
        // EMGT-1036 radius server set as blank if its deleted from external servers
        templateData.ssids.map((item) => {
            if (this.state.server_data?.filter(server => server.profile_name === item.radius_server).length < 1) {
                item.radius_server = '';
                item.prim_auth_server = "";
                item.prim_auth_port = "";
                item.prim_auth_secret = "";
                item.sec_auth_server = "";
                item.sec_auth_port = "";
                item.sec_auth_secret = "";
            }
        });
        this.state.server_data.map((server) => {
            servers.push(server.profile_name)
        })
        this.setState({
            servers,
            templateData
        })
    }
    closeSSID = () => {
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;";
        }
        this.setState({
            addSSID: false,
            update: false,
            toggledClearRows: !this.state.toggledClearRows,
            ssidData: {
                "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "radio": "both",
                "network": this.props.edit ? this.state.networkViewData[0].networkName : "",
                "hidden": "0",
                "enabled": "1",
                "ssid": "",
                "encryption": "none",
                "password": "",
                "isolate": "0",
                "isdeleted": "0",
                "isedited": "0",
                "auth_server": "",
                "auth_port": "",
                "auth_secret": "",
                "radius_server": "",
                "prim_auth_server": "",
                "prim_auth_port": "",
                "prim_auth_secret": "",
                "sec_auth_server": "",
                "sec_auth_port": "",
                "sec_auth_secret": ""
            }
        }, () => {
            if (this.props.edit) {
                this.props.dispatch({
                    type: Action.CLOSE_SSID,
                });
            }
        });
    }
    getNetworkName = (name) => {
        const network = this.state.networkViewData.filter(item => item.networkName === name)[0];
        let networkName = ''
        if (network)
            networkName = network.name;
        return networkName;
    }
    getChannelOptions = () => {
        let options = [<option value="auto">Auto</option>]
        for (let i = 1; i <= 11; i++) {
            options.push(<option value={i.toString()}>{i}</option>)
        }
        return options;
    }
    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            multipleSelect: ""
        }, () => {
            $("#errorMessage").modal("hide");
            if (this.state.hide) {
                this.props.refreshList();
                this.props.closeAddTemplate();
                if (this.props?.configApply) {
                    this.props.configRedirect(this.state.configId);
                }
            }
        })
    }
    setValue = (e) => {
        const name = e.target.name;
        const data = this.state.templateData;
        if (name.includes('twog')) {
            const key = name.split('_')[1];
            data.radio.twog[key] = key === 'enable' ? (e.target.checked === true ? "1" : "0") :
                e.target.value;
        } else if (name.includes('fiveg')) {
            const key = name.split('_')[1];
            data.radio.fiveg[key] = key === 'enable' ? (e.target.checked === true ? "1" : "0") :
                e.target.value;
        }
        this.setState({
            templateData: data
        })
    }
    setSSIDValues = (e) => {
        if (Common.removeError(e)) {
            this.setState({ showError: true })
        }
        const name = e.target.name;
        let value;
        if (e.target.name === 'hidden') {
            value = e.target.checked === true ? "0" : "1"
        } else {
            value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
        }
        const data = this.state.ssidData;
        if (name === 'ssid' && this.checkSSIDName(e) === true) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = "SSID name cannot be same!";
            }
        } else if (name === 'encryption') {
            if ((value === 'wpa2' || value === 'wpa3' ||
                value === 'wpa3-mixed')) {
                if (this.state.is_auth_server_configured === true) {
                    data.auth_server = this.state.auth_server;
                    data.auth_port = this.state.auth_port;
                    data.auth_secret = this.state.auth_secret;
                } else {
                    data.auth_server = "";
                    data.auth_port = "";
                    data.auth_secret = "";
                    const element = document.getElementById(e.target.name);
                    if (element) {
                        element.style.display = 'block';
                        this.setState({
                            showError: true
                        })
                    }
                }
            } else {
                data.auth_server = "";
                data.auth_port = "";
                data.auth_secret = "";
                data.radius_server = "";
                data.prim_auth_server = "";
                data.prim_auth_port = "";
                data.prim_auth_secret = "";
                data.sec_auth_server = "";
                data.sec_auth_port = "";
                data.sec_auth_secret = ""
            }
            const element = document.getElementById('password');
            data['password'] = '';
            element.style.display = 'none';
            this.setState({
                showError: false
            })
        }
        data[name] = value;
        this.setState({
            ssidData: data
        })
    }
    //LONG-2930-EM - Front-end Issues 1, Not able to create 4 ssid per radio in wireless template
    errorPopupSSID = () => {
        this.setState({
            title: 'Create Template',
            message: "Only up to 4 SSIDs per radio can be added!",
            show: true,
            error: true,
            delete: false,
            hide: false,
        }, () => $("#errorMessage").modal("show"));
    }
    //LONG-2930- EM - Front-end Issues1, Not able to create 4 ssid per radio in wireless template
    addSSID = () => {
        let dataBoth = this.state.templateData.ssids.filter(item => item.isdeleted !== "1" && item.radio === "both" &&
            item.id !== this.state.ssidData.id).length;
        let data5G = this.state.templateData.ssids.filter(item => item.isdeleted !== "1" && item.radio === "5g" &&
            item.id !== this.state.ssidData.id).length + dataBoth;
        let data2G = this.state.templateData.ssids.filter(item => item.isdeleted !== "1" && item.radio === "2g" &&
            item.id !== this.state.ssidData.id).length + dataBoth;
        switch (this.state.ssidData.radio) {
            case "5g":
                if (data5G > 3) {
                    this.errorPopupSSID()
                }
                else {
                    data5G = data5G + 1
                    this.addDatassid();
                }
                break;
            case "2g":
                if (data2G > 3) {
                    this.errorPopupSSID()
                }
                else {
                    data2G = data2G + 1
                    this.addDatassid();
                }
                break;
            case "both":
                if (data2G > 3 && data5G > 3) {
                    this.errorPopupSSID()
                }
                else if (data2G < 4 && data5G < 4) {
                    data2G = data2G + 1
                    data5G = data5G + 1
                    this.addDatassid();
                }
                else {
                    this.errorPopupSSID()
                }
                break;
            default: {
                this.addDatassid();
                break;
            }
        }
    }
    addDatassid = () => {
        let result = false;
        const data = this.state.templateData;
        if (this.state.ssidData.ssid !== "") {
            const elements = document.getElementsByClassName('error-txt');
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].style.cssText === "display: block;")
                    result = true;
            }
            if (this.state.ssidData.encryption !== 'none' && this.state.ssidData.encryption !== 'ccmp' &&
                this.state.ssidData.encryption !== 'wpa2' && this.state.ssidData.encryption !== 'wpa3' &&
                this.state.ssidData.encryption !== 'wpa3-mixed'
            ) {
                if (this.state.ssidData.password === '') {
                    result = true;
                    const element = document.getElementById('password');
                    if (element) {
                        element.innerHTML = 'Passphrase can not be blank '
                        element.style.display = 'block';
                        this.setState({
                            showError: true
                        })
                    }
                }
            }
            if (result === false) {
                let found = false;
                let { ssidData } = this.state;
                data.ssids.map((item, index) => {
                    if (item.id === this.state.ssidData.id) {
                        found = true;
                        if (this.props.edit && this.state.ssidData.radio !== item.radio) {
                            ssidData.isedited = "1";
                        }
                        data.ssids[index] = ssidData;
                    }
                })
                if (!found)
                    data.ssids.push(this.state.ssidData);
                this.setState({
                    templateData: data,
                    keyUpdate: Math.random(),
                    ssidData: {
                        "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                        "radio": "both",
                        "network": this.props.edit ? this.state.networkViewData[0].networkName : "",
                        "hidden": "0",
                        "enabled": "1",
                        "ssid": "",
                        "encryption": "none",
                        "password": "",
                        "isolate": "0",
                        "isdeleted": "0",
                        "isedited": "0",
                        "auth_server": "",
                        "auth_port": "",
                        "auth_secret": "",
                        "radius_server": "",
                        "prim_auth_server": "",
                        "prim_auth_port": "",
                        "prim_auth_secret": "",
                        "sec_auth_server": "",
                        "sec_auth_port": "",
                        "sec_auth_secret": ""
                    },
                }, () => {
                    this.closeSSID();
                })
            }
            // Long-1525 on adding valid ssid hyperlink was not showing for mifi device
            if (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' ||
                this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm')
                this.filterSSID(data);
        } else {
            const element = document.getElementById('ssid');
            element.style.display = 'block';
            this.setState({
                showError: true
            })
        }
    }
    openSSID = (data) => {
        const x = document.getElementById("passphrase");
        x.type = "password"
        this.setState({
            showPassword: false
        })
        if (data) {
            const rowData = JSON.parse(JSON.stringify(data));
            this.setState({
                ssidData: rowData,
                addSSID: true,
                update: true
            })
        } else {
            const elements = document.getElementsByClassName("error-txt");
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.cssText = "display: none;";
            }
            this.setState({
                ssidData: {
                    "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                    "radio": "both",
                    "network": this.props.edit ? this.state.networkViewData[0].networkName : "",
                    "hidden": "0",
                    "enabled": "1",
                    "ssid": "",
                    "encryption": "none",
                    "password": "",
                    "isolate": "0",
                    "isdeleted": "0",
                    "isedited": "0",
                    "auth_server": "",
                    "auth_port": "",
                    "auth_secret": "",
                    "radius_server": "",
                    "prim_auth_server": "",
                    "prim_auth_port": "",
                    "prim_auth_secret": "",
                    "sec_auth_server": "",
                    "sec_auth_port": "",
                    "sec_auth_secret": ""
                },
                addSSID: true,
            })
        }
        if (this.props.edit) {
            this.props.dispatch({
                type: Action.OPEN_SSID,
            });
        }
    }
    validatePassword = (e) => {
        if (e.target.value.length < 8 || e.target.value.length > 20) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'Passphrase can be 8 to 20 characters long'
            }
        } else {
            const regex = /^[a-zA-Z0-9!@#$%^&*()[\]-_.+={}|:;<>?,/\-\\]*$/;
            const result = regex.test(e.target.value);
            if (!result) {
                const element = document.getElementById(e.target.name);
                if (element)
                    element.style.display = 'block';
                element.innerHTML = 'Invalid value';
            }
        }
    }
    checkError = () => {
        let result = false;
        if (this.props.applying)
            result = true;
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.props.apply) {
            this.state.templateData.ssids.map((item) => {
                if (!this.getNetworkName(item.network)) {
                    result = true;
                }
                // added validation for radius server
                if ((item.encryption === 'wpa2' || item.encryption === 'wpa3' ||
                    item.encryption === 'wpa3-mixed') && item.radius_server === '')
                    result = true;
            })
        }
        if (this.state.template_name === '' ||
            this.state.template_type === '')
            result = true;
        return result;
    }
    checkSSIDName = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            if (e.target.value === "") {
                element.innerHTML = "Invalid Value";
                element.style.display = "block"
                this.setState({
                    showError: true
                })
            }
            //LONG-2806 Wrong error messge was shown in wifi template
            if (!Common.validateAlphaNumericUnderChar(e)) {
                element.innerHTML = "Invalid Value";
                element.style.display = "block"
                this.setState({
                    showError: true
                })
            }
        }
        let found = false;
        const ssids = this.props.edit ?
            this.state.templateData.ssids.filter(item => item.isdeleted !== "1") :
            this.state.templateData.ssids;
        ssids.map((item) => {
            if (item.ssid === e.target.value && item.id !== this.state.ssidData.id)
                found = true;
        })
        return found;
    }
    checkSSIDError = () => {
        let result = false;
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.ssidData.ssid === '')
            result = true;
        if ((this.state.ssidData.encryption === 'wpa2' || this.state.ssidData.encryption === 'wpa3' ||
            this.state.ssidData.encryption === 'wpa3-mixed') && this.state.ssidData.radius_server === '') {
            result = true;
        }
        return result;
    }
    deleteSSID = () => {
        let data = this.state.templateData;
        if (this.props.edit) {
            this.state.multipleSelect.map((item) => {
                let found = false;
                this.undefinedValueCheck(this.props.template_schema_json).ssids.map((ssid) => {
                    if (item.id === ssid.id) {
                        this.state.templateData.ssids.filter(value => value.id === item.id)[0].isdeleted = "1";
                        found = true;
                    }
                    return ssid;
                })
                if (found === false) {
                    data.ssids = this.state.templateData.ssids.filter(finalValue => finalValue.id !== item.id);
                }
            })
        } else {
            this.state.multipleSelect.map((item) => {
                data.ssids = this.state.templateData.ssids.filter(finalValue => finalValue.id !== item.id);
            })
        }
        this.setState({
            templateData: data,
            toggledClearRows: !this.state.toggledClearRows
        })
    }
    saveData = () => {
        let data = {};
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_order = 9;
        data.template_schema_json = this.state.templateData;
        if (this.state.selectedTemplateId === "") {
            data.template_version = {
                chimay: this.state.chimay,
                cbr: this.state.cbr,
                mifi: this.state.mifi
            }
        }
        this.setState({
            showLoader: true,
        });
        if (this?.props?.configDeviceId && !this?.props?.configAdd) {
            let config_data = {
                device_id: this?.props?.configDeviceId,
                template_name: this.state.template_name,
                template_desc: this.state.template_desc,
                template_type: this.state.template_type,
                template_schema_json: this.state.templateData,
            }
            ApiCall(
                urlMapping.setConfigDetails(config_data),
                (response) => {
                    this.setState({
                        showLoader: false,
                    });
                    if (response?.success) {
                        if (this.props?.reorderEnable) {
                            this.resetPopup();
                        }
                        else {
                            this.setState(
                                {
                                    title: "Update Configuration",
                                    message: response?.message,
                                    show: true,
                                    error: false,
                                }, () => {
                                    $("#errorMessage").modal("show");
                                }
                            )
                        }
                    } else {
                        if (parseInt(response?.response?.status) === 401) {
                            this.setState({ showRefreshModal: true });
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"));
                        }
                        else {
                            this.setState(
                                {
                                    title: "Update Configuration",
                                    message: response?.message,
                                    show: true,
                                    error: true,
                                }, () => $("#errorMessage").modal("show"));
                        }
                    }
                }
            );
        }
        else if (this?.props?.configDeviceId && this?.props?.configAdd) {
            let config_data = {
                device_id: this?.props?.configDeviceId,
                template_name: this.state.template_name,
                template_desc: this.state.template_desc,
                device_model: this?.props?.configModels,
                template_type: this.state.template_type,
                collection_version: this.state.collection_version,
                fw_version: this.state.fw_version,
                template_order: 12,
                template_version: {
                    chimay: this.state.chimay,
                    cbr: this.state.cbr,
                    mifi: this.state.mifi
                },
                template_schema_json: this.state.templateData,
            }
            ApiCall(
                urlMapping.setConfigDetails(config_data),
                (response) => {
                    this.setState({
                        showLoader: false,
                    });
                    if (response?.success) {
                        this.setState(
                            {
                                title: "New Configuration",
                                configId: response?.data,
                                message: response?.message,
                                show: true,
                                error: false,
                            }, () => {
                                $("#errorMessage").modal("show");
                            }
                        )
                    } else {
                        if (parseInt(response?.response?.status) === 401) {
                            this.setState({ showRefreshModal: true });
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"));
                        }
                        else {
                            this.setState(
                                {
                                    title: "New Configuration",
                                    message: response?.message,
                                    show: true,
                                    error: true,
                                }, () => $("#errorMessage").modal("show"));
                        }
                    }
                }
            );
        }
        else if (this.state.selectedTemplateId !== '') {
            ApiCall(urlMapping.updateTemplate(this.state.selectedTemplateId, data), (response) => {
                if (response?.Success) {
                    this.setState({ showLoader: false });
                    this.props.refreshList();
                    this.props.openApplyTemplate(this.state.selectedTemplateId);
                    this.props.closeAddTemplate();
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showLoader: false,
                            showRefreshModal: true
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Update Template',
                            message: response?.message,
                            showLoader: false,
                            show: true,
                            error: true,
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            });
        } else {
            ApiCall(urlMapping.createTemplate(data), (response) => {
                if (response?.success) {
                    this.setState({ showLoader: false });
                    this.props.refreshList();
                    this.props.openApplyTemplate(response?.data?.id);
                    this.props.closeAddTemplate();
                } else {
                    $("#errorMessage").modal("hide");
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showLoader: false,
                            showRefreshModal: true
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        this.setState({
                            title: 'Create Template',
                            message: response?.message,
                            showLoader: false,
                            show: true,
                            error: true,
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            });
        }
    }
    showPass = () => {
        const x = document.getElementById("passphrase");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }
    closeApplyTemplate = () => {
        //LONG-3014-EM - Issue in checkbox enable/disable case.
        this.setState({
            toggledClearRows: ''
        })
        //LONG-3016-EM - While cancelling the wifi create template EM is Freezed
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.props.closeAddTemplate()
    }
    handleChange = (e, row) => {
        let { templateData } = this.state;
        templateData.ssids.map((item) => {
            if (item.ssid === row.ssid)
                item.network = e.target.value;
            return item;
        });
        this.setState({
            templateData,
            // edit case network was not getting changed
            keyUpdate: Math.random(),
        })
    }
    applyTemplate = () => {
        let { templateData } = this.state;
        if (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' ||
            this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm') {
            let ssids = [];
            this.state.enabledSSID.map((item) => {
                if (templateData.ssids.filter(ssid => ssid.id === item).length > 0) {
                    ssids.push(templateData.ssids.filter(ssid => ssid.id === item)[0])
                }
            })
            templateData.ssids = ssids;
        }
        if (this.props.selectedModel === 'CHIMAY') {
            templateData.ssids.map((item) => {
                if (item.encryption === 'wpa2' || item.encryption === 'wpa3' || item.encryption === 'wpa3-mixed') {
                    if (item.radius_server === 'SD-Edge_ZTNA-B') {
                        item.auth_server = this.state.auth_server;
                        item.auth_port = this.state.auth_port;
                        item.auth_secret = this.state.auth_secret;
                        item.prim_auth_server = "";
                        item.prim_auth_port = "";
                        item.prim_auth_secret = "";
                        item.sec_auth_server = "";
                        item.sec_auth_port = "";
                        item.sec_auth_secret = ""
                    } else {
                        this.state.server_data.map((server) => {
                            if (server.profile_name === item.radius_server) {
                                item.auth_server = "";
                                item.auth_port = "";
                                item.auth_secret = "";
                                item.prim_auth_server = server.primary_server.prim_auth_server;
                                item.prim_auth_port = server.primary_server.prim_auth_port;
                                item.prim_auth_secret = server.primary_server.prim_auth_secret;
                                item.sec_auth_server = server.secondary_server.sec_auth_server;
                                item.sec_auth_port = server.secondary_server.sec_auth_port;
                                item.sec_auth_secret = server.secondary_server.sec_auth_secret
                            }
                        })
                    }
                    return item;
                }
            })
        }
        this.props.dispatch({
            type: Action.SET_APPLY_TEMPLATE,
        });
        this.props.applyTemplate(templateData);
    }
    debounceApply = debounce((fn, event) => {
        fn(event)
    }, 500);
    //LONG-3014: EM - Issue in checkbox enable/disable case.
    selectedTabChange = (name) => {
        if (name === "ssid") {
            this.setState({ toggledClearRows: !this.state.toggledClearRows })
        }
    }
    getConfigInfo = () => {
        this.setState({
            showLoader: true
        })
        ApiCall(urlMapping.getConfigInfo(), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    is_auth_server_configured: response?.data?.is_auth_server_configured
                })
                this.getAuthServerConfiguration();
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    })
                }
            }
        })
    }
    getAuthServerConfiguration = () => {
        this.setState({
            showLoader: true,
        })
        ApiCall(urlMapping.authServerConfiguration(), (response) => {
            if (response?.success) {
                if (response?.data) {
                    this.setState({
                        showLoader: false,
                        auth_server: response?.data?.local?.server_ip,
                        auth_port: response?.data?.[response?.data?.settings?.enable]?.port,
                        auth_secret: response?.data?.settings?.secret_passphrase
                    })
                }
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false,
                    });
                }
            }
        });
    }
    disabledOptions = () => {
        let model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() :
            this.props.selectedModel ? this.props.selectedModel : this.state.device_model;
        model = Common.getModels(model);
        switch (model) {
            case 'Moretti_Model':
            case 'Duvel_Model':
            case "Stormbreaker":
            case "Firestorm":
            case 'Bradshaw':
            case 'Orval':
            case 'Sander':
                return true;
            default:
                return false;
        }
    }
    render() {
        return (
            <div className="wifi">
                {this.state.showLoader && <Loader />}
                <div className="tab-box">
                    <ul className="tab-nav nav nav-pills bg-gray" id="wifiTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active"
                                data-toggle="tab" href="#radio-tab" role="tab" aria-controls="radio" aria-selected="true">Radio</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link"
                                data-toggle="tab" href="#ssid-tab" role="tab" aria-controls="ssid" aria-selected="false" onClick={() => this.selectedTabChange('ssid')}>SSID</a>
                        </li>
                    </ul>
                    <div className="tab-content mt-2">
                        <div className="tab-pane active" id="radio-tab" role="tabpanel" aria-labelledby="radio-tab">
                            <div className="gray-bg p-3">
                                <p><strong>2.4GHz</strong></p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mode</label>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Specifies the hardware mode">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                            <select
                                                className="form-control"
                                                name="twog_mode"
                                                value={this.state.templateData.radio.twog.mode}
                                                onChange={(e) => this.setValue(e)}>
                                                <option value="11axg">802.11ax</option>
                                                <option value="11ng">802.11bgn</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Channel</label>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Specifies the wireless channel">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                            <select
                                                className="form-control"
                                                name="twog_channel"
                                                value={this.state.templateData.radio.twog.channel}
                                                onChange={(e) => this.setValue(e)}>
                                                {this.getChannelOptions()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-0">
                                            <label>BW</label>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Specifies the channel width">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                            <select
                                                className="form-control"
                                                name="twog_bw"
                                                value={this.state.templateData.radio.twog.bw}
                                                onChange={(e) => this.setValue(e)}>
                                                <option value="HT20">20 MHz</option>
                                                <option value="HT40">40 MHz</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {/* EMGT-2197 Enable button disabled for chimay devices */}
                                        <div className={Common.getModels(this.props?.selectedModelList)?.includes('CHIMAY') ||
                                            this.props.selectedModel === 'CHIMAY' ?
                                            "d-flex align-items-center justify-content-between top-position disabled" :
                                            "d-flex align-items-center justify-content-between top-position"}>
                                            <div>
                                                <span>Enable</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title=" Enable/Disable the radio adapter">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </div>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    name="twog_enable"
                                                    checked={this.state.templateData.radio.twog.enable === "1"}
                                                    onChange={(e) => this.setValue(e)}
                                                    disabled={Common.getModels(this.props?.selectedModelList)?.includes('CHIMAY') || this.props.selectedModel === 'CHIMAY'} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gray-bg p-3 mt-2">
                                <p><strong>5GHz</strong></p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mode</label>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Specifies the hardware mode">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                            <select
                                                className="form-control"
                                                name="fiveg_mode"
                                                value={this.state.templateData.radio.fiveg.mode}
                                                onChange={(e) => this.setValue(e)}>
                                                <option value="11axa">802.11ax</option>
                                                <option value="11na">802.11an</option>
                                                <option value="11ac">802.11ac</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Channel</label>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Specifies the wireless channel">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                            <select
                                                className="form-control"
                                                name="fiveg_channel"
                                                value={this.state.templateData.radio.fiveg.channel}
                                                onChange={(e) => this.setValue(e)}>
                                                <option value="auto">Auto</option>
                                                <option value="36">36</option>
                                                <option value="40">40</option>
                                                <option value="44">44</option>
                                                <option value="48">48</option>
                                                <option value="149">149</option>
                                                <option value="153">153</option>
                                                <option value="157">157</option>
                                                <option value="161">161</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-0">
                                            <label>BW</label>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                title="Specifies the channel width">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                            <select
                                                className="form-control"
                                                name="fiveg_bw"
                                                value={this.state.templateData.radio.fiveg.bw}
                                                onChange={(e) => this.setValue(e)}>
                                                <option value="HT20">20 MHz</option>
                                                <option value="HT40">40 MHz</option>
                                                <option value="HT80">80 MHz</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {/* Enable button disabled for chimay devices */}
                                        <div className={Common.getModels(this.props?.selectedModelList)?.includes('CHIMAY') ||
                                            this.props.selectedModel === 'CHIMAY' ?
                                            "d-flex align-items-center justify-content-between top-position disabled" : "d-flex align-items-center justify-content-between top-position"}>
                                            <div>
                                                <span>Enable</span>
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                                    title=" Enable/Disable the radio adapter">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </div>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    name="fiveg_enable"
                                                    checked={this.state.templateData.radio.fiveg.enable === "1"}
                                                    onChange={(e) => this.setValue(e)}
                                                    disabled={Common.getModels(this.props?.selectedModelList)?.includes('CHIMAY') ||
                                                        this.props.selectedModel === 'CHIMAY'} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane"
                            id="ssid-tab" role="tabpanel" aria-labelledby="ssid-tab">
                            <div className="page-sub-title">
                                <div><strong>Settings</strong>
                                    <img
                                        src="/images/svg-icons/add.svg"
                                        className={((this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model' ||
                                            this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm') &&
                                            !this.checkEnabled()) || (this.props.templateData.default_type !== '' &&
                                                this.props.templateData.default_type !== null) ? "add-btn ml-2 disabled" :
                                            "add-btn ml-2"}
                                        alt=""
                                        onClick={((this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
                                            || this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm') &&
                                            !this.checkEnabled()) || (this.props.templateData.default_type !== ''
                                                && this.props.templateData.default_type !== null) ? undefined : () => this.openSSID()} />
                                </div>
                                <div className="device-action">
                                    <span className={this.state.multipleSelect.length !== 1 ?
                                        "icon-box disabled" : "icon-box"}>
                                        <img
                                            src="/images/svg-icons/edit.svg"
                                            alt=""
                                            disabled={this.state.multipleSelect.length !== 1}
                                            onClick={this.state.multipleSelect.length === 1 ? () => this.openSSID(this.state.multipleSelect[0]) : undefined}
                                        />
                                    </span>
                                    <span
                                        className={this.state.multipleSelect === "" ||
                                            (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
                                                || this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm') ||
                                            (this.props.templateData.default_type !== ''
                                                && this.props.templateData.default_type !== null) ?
                                            "icon-box disabled" : "icon-box"}
                                        onClick={this.state.multipleSelect === "" ||
                                            (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
                                                || this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm') ||
                                            (this.props.templateData.default_type !== ''
                                                && this.props.templateData.default_type !== null) ? undefined :
                                            () => this.deleteSSID()}>
                                        <img src="/images/svg-icons/delete.svg" alt="" disabled={this.state.multipleSelect === ""} /></span>
                                </div>
                            </div>
                            <div className="ssid-table"
                                key={this.state.keyUpdate}
                            >
                                {this.state.templateData.ssids.length > 0 &&
                                    <DataTable
                                        columns={this.state.columns}
                                        data={
                                            this.props.edit ?
                                                this.state.templateData.ssids.filter(item => item.isdeleted !== "1") :
                                                (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
                                                    || this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm') ?
                                                    this.state.templateData.ssids.slice(0, 2) : this.state.templateData.ssids}
                                        selectableRows
                                        selectableRowDisabled={item => (this.props.selectedModel === 'Moretti_Model' || this.props.selectedModel === 'Duvel_Model'
                                            || this.props.selectedModel === "Stormbreaker" || this.props.selectedModel === 'Firestorm')
                                            && !this.state.enabledSSID.includes(item.id)}
                                        fixedHeader
                                        onSelectedRowsChange={(data) => this.multipleSelect(data)}
                                        clearSelectedRows={this.state.toggledClearRows}
                                    />}
                            </div>
                        </div>
                    </div>
                    {<div className="s-footer">
                        <button
                            disabled={this.props?.disableCancel || false}
                            className="btn btn-light"
                            onClick={(this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
                                () => this.closeApplyTemplate()}>{this.props.apply && !this.props.edit && !this.props.reapply ? 'Back' : 'Cancel'}</button>
                        <button className="btn btn-primary"
                            disabled={this.checkError()}
                            onClick={(this.props.apply && !this.props?.configEdit) ? (event) => this.debounceApply(this.applyTemplate, event) : () => this.saveData()}
                        >
                            {
                                (this.props?.configApply || this.props?.reorderEnable)
                                    ? "Next"
                                    : (this.props.apply && !this.props?.configEdit)
                                        ? "Apply"
                                        : (this?.props?.configAdd || this.props?.configEdit)
                                            ? "Save"
                                            : (this.state.selectedTemplateId !== "" || this?.props?.configDeviceId)
                                                ? "Update"
                                                : "Save"
                            }
                        </button>
                    </div>}
                    <div className={this.state.addSSID ? "disabled-slide" : ""}></div>
                </div>
                <div className={
                    (this.props.edit ? this.props.openSSID === true : this.state.addSSID === true) ? "sidebar-slide  zindex10 w-350 second-open r-757" : "sidebar-slide  zindex10 w-350"}>
                    <div className="s-header">
                        <h1 className="ss-title">{this.state.update ? 'Edit SSID' : 'Add New SSID'}</h1>
                        <button>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" onClick={() => this.closeSSID()} />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="form-group">
                            <label>SSID
                                <span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="The broadcasted SSID of the wireless network">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="ssid"
                                value={this.state.ssidData.ssid}
                                readOnly={this.props.reapply}
                                onChange={(e) => this.setSSIDValues(e)}
                                onBlur={(e) => this.checkSSIDName(e)}
                            />
                            <div className="error-txt" id="ssid">SSID cannot be blank</div>
                        </div>
                        <div className="form-group">
                            <label>Security
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Wireless encryption method">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <select
                                className="form-control"
                                name="encryption"
                                value={this.state.ssidData.encryption}
                                onChange={(e) => this.setSSIDValues(e)}>
                                <option value="none">Open</option>
                                <option value="psk2+aes">WPA2 AES PSK</option>
                                <option value="psk+tkip+aes">WPA/WPA2 Mixed Mode</option>
                                <option value="psk2+ccmp">WPA3/WPA2 Transition</option>
                                {/* EMGT-2197 Removed options for Models do not support wifi */}
                                {!this.disabledOptions() &&
                                    <> <option value="ccmp">WPA3 Open Enhanced</option>
                                        <option value="ccmpaes">WPA3 PSK</option>
                                        <option value="wpa2">WPA2 Enterprise</option>
                                        <option value="wpa3">WPA3 Enterprise</option>
                                        <option value="wpa3-mixed">WPA3/WPA2 Enterprise</option></>}
                            </select>
                            {/* <div className="error-txt" id="encryption">Packetfence switch is not configured</div> */}
                        </div>
                        {(this.state.ssidData.encryption === 'wpa2' || this.state.ssidData.encryption === 'wpa3' ||
                            this.state.ssidData.encryption === 'wpa3-mixed') && <div className="form-group">
                                <label>Server
                                    {(this.state.ssidData.encryption === 'wpa2' || this.state.ssidData.encryption === 'wpa3' ||
                                        this.state.ssidData.encryption === 'wpa3-mixed') && <span className="red-txt">*</span>}
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Select RADIUS server">
                                        <img src="/images/svg-icons/info.svg" alt="" /></div>
                                </label>
                                <select
                                    className="form-control"
                                    name='radius_server'
                                    onChange={this.setSSIDValues}
                                    value={this.state.ssidData.radius_server}>
                                    <option value="">Select server</option>
                                    {this.state.is_auth_server_configured && <option value="SD-Edge_ZTNA-B">SD-EDGE ZTNA-B</option>}
                                    {this.state.servers.map((server) => {
                                        return (<option value={server}>{server}</option>)
                                    })}
                                </select>
                            </div>}
                        <div className="form-group">
                            <label>Passphrase
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="This is a string that specifies the pre-shared passphrase">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="passphrase"
                                value={this.state.ssidData.password}
                                onChange={(e) => this.setSSIDValues(e)}
                                onBlur={(e) => this.validatePassword(e)}
                                disabled={this.state.ssidData.encryption === 'none' || this.state.ssidData.encryption === 'ccmp' ||
                                    this.state.ssidData.encryption === 'wpa2' || this.state.ssidData.encryption === 'wpa3' || this.state.ssidData.encryption === 'wpa3-mixed'}
                                minLength="8"
                                maxLength="20" />
                            <div className="sh-icon">
                                <img src={this.state.showPassword ? "/images/show.png" : "/images/hide.png"} alt="" onClick={() => this.showPass()} />
                            </div>
                            <div className="error-txt" id="password">Invalid value</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <span className="mr-5">Broadcast
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title=" Specifies the SSID to be broadcast or hidden">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </span>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name="hidden"
                                    checked={this.state.ssidData.hidden === "0"}
                                    onChange={(e) => this.setSSIDValues(e)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Radio Type
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Identifier for the underlying radio hardware">
                                    <img src="/images/svg-icons/info.svg" alt="" /></div>
                            </label>
                            <select
                                className="form-control"
                                name="radio"
                                value={this.state.ssidData.radio}
                                onChange={(e) => this.setSSIDValues(e)}>
                                <option value="2g">2.4GHz</option>
                                <option value="5g">5GHz</option>
                                <option value="both">Both</option>
                            </select>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <span className="mr-5">Enable
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Enable/Disable the wireless network">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </span>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name="enabled"
                                    checked={this.state.ssidData.enabled === "1"}
                                    onChange={(e) => this.setSSIDValues(e)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        {/* apply success message */}
                        {this.state.showLink && <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body pt-5">
                                        <div className="error-msg">
                                            <span className="success-icon"></span>
                                            <div className="txt1">Create Template</div>
                                            <div className="txt2">Template Created Successfully. To apply <span
                                                onClick={() => this.props.openApplyTemplate(this.state.templateID)}>click here</span></div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-top-0 justify-content-center pb-4">
                                        <button type="button" className="btn btn-light" data-dismiss="modal"
                                            onclick={() => this.closeLinkpopup()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {/* close apply success message */}
                    </div>
                    <div className="s-footer">
                        <button className="btn btn-light" onClick={() => this.closeSSID()}>Cancel</button>
                        <button className="btn btn-primary" disabled={this.checkSSIDError()}
                            onClick={() => this.addSSID()}>{this.state.update ? 'Update' : 'Add'}</button>
                    </div>
                </div>
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        applying: state.displayAddTemplate.applying,
        openSSID: state.displayAddTemplate.openSSID
    });
}
export default connect(mapStateToProps)(WiFiTemplate);