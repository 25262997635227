import React, { Component } from 'react'
import { connect } from "react-redux";
import * as Common from '../../../../utils/common';
import ErrorHandlePopup from "../../../ErrorHandlePopup";
import debounce from 'lodash/debounce';
import * as Action from "../../../../redux/actionTypes";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import { Loader } from '../../../../layOuts/Loader';
import { ErrorPopup } from '../../../../layOuts/errorPopup';
import $ from "jquery";
import { Link } from "react-router-dom";
import isEqual from 'lodash/isEqual';
class CaptivePortal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddTemplate: false,
            enterBlValue: '',
            profileData: {
                "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "profile_name": "",
                "splash_url": "",
                "keyword": "",
                "pRadius_enable": "0",
                "pAuth_server_ip": "",
                "pAuth_server_port": "",
                "pAuth_server_pass": "",
                "sRadius_enable": "0",
                "sAuth_server_ip": "",
                "sAuth_server_port": "",
                "sAuth_server_pass": "",
                "interface": [],
                "networks": [],
                "walled_garden_enable": "0",
                "walled_garden": [],
                "isdeleted": "0",
                "isEnabled": "1"
            },
            selectedTemplateId: this.props?.id ? this.props?.id : "",
            template_type: this.props?.templateData?.template_type || "",
            template_name: this.props?.templateData?.template_name || "",
            template_desc: this.props?.templateData?.template_desc || "",
            device_model: this.props?.templateData?.device_model || "",
            collection_version: this.props?.templateData?.collection_version || "",
            fw_version: this.props?.templateData?.fw_version || "",
            chimay: this.props.chimay,
            cbr: this.props.cbr,
            mifi: this.props.mifi,
            template_schema_json: {
                "data": {
                    "captive_portal": []
                }
            },
            title: "",
            message: "",
            show: false,
            error: false,
            selectedCP: [],
            update: false,
            networkViewData: [],
            showError: false,
            splashLabel: [],
            suggestWallenEnable: true,
            isAxiosError: false
        };
    }
    componentDidMount = () => {
        if (this.props.template_schema_json) {
            this.setState({
                template_schema_json: JSON.parse(JSON.stringify(this.props.template_schema_json)),
                networkViewData: this.props?.networkViewData
                    ? JSON.parse(JSON.stringify(this.props?.networkViewData?.filter(item => ((item?.network_zone === 'lan')
                        && (item["802_1x_enabled"] !== "1")))))
                    : []
            })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps?.templateData)?.length !== 0) {
            this.setState({
                selectedTemplateId: nextProps.id ? nextProps.id : '',
                template_type: nextProps?.templateData?.template_type || "",
                template_name: nextProps?.templateData?.template_name || "",
                template_desc: nextProps?.templateData?.template_desc || "",
                device_model: nextProps?.templateData?.device_model || "",
                collection_version: nextProps?.templateData?.collection_version || "",
                fw_version: nextProps?.templateData?.fw_version || ""
            });
        }
        if (nextProps?.template_schema_json && nextProps?.networkViewData)
            if (!isEqual(this.props?.template_schema_json, nextProps?.template_schema_json)) {
                this.setState({
                    template_schema_json: JSON.parse(JSON.stringify(nextProps.template_schema_json)),
                    networkViewData: nextProps?.networkViewData
                        ? JSON.parse(JSON.stringify(nextProps.networkViewData.filter(item => ((item.network_zone === 'lan')
                            && (item["802_1x_enabled"] !== "1")))))
                        : []
                })
            }
        if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
            !isEqual(this.props?.cbr, nextProps?.cbr) ||
            !isEqual(this.props?.mifi, nextProps?.mifi)) {
            this.setState({
                chimay: nextProps.chimay,
                cbr: nextProps.cbr,
                mifi: nextProps.mifi
            })
        }
    }
    openCaptivePortalTemplate = (data) => {
        if (data) {
            this.setState({
                profileData: JSON.parse(JSON.stringify(data)),
                openAddTemplate: true,
                update: true
            }, () => {
                if (this.state.profileData.splash_url !== '') {
                    let temp = this.state.profileData.splash_url?.replace("https://", '')?.replace("/", "")
                    temp = temp?.split(".")
                    let newTemp = []
                    temp.length > 2 && newTemp.push(temp[temp.length - 3] + "." + temp[temp.length - 2] + "." + temp[temp.length - 1])
                    newTemp.push(temp[temp.length - 2] + "." + temp[temp.length - 1])
                    this.setState({
                        splashLabel: newTemp
                    })
                }
            })
        }
        else {
            const elements = document.getElementsByClassName("error-txt");
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.cssText = "display: none;"
            }
            this.setState({
                profileData: {
                    "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                    "profile_name": "",
                    "splash_url": "",
                    "keyword": "",
                    "pRadius_enable": "0",
                    "pAuth_server_ip": "",
                    "pAuth_server_port": "",
                    "pAuth_server_pass": "",
                    "sRadius_enable": "0",
                    "sAuth_server_ip": "",
                    "sAuth_server_port": "",
                    "sAuth_server_pass": "",
                    "interface": [],
                    "networks": [],
                    "walled_garden_enable": "0",
                    "walled_garden": [],
                    "isdeleted": "0",
                    "isEnabled": "1"
                },
                openAddTemplate: true,
                showError: false
            })
        }
    }
    cancelCaptivePortal = () => {
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;"
        }
        this.setState({
            openAddTemplate: false,
            update: false,
            profileData: {
                "profile_name": "",
                "splash_url": "",
                "keyword": "",
                "pRadius_enable": "0",
                "pAuth_server_ip": "",
                "pAuth_server_port": "",
                "pAuth_server_pass": "",
                "sRadius_enable": "0",
                "sAuth_server_ip": "",
                "sAuth_server_port": "",
                "sAuth_server_pass": "",
                "interface": [],
                "networks": [],
                "walled_garden_enable": "0",
                "walled_garden": [],
                "isdeleted": "0",
                "isEnabled": "1"
            }
        })
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.enterDomain(e);
        }
    }
    setProfileData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true,
            });
        }
        const name = e.target.name;
        let value = (e.target.type === "checkbox") ?
            (e.target.checked === true ? "1" : "0") : e.target.value;
        let data = this.state.profileData;
        // if (name === "sRadius_enable") {
        //     data[name] = data[name] === '1' ? '0' : '1';
        //     if (data[name] === '0') {
        //         data["sRadius_enable"] = "0"
        //         data["sAuth_server_ip"] = ""
        //         data["sAuth_server_port"] = ""
        //         data["sAuth_server_pass"] = ""
        //     }
        // }
        // else 
        if (name === "pRadius_enable") {
            data[name] = value;
            if (data[name] === '0') {
                data["pRadius_enable"] = "0";
                data["pAuth_server_ip"] = "";
                data["pAuth_server_port"] = "";
                data["pAuth_server_pass"] = "";
                // data["sRadius_enable"] = "0"
                // data["sAuth_server_ip"] = ""
                // data["sAuth_server_port"] = ""
                // data["sAuth_server_pass"] = ""
            }
        }
        else if (name === "walled_garden_enable") {
            data[name] = value;
            if (data[name] === '0') {
                data["walled_garden_enable"] = "0"
                data["walled_garden"] = []
            }
            let { profileData } = this.state;
            if (profileData.splash_url !== '') {
                let temp = profileData.splash_url?.replace("https://", '')?.replace("/", "")
                temp = temp?.split(".")
                let newTemp = []
                temp.length > 2 && newTemp?.push(temp?.[temp?.length - 3] + "." + temp?.[temp?.length - 2] + "." + temp?.[temp?.length - 1])
                newTemp.push(temp[temp.length - 2] + "." + temp[temp.length - 1])
                this.setState({
                    splashLabel: newTemp,
                })
            }
        }
        else if (name === "splash_url") {
            let { profileData } = this.state;
            let temp = []
            this.state.splashLabel?.map((val) => {
                temp = this.state.profileData.walled_garden?.filter((item) => item != val)
                profileData.walled_garden = temp
            })
            this.setState({
                profileData,
            })
            data[name] = value;
            this.setState({
                splashLabel: []
            })
        }
        else
            data[name] = value;
        this.setState({
            profileData: data
        })
    }
    enterDomain = (e, cancel) => {
        let data = this.state.profileData;
        if (!cancel) {
            let isDomain = false
            let regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
            let str = (e.target.value?.replace("https://", '')?.replace("/", ""))?.trim();
            if (str?.toLowerCase()?.substring(0, 4) === 'www.') {
                str = str?.toLowerCase()?.substring(4, str.length)
            }
            if (regex.test(str)) {
                let Domains = data?.walled_garden?.map(domain => {
                    if (domain?.toLowerCase()?.substring(0, 4) === 'www.') {
                        return domain?.toLowerCase()?.substring(4, domain.length)
                    }
                    else return domain
                })
                if (!Domains?.includes(str)) {
                    isDomain = true
                }
                if (!isDomain) {
                    this.setState({ enterBlValue: '' })
                }
                if (e.target.value?.replace("https://", '')?.replace("/", "") && !cancel && isDomain) {
                    data.walled_garden?.push((e.target.value?.replace("https://", '')?.replace("/", ""))?.trim())
                }
            }
        }
        else {
            data.walled_garden = data?.walled_garden?.filter(i => i !== e?.target?.id)
        }
        this.setState({
            profileData: data,
            enterBlValue: ''
        })
    }
    selectAll = (e) => {
        if (e.target.checked) {
            this.setState({
                selectedCP: this.state.template_schema_json.data.captive_portal
            })
        } else {
            this.setState({
                selectedCP: []
            })
        }
    }
    selectedCP = (e, cp) => {
        let { selectedCP } = this.state;
        if (e.target.checked) {
            selectedCP.push(cp);
        }
        else {
            selectedCP = selectedCP?.filter(item => item?.id !== cp?.id);
        }
        this.setState({
            selectedCP
        })
    }
    validateProfileName = (value) => {
        let temp = false
        if (this.state.template_schema_json.data.captive_portal?.profile_name !== "") {
            this.state.template_schema_json.data.captive_portal?.map((item) => {
                if (item.profile_name === value && item.id !== this.state.profileData.id) {
                    temp = true
                }
            })
            return temp
        }
        else {
            return temp
        }
    }
    validate = (e) => {
        if (e.target.name === "pAuth_server_ip"
            // || e.target.name === "sAuth_server_ip"
        ) {
            if (!Common.validateIPaddress(e)) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true
                    })
                }
            }
            else {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'none';
                    this.setState({
                        showError: false
                    })
                }
            }
        }
        else if (e.target.name === "keyword") {
            if (e.target.value !== "" && !Common.validateAlphaNumericOnlyHyphen(e) || e.target.value.length > 20) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true
                    })
                }
            }
            else {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'none';
                    this.setState({
                        showError: false
                    })
                }
            }
        }
        else if (e.target.name === "profile_name") {
            if (!Common.validateAlphaNumericSpecial(e) || e.target.value.length < 1 || e.target.value.length > 20) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true
                    })
                }
            }
            else if (this.validateProfileName(e.target.value)) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "block";
                    element.innerHTML = 'Profile name already exits';
                    this.setState({
                        showError: true
                    })
                }
            }
            else {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'none';
                    this.setState({
                        showError: false
                    })
                }
            }
        }
        else if (e.target.name === "splash_url") {
            let regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            const result = regex.test(e.target.value);
            if (!result) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    this.setState({
                        showError: true,
                        suggestWallenEnable: false
                    })
                }
            }
            else {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'none';
                    let temp = (e.target.value)?.replace("https://", '')?.replace("/", "")
                    temp = temp?.split(".")
                    let newTemp = []
                    newTemp?.push(temp?.[temp?.length - 3] + "." + temp?.[temp?.length - 2] + "." + temp?.[temp?.length - 1])
                    newTemp?.push(temp?.[temp?.length - 2] + "." + temp?.[temp?.length - 1])
                    this.setState({
                        showError: false,
                        splashLabel: newTemp,
                        suggestWallenEnable: true
                    })
                }
            }
        }
        else if (e.target.name === "pAuth_server_port"
            // || e.target.name === "sAuth_server_port"
        ) {
            if (parseInt(e.target.value) > 65535 || parseInt(e.target.value) < 1) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    this.setState({
                        showError: true
                    })
                }
            }
            else {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'none';
                    this.setState({
                        showError: false
                    })
                }
            }
        }
        else if (e.target.name === "pAuth_server_pass"
            // || e.target.name === "sAuth_server_pass"
        ) {
            const result = Common.validateAlphaNumericSpecial(e);
            if (!result || e.target.value.length < 8 || e.target.value.length > 20) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    this.setState({
                        showError: true
                    })
                }
            }
            else {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'none';
                    this.setState({
                        showError: false
                    })
                }
            }
        }
        else {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'none';
                this.setState({
                    showError: false
                })
            }
        }
    }
    addProfile = () => {
        let { template_schema_json } = this.state;
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;") result = true;
        }
        if (result === false) {
            let found = false;
            template_schema_json.data.captive_portal?.map((item, index) => {
                if (item.id === this.state.profileData.id) {
                    found = true;
                    template_schema_json.data.captive_portal[index] = this.state.profileData;
                }
            });
            if (!found)
                template_schema_json.data.captive_portal?.push(this.state.profileData);
            this.setState(
                {
                    template_schema_json,
                    keyUpdate: Math.random(),
                    profileData: {
                        "id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                        "profile_name": "",
                        "splash_url": "",
                        "keyword": "",
                        "pRadius_enable": "0",
                        "pAuth_server_ip": "",
                        "pAuth_server_port": "",
                        "pAuth_server_pass": "",
                        "sRadius_enable": "0",
                        "sAuth_server_ip": "",
                        "sAuth_server_port": "",
                        "sAuth_server_pass": "",
                        "interface": [],
                        "networks": [],
                        "walled_garden_enable": "0",
                        "walled_garden": [],
                        "isdeleted": "0",
                        "isEnabled": "1"
                    }
                },
                () => this.cancelCaptivePortal()
            );
        }
    }
    deleteCapticePortal = () => {
        let data = this.state.template_schema_json?.data?.captive_portal
        if (this.props.edit) {
            this.state.selectedCP?.map((item) => {
                let found = false;
                this.props.template_schema_json.data.captive_portal?.map((area) => {
                    if (item.id === area.id) {
                        this.state.template_schema_json.data.captive_portal.filter(value => value.id === item.id)[0].isdeleted = "1";
                        found = true;
                    }
                    return area;
                })
                if (found === false) {
                    data = this.state.template_schema_json.data.captive_portal?.filter(finalValue => finalValue?.id !== item?.id);
                }
            })
        }
        else {
            this.state.selectedCP.map((item) => {
                data = this.state.template_schema_json.data.captive_portal?.filter(finalValue => finalValue?.id !== item?.id);
            })
        }
        this.setState({
            template_schema_json: {
                "data": {
                    "captive_portal": data
                }
            },
            selectedCP: []
        })
    }
    closeAll = () => {
        if (this.props.template_schema_json !== undefined) {
            this.setState({
                template_schema_json: this.props.template_schema_json,
            })
        }
        if (!this.props.edit && !this.props.reapply) {
            this.props.closeAddTemplate()
        }
        else {
            this.props.closeApplyTemplate()
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false
        }, () => {
            $("#errorMessage").modal("hide");
            this.props.refreshList();
            this.props.closeAddTemplate();
        });
    }
    disableCheck = () => {
        let result = false
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.showError) {
            result = true;
        }
        if (this.state.profileData.profile_name === "" || this.state.profileData.splash_url === "") {
            result = true;
        }
        if (this.state.profileData.pRadius_enable === "1") {
            if (this.state.profileData.pAuth_server_ip === "" || this.state.profileData.pAuth_server_port === "" || this.state.profileData.pAuth_server_pass === "") {
                result = true;
            }
            // if (this.state.profileData.sRadius_enable === "1") {
            //     if (this.state.profileData.sAuth_server_ip === "" || this.state.profileData.sAuth_server_port === "" || this.state.profileData.sAuth_server_pass === "") {
            //         result = true;
            //     }
            // }
        }
        if (this.state.profileData.walled_garden_enable == '1') {
            if (this.state.profileData.walled_garden.length === 0) {
                result = true;
            }
        }
        return result;
    }
    saveData = () => {
        let data = {};
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_order = 15;
        data.template_schema_json = this.state.template_schema_json;
        if (this.state.selectedTemplateId === "") {
            data.template_version = {
                chimay: this.state.chimay,
                cbr: this.state.cbr,
                mifi: this.state.mifi
            }
        }
        this.setState({
            showLoader: true,
        });
        if (this.state.selectedTemplateId !== "") {
            ApiCall(
                urlMapping.updateTemplate(this.state.selectedTemplateId, data),
                (response) => {
                    this.setState({
                        showLoader: false,
                    });
                    if (response?.Success) {
                        this.props.refreshList();
                        this.props.openApplyTemplate(this.state.selectedTemplateId);
                        this.props.closeAddTemplate();
                    } else {
                        if (parseInt(response?.response?.status) === 401) {
                            this.setState({ showRefreshModal: true })
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                        else {
                            this.setState(
                                {
                                    title: "Update Template",
                                    message: response?.message,
                                    show: true,
                                    error: true,
                                },
                                () => $("#errorMessage").modal("show")
                            );
                        }
                    }
                }
            );
        } else {
            ApiCall(urlMapping.createTemplate(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.props.refreshList();
                    this.props.openApplyTemplate(response?.data?.id);
                    this.props.closeAddTemplate();
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState(
                            {
                                title: "Create Template",
                                message: response?.message,
                                show: true,
                                error: true,
                            },
                            () => $("#errorMessage").modal("show")
                        );
                    }
                }
            });
        }
    };
    handleChange = (e, row) => {
        let { template_schema_json } = this.state;
        template_schema_json.data.captive_portal?.map((item) => {
            item.interface = []
            item.networks = []
            if (item.profile_name === row.profile_name) {
                const selectedNetwork = this.state.networkViewData?.filter((item) => item?.networkName === e.target?.id)?.[0];
                let value = selectedNetwork.bridgeStatus === "Enabled" ? selectedNetwork?.Bridge_name : selectedNetwork?.interfaceName;
                if (e.target.checked) {
                    item.interface.push(value);
                    item.networks.push(e.target.id);
                } else {
                    item.interface = item.interface?.filter(i => i !== value);
                    item.networks = item.networks?.filter(network => network !== e.target.id);
                }
            }
            return item;
        });
        this.setState({
            template_schema_json
        })
    }
    suggestWallenGarden = (item) => {
        let data = this.state.profileData;
        data.walled_garden_enable = "1"
        this.setState({
            profileData: data
        }, () => {
            let data = this.state.profileData;
            let isDomain = false;
            let regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
            let str = item;
            if (str?.toLowerCase()?.substring(0, 4) === 'www.') {
                str = str?.toLowerCase()?.substring(4, str.length);
            }
            if (regex.test(str)) {
                let Domains = data?.walled_garden?.map(domain => {
                    if (domain?.toLowerCase()?.substring(0, 4) === 'www.') {
                        return domain?.toLowerCase()?.substring(4, domain.length)
                    }
                    else return domain
                })
                if (Domains?.includes(str)) {
                    isDomain = true;
                }
                if (!isDomain) {
                    data.walled_garden?.push((item));
                }
            }
            this.setState({
                profileData: data,
                enterBlValue: ''
            })
        })
    }
    getNetworkName = (name) => {
        const network = this.state.networkViewData?.filter(item => item?.networkName === name)?.[0];
        let networkName = ''
        if (network)
            networkName = network.name;
        return networkName;
    }
    checkError = () => {
        let result = false;
        if (this.props.applying)
            result = true;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (
            this.state.template_name === "" ||
            this.state.template_type === "" ||
            this.state.template_schema_json.data.captive_portal?.filter(item => item.isdeleted !== '1')?.length === 0
        )
            result = true;
        if (this.props.edit) {
            this.state.template_schema_json.data.captive_portal?.filter(item => item.isdeleted !== '1')?.map(((item) => {
                // EMGT-700 network deleted after applying, hide that network so user need to apply other network
                if (item.networks.length === 0 || !this.getNetworkName(item.networks?.[0])) {
                    result = true;
                }
            }
            ))
        }
        return result;
    }
    debounceApply = debounce((fn, event) => {
        fn(event)
    }, 500);
    applyTemplate = () => {
        let { template_schema_json } = this.state;
        template_schema_json.data.captive_portal.map((item) => {
            const network = this.state.networkViewData.filter((item1) => item1.networkName === item.networks?.[0])?.[0];
            let value = network.bridgeStatus === "Enabled" ? network?.Bridge_name : network?.interfaceName;
            item.interface = [];
            item.interface.push(value);
            return item;
        });
        this.setState({
            template_schema_json
        }, () => {
            this.props.dispatch({
                type: Action.SET_APPLY_TEMPLATE
            });
            this.props.applyTemplate(this.state.template_schema_json)
        })
    }
    render() {
        return (
            <div>
                {this.state.showLoader && <Loader />}
                <div>
                    <div className={this.state.openAddTemplate ? "disabled-slide" : ""}></div>
                    <div className="page-sub-title">
                        <div>
                            <strong>Profile</strong>
                            <img
                                src="/images/svg-icons/add.svg"
                                className={this.state.template_schema_json.data?.captive_portal?.filter(item => item.isdeleted !== '1')?.length > 0 ?
                                    "add-btn ml-2 disabled" : "add-btn ml-2"}
                                alt=""
                                onClick={
                                    this.state.template_schema_json?.data?.captive_portal?.filter(item => item.isdeleted !== '1')?.length > 0
                                        ? undefined
                                        : () => this.openCaptivePortalTemplate()}
                            />
                        </div>
                        <div className="device-action">
                            <span className={"icon-box"}>
                                <img
                                    src="/images/svg-icons/edit.svg"
                                    alt=""
                                    className={this.state.selectedCP?.length === 1 ? undefined : "disabled"}
                                    onClick={
                                        this.state.selectedCP?.length === 1
                                            ? () => this.openCaptivePortalTemplate(this.state?.selectedCP?.[0])
                                            : undefined}
                                    disabled={this.state.selectedCP?.length !== 1}
                                />
                            </span>
                            <span className={"icon-box"}>
                                <img
                                    src="/images/svg-icons/delete.svg"
                                    alt=""
                                    className={this.state.selectedCP?.length === 1 ? undefined : "disabled"}
                                    onClick={this.state.selectedCP?.length === 1 ? () => this.deleteCapticePortal() : undefined}
                                />
                            </span>
                        </div>
                    </div>
                    {this.state.template_schema_json.data.captive_portal?.filter(item => item.isdeleted !== '1')?.length === 1 &&
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => this.selectAll(e)}
                                                checked={this.state.selectedCP?.length === this.state.template_schema_json?.data?.captive_portal?.length}
                                            />
                                        </th>
                                        <th>Name</th>
                                        {(this.props.apply || this.props.edit) &&
                                            <th>Network<span className="red-txt">*</span>
                                            </th>}
                                        <th>Advance Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.template_schema_json.data?.captive_portal?.filter(item => item.isdeleted !== '1')?.map(
                                        (item) => (
                                            <tr>
                                                <>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.selectedCP?.filter(data => (data.id === item.id))?.length > 0}
                                                            onClick={(e) => this.selectedCP(e, item)}
                                                        />
                                                    </td>
                                                    <td>{
                                                        <Link
                                                            to={{}}
                                                            className="txt-blue"
                                                            onClick={() => this.openCaptivePortalTemplate(item)} >
                                                            {item?.profile_name}
                                                        </Link>
                                                    }
                                                    </td>
                                                    {
                                                        (this.props.apply || this.props.edit) &&
                                                        <td width={125}>
                                                            <div className="dropdown">
                                                                <ul className="chip-list z-index-9 radius">
                                                                    {item?.networks?.map((network) => {
                                                                        // EMGT-700 network deleted after applying, hide that network 
                                                                        return (this.getNetworkName(network) && <li>
                                                                            {this.getNetworkName(network)}
                                                                            {<img
                                                                                src="/images/svg-icons/cancel.svg"
                                                                                alt=""
                                                                                id={network}
                                                                                onClick={(e) => this.handleChange(e, item)} />
                                                                            }
                                                                        </li>)
                                                                    })}
                                                                </ul>
                                                                <div
                                                                    className="chip-box add-arrow"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                </div>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    <ul>
                                                                        {
                                                                            this.state.networkViewData?.map((network) => {
                                                                                return (<li >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={network?.networkName}
                                                                                        checked={item?.networks?.filter(i => i === network?.networkName)?.length > 0}
                                                                                        onClick={(e) => this.handleChange(e, item)}
                                                                                    />
                                                                                    {network?.name}
                                                                                </li>)
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    }
                                                    <td>
                                                        <ul className="h-list width40">
                                                            <li className="head-list">Global Configuration:</li>
                                                            <li>
                                                                <span>Splash URL:</span>
                                                                <span className='splash_url' title={item?.splash_url}>{item?.splash_url}</span>
                                                            </li>
                                                            <li>
                                                                <span>Keyword:</span>
                                                                <span className='splash_url' title={item?.keyword}>{item?.keyword || "-"}</span>
                                                            </li>
                                                            <li className="head-list">
                                                                <span>
                                                                    RADIUS Configuration:</span>
                                                                <label className="switch gray">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item?.pRadius_enable === "1"}
                                                                    />
                                                                    <label className="slider round cp-switch" style={{ cursor: "not-allowed" }}></label>
                                                                </label>
                                                            </li>
                                                            {item?.pRadius_enable === "1" && <>
                                                                {/* <li><strong>Primary Server:</strong>
                                                                </li> */}
                                                                <li>
                                                                    <span>Authentication IP:</span>
                                                                    <span>{item?.pAuth_server_ip}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Authentication Port:</span>
                                                                    <span>{item?.pAuth_server_port}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Authentication Secret:</span>
                                                                    <span>{item?.pAuth_server_pass}</span>
                                                                </li>
                                                                {/* <li>
                                                                    <span><strong>Secondary Server:</strong></span>
                                                                    <label className="switch gray">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={item.sRadius_enable === "1"}
                                                                        />
                                                                        <label className="slider round cp-switch" style={{ cursor: "not-allowed" }}></label>
                                                                    </label>
                                                                </li> 
                                                                {item.sRadius_enable === '1' && <>
                                                                    <li><span>Authentication IP:</span>
                                                                        <span>{item.sAuth_server_ip}</span>
                                                                    </li>
                                                                    <li><span>Authentication Port:</span>
                                                                        <span>{item.sAuth_server_port}</span>
                                                                    </li>
                                                                    <li><span>Authentication Secret:</span>
                                                                        <span>{item.sAuth_server_pass}</span></li>
                                                                </>} */}
                                                            </>}
                                                            <li className="head-list">
                                                                <span>Walled Garden:</span>
                                                                <label className="switch gray">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item?.walled_garden_enable === "1"}
                                                                    />
                                                                    <label className="slider round cp-switch" style={{ cursor: "not-allowed" }}></label>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                        {item?.walled_garden_enable === "1" &&
                                                            <ul className="chip-list d-flex">
                                                                {item?.walled_garden?.map((network) => {
                                                                    return (<li className="p-2">{network}</li>)
                                                                })
                                                                }
                                                            </ul>
                                                        }
                                                        <ul className="h-list width40">
                                                            <li className="head-list">
                                                                <span>Enabled:</span>
                                                                <label className="switch gray">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item?.isEnabled === "1"}
                                                                    />
                                                                    <label className="slider round cp-switch" style={{ cursor: "not-allowed" }}></label>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        <div className="s-footer">
                            <button
                                className="btn btn-light"
                                onClick={
                                    this.props.apply && !this.props.edit
                                        ? () => {
                                            this.props.closeAddTemplate()
                                        }
                                        : () => this.closeAll()
                                }
                            >
                                {this.props.apply && !this.props.edit && !this.props.reapply ? "Back" : "Cancel"}
                            </button>
                            <button
                                className="btn btn-primary"
                                disabled={this.checkError()}
                                onClick={
                                    this.props.apply
                                        ? (event) => this.debounceApply(this.applyTemplate, event)
                                        : () => this.saveData()
                                }
                            >
                                {this.props.apply
                                    ? "Apply"
                                    : this.state.selectedTemplateId !== ""
                                        ? "Update"
                                        : "Save"}
                            </button>
                        </div>
                    }
                </div>
                <div
                    className={
                        this.state.openAddTemplate ? "sidebar-slide  zindex10 w-400 second-open r-757"
                            : "sidebar-slide  zindex10 w-400"
                    }
                >
                    <div className="s-header">
                        <h1 className="ss-title">
                            {this.state.update ? "Edit Captive Portal Configuration" : "Add Captive Portal Configuration"}
                        </h1>
                        <button>
                            <img
                                src="/images/svg-icons/s-cancel.svg"
                                alt=""
                                onClick={() => this.cancelCaptivePortal()}
                            />
                        </button>
                    </div>
                    <div className="s-body cp-size">
                        <div className="form-group">
                            <label>
                                <strong>Global Configuration</strong>
                            </label>
                        </div>
                        <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Profile Name</label>
                                <span className="red-txt">*</span>
                                <div className="tooltip-cp" data-toggle="tooltip" data-placement="right"
                                    title="Unique name for the CP Configuration">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <input
                                    type="name"
                                    class="form-control"
                                    name="profile_name"
                                    value={this.state.profileData?.profile_name}
                                    onChange={this.setProfileData}
                                    onBlur={(e) => this.validate(e)}
                                />
                            </div>
                            <div className="col-md-12 error-txt" align="end" id="profile_name">1-20 Alphanumeric and Special characters allowed without space</div>
                        </div>
                        <div class="row form-group">
                            <div className='col-md-6'>
                                <label className='mt-1'>Splash URL</label>
                                <span className="red-txt">*</span>
                                <div className="tooltip-cp" data-toggle="tooltip" data-placement="right"
                                    title="URL where client is redirected to begin splash authentication process">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <input
                                    type="name"
                                    name="splash_url"
                                    class="form-control"
                                    value={this.state.profileData?.splash_url}
                                    onChange={this.setProfileData}
                                    onBlur={(e) => this.validate(e)}
                                    title={this.state.profileData?.splash_url}
                                />
                            </div>
                            <div className="col-md-12 error-txt" align="end" id="splash_url">Invalid URL</div>
                        </div>
                        <div class="row form-group">
                            <div className='col-md-6'>
                                <label className='mt-1'>Keyword</label>
                                <div className="tooltip-cp" data-toggle="tooltip" data-placement="right"
                                    title="Parameter for constructing the URL">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <input
                                    type="name"
                                    name="keyword"
                                    class="form-control"
                                    value={this.state.profileData?.keyword}
                                    onChange={this.setProfileData}
                                    onBlur={(e) => this.validate(e)}
                                    title={this.state.profileData?.keyword}
                                />
                            </div>
                            <div className="col-md-12 error-txt" align="end" id="keyword">1- 20 Alphanumeric and '_' allowed without space</div>
                        </div>
                        <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="mr-5"><strong>RADIUS Configuration</strong>
                                </span>
                                <label className="switch">
                                    <input type="checkbox" name="pRadius_enable"
                                        checked={this.state.profileData?.pRadius_enable === "1"}
                                        onChange={this.setProfileData} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.profileData?.pRadius_enable === '1' && <div>
                            {/* <div className="form-group">
                                <label className='head-list'>
                                    Primary Server
                                    <div
                                        className="tooltip-cp"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Internet protocol version"
                                    >
                                        <img src="/images/svg-icons/info.svg" alt='' />
                                    </div>
                                </label>
                            </div> */}

                            <div class="row form-group">
                                <div className='mt-1 col-md-6'>
                                    <label>Authentication IP</label>
                                    <span className="red-txt">*</span>
                                    <div
                                        className="tooltip-cp"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="RADIUS server IP">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <input
                                        type="name"
                                        class="form-control"
                                        name="pAuth_server_ip"
                                        value={this.state.profileData?.pAuth_server_ip}
                                        onChange={this.setProfileData}
                                        onBlur={(e) => this.validate(e)}
                                    />
                                    <div className="error-txt" align="end" id="pAuth_server_ip">Invalid IP</div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div className='col-md-6'>
                                    <label className='mt-1'>Authentication Port</label>
                                    <span className="red-txt">*</span>
                                    <div
                                        className="tooltip-cp"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="RADIUS server Port">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <input
                                        type="number"
                                        name="pAuth_server_port"
                                        class="form-control"
                                        value={this.state.profileData?.pAuth_server_port}
                                        onChange={this.setProfileData}
                                        onBlur={(e) => this.validate(e)}
                                        min="1"
                                        max="65535"
                                    />
                                    <div className="error-txt" align="end" id="pAuth_server_port">Keep alive port between 1 to 65535</div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div className='col-md-6'>
                                    <label className='mt-1'>Authentication Secret</label>
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-cp" data-toggle="tooltip" data-placement="right"
                                        title="RADIUS server Password">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <input
                                        type="name"
                                        name="pAuth_server_pass"
                                        class="form-control"
                                        value={this.state.profileData?.pAuth_server_pass}
                                        onChange={this.setProfileData}
                                        onBlur={(e) => this.validate(e)}
                                    />
                                    <div className="error-txt" align="end" id="pAuth_server_pass">Invalid Value</div>

                                </div>
                            </div>
                            {/* <div className="row form-group secondary_Server">
                                <div
                                    className='col-md-8' style={{ padding: "1px" }}
                                >
                                    <span className='head-list'>
                                        Secondary Server
                                    </span>
                                </div>
                                <div className='col-md-4'>
                                    <label>
                                        <img className="" style={{ width: "14%", marginLeft: "50px" }} src="../images/svg-icons/down-arrow.svg" alt=""
                                            name="sRadius_enable"
                                            onClick={this.setProfileData} />
                                    </label>
                                </div>
                            </div>
                            {this.state.profileData.sRadius_enable === '1' && <div>
                                <div class="row form-group">
                                    <div className='mt-1 col-md-6'>
                                        <label>Authentication IP</label>
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-cp" data-toggle="tooltip" data-placement="right"
                                            title="Radius server IP">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            type="name"
                                            class="form-control"
                                            name="sAuth_server_ip"
                                            value={this.state.profileData.sAuth_server_ip}
                                            onChange={this.setProfileData}
                                            onBlur={(e) => this.validate(e)}
                                        />
                                        <div className="error-txt" id="sAuth_server_ip">Invalid IP</div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div className='col-md-6'>
                                        <label className='mt-1'>Authentication Port</label>
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-cp" data-toggle="tooltip" data-placement="right"
                                            title="Radius server Port">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            type="number"
                                            name="sAuth_server_port"
                                            class="form-control"
                                            value={this.state.profileData.sAuth_server_port}
                                            onChange={this.setProfileData}
                                            onBlur={(e) => this.validate(e)}
                                            min="1"
                                            max="65535"
                                        />
                                        <div className="error-txt" id="sAuth_server_port">Keep alive port between 1 to 65535</div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div className='col-md-6'>
                                        <label className='mt-1'>Authentication Secret</label>
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-cp" data-toggle="tooltip" data-placement="right"
                                            title="Radius server Password">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            type="name"
                                            name="sAuth_server_pass"
                                            class="form-control"
                                            value={this.state.profileData.sAuth_server_pass}
                                            onChange={this.setProfileData}
                                            onBlur={(e) => this.validate(e)}
                                        />
                                        <div className="error-txt" id="sAuth_server_pass">Invalid value</div>
                                    </div>
                                </div>
                            </div>
                            } */}
                        </div>
                        }
                        <div class="form-group">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="mr-5">
                                    <strong>Walled Garden</strong>
                                    {this.state.profileData?.walled_garden_enable === '1' &&
                                        <span className="red-txt">*</span>}
                                    <div
                                        className="tooltip-cp"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Domain suffixes the client can access without authenticating first">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        name="walled_garden_enable"
                                        onChange={this.setProfileData}
                                        checked={this.state.profileData?.walled_garden_enable === '1'} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.profileData?.walled_garden_enable === '1' &&
                            <div className="chip-list-border cp">
                                {this.state.profileData?.walled_garden?.map(i => <div
                                    className="chip-set"
                                    id={i}>
                                    {i}
                                    <img src="/images/svg-icons/cancel.svg"
                                        id={i}
                                        alt=""
                                        onClick={e => this.enterDomain(e, true)}
                                    />
                                </div>)}
                                <textarea
                                    onKeyDown={(e) => this.handleKeyDown(e)}
                                    type="text"
                                    onChange={e => this.setState({ enterBlValue: e.target.value })}
                                    value={this.state?.enterBlValue}
                                    onBlur={(e) => this.enterDomain(e)}
                                />
                            </div>}
                        {this.state.profileData?.walled_garden_enable === '1' &&
                            this.state.suggestWallenEnable &&
                            this.state.profileData?.splash_url !== "" &&
                            <>
                                <div class="row form-group">
                                    <div class="col-md-12">
                                        <label className='mt-1'>Suggested Walled Garden</label>
                                        <div
                                            className="tooltip-cp"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Click it to add in Walled Garden">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group mt-n2">
                                    <div class="col-md-12">
                                        <div className="gray-bg rounded">
                                            < ul className="chip-list">
                                                {this.state?.splashLabel?.map((item) => {
                                                    return (<li
                                                        onClick={(item) => this.suggestWallenGarden(item?.target?.innerText)}
                                                        className='splashSuggest'
                                                        style={{ cursor: "Pointer" }}>
                                                        {item}
                                                    </li>)
                                                })}
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </>}
                        <div class="form-group">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="mr-5">
                                    <strong>Enabled</strong>
                                    {this.state.profileData?.isEnabled === '1' &&
                                        <span className="red-txt">*</span>
                                    }
                                    <div
                                        className="tooltip-cp"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Enable/Disable Captive portal profile">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        name="isEnabled"
                                        onChange={this.setProfileData}
                                        checked={this.state.profileData?.isEnabled === '1'} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="s-footer">
                        <button
                            className="btn btn-light"
                            onClick={() => this.cancelCaptivePortal()}
                        >
                            Cancel
                        </button>
                        <button
                            disabled={this.disableCheck()}
                            className="btn btn-primary"
                            onClick={() => this.addProfile()}
                        >
                            {this.state.update ? 'Update' : 'Add'}
                        </button>
                    </div>
                </div>
                {
                    (this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying,
    };
};
export default connect(mapStateToProps)(CaptivePortal);
