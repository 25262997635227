import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import * as Action from '../../redux/actionTypes';
import AddDeviceGroup from './addDeviceGroup';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import '../DataTableDemo.css';
import ErrorHandlePopup from "../ErrorHandlePopup";
import { LinkPopup } from '../../layOuts/linkPopup';
class ConfiguredDeviceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            multipleSelect: "",
            toggledClearRows: false,
            showLoader: false,
            selectedTemplate: 1,
            selectedOption: [],
            filterList: this.props?.location?.state?.filterList ? this.props?.location?.state?.filterList : [],
            modelFilterList: [],
            nameFilterList: [],
            group_name: '',
            title: '',
            message: '',
            show: false,
            error: false,
            delete: false,
            factoryReset: false,
            reboot: false,
            pageNo: 1,
            pageSize: 10,
            first: 1,
            last: this.props?.location?.state?.last ? this.props?.location?.state?.last : 10,
            next: true,
            prev: true,
            firstDefault: 0,
            modelList: [],
            deviceName: [],
            deviceListWithId: [],
            deviceId: "",
            deviceModel: this.props?.location?.state?.deviceModel ? this.props?.location?.state?.deviceModel : "",
            count: 0,
            totalCount: this.props?.location?.state?.totalCount ? this.props?.location?.state?.totalCount : 0,
            showFilter: this.props?.location?.state?.showFilter ? this.props?.location?.state?.showFilter : false,
            showIcon: this.props?.location?.state?.showIcon ? this.props?.location?.state?.showIcon : false,
            deviceData: this.props?.location?.state?.deviceData ? this.props?.location?.state?.deviceData : [],
            id: '',
            showLink: false,
            deviceGroupDetails: [],
            sortOrder: 1,
            flag: 0,
            sortField: '',
            sortOn: false,
            isAxiosError: false,
            deviceColumns: [
                {
                    field: 'group_name', header: 'Group Name', value: 'Group Name', body: this.groupNameTemplate,
                    frozen: false,
                    alignFrozen: 'left',
                    sortable: true,
                    style: { width: '200px', flexGrow: 1, minWidth: '150px' }
                },
                {
                    field: 'supported_models', header: 'Model', value: 'Model', body: this.modelTemplate, frozen: false,
                    sortable: true,
                    style: { width: '90px', flexGrow: .1, minWidth: '100px' }
                },
                {
                    field: 'attached_devices', header: 'No.of Devices', value: 'No.of Devices',
                    body: this.attachedDeviceTemplate,
                    frozen: false,
                    sortable: true,
                    style: { width: '100px', flexGrow: .5, minWidth: '110px', justifyContent: 'center' }
                },
                {
                    field: 'attached_template_count', header: 'Templates', frozen: false, value: 'Templates',
                    sortable: true,
                    body: this.attachedTemplate,
                    style: { width: '300px', flexGrow: 1, minWidth: '200px' }
                },
                {
                    field: 'alerts', header: 'Alarms', frozen: false, value: 'Alarms',
                    // sortable: true,
                    body: this.alarmsTemplate,
                    style: { width: '170px', flexGrow: .5, minWidth: '130px' }
                },
                {
                    field: 'Action', header: '', body: this.actionTemplate, value: 'Action',
                    style: { flexGrow: 1, flexBasis: '120px' },
                    // sortable: false,
                    alignFrozen: 'right', frozen: true
                }
            ],
            showModel: false,
            is_bolt_reset: false

        };
    }

    async componentDidMount() {
        await this.getDeviceGroupList();
    }

    getDeviceGroupList = async (sort) => {
        if (this.state.deviceModel !== "") {
            this.getDeviceModels();
        }
        else {
            this.setState({
                showLoader: true,
            });
            let sorting_parameter = sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date'
            let sorting_type = sort === 'sort' ? this.state.order : 'descending'
            await ApiCall(urlMapping.getDeviceGroupList(sorting_parameter, sorting_type, this.state.pageNo, this.state.pageSize), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.results) {
                    const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
                    const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
                    this.setState({
                        next: response?.next ? false : true,
                        prev: response?.previous ? false : true,
                        first: first,
                        last: last,
                        deviceData: response?.results,
                        totalCount: response?.count,
                        filterList: response?.results
                    })
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        }
    }
    openOperationList = (value) => {
        $("#exampleModal").modal("hide");
        this.props.history.push({
            pathname: '/operations',
            state: { data: value }
        })
    }
    closeLinkpopup = () => {
        $("#exampleModal").modal("hide");
        this.setState({ showLink: false, id: '' });
    }
    getGroupDetails = (id) => {
        let data = { group_id: id }
        this.setState({ showLoader: true, id: id })
        ApiCall(urlMapping.deviceGroupList(data), (response) => {
            this.setState({ showLoader: false })
            if (response?.Success) {
                this.setState({
                    deviceGroupDetails: response?.data
                }, () => this.openAddDeviceGroup())
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    confirmDelete = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Delete Device Groups' : 'Delete Device Group',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to delete device Groups?' : 'Are you sure you want to delete device Group?',
            show: true,
            error: true,
            delete: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    checkDeviceTask = async () => {
        let deviceMac = [];

        this.state.multipleSelect.map(item => (
            deviceMac = [...item.device_macs]
        ));

        let data = { device_mac: deviceMac, operation_type: ['Firmware Upgrade'] }
        this.setState({
            showLoader: true
        });

        const trueArr = [];
        await ApiCall(urlMapping.checkDeviceTask(data), response => {
            this.setState({
                showLoader: false,
                show: false
            });
            $("#errorMessage").modal("hide");
            if (response?.success === true) {
                const data = response?.data;
                Object.keys(data)?.forEach(item => {
                    if (data?.[item]?.status === true) {
                        trueArr?.push(data?.[item]);
                    }
                });
                if (trueArr?.length > 0) {
                    this.setState({
                        title: 'Device Task',
                        message: trueArr?.[0]?.message,
                        show: true,
                        error: true,
                        delete: false,
                        toggledClearRows: !this.state.toggledClearRows,
                    }, () => $("#errorMessage").modal("show"));
                }
                this.setState({
                    show: false,
                    error: false,
                    delete: false
                })

            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Device Task',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
        return trueArr;
    }
    deleteDeviceGroup = async () => {
        const haveTask = await this.checkDeviceTask();

        let groupId = [];
        this.state.multipleSelect.map(element => (
            groupId.push(element.group_id)
        ));
        let data = { group_list: groupId }
        this.setState({ showLoader: true });

        if (haveTask.length === 0) {
            ApiCall(urlMapping.deleteDeviceGroup(data), (response) => {
                this.setState({ showLoader: false })
                $("#errorMessage").modal("hide");
                if (response?.success) {
                    this.setState({
                        title: 'Factory Reset Device Group',
                        message: response?.message,
                        show: true,
                        delete: false,
                        error: false
                    }, () => $("#errorMessage").modal("show"))
                    this.getDeviceGroupList();
                }
                else {
                    $("#errorMessage").modal("hide");
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        this.setState({
                            title: 'Factory Reset Device Group',
                            message: response?.message,
                            show: true,
                            delete: false,
                            error: true,
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        } else {
            this.setState({ showLoader: false })
        }
    }


    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    }
    //EMGT-400-EM - Able to Click reboot button when device Offline state in Device group
    checkOnlineDevices = () => {
        let result = false;
        if (
            this.state.multipleSelect.filter((item) => item.is_all_device_online === 0)
                .length > 0
        )
            result = true;
        return result;
    };

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            // Long-1629 Reboot and Factory reset buttons become disable when clicked cancel in delete conformation
            multipleSelect: '',
            toggledClearRows: !this.state.toggledClearRows
        }, () => $("#errorMessage").modal("hide"))
        this.getDeviceGroupList();
    }

    hideDropdown = (e) => {
        var popup = $("#parentDiv");
        if (!$('#open').is(e.target) && !popup.is(e.target) && popup.has(e.target).length === 0) {
            this.setState({ showFilter: false, deviceModel: this.state.showIcon ? this.state.deviceModel : "" })
        }
    }

    openAddDeviceGroup = () => {
        this.props.dispatch({
            type: Action.OPEN_UPDATE_FIRMWARE,
        });
    }

    closeAddDeviceGroup = () => {
        this.props.dispatch({
            type: Action.CLOSE_UPDATE_FIRMWARE,
        });
        this.setState({ deviceGroupDetails: [], id: '' })
    }

    confirmFactoryReset = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Bootstrap Reset Device Groups' : 'Bootstrap Reset Device Group',
            message: this.state.multipleSelect.length > 1 ?
                'Bootstrap reset will remove all the template/tunnel linked to this device.It doesn\'t affect ZTP.'
                : 'Bootstrap reset will remove all the template/tunnel linked to these devices.It doesn\'t affect ZTP.',
            show: true,
            error: true,
            delete: true,
            factoryReset: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    confirmReboot = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Reboot Device Groups' : 'Reboot Device Group',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to reboot device Groups?' : 'Are you sure you want to reboot device Group?',
            show: true,
            error: true,
            delete: true,
            reboot: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    checkBoltAction = () => {
        $("#errorMessage").modal("hide");
        //LONG-2242-EM - Bootstrap page - name for Bolt and cancel button in bolt popup 
        this.setState(
            {
                title: "Factory Reset BOLT Device Group",
                message: "Do you want to Factory Reset the BOLT as well? (Factory Resetting Bolt will reset the APN Configurations)",
                show: false,
                error: true,
                delete: true,
                showModel: true,
                factoryReset: true,
            },
            () => {
                $("#errorMessage").modal("show");
            }
        );
    }
    chooseAction = (deleteAct, factoryResetAct, rebootAct) => {
        if (deleteAct && factoryResetAct) {
            this.factoryResetDeviceGroup();
        }
        else if (deleteAct && rebootAct) {
            this.rebootDeviceGroup();
            this.setState({ reboot: false })
        }
        else if (deleteAct) {
            this.deleteDeviceGroup();
        }
        else {
            return "undefined";
        }
    }

    factoryResetDeviceGroup = async () => {
        const haveTask = await this.checkDeviceTask();

        let groupId = ''
        this.state.multipleSelect.map(element => (
            groupId = element.group_id
        ));
        let data = { group_id: groupId, is_bolt_reset: this.state.is_bolt_reset }
        this.setState({
            showLoader: true,
            show: false
        });
        if (haveTask.length === 0) {
            ApiCall(urlMapping.deviceGroupFactoryReset(data), (response) => {
                if (response?.success) {
                    $("#errorMessage").modal("hide");
                    this.setState({
                        title: 'Bootstrap Reset Devices',
                        message: response?.message,
                        error: false,
                        toggledClearRows: !this.state.toggledClearRows,
                        multipleSelect: '',
                        showLink: true,
                        delete: false,
                        factoryReset: false,
                        is_bolt_reset: false
                    }, () => $("#exampleModal").modal("show"));
                    this.getDeviceGroupList();
                }
                else {
                    $("#errorMessage").modal("hide");
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showLoader: false,
                            showRefreshModal: true
                        });
                    } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        this.setState({
                            title: 'Bootstrap Reset Devices',
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                            factoryReset: false,
                            is_bolt_reset: false
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            })
        } else {
            this.setState({
                showLoader: false
            });
        }
    }

    setFilterList = () => {
        this.setState((prevState) => ({
            showFilter: !prevState.showFilter, modelFilterList: [...new Set(this.state.filterList.map((obj) => obj.supported_models))],

        }));

    }
    getModelDeviceList = (e) => {
        if (e.target.value !== 'Select') {
            this.setState({ deviceModel: e.target.value })
        }
    }

    getDeviceModels = () => {
        this.setState({ showLoader: true })
        let data = { device_model: this.state.deviceModel }
        ApiCall(urlMapping.getGroupsByModel(data), (response) => {
            this.setState({ showLoader: false })
            if (response?.results) {
                this.setState({
                    deviceData: response?.results,
                    count: response?.count,
                    showFilter: false,
                    showLoader: false,
                    showIcon: true,
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Get Groups by model',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
    }
    clearDropdown = (type) => {
        this.setState({ deviceModel: "" })
    }
    resetValue = () => {
        this.setState({ deviceModel: '', group_name: '', filterList: [], modelFilterList: [], showFilter: false, showIcon: false, pageNo: 1 }, () => this.getDeviceGroupList())
    }
    rebootDeviceGroup = async () => {
        const haveTask = await this.checkDeviceTask();
        let groupId = ''
        this.state.multipleSelect.map(element => (
            groupId = element.group_id
        ));
        let data = { group_id: groupId }
        this.setState({
            showLoader: true
        });
        if (haveTask.length === 0) {
            ApiCall(urlMapping.deviceGroupReboot(data), (response) => {
                this.setState({
                    showLoader: false
                });
                $("#errorMessage").modal("hide");

                if (response?.success) {
                    this.setState({
                        title: 'Reboot Devices',
                        message: response?.message,
                        show: true,
                        error: false,
                        toggledClearRows: !this.state.toggledClearRows,
                        multipleSelect: '',
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                    this.getDeviceGroupList();
                }
                else {
                    $("#errorMessage").modal("hide");
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        this.setState({
                            title: 'Reboot Devices',
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            })
        } else {
            this.setState({
                showLoader: false
            });
        }
    }

    onSelectRow = (e) => {
        if (e.value.length === 0) {
            this.setState({
                multipleSelect: ''
            })
        } else {
            this.setState({
                multipleSelect: e.value
            })
        }
    }
    groupNameTemplate = (row) => {
        if (row.attached_devices > 0) {
            return (
                <span title={row.group_name} style={{ width: 'auto' }} className="cursor-pointer"
                >
                    <Link to={{
                        pathname: '/devicegroupdetails',
                        state: {
                            data: row, showIcon: this.state.showIcon, deviceData: this.state.deviceData,
                            totalCount: this.state.totalCount, showFilter: this.state.showFilter, deviceModel: this.state.deviceModel, last: this.state.last,
                            filterList: this.state.filterList,
                            editTunnelSelectedTab: undefined,
                            editTunnel_group_id: undefined
                        }
                    }} className="txt-blue">{row.group_name}</Link>
                </span>
            )
        } else {
            return (<span title={row.group_name} style={{ width: 'auto' }}>{row.group_name}</span>)
        }
    }
    modelTemplate = (row) => {
        return (
            <span className="dm-cell">
                <ul className="dm-list">
                    <li title={(row.supported_models).split(',')[0]}>{(row.supported_models).split(',')[0]}</li>
                    {(row.supported_models).split(',').length > 1 &&
                        <li title={(row.supported_models).split(',')[1]}>{(row.supported_models).split(',')[1]}</li>
                    }
                </ul>
                {(row.supported_models).split(',').length > 2 &&
                    <span className="show-full-model" title={(row.supported_models)}>
                        {`+${(row.supported_models).split(',').length > 2 ? (row.supported_models).split(',').length - 2 : ''}`}</span>
                }
            </span>
        )
    }
    attachedTemplate = (row) => {
        return (
            <span className="dm-cell">
                {row.attached_template_count.map((item) => {
                    return (<span className="dm-list" title={item} key={item}>{item}</span>)
                })}
            </span>
        )
    }
    alarmsTemplate = (row) => {
        return (
            <span className="dm-cell">
                <ul className="alarms-list da-list">
                    <li title='Medium' className="average">{row?.alerts?.average}</li>
                    <li title='Critical' className="emergency">{row?.alerts?.disaster}</li>
                    <li title='High' className="critical">{row?.alerts?.high}</li>
                    <li title='Information' className="information">{row?.alerts?.information}</li>
                    <li title='Low' className="warning">{row?.alerts?.warning}</li>
                </ul>
            </span>
        )
    }
    actionTemplate = (row) => {
        return (
            <div className="da-box dropleft">
                <img className="a-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
                <div className="drop-box dropdown-menu">
                    <ul>
                        <li onClick={row.attached_devices > 0 ? () => this.props.history.push({
                            pathname: "/devicegroupdetails",
                            state: {
                                data: row,
                                editTunnelSelectedTab: undefined,
                                editTunnel_group_id: undefined
                            }
                        }) : undefined}><img src="/images/svg-icons/view.svg" alt=""
                            title={row.attached_devices === 0 ? "No device in the group" : ""} /> View</li>
                        <li
                            className={
                                (this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? "disabled"
                                    : ""
                            }
                            onClick={() =>
                                (this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? undefined
                                    : this.getGroupDetails(row.group_id)}>
                            <img className="edit" src="/images/svg-icons/edit.svg" alt="" />Edit</li>
                        <li
                            className={
                                (this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? "disabled"
                                    : ""
                            }
                            onClick={() =>
                                (this.props?.userRole === "Viewer_Group" ||
                                    this.props?.userRole === "Operator_Group")
                                    ? undefined
                                    : this.setState({ multipleSelect: [row] }, () => this.confirmDelete())
                            }>
                            <img className="delete" src="/images/svg-icons/delete.svg" alt="" /> Delete</li>
                    </ul>
                </div>
            </div>
        )
    }
    attachedDeviceTemplate = (row) => {
        return (
            <span title={row.attached_devices} style={{ width: 'auto' }}>
                {row.attached_devices}</span>
        )
    }
    setPerPage = (e) => {
        this.setState({
            pageNo: 1,
            pageSize: e.value
        }, () => this.state.sortOn === true ? this.getDeviceGroupList('sort') : this.getDeviceGroupList());
    };

    nextPageChangeHandle = () => {
        this.setState({
            pageNo: this.state.pageNo + 1
        }, () => this.state.sortOn === true ? this.getDeviceGroupList('sort') : this.getDeviceGroupList());
    };

    prevPageChangeHandle = () => {
        this.setState({
            pageNo: this.state.pageNo - 1
        }, () => this.state.sortOn === true ? this.getDeviceGroupList('sort') : this.getDeviceGroupList());
    };
    firstPageChangeHandle = () => {
        this.setState({
            pageNo: 1
        }, () => this.state.sortOn === true ? this.getDeviceGroupList('sort') : this.getDeviceGroupList());
    }
    lastPageChangeHandle = () => {
        this.setState({
            pageNo: Math.ceil(this.state.count / this.state.pageSize)
        }, () => this.state.sortOn === true ? this.getDeviceGroupList('sort') : this.getDeviceGroupList());
    }
    newDeviceGroupSort = (columnSort) => {
        if (this.state.flag < 2) {
            this.setState({
                sortField: columnSort.sortField,
                sortOrder: columnSort.sortOrder,
                order: columnSort.sortOrder === 1 ? 'ascending' : 'descending',
                sortOn: true,
                flag: this.state.flag + 1,
            }, () => this.getDeviceGroupList("sort")
            )
        } else {
            this.setState({
                sortField: null,
                order: 'descending',
                sortOn: true,
                flag: 0,
            }, () => this.getDeviceGroupList("sort")
            )
        }
    }

    render() {
        let colArray = this.state.selectedOption.length === 0 ? this.state.deviceColumns : this.state.selectedOption;
        const dynamicColumns = colArray.map((col, i) => {
            return <Column key={col.field} field={col.field} resizable={col.resizable}
                sortable={col.sortable}
                header={col.header} body={col.body} style={col.style} alignFrozen={col.alignFrozen} frozen={col.frozen} />;
        });

        const template2 = {
            layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: "10", value: 10 },
                    { label: "20", value: 20 },
                    { label: "30", value: 30 },
                    { label: "40", value: 40 },
                    { label: "50", value: 50 }
                ];

                return (
                    <React.Fragment>
                        <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Rows per page: </span>
                        <Dropdown value={this.state.pageSize} options={dropdownOptions} onChange={(e) => this.setPerPage(e)} />
                    </React.Fragment>
                );
            },
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {this.state.first} - {this.state.last} of {this.state.totalCount}
                    </span>
                )
            },
            'FirstPageLink': () => {
                return (
                    <button type="button"
                        className=" p-link1"
                        onClick={() => this.firstPageChangeHandle()}
                        disabled={this.state.prev}
                    >
                        <img
                            className={this.state.prev ? "p-link3" : "p-link2"}
                            src={this.state.prev ? "/images/svg-icons/arrow-pre-disabled.svg" : "/images/svg-icons/arrow-pre.svg"}
                            alt=""
                        />
                    </button>
                )
            },
            'PrevPageLink': () => {
                return (
                    <button type="button"
                        className=" p-link1"
                        onClick={() => this.prevPageChangeHandle()}
                        disabled={this.state.prev}
                    >
                        <i className="pi pi-chevron-left"></i>
                    </button>
                )
            },
            'NextPageLink': () => {
                return (
                    <button
                        type="button"
                        className="p-link1"
                        onClick={() => this.nextPageChangeHandle()}
                        disabled={this.state.next}
                    >
                        <i className="pi pi-chevron-right"></i>
                    </button>
                )
            },
            'LastPageLink': () => {
                return (
                    <button type="button"
                        onClick={() => this.lastPageChangeHandle()}
                        className=" p-link1"
                        disabled={this.state.next}
                    >
                        <img
                            className={this.state.next ? "p-link3" : "p-link2"}
                            src={this.state.next ? "/images/svg-icons/arrow-next-disabled.svg" : "/images/svg-icons/arrow-next.svg"}
                            alt=""
                        />
                    </button>
                )
            }
        };
        return (
            <div onMouseDown={(e) => this.hideDropdown(e)}>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <Header />
                    <div className="white-bg  page-height">
                        <div className="ta-box">
                            <h1 className="page-title"> Device Groups <img src="/images/svg-icons/add.svg"
                                className={
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? "add-btn disabled"
                                        : "add-btn"}
                                alt=""
                                onClick={() =>
                                    (this.props?.userRole === "Viewer_Group" ||
                                        this.props?.userRole === "Operator_Group")
                                        ? undefined
                                        : this.openAddDeviceGroup()
                                } />
                            </h1>
                            <div className="action-btn">

                                <button onClick={() => this.getDeviceGroupList()}><img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>
                                {<button
                                    className="button-lst"
                                    onClick={() => this.confirmFactoryReset()}
                                    disabled={
                                        (this.props?.userRole === "Viewer_Group" ||
                                            this.props?.userRole === "Operator_Group") ||
                                        this.state.multipleSelect.length > 1 ||
                                        this.state.multipleSelect === "" ||
                                        this.checkOnlineDevices()
                                    }><img src="/images/svg-icons/refreshing.svg" alt="" /> Bootstrap Reset</button>}
                                {/*EMGT-400-EM - Able to Click reboot button when device Offline state in Device group */}
                                {<button
                                    className="button-lst"
                                    onClick={() => this.confirmReboot()}
                                    disabled={
                                        this.props?.userRole === "Viewer_Group" ||
                                        this.state.multipleSelect.length > 1 ||
                                        this.state.multipleSelect === "" ||
                                        this.checkOnlineDevices()
                                    }><img src="/images/svg-icons/retry.svg" alt="" /> Reboot</button>}
                                <button
                                    className="button-lst"
                                    disabled={
                                        this.state.multipleSelect === '' ||
                                        (this.props?.userRole === "Viewer_Group" ||
                                            this.props?.userRole === "Operator_Group")
                                    }
                                    onClick={() => this.confirmDelete()} ><img src="/images/svg-icons/delete.svg" alt="" /> Delete</button>
                                <div className="btn-group" >
                                    {/* disabled filter button if no data */}
                                    <button type="button" className="dropdown-toggle  filter-btn" disabled={this.state.deviceData.length === 0} onClick={() => this.setFilterList()}>
                                        {(this.state.showIcon) ?
                                            <img src="/images/svg-icons/info-icon.svg" alt="" />
                                            :
                                            <img src="/images/svg-icons/filter.svg" alt="" />
                                        }
                                        Filter</button>
                                    {this.state.showFilter && <div className={this.state.showFilter ? "dropdown-menu show  dropdown-menu-right filter-section width360" : "dropdown-menu dropdown-menu-right filter-section width360"} id="parentDiv">
                                        <div className="form-group">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label>Model</label>
                                                <span onClick={() => this.clearDropdown("Model")}>Clear</span>
                                            </div>
                                            <select className="form-control" value={this.state.deviceModel ? this.state.deviceModel : "Select"} onChange={(e) => this.getModelDeviceList(e)}>
                                                <option value="Select">Select Model</option>
                                                {
                                                    // eslint-disable-next-line array-callback-return
                                                    this.state.modelFilterList.map((item) => {
                                                        if (item !== "")
                                                            return (<option value={item}>{item}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="action-box">
                                            <button className="btn btn-light" onClick={() => this.resetValue()}>Reset</button>
                                            {/* Apply button disabled issue is fixed */}
                                            <button className="btn btn-primary" onClick={() => this.getDeviceModels()} disabled={this.state.deviceModel === "" || this.state.deviceModel === "Select"} >Apply</button>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <DataTable
                                value={this.state.deviceData}
                                onSort={(columnSort) => {
                                    this.newDeviceGroupSort(columnSort);
                                }}
                                sortField={this.state.sortField}
                                sortOrder={this.state.sortOrder}
                                scrollable scrollHeight="400px"
                                loading={this.state.loading}
                                removableSort
                                resizableColumns
                                columnResizeMode="fit"
                                showGridlines
                                paginator
                                paginatorTemplate={template2}
                                first={this.state.firstDefault}
                                rows={this.state.pageSize}
                                paginatorClassName="p-jc-end"
                                selectionMode="checkbox"
                                selection={this.state.multipleSelect}
                                onSelectionChange={e => this.onSelectRow(e)}
                                dataKey="group_id" scrollDirection="both" className="p-mt-3">
                                <Column selectionMode="multiple" style={{ flexGrow: 1, flexBasis: '80px' }} ></Column>
                                {dynamicColumns}
                            </DataTable>
                        </div>

                    </div>
                    {this.props.openUpdateFirmware &&
                        <AddDeviceGroup
                            id={this.state.id}
                            props={this.props}
                            deviceGroupDetails={this.state.deviceGroupDetails}
                            closeAddDeviceGroup={() => this.closeAddDeviceGroup()}
                            getDeviceGroupList={() => this.getDeviceGroupList()}
                        />}
                </section>
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                        onSubmit={() => this.chooseAction(this.state.delete, this.state.factoryReset, this.state.reboot)}
                    />
                }

                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {this.state.showModel &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        factoryReset={this.state.factoryReset}
                        dataReset={this.state.dataReset}
                        reset={() => this.resetPopup()}
                        onCancel={() => this.factoryResetDeviceGroup()}
                        onSubmit={() => {
                            this.setState({ is_bolt_reset: true }, () =>
                                this.factoryResetDeviceGroup())
                        }} />}
                {this.state.showLink &&
                    <LinkPopup
                        title={this.state.title}
                        message={this.state.message}
                        open={() => this.openOperationList()}
                        close={() => this.closeLinkpopup()}
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>
        );

    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openUpdateFirmware: state.displayUpdateFirmware.openUpdateFirmware,
        userRole: String(state.auth.role)
    });
}
export default withRouter(connect(mapStateToProps)(ConfiguredDeviceList));