import React, { Component } from 'react'
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from "jquery";
import * as Common from "./../../utils/common";
import { encode as base64_encode } from 'base-64';
import ErrorHandlePopup from "../ErrorHandlePopup";

class AddNewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mac: "",
            addUser: "",
            response: "",
            show: false,
            userId: this.props.userId,
            showLoader: false,
            showRefreshModal: false,
            title: '',
            group: '',
            message: '',
            error: false,
            validPassword: "",
            userData: {
                firstName: this.props.userFirstName ? this.props.userFirstName : "",
                lastName: this.props.userLastName ? this.props.userLastName : "",
                email: this.props.userEmailId ? this.props.userEmailId : "",
                username: this.props?.multiselect?.[0]?.[0]?.username || "",
                password: "",
                role:
                    this.props?.multiselect?.[0]?.[0]?.is_superuser
                        ? "true"
                        : (
                            this.props?.multiselect?.[0]?.[0]?.group_name !== ""
                                ? (
                                    this.props?.multiselect?.[0]?.[0]?.group_name === "Viewer_Group"
                                        ? "viewer"
                                        : (
                                            this.props?.multiselect?.[0]?.[0]?.group_name === "Operator_Group"
                                                ? "operator"
                                                : (
                                                    this.props?.multiselect?.[0]?.[0]?.group_name === "Admin_Group"
                                                        ? "false"
                                                        : ""
                                                )
                                        )
                                )
                                : "false"
                        )
            },
            isAxiosError: false
        }

    }
    handleUpdateUser = () => {
        if ((this.state.firstname !== '')
            || (this.state.lastname !== '')) {
            let groupName = String(this.state.userData.role);
            let data = {
                first_name: this.state.userData.firstName,
                last_name: this.state.userData.lastName,
                email: this.state.userData.email,
                group_name:
                    groupName === "true"
                        ? "superuser_group"
                        : (groupName === "false"
                            ? "Admin_Group"
                            : (groupName === "viewer"
                                ? "Viewer_Group"
                                : (groupName === "operator"
                                    ? "Operator_Group"
                                    : ""
                                )
                            )
                        )
            }

            this.setState({
                showLoader: true,
            });
            ApiCall(urlMapping.updateUser(data, this.state.userId), (response) => {
                if (response?.success) {
                    this.setState({
                        showLoader: false,
                        title: 'Update User',
                        message: response?.message,
                        response: response,
                        show: true,
                        error: false,
                    }, () => {
                        this.props.UserList()
                        $("#errorMessage").modal("show");
                    });
                }
                else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showLoader: false,
                            showRefreshModal: true
                        })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            title: 'Update User',
                            message: response?.message,
                            show: true,
                            delete: false,
                            error: true,
                        }, () => $("#errorMessage").modal("show"))
                    }

                }

            })
        }
    }
    handleAddUser = () => {
        if ((this.state.userData.firstName !== '') && (this.state.userData.lastName !== '')
            && (this.state.userData.password !== '') && (this.state.userData.username !== '')
            && (this.state.userData.rule !== '')) {
            let encodePassword = base64_encode(this.state.userData.password);
            let data = {
                username: this.state.userData.username,
                password: encodePassword,
                first_name: this.state.userData.firstName,
                last_name: this.state.userData.lastName,
                email: this.state.userData.email ? this.state.userData.email : "",
                is_superuser: this.state.userData.role === 'true' ? true : false,
                group_name:
                    this.state.userData.group
                        ? this.state.userData.group
                        : (this.state.userData.role === "viewer"
                            ? "Viewer_Group"
                            : (this.state.userData.role === "operator"
                                ? "Operator_Group"
                                : ""
                            )
                        )
            }
            this.setState({
                showLoader: true,
            });
            ApiCall(urlMapping.addUserList(data), (response) => {
                if (response?.success) {
                    this.setState({
                        showLoader: false,
                        title: 'Add User',
                        message: response?.message,
                        show: true,
                        error: false,
                    }, () => {
                        this.props?.UserList()
                        $("#errorMessage").modal("show");
                    });
                }
                else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showLoader: false,
                            showRefreshModal: true
                        })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            showLoader: false,
                            title: 'Add User',
                            //LONG-2293 page is getting freezed when configuring the same user
                            message: response?.message,
                            show: true,
                            delete: false,
                            error: true,
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
            this.props.closeAddUser();
        })
    }
    errorEmpty = () => {
        if ((this.state.firstname === '') || (this.state.lastname === '') ||
            (this.state.password === '') || (this.state.username === '') || (this.state.role === "")) {
            return true;
        }
    }
    removeError = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) element.style.display = "none";
        this.setState({
            error: false,
            validPassword: ''
        });
    };
    //LONG-2294-EM - [User] Request 500 error throws when configuring user with invalid mail
    checkUserDataError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.userData.firstName === "" || this.state.userData.lastName === ""
            || this.state.userData.username === "" || this.state.userData.password === "" || this.state.userData.role === "")
            result = true;
        return result;
    }
    setUserData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                error: false
            })
        }
        let { userData } = this.state;
        const name = e.target.name;
        const value = e.target.value
        userData[name] = value;
        this.setState({
            userData
        })
    }
    validateUser = (e) => {
        if (e.target.name === "firstName") {
            if (e.target.value === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = 'Firstname cannot be empty!';
                this.setState({
                    error: true,
                });
            }
            else {
                if (e.target.value.length > 32) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = "block";
                    element.innerHTML = 'Firstname should be between 1-32';
                    this.setState({
                        error: true,
                    });
                }
                else
                    this.removeError(e)
            }
        }
        else if (e.target.name === "lastName") {
            if (e.target.value === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = 'Lastname cannot be empty!';
                this.setState({
                    error: true,
                });
            }
            else {
                if (e.target.value.length > 32) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = "block";
                    element.innerHTML = 'Lastname should be between 1-32';
                    this.setState({
                        error: true,
                    });
                }
                else
                    this.removeError(e)
            }
        }
        else if (e.target.name === "email") {
            if (e.target.value !== "") {
                if ((! /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = "block";
                    this.setState({
                        error: true,
                    });
                }
                else {
                    this.removeError(e)
                }
            } else {
                this.removeError(e);
            }
        }
        else if (e.target.name === "username") {
            if (e.target.value === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = 'Username cannot be empty!';
                this.setState({
                    error: true,
                });
            } else {
                if (e.target.value.length > 32) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = "block";
                    element.innerHTML = 'Username should be between 1-32';
                    this.setState({
                        error: true,
                    });
                }
                else
                    this.removeError(e);
            }
        }
        else if (e.target.name === "role") {
            if (e.target.value === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({
                    error: true,
                });
            } else {
                this.removeError(e);
            }

        }
        else if (e.target.name === "password") {

            const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$");
            const valid = regex.test(e.target.value);

            if (e.target.value !== "") {
                if (!!!valid) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = "block";
                    element.innerHTML = 'Passwords should have 8 to 16 characters, 1 special character, 1 number, 1 uppercase character, and 1 lowercase character!';
                    this.setState({
                        error: true,
                    });
                }
                else {
                    this.removeError(e)
                }
            } else if (e.target.value === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = 'Password cannot be empty!';
                this.setState({
                    error: true,
                });
            }

            else {
                this.removeError(e);
            }
        }

        else {
            this.removeError(e)
        }
    }
    render() {
        return (
            <div>
                <div className={this.props.openAddUser ? "slide-overlay open" : "slide-overlay"}></div>
                <div className={this.props.openAddUser ? "sidebar-slide open" : "sidebar-slide"}>
                    <div className="s-header">
                        <h1 className="ss-title">{this.props.userId ? 'Update User' : 'Add New User'}</h1>
                        <button onClick={() => this.props.closeAddUser()}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className='s-body'>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label>First Name
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="First Name of User">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <input type='text' name="firstName" defaultValue={this.state.userData.firstName}
                                        onChange={this.setUserData}
                                        onBlur={(e) => this.validateUser(e)} className='form-control' />
                                    <div className="error-txt" id="firstName">Invalid value</div>
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label>Last Name
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Last Name of User">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <input type='text' name="lastName" defaultValue={this.state.userData.lastName}
                                        onChange={this.setUserData}
                                        onBlur={(e) => this.validateUser(e)} className='form-control' />
                                    <div className="error-txt" id="lastName">Invalid value</div>
                                </div>
                            </div>
                            {/* {this.state.userId ?
                                "" :  */}
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label>Email
                                    </label>
                                    <input type='text' name="email"
                                        value={this.state.userData.email}
                                        onChange={this.setUserData}
                                        onBlur={(e) => this.validateUser(e)} className='form-control' />
                                    <div className="error-txt" id="email">Invalid Email</div>
                                </div>
                            </div>
                            {/* } */}
                            {this.state.userId ?
                                "" : <div className='col-md-6 col-sm-12'>
                                    <div className='form-group'>
                                        <label>Username
                                            <span className="red-txt">*</span>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Username of User">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </label>
                                        <input type='text' name="username"
                                            onChange={this.setUserData}
                                            onBlur={(e) => this.validateUser(e)} className='form-control' />
                                        <div className="error-txt" id="username">Invalid value</div>
                                    </div>
                                </div>}
                            {this.state.userId ? "" :
                                <div className='col-md-6 col-sm-12'>
                                    <div className='form-group'>
                                        <label>Create New Password
                                            <span className="red-txt">*</span>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Create Strong Password">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </label>
                                        <input type='password' name="password"
                                            onChange={this.setUserData}
                                            onBlur={(e) => this.validateUser(e)} className='form-control' />
                                        <div className="error-txt" id="password">{this.state.validPassword}</div>

                                    </div>
                                </div>
                            }
                            {
                                <div className='col-md-6 col-sm-12'>
                                    <div className='form-group'>
                                        <label>Role
                                            <span className="red-txt">*</span>
                                            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Role of User">
                                                <img src="/images/svg-icons/info.svg" alt="" />
                                            </div>
                                        </label>
                                        <select
                                            name="role"
                                            value={this.state.userData.role}
                                            disabled={String(this.props?.user) === String(this.state.userData.username)}
                                            onChange={this.setUserData}
                                            onBlur={(e) => this.validateUser(e)}
                                            className='form-control'>
                                            <option value="">Select Role</option>
                                            <option value='true'>Super Admin</option>
                                            <option value='false'>Admin</option>
                                            <option value='viewer'>Viewer</option>
                                            <option value='operator'>Operator</option>
                                        </select>
                                        <div className="error-txt" id="role">Invalid value</div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="s-footer">
                        <button className="btn btn-light" onClick={() => this.props.closeAddUser()}>Cancel</button>
                        <button className="btn btn-primary" disabled={this.state.userId ? this.state.error : this.errorEmpty() || this.checkUserDataError()} onClick={this.state.userId ? () => this.handleUpdateUser() :
                            () => this.handleAddUser()}>{this.state.userId ? "Update" : "Save"}</button>

                    </div>
                </div>
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.showRefreshModal) &&
                    <ErrorHandlePopup
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        openAddUser: state.displayAddDevice.openAddUser,
        user: state.auth.user,
    });
}
export default connect(mapStateToProps)(AddNewUser);