import React, { useState, useEffect } from 'react';
export const ErrorPopup = (props) => {
    const [display, setDisplay] = useState(!!localStorage.getItem('token'));
    return (
        <>
            {display && (<div
                className="modal fade errorMessage"
                id="errorMessage"
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="errorMessageTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                    {/* LONG-2242-EM - Bootstrap page - name for Bolt and cancel button in bolt popup  */}
                    <div className="modal-content">
                        <div className={props?.offlineDevice ? "modal-header bg-white border-0 offlinePopUpHeader" : "modal-header bg-white border-0"}>
                            {/* <h5 className="modal-title"></h5> */}
                            {props?.offlineDevice &&
                                <div className="offlinePopUpTooltip"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Please be sure to factory reset it before adding it to another EM."
                                    style={{ width: "20px" }}>
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>}
                            {(props?.offlineDevice || props?.factoryReset || props?.duplicate) &&
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => props?.reset && props.reset()}>
                                    <img src="/images/svg-icons/cancel.svg" alt="" height="15px" width="15px" />
                                </button>}
                        </div>
                        <div className="modal-body pt-5">
                            <div className="error-msg">
                                <span
                                    className={props?.error ? "error-icon" : "success-icon"}>
                                    {props.error ? (props?.info ? 'i' : '!') : ''}
                                </span>
                                <div className="txt1">{props?.title}</div>
                                <div
                                    className={(props?.title === "Firmware Upgrade" && props?.message !== "No-Description")
                                        ? "txt2 text-left"
                                        : "txt2"}>
                                    {props?.message}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-top-0 justify-content-center pb-4">
                            {(props?.delete || props?.approve || props?.duplicate || props?.nonSync) &&
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-dismiss="modal"
                                    onClick={() => (
                                        // props?.offlineDevice || props?.factoryReset || 
                                        props?.duplicate)
                                        ? props?.onCancel && props.onCancel()
                                        : props?.reset && props.reset()}>
                                    {(
                                        // props?.offlineDevice || props?.factoryReset || 
                                        props?.duplicate)
                                        ? 'No'
                                        : 'Cancel'}
                                </button>}
                            {props.message
                                && !props?.message?.includes('Maximum Number of Checkpoints Already Created')
                                && !props?.message?.includes('Public key is not available to create the data tunnel')
                                && <button
                                    type="button"
                                    className={props?.error ? "btn btn-danger" : "btn btn-primary"}
                                    data-dismiss="modal"
                                    onClick={() => (props?.offlineDevice || props?.delete || props?.approve || props?.duplicate)
                                        ? props?.onSubmit && props?.onSubmit()
                                        : props?.nonSync
                                            ? props.wait && props.wait()
                                            : props?.reset && props.reset()}>
                                    {(props?.offlineDevice || props?.duplicate || props?.factoryReset)
                                        ? 'Yes'
                                        : props?.nonSync
                                            ? 'Wait'
                                            : 'OK'}
                                </button>
                            }
                            {props?.message && props?.message?.includes('Maximum Number of Checkpoints Already Created') &&
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                        onClick={() => props?.reset && props.reset()}>No</button>
                                    <button
                                        type="button"
                                        className={props.error ? "btn btn-danger" : "btn btn-primary"}
                                        data-dismiss="modal"
                                        onClick={() => props?.onSubmit && props.onSubmit()}>Yes</button>
                                </>
                            }
                            {props?.message && props?.message?.includes('Public key is not available to create the data tunnel') &&
                                <>
                                    <button
                                        type="button"
                                        className={props?.error ? "btn btn-danger" : "btn btn-primary"}
                                        data-dismiss="modal"
                                        onClick={() => props?.onSubmit && props.onSubmit()}>Generate Key</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            )}
        </>
    );
};
