  
  import { combineReducers } from 'redux';
  import {auth, displaySidebarMenu} from './modules/loginManagement';
  import {displayAddTunnel, 
  } from './modules/tunnelManagement';
  import {displayAddDevice} from './modules/deviceManagement';
  import {displayAddTemplate} from './modules/templateManagement';
  import { displayUpdateFirmware } from './modules/firmwareManagement';
  import * as Action from "../redux/actionTypes";
import { displaySystemAlerts } from './modules/systemAlertsManagement';
  const reducers =  combineReducers({
      auth,
      displaySidebarMenu,
      displayAddTunnel,
      displayAddDevice,
      displayAddTemplate,
      displayUpdateFirmware,
      displaySystemAlerts
    });
  
  const rootReducer = (state, action) => {
      if (action.type === Action.LOGOUT) {
          // Clears state. Each reducers uses initialState again.
          state = undefined;
      }
      return reducers(state, action);
  };
  
  export default rootReducer;
  