import React, { Component } from 'react';

export default class Overview extends Component {
    render() {
        return (
            <div id="overview" className="container">
                <h3>Get Started</h3>
                <p>An increasing number of connectivity solutions are being deployed across enterprises, distributed workforce, hospitals, and schools for providing more flexibility and business opportunities while also introducing new challenges for maintenance, configurations, deployment, and costs for the IT team.</p>
                <p>How should IT Managers or SMBs address these challenges effectively?</p>
                <p>Inseego Longship (5G SD EDGE Manager) is the new, multi-tiered configuration, management, and monitoring platform by Inseego. It is a centralized control function to securely and intelligently direct traffic across the WAN. This increases application performance and delivers a high-quality user experience, resulting in increased business productivity, agility. </p>
                <p>5G SD EDGE Manager will run in the on-premise network or on the cloud as per the deployment use case. Through EDGE Managers, a network administrator can manage his devices and a network.  Inseego Longship gives enterprise IT managers and small businesses a powerful tool to seamlessly operate networks. A Mgmt. Plane is created between the 5G SD EDGE Manager and Devices pushing configurations and receiving statistics.</p>
                <p>Inseego Longship is the best solution in the Market for Managing Inseego Devices.</p>
                <div className="text-right s-footer">
                    <button className="btn btn-light mr-3" onClick={() => { this.props.goToStep(5); this.props.stepChange(5) }}>Skip</button>
                    <button className="btn btn-primary"
                        onClick={() => { this.props.nextStep(2); this.props.stepChange(2) }}>Next</button>
                </div>
            </div>
        )
    }
}