/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import _, { debounce, isEmpty } from "lodash";
import $ from "jquery";
import urlMapping from "../../../../redux/API/api";
import ApiCall from "../../../../redux/API/apiCall";
import * as Action from "../../../../redux/actionTypes";
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import isEqual from 'lodash/isEqual';
class SystemTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplateId: this.props.id ? this.props.id : "",
      data: {
        syslog: {
          enabled: 1,
          logfilesize: 5,
          logfilecount: 5,
          severity: {
            emergency: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
            critical: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
            error: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
            warning: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
            notice: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 1, notify: 0 },
            },
            info: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 0, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 1, notify: 0 },
            },
            debug: {
              enabled: 0,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 0, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
          },
        },
      },
      editSeverity: false,
      selectedSeverity: {},
      template_type: this.props?.templateData?.template_type || "",
      template_name: this.props?.templateData?.template_name || "",
      template_desc: this.props?.templateData?.template_desc || "",
      device_model: this.props?.templateData?.device_model || "",
      collection_version: this.props?.templateData?.collection_version || "",
      fw_version: this.props.templateData?.fw_version || "",
      chimay: this.props.chimay,
      cbr: this.props.cbr,
      mifi: this.props.mifi,
      isAxiosError: false
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json?.data) {
      let temp = {};
      temp = {
        emergency:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.emergency),
        critical:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.critical),
        error:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.error),
        warning:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.warning),
        notice:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.notice),
        info:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.info),
        debug:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.debug),
      };
      this.setState({
        data: {
          ...this.props.template_schema_json?.data,
          syslog: {
            ...this.props.template_schema_json?.data?.syslog,
            enabled: this.props.template_schema_json?.data?.syslog?.enabled || 1,
            severity: temp,
            logfilesize: this.props.template_schema_json?.data?.syslog?.logfilesize || 5,
            logfilecount: this.props.template_schema_json?.data?.syslog?.logfilecount || 5
          }
        }
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.templateData).length !== 0) {
      if (nextProps.templateData !== undefined) {
        this.setState({
          selectedTemplateId: nextProps.id ? nextProps.id : '',
          template_type: nextProps?.templateData?.template_type || "",
          template_name: nextProps?.templateData?.template_name || "",
          template_desc: nextProps?.templateData?.template_desc || "",
          device_model: nextProps?.templateData?.device_model || "",
          collection_version: nextProps?.templateData?.collection_version || "",
          fw_version: nextProps?.templateData?.fw_version || "",
          selectedSeverity: {}
        });
      }
    }
    if ((nextProps.edit && nextProps.template_schema_json !== undefined) &&
      !isEqual(this.props.template_schema_json, nextProps.template_schema_json)) {
      let temp = {};
      temp = {
        emergency:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.emergency),
        critical:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.critical),
        error:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.error),
        warning:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.warning),
        notice:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.notice),
        info:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.info),
        debug:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.debug),
      };
      this.setState({
        data: {
          ...nextProps.template_schema_json?.data,
          syslog: {
            ...nextProps.template_schema_json?.data?.syslog,
            enabled: nextProps.template_schema_json?.data?.syslog?.enabled || 1,
            severity: temp,
            logfilesize: nextProps.template_schema_json?.data?.syslog?.logfilesize || 5,
            logfilecount: nextProps.template_schema_json?.data?.syslog?.logfilecount || 5
          }
        }
      });
    }
    if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
      !isEqual(this.props?.cbr, nextProps?.cbr) ||
      !isEqual(this.props?.mifi, nextProps?.mifi)) {
      this.setState({
        chimay: nextProps.chimay,
        cbr: nextProps.cbr,
        mifi: nextProps.mifi
      })
    }
  }
  undefinedCheck = (value) => {
    if (value) {
      let data = {
        enabled: value?.enabled || 0,
        kern: { enabled: value?.enabled || 0, notify: value?.notify || 0 },
        user: { enabled: value?.enabled || 0, notify: value?.notify || 0 },
        daemon: { enabled: value?.enabled || 0, notify: value?.notify || 0 },
        authpriv: { enabled: value?.enabled || 0, notify: value?.notify || 0 }
      }
      return data
    }
  }
  applyTemplate = () => {
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate({ data: this.state.data });
  };
  debounceApply = debounce((fn, event) => {
    fn(event);
  }, 500);
  handleEditClick() {
    if (!this.state.editSeverity) {
      this.setState({ editSeverity: true });
    }
  }
  saveData = () => {
    let data = {};
    data.template_name = this.state.template_name;
    data.template_desc = this.state.template_desc;
    data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
    data.template_type = this.state.template_type;
    data.collection_version = this.state.collection_version;
    data.fw_version = this.state.fw_version;
    data.template_order = 13;
    data.template_schema_json = { data: this.state.data };
    if (this.state.selectedTemplateId === "") {
      data.template_version = {
        chimay: this.state.chimay,
        cbr: this.state.cbr,
        mifi: this.state.mifi
      }
    }
    this.setState({
      showLoader: true,
    });
    if (this?.props?.configDeviceId && !this?.props?.configAdd) {
      let config_data = {
        device_id: this?.props?.configDeviceId,
        template_name: this.state.template_name,
        template_desc: this.state.template_desc,
        template_type: this.state.template_type,
        template_schema_json: data.template_schema_json,
      }
      ApiCall(
        urlMapping.setConfigDetails(config_data),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.success) {
            this.setState(
              {
                title: "Update Configuration",
                message: response?.message,
                show: true,
                error: false,
              }, () => {
                $("#errorMessage").modal("show");
              }
            )
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"));
            }
            else {
              this.setState(
                {
                  title: "Update Configuration",
                  message: response?.message,
                  show: true,
                  error: true,
                }, () => $("#errorMessage").modal("show"));
            }
          }
        }
      );
    }
    else if (this?.props?.configDeviceId && this?.props?.configAdd) {
      let config_data = {
        device_id: this?.props?.configDeviceId,
        template_name: this.state.template_name,
        template_desc: this.state.template_desc,
        device_model: this?.props?.configModels,
        template_type: this.state.template_type,
        collection_version: this.state.collection_version,
        fw_version: this.state.fw_version,
        template_order: 12,
        template_version: {
          chimay: this.state.chimay,
          cbr: this.state.cbr,
          mifi: this.state.mifi
        },
        template_schema_json: data.template_schema_json,
      }
      ApiCall(
        urlMapping.setConfigDetails(config_data),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.success) {
            this.setState(
              {
                title: "New Configuration",
                message: response?.message,
                show: true,
                error: false,
              }, () => {
                $("#errorMessage").modal("show");
              }
            )
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({ showRefreshModal: true });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"));
            }
            else {
              this.setState(
                {
                  title: "New Configuration",
                  message: response?.message,
                  show: true,
                  error: true,
                }, () => $("#errorMessage").modal("show"));
            }
          }
        }
      );
    }
    else if (this.state.selectedTemplateId !== "") {
      ApiCall(
        urlMapping.updateTemplate(this.state.selectedTemplateId, data),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.Success) {
            this.props.refreshList();
            this.props.openApplyTemplate(this.state.selectedTemplateId);
            this.props.closeAddTemplate();
          } else {
            if (parseInt(response?.response?.status) === 401) {
              this.setState({
                showRefreshModal: true,
              });
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else {
              this.setState(
                {
                  title: "Update Template",
                  message: response?.message,
                  show: true,
                  error: true,
                },
                () => $("#errorMessage").modal("show")
              );
            }
          }
        }
      );
    } else {
      ApiCall(urlMapping.createTemplate(data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.success) {
          this.props.refreshList();
          this.props.openApplyTemplate(response?.data?.id);
          this.props.closeAddTemplate();
        } else {
          if (parseInt(response?.response?.status) === 401) {
            this.setState({
              showRefreshModal: true,
            });
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Create Template",
                message: response?.message,
                show: true,
                error: true,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    }
  };
  //EMGT-381-EM - "Template name already exists" pop-up is not shown while creating custom template.
  resetPopup = () => {
    this.setState(
      {
        show: false,
        error: false,
      },
      () => {
        $("#errorMessage").modal("hide");
        this.props.refreshList();
        this.props.closeAddTemplate();
      }
    );
  };
  changeFacility = (e, mode) => {
    let temp = this.state.selectedSeverity?.value;
    if (mode !== "root") {
      temp = {
        ...temp,
        [e.target.id]: {
          ...temp[e.target.id],
          [mode]: e.target.checked ? 1 : 0,
        },
      };
      if (mode === "enabled" && !e.target.checked) {
        temp = {
          ...temp,
          [e.target.id]: {
            ...temp[e.target.id],
            notify: 0,
          },
        };
      }
    } else {
      temp = {
        ...temp,
        enabled: e.target.checked ? 1 : 0,
      };
    }
    this.setState({
      selectedSeverity: { ...this.state.selectedSeverity, value: temp },
    });
  };
  closeAll = () => {
    this.props.dispatch({
      type: Action.CLOSE_APPLY_TEMPLATE,
    });
    this.props.closeAddTemplate();
  };
  updateChange = () => {
    let tempData = this.state.data?.syslog?.severity;
    _?.map(tempData, (severity, key) => {
      if (key === this.state.selectedSeverity?.key) {
        tempData = { ...tempData, [key]: this.state.selectedSeverity?.value };
      }
    });
    this.setState({
      data: {
        ...this.state.data,
        syslog: { ...this.state.data.syslog, severity: tempData },
      },
      selectedSeverity: {},
      editSeverity: false,
    });
  };
  getFacilityName = (key) => {
    switch (key) {
      case "kern":
        return "Kernel";
      case "user":
        return "User";
      case "daemon":
        return "Daemon";
      case "authpriv":
        return "AuthPriv";
      default:
        break;
    }
  };
  checkError = () => {
    let result = false;
    if (this.props.applying)
      result = true;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;") result = true;
    }
    if (this.state.template_name === "" || this.state.template_type === "")
      result = true;
    return result;
  };
  handleCancel = () => {
    this.setState({
      selectedSeverity: {},
      editSeverity: false
    });
  };
  openSeverity = (key, sever) => {
    if (
      isEmpty(this.state.selectedSeverity) &&
      this.state.data?.syslog?.enabled === 1
    ) {
      this.setState({
        selectedSeverity: { key: key, value: sever },
        editSeverity: true,
      });
    }
  };
  setFileSize = (e) => {
    const element = document.getElementById(e.target.name);
    if (element) {
      const regex = /^[\d]*$/;
      const result = regex.test(e.target.value);
      if (e.target.value === '' || e.target.value > 20 || e.target.value < 1 || result === false) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
      this.setState({
        data: {
          ...this.state.data,
          syslog: {
            ...this.state.data.syslog,
            logfilesize: e.target.value,
          },
        },
      })
    }
  }
  checkAtleastOneSeverity = () => {
    let disabled = false;
    if (this.state.selectedSeverity?.value?.enabled === 1) {
      let enableSeverity = _?.find(this.state.selectedSeverity?.value, (i, key) => key !== "enabled" && i?.enabled === 1)
      if (isEmpty(enableSeverity)) {
        disabled = true
      } else {
        disabled = false
      }
    }
    return disabled
  }
  render() {
    return (
      <div className="system-configuration">
        {/*EMGT-314-EM - System template field validation */}
        <div className={this.state.editSeverity ? "disabled-slide" : ""}></div>
        <div className="page-sub-title border-bottom mb-2">
          <div>
            <strong>System Log</strong>
          </div>
          <div
            className="device-action"
            onClick={() =>
              !isEmpty(this.state.selectedSeverity) ? this.handleEditClick() : undefined
            }
          >
            <span className={!isEmpty(this.state.selectedSeverity) ? "icon-box" : "icon-box disabled"}>
              <img src="/images/svg-icons/edit.svg" alt="" />
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <strong>Enable SYSLOG</strong>
          <div
            className="tooltip-c"
            data-toggle="tooltip"
            data-placement="right"
            title="Enable Syslog messages to remote server"
          >
            <img src="/images/svg-icons/info.svg" alt="" />
          </div>
          <label className="switch ml-3">
            <input
              checked={this.state.data?.syslog?.enabled === 1}
              type="checkbox"
              name="advancedOptions"
              onChange={(e) =>
                this.setState({
                  data: {
                    ...this.state.data,
                    syslog: {
                      ...this.state.data?.syslog,
                      enabled: e.target.checked ? 1 : 0,
                    },
                  },
                })
              }
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>
            <strong>Log Rotate</strong>
          </div>
          <div>
            <label>Log file size</label>
            <div
              className="tooltip-c"
              data-toggle="tooltip"
              data-placement="right"
              title="Max size for file rotation in the device"
            >
              <img src="/images/svg-icons/info.svg" alt="" />
            </div>
            <input
              type="number"
              className="form-control d-inline w-90 ml-2 mr-2"
              value={this.state.data?.syslog?.logfilesize}
              max={20}
              // EMGT-314-EM - System template field validation
              min={1}
              name="log_file_size"
              onChange={(e) => this.setFileSize(e)}
            />
            <label>MB</label>
            <div className="error-txt" id="log_file_size">Invalid Value (value should be between 1-20)</div>
          </div>
          <div>
            <label>Log file limit</label>
            <div
              className="tooltip-c"
              data-toggle="tooltip"
              data-placement="right"
              title="Number of files preserved in the device"
            >
              <img src="/images/svg-icons/info.svg" alt="" />
            </div>
            <select
              value={this.state.data?.syslog?.logfilecount}
              className="form-control d-inline w-90  ml-2"
              onChange={(e) =>
                this.setState({
                  data: {
                    ...this.state.data,
                    syslog: {
                      ...this.state.data?.syslog,
                      logfilecount: e.target.value,
                    },
                  },
                })
              }
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
            </select>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Severity</th>
              <th>Severity Status</th>
              <th>Facility</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {_?.map(this.state.data?.syslog?.severity, (sever, key) => {
              return (
                <tr
                  className={
                    this.state.data.syslog?.enabled === 0 ? "disabled-cell" : ""
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={this.state.selectedSeverity?.value === sever}
                      disabled={
                        this.state.data.syslog?.enabled === 0 ||
                        (this.state.selectedSeverity?.value !== sever &&
                          !isEmpty(this.state.selectedSeverity))
                      }
                      onClick={(e) => {
                        e.target.checked
                          ? this.setState({
                            selectedSeverity: { key: key, value: sever },
                          })
                          : this.setState({ selectedSeverity: {} });
                      }}
                    />
                  </td>
                  <td onClick={() => this.openSeverity(key, sever)}>
                    <span
                      className={`txt-blue ${this.state.data?.syslog?.enabled === 1
                        ? "cursor-pointer"
                        : ""
                        }`}
                    >
                      {key?.[0]?.toLocaleUpperCase()?.concat(key?.slice(1))}
                    </span>
                  </td>
                  <td>
                    <span>{sever?.enabled === 0 ? "Disabled" : "Enabled"}</span>
                  </td>
                  <td className={sever?.enabled === 0 ? "disabled-cell" : ""}>
                    <ul className="syslog-list">
                      {_?.map(
                        sever,
                        (item, index) =>
                          index !== "enabled" && (
                            <li>
                              <span>{this.getFacilityName(index)}</span>
                            </li>
                          )
                      )}
                    </ul>
                  </td>
                  <td className={sever?.enabled === 0 ? "disabled-cell" : ""}>
                    <ul className="syslog-list">
                      {_?.map(
                        sever,
                        (item, index) =>
                          index !== "enabled" && (
                            <li>
                              <span>
                                {item?.enabled === 0 ? "Disabled" : "Enabled"}
                              </span>
                            </li>
                          )
                      )}
                    </ul>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* second slider */}
        {this.state.editSeverity && (
          <div className="sidebar-slide  zindex10 w-350 second-open r-757">
            <div className="s-header">
              <h1 className="ss-title capitalize">
                {this.state.selectedSeverity?.key} Facilities
              </h1>
              <button onClick={() => this.handleCancel()}>
                <img src="/images/svg-icons/s-cancel.svg" alt="" />
              </button>
            </div>
            <div className="s-body">
              <div className="form-group">
                <label>
                  <strong>Facilities</strong>
                  <div
                    className="tooltip-c"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={`To fetch the ${this.state.selectedSeverity?.key} severity logs`}
                  >
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
              </div>
              <div className="d-flex align-items-center">
                <strong>Enable</strong>
                <label className="switch ml-3">
                  <input
                    checked={this.state.selectedSeverity?.value?.enabled === 1}
                    onChange={(e) => this.changeFacility(e, "root")}
                    type="checkbox"
                    name="advancedOptions"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              {this.state.selectedSeverity?.value?.enabled === 1 && (
                <div className="facilities-list">
                  <div className="d-flex align-items-center justify-content-between border-bottom">
                    <div className="w-33">&nbsp;</div>
                    <div>
                      <strong>Enabled</strong>
                    </div>
                  </div>
                  {_?.map(
                    this.state.selectedSeverity?.value,
                    (item, index) =>
                      index !== "enabled" && (
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="w-33">
                            {this.getFacilityName(index)}
                          </div>
                          <div>
                            <label className="switch">
                              <input
                                type="checkbox"
                                id={index}
                                onChange={(e) =>
                                  this.changeFacility(e, "enabled")
                                }
                                checked={item?.enabled === 1}
                                name="advancedOptions"
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
            <div className="s-footer">
              <button
                className="btn btn-light"
                onClick={() => this.handleCancel()}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => this.updateChange()}
                disabled={this.checkAtleastOneSeverity()}
              >
                Update
              </button>
            </div>
          </div>
        )}
        {
          <div className="s-footer">
            {/* 1665 reapply page issue */}
            <button
              className="btn btn-light"
              onClick={
                this.props.apply && this.props.edit && this.props.reapply
                  ? () => this.props.closeApplyTemplate()
                  : () => this.closeAll()
              }
            >
              {/* 1665 reapply page issue */}
              {this.props.apply && !this.props.edit && !this.props.reapply
                ? "Back"
                : "Cancel"}
            </button>
            <button
              className="btn btn-primary"
              disabled={this.checkError()}
              onClick={
                (this.props.apply && !this.props?.configEdit)
                  ? (event) => this.debounceApply(this.applyTemplate, event)
                  : () => this.saveData()
              }
            >
              {
                (this.props.apply && !this.props?.configEdit)
                  ? "Apply"
                  : (this?.props?.configAdd || this.props?.configEdit)
                    ? "Save"
                    : (this.state.selectedTemplateId !== "" || this?.props?.configDeviceId)
                      ? "Update"
                      : "Save"
              }
            </button>
          </div>
        }
        {/*EMGT-381-EM - "Template name already exists" pop-up is not shown while creating custom template. */}
        {(this.state.show) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            reset={() => this.resetPopup()}
          />
        }
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
  }
};
export default connect(mapStateToProps)(SystemTemplate);
