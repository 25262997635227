/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Common from "../../../../utils/common";
import * as Action from "../../../../redux/actionTypes";
class OSPFView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networkViewData: [],
      availableNetworks: [],
      usedNetworks: [],
      selectedNetwork: '',
      selectedTemplateId: this.props.id ? this.props.id : "",
      template_schema_json: {
        areaDetails: [],
        redistribution: {
          protocol: '',
        },
        distanceConfiguration: {},
      },
      applied: false,
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      this.setState({
        template_schema_json: this.props.template_schema_json
      });
    }
    if (this.props.apply) {
      if (this.props.networkViewData) {
        let networkViewData = this.props?.networkViewData?.filter(element => element?.network_zone !== "wan");
        networkViewData = networkViewData?.filter(element => !(element.bridgeStatus === 'Disabled' && element.interfaceName === ""));
        // LONG-1836 : Removing condition for not including networks with blank ip address in case of device group only
        if (!this.props.group_id) {
          networkViewData = networkViewData?.filter(element => element?.ip_address !== "" && element?.name !== "Control Tunnel"
            && element.network_zone !== "datatunnel"
          )
        }
        else {
          networkViewData = networkViewData?.filter(element => element.name !== "Control Tunnel"
            && element.network_zone !== "datatunnel"
          )
        };
        let { availableNetworks } = this.state;
        availableNetworks = networkViewData?.map((item) => item.name);
        this.setState({
          networkViewData,
          availableNetworks
        },
        )
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id) {
      this.setState({
        selectedTemplateId: nextProps.id ? nextProps.id : "",
      });
    }
    if (Object.keys(nextProps.templateData).length !== 0) {
      this.setState({
        template_schema_json: nextProps.templateData.template_schema_json
      });
    }
    if (nextProps.networkViewData && nextProps.networkViewData?.length > 0) {
      let { availableNetworks } = this.state;
      let networkViewData = nextProps.networkViewData?.filter(element => element.network_zone !== "wan");
      networkViewData = networkViewData?.filter(element => !(element.bridgeStatus === 'Disabled' && element.interfaceName === ""));
      // LONG-1836 : Removing condition for not including networks with blank ip address
      // networkViewData = networkViewData.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
      //   && element.name !== "Data Tunnel"
      if (!nextProps.group_id) {
        networkViewData = networkViewData?.filter(element => element.ip_address !== "" && element.name !== "Control Tunnel"
          && element.network_zone !== "datatunnel"
        )
      }
      // networkViewData = networkViewData.filter(element => !(element.name === "Data Tunnel" && element.ip_address !== ''))
      // && element.name !== "Data Tunnel"
      else {
        networkViewData = networkViewData?.filter(element => element.name !== "Control Tunnel"
          && element.network_zone !== "datatunnel"
        )
      };
      // availableNetworks = [];
      availableNetworks = networkViewData.map((item) => item.name);
      this.setState({
        availableNetworks, networkViewData
      })
    }
  }
  setNetwork = () => {
    let { template_schema_json } = this.state;
    if (this.state.networkViewData.length > 0) {
      template_schema_json.areaDetails?.map((item) => {
        item.network = this.state.networkViewData?.[0]?.name
      })
    }
  }
  handleChange = (e, givenID, name) => {
    const { template_schema_json } = this.state;
    let { availableNetworks, usedNetworks } = this.state;
    template_schema_json.areaDetails.map((item) => {
      if (e.target.checked) {
        if (item.id === givenID) {
          item.network = item.network === '' ?
            e.target.id : item.network + ' ' + e.target.id;
          availableNetworks = availableNetworks.filter(item => item !== name);
          usedNetworks.push(name);
        }
      } else {
        if (item.id === givenID) {
          if (!availableNetworks.includes(name)) {
            availableNetworks.push(name);
          }
          usedNetworks = usedNetworks?.filter((item2) => item2 !== name);
          const networks = item.network?.split(' ');
          item.network = networks.filter(item => item !== e.target.id)?.join(' ');
        }
      } return item;
    });
    this.setState({
      template_schema_json,
      availableNetworks,
      usedNetworks,
      selectedNetwork: availableNetworks
    })
  }
  checkError = () => {
    let result = false;
    if (this.props.applying)
      result = true;
    const elements = document.getElementsByClassName('error-txt');
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (this.state.applied) {
      result = true;
    }
    if (this.state.networkViewData.length === 0) {
      result = true;
    }
    if (this.props.apply) {
      this.state.template_schema_json.areaDetails.length > 0 && this.state.template_schema_json.areaDetails?.map((item) => {
        if (item.network === '') {
          result = true;
        }
      }
      )
    }
    return result;
  }
  // LONG-1836 : Removing condition for not including networks with blank ip address. So these functions are no longer needed
  // getNetwork = (value) => {
  //   let networkName = '';
  //   this.state.networkViewData.map((network) => {
  //     const ipValue = value.split('/')[0];
  //     const subnet = value.split('/')[1];
  //     if (ipValue === network.ip_address && subnet === network.subnet) {
  //       networkName = network.name;
  //     }
  //   })
  //   return networkName;
  // }
  // getIpAddress = (value) => {
  //   let ipAddress = '';
  //   this.state.networkViewData.map((item) => {
  //     if (item.name === value) {
  //       ipAddress = item.ip_address + "/" + item.subnet;
  //     }
  //   })
  //   return ipAddress;
  // }
  applyTemplate = () => {
    const { template_schema_json } = this.state;
    let networks = [];
    this.state.networkViewData.map((item) => {
      networks.push(item);
    });
    template_schema_json.areaDetails.map((area) => {
      let network = area.network;
      let interfaces = '';
      network?.split(' ')?.map((value) => {
        let networkData;
        // LONG-1836 : Removing condition for not including networks with blank ip address
        // networkData = networks.filter(item => item.ip_address + "/" + item.subnet === value)[0];
        networkData = networks?.filter(item => item.name === value)?.[0];
        if (networkData) {
          // if (networkData.protocol === "staticAddr") {
          if (networkData.bridgeStatus === "Disabled") {
            if (interfaces === '') {
              interfaces = networkData.interfaceName;
            } else {
              interfaces = interfaces + " " + networkData.interfaceName;
            }
          }
          else if (networkData.bridgeStatus === "Enabled") {
            if (interfaces === '') {
              interfaces = "br-" + networkData.networkName;
            } else {
              interfaces = interfaces + " br-" + networkData.networkName;
            }
          }
        }
      })
      area.interface = interfaces;
      return area;
    })
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate(template_schema_json);
  }
  render() {
    return (
      <div>
        <div className="ospf-view">
          <div className="gray-bg rounded p-3">
            <p>
              <strong>OSPF</strong>
              <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="OSPF"><img src="/images/svg-icons/info.svg" alt="" />
              </div>
            </p>
            {
              this.state.template_schema_json.areaDetails?.length > 0 &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Area ID</th>
                    {!this.props.info && <th>Network <span className="red-txt">*</span></th>}
                    <th>Advance Options</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    //LONG-1633_Set filter for area details item
                    this.state.template_schema_json.areaDetails?.filter(item => item.isdeleted !== '1')?.map((item, i) => {
                      return (
                        <tr>
                          <td>{item?.area}</td>
                          {!this.props.info &&
                            <>
                              {!this.props.view && !this.props.reapply ? <td>
                                <div className="dropdown">
                                  <ul className="chip-list z-index-9 bg-white radius">
                                    {
                                      item?.network === '' ? '' :
                                        item?.network.split(' ').map((item2) => {
                                          // LONG-1836 : Removing condition for not including networks with blank ip address
                                          return (<li > {item2}
                                            <img
                                              src="/images/svg-icons/cancel.svg"
                                              alt=""
                                              id={item2}
                                              onClick={(e) => this.handleChange(e, item?.id, item2)} /></li>)
                                        }
                                        )
                                    }
                                  </ul>
                                  <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                      {
                                        this.state.availableNetworks?.map((val) => {
                                          return (<li>
                                            <input
                                              type="checkbox"
                                              checked={this.state.selectedNetwork === val}
                                              name="networkname"
                                              // LONG-1836 : Removing condition for not including networks with blank ip address
                                              id={val}
                                              onChange={(e) => this.handleChange(e, item?.id, val)} /> {val}</li>)
                                        })
                                      }
                                    </ul>
                                  </div>
                                </div>
                              </td> :
                                <td>{item?.network?.split(' ')?.map((item2) => {
                                  // LONG-1836 : Removing condition for not including networks with blank ip address
                                  return (<li> {item2}
                                  </li>)
                                })}</td>
                              }
                            </>}
                          <td>
                            <label className="switch gray">
                              <input type="checkbox" name="advancedOptions"
                                checked={item?.advancedOptions === "1" ? true : false} />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            {item?.advancedOptions === "1" &&
                              <ul className="h-list">
                                <li>
                                  <span>Hello Timer</span>
                                  <span>{item?.helloTimer}</span>
                                </li>
                                <li>
                                  <span>Dead Timer</span>
                                  <span>{item?.deadTimer}</span>
                                </li>
                                <li>
                                  <span>Priority</span>
                                  <span>{item?.priority}</span>
                                </li>
                                <li>
                                  <span>Cost</span>
                                  <span>{item?.cost}</span>
                                </li>
                                <li>
                                  <span>Retransmit Interval</span>
                                  <span>{item?.retransmitInterval}</span>
                                </li>
                                <li>
                                  <span>Transmit Delay</span>
                                  <span>{item?.transmitDelay}</span>
                                </li>
                                <li>
                                  <span>Authentication Type</span>
                                  <span>{item?.authType}</span>
                                </li>
                                <li>
                                  <span>Key ID</span>
                                  <span>{item?.keyID}</span>
                                </li>
                              </ul>}
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            }
          </div>
          <div className="gray-bg rounded p-3 mt-2">
            <p><strong>Redistribute</strong>
              <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Redistribute"><img src="/images/svg-icons/info.svg" alt="" />
              </div>
            </p>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Protocol</label>
                  <ul className="dm-list d-block">
                    {
                      this.state.template_schema_json?.redistribution?.protocol?.split(',')?.length > 0 &&
                      this.state.template_schema_json?.redistribution?.protocol?.split(',')?.map(item => {
                        return (<li>{Common.getProtocol(item)}</li>)
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="gray-bg rounded p-3 mt-2">
            <p><strong>Basic Configuration</strong><div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Basic Configuration">
              <img src="/images/svg-icons/info.svg" alt="" />
            </div>
            </p>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Inter-area Routes Distance</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={this.state.template_schema_json?.distanceConfiguration?.interAreaDistance} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Intra-area Routes Distance</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={this.state.template_schema_json?.distanceConfiguration?.intraAreaDistance} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>External-area Routes Distance</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={this.state.template_schema_json?.distanceConfiguration?.externalRouteDistance} />
                </div>
              </div>
            </div>
          </div>
          {!this.props.info && !this.props.view &&
            (
              <div className="s-footer">
                <button
                  className="btn btn-light"
                  onClick={(this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
                    () => this.props.closeApplyTemplate()}
                >
                  {this.props.apply && !this.props.reapply ? "Back" : "Cancel"}
                </button>
                <button
                  className="btn btn-primary"
                  disabled={this.checkError()}
                  onClick={
                    this.props.apply
                      ? () => { this.applyTemplate(); this.setState({ applied: true }); }
                      : ""
                  }
                >
                  {this.props.apply
                    ? "Apply"
                    : this.state.selectedTemplateId !== ""
                      ? "Update"
                      : "Save"}
                </button>
              </div>
            )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(OSPFView);
