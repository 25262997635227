import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
export default class Template extends Component {
    render() {
        return (
            <div id="templates" className="container">
                <h3>Templates</h3> 
                {/* template step 1 */}
                <Carousel showThumbs={false}
                    showArrows={true}
                    centerMode={false}
                    showStatus={false}>
                    <div className="template-step-1">
                        <p style={{ textAlign: "left" }}>Click on <strong>"+"</strong> button to add new Template.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/add-template.png" alt="" />
                        </div>
                    </div>
                    {/* close template step 1 */}

                    {/* template step 2 */}
                    <div className="template-step-2">
                        <p style={{ textAlign: "left" }}>User can create template to select feature template.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/template-add-flow.png" alt="" />
                        </div>
                    </div>
                    {/* close template step 2 */}

                    {/* template step 3 */}
                    <div className="template-step-3">
                        <p style={{ textAlign: "left" }}>Click on <strong>Device Name</strong> to see the Device Details, like Dashboard, Connected Devices, Warnings & Alerts, Template and Network information.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/template-detail.png" alt="" />
                        </div>
                    </div>
                </Carousel>
                {/* close template step 3 */}

                <div className="text-right">
                    <button className="btn btn-light mr-3" onClick={() => { this.props.goToStep(5); this.props.stepChange(5) }}>Skip</button>
                    <button className="btn btn-light mr-3" onClick={() => { this.props.goToStep(2); this.props.stepChange(2) }}>Back</button>
                    <button className="btn btn-primary" onClick={() => { this.props.nextStep(4); this.props.stepChange(4) }}>Next</button>
                </div>
            </div>
        )
    }
}
