import * as Action from "../actionTypes";

const initialState = {
  openUpdateFirmware: false,
};

export const displayUpdateFirmware = (state = initialState, action) => {
  switch (action.type) {
    case Action.OPEN_UPDATE_FIRMWARE:
      return {
        ...state,
        openUpdateFirmware: true,
      };
      case Action.CLOSE_UPDATE_FIRMWARE:
      return {
        ...state,
        openUpdateFirmware: false,
      };
    default:
      return state;
  }
};
