import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
export default class Device extends Component {

    render() {
        return (
            <div id="devices" className="container">
                <h3>Devices</h3>
                <Carousel showThumbs={false}
                    showArrows={true}
                    centerMode={false}
                    showStatus={false}>
                    {/* device step 1 */}
                    <div className="device-step-1">
                        <p style={{ textAlign: "left" }}>Click on <strong>"+"</strong> button to add new device.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/add-device.png" alt="" />
                        </div>
                    </div>
                    {/* close device step 1 */}

                    {/* device step 2 */}
                    <div className="device-step-1">
                        <p style={{ textAlign: "left" }}>User can add device by entering "Device MAC" or upload csv for bulk devices.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/add-flow.png" alt="" />
                        </div>
                    </div>
                    {/* close device step 2 */}

                    {/* device step 3 */}
                    <div className="device-step-1">
                        <p style={{ textAlign: "left" }}>Click on <strong>Device Name</strong> to see the Device Details. like Dashboard, Connected Devices, Warnings & Alerts, Template and Network information.</p>
                        <div className="img-box">
                            <img src="/images/user-guide/device-details.png" alt="" />
                        </div>
                    </div>
                </Carousel>
                {/* close device step 3 */}

                <div className="text-right">
                    <button className="btn btn-light mr-3" onClick={() => { this.props.goToStep(5); this.props.stepChange(5) }}>Skip</button>
                    <button className="btn btn-light mr-3" onClick={() => { this.props.goToStep(1); this.props.stepChange(1) }}>Back</button>
                    <button className="btn btn-primary"
                        onClick={() => { this.props.nextStep(3); this.props.stepChange(3) }}>Next</button>
                </div>
            </div>
        )
    }
}
