/* eslint-disable array-callback-return */
import React, { Component } from "react";
import $ from 'jquery';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import { ErrorPopup } from '../../layOuts/errorPopup';
import { Netmask } from 'netmask';
import { LinkPopup } from '../../layOuts/linkPopup';
import ErrorHandlePopup from "../ErrorHandlePopup";
import * as Common from "../../utils/common";
import Select from 'react-select';
import { connect } from "react-redux";

class TunnelSettings extends Component {
    constructor(props) {
        super(props); this.state = {
            showRefreshModal: false,
            showLoader: false,
            deviceData: undefined,
            showLink: false,
            showMessage: false,
            showError: false,
            tunnel_name: "",
            closeTunnel: true,
            back: true,
            device_A_zone: true,
            device_B_zone: true,
            BGP_device_A_zone: true,
            BGP_device_B_zone: true,
            keyUpdate: '',
            deviceA_networks: [],
            deviceB_networks: [],
            createClicked: false,
            selectedAvailablePortA: null,
            selectedAvailablePortB: null,
            isAxiosError: false
        }
    }
    static getDerivedStateFromProps = (props, state) => {
        if (props.currentStep === 3 && props.devices === undefined && props.pass === false) {
            props.goToStep(2);
        }
        if (props.devices && state.back === true) {
            state.deviceData = props.devices;
            state.deviceA_networks = props.deviceA_networks;
            state.deviceB_networks = props.deviceB_networks;
            // Issue fixed in tunnel create button
            state.tunnel_name = props.devices.settings.tunnel_name;
            state.keyUpdate = Math.random();
        }
        return state;
    }

    getOptions = (option) => {
        let optionsArray = [{ label: "Select", value: "" }];
        if (option === 'deviceA_port') {
            optionsArray = this.props.devices?.device_A?.available_ports.map((item) => {
                return { label: item, value: item }
            });
        }
        else {
            optionsArray = this.props.devices?.device_B?.available_ports.map((item) => {
                return { label: item, value: item }
            });
        }
        return optionsArray;
    }

    handleKeyDown = evt => {
        this.removeError(evt);
        let valid = false;
        if (evt.target.value && (["Enter", "Tab", " ", ","].includes(evt.key) || evt.type === "blur")) {
            evt.preventDefault();
            const items = evt.target.name;
            let ip;
            if (evt.target.value.includes('/') && this.validateIPaddress(evt.target.value.split('/')[0], evt.target.name)) {
                ip = new Netmask(evt.target.value);
                valid = this.validateIPaddress(evt.target.value.split('/')[0], evt.target.name) && ip.contains(evt.target.value.split('/')[0]);

            } else
                valid = this.validateIPaddress(evt.target.value, evt.target.name);

            let data = this.state.deviceData;
            if (valid && items === "device_A_allowedIPs") {
                if (evt.target.value && !data.device_A.allowed_ips.includes(evt.target.value)) {
                    data.device_A.allowed_ips = [...data.device_A.allowed_ips, evt.target.value];
                    this.setState({
                        deviceData: data
                    });
                }
                $("input[name=device_A_allowedIPs]").val("");
            }
            else {
                if (valid && items === "device_B_allowedIPs") {
                    let data = this.state.deviceData;
                    if (evt.target.value && !data.device_B.allowed_ips.includes(evt.target.value)) {

                        data.device_B.allowed_ips = [...data.device_B.allowed_ips, evt.target.value];
                        this.setState({
                            deviceData: data
                        });
                    }
                    $("input[name=device_B_allowedIPs]").val("");
                }
            }
        }
    };

    setAllowedIP = (evt) => {
        this.removeError(evt);
        const value = evt.target.dataset.value;
        let data = this.state.deviceData;
        const name = evt.target.dataset.name;
        if (name === "device_A_allowedIPs") {
            if (!data.device_A.allowed_ips.includes(value)) {
                data.device_A.allowed_ips = [...data.device_A.allowed_ips, value];
                this.setState({
                    deviceData: data
                });
            }
            $("input[name=device_A_allowedIPs]").val("");
        }
        else {
            if (name === "device_B_allowedIPs") {
                let data = this.state.deviceData;
                if (value && !data.device_B.allowed_ips.includes(value)) {

                    data.device_B.allowed_ips = [...data.device_B.allowed_ips, value];
                    this.setState({
                        deviceData: data
                    });
                }
                $("input[name=device_B_allowedIPs]").val("");
            }
        }
    };
    handleDeleteA = item => {
        let data = this.state.deviceData;
        data.device_A.allowed_ips = data.device_A.allowed_ips.filter(i => i !== item)
        this.setState({
            deviceData: data
        });
    };
    handleDeleteB = item => {
        let data = this.state.deviceData;
        data.device_B.allowed_ips = data.device_B.allowed_ips.filter(i => i !== item)
        this.setState({
            deviceData: data
        });
    };
    setValue = (e) => {
        this.removeError(e);
        let value = '';
        const name = e.target.name;
        if (e.target.name === 'tunnel_keep_alive_status') {
            let data = this.state.deviceData;
            data.settings.tunnel_keep_alive_status = e.target.checked === true ? 1 : 0;
            value = e.target.checked === true ? 1 : 0;
            this.setState({
                [name]: value,
                deviceData: data
            });
        } else if (e.target.name === 'tunnel_keep_alive_interval') {
            let data = this.state.deviceData;
            data.settings.tunnel_keep_alive_interval = parseInt(e.target.value);
            this.setState({
                [name]: value,
                deviceData: data
            });
            if (e.target.value === '' || parseInt(e.target.value) > 120 || parseInt(e.target.value) < 1) {
                const element = document.getElementById(name);
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }

        }
        else {
            value = e.target.value;
            let data = this.state.deviceData;
            if (name === 'device_A_endpoint') {
                data.device_A.endpoint_details = value;
            } else if (name === 'device_B_endpoint') {
                data.device_B.endpoint_details = value;
            }
            if (name === 'device_A_tunnel_link') {
                data.device_A.tunnel_link = value;
            } else if (name === 'device_B_tunnel_link') {
                data.device_B.tunnel_link = value;
            }
            // Issue fixed in tunnel create button
            if (name === 'tunnel_name') {
                if (value.length > 32) {
                    data.settings.tunnel_name = value;
                    const element = document.getElementById(name);
                    element.style.display = "block";
                    element.innerHTML = "Enter name up to 32 characters";
                    this.setState({
                        showError: true,
                    });
                } else {
                    data.settings.tunnel_name = value;
                }
            }

            this.setState({
                [name]: value,
                deviceData: data,
                tunnelName: true,
            });
        }

    }
    setTunnelLink = (e, device) => {
        let data = this.state.deviceData;
        if (device === 'device_A_tunnel_link') {
            data.device_A.tunnel_link = e && e.value ? e.value : '';
        } else {
            data.device_B.tunnel_link = e && e.value ? e.value : '';
        }
        this.setState({
            deviceData: data
        });
    }

    getDeviceNetworks = (device) => {
        let optionsArray = [{ label: "Select", value: "" }];
        if (device === 'device_A') {
            this.props.deviceA_networks.map((item) => {
                if (item.network_zone === 'wan') {
                    if (!this.props.devices?.device_A?.used_tunnel_link_interface?.includes(item.networkName)) {
                        optionsArray.push({ label: item.name, value: item.networkName })
                    }
                }
            });
        }
        else {
            this.props.deviceB_networks.map((item) => {
                if (item.network_zone === 'wan') {
                    if (!this.props.devices?.device_B?.used_tunnel_link_interface?.includes(item.networkName)) {
                        optionsArray.push({ label: item.name, value: item.networkName })
                    }
                }
            });
        }
        return optionsArray;
    }
    getNetworkName = (value, device) => {
        let network = "";
        let networks = device === 'device_A' ? this.props.deviceA_networks : this.props.deviceB_networks;
        networks.map((item) => {
            if (item.network_zone === 'wan' && item.networkName === value) {
                network = item.name;
            }
        })
        return network;
    }
    setEndpoint = (e) => {
        const value = e.target.dataset.value;
        const name = e.target.dataset.name;
        let data = this.state.deviceData;
        if (name === 'device_A_endpoint') {
            data.device_A.endpoint_details = value;
            const element = document.getElementById("device_A_endpoint");
            if (element) {
                element.style.display = 'none';
            }
            this.setState({
                showError: false,
            });
        } else if (name === 'device_B_endpoint') {
            data.device_B.endpoint_details = value;
            const element = document.getElementById("device_B_endpoint");
            if (element) {
                element.style.display = 'none';
            }
            this.setState({
                showError: false,
            });
        }
        this.setState({
            [name]: value,
            deviceData: data
        });
    }
    validateIPaddress = (ipaddress, name) => {
        //LONG-2998-EM - Field validation for track address,Domain name should be included in keepalive configuration,Scenario 2
        let DomainName = /^(www.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]+?\.[a-zA-Z]{2,3}$/.test(ipaddress)
        if (ipaddress !== '') {
            if (DomainName === false) {
                if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
                    return (true);
                }
                else {
                    const element = document.getElementById(name);
                    element.style.display = "block";
                    this.setState({
                        showError: true,
                    });
                }
                return (false);
            }
            else {
                const element = document.getElementById(name);
                element.style.display = "none";
                this.setState({
                    showError: false,
                });
                return (true);
            }
        }
    }
    removeError = (e) => {
        const element = document.getElementById(e.target.name);
        if (element)
            element.style.display = 'none';
        this.setState({
            showError: false
        })
    }
    showPopup = () => {
        //LONG-2567-EM Tunnel Creation message modification
        let device_A_model = Common.getModels(this.state.deviceData.device_A.device_model);
        let device_B_model = Common.getModels(this.state.deviceData.device_B.device_model);
        if (device_A_model === "Moretti_Model" || device_B_model === "Moretti_Model" ||
            device_A_model === "Duvel_Model" || device_B_model === "Duvel_Model") {
            this.setState({
                title: 'Create Tunnel',
                message: "Apply Static Route Template in each devices to add routes manually for required networks to enable communication between each other.",
                showMessage: true,
                delete: true,
                error: false,
                closeTunnel: false
            }, () => $("#errorMessage").modal("show"));
        }
        else if (this.state.BGP_device_A_zone === true && this.state.BGP_device_B_zone === true) {
            this.setState({
                title: 'Create Tunnel',
                message: "BGP Session is enabled between Device-A to Device-B via Data Tunnel with redistribution of Default network.",
                showMessage: true,
                delete: true,
                error: false,
                closeTunnel: false
            }, () => $("#errorMessage").modal("show"));
        } else {
            this.setState({
                title: 'Create Tunnel',
                message: "Apply BGP template to share routes dynamically for all networks available in the device (or) Apply Static Route template to add routes manually for required networks to enable communication between each other.",
                showMessage: true,
                error: false,
                delete: true,
                closeTunnel: false
            }, () => $("#errorMessage").modal("show"));
        }
    }

    checkDeviceTask = async () => {
        let deviceMac = [];

        deviceMac.push(this.state.deviceData.device_A.device_mac, this.state.deviceData.device_B.device_mac);

        let data = { device_mac: deviceMac, operation_type: ['Create Tunnel', 'Update Tunnel', 'TunnelConfiguration', 'Firmware Upgrade', 'BGPConfiguration'] }
        this.setState({
            showLoader: true
        });

        const trueArr = [];
        await ApiCall(urlMapping.checkDeviceTask(data), response => {
            this.setState({
                showLoader: false,
                show: false
            });
            $("#errorMessage").modal("hide");
            if (response?.success === true) {
                const data = response?.data;
                Object?.keys(data)?.forEach(item => {
                    if (data?.[item]?.status === true) {
                        trueArr?.push(data?.[item]);
                    }
                });
                if (trueArr?.length > 0) {
                    this.setState({
                        title: 'Device Task',
                        // message: trueArr[0].message,
                        message: "An operation on similar/dependent template is in-progress, please wait until the operation completes",
                        show: true,
                        error: true,
                        delete: false,
                        toggledClearRows: !this.state.toggledClearRows,
                    }, () => $("#errorMessage").modal("show"));
                }
                this.setState({
                    show: false,
                    error: false,
                    delete: false
                })
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Device Task',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
        return trueArr;
    }
    checkError = () => {
        // EMGT-273-EM - Remote Endpoint Field Validation in Tunnel Page
        let result = false;
        const elements = document.getElementsByClassName('error-txt');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        // Create button should be enabled by default as tunnel name is getting filled automatically from api
        if ((this.state.tunnelName && this.state.tunnel_name === '') || this.state.tunnel_name?.length > 32)
            result = true;
        if (this.state.showError || this.state.show || this.state.showLink)
            result = true;
        if (this.state.deviceData.device_A.load_balance === '1' &&
            (this.state.deviceData.device_A.tunnel_link === undefined ||
                this.state.deviceData.device_A.tunnel_link === ''))
            result = true;
        if (this.state.deviceData.device_B.load_balance === '1' &&
            (this.state.deviceData.device_B.tunnel_link === undefined ||
                this.state.deviceData.device_B.tunnel_link === ''))
            result = true;
        if (this.props.disableCreate)
            result = true;
        return result;
    }
    createTunnel = async () => {
        const haveTask = await this.checkDeviceTask();
        let data = this.state.deviceData;
        data.device_A.public_key = this.state.device_A_publicKey === undefined ? data.device_A.public_key : this.state.device_A_publicKey;
        data.device_A.endpoint_details = this.state.device_A_endpoint === undefined ? data.device_A.endpoint_details : this.state.device_A_endpoint;
        data.device_A.allowed_ips = this.state.device_A_allowedIPs === undefined ?
            (Common.getModels(this.state.deviceData.device_A.device_model) === "CHIMAY" ||
                Common.getModels(this.state.deviceData.device_A.device_model) === "vCPE") ? ["0.0.0.0/0"] :
                data.device_A.allowed_ips : this.state.device_A_allowedIPs;
        data.device_B.public_key = this.state.device_B_publicKey === undefined ? data.device_B.public_key : this.state.device_B_publicKey;
        data.device_B.endpoint_details = this.state.device_B_endpoint === undefined ? data.device_B.endpoint_details : this.state.device_B_endpoint;
        data.device_B.allowed_ips = this.state.device_B_allowedIPs === undefined ?
            (Common.getModels(this.state.deviceData.device_B.device_model) === "CHIMAY" ||
                Common.getModels(this.state.deviceData.device_B.device_model) === "vCPE") ? ["0.0.0.0/0"] :
                data.device_B.allowed_ips : this.state.device_B_allowedIPs;
        data.settings.tunnel_name = this.state.tunnel_name ? this.state.tunnel_name : data.settings.tunnel_name;
        data.settings.is_change_in_tunnel = true;
        // LONG-2215 : Toggle Button to Add interface into default data zone
        data.device_A.add_zone_in_firewall = this.state.device_A_zone ? true : false;
        data.device_B.add_zone_in_firewall = this.state.device_B_zone ? true : false;
        data.device_A.add_bgp_in_tunnel = this.state.BGP_device_A_zone ? true : false;
        data.device_B.add_bgp_in_tunnel = this.state.BGP_device_B_zone ? true : false;
        data.device_A.own_port = this.state.selectedAvailablePortA ? this.state.selectedAvailablePortA : (data.device_A?.available_ports?.[0] || data.device_A?.available_ports !== null ? data.device_A?.available_ports?.[0] : "");
        data.device_B.own_port = this.state.selectedAvailablePortB ? this.state.selectedAvailablePortB : (data.device_B?.available_ports?.[0] || data.device_B?.available_ports !== null ? data.device_B?.available_ports?.[0] : "");
        if (data.settings.tunnel_keep_alive_status === 0)
            data.settings.tunnel_keep_alive_interval = 0;

        this.setState({
            showLoader: true,
        });
        if (haveTask?.length === 0) {
            ApiCall(urlMapping.createTunnel(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.setState({
                        title: 'Create Tunnel',
                        message: 'Create Tunnel Operation Initiated Successfully. To view the Operation logs ',
                        showLink: true,
                        id: response?.data,
                        closeTunnel: true
                    }, () => {
                        $("#exampleModal").modal("show")
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        this.setState({
                            title: 'Create Tunnel',
                            message: response?.message,
                            show: true,
                            error: true,
                            closeTunnel: false
                        }, () => $("#errorMessage").modal("show"));
                    }
                }
            });
        } else {
            this.setState({
                showLoader: false
            });
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            showMessage: false
        }, () => {
            $("#errorMessage").modal("hide");
            this.props.getTunnelList();
            if (this.state.closeTunnel)
                this.props.closeAddTunnel();
        })
    }

    openOperationList = (value) => {
        if (this.state.closeTunnel)
            this.props.closeAddTunnel();

        $("#exampleModal").modal("hide");
        this.props.history.push({
            pathname: '/operations',
            state: { data: value }
        })
        this.props.closeAddTunnel();
    }
    closeLinkpopup = () => {
        $("#exampleModal").modal("hide");
        this.setState({ showLink: false, id: '' }, () => {
            this.props.getTunnelList();
            this.props.closeAddTunnel();
        })

    }
    // LONG-2215 : Toggle Button to Add interface into default data zone
    handleChange = (e, name) => {
        if (name === "device_A_zone") {
            if (e.target.checked) {
                this.setState({
                    device_A_zone: true
                })
            }
            else {
                this.setState({
                    device_A_zone: false
                })
            }
        }
        else if (name === "device_B_zone") {
            if (e.target.checked) {
                this.setState({
                    device_B_zone: true
                })
            }
            else {
                this.setState({
                    device_B_zone: false
                })
            }
        }
        else if (name === "BGP_device_A_zone") {
            if (e.target.checked) {
                this.setState({
                    BGP_device_A_zone: true
                })
            }
            else {
                this.setState({
                    BGP_device_A_zone: false
                })
            }
        }
        else if (name === "BGP_device_B_zone") {
            if (e.target.checked) {
                this.setState({
                    BGP_device_B_zone: true
                })
            }
            else {
                this.setState({
                    BGP_device_B_zone: false
                })
            }
        }
    }
    handleAvailablePorts = (e, port) => {
        if (port === 'PortA') {
            this.setState({
                selectedAvailablePortA: e && e.value ? e.value : ''
            })
        }
        else {
            this.setState({
                selectedAvailablePortB: e && e.value ? e.value : ''
            })
        }
    }
    goback = () => {
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;";
        }
        let { deviceData } = this.state;
        deviceData.device_A.tunnel_link = "";
        deviceData.device_B.tunnel_link = "";
        this.setState({
            back: true,
            selectedAvailablePortA: null,
            selectedAvailablePortB: null,
            deviceData,
            showError: false
        })
    }
    render() {
        if (this.state.deviceData)
            return (
                <div className="tunnel-box-setting" >
                    {this.state.showLoader && <Loader />}
                    <div className="graybg-title d-flex align-items-center justify-content-between mt-3">
                        <div>Device A</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="d-flex align-items-center mb-3">
                                <div className="device-icon">
                                    {/* LONG-2371 : Fixed IP Icon show in Data Tunnel Page */}
                                    {this.state.deviceData.device_A.is_fixed_ip === 1 &&
                                        <>
                                            <div className="ip-img">
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="This device is having Fixed IP address">
                                                    <img src="/images/ip.png" alt="" />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <img src="/images/svg-icons/wifi-router.svg" alt="" />
                                    <div className={this.state.deviceData.device_A.device_status === 1 ? "status online" : "status offline"}></div>
                                </div>
                                <div className="d-title flex-grow-1 ml-3" title={this.state.deviceData.device_A.device_name}>
                                    <strong>{this.state.deviceData.device_A.device_name}</strong>
                                </div>
                            </div>
                        </div>
                        {/* LONG-2215 : Toggle Button to Add interface into default data zone */}
                        <div className="col-md-4">
                            <div className="d-flex align-items-center mt-3">
                                <input type="checkbox"
                                    checked={this.state.device_A_zone}
                                    // LONG-2374 : Disabled add interface to zone for mifi model
                                    disabled={Common.getModels(this.state.deviceData?.device_A?.device_model) === "Moretti_Model" ||
                                        Common.getModels(this.state.deviceData?.device_A?.device_model) === "Duvel_Model"}
                                    style={{
                                        cursor: Common.getModels(this.state.deviceData?.device_A?.device_model) === "Moretti_Model" ||
                                            Common.getModels(this.state.deviceData?.device_A?.device_model) === "Duvel_Model" ? "not-allowed" : ""
                                    }}
                                    onChange={(e) => this.handleChange(e, "device_A_zone")}
                                />
                                <label className="mb-0 ml-2">
                                    Add Interface to Zone
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Add tunnel interface to the default Data Tunnel Zone"
                                    >
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex align-items-center mt-3">
                                <input type="checkbox"
                                    checked={this.state.BGP_device_A_zone}
                                    // LONG-2374 : Disabled enable BGP for mifi model
                                    disabled={Common.getModels(this.state.deviceData?.device_A?.device_model) === "Moretti_Model" ||
                                        Common.getModels(this.state.deviceData?.device_A?.device_model) === "Duvel_Model"}
                                    style={{
                                        cursor: Common.getModels(this.state.deviceData?.device_A?.device_model) === "Moretti_Model" ||
                                            Common.getModels(this.state.deviceData?.device_A?.device_model) === "Duvel_Model" ? "not-allowed" : ""
                                    }}
                                    onChange={(e) => this.handleChange(e, "BGP_device_A_zone")}
                                />
                                <label className="mb-0 ml-2">
                                    Enable BGP
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Enable BGP for Dynamic Routing of available networks"
                                    >
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label>Public Key
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="The key used to encrypt data. Each peer has its own public key">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <input type="text"
                                    name="device_A_publicKey"
                                    onChange={(e) => this.setValue(e)}
                                    minlength="35" maxlength="50"
                                    disabled
                                    style={{ cursor: "not-allowed" }}
                                    title={this.state.deviceData.device_A.public_key}
                                    //Long-1789 Public key is not updating in Create Tunnel page when changing the device in select device option	
                                    value={this.state.deviceData.device_A.public_key} className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-4" >
                            <div className="form-group with-arrow">
                                <label>Remote Endpoint Details
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="The public facing Internet IP address of the remote side of the tunnel">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <input type="text"
                                    name="device_A_endpoint"
                                    onChange={(e) => this.setValue(e)}
                                    readOnly={this.state.deviceData.device_A.load_balance === '1'}
                                    onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                    value={(this.state.deviceData.device_A.endpoint_details === null ||
                                        this.state.deviceData.device_A.endpoint_details === 'null') ? '' :
                                        this.state.deviceData.device_A.endpoint_details}
                                    className="form-control" />
                                <i className="drop-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                <div className="dropdown-menu max-height-200" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        {this.state.deviceData.device_A.available_endpoint_details?.map((item) => {
                                            if (item)
                                                return <li className={this.state.deviceData.device_A.load_balance === '1' &&
                                                    this.state.deviceData.device_A.used_tunnel_endpoint?.includes(item) ? "disabled" : ""}
                                                    data-name="device_A_endpoint" data-value={item}
                                                    onClick={this.state.deviceData.device_A.load_balance === '1' && this.state.deviceData.device_A.used_tunnel_endpoint?.includes(item) ? undefined :
                                                        (e) => this.setEndpoint(e)}
                                                    disabled={this.state.deviceData.device_A.load_balance === '1' && this.state.deviceData.device_A.used_tunnel_endpoint?.includes(item)}>{item}</li>
                                        })}
                                    </ul>
                                </div>
                                <div className="error-txt" id="device_A_endpoint">Invalid value</div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div>
                                <label>Listen Port
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Available listen ports for data tunnel creation">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <Select options={this.getOptions('deviceA_port')}
                                    isClearable
                                    isSearchable
                                    className="custom-dropdown w-105"
                                    classNamePrefix="react-select-container"
                                    value={{
                                        label: this.state?.selectedAvailablePortA ? this.state?.selectedAvailablePortA : (this.state?.deviceData.device_A?.available_ports[0] || this.state?.deviceData.device_A?.available_ports !== null ? this.state?.deviceData.device_A?.available_ports[0] : ""),
                                        value: this.state?.selectedAvailablePortA ? this.state?.selectedAvailablePortA : (this.state?.deviceData.device_A?.available_ports[0] || this.state?.deviceData.device_A?.available_ports !== null ? this.state?.deviceData.device_A?.available_ports[0] : ""),
                                    }}
                                    onChange={(e) => this.handleAvailablePorts(e, 'PortA')}
                                />

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {/* Hiding remote allowed ips for Chimay and CBR devices */}
                            {!((Common.getModels(this.state.deviceData.device_A.device_model) === "CHIMAY") || (Common.getModels(this.state.deviceData.device_A.device_model)) === "vCPE") &&
                                <>
                                    <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                            <label className="mb-0">Remote Allowed IPs
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="These are the CIDR formatted remote subnets of the opposite side of the tunnel">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>
                                        </div>
                                        <div className="allowedip-box with-arrow">
                                            {this.state.deviceData.device_A.allowed_ips.map((item) => {
                                                return (
                                                    (item !== null && item !== 'null' && item !== '') && <div className="tag-item" key={item} >
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="button"
                                                            onClick={() => this.handleDeleteA(item)}
                                                        >
                                                            <img src="/images/svg-icons/cancel.svg" alt="" />
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                            <input type="text"
                                                name="device_A_allowedIPs"
                                                onKeyDown={(e) => this.handleKeyDown(e)}
                                                onBlur={(e) => this.handleKeyDown(e)}
                                                autoComplete="off"
                                            />
                                            <i className="drop-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            <div className="dropdown-menu max-height-200" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    {this.state.deviceData.device_A.available_allowed_ips?.map((item) => {
                                                        if (item)
                                                            return <li data-name="device_A_allowedIPs" data-value={item} onClick={(e) => this.setAllowedIP(e)}>{item}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="error-txt" id="device_A_allowedIPs">Invalid value</div>
                                    </div>
                                </>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group"
                                style={{ cursor: this.state.deviceData.device_A.load_balance === '0' ? "not-allowed" : "" }}>
                                <label>WAN Binding
                                    {this.state.deviceData.device_A.load_balance === '1' &&
                                        <span className="red-txt">*</span>}</label>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Select the WAN interface to be mapped with the tunnel.">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                                <Select
                                    // name="device_A_tunnel_link"
                                    options={this.getDeviceNetworks('device_A')}
                                    isClearable={true}
                                    isSearchable={false}
                                    className="custom-dropdown w-145"
                                    classNamePrefix="react-select-container"
                                    onChange={(e) => this.setTunnelLink(e, 'device_A_tunnel_link')}
                                    value={{ label: this.getNetworkName(this.state.deviceData.device_A.tunnel_link, 'device_A'), value: this.state.deviceData.device_A.tunnel_link }}
                                    isDisabled={this.state.deviceData.device_A.load_balance === '0'}
                                />

                            </div>
                        </div>

                    </div>
                    <div className="graybg-title d-flex align-items-center justify-content-between mt-4">
                        <div>Device B</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="d-flex align-items-center mb-3">
                                <div className="device-icon">
                                    {/* LONG-2371 : Fixed IP Icon show in Data Tunnel Page */}
                                    {this.state.deviceData.device_B.is_fixed_ip === 1 &&
                                        <>
                                            <div className="ip-img">
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="This device is having Fixed IP address">
                                                    <img src="/images/ip.png" alt="" />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <img src="/images/svg-icons/wifi-router.svg" alt="" />
                                    <div className={this.state.deviceData.device_B.device_status === 1 ? "status online" : "status offline"}></div>
                                </div>
                                <div className="d-title flex-grow-1 ml-3" title={this.state.deviceData.device_B.device_name}>
                                    <strong>{this.state.deviceData.device_B.device_name}</strong>
                                </div>
                            </div>
                        </div>
                        {/* LONG-2215 : Toggle Button to Add interface into default data zone */}
                        <div className="col-md-4">
                            <div className="d-flex align-items-center mt-3">

                                <input type="checkbox"
                                    checked={this.state.device_B_zone}
                                    // LONG-2374 : Disabled add interface to zone for mifi model
                                    disabled={Common.getModels(this.state.deviceData?.device_B?.device_model) === "Moretti_Model" ||
                                        Common.getModels(this.state.deviceData?.device_B?.device_model) === "Duvel_Model"}
                                    style={{
                                        cursor: Common.getModels(this.state.deviceData?.device_B?.device_model) === "Moretti_Model" ||
                                            Common.getModels(this.state.deviceData?.device_B?.device_model) === "Duvel_Model" ? "not-allowed" : ""
                                    }}
                                    onChange={(e) => this.handleChange(e, "device_B_zone")}
                                />
                                <label className="mb-0 ml-2">
                                    Add Interface to Zone
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Add tunnel interface to the default Data Tunnel Zone">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="d-flex align-items-center mt-3">
                                <input type="checkbox"
                                    checked={this.state.BGP_device_B_zone}
                                    // LONG-2374 : Disabled enable BGP for mifi model
                                    disabled={Common.getModels(this.state.deviceData?.device_B?.device_model) === "Moretti_Model" ||
                                        Common.getModels(this.state.deviceData?.device_B?.device_model) === "Duvel_Model"}
                                    style={{
                                        cursor: Common.getModels(this.state.deviceData?.device_B?.device_model) === "Moretti_Model" ||
                                            Common.getModels(this.state.deviceData?.device_B?.device_model) === "Duvel_Model" ? "not-allowed" : ""
                                    }}
                                    onChange={(e) => this.handleChange(e, "BGP_device_B_zone")}
                                />
                                <label className="mb-0 ml-2">
                                    Enable BGP
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Enable BGP for Dynamic Routing of available networks"
                                    >
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label>Public Key
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="The key used to encrypt data. Each peer has its own public key">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <input type="text"
                                    name="device_B_publicKey"
                                    onChange={(e) => this.setValue(e)}
                                    minlength="35" maxlength="50"
                                    disabled
                                    style={{ cursor: "not-allowed" }}
                                    title={this.state.deviceData.device_B.public_key}
                                    //Long-1789 Public key is not updating in Create Tunnel page when changing the device in select device option	
                                    value={this.state.deviceData.device_B.public_key}
                                    className="form-control" />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group with-arrow">
                                <label>Remote Endpoint Details
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="The public facing Internet IP address of the remote side of the tunnel">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <input type="text"
                                    name="device_B_endpoint"
                                    onChange={(e) => this.setValue(e)}
                                    onBlur={(e) => this.validateIPaddress(e.target.value, e.target.name)}
                                    readOnly={this.state.deviceData.device_B.load_balance === '1'}
                                    value={(this.state.deviceData.device_B.endpoint_details === null ||
                                        this.state.deviceData.device_B.endpoint_details === 'null') ? '' :
                                        this.state.deviceData.device_B.endpoint_details}
                                    className="form-control" />
                                <i className="drop-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                <div className="dropdown-menu max-height-200" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        {this.state.deviceData.device_B.available_endpoint_details?.map((item) => {
                                            if (item)
                                                return <li className={this.state.deviceData.device_B.load_balance === '1' &&
                                                    this.state.deviceData.device_B.used_tunnel_endpoint?.includes(item) ? "disabled" : ""}
                                                    data-name="device_B_endpoint" data-value={item}
                                                    onClick={this.state.deviceData.device_B.load_balance === '1' && this.state.deviceData.device_B.used_tunnel_endpoint?.includes(item) ? undefined :
                                                        (e) => this.setEndpoint(e)}
                                                    disabled={this.state.deviceData.device_B.load_balance === '1' && this.state.deviceData.device_B.used_tunnel_endpoint?.includes(item)}>{item}</li>
                                        })}
                                    </ul>
                                </div>
                                <div className="error-txt" id="device_B_endpoint">Invalid value</div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div>
                                <label>Listen Port
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Available listen ports for data tunnel creation">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <Select options={this.getOptions('deviceB_port')}
                                    isClearable
                                    isSearchable
                                    className="custom-dropdown w-105"
                                    classNamePrefix="react-select-container"
                                    value={{
                                        label: this.state?.selectedAvailablePortB ? this.state?.selectedAvailablePortB : (this.state?.deviceData.device_B?.available_ports[0] || this.state?.deviceData.device_B?.available_ports !== null ? this.state?.deviceData.device_B?.available_ports[0] : ""),
                                        value: this.state?.selectedAvailablePortB ? this.state?.selectedAvailablePortB : (this.state?.deviceData.device_B?.available_ports[0] || this.state?.deviceData.device_B?.available_ports !== null ? this.state?.deviceData.device_B?.available_ports[0] : ""),
                                    }}
                                    onChange={(e) => this.handleAvailablePorts(e, 'PortB')}
                                />

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {/* Hiding remote allowed ips for Chimay and CBR devices */}
                            {!(Common.getModels(this.state.deviceData.device_B.device_model) === "CHIMAY" || Common.getModels(this.state.deviceData.device_B.device_model) === "vCPE") &&
                                <>
                                    <div className="form-group">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                            <label className="mb-0">Remote Allowed IPs
                                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="These are the CIDR formatted remote subnets of the opposite side of the tunnel">
                                                    <img src="/images/svg-icons/info.svg" alt="" />
                                                </div>
                                            </label>

                                        </div>
                                        <div className="allowedip-box with-arrow">
                                            {this.state.deviceData.device_B.allowed_ips.map((item) => {
                                                return (
                                                    (item !== null && item !== 'null' && item !== '') && <div className="tag-item" key={item}>
                                                        {item}
                                                        <button
                                                            type="button"
                                                            className="button"
                                                            onClick={() => this.handleDeleteB(item)}
                                                        >
                                                            <img src="/images/svg-icons/cancel.svg" alt="" />
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                            <input type="text"
                                                name="device_B_allowedIPs"
                                                onKeyDown={(e) => this.handleKeyDown(e)}
                                                onBlur={(e) => this.handleKeyDown(e)}
                                                autoComplete="off"
                                            />
                                            <i className="drop-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            <div className="dropdown-menu max-height-200" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    {this.state.deviceData.device_B.available_allowed_ips?.map((item) => {
                                                        if (item)
                                                            return <li data-name="device_B_allowedIPs" data-value={item} onClick={(e) => this.setAllowedIP(e)}>{item}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="error-txt" id="device_B_allowedIPs">Invalid value</div>
                                    </div>
                                </>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group"
                                style={{ cursor: this.state.deviceData.device_B.load_balance === '0' ? "not-allowed" : "" }}>
                                <label>WAN Binding
                                    {this.state.deviceData.device_B.load_balance === '1' &&
                                        <span className="red-txt">*</span>}
                                </label>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Select the WAN interface to be mapped with the tunnel.">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                                <Select
                                    name="device_B_tunnel_link"
                                    options={this.getDeviceNetworks('device_B')}
                                    isClearable={true}
                                    isSearchable={false}
                                    className="custom-dropdown w-145"
                                    classNamePrefix="react-select-container"
                                    onChange={(e) => this.setTunnelLink(e, 'device_B_tunnel_link')}
                                    value={{ label: this.getNetworkName(this.state.deviceData.device_B.tunnel_link, 'device_B'), value: this.state.deviceData.device_B.tunnel_link }}
                                    isDisabled={this.state.deviceData.device_B.load_balance === '0'}
                                />

                            </div>
                        </div>

                    </div>
                    <div className="gray-bg p-3">
                        <div className="page-title">Settings</div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Tunnel Name
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of tunnel">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        value={this.state.deviceData.settings.tunnel_name}
                                        className={this.state.deviceData.settings.tunnel_name.length > 32 ? "form-control error-field" : "form-control"}
                                        name="tunnel_name"
                                        onChange={(e) => this.setValue(e)} />
                                    <div className="error-txt" id="tunnel_name">Invalid value</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <label className="mb-0">Keep Alive
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Keep alive status of tunnel">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <label className="switch">
                                        <input type="checkbox"
                                            checked={this.state.deviceData.settings.tunnel_keep_alive_status === 1}
                                            name="tunnel_keep_alive_status"
                                            onChange={(e) => this.setValue(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {this.state.deviceData.settings.tunnel_keep_alive_status === 1 && <div className="col-md-6">
                                <div className="form-group">
                                    <label>Keep Alive Interval
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Keep alive interval of tunnel">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>

                                    <div> <input type="number"
                                        defaultValue={this.state.deviceData.settings.tunnel_keep_alive_interval}
                                        className="form-control"
                                        min="1"
                                        max="120"
                                        name="tunnel_keep_alive_interval"
                                        onChange={(e) => this.setValue(e)}
                                    /></div>
                                    <span className="units">Seconds</span>

                                    <div className="error-txt" id="tunnel_keep_alive_interval">Keep alive interval can be 1 to 120</div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="s-footer">
                        <div className="slide-pagination">3/3</div>
                        <button className="btn btn-light" onClick={() => {
                            this.props.goToStep(2);
                            this.goback();
                        }}>Back</button>
                        <button className="btn btn-primary" disabled={this.checkError()} onClick={() => {
                            this.showPopup()
                        }}>Create</button>
                    </div>
                    {
                        (this.state.show) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            reset={() => this.resetPopup()}
                        />
                    }
                    {
                        (this.state.showLink) &&
                        <LinkPopup
                            title={this.state.title}
                            message={this.state.message}
                            open={() => this.openOperationList(this.state.id)}
                            close={() => this.closeLinkpopup()}
                        />
                    }
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                    {
                        (this.state.showMessage) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.resetPopup()}
                            onSubmit={() => this.createTunnel()}
                        />
                    }
                    {(this.state.isAxiosError) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.setState({ isAxiosError: false })}
                        />
                    }
                </div >
            )
        else
            return <div className="text-center p-5">No data available</div>
    }
}
const mapStateToProps = (state) => {
    return ({
        selectTunnelPortA: state.displayAddTunnel.selectTunnelPortA,
        selectTunnelPortB: state.displayAddTunnel.selectTunnelPortB
    });
}
export default connect(mapStateToProps)(TunnelSettings);