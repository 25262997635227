import React from 'react';

export const ProgressBar = ({ progress }) => {
    const size = 100;
    const center = size / 2;
    const radius = size / 2 - 10;
    const dotCount = 20;

    const containerStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 9999,
    };

    const circleStyle = {
        position: 'relative',
        width: size,
        height: size,
    };

    const textContainerStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    };

    const textStyle = {
        color: '#0083bf',
        fontSize: '18px',
        fontWeight: 'bold',
    };

    const createDot = (index) => {
        const angle = (index / dotCount) * 2 * Math.PI - Math.PI / 2;
        const x = center + radius * Math.cos(angle);
        const y = center + radius * Math.sin(angle);
        const isActive = progress > 0 && index < Math.floor(progress / (100 / dotCount));

        return (
            <div
                key={index}
                style={{
                    position: 'absolute',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: isActive ? '#0083bf' : '#e0e0e0',
                    left: `${x}px`,
                    top: `${y}px`,
                    transform: 'translate(-50%, -50%)',
                    animation: isActive ? 'pulse 1.8s infinite ease-in-out' : 'none',
                    animationDelay: `${index * 0.1}s`,
                }}
            />
        );
    };

    return (
        <div style={containerStyle}>
            <div style={circleStyle}>
                {Array.from({ length: dotCount }, (_, index) => createDot(index))}
                <div style={textContainerStyle}>
                    <span style={textStyle}>{progress > 0 ? `${Math?.round(progress)}%` : 'Ready'}</span>
                </div>
            </div>
            <style>
                {`
                @keyframes pulse {
                    0%, 80%, 100% { transform: translate(-50%, -50%) scale(0.8); }
                    40% { transform: translate(-50%, -50%) scale(1.2); }
                }
                `}
            </style>
        </div>
    );
};