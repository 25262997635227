import * as Action from "../actionTypes";

const initialState = {
    ignoreMemory: false,
    ignoreCPU: false,
    ignoreHardDisc: false,
    ignoreUserLogin: false
};

export const displaySystemAlerts = (state = initialState, action) => {
    switch (action.type) {
        case Action.IGNORE_MEMORY:
            return {
                ...state,
                ignoreMemory: action.payload
            };
        case Action.IGNORE_CPU:
            return {
                ...state,
                ignoreCPU: action.payload
            };
        case Action.IGNORE_HARD_DISC:
            return {
                ...state,
                ignoreHardDisc: action.payload
            };
        case Action.IGNORE_USER_LOGIN:
            return {
                ...state,
                ignoreUserLogin: action.payload
            };
        default:
            return state;
    }
};