/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import _, { debounce } from "lodash";
import $ from "jquery";
import urlMapping from "../../../../redux/API/api";
import ApiCall from "../../../../redux/API/apiCall";
import * as Action from "../../../../redux/actionTypes";

class SystemTemplateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplateId: this.props.id ? this.props.id : "",
      data: {
        syslog: {
          enabled: 1,
          logfilesize: 5,
          logfilecount: 5,
          severity: {
            emergency: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
            critical: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
            error: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
            warning: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
            notice: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 1, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 1, notify: 0 },
            },
            info: {
              enabled: 1,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 0, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 1, notify: 0 },
            },
            debug: {
              enabled: 0,
              kern: { enabled: 0, notify: 0 },
              user: { enabled: 0, notify: 0 },
              daemon: { enabled: 0, notify: 0 },
              authpriv: { enabled: 0, notify: 0 },
            },
          },
        },
      },
      selectedSeverity: {},
      template_type: this.props.templateData.template_type || "",
      template_name: this.props.templateData.template_name || "",
    }
  }
  componentDidMount() {
    if (this.props.template_schema_json?.data) {
      let temp = {};
      temp = {
        emergency:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.emergency),
        critical:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.critical),
        error:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.error),
        warning:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.warning),
        notice:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.notice),
        info:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.info),
        debug:
          this.undefinedCheck(this.props.template_schema_json?.data?.syslog?.severity?.debug),
      };
      this.setState({
        data: {
          ...this.props.template_schema_json?.data,
          syslog: {
            ...this.props.template_schema_json?.data?.syslog,
            enabled: this.props.template_schema_json?.data?.syslog?.enabled || 1,
            severity: temp,
            logfilesize: this.props.template_schema_json?.data?.syslog?.logfilesize || 5,
            logfilecount: this.props.template_schema_json?.data?.syslog?.logfilecount || 5
          }
        }
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.templateData).length !== 0) {
      if (nextProps.templateData !== undefined) {
        this.setState({
          selectedTemplateId: nextProps.id ? nextProps.id : '',
          template_type: nextProps.templateData.template_type,
          template_name: nextProps.templateData.template_name,
          selectedSeverity: {}
        });
      }
    }
    if (nextProps.edit && nextProps.template_schema_json !== undefined) {
      let temp = {};
      temp = {
        emergency:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.emergency),
        critical:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.critical),
        error:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.error),
        warning:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.warning),
        notice:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.notice),
        info:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.info),
        debug:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.debug),
      };
      this.setState({
        data: {
          ...nextProps.template_schema_json?.data,
          syslog: {
            ...nextProps.template_schema_json?.data?.syslog,
            enabled: nextProps.template_schema_json?.data?.syslog?.enabled || 1,
            severity: temp,
            logfilesize: nextProps.template_schema_json?.data?.syslog?.logfilesize || 5,
            logfilecount: nextProps.template_schema_json?.data?.syslog?.logfilecount || 5
          }
        }
      });
    }
    if (nextProps.view && nextProps.template_schema_json !== undefined) {
      let temp = {};
      temp = {
        emergency:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.emergency),
        critical:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.critical),
        error:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.error),
        warning:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.warning),
        notice:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.notice),
        info:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.info),
        debug:
          this.undefinedCheck(nextProps.template_schema_json?.data?.syslog?.severity?.debug),
      };
      this.setState({
        data: {
          ...nextProps.template_schema_json?.data,
          syslog: {
            ...nextProps.template_schema_json?.data?.syslog,
            enabled: nextProps.template_schema_json?.data?.syslog?.enabled || 1,
            severity: temp,
            logfilesize: nextProps.template_schema_json?.data?.syslog?.logfilesize || 5,
            logfilecount: nextProps.template_schema_json?.data?.syslog?.logfilecount || 5
          }
        }
      });
    }
  }
  undefinedCheck = (value) => {
    if (value) {
      let data = {
        enabled: value?.enabled || 0,
        kern: { enabled: value?.enabled || 0, notify: value?.notify || 0 },
        user: { enabled: value?.enabled || 0, notify: value?.notify || 0 },
        daemon: { enabled: value?.enabled || 0, notify: value?.notify || 0 },
        authpriv: { enabled: value?.enabled || 0, notify: value?.notify || 0 }
      }
      return data
    }
  }
  applyTemplate = () => {
    this.props.dispatch({
      type: Action.SET_APPLY_TEMPLATE,
    });
    this.props.applyTemplate({ data: this.state.data })
  };
  debounceApply = debounce((fn, event) => {
    fn(event);
  }, 500);
  changeFacility = (e, mode) => {
    let temp = this.state.selectedSeverity?.value;
    if (mode !== "root") {
      temp = {
        ...temp,
        [e.target.id]: {
          ...temp[e.target.id],
          [mode]: e.target.checked ? 1 : 0,
        },
      };
      if (mode === "enabled" && !e.target.checked) {
        temp = {
          ...temp,
          [e.target.id]: {
            ...temp[e.target.id],
            notify: 0,
          },
        };
      }
    } else {
      temp = {
        ...temp,
        enabled: e.target.checked ? 1 : 0,
      };
    }
    this.setState({
      selectedSeverity: { ...this.state.selectedSeverity, value: temp },
    });
  };
  closeAll = () => {
    this.props.dispatch({
      type: Action.CLOSE_APPLY_TEMPLATE,
    });
    this.props.closeAddTemplate();
  };
  updateChange = () => {
    let tempData = this.state.data?.syslog?.severity;
    _?.map(tempData, (severity, key) => {
      if (key === this.state.selectedSeverity?.key) {
        tempData = { ...tempData, [key]: this.state.selectedSeverity?.value };
      }
    });
    this.setState({
      data: {
        ...this.state.data,
        syslog: { ...this.state.data.syslog, severity: tempData },
      },
      selectedSeverity: {},
      editSeverity: "",
    });
  };
  getFacilityName = (key) => {
    switch (key) {
      case "kern":
        return "Kernel";
      case "user":
        return "User";
      case "daemon":
        return "Daemon";
      case "authpriv":
        return "AuthPriv";
      default:
        break;
    }
  };
  checkError = () => {
    let result = false;
    if (this.props.applying)
      result = true;
    const elements = document.getElementsByClassName("error-txt");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].style.cssText === "display: block;")
        result = true;
    }
    if (
      this.state.template_name === "" ||
      this.state.template_type === ""
    )
      result = true;
    return result;
  };
  render() {
    return (
      <div className="system-configuration">
        <div className="page-sub-title border-bottom mb-2">
          <div>
            <strong>System Log</strong>
          </div>
          <div className="device-action curson-notAllowed" >
            <span className="icon-box disabled">
              <img src="/images/svg-icons/edit.svg" alt="" className='disabled' />
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <strong>Enable SYSLOG</strong>
          <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enable Syslog messages to remote server">
            <img src="/images/svg-icons/info.svg" alt="" />
          </div>
          <label className="switch ml-3">
            <input disabled checked={this.state.data?.syslog?.enabled === 1} type="checkbox" name="advancedOptions" />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div>
            <strong>Log Rotate</strong>
          </div>
          <div>
            <label>Log file size</label>
            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Max size for file rotation in the device">
              <img src="/images/svg-icons/info.svg" alt="" />
            </div>
            <input
              type="number"
              className="form-control d-inline w-90 ml-2 mr-2 curson-notAllowed"
              value={this.state.data?.syslog?.logfilesize}
              disabled
            />
            <label>MB</label>
          </div>
          <div>
            <label>Log file limit</label>
            <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Number of files preserved in the device">
              <img src="/images/svg-icons/info.svg" alt="" />
            </div>
            <select
              value={this.state.data?.syslog?.logfilecount}
              className="form-control d-inline w-90  ml-2 curson-notAllowed"
              disabled
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
            </select>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Severity</th>
              <th>Severity Status</th>
              <th>Facility</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {_?.map(this.state.data?.syslog?.severity, (sever, key) => {
              return (
                <tr className={`${this.state.data.syslog?.enabled === 0 ? 'disabled-cell' : ''} curson-notAllowed`} >
                  <td>
                    <input
                      type="checkbox"
                      checked={this.state.selectedSeverity?.value === sever}
                      disabled
                    />
                  </td>
                  <td>
                    <span className="txt-blue">
                      {key?.[0]?.toLocaleUpperCase()?.concat(key?.slice(1))}
                    </span>
                  </td>
                  <td>
                    <span>
                      {sever?.enabled === 0 ? "Disabled" : "Enabled"}
                    </span>
                  </td>
                  <td className={sever?.enabled === 0 ? 'disabled-cell' : ''}>
                    <ul className="syslog-list">
                      {_?.map(
                        sever,
                        (item, index) =>
                          index !== "enabled" && (
                            <li>
                              <span>{this.getFacilityName(index)}</span>
                            </li>
                          )
                      )}
                    </ul>
                  </td>
                  <td className={sever?.enabled === 0 ? 'disabled-cell' : ''}>
                    <ul className="syslog-list">
                      {_?.map(
                        sever,
                        (item, index) =>
                          index !== "enabled" && (
                            <li>
                              <span>
                                {item?.enabled === 0 ? "Disabled" : "Enabled"}
                              </span>
                            </li>
                          )
                      )}
                    </ul>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* second slider */}
        {this.state.editSeverity && (
          <div className="sidebar-slide  zindex10 w-350 second-open r-757">
            <div className="s-header">
              <h1 className="ss-title">Emergency Facilities</h1>
              <button>
                <img src="/images/svg-icons/s-cancel.svg" alt="" />
              </button>
            </div>
            <div className="s-body">
              <div className="form-group">
                <label>
                  <strong>Facilities</strong>
                  <div
                    className="tooltip-c"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={`To fetch the ${this.state.selectedSeverity?.key} severity logs`}
                  >
                    <img src="/images/svg-icons/info.svg" alt="" />
                  </div>
                </label>
              </div>
              <div className="d-flex align-items-center">
                <strong>Enable</strong>
                <label className="switch ml-3">
                  <input
                    checked={this.state.selectedSeverity?.value?.enabled === 1}
                    type="checkbox"
                    name="advancedOptions"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="facilities-list">
                <div className="d-flex align-items-center justify-content-between border-bottom">
                  <div className="w-33">&nbsp;</div>
                  <div>
                    <strong>Enabled</strong>
                  </div>
                </div>
                {_?.map(
                  this.state.selectedSeverity?.value,
                  (item, index) =>
                    index !== "enabled" && (
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="w-33">{this.getFacilityName(index)}</div>
                        <div>
                          <label className="switch">
                            <input
                              type="checkbox"
                              id={index}
                              checked={item?.enabled === 1}
                              name="advancedOptions"
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
            <div className="s-footer">
              <button className="btn btn-light">Cancel</button>
              {/* <button
                className="btn btn-primary"
                onClick={() => this.updateChange()}
              >
                Update
              </button> */}
            </div>
          </div>
        )}
        {!this.props.view &&
          <div className="s-footer">
            {/* 1665 reapply page issue */}
            <button
              className="btn btn-light"
              onClick={
                this.props.apply && !this.props.edit && !this.props.reapply
                  ? () => this.props.closeAddTemplate()
                  : () => this.props.closeApplyTemplate()
              }
            >
              {/* 1665 reapply page issue */}
              {this.props.apply && !this.props.reapply
                ? "Back"
                : "Cancel"}
            </button>
            <button
              className="btn btn-primary"
              disabled={this.checkError()}
              onClick={
                this.props.apply
                  ? (event) => {
                    this.debounceApply(this.applyTemplate, event)
                  }
                  : ""
              }
            >
              {this.props.apply
                ? "Apply"
                : this.state.selectedTemplateId !== ""
                  ? "Update"
                  : "Save"}
            </button>
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
  }
};
export default connect(mapStateToProps)(SystemTemplateView);
