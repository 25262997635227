import axios from "axios";
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

const envURL = process.env.REACT_APP_LOGIN_URL + 'api-user/';

const callingApis = async (requestParams) => {
  const token = localStorage.getItem('token');
  if (!requestParams.url.includes('/login')) {
    requestParams['headers'] = {
      Authorization: `Bearer ${token}`
    };
  }
  const { onUploadProgress, ...axiosParams } = requestParams;
  const datax = await axios({
    ...axiosParams,
    onUploadProgress
  });
  const { data } = datax;
  let res = {
    ...data,
    response: {
      status: datax.status
    }
  };
  if (
    requestParams.url.includes('device/downloadOperationLogfile') ||
    requestParams.url.includes('device/downloadDeviceLogfile') ||
    requestParams.url.includes('device/ipsecLogDownload') ||
    requestParams.url.includes('device/deviceLogDownload') ||
    requestParams.url.includes('device/downloadTemplate') ||
    requestParams.url.includes('deviceTemplateDownload')
  ) {
    res = {
      ...datax,
      response: {
        status: datax.status
      }
    };
  };
  return res;
};

const apiCall = async (requestParams, cb) => {
  var token = localStorage.getItem('token');
  if (
    requestParams.url.includes('/login') ||
    (token !== null && token !== '')
  ) {
    var requestParamsCopy = requestParams;
    await callingApis(requestParams)
      .then((response) => {
        cb(response);
      },
        async (error) => {
          if (requestParams.url.includes('/startTroubleshoot') &&
            error.response?.status && parseInt(error.response?.status) === 400) {
            cb(error)
          }
          else if
            (error.response &&
            error.response?.status &&
            parseInt(error.response?.status) !== 401) {
            cb(error);
          }
          else {
            if (!requestParams.url.includes('/login')) {
              const user = jwt_decode(token);
              const refresh_token = localStorage.getItem('refresh');
              const isExpired = dayjs.unix(user.exp).diff(dayjs(), 'day') < 1;
              var changed = false;
              if (refresh_token && isExpired) {
                await axios.post(`${envURL}token/refresh/`, {
                  refresh: refresh_token
                })
                  .then((response) => {
                    localStorage.setItem('token', response?.data?.access);
                    changed = true;
                    if (changed) {
                      callingApis(requestParamsCopy)
                        .then((res) => {
                          cb(res);
                        }, (err) => {
                          cb(err);
                        });
                    }
                  },
                    (error) => {
                      cb(error);
                    })
              }
              else {
                cb(error);
              }
            }
            else {
              cb(error);
            }
          }
        });
  } else {
    const err = {
      response: {
        status: 401
      }
    }
    cb(err);
  }
};

export default apiCall;