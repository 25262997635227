import axios from "axios";

const PfApiCall = async (requestParams, cb) => {
    try {
      const { data } = await axios(requestParams);
      cb(data);
    } catch (error) {
        cb(error);
    }
  };
  
  
  export default PfApiCall;