import React, { Component } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
export default class Mapnew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon1: L.icon({
        iconUrl: "marker-icon.png",
      }),
      position: [23.2599, 77.4126],
      center: [23.2599, 77.4126],
    };
  }
  static getDerivedStateFromProps = (props, state) => {
    if (props.center.length > 0) {
      state.center = props.center;
    }
    return state;
  };

  render() {
    return (
      <MapContainer
        id="mymap"
        className="map"
        center={this.state.center}
        zoom={this.props.zoom === 0 ? 16 : this.props.zoom}
        scrollWheelZoom={
          this.props.scrollWheelZoom ? this.props.scrollWheelZoom : true
        }
      >
        {/* EMGT-110-GPS Map not reporting accurate location of device */}
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Default">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite View">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              subdomains={["mt1", "mt2", "mt3"]}
            />
          </LayersControl.BaseLayer>
        </LayersControl>

        <Marker position={this.state.center} icon={this.state.icon1}></Marker>
      </MapContainer>
    );
  }
}
