/* eslint-disable array-callback-return */
import React, { Component, Fragment } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { ErrorPopup } from "../../../layOuts/errorPopup";
import * as Action from "../../../redux/actionTypes";
import * as Common from "../../../utils/common";
import Select, { components } from 'react-select';
import ErrorHandlePopup from "../../ErrorHandlePopup";
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
const ButtonComponent = (props, onClickHandler, id) => {
    return (
        <Fragment>
            <components.MenuList {...props}>
                <div>{props.children}</div>
                <button data-toggle="modal" data-target={id} onClick={onClickHandler} name="addIP" className="btn btn-primary btn-block mt-3">+ Add IP</button>
            </components.MenuList>
        </Fragment>
    );
};
class FirewallTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showError: false,
            selectedProtocol: '',
            show: false,
            toggledClearRows: false,
            customIP: false,
            selectedTemplateId: this.props?.id ? this.props?.id : "",
            template_type: this.props?.templateData?.template_type || "",
            template_name: this.props?.templateData?.template_name || "",
            template_desc: this.props?.templateData?.template_desc || "",
            device_model: this.props?.templateData?.device_model || "",
            collection_version: this.props?.templateData?.collection_version || "",
            fw_version: this.props?.templateData?.fw_version || "",
            chimay: this.props.chimay,
            cbr: this.props.cbr,
            mifi: this.props.mifi,
            openAddZone: false,
            AddPortForward: false,
            addTrafficRule: false,
            addNATRule: false,
            selectedZone: [],
            selectedPort: [],
            selectedTraffic: [],
            selectedNAT: [],
            update: false,
            availableProtocol: [
                "any",
                "tcp",
                "udp",
                "icmp"
            ],
            template_schema_json: {
                "zoneInfo": [{
                    "cfg": "wan",
                    "masq": "1",
                    "name": "wan",
                    "input": "REJECT",
                    "output": "ACCEPT",
                    "forward": "REJECT",
                    "network": "",
                    "forwardTo": ["none"],
                    "isdeleted": "0",
                    "forwardFrom": ["none"],
                    "mssClamping": "1"
                },
                {
                    "cfg": "lan",
                    "masq": "0",
                    "name": "lan",
                    "input": "ACCEPT",
                    "output": "ACCEPT",
                    "forward": "ACCEPT",
                    "network": "",
                    "forwardTo": ["wan"],
                    "isdeleted": "0",
                    "forwardFrom": ["none"],
                    "mssClamping": "0"
                },
                {
                    "cfg": "guest",
                    "masq": "0",
                    "name": "guest",
                    "input": "REJECT",
                    "output": "ACCEPT",
                    "forward": "REJECT",
                    "network": "guest",
                    "forwardTo": ["wan"],
                    "isdeleted": "0",
                    "forwardFrom": ["none"],
                    "mssClamping": "0"
                }, {
                    "cfg": "datatunnel",
                    "masq": "0",
                    "name": "datatunnel",
                    "input": "ACCEPT",
                    "output": "ACCEPT",
                    "forward": "REJECT",
                    "network": "",
                    "forwardTo": ["lan"],
                    "isdeleted": "0",
                    "forwardFrom": ["lan"],
                    "mssClamping": "0"
                }
                ],
                "portForward": [],
                "trafficRules": [],
                "natRules": []
            },
            temp_template_schema_json: undefined,
            selectedModelList: "",
            zoneData: {
                "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "name": "",
                "input": "ACCEPT",
                "output": "ACCEPT",
                "forward": "ACCEPT",
                "masq": "0",
                "mssClamping": "0",
                "forwardTo": ["none"],
                "forwardFrom": ["none"],
                "network": "",
                "isdeleted": "0"
            },
            portForwardData: {
                "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "name": "",
                "proto": "any",
                "sourceZone": "",
                "sourcePort": "",
                "destZone": "",
                "destPort": "any",
                "sourceIP": "any",
                "destIP": "",
                "enabled": "1",
                "isdeleted": "0"
            },
            trafficRuleData: {
                "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "name": "",
                "proto": "any",
                "sourceZone": "",
                "sourcePort": "any",
                "destZone": "",
                "destPort": "any",
                "action": "ACCEPT",
                "sourceIP": "any",
                "destIP": "any",
                "enabled": "1",
                "isdeleted": "0"
            },
            NATRuleData: {
                "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "name": "",
                "proto": "any",
                "outboundZone": "any",
                "sourceIP": "any",
                "destIP": "any",
                "action": "",
                "snatIP": "",
                "enabled": "1",
                "isdeleted": "0"
            },
            availableFromZone: [],
            availableToZone: [],
            selectedNetworks: ['all'],
            availableNetworks: [],
            availableIP: [],
            availableTrafficIP: [],
            validIP: false,
            customIPAddress: '',
            options: [],
            isAxiosError: false
        };
    }
    componentDidMount() {
        if (this.props.template_schema_json !== undefined) {
            let template_schema_json = JSON.parse(
                JSON.stringify(this.props.template_schema_json));
            template_schema_json.zoneInfo?.map((zone) => {
                let fromNetworks = zone.forwardFrom?.split(' ');
                zone.forwardFrom = fromNetworks;
                let toNetworks = zone.forwardTo?.split(' ');
                zone.forwardTo = toNetworks;
                return zone;
            })
            if (this.props.networkViewData)
                template_schema_json?.zoneInfo?.map((zone) => {
                    let availableNetworks = this.props?.networkViewData?.filter(element => (element.name !== "Control Tunnel"));
                    let newArr = []
                    let newValue = ""
                    zone.network !== "" && zone.network?.split(' ')?.map((val) => {
                        newArr = availableNetworks.filter(e => e.networkName === val)
                        if (newArr.length !== 0)
                            newValue = newValue.length === 0 ? newValue + newArr?.[0]?.networkName : newValue + " " + newArr?.[0]?.networkName
                        else
                            newValue = newValue
                    })
                    zone.network = newValue
                    return zone
                })
            this.setState({
                template_schema_json
            });
            if (this.props.edit && this.props.networkViewData) {
                let { availableNetworks } = this.state;
                let availableIP = ['any'];
                let options = [];
                // LONG-2298 : Restriction of blank IP address removed for device group from the networks of firewall template
                // if (!this.props.group_id) {
                //     availableNetworks = this.props.networkViewData.filter(element => (element.ip_address !== ""
                //         && element.name !== "Control Tunnel"));
                // }
                // else {
                availableNetworks = this.props?.networkViewData?.filter(element => (element.name !== "Control Tunnel"));
                // }
                availableNetworks.map((networks) => {
                    if (networks.ip_address)
                        availableIP.push(networks.ip_address);
                })
                availableIP.length > 0 && availableIP.map((item) => {
                    if (!options.find(val => val.value === item)) {
                        options.push({ label: item, value: item });
                    }
                })
                this.setState({
                    availableNetworks,
                    availableIP,
                    availableTrafficIP: availableIP,
                    options
                })
            }
        }
        this.setState({
            temp_template_schema_json: this.state.template_schema_json,
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        {/*Modified template_schema_json for selected model*/ }
        if (
            this.state.temp_template_schema_json !== undefined &&
            nextProps?.selectedModelList !== undefined &&
            (nextProps?.id === '' || nextProps?.id == null)
        ) {
            const { temp_template_schema_json } = this.state;
            let selectedModelList = Common.getModels(nextProps.selectedModelList);
            if (!isEqual(this.state?.selectedModelList, selectedModelList)) {
                let template_schema_json = JSON.parse(JSON.stringify(temp_template_schema_json));
                if (['Stormbreaker', 'Firestorm', 'Bradshaw', 'Sander', 'Duvel_Model', 'Moretti_Model', 'Orval']?.includes(selectedModelList)) {
                    template_schema_json.zoneInfo = template_schema_json.zoneInfo.filter(item => item.name !== "guest" && item.name !== 'datatunnel');
                }
                this.setState({
                    template_schema_json,
                    selectedModelList
                });
            }
        }

        if (Object.keys(nextProps.templateData).length !== 0) {
            this.setState({
                selectedTemplateId: nextProps.id ? nextProps.id : '',
                template_type: nextProps?.templateData?.template_type || "",
                template_name: nextProps?.templateData?.template_name || "",
                template_desc: nextProps?.templateData?.template_desc || "",
                device_model: nextProps?.templateData?.device_model || "",
                collection_version: nextProps?.templateData?.collection_version || "",
                fw_version: nextProps?.templateData?.fw_version || ""
            });
        }
        if (nextProps.edit) {
            if (!isEqual(this.props.template_schema_json, nextProps.template_schema_json)) {
                let template_schema_json = JSON.parse(
                    JSON.stringify(nextProps.template_schema_json));
                //LONG-2538  Default template for WAN/Network/Firewall should be as per device model
                // LONG-2831 : Need Guest network for CBR device
                // if (this.props.selectedModel === "vCPE") {
                //     template_schema_json.zoneInfo = template_schema_json.zoneInfo.filter(item => item.name !== "guest")
                // }
                template_schema_json.zoneInfo.map((zone) => {
                    let fromNetworks = zone.forwardFrom?.split(' ');
                    zone.forwardFrom = fromNetworks;
                    let toNetworks = zone.forwardTo?.split(' ');
                    zone.forwardTo = toNetworks;
                    return zone;
                })
                if (nextProps.networkViewData)
                    template_schema_json?.zoneInfo?.map((zone) => {
                        let availableNetworks = nextProps.networkViewData?.filter(element => (element.name !== "Control Tunnel"));
                        let newArr = []
                        let newValue = ""
                        zone.network !== "" && zone.network.split(' ').map((val) => {
                            newArr = availableNetworks.filter(e => e.networkName === val)
                            if (newArr.length !== 0)
                                newValue = newValue.length === 0 ? newValue + newArr?.[0]?.networkName : newValue + " " + newArr?.[0]?.networkName
                            else
                                newValue = newValue
                        })
                        zone.network = newValue
                        return zone
                    })
                this.setState({ template_schema_json });
            }
        }
        if (!isEqual(this.props.networkViewData, nextProps.networkViewData)) {
            let { availableNetworks } = this.state;
            let availableIP = ['any'];
            let options = [];
            // LONG-2298 : Restriction of blank IP address removed for device group from the networks of firewall template
            // if (!this.props.group_id) {
            //     availableNetworks = this.props.networkViewData.filter(element => (element.ip_address !== ""
            //         && element.name !== "Control Tunnel"));
            // }
            // else {
            availableNetworks = nextProps?.networkViewData?.filter(element => (element.name !== "Control Tunnel"));
            // }
            availableNetworks.map((networks) => {
                if (networks.ip_address)
                    availableIP.push(networks.ip_address);
            })
            availableIP.length > 0 && availableIP.map((item) => {
                if (!options.find(val => val.value === item)) {
                    options.push({ label: item, value: item });
                }
            })
            this.setState({
                availableNetworks,
                availableIP,
                availableTrafficIP: availableIP,
                options
            })
        }
        if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
            !isEqual(this.props?.cbr, nextProps?.cbr) ||
            !isEqual(this.props?.mifi, nextProps?.mifi)) {
            this.setState({
                chimay: nextProps.chimay,
                cbr: nextProps.cbr,
                mifi: nextProps.mifi
            })
        }
    }
    saveData = () => {
        let data = {};
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_order = 1;
        if (this.state.selectedTemplateId === "") {
            data.template_version = {
                chimay: this.state.chimay,
                cbr: this.state.cbr,
                mifi: this.state.mifi
            }
        }
        data.template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
        data.template_schema_json.zoneInfo.map((zone) => {
            zone.forwardFrom = zone.forwardFrom.join(' ');
            zone.forwardTo = zone.forwardTo.join(' ');
        })
        this.setState({
            showLoader: true,
        });
        if (this.state.selectedTemplateId !== "") {
            ApiCall(
                urlMapping.updateTemplate(this.state.selectedTemplateId, data),
                (response) => {
                    this.setState({
                        showLoader: false,
                    });
                    if (response?.Success) {
                        this.closeAddTemplate();
                        this.props.refreshList();
                        this.props.openApplyTemplate(this.state.selectedTemplateId);
                    } else {
                        if (parseInt(response?.response?.status) === 401) {
                            this.setState({ showRefreshModal: true });
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                        else {
                            this.setState(
                                {
                                    title: "Update Template",
                                    message: response?.message,
                                    show: true,
                                    error: true,
                                },
                                () => $("#errorMessage").modal("show")
                            );
                        }
                    }
                }
            );
        } else {
            ApiCall(urlMapping.createTemplate(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.props.refreshList();
                    this.props.openApplyTemplate(response?.data.id);
                    this.closeAddTemplate();
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState(
                            {
                                title: "Create Template",
                                message: response?.message,
                                show: true,
                                error: true,
                            },
                            () => $("#errorMessage").modal("show")
                        );
                    }
                }
            });
        }
    };
    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
                this.props.refreshList();
                this.closeAddTemplate();
            }
        );
    };
    closeAddTemplate = () => {
        this.setState({
            options: []
        }, () => this.props.closeAddTemplate())
    }
    checkError = () => {
        let result = false;
        if (this.props.applying)
            result = true;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.props.edit) {
            if (
                this.state.template_name === "" ||
                this.state.template_type === "" ||
                this.state.template_schema_json.zoneInfo.length === 0
            )
                result = true;
            this.state.template_schema_json.zoneInfo.map((zone) => {
                // Long-1646 Deleted interface mapped zone is not displaying in default LAN dropdown
                if (zone.network === '' && zone.isdeleted !== '1' && zone.name !== 'datatunnel')
                    result = true;
            })
            this.state.template_schema_json.portForward.map((port) => {
                if (port.destIP === '')
                    result = true;
            })
            this.state.template_schema_json.trafficRules.map((rule) => {
                if (rule.sourceIP === '')
                    result = true;
            })
            //LONG-2155-Apply button is disabled when creating nat rule with MASQ option enabled
            this.state.template_schema_json.natRules.map((rule) => {
                if (rule.snatIP === '' && rule.action !== 'masq')
                    result = true;
            })
        } else if (
            this.state.template_name === "" ||
            this.state.template_type === "" ||
            this.state.template_schema_json.zoneInfo.length === 0
        )
            result = true;
        return result;
    };
    checkZoneError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt zone");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (
            this.state.zoneData.name === "" ||
            this.state.zoneData.forwardFrom.length === 0 ||
            this.state.zoneData.forwardTo.length === 0
        )
            result = true;
        return result;
    };
    checkPortError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt port");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.portForwardData.proto === 'any' || this.state.portForwardData.proto === 'icmp') {
            if (
                this.state.portForwardData.name === "" ||
                this.state.portForwardData.proto === ""
                || this.state.portForwardData.sourceZone === "" ||
                this.state.portForwardData.destZone === ""
            )
                result = true;
        } else if (
            this.state.portForwardData.name === "" ||
            this.state.portForwardData.proto === "" ||
            this.state.portForwardData.sourceZone === "" ||
            this.state.portForwardData.destZone === "" ||
            this.state.portForwardData.sourcePort === ""
        )
            result = true;
        return result;
    };
    checkTrafficError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt traffic");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.trafficRuleData.proto === 'any' || this.state.trafficRuleData.proto === 'icmp') {
            if (
                this.state.trafficRuleData.name === "" ||
                this.state.trafficRuleData.proto === ""
                ||
                this.state.trafficRuleData.sourceZone === "" ||
                this.state.trafficRuleData.destZone === ""
            )
                result = true;
        } else if (
            this.state.trafficRuleData.name === "" ||
            this.state.trafficRuleData.proto === ""
            ||
            this.state.trafficRuleData.sourceZone === "" ||
            this.state.trafficRuleData.destZone === ""
        )
            result = true;
        return result;
    };
    checkNATError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt nat");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (
            this.state.NATRuleData.name === ""
        )
            result = true;
        return result;
    };
    setProtocol = (e, selectedTab) => {
        let data;
        switch (selectedTab) {
            case 'portForward':
                data = this.state.portForwardData;
                break;
            case 'trafficRules':
                data = this.state.trafficRuleData;
                break;
            case 'natRules':
                data = this.state.NATRuleData;
                break;
            default:
                return "";
        }
        let { availableProtocol } = this.state;
        if (e.target.checked) {
            if (e.target.id === 'any') {
                let protocols = data.proto.split(' ');
                availableProtocol = availableProtocol.filter(item => item !== e.target.id);
                protocols.map((item) => {
                    availableProtocol.push(item);
                })
                data.proto = 'any';
            } else {
                data.proto = data.proto.split(' ').filter(item => item !== 'any').join(' ');
                data.proto = data.proto === '' ?
                    e.target.id : data.proto + ' ' + e.target.id;
                availableProtocol = availableProtocol.filter(item => item !== e.target.id);
                if (!availableProtocol.includes('any')) {
                    availableProtocol.push('any');
                }
            }
        }
        else {
            if (!availableProtocol.includes(e.target.id))
                availableProtocol.push(e.target.id);
            const protocols = data.proto.split(' ');
            data.proto = protocols.filter(item => item !== e.target.id).join(' ');
        }

        if (selectedTab === 'portForward') {
            if (data.proto === 'any' || data.proto === 'icmp') {
                data.sourcePort = '';
                data.destPort = 'Any';
            }
        }
        // LONG-1898:'Any' is written in source port field when switching to 'Any' protocol from 'TCP' protocol
        // Code not needed as in case of 'Any', Source and Destination Port fields are disabled now

        // if(selectedTab === 'trafficRules'){
        //     if(data.proto === 'any' || data.proto === 'icmp'){
        //         data.sourcePort = 'Any';
        //         data.destPort = 'Any';
        //     }
        // }
        switch (selectedTab) {
            case 'portForward':
                this.setState({ portForwardData: data });
                break;
            case 'trafficRules':
                this.setState({ trafficRuleData: data });
                break;
            case 'natRules':
                this.setState({ NATRuleData: data });
                break;
            default:
                return "";
        }
        this.setState({
            availableProtocol,
        }
        )
    }
    checkDuplicateName = (e, selectedTab, cfg) => {
        const { template_schema_json } = this.state;
        let found = false;
        template_schema_json[selectedTab].map((item) => {
            if (item.name === e.target.value && cfg !== item.cfg && item.isdeleted !== '1')
                found = true;
        });
        if (found) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.innerHTML = 'Name already exists';
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
    }
    setZoneData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        let { zoneData } = this.state;
        const name = e.target.name
        const value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
        zoneData[name] = value;
        this.setState({
            zoneData
        })
    }
    setPortData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        let { portForwardData } = this.state;
        const name = e.target.name
        const value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
        portForwardData[name] = value;
        this.setState({
            portForwardData
        })
    }
    openAddZone = (data) => {
        let zones = [];
        this.state.template_schema_json.zoneInfo.map((item) => {
            zones.push(item);
        })
        if (data) {
            let zoneData = JSON.parse(JSON.stringify(data));
            zones = zones.filter(item => item.name !== zoneData.name);
            let found = false;
            zones.map((zone) => {
                if (zone.name === 'none')
                    found = true;
            })
            if (!found) {
                zones.push({ name: 'none', forwardFrom: 'none', forwardTo: 'none' });
            }
            let fromZones = zones;
            let toZones = zones;
            zoneData.forwardFrom.map((zone) => {
                fromZones = zones.filter(item => item.name !== zone);
            });
            zoneData.forwardTo.map((zone) => {
                toZones = zones.filter(item => item.name !== zone);
            });
            if (zones)
                this.setState({
                    zoneData,
                    availableFromZone: fromZones,
                    availableToZone: toZones,
                    openAddZone: true,
                    update: true,
                })
        }
        else {
            this.setState({
                openAddZone: true,
                update: false,
                availableFromZone: zones,
                availableToZone: zones,
                zoneData: {
                    "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                    "name": "",
                    "input": "ACCEPT",
                    "output": "ACCEPT",
                    "forward": "ACCEPT",
                    "masq": "0",
                    "mssClamping": "0",
                    "forwardTo": ["none"],
                    "forwardFrom": ["none"],
                    "network": "",
                    "isdeleted": "0"
                }
            })
        }
    }
    closeAddZone = () => {
        this.setState({
            selectedZone: [],
            openAddZone: false
        })
    }
    getAvailableProtocols = (protocols) => {
        let availableProtocol = [
            "any",
            "tcp",
            "udp",
            "icmp"
        ];
        protocols = protocols.split(' ');
        protocols.map((item) => {
            availableProtocol = availableProtocol.filter(value => value !== item);
        });
        return availableProtocol;
    }
    openAddPortForward = (data) => {
        const availableProtocol = data ? this.getAvailableProtocols(data.proto) :
            [
                "tcp",
                "udp",
                "icmp"
            ];
        if (data) {
            let portForwardData = JSON.parse(JSON.stringify(data));
            this.setState({
                portForwardData,
                AddPortForward: true,
                update: true,
                availableProtocol
            })
        }
        else {
            this.setState({
                AddPortForward: true,
                availableProtocol,
                update: false,
                portForwardData: {
                    "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                    "name": "",
                    "proto": "any",
                    "sourceZone": "",
                    "sourcePort": "",
                    "destZone": "",
                    "destPort": "",
                    "sourceIP": "any",
                    "destIP": "",
                    "enabled": "1",
                    "isdeleted": "0"
                }
            })
        }
    }
    closeAddPortForward = () => {
        this.setState({
            selectedPort: [],
            AddPortForward: false,
        })
    }
    openAddTrafficRule = (data) => {
        const availableProtocol = data ? this.getAvailableProtocols(data.proto) :
            [
                "tcp",
                "udp",
                "icmp"
            ];
        if (data) {
            let trafficRuleData = JSON.parse(JSON.stringify(data));
            this.setState({
                trafficRuleData,
                addTrafficRule: true,
                update: true,
                availableProtocol
            })
        }
        else {
            this.setState({
                addTrafficRule: true,
                availableProtocol,
                update: false,
                trafficRuleData: {
                    "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                    "name": "",
                    "proto": "any",
                    "sourceZone": "",
                    "sourcePort": "",
                    "destZone": "",
                    "destPort": "",
                    "action": "ACCEPT",
                    "sourceIP": "any",
                    "destIP": "any",
                    "enabled": "1",
                    "isdeleted": "0"
                }
            })
        }
    }
    closeAddTrafficRule = () => {
        this.setState({
            selectedTraffic: [],
            addTrafficRule: false
        })
    }
    openAddNATRule = (data) => {
        const availableProtocol = data ? this.getAvailableProtocols(data.proto) : [
            "tcp",
            "udp",
            "icmp"
        ];
        if (data) {
            let NATRuleData = JSON.parse(JSON.stringify(data));
            this.setState({
                NATRuleData,
                addNATRule: true,
                update: true,
                availableProtocol
            })
        }
        else {
            this.setState({
                addNATRule: true,
                update: false,
                availableProtocol,
                NATRuleData: {
                    "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                    "name": "",
                    "proto": "any",
                    "outboundZone": "any",
                    "sourceIP": "any",
                    "destIP": "any",
                    "action": "snat",
                    "snatIP": "",
                    "enabled": "1",
                    "isdeleted": "0"
                }
            })
        }
    }
    closeAddNATRule = () => {
        this.setState({
            addNATRule: false,
            selectedNAT: []
        })
    }
    handleChangeFrom = (e) => {
        let { zoneData } = this.state;
        let { forwardFrom } = this.state.zoneData;
        let { availableFromZone } = this.state;
        if (e.target.checked) {
            if (e.target.id === 'none') {
                forwardFrom.map((value) => {
                    const zone = this.state.template_schema_json.zoneInfo.filter(item => item.name === value)[0];
                    availableFromZone.push(zone);
                })
                forwardFrom = ['none'];
                availableFromZone = availableFromZone.filter(item => item.name !== e.target.id);
            } else {
                forwardFrom = forwardFrom.filter(item => item !== 'none');
                let found = false;
                availableFromZone.map((zone) => {
                    if (zone.name === 'none')
                        found = true;
                });
                if (!found)
                    availableFromZone.push({ name: 'none', forwardFrom: 'none', forwardTo: 'none' })
                if (!forwardFrom.includes(e.target.id)) {
                    forwardFrom.push(e.target.id);
                    availableFromZone = availableFromZone.filter(item => item.name !== e.target.id);
                }
            }
        } else {
            forwardFrom = forwardFrom.filter(item => item !== e.target.id);
            const zone = e.target.id === 'none' ?
                { name: e.target.id, forwardFrom: 'none', forwardTo: 'none' } :
                this.state.template_schema_json.zoneInfo.filter(item => item.name === e.target.id)[0];
            let zones = availableFromZone.map((element) => element.name)
            if (!zones.includes(e.target.id)) {
                availableFromZone.push(zone);
            }
        }
        zoneData.forwardFrom = forwardFrom;
        this.setState({
            zoneData,
            availableFromZone
        })
    }
    handleChangeTo = (e) => {
        let { zoneData } = this.state;
        let { forwardTo } = this.state.zoneData;
        let { availableToZone } = this.state;
        if (e.target.checked) {
            if (e.target.id === 'none') {
                forwardTo.map((value) => {
                    const zone = this.state.template_schema_json.zoneInfo.filter(item => item.name === value)[0];
                    availableToZone.push(zone);
                })
                forwardTo = ['none'];
                availableToZone = availableToZone.filter(item => item.name !== e.target.id);
            } else {
                forwardTo = forwardTo.filter(item => item !== 'none');
                let found = false;
                availableToZone.map((zone) => {
                    if (zone.name === 'none')
                        found = true;
                });
                if (!found)
                    availableToZone.push({ name: 'none', forwardFrom: 'none', forwardTo: 'none' })
                if (!forwardTo.includes(e.target.id)) {
                    forwardTo.push(e.target.id);
                    availableToZone = availableToZone.filter(item => item.name !== e.target.id);
                }
            }
        } else {
            forwardTo = forwardTo.filter(item => item !== e.target.id);
            const zone = e.target.id === 'none' ?
                { name: e.target.id, forwardFrom: 'none', forwardTo: 'none' } :
                this.state.template_schema_json.zoneInfo.filter(item => item.name === e.target.id)[0];
            let zones = availableToZone.map((element) => element.name)
            if (!zones.includes(e.target.id)) {
                availableToZone.push(zone);
            }
        }
        zoneData.forwardTo = forwardTo;
        this.setState({
            zoneData,
            availableToZone
        })
    }
    addZone = () => {
        let result = false;
        let zoneData = JSON.parse(JSON.stringify(this.state.zoneData));
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (result === false) {
            const data = this.state.template_schema_json;
            let found = false;
            zoneData.forwardFrom = zoneData.forwardFrom.join(' ');
            data.zoneInfo.map((item, index) => {
                if (item.cfg === this.state.zoneData.cfg) {
                    found = true;
                    data.zoneInfo[index] = this.state.zoneData;
                }
            });
            if (!found)
                data.zoneInfo.push(this.state.zoneData);
            this.setState(
                {
                    template_schema_json: data,
                    update: false,
                    zoneData: {
                        "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                        "name": "",
                        "input": "ACCEPT",
                        "output": "ACCEPT",
                        "forward": "ACCEPT",
                        "masq": "0",
                        "mssClamping": "0",
                        "forwardTo": ["none"],
                        "forwardFrom": ["none"],
                        "network": "",
                        "isdeleted": "0"
                    }
                }, () => {
                    this.closeAddZone()
                })
        }
    }
    addPortData = () => {
        let result = false;
        let portData = JSON.parse(JSON.stringify(this.state.portForwardData));
        if (this.state.portForwardData.destPort === "") {
            portData.destPort = 'any'
        }
        const elements = document.getElementsByClassName("error-txt port");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (result === false) {
            const data = this.state.template_schema_json;
            let found = false;
            data.portForward.map((item, index) => {
                if (item.cfg === portData.cfg) {
                    found = true;
                    data.portForward[index] = portData;
                }
            });
            if (!found)
                data.portForward.push(portData);
            this.setState(
                {
                    template_schema_json: data,
                    portForwardData: {
                        "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                        "name": "",
                        "proto": "",
                        "sourceZone": "",
                        "sourcePort": "",
                        "destZone": "",
                        "destPort": "",
                        "sourceIP": "any",
                        "destIP": "",
                        "enabled": "1",
                        "isdeleted": "0"
                    }
                }, () => {
                    this.closeAddPortForward()
                })
        }
    }
    getDestinationZone = (zoneName) => {
        let check = ['lan', 'wan'];
        // Long-1541 in edit and reapply deleted zone also showing in forward
        let zones = zoneName.forwardTo.filter(item => item !== 'none' && item.isdeleted !== "1").join(' ');
        this.state.template_schema_json.zoneInfo.filter(item => item.isdeleted !== "1").map((zone) => {
            // zones = zone.forwardTo.join(' ');
            zone.forwardFrom.map((item) => {
                //LONG-1807_LONG-Able to add forwarding rule between 2 zones twice from both the zones
                if (item === zoneName.name && !zones.split(' ').includes(zone.name)) {
                    zone.name = (check.includes((zone.name).toLowerCase())) ? (zone.name).toUpperCase() : zone.name
                    zones = zones === '' ? zone.name : ((check.includes(zones.toLowerCase())) ?
                        zones.toUpperCase() : zones) + ' ' + zone.name;
                }
            })
        })
        if (zones === '')
            zones = 'none';
        zones = (check.includes(zones.toLowerCase())) ? zones.toUpperCase() : zones
        return zones;
    }
    getProtocol = (protocol) => {
        let protocols = '';
        protocol.split(' ').map((item) => {
            item = item === 'any' ? 'Any' : item.toUpperCase();
            protocols = protocols === '' ? item : protocols + ' ' + item;
        })
        return protocols;
    }
    setTrafficData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        let { trafficRuleData } = this.state;
        const name = e.target.name
        const value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
        trafficRuleData[name] = value;
        this.setState({
            trafficRuleData
        })
    }
    addTrafficRule = () => {
        let result = false;
        let trafficData = JSON.parse(JSON.stringify(this.state.trafficRuleData));
        // LONG-1898 : Removed 'Any' written in source & dest port when  new traffic rule is added
        // if (this.state.trafficRuleData.sourcePort === "") {
        //     trafficData.sourcePort = "any"
        // }
        // if (this.state.trafficRuleData.destPort === "") {
        //     trafficData.destPort = "any"
        // }
        if (this.state.trafficRuleData.destIP === "") {
            trafficData.destIP = "any"
        }
        // if (this.state.trafficRuleData.destZone === "") {
        //     trafficData.destZone = "any"
        // }
        if (this.state.trafficRuleData.sourceIP === "") {
            trafficData.sourceIP = "any"
        }
        // if (this.state.trafficRuleData.sourceZone === "") {
        //     trafficData.sourceZone = "any"
        // }
        const elements = document.getElementsByClassName("error-txt traffic");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (result === false) {
            const data = this.state.template_schema_json;
            let found = false;
            data.trafficRules.map((item, index) => {
                if (item.cfg === trafficData.cfg) {
                    found = true;
                    data.trafficRules[index] = trafficData;
                }
            });
            if (!found)
                data.trafficRules.push(trafficData);
            this.setState(
                {
                    template_schema_json: data,
                    trafficRuleData: {
                        "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                        "name": "",
                        "proto": "",
                        "sourceZone": "",
                        "sourcePort": "",
                        "destZone": "",
                        "destPort": "",
                        "action": "ACCEPT",
                        "sourceIP": "any",
                        "destIP": "any",
                        "enabled": "1",
                        "isdeleted": "0"
                    }
                }, () => {
                    this.closeAddTrafficRule()
                })
        }
    }
    setNATData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: false
            })
        }
        let { NATRuleData } = this.state;
        const name = e.target.name
        const value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
        NATRuleData[name] = value;
        this.setState({
            NATRuleData
        })
        //LONG-2157 View page is incorrect for MASQ enabled NAT Page
        if (e.target.name === "action") {
            if (e.target.value === "masq") {
                NATRuleData["snatIP"] = "";
                this.setState({
                    NATRuleData
                })
            }
        }
    }
    addNATRule = () => {
        let result = false;
        let natData = JSON.parse(JSON.stringify(this.state.NATRuleData));
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (result === false) {
            const data = this.state.template_schema_json;
            let found = false;
            data.natRules.map((item, index) => {
                if (item.cfg === natData.cfg) {
                    found = true;
                    data.natRules[index] = natData;
                }
            });
            if (!found)
                data.natRules.push(natData);
            this.setState(
                {
                    template_schema_json: data,
                    NATRuleData: {
                        "cfg": Math.random().toString(36).substring(2, 11).toUpperCase(),
                        "name": "",
                        "proto": "",
                        "outboundZone": "any",
                        "sourceIP": "any",
                        "destIP": "any",
                        "action": "snat",
                        "snatIP": "",
                        "enabled": "1",
                        "isdeleted": "0"
                    },
                    selectedNAT: []
                }, () => {
                    this.closeAddNATRule()
                })
        }
    }
    selectedZone = (e, zone) => {
        let { selectedZone } = this.state;
        if (e.target.checked === true) {
            selectedZone.push(zone);
        }
        else {
            selectedZone = selectedZone.filter(item => item.cfg !== zone.cfg);
        }
        this.setState({
            selectedZone
        })
    }
    selectedPort = (e, port) => {
        let { selectedPort } = this.state;
        if (e.target.checked) {
            selectedPort.push(port);
        }
        else {
            selectedPort = selectedPort.filter(item => item.cfg !== port.cfg);
        }

        this.setState({
            selectedPort
        })
    }
    selectedTraffic = (e, traffic) => {
        let { selectedTraffic } = this.state;
        if (e.target.checked) {
            selectedTraffic.push(traffic);
        }
        else {
            selectedTraffic = selectedTraffic.filter(item => item.cfg !== traffic.cfg);
        }
        this.setState({
            selectedTraffic
        })
    }
    selectedNAT = (e, nat) => {
        let { selectedNAT } = this.state;
        if (e.target.checked) {
            selectedNAT.push(nat);
        }
        else {
            selectedNAT = selectedNAT.filter(item => item.cfg !== nat.cfg);
        }
        this.setState({
            selectedNAT
        })
    }
    deleteZone = () => {
        let data = this.state.template_schema_json;
        if (this.props.edit) {
            this.state.selectedZone.map((item) => {
                let found = false;
                this.props?.template_schema_json?.zoneInfo?.map((zone) => {
                    if (item.cfg === zone.cfg) {
                        // Long-1646 Deleted interface mapped zone is not displaying in default LAN dropdown
                        const deletedZone = this.state.template_schema_json.zoneInfo.filter(value => value.cfg === item.cfg)[0];
                        deletedZone.isdeleted = "1";
                        deletedZone.network = '';
                        found = true;
                    }
                    return zone;
                })
                if (found === false) {
                    data.zoneInfo = this.state.template_schema_json.zoneInfo.filter(finalValue => finalValue.cfg !== item.cfg);
                }
            })
        } else {
            this.state.selectedZone.map((item) => {
                data.zoneInfo = this.state.template_schema_json.zoneInfo.filter(finalValue => finalValue.cfg !== item.cfg);
            })
        }
        this.setState({
            template_schema_json: data,
            selectedZone: []
        })
    }
    deletePort = () => {
        let data = this.state.template_schema_json;
        if (this.props.edit) {
            this.state.selectedPort.map((item) => {
                const element = document.getElementById(`ipAddress_${item.name}`);
                if (element) {
                    element.style.display = 'none';
                    element.innerHTML = '';
                    this.setState({
                        showError: false
                    })
                }
                let found = false;
                this.props?.template_schema_json?.portForward?.map((port) => {
                    if (item.cfg === port.cfg) {
                        this.state.template_schema_json.portForward.filter(value => value.cfg === item.cfg)[0].isdeleted = "1";
                        found = true;
                    }
                    return port;
                })
                if (found === false) {
                    data.portForward = this.state.template_schema_json.portForward.filter(finalValue => finalValue.cfg !== item.cfg);
                }
            })
        } else {
            this.state.selectedPort.map((item) => {
                const element = document.getElementById(`ipAddress_${item.name}`);
                if (element) {
                    element.style.display = 'none';
                    element.innerHTML = '';
                    this.setState({
                        showError: false
                    })
                }
                data.portForward = this.state.template_schema_json.portForward.filter(finalValue => finalValue.cfg !== item.cfg);
            })
        }
        this.setState({
            template_schema_json: data,
            selectedPort: [],
            toggledClearRows: !this.state.toggledClearRows
        })
    }
    deleteTraffic = () => {
        let data = this.state.template_schema_json;
        if (this.props.edit) {
            this.state.selectedTraffic.map((item) => {
                let found = false;
                this.props?.template_schema_json?.trafficRules?.map((rule) => {
                    if (item.cfg === rule.cfg) {
                        this.state.template_schema_json.trafficRules.filter(value => value.cfg === item.cfg)[0].isdeleted = "1";
                        found = true;
                    }
                    return rule;
                })
                if (found === false) {
                    data.trafficRules = this.state.template_schema_json.trafficRules.filter(finalValue => finalValue.cfg !== item.cfg);
                }
            })
        } else {
            this.state.selectedTraffic.map((item) => {
                data.trafficRules = this.state.template_schema_json.trafficRules.filter(finalValue => finalValue.cfg !== item.cfg);
            })
        }
        this.setState({
            template_schema_json: data,
            selectedTraffic: []
        })
    }
    deleteNAT = () => {
        let data = this.state.template_schema_json;
        if (this.props.edit) {
            this.state.selectedNAT.map((item) => {
                let found = false;
                this.props?.template_schema_json?.natRules?.map((rule) => {
                    if (item.cfg === rule.cfg) {
                        this.state.template_schema_json.natRules.filter(value => value.cfg === item.cfg)[0].isdeleted = "1";
                        found = true;
                    }
                    return rule;
                })
                if (found === false) {
                    data.natRules = this.state.template_schema_json.natRules.filter(finalValue => finalValue.cfg !== item.cfg);
                }
            })
        } else {
            this.state.selectedNAT.map((item) => {
                data.natRules = this.state.template_schema_json.natRules.filter(finalValue => finalValue.cfg !== item.cfg);
            })
        }
        this.setState({
            template_schema_json: data,
            selectedNAT: []
        })
    }
    closeAll = () => {
        //LONG-3014-EM - Issue in checkbox enable/disable case.
        this.setState({
            selectedZone: [],
            selectedTraffic: [],
            selectedPort: [],
            selectedNAT: []
        })
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.closeAddTemplate()
    }
    //LONG-1703_not able to delete rules name for newly created rows
    validateName = (e, selectedTab, name) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            if (e.target.value === "") {
                element.innerHTML = "Invalid Value";
                element.style.display = "block"
                this.setState({
                    showError: true
                })
            }
            if (!Common.validateAlphaNumeric(e)) {
                element.innerHTML = "Invalid Value";
                element.style.display = "block"
                this.setState({
                    showError: true
                })
            } else {
                this.checkDuplicateName(e, selectedTab, name.cfg);
            }
        }
    }
    validateIP = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
        }
        if (!Common.validateIPaddress(e)) {
            this.setState({
                showError: true
            })
        }
    }
    validatePort = (e) => {
        let result = true;
        if (
            (this.state.portForwardData.proto === 'any' ||
                this.state.portForwardData.proto === 'icmp') && (this.state.trafficRuleData.proto !== "tcp") && (this.state.trafficRuleData.proto !== "udp")) {
            result = true;
        } else {
            const values = e.target.value.split('-');
            if (values.length > 1 && (parseInt(values[0]) < 0 || parseInt(values[0]) > 65535 ||
                parseInt(values[1]) < 0 || parseInt(values[1]) > 65535)
            ) {
                result = false;
            } else if (values.length === 1 && (parseInt(values[0]) < 0 || parseInt(values[0]) > 65535)) {
                result = false;
            }
            if (!result) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = "Port must be between 0 to 65535";
                    this.setState({
                        showError: true
                    })
                }
            }
        };
        //LONG-2154 UI validation need to be corrected for some cases
        if (e.target.name === 'sourcePort' || e.target.name === 'destPort') {
            if (e.target.value === "") {
                this.setState({
                    showError: false
                })
            }
            else {
                if (!Common.validatePort(e)) {
                    this.setState({
                        showError: true
                    })
                }
            }
        }
        else {
            if (!Common.validatePort(e)) {
                this.setState({
                    showError: true
                })
            }
        }
    }
    getProtocol = (protocol) => {
        let protocols = '';
        protocol.split(' ').map((item) => {
            item = item === 'any' ? 'Any' : item.toUpperCase();
            protocols = protocols === '' ? item : protocols + ' ' + item;
        })
        return protocols;
    }
    handleChange = (e, zone) => {
        const { template_schema_json } = this.state;
        let { availableNetworks } = this.state;
        if (e.target.checked) {
            template_schema_json.zoneInfo.map((item) => {
                if (item.cfg === zone.cfg) {
                    item.network = item.network === '' ? e.target.id : item.network + ' ' + e.target.id;
                }
                return item;
            });
        } else {
            template_schema_json.zoneInfo.map((item) => {
                if (item.cfg === zone.cfg) {
                    const networks = item.network.split(' ');
                    item.network = networks.filter(value => value !== e.target.id).join(' ');
                }
                return item;
            });
        }
        this.setState({
            template_schema_json,
            availableNetworks
        })
    }
    getNetworkName = (name) => {
        if (this.state.availableNetworks && this.state.availableNetworks.length > 0) {
            const network = this.state.availableNetworks.filter(item => item.networkName === name)[0];
            let networkName = ''
            if (network)
                networkName = network.name;
            return networkName;
        }
    }
    getAvailableNetwork = () => {
        let networks = this.state.availableNetworks;
        let used = [];
        this.state.template_schema_json.zoneInfo.map((zone) => {
            const values = zone.network?.split(' ');
            values.map((item) => {
                used.push(item);
            })
        })
        used.map((value) => {
            if (value) {
                networks = networks.filter(item => item.networkName !== value);
            }
        })
        return networks;
    }
    handlePortSourceIP = (e, port) => {
        let { template_schema_json } = this.state;
        template_schema_json.portForward.map((item) => {
            if (item.cfg === port.cfg) {
                item.sourceIP = e && e.value ? e.value : '';
            }
            return item;
        })
        this.setState({
            template_schema_json
        })
    }
    //LONG-2154 UI validation need to be corrected for some cases
    handlePortDestIP = (e, port) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
        }
        // if (e.target.name === 'ipAddress1') {
        if (
            // Common.validateIPaddress(e) === false ||
            e.target.value === '0.0.0.0' || e.target.value === '255.255.255.255') {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'Invalid value';
                this.setState({
                    showError: true,
                })
            }
        }
        else if (Common.validateIPaddress(e) === false) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = 'Invalid value';
                this.setState({
                    showError: true,
                })
            }
            else {
                this.setState({
                    showError: false
                })
            }
        }
        // }
        let { template_schema_json } = this.state;
        template_schema_json.portForward.map((item) => {
            if (item.cfg === port.cfg) {
                item.destIP = e.target.value
            }
            return item;
        })
        this.setState({
            template_schema_json
        })
    }
    //LONG-2154 UI validation need to be corrected for some cases
    validateCustomIP = (e) => {
        if (e.target.name === 'ipAddress') {
            // if (Common.validatePrivateIP(e) === false) {
            //     const element = document.getElementById(e.target.name);
            //     if (element) {
            //         element.style.display = 'block';
            //         element.innerHTML = 'Invalid IP';
            //         this.setState({
            //             showError: true,
            //         })
            //     }
            // }
            // else 
            if (Common.validateIPaddressWithSubnetMask(e)) {
                this.setState({
                    showError: false,
                    validIP: true,
                    customIPAddress: e.target.value
                })
            } else {
                this.setState({
                    showError: true
                })
            }
        }
        else {
            this.setState({
                showError: false,
            })
        }
    }
    addIP = () => {
        let { availableIP, options } = this.state;
        if (this.state.validIP) {
            if (!availableIP.includes(this.state.customIPAddress)) {
                availableIP.push(this.state.customIPAddress);
                options.push({ label: this.state.customIPAddress, value: this.state.customIPAddress });
            }
            this.setState({
                customIP: false,
                customIPAddress: '',
                options,
                validIP: false
            }, () => {
                $("#errorMessage").modal("hide")
            })
        }
    }
    cancelIP = () => {
        let element = document.getElementById('ipAddress');
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
        }
        this.setState({
            customIP: false
        })
    }
    handleTrafficSourceIP = (e, rule) => {
        let { template_schema_json } = this.state;
        if (e.target.checked === true) {
            template_schema_json.trafficRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    if (!item.sourceIP.split(' ').includes(e.target.id)) {
                        item.sourceIP = e.target.id === 'any' ? '' : item.sourceIP;
                        item.sourceIP = item.sourceIP === 'any' ? '' : item.sourceIP;
                        item.sourceIP = item.sourceIP === '' ? e.target.id : item.sourceIP + " " + e.target.id;
                    }
                }
            })
        } else {
            template_schema_json.trafficRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    if (item.sourceIP.split(' ').includes(e.target.id)) {
                        item.sourceIP = item.sourceIP.split(' ').filter(element => element !== e.target.id).join(' ');
                    }
                }
            })
        }
        this.setState({ template_schema_json }
        )
    }
    handleTrafficDestIP = (e, rule) => {
        let { template_schema_json } = this.state;
        if (e.target.checked === true) {
            template_schema_json.trafficRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    if (!item.destIP.split(' ').includes(e.target.id)) {
                        item.destIP = e.target.id === 'any' ? '' : item.destIP;
                        item.destIP = item.destIP === 'any' ? '' : item.destIP;
                        item.destIP = item.destIP === '' ? e.target.id : item.destIP + " " + e.target.id;
                    }
                }
            })
        } else {
            template_schema_json.trafficRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    if (item.destIP.split(' ').includes(e.target.id)) {
                        item.destIP = item.destIP.split(' ').filter(element => element !== e.target.id).join(' ');
                    }
                }
            })
        }
        this.setState({ template_schema_json }
        )
    }
    handleNatSourceIP = (e, rule) => {
        let { template_schema_json } = this.state;
        template_schema_json.natRules.map((item) => {
            if (item.cfg === rule.cfg) {
                item.sourceIP = e && e.value ? e.value : '';
            }
            return item;
        })
        this.setState({
            template_schema_json
        })
    }
    handleNatDestIP = (e, rule) => {
        let { template_schema_json } = this.state;
        template_schema_json.natRules.map((item) => {
            if (item.cfg === rule.cfg) {
                item.destIP = e && e.value ? e.value : '';
            }
            return item;
        })
        this.setState({
            template_schema_json
        })
    }
    handleSnatIP = (e, rule) => {
        if (Common.validateIPaddress(e)) {
            let { template_schema_json } = this.state;
            template_schema_json.natRules.map((item) => {
                if (item.cfg === rule.cfg) {
                    item.snatIP = e.target.value
                }
                return item;
            })
            this.setState({
                showError: false,
                template_schema_json,
            })
        } else {
            this.setState({ showError: true })
        }
    }
    removeErrorIP = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true
            })
        }
    }
    checkApplyError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        this.state.template_schema_json.zoneInfo.map((zone) => {
            if (zone.network === '')
                result = true;
        })
        this.state.template_schema_json.portForward.map((port) => {
            if (port.destIP === '')
                result = true;
        })
        this.state.template_schema_json.trafficRules.map((rule) => {
            if (rule.sourceIP === '' || rule.destIP === '')
                result = true;
        })
        return result;
    };
    applyTemplate = () => {
        let { template_schema_json } = this.state;
        template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
        template_schema_json.zoneInfo.map((zone) => {
            zone.forwardFrom = zone.forwardFrom.join(' ');
            zone.forwardTo = zone.forwardTo.join(' ');
            return zone;
        })
        this.props.dispatch({
            type: Action.SET_APPLY_TEMPLATE,
        });
        this.props.applyTemplate(template_schema_json);
    }
    debounceApply = debounce((fn, event) => {
        fn(event)
    }, 500);
    changeIp = () => {
        this.setState({
            customIP: !this.state.customIP
        })
    }
    checkEnabled = () => {
        let result = true;
        if (this.state.selectedZone.length > 0) {
            const zones = this.state.selectedZone.filter(item => item.name === 'lan' ||
                item.name === 'wan' || item.name === 'datatunnel');
            if (zones.length > 0) {
                result = false;
            } else {
                if (this.props?.templateData.default_type === ''
                    || this.props?.templateData.default_type === null)
                    result = true;
                else
                    result = false;
            }
            return result;
        }
    }
    //LONG-3014: EM - Issue in checkbox enable/disable case.
    selectedTabChange = (name) => {
        switch (name) {
            case 'zone':
                this.setState({ selectedZone: [] })
                break;
            case 'port-forwards':
                this.setState({ selectedPort: [] });
                break;
            case 'traffic-rules':
                this.setState({ selectedTraffic: [] });
                break;
            case 'nat-rules':
                this.setState({ selectedNAT: [] });
                break;
            default:
                return "";
        }
    }
    render() {
        return (
            <div className="tab-box firewall">
                <div className={(this.state.openAddZone ||
                    this.state.AddPortForward || this.state.addNATRule || this.state.addTrafficRule) ?
                    "disabled-slide" : ""}>
                </div>
                <ul className="tab-nav nav nav-pills bg-gray" id="firewallTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="zone-tab" data-toggle="tab" href="#zone" role="tab" aria-controls="zone" aria-selected="true" onClick={() => this.selectedTabChange('zone')}>Zone</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="port-forwards-tab" data-toggle="tab" href="#port-forwards" role="tab" aria-controls="port-forwards" aria-selected="false" onClick={() => this.selectedTabChange('port-forwards')}>Port Forwards</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="traffic-rules-tab" data-toggle="tab" href="#traffic-rules" role="tab" aria-controls="traffic-rules" aria-selected="false" onClick={() => this.selectedTabChange('traffic-rules')}>Traffic Rules</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="nat-rules-tab" data-toggle="tab" href="#nat-rules" role="tab" aria-controls="nat-rules" aria-selected="false" onClick={() => this.selectedTabChange('nat-rules')}>NAT Rules</a>
                    </li>
                </ul>
                <div className="tab-content mt-2">
                    <div className="tab-pane active" id="zone" role="tabpanel" aria-labelledby="zone-tab">
                        <div className="page-sub-title">
                            <div><strong>Zone</strong>
                                <img src="/images/svg-icons/add.svg"
                                    className={(this.props?.templateData?.default_type !== ''
                                        && this.props?.templateData?.default_type !== null)
                                        ? "add-btn ml-2 disabled"
                                        : "add-btn ml-2"}
                                    alt=""
                                    onClick={(this.props?.templateData?.default_type !== ''
                                        && this.props?.templateData?.default_type !== null)
                                        ? undefined
                                        : () => this.openAddZone()} />
                            </div>
                            <div className="device-action">
                                <span className={this.state.selectedZone.length === 1 ?
                                    "icon-box" : "icon-box disabled"}><img src="/images/svg-icons/edit.svg" alt="" disabled={this.state.selectedZone.length !== 1}
                                        onClick={this.state.selectedZone.length === 1 ? () => this.openAddZone(this.state.selectedZone[0]) : undefined} /></span>
                                <span className={this.checkEnabled() ?
                                    "icon-box" : "icon-box disabled"}><img src="/images/svg-icons/delete.svg" alt="" disabled={this.state.selectedZone.length < 1}
                                        onClick={this.checkEnabled() ? this.deleteZone : undefined} /></span>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                    </th>
                                    <th>
                                        <div className="zone-forwarding">
                                            <span>Source</span>
                                            <span className="arrow-sorting">&rarr;</span>
                                            <span>Forwarding</span>
                                        </div>
                                    </th>
                                    {this.props.edit &&
                                        <th>Source Zone Networks<span className="red-txt">*</span></th>}
                                    <th>Input</th>
                                    <th>Output</th>
                                    <th>Forward</th>
                                    <th>Masquerading</th>
                                    <th>MSS Clamping</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.template_schema_json.zoneInfo.filter(item => item.isdeleted !== '1')?.map((zone) => {
                                    return <><tr>
                                        {/* LONG-1633_check box issue fixed */}
                                        {(!this.props.view) && <td>
                                            <input
                                                type="checkbox"
                                                checked={this.state.selectedZone.filter(item => (item.cfg === zone.cfg))?.length > 0}
                                                onClick={(e) => this.selectedZone(e, zone)} />
                                        </td>}
                                        <td>
                                            <div className="zone-forwarding">
                                                <span>{((zone?.name).toLowerCase() === "wan" || (zone.name).toLowerCase() === "lan") ? (zone?.name).toUpperCase() : zone?.name}</span>
                                                <span className="arrow-sorting">&rarr;</span>
                                                <span className="split-word">{
                                                    this.getDestinationZone(zone)
                                                }
                                                </span>
                                            </div>
                                        </td>
                                        {this.props.edit && <td>
                                            <div className="dropdown">
                                                <ul className="chip-list z-index-9 radius">
                                                    {zone.network?.split(' ')?.map((item) => {
                                                        if (item && this.getNetworkName(item))
                                                            return <li>{this.getNetworkName(item)}<img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                                onClick={(e) => this.handleChange(e, zone)} /></li>
                                                    })}
                                                </ul>
                                                <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                </div>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <ul>{
                                                        this.getAvailableNetwork(zone)?.map((item) => {
                                                            return <li >
                                                                <input
                                                                    type="checkbox"
                                                                    id={item.networkName}
                                                                    checked={this.state.selectedNetworks?.includes(item.name)}
                                                                    onClick={(e) => this.handleChange(e, zone)} />
                                                                {item.name}</li>
                                                        })
                                                    }
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>}
                                        <td>{Common.getAction(zone?.input)}</td>
                                        <td>{Common.getAction(zone?.output)}</td>
                                        <td>{Common.getAction(zone?.forward)}</td>
                                        <td>{zone?.masq === '1' ? 'Enabled' : 'Disabled'}</td>
                                        <td>{zone?.mssClamping === '1' ? 'Enabled' : 'Disabled'}</td>
                                    </tr>
                                    </>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="tab-pane" id="port-forwards" role="tabpanel" aria-labelledby="port-forwards-tab">
                        <div className="page-sub-title">
                            <div><strong>Port Forward</strong>
                                <img src="/images/svg-icons/add.svg"
                                    className={(this.props?.templateData.default_type !== ''
                                        && this.props?.templateData.default_type !== null)
                                        ? "add-btn ml-2 disabled"
                                        : "add-btn ml-2"}
                                    alt=""
                                    onClick={(this.props?.templateData?.default_type !== ''
                                        && this.props?.templateData?.default_type !== null)
                                        ? undefined
                                        : () => this.openAddPortForward()} />
                            </div>
                            <div className="device-action">
                                {/* edit icon not getting disabled when more than one rows were selected */}
                                <span className={this.state.selectedPort.length === 1 ?
                                    "icon-box" : "icon-box disabled"}><img src="/images/svg-icons/edit.svg" alt="" disabled={this.state.selectedPort.length !== 1}
                                        onClick={this.state.selectedPort.length === 1 ? () => this.openAddPortForward(this.state.selectedPort[0]) : undefined} /></span>
                                <span className={this.state.selectedPort.length < 1 &&
                                    (this.props.templateData.default_type !== '' || this.props.templateData.default_type !== null) ?
                                    "icon-box disabled" : "icon-box"}><img src="/images/svg-icons/delete.svg" alt="" disabled={this.state.selectedPort.length < 1 &&
                                        (this.props.templateData.default_type !== '' || this.props.templateData.default_type !== null)}
                                        onClick={this.state.selectedPort.length > 0 && (this.props.templateData.default_type === ''
                                            || this.props.templateData.default_type === null) ? this.deletePort : undefined} /></span>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        {/* <input type="checkbox" /> */}
                                    </th>
                                    <th className="w-80">Name</th>
                                    <th className="w-150">Match</th>
                                    {this.props.edit && <><th>Source IP<span className="red-txt">*</span></th>
                                        <th>DNAT IP<span className="red-txt">*</span></th></>}
                                    {/* <th>Action</th> */}
                                    <th className="w-150">Forward to</th>
                                    <th>Enabled</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.template_schema_json.portForward?.filter(item => item.isdeleted !== '1')?.map((port) => {
                                    return <>
                                        {port.isdeleted === "0" ?
                                            <tr>
                                                {/* Long 1806  */}
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.selectedPort.filter(item => (item.cfg === port.cfg))?.length > 0}
                                                        onClick={(e) => this.selectedPort(e, port)} />
                                                </td>
                                                <td>{port.name}</td>
                                                <td className="c-gray">
                                                    <ul>
                                                        <li>
                                                            <strong>Protocol:</strong><span> {this.getProtocol(port?.proto)}</span>
                                                        </li>
                                                        <li>
                                                            <strong>Source Zone:</strong><span> {Common.getZoneName(port?.sourceZone)}</span>
                                                        </li>
                                                        <li>
                                                            <strong>Port:</strong><span> {port?.sourcePort}</span>
                                                        </li>
                                                    </ul>
                                                </td>
                                                {this.props.edit && <>
                                                    <td className="c-gray">
                                                        <Select
                                                            isClearable
                                                            components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP') }}
                                                            isSearchable
                                                            className="custom-dropdown w-105"
                                                            classNamePrefix="react-select-container"
                                                            name="customip"
                                                            options={this.state.options}
                                                            value={{ label: port.sourceIP, value: port.sourceIP }}
                                                            onChange={(e) => this.handlePortSourceIP(e, port)}
                                                        />
                                                    </td>
                                                    <td className="lblue-bg">
                                                        {/* LONG-2154 UI validation need to be corrected for some cases */}
                                                        <input
                                                            type="text"
                                                            className="form-control w-105"
                                                            name={`ipAddress_${port?.name}`}
                                                            value={port?.destIP} onChange={(e) => this.handlePortDestIP(e, port)} />
                                                        <div className="error-txt" id={`ipAddress_${port?.name}`} >Invalid IP address</div></td></>}
                                                <td className="lblue-bg">
                                                    <ul>
                                                        <li>
                                                            <strong> Destination Zone:</strong>
                                                            <span> {Common.getZoneName(port?.destZone)}</span>
                                                        </li>
                                                        <li>
                                                            <strong>Port:</strong><span>{port?.destPort}</span>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td>{port?.enabled === '1' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            : " "}
                                    </>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="tab-pane" id="traffic-rules" role="tabpanel" aria-labelledby="traffic-rules-tab">
                        <div className="page-sub-title">
                            <div><strong>Traffic Rules</strong>
                                <img
                                    src="/images/svg-icons/add.svg"
                                    className={(this.props.templateData.default_type !== ''
                                        && this.props.templateData.default_type !== null) ? "add-btn ml-2 disabled" : "add-btn ml-2"}
                                    alt=""
                                    onClick={(this.props.templateData.default_type !== ''
                                        && this.props.templateData.default_type !== null) ? undefined : () => this.openAddTrafficRule()} />
                            </div>
                            <div className="device-action">
                                {/* edit icon not getting disabled when more than one rows were selected */}
                                <span className={this.state.selectedTraffic.length === 1 ?
                                    "icon-box" : "icon-box disabled"}>
                                    <img
                                        src="/images/svg-icons/edit.svg"
                                        alt=""
                                        disabled={this.state.selectedTraffic.length !== 1}
                                        onClick={this.state.selectedTraffic.length === 1 ? () => this.openAddTrafficRule(this.state.selectedTraffic[0]) : undefined} />
                                </span>
                                {/* Long 1806 -restricted symbol is showed for the delete option, eventhough restricted symbol is coming able to delete. */}
                                <span
                                    className={this.state.selectedTraffic.length < 1 && (this.props.templateData.default_type !== '' ||
                                        this.props.templateData.default_type !== null) ? "icon-box disabled" : "icon-box"}>
                                    <img
                                        src="/images/svg-icons/delete.svg"
                                        alt=""
                                        disabled={this.state.selectedTraffic.length < 1}
                                        onClick={this.state.selectedTraffic.length > 0 && (this.props.templateData.default_type === '' ||
                                            this.props.templateData.default_type === null) ? this.deleteTraffic : undefined} />
                                </span>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                    </th>
                                    <th className="w-80">Name</th>
                                    <th className="w-150">Match</th>
                                    {this.props.edit &&
                                        <>
                                            <th>Source IP<span className="red-txt">*</span></th>
                                            <th>Dest. IP<span className="red-txt">*</span></th>
                                        </>
                                    }
                                    <th>Action</th>
                                    <th>Enabled</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.template_schema_json.trafficRules.filter(item => item.isdeleted !== '1')?.map((rule) =>
                                    <tr>
                                        {/* Long - 1806 3rd observation bug-  after deleting the rule check box is moving to the next traffic rule. */}
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={this.state.selectedTraffic.filter(item => (item.cfg === rule.cfg))?.length > 0}
                                                onClick={(e) => this.selectedTraffic(e, rule)} />
                                        </td>
                                        <td>{rule?.name}</td>
                                        <td className="c-gray">
                                            <ul>
                                                <li>
                                                    <strong>Protocol:</strong>
                                                    <span>{this.getProtocol(rule?.proto)}</span>
                                                </li>
                                                <li>
                                                    <strong>Source Zone:</strong>
                                                    <span>{Common.getZoneName(rule?.sourceZone)}</span>
                                                </li>
                                                <li>
                                                    <strong>Source IP:</strong>
                                                    <span>{rule?.sourceIP}</span>
                                                </li>
                                                <li>
                                                    <strong>Source Port:</strong>
                                                    <span>{rule?.sourcePort}</span>
                                                </li>
                                                <li>
                                                    <strong>Destination Zone:</strong>
                                                    <span>{Common.getZoneName(rule?.destZone)}</span>
                                                </li>
                                                <li>
                                                    <strong>Destination IP:</strong>
                                                    <span>{rule?.destIP}</span>
                                                </li>
                                                <li>
                                                    <strong>Destination Port:</strong>
                                                    <span>{rule?.destPort}</span>
                                                </li>
                                            </ul>
                                        </td>
                                        {this.props.edit &&
                                            <>
                                                <td className="c-gray">
                                                    <div className="dropdown">
                                                        <ul className="chip-list z-index-9 radius">
                                                            {rule.sourceIP?.split(' ')?.map((item) => {
                                                                if (item)
                                                                    return (<li>{item}
                                                                        <img
                                                                            src="/images/svg-icons/cancel.svg"
                                                                            alt=""
                                                                            id={item}
                                                                            onClick={(e) => this.handleTrafficSourceIP(e, rule)} />
                                                                    </li>)
                                                            })}
                                                        </ul>
                                                        <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        </div>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <ul>{
                                                                this.state.availableIP.length > 0 && this.state.availableIP.map((item) => {
                                                                    return (<li>
                                                                        <input
                                                                            id={item}
                                                                            type="checkbox"
                                                                            checked={rule.sourceIP?.split(' ')?.includes(item)}
                                                                            onChange={(e) => this.handleTrafficSourceIP(e, rule)}
                                                                        />
                                                                        {item}
                                                                    </li>)
                                                                })}
                                                                <li
                                                                    data-toggle="modal"
                                                                    data-target="#exampleModalIP"
                                                                    onClick={() => this.setState({ customIP: true })}
                                                                    className="btn btn-primary btn-block mt-3">
                                                                    + Add IP
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="c-gray">
                                                    <div className="dropdown">
                                                        <ul className="chip-list z-index-9 radius">
                                                            {rule.destIP?.split(' ')?.map((item) => {
                                                                if (item)
                                                                    return (<li>{item}
                                                                        <img
                                                                            src="/images/svg-icons/cancel.svg"
                                                                            alt=""
                                                                            id={item}
                                                                            onClick={(e) => this.handleTrafficDestIP(e, rule)} />
                                                                    </li>)
                                                            })}
                                                        </ul>
                                                        <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        </div>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <ul>{
                                                                this.state.availableIP.length > 0 && this.state.availableIP.map((item) => {
                                                                    return (<li>
                                                                        <input
                                                                            id={item}
                                                                            type="checkbox"
                                                                            checked={rule.destIP?.split(' ')?.includes(item)}
                                                                            onChange={(e) => this.handleTrafficDestIP(e, rule)}
                                                                        />
                                                                        {item}</li>)
                                                                })}
                                                                <li
                                                                    data-toggle="modal"
                                                                    data-target="#exampleModalIP"
                                                                    onClick={() => this.setState({ customIP: true })}
                                                                    className="btn btn-primary btn-block mt-3">
                                                                    + Add IP
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td></>}
                                        <td>{Common.getAction(rule?.action)}</td>
                                        <td>{rule?.enabled === '1' ? 'Yes' : 'No'}</td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                    <div className="tab-pane" id="nat-rules" role="tabpanel" aria-labelledby="nat-rules-tab">
                        <div className="page-sub-title">
                            <div><strong>NAT Rules</strong>
                                <img
                                    src="/images/svg-icons/add.svg"
                                    className={(this.props.templateData.default_type !== ''
                                        && this.props.templateData.default_type !== null) ? "add-btn ml-2 disabled" : "add-btn ml-2"}
                                    alt=""
                                    onClick={(this.props.templateData.default_type !== ''
                                        && this.props.templateData.default_type !== null) ? undefined : () => this.openAddNATRule()} />
                            </div>
                            <div className="device-action">
                                {/* edit icon not getting disabled when more than one rows were selected */}
                                <span
                                    className={this.state.selectedNAT.length === 1 ?
                                        "icon-box" : "icon-box disabled"}>
                                    <img
                                        src="/images/svg-icons/edit.svg"
                                        alt=""
                                        disabled={this.state.selectedNAT.length !== 1}
                                        onClick={this.state.selectedNAT.length === 1 ? () => this.openAddNATRule(this.state.selectedNAT[0]) : undefined} />
                                </span>
                                <span
                                    className={this.state.selectedNAT.length < 1 && (this.props.templateData.default_type !== '' ||
                                        this.props.templateData.default_type !== null) ? "icon-box disabled" : "icon-box"}>
                                    <img
                                        src="/images/svg-icons/delete.svg"
                                        alt=""
                                        disabled={this.state.selectedNAT.length < 1}
                                        onClick={this.state.selectedNAT.length > 0 && (this.props.templateData.default_type === '' ||
                                            this.props.templateData.default_type === null) ? this.deleteNAT : undefined} />
                                </span>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                    </th>
                                    <th className="w-80">Name</th>
                                    <th className="w-150">Match</th>
                                    {this.props.edit &&
                                        <>
                                            <th>Source IP<span className="red-txt">*</span></th>
                                            <th>Dest. IP<span className="red-txt">*</span></th>
                                        </>
                                    }
                                    <th>Action<span className="red-txt">*</span></th>
                                    <th>Enabled</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.template_schema_json.natRules.filter(item => item.isdeleted !== '1')?.map((rule, index) =>
                                    <tr>
                                        {/* Long 1806 after deleting the rule check box is moving to the next Nat rule. */}
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={this.state.selectedNAT.filter(item => (item.cfg === rule.cfg))?.length > 0}
                                                onClick={(e) => this.selectedNAT(e, rule)} />
                                        </td>
                                        <td>{rule.name}</td>
                                        <td className="c-gray">
                                            <ul>
                                                <li><strong>Protocol:</strong><span>{rule?.proto}</span></li>
                                                <li><strong>Source IP:</strong><span>{rule?.sourceIP}</span></li>
                                                <li><strong>Destination IP:</strong><span>{rule?.destIP}</span></li>
                                                <li><strong>Outbound Zone:</strong><span>{((rule?.outboundZone).toLowerCase() === "lan" || (rule?.outboundZone).toLowerCase() === "wan") ? (rule?.outboundZone).toUpperCase() : rule?.outboundZone}</span></li>
                                            </ul>
                                        </td>
                                        {this.props.edit && <>
                                            <td className="c-gray">
                                                <Select
                                                    isClearable
                                                    components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP') }}
                                                    isSearchable
                                                    className="custom-dropdown w-105"
                                                    classNamePrefix="react-select-container"
                                                    name="customip"
                                                    options={this.state.options}
                                                    value={{ label: rule.sourceIP, value: rule.sourceIP }}
                                                    onChange={(e) => this.handleNatSourceIP(e, rule)}
                                                />
                                            </td>

                                            <td className="c-gray">
                                                <Select
                                                    isClearable
                                                    components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP') }}
                                                    isSearchable
                                                    className="custom-dropdown w-105"
                                                    classNamePrefix="react-select-container"
                                                    name="customip"
                                                    options={this.state.options}
                                                    value={{ label: rule.destIP, value: rule.destIP }}
                                                    onChange={(e) => this.handleNatDestIP(e, rule)}
                                                />
                                            </td></>}
                                        <td className="lblue-bg">{Common.getAction(rule?.action)}
                                            {this.props.edit && rule?.action === 'snat' &&
                                                <>
                                                    <li><span>SNAT IP:   </span>
                                                        <span>{rule?.snatIP}</span></li>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="snatipAddress"
                                                        onBlur={(e) => this.handleSnatIP(e, rule)}
                                                        onChange={(e) => this.removeErrorIP(e)}
                                                        defaultValue={rule?.snatIP || ''} />
                                                    <div className="error-txt" id="snatipAddress"></div>
                                                </>
                                            }</td>
                                        <td>{rule?.enabled === '1' ? 'Yes' : 'No'}</td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.state.openAddZone &&
                    <>
                        <div className="sidebar-slide w-350 r-757 second-open">
                            <div className="s-header">
                                <h1 className="ss-title">Add New Zone</h1>
                                <button>
                                    <img src="/images/svg-icons/s-cancel.svg" alt="" onClick={this.closeAddZone} />
                                </button>
                            </div>
                            <div className="s-body">
                                {/* Add new zone */}
                                <p>This section defines common properties of "this new zone". The input and output options set the default policies for traffic entering and leaving this zone while the forward option describes the policy Covered networks specifies which available networks are members of this zone.</p>
                                <div className="form-group">
                                    <label>Name<span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of the zone">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={this.state.zoneData?.name}
                                        onChange={this.setZoneData}
                                        onBlur={this.state.update ? undefined : (e) => this.validateName(e, 'zoneInfo', this.state.zoneData)}
                                        readOnly={this.state.update} maxLength='12' />
                                    <div className="error-txt zone" id='name'>Invalid Value</div>
                                </div>
                                <div className="form-group">
                                    <label>Input<span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Default policy for incoming zone traffic">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <select
                                        className="form-control"
                                        name="input"
                                        value={this.state.zoneData?.input}
                                        onChange={this.setZoneData}
                                    >
                                        <option value='ACCEPT'>Accept</option>
                                        <option value='REJECT'>Reject</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Output<span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Default policy for outgoing zone traffic.">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <select
                                        className="form-control"
                                        name='output'
                                        value={this.state.zoneData?.output}
                                        onChange={this.setZoneData}
                                    >
                                        <option value='ACCEPT'>Accept</option>
                                        <option value='REJECT'>Reject</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Forward<span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Default policy for forwarded zone traffic.">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <select
                                        className="form-control"
                                        name='forward'
                                        value={this.state.zoneData?.forward}
                                        onChange={this.setZoneData}
                                    >
                                        <option value='ACCEPT'>Accept</option>
                                        <option value='REJECT'>Reject</option>
                                    </select>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                                    <span className="mr-5">Masquerading<span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Specifies whether outgoing zone traffic should be masqueraded">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="masq"
                                            checked={this.state.zoneData?.masq === '1'}
                                            onChange={(e) => this.setZoneData(e)} />
                                        <span className="slider round">
                                        </span>
                                    </label>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                                    <span className="mr-5">MSS Clamping<span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Specifies whether outgoing zone traffic should be MSS Clamping">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name="mssClamping"
                                            checked={this.state.zoneData?.mssClamping === '1'}
                                            onChange={(e) => this.setZoneData(e)}
                                        />
                                        <span className="slider round">
                                        </span>
                                    </label>
                                </div>
                                <p>The options below control the forwarding policies between this zone (this new zone) and other zones. Destination zones
                                    cover forwarded traffic originating from this new zone. Source zones match forwarded traffic from other zone targeted at this new zone. The forwarding rules is unidirectional, e.g. a forward from LAN to WAN does not imply a permission to forward from WAN to LAN as well.</p>
                                <div className="form-group">
                                    <label>Allow forward to Destination zone<span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Control the traffic flow between zones">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <div className="dropdown">
                                        <ul className="chip-list z-index-9 bg-white radius">
                                            {this.state.zoneData.forwardTo?.map((item) => {
                                                return (<li>
                                                    {item === 'none' ?
                                                        'None' : (item.toLowerCase() === "lan" || item.toLowerCase() === "wan") ?
                                                            item.toUpperCase() : item}
                                                    <img src="/images/svg-icons/cancel.svg" alt=""
                                                        id={item} onClick={(e) => this.handleChangeTo(e)} /></li>)
                                            })}
                                        </ul>
                                        <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </div>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                {this.state.availableToZone?.map((item) => {
                                                    return (<li>
                                                        <input
                                                            type="checkbox"
                                                            id={item?.name}
                                                            checked={this.state.availableToZone?.includes(item?.name)}
                                                            onClick={(e) => this.handleChangeTo(e)} />
                                                        {item.name === 'none' ?
                                                            'None' : ((item?.name).toLowerCase() === "lan" || (item?.name).toLowerCase() === "wan") ?
                                                                (item?.name).toUpperCase() : item.name}
                                                    </li>)
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Allow forward from source zone<span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Control the traffic flow between zones">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <div className="dropdown">
                                        <ul className="chip-list z-index-9 bg-white radius">
                                            {this.state.zoneData.forwardFrom?.map((item) => {
                                                return (<li>
                                                    {item === 'none' ?
                                                        'None' : (item.toLowerCase() === "lan" || item.toLowerCase() === "wan") ?
                                                            item.toUpperCase() : item}
                                                    <img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                        onClick={(e) => this.handleChangeFrom(e)} />
                                                </li>)
                                            })}
                                        </ul>
                                        <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </div>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                {this.state.availableFromZone.map((item) => {
                                                    return (<li >
                                                        <input
                                                            type="checkbox"
                                                            id={item.name}
                                                            checked={this.state.availableFromZone?.includes(item?.name)}
                                                            onClick={(e) => this.handleChangeFrom(e)} />
                                                        {item.name === 'none' ?
                                                            'None' : ((item?.name).toLowerCase() === "lan" || (item?.name).toLowerCase() === "wan") ?
                                                                (item?.name).toUpperCase() : item?.name}</li>)
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="s-footer">
                                <button className="btn btn-light" onClick={this.closeAddZone}>Cancel</button>
                                <button className="btn btn-primary" disabled={this.checkZoneError()} onClick={this.addZone}>{this.state.update ? 'Update' : 'Add'}</button>
                            </div>
                        </div>
                    </>
                }
                {/* Add new zone close */}
                {/* Add New Port Forward */}
                <br />
                {this.state.AddPortForward && <div className="sidebar-slide w-350 r-757 second-open">
                    <div className="s-header">
                        <h1 className="ss-title">Add New Port Forward</h1>
                        <button>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" onClick={this.closeAddPortForward} />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="form-group">
                            <label>Name<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Name of the Port Forwarding rule">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='name'
                                value={this.state.portForwardData?.name}
                                onChange={this.setPortData}
                                onBlur={(e) => this.validateName(e, 'portForward', this.state.portForwardData)}
                                maxLength='12' />
                            <div className="error-txt port" id='name'>Invalid Value</div>
                        </div>
                        <div className="form-group">
                            <label>Protocol<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match incoming traffic using the given protocol">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                    <div className="error-txt port" id='proto'>Invalid value</div>
                                </div>
                            </label>
                            <div className="dropdown">
                                <ul className="chip-list z-index-9 bg-white radius">
                                    {this.state.portForwardData?.proto?.split(' ')?.map((item) => {
                                        if (item)
                                            return (<li>{item === 'any' ? 'Any' : item.toUpperCase()}
                                                <img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                    onClick={(e) => this.setProtocol(e, 'portForward')} /></li>)
                                    })}
                                </ul>
                                <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                </div>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        {this.state.availableProtocol.map((item) => {
                                            if (item)
                                                return (<li>
                                                    <input
                                                        type="checkbox"
                                                        name="proto"
                                                        id={item}
                                                        checked={this.state.selectedProtocol === item}
                                                        onChange={(e) => this.setProtocol(e, 'portForward')} />
                                                    {item === 'any' ? 'Any' : item.toUpperCase()}</li>)
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Source Zone
                                <span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Specifies the traffic source zone. Refers to one of the defined zone names">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select
                                className="form-control"
                                name='sourceZone'
                                value={this.state.portForwardData?.sourceZone}
                                onChange={this.setPortData}
                            >
                                <option value=''>Select</option>
                                {/* LONG-3701-EM - In firewall template, deleted zone is displayed in source and destination dropdown */}
                                {this.state.template_schema_json.zoneInfo.filter(item => item.isdeleted !== '1').map((zone) => {
                                    return <option value={zone?.name}>{((zone?.name).toLowerCase() === "lan" || (zone?.name).toLowerCase() === "wan") ? (zone?.name).toUpperCase() : zone?.name}</option>
                                })}
                            </select>
                            <div className="error-txt port" id='sourceZone'>Invalid value</div>
                        </div>
                        <div className="form-group">
                            <label>External Port{(this.state.portForwardData?.proto !== 'any' &&
                                this.state.portForwardData?.proto !== 'icmp') && <span className="red-txt">*</span>}
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match incoming traffic originating from the given source port or port range on the client host">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <input
                                disabled={this.state.portForwardData.proto === 'any' || this.state.portForwardData.proto === 'icmp'}
                                type="text"
                                className="form-control"
                                name='sourcePort'
                                value={this.state.portForwardData?.sourcePort}
                                onChange={this.setPortData}
                                onBlur={this.validatePort} />
                            <div className="error-txt port" id='sourcePort'>Invalid value</div>
                        </div>
                        <div className="gray-bg rounded p-3">
                            <p><strong>Action</strong></p>
                            <div className="form-group">
                                <label>Destination Zone<span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Specifies the traffic destination zone. Refers to one of the defined zone names">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <select
                                    className="form-control"
                                    name='destZone'
                                    value={this.state.portForwardData?.destZone}
                                    onChange={this.setPortData}>
                                    <option value=''>Select</option>
                                    {/* LONG-3701-EM - In firewall template, deleted zone is displayed in source and destination dropdown */}
                                    <option value='device_input'>Device (input)</option>
                                    {this.state.template_schema_json.zoneInfo.filter(item => item.isdeleted !== '1')?.map((zone) => {
                                        return <option value={zone?.name}>{((zone?.name).toLowerCase() === "lan" || (zone?.name).toLowerCase() === "wan") ? (zone?.name).toUpperCase() : zone?.name}</option>
                                    })}
                                </select>
                                <div className="error-txt port" id='destZone'>Invalid value</div>
                            </div>
                            <div className="form-group mb-0">
                                <label>Internal Port
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Match incoming traffic directed at the given destination port or port range on this host">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                                <input
                                    disabled={this.state.portForwardData?.proto === 'any' || this.state.portForwardData?.proto === 'icmp'}
                                    type="text"
                                    className="form-control"
                                    name='destPort'
                                    value={this.state.portForwardData?.destPort}
                                    onChange={this.setPortData}
                                    onBlur={this.validatePort}
                                    placeholder="Any" />
                                <div className="error-txt port" id='destPort'>Invalid value</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                            <span className="mr-5">Enabled<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enabled">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </span>
                            <label className="switch">
                                <input
                                    type="checkbox" name="enabled"
                                    checked={this.state.portForwardData?.enabled === '1'}
                                    onChange={(e) => this.setPortData(e)} />
                                <span className="slider round">
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="s-footer">
                        <button className="btn btn-light" onClick={this.closeAddPortForward}>Cancel</button>
                        <button className="btn btn-primary" disabled={this.checkPortError()} onClick={this.addPortData}>
                            {this.state.update ? 'Update' : 'Add'}</button>
                    </div>
                </div>}
                {/* Add New Port Forward close */}
                {/* Add New Traffic Rules */}
                {this.state.addTrafficRule && <div className="sidebar-slide w-350 r-757  second-open">
                    <div className="s-header">
                        <h1 className="ss-title">Add New Traffic Rules</h1>
                        <button><img src="/images/svg-icons/s-cancel.svg" alt="" onClick={this.closeAddTrafficRule} /></button>
                    </div>
                    <div className="s-body">
                        <div className="form-group">
                            <label>Name<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Name of the Traffic Rule">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='name'
                                onBlur={(e) => this.validateName(e, 'trafficRules', this.state.trafficRuleData)}
                                maxLength='12'
                                value={this.state.trafficRuleData.name}
                                onChange={this.setTrafficData} />
                            <div className="error-txt traffic" id='name'>Invalid Value</div>
                        </div>
                        <div className="form-group">
                            <label>Protocol<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match incoming traffic using the given protocol">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <div className="dropdown">
                                <ul className="chip-list z-index-9 bg-white radius">
                                    {this.state.trafficRuleData.proto.split(' ')?.map((item) => {
                                        if (item)
                                            return (<li>{item === 'any' ? 'Any' : item.toUpperCase()}
                                                <img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                    onClick={(e) => this.setProtocol(e, 'trafficRules')} /></li>)
                                    })}
                                </ul>
                                <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                </div>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        {this.state.availableProtocol.map((item) => {
                                            if (item)
                                                return <li>
                                                    <input
                                                        type="checkbox"
                                                        name="proto" id={item}
                                                        checked={this.state.selectedProtocol === item}
                                                        onChange={(e) => this.setProtocol(e, 'trafficRules')} />
                                                    {item === 'any' ? 'Any' : item.toUpperCase()}</li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Source Zone{<span className="red-txt">*</span>}
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Specifies the traffic source zone. Refers to one of the defined zone names">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select
                                className="form-control"
                                name='sourceZone'
                                value={this.state.trafficRuleData?.sourceZone}
                                onChange={this.setTrafficData}>
                                <option value=''>Select</option>
                                {this.state.trafficRuleData?.destZone !== 'device_input' &&
                                    <option value='device_output'>Device (output)</option>}
                                {/* LONG-3701-EM - In firewall template, deleted zone is displayed in source and destination dropdown */}
                                <option value='any'>Any (forward)</option>
                                {this.state.template_schema_json?.zoneInfo.filter(item => item.isdeleted !== '1')?.map((zone) => {
                                    return <option value={zone?.name}>{((zone?.name).toLowerCase() === "lan" || (zone?.name).toLowerCase() === "wan") ? (zone?.name).toUpperCase() : zone?.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Source IP{(this.state.trafficRuleData?.proto !== 'tcp' &&
                                this.state.trafficRuleData?.proto !== 'udp')}
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match incoming traffic from the specified source IP address">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select
                                className="form-control"
                                name='sourceIP'
                                value={this.state.trafficRuleData?.sourceIP}
                                onChange={this.setTrafficData}>
                                <option value='any'>Any</option>
                                <option value=''>Configure IP at Apply Phase</option>
                            </select>
                        </div>
                        <div className="form-group">
                            {/* LONG-1701_Added validation for source and destination port */}
                            <label>Source Port{this.state.trafficRuleData?.proto !== 'any' &&
                                this.state.trafficRuleData?.proto !== 'icmp'}
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match incoming traffic from the specified source port or port range">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <input
                                disabled={this.state.trafficRuleData?.proto === 'any' || this.state.trafficRuleData?.proto === 'icmp'}
                                type="text"
                                name='sourcePort'
                                value={this.state.trafficRuleData?.sourcePort}
                                className="form-control"
                                onChange={this.setTrafficData}
                                onBlur={(e) => this.validatePort(e)} />
                            <div className="error-txt traffic" id='sourcePort'>Invalid value</div>
                        </div>
                        <div className="form-group">
                            <label>Destination Zone<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Specifies the traffic destination zone. Refers to one of the defined zone names">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select
                                className="form-control"
                                name='destZone'
                                value={this.state.trafficRuleData?.destZone}
                                onChange={this.setTrafficData}>
                                <option value=''>Select</option>
                                {this.state.trafficRuleData?.sourceZone !== 'device_output' && <option value='device_input'>Device (input)</option>}
                                {/* LONG-3701-EM - In firewall template, deleted zone is displayed in source and destination dropdown */}
                                <option value='any'>Any (forward)</option>
                                {this.state.template_schema_json?.zoneInfo.filter(item => item.isdeleted !== '1')?.map((zone) => {
                                    return <option value={zone?.name}>{((zone?.name).toLowerCase() === "lan" || (zone?.name).toLowerCase() === "wan") ? (zone?.name).toUpperCase() : zone?.name}</option>
                                })}
                            </select>
                            <div className="error-txt traffic" id='destZone'>Invalid value</div>
                        </div>
                        <div className="form-group">
                            <label>Destination IP
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match incoming traffic directed to the specified destination IP address">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select
                                className="form-control"
                                name='destIP'
                                value={this.state.trafficRuleData?.destIP}
                                onChange={this.setTrafficData}>
                                <option value='any'>Any</option>
                                <option value=''>Configure IP at Apply Phase</option>
                            </select>
                        </div>
                        <div className="form-group">
                            {/* LONG-1701_Added validation for source and destination port */}
                            <label>Destination Port
                                {(this.state.trafficRuleData.proto !== 'any' &&
                                    this.state.trafficRuleData.proto !== 'icmp')}
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match incoming traffic directed at the given destination port or port range">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <input
                                disabled={this.state.trafficRuleData?.proto === 'any' || this.state.trafficRuleData?.proto === 'icmp'}
                                type="text"
                                name='destPort'
                                value={this.state.trafficRuleData?.destPort}
                                className="form-control"
                                onChange={this.setTrafficData}
                                onBlur={(e) => this.validatePort(e)}
                            />

                            <div className="error-txt traffic" id='destPort'>Invalid value</div>
                        </div>
                        <div className="form-group">
                            <label>Action<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Firewall action for matched traffic">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select
                                className="form-control"
                                name="action"
                                value={this.state.trafficRuleData?.action}
                                onChange={this.setTrafficData}>
                                <option value='ACCEPT'>Accept</option>
                                <option value='REJECT'>Reject</option>
                                <option value='DROP'>Drop</option>
                            </select>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                            <span className="mr-5">Enabled<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enabled">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </span>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name="enabled"
                                    checked={this.state.trafficRuleData?.enabled === '1'}
                                    onClick={(e) => this.setTrafficData(e)} />
                                <span className="slider round">
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="s-footer">
                        <button className="btn btn-light" onClick={this.closeAddTrafficRule}>Cancel</button>
                        <button className="btn btn-primary" disabled={this.checkTrafficError()}
                            onClick={this.addTrafficRule}>{this.state.update ? 'Update' : 'Add'}</button>
                    </div>
                </div>}
                {/* Add New Traffic Rules close */}
                {/* Add New NAT Rules */}
                {this.state.addNATRule && <div className="sidebar-slide w-350 r-757 second-open" >
                    <div className="s-header">
                        <h1 className="ss-title">Add New NAT Rules</h1>
                        <button><img src="/images/svg-icons/s-cancel.svg" alt="" onClick={this.closeAddNATRule} /></button>
                    </div>
                    <div className="s-body">
                        <div className="form-group">
                            <label>Name<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Name of NAT Rule">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='name'
                                value={this.state.NATRuleData?.name}
                                maxLength='12'
                                onChange={this.setNATData}
                                onBlur={(e) => this.validateName(e, 'natRules', this.state.NATRuleData)} />
                            <div className="error-txt nat" id='name'>Invalid Value</div>
                        </div>
                        <div className="form-group">
                            <label>Protocol<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match incoming traffic using the given protocol">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <div className="dropdown">
                                <ul className="chip-list z-index-9 bg-white radius">
                                    {this.state.NATRuleData.proto.split(' ').map((item) => {
                                        if (item)
                                            return (<li>{item === 'any' ? 'Any' : item.toUpperCase()}
                                                <img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                    onClick={(e) => this.setProtocol(e, 'natRules')} /></li>)
                                    })}
                                </ul>
                                <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                </div>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        {this.state.availableProtocol.map((item) => {
                                            if (item)
                                                return (<li>
                                                    <input
                                                        type="checkbox"
                                                        name="proto"
                                                        id={item}
                                                        checked={this.state.selectedProtocol === item}
                                                        onChange={(e) => this.setProtocol(e, 'natRules')} />
                                                    {item === 'any' ? 'Any' : item.toUpperCase()}</li>)
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Outbound Zone
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title=" Specifies the traffic destination zone">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select className="form-control" name='outboundZone' value={this.state.NATRuleData.outboundZone}
                                onChange={this.setNATData}>
                                <option value='any'>Any</option>
                                {/* LONG-3701-EM - In firewall template, deleted zone is displayed in source and destination dropdown */}
                                {this.state.template_schema_json.zoneInfo.filter(item => item.isdeleted !== '1').map((zone) => {
                                    return <option value={zone.name}>{((zone.name).toLowerCase() === "lan" || (zone.name).toLowerCase() === "wan") ? (zone.name).toUpperCase() : zone.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Source IP
                                <div className="tooltip-c"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Match incoming traffic from the specified source IP address">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select
                                className="form-control"
                                name='sourceIP'
                                value={this.state.NATRuleData?.sourceIP}
                                onChange={this.setNATData}>
                                <option value='any'>Any</option>
                                <option value=''>Configure IP at Apply Phase</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Destination IP
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Match incoming traffic directed to the specified destination IP address">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select
                                className="form-control"
                                name='destIP'
                                value={this.state.NATRuleData?.destIP}
                                onChange={this.setNATData}>
                                <option value='any'>Any</option>
                                <option value=''>Configure IP at Apply Phase</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Action<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="NAT action for matched traffic">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </label>
                            <select
                                className="form-control"
                                name='action'
                                value={this.state.NATRuleData?.action}
                                onChange={this.setNATData}>
                                <option value='snat'>SNAT - Rewrite to specific Source IP</option>
                                <option value='masq'>MASQUERADE - Automatically rewrite to outbound interface IP</option>
                            </select>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                            <span className="mr-5">Enabled<span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Enabled">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </span>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name='enabled'
                                    checked={this.state.NATRuleData?.enabled === "1" ? true : false}
                                    onChange={this.setNATData} />
                                <span className="slider round">
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="s-footer">
                        <button className="btn btn-light" onClick={this.closeAddNATRule}>Cancel</button>
                        <button className="btn btn-primary" disabled={this.checkNATError()}
                            onClick={this.addNATRule}>{this.state.update ? 'Update' : 'Add'}</button>
                    </div>
                </div>}
                {/* Add New NAT Rules close */}
                {!this.props.view && <div className="s-footer">
                    <button className="btn btn-light" onClick={
                        (this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.closeAddTemplate() :
                            () => this.closeAll()}>
                        {(this.props.apply && !this.props.edit && !this.props.reapply) ? 'Back' : 'Cancel'}</button>
                    <button className="btn btn-primary"
                        disabled={
                            this.checkError()}
                        onClick={
                            this.props.apply ? (event) => this.debounceApply(this.applyTemplate, event) :
                                () => this.saveData()}
                    >{this.props.apply ? 'Apply' : (this.state.selectedTemplateId !== '' ? 'Update' : 'Save')}</button>
                </div>}
                {this.state.customIP &&
                    <div className="modal fade" id="exampleModalIP" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add IP</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => this.setState({ customIP: false })}>
                                        <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group mb-1">
                                        {/* //LONG-2154 UI validation need to be corrected for some cases */}
                                        <label>IP address/Subnet</label>
                                        <div className="d-flex align-items-center">
                                            <input type="text" className="form-control w-100" name="ipAddress" onBlur={(e) => this.validateCustomIP(e)} onChange={(e) => this.removeErrorIP(e)}
                                            />
                                        </div>
                                        <div className="error-txt" id="ipAddress" >Invalid IP address</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-light" data-dismiss="modal"
                                        onClick={() => this.cancelIP()}
                                    >Cancel</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.state.validIP || this.state.showError}
                                        onClick={this.addIP}
                                    >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                {/* LONG-1634_Issue Fixed for error popup */}
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>);
    }
}
const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying,
    };
};
export default connect(mapStateToProps)(FirewallTemplate);
