/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import moment from 'moment';
import DataTable from "react-data-table-component";
import $ from 'jquery';
import Select from 'react-select';
import ErrorHandlePopup from '../ErrorHandlePopup';
import { ErrorPopup } from '../../layOuts/errorPopup';
class Warnings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showRefreshModal: false,
            deviceEventsData: [],
            pageNo: 1,
            pageSize: 10,
            first: 1,
            last: 10,
            next: true,
            prev: true,
            count: 0,
            alertPageCount: 0,
            id: this.props.data,
            deviceName: '',
            groupName: '',
            deviceSeverity: '',
            filterDeviceName: '',
            filterGroupName: '',
            filterDeviceSeverity: '',
            getDeviceLookUp: [],
            showFilter: false,
            alertWarning: [],
            selectedOption: [],
            filterEnabled: false,
            deviceGroupLookUp: [],
            showIcon: false,
            isAxiosError: false,
            warningColumns: [
                {
                    name: "Severity",
                    selector: "severity",
                    value: "Severity",
                    cell: (row) => (<div id={`col${row.severity}`} >
                        <span id={`icon${row.severity}`}>
                            {this.returnSeverity(row.severity, true)}</span>
                    </div>
                    ),
                },
                {
                    name: "Event Name",
                    selector: "event_name",
                    value: "Event Name",
                    minWidth: "500px",
                    cell: (row) => (<span title={row.event_name} style={{ width: 'auto' }}>
                        {row.event_name}</span>
                    ),
                },
                {
                    name: "Device Name",
                    selector: "device_name",
                    value: "Device Name",
                    cell: (row) => (<span title={row.device_name} style={{ width: 'auto' }}>
                        {row.device_name}</span>
                    ),
                },
                {
                    name: "Created Date",
                    selector: "created_date",
                    value: "Create Date",
                    minWidth: "220px",
                    cell: (row) => (<span className="date-time" title={this.getTimeExact(row.clock)} style={{ width: 'auto' }}>
                        {this.getTimeExact(row.clock)}</span>
                    ),
                },
            ]
        }
    }
    // LONG-2308 : Trigger Configuration- Severity mismatch in Zabbix and EM
    returnSeverity = (severity, table) => {
        switch (severity) {
            case "0":
            case "1":
                return "Information";
            case "2":
                return table ? "Low" : "Warning"
            case "3":
                return table ? "Medium" : "Average";
            case "4":
                return "High"
            case "5":
                return table ? "Critical" : "Disaster";
            default:
                return "";
        }
    }
    componentDidMount() {
        this.getDeviceLookUpMac();
        this.getDeviceGroupLookUp();
        if (this.props?.location?.state?.tabValue || this.props?.deviceSeverity) {
            this.setState({
                groupName: this.props?.location?.state?.group_id || "",
                deviceSeverity: this.props?.deviceSeverity || this.props?.location?.state?.deviceSeverity,
                filterEnabled: true
            }, () => this.getDeviceEventFilter())
        }
        else if (this.props?.data) {
            this.getAlertsAndWarning()
        }
        else {
            this.deviceEvents();
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps?.data) {
            if (nextProps?.data !== this.props.data) {
                this.setState({
                    pageNo: 1
                })
            }
            this.setState({
                id: nextProps.data
            }, () => this.getAlertsAndWarning())
        }
    }
    getDeviceLookUpMac = () => {
        this.setState({ showLoader: true });
        ApiCall(urlMapping.deviceLookUp(), (response) => {
            if (response?.data) {
                const data = response?.data
                this.setState({
                    getDeviceLookUp: data,
                    showLoader: false
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    getDeviceGroupLookUp = async () => {
        this.setState({ showLoader: true });
        await ApiCall(urlMapping.deviceGroupLookUpDrop(), (response) => {
            if (response?.data) {
                const data = response?.data;
                if (data?.length !== 0)
                    this.setState({
                        deviceGroupLookUp: data,
                        showLoader: false
                    })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        });
    };
    refreshPage = () => {
        if (this.props.data) {
            if (this.state.filterEnabled) {
                this.getDeviceEventFilter();
            } else {
                this.getAlertsAndWarning()
            }
        }
        else {
            if (this.state.filterEnabled) {
                this.getDeviceEventFilter()
            }
            else {
                this.deviceEvents()
            }
        }
    }
    deviceEvents = () => {
        this.setState({
            showLoader: true,
        })
        ApiCall(urlMapping.homeDashboardDeviceEvents(this.state.pageNo, this.state.pageSize), (response) => {
            this.setState({ showLoader: false })
            if (response?.results) {
                this.setState({
                    deviceEventsData: response?.results,
                    count: response?.count,
                    showLoader: false
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    getAlertsAndWarning = () => {
        this.setState({
            showLoader: true,
        })
        let data = { device_id: this.state.id }
        ApiCall(urlMapping.alertWarningDevice(data, this.state.pageNo, this.state.pageSize), (response) => {
            if (response?.results) {
                this.setState({
                    showLoader: false,
                    alertWarning: response?.results,
                    alertPageCount: response?.count,
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    paginationChange = (e) => {
        if (e) {
            if (this.props?.data) {
                this.setState({
                    pageNo: e
                }, () => {
                    this.state.filterEnabled ? this.getDeviceEventFilter() : this.getAlertsAndWarning();
                });
            }
            else {
                this.setState({
                    pageNo: e
                }, () => {
                    this.state.filterEnabled ? this.getDeviceEventFilter() : this.deviceEvents();
                });
            }
        }
    }
    pageSizeChange = (e) => {
        if (e) {
            if (this.props?.data) {
                this.setState({
                    pageSize: e
                }, () => {
                    this.state.filterEnabled ? this.getDeviceEventFilter() : this.getAlertsAndWarning();
                });
            }
            else {
                this.setState({
                    pageSize: e
                }, () => {
                    this.state.filterEnabled ? this.getDeviceEventFilter() : this.deviceEvents();
                });
            }
        }
    }
    getTimeExact = (time) => {
        // EMGT-19 : Randomly, Warning and alerts are not updating in EM in periodical interval
        let date = moment.unix(time);
        return date.format('DD MMMM YYYY hh:mm:ss A');
    }
    setFilterList = () => {
        this.setState((prevState) => ({
            showFilter: !prevState.showFilter,
        }));
    }
    //Severity must reset on changing device name to select warnings and alerts list
    getSelectedDeviceName = (e) => {
        if (e && e.label) {
            this.setState({
                filterDeviceName: e.value || ""
            })
        }
    }
    getSelectedDeviceGroupName = (e) => {
        if (e && e.label) {
            this.setState({
                filterGroupName: e.value || ""
            })
        }
    }
    getOptions = () => {
        let options = [];
        options = this.state.getDeviceLookUp.map((item) => {
            if (item !== "")
                return { label: item.device_name, value: item.device_id }
        })
        return options;
    }
    getDeviceGroupOptions = () => {
        let options = [];
        options = this.state.deviceGroupLookUp.map((item) => {
            if (item !== "")
                return { label: item.group_name, value: item.group_id }
        })
        return options;
    }
    filterOptions = (option, inputValue) => {
        const { label } = option;
        const otherKey = this.getOptions().filter(
            (opt) => opt.label.toLowerCase() === label.toLowerCase() && opt.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return label.toLowerCase().includes(inputValue.toLowerCase()) || otherKey.length > 0;
    }
    setDeviceSeverity = (e) => {
        if (e.target.value !== 'select') {
            this.setState({
                filterDeviceSeverity: e.target.value
            })
        }
    }
    clearDropdown = (type) => {
        if (type === "Device") {
            this.setState({ filterDeviceName: "" })
        }
        else if (type === "Group") {
            this.setState({ filterGroupName: "" })
        }
        else {
            this.setState({ filterDeviceSeverity: '' })
        }
    }
    resetValue = () => {
        if (this.props.data) {
            this.setState({
                pageNo: this.state.filterEnabled ? 1 : this.state.pageNo,
                deviceName: '',
                groupName: '',
                deviceSeverity: '',
                showIcon: false,
                filterEnabled: false,
                showFilter: false
            }, () => {
                this.getAlertsAndWarning()
            })
        }
        else {
            this.setState({
                deviceName: '',
                groupName: '',
                deviceSeverity: '',
                showIcon: false,
                filterEnabled: false,
                pageNo: this.state.filterEnabled ? 1 : this.state.pageNo,
                showFilter: false
            }, () => {
                this.deviceEvents()
            })
        }
    }
    hideDropdown = (e) => {
        var popup = $("#parentDiv");
        if (!$('#open').is(e.target) && !popup.is(e.target) && popup.has(e.target).length === 0) {
            this.setState({ showFilter: false, })
        }
    }
    getDeviceEventFilter = () => {
        this.setState({ showLoader: true })
        let data = '';
        if (this.state.deviceName !== '') {
            data = {
                device_id: this.state.deviceName,
                severity: this.state.deviceSeverity
            };
        }
        else if (this.state.groupName !== '') {
            data = {
                group_id: this.state.groupName,
                severity: this.state.deviceSeverity
            }
        }
        else if (this.state.id !== '') {
            data = {
                device_id: this.state.id,
                severity: this.state.deviceSeverity
            };
        }
        else {
            data = {
                severity: this.state.deviceSeverity
            }
        }
        ApiCall(urlMapping.getDeviceEventFilter(data, this.state.pageNo, this.state.pageSize), (response) => {
            if (parseInt(response?.response?.status) === 200) {
                if (this.props?.data) {
                    this.setState({
                        alertWarning: response?.results,
                        count: response?.count,
                        alertPageCount: response?.count,
                        showFilter: false,
                        showIcon: true,
                        showLoader: false
                    })
                }
                else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true,
                            showLoader: false
                        })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
                        const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
                        this.setState({
                            next: response?.next ? false : true,
                            prev: response?.previous ? false : true,
                            first: first,
                            last: last,
                            deviceEventsData: response?.results,
                            count: response?.count,
                            showFilter: false,
                            showIcon: true,
                            showLoader: false
                        })
                    }
                }
            }
        })
    }
    render() {
        return (
            !this.props.data ?
                <div onMouseDown={(e) => this.hideDropdown(e)}>
                    <SideMenu />
                    {this.state.showLoader && <Loader />}
                    <div className="white-bg">
                        <div className="ta-box set-pos mb-0">
                            <div className="action-btn">
                                <button
                                    onClick={() => this.refreshPage()}>
                                    <img src="/images/svg-icons/refreshing.svg" alt="" />
                                    Refresh
                                </button>
                                <div className="btn-group" >
                                    {/* disabled filter button if no data */}
                                    {/* LONG-3898-EM - Issues in filter operation in warning and alerts page */}
                                    <button
                                        type="button"
                                        className="dropdown-toggle  filter-btn"
                                        disabled={this.state?.deviceEventsData?.length === 0 && !this.state.showIcon}
                                        onClick={() => this.setState({
                                            filterDeviceSeverity: this.state.deviceSeverity,
                                            filterDeviceName: this.state.deviceName,
                                            filterGroupName: this.state.groupName
                                        }, () => this.setFilterList())}>
                                        {this.state.showIcon ?
                                            <img src="/images/svg-icons/info-icon.svg" alt="" />
                                            :
                                            <img src="/images/svg-icons/filter.svg" alt="" />
                                        }
                                        Filter
                                    </button>
                                    {this.state.showFilter &&
                                        <div
                                            id="parentDiv"
                                            className={this.state.showFilter ? "dropdown-menu show  dropdown-menu-right filter-section width360 overflow-visible" : "dropdown-menu dropdown-menu-right filter-section width360 overflow-visible"}
                                        >
                                            <div className="form-group">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label>Severity</label>
                                                    <span onClick={() => this.clearDropdown()}>Clear</span>
                                                </div>
                                                <select
                                                    className="form-control"
                                                    value={this.state.filterDeviceSeverity ? this.state.filterDeviceSeverity : "Select"}
                                                    onChange={(e) => this.setDeviceSeverity(e)}>
                                                    <option value="Select">Select Severity</option>
                                                    <option value="Information">Information</option>
                                                    <option value="Warning">Low</option>
                                                    <option value="Average">Medium</option>
                                                    <option value="High">High</option>
                                                    <option value="Disaster">Critical</option>
                                                </select>
                                            </div>
                                            <div className={this.state.filterGroupName !== "" ? "form-group disabled" : "form-group"}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label>Device Name</label>
                                                    <span
                                                        className={this.state.filterGroupName !== "" ? "disabled" : ""}
                                                        onClick={() => this.state.filterGroupName !== "" ? undefined : this.clearDropdown("Device")}
                                                    >
                                                        Clear
                                                    </span>
                                                </div>
                                                <Select
                                                    options={this.getOptions()}
                                                    isDisabled={this.state.filterGroupName !== ""}
                                                    filterOption={(o, i) => this.filterOptions(o, i)}
                                                    placeholder={"Select Device Name"}
                                                    isSearchable
                                                    className="filter-dropdown"
                                                    classNamePrefix="react-select-container"
                                                    value={
                                                        this.getOptions().filter(option =>
                                                            option.value === this.state.filterDeviceName)
                                                    }
                                                    onChange={(e) => this.getSelectedDeviceName(e)}
                                                />
                                            </div>
                                            <div className={this.state.filterDeviceName !== "" ? "form-group disabled" : "form-group"}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label>Device Group Name</label>
                                                    <span
                                                        className={this.state.filterDeviceName !== "" ? "disabled" : ""}
                                                        onClick={() => this.state.filterDeviceName !== "" ? undefined : this.clearDropdown("Group")}
                                                    >
                                                        Clear
                                                    </span>
                                                </div>
                                                <Select
                                                    options={this.getDeviceGroupOptions()}
                                                    isDisabled={this.state.filterDeviceName !== ""}
                                                    filterOption={(o, i) => this.filterOptions(o, i)}
                                                    placeholder={"Select Device Group Name"}
                                                    isSearchable
                                                    className="filter-dropdown"
                                                    classNamePrefix="react-select-container"
                                                    value={
                                                        this.getDeviceGroupOptions().filter(option =>
                                                            option.value === this.state.filterGroupName)
                                                    }
                                                    onChange={(e) => this.getSelectedDeviceGroupName(e)}
                                                />
                                            </div>
                                            <div className="action-box">
                                                <button
                                                    className="btn btn-light"
                                                    onClick={() => this.resetValue()}
                                                >
                                                    Reset
                                                </button>
                                                {/* Filter Apply button disable issue is fixed */}
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => this.setState({
                                                        pageNo: 1,
                                                        deviceName: this.state.filterDeviceName,
                                                        groupName: this.state.filterGroupName,
                                                        deviceSeverity: this.state.filterDeviceSeverity,
                                                        filterEnabled: true,
                                                    }, () => this.getDeviceEventFilter())}
                                                    disabled={
                                                        (this.state.filterDeviceSeverity === "" || this.state.filterDeviceSeverity === "Select") &&
                                                        this.state.filterDeviceName === "" && this.state.filterGroupName === ""}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div >
                        <div className="">
                            <DataTable
                                columns={this.state.warningColumns}
                                data={this.state.deviceEventsData}
                                highlightOnHover
                                fixedHeader
                                pagination
                                paginationServer
                                paginationTotalRows={this.state.count}
                                paginationDefaultPage={this.state.pageNo}
                                onChangePage={(e) => this.paginationChange(e)}
                                onChangeRowsPerPage={(e) => this.pageSizeChange(e)}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            />
                        </div>
                    </div>
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                </div > :
                <>
                    {this.state.showLoader && <Loader />}
                    <div className="white-bg " onMouseDown={(e) => this.hideDropdown(e)}>
                        <div className="ta-box set-pos mb-0">
                            <div className="action-btn">
                                <button
                                    onClick={() => this.refreshPage()}>
                                    <img src="/images/svg-icons/refreshing.svg" alt="" />
                                    Refresh
                                </button>
                                {/* LONG-3167-EM - Inside device page No need filter option on Warning and alerts--removed the filter here */}
                                <div className="btn-group" >
                                    <button
                                        type="button"
                                        className="dropdown-toggle  filter-btn"
                                        onClick={() => this.setState({ filterDeviceSeverity: this.state.deviceSeverity }, () => this.setFilterList())}>
                                        {this.state.showIcon ?
                                            <img src="/images/svg-icons/info-icon.svg" alt="" />
                                            :
                                            <img src="/images/svg-icons/filter.svg" alt="" />
                                        }
                                        Filter
                                    </button>
                                    {this.state.showFilter &&
                                        <div
                                            id="parentDiv"
                                            className={this.state.showFilter ? "dropdown-menu show  dropdown-menu-right filter-section width360 overflow-visible" : "dropdown-menu dropdown-menu-right filter-section width360 overflow-visible"}
                                        >
                                            <div className="form-group">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label>Severity</label>
                                                    <span onClick={() => this.clearDropdown()}>Clear</span>
                                                </div>
                                                <select
                                                    className="form-control"
                                                    value={this.state.filterDeviceSeverity ? this.state.filterDeviceSeverity : "Select"}
                                                    onChange={(e) => this.setDeviceSeverity(e)}>
                                                    <option value="Select">Select Severity</option>
                                                    <option value="Information">Information</option>
                                                    <option value="Warning">Low</option>
                                                    <option value="Average">Medium</option>
                                                    <option value="High">High</option>
                                                    <option value="Disaster">Critical</option>
                                                </select>
                                            </div>
                                            <div className="action-box">
                                                <button
                                                    className="btn btn-light"
                                                    onClick={() => this.resetValue()}
                                                >
                                                    Reset</button>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => this.setState({
                                                        pageNo: 1,
                                                        filterEnabled: true,
                                                        deviceSeverity: this.state.filterDeviceSeverity,
                                                    }, () => this.getDeviceEventFilter())}
                                                    disabled={this.state.filterDeviceSeverity === "" || this.state.filterDeviceSeverity === "Select"}
                                                >
                                                    Apply</button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <DataTable
                                columns={this.state.warningColumns}
                                data={this.state.alertWarning}
                                highlightOnHover
                                fixedHeader
                                pagination
                                paginationServer
                                paginationDefaultPage={this.state.pageNo}
                                paginationTotalRows={this.state.alertPageCount}
                                onChangePage={(e) => this.paginationChange(e)}
                                onChangeRowsPerPage={(e) => this.pageSizeChange(e)}
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            />
                        </div>
                    </div>
                    {(this.state.isAxiosError) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.setState({ isAxiosError: false })}
                        />
                    }
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                </>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
    });
}
export default withRouter(connect(mapStateToProps)(Warnings));

