
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import urlMapping from "../../redux/API/api";
import apiCall from "../../redux/API/apiCall";
import { Loader } from "../../layOuts/Loader";
import ErrorHandlePopup from "../ErrorHandlePopup";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import { ErrorPopup } from '../../layOuts/errorPopup';
var interval = null;
class Statistics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            option: {},
            showLoader: false,
            selectedQueue: 1,
            selectedInterface: "",
            selectedTab: "wan",
            selectedNetworks: [],
            toggleStatus: false,
            endTime: new Date(),
            id: this.props.data.device_id,
            show: false,
            error: false,
            startTime: new Date(moment(new Date()).subtract(5, "minutes")),
            linkData1: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "ceilrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
            linkData2: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "ceilrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
            linkData3: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "ceilrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
            linkData4: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
            linkData5: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "ceilrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
            "data": [
            ],
            fullView: false,
            wanInterfaces: [],
            lanInterfaces: [],
            tunnelInterfaces: [],
            interfacesOptions: [],
            showFilter: false,
            filterApplied: false,
            autoRefresh: false,
            status: 0,
            isAxiosError: false,
        }
    }
    componentDidMount() {
        if (this.state.id) {
            this.getNetworkView();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.data.device_id !== this.props.data.device_id) {
            this.setState({
                id: nextProps.data.device_id,
            }, () => {
                this.getNetworkView();
                this.getPollingData()
            })
        }
    }
    getPollingData = () => {
        this.setState({
            showLoader: true,
        });
        let data = {
            device_id: this.state.id,
        }
        apiCall(urlMapping.getPollingData(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                let startTime = ""
                let updated_time = response?.data?.qos?.updated_date
                let refreshTime = response?.data?.qos?.keys?.QoS_Statistics?.value * 20
                let refreshInterval = moment().subtract(refreshTime, "seconds")._d;
                if (response?.data?.qos?.updated_date && Math.round(parseInt(new Date(refreshInterval).getTime() / 1000)) <
                    Math.round(parseInt(new Date(moment.utc(updated_time)._d).getTime() / 1000))) {
                    startTime = moment.utc(updated_time)._d
                }
                else {
                    startTime = refreshInterval
                }
                this.setState({
                    startTime: startTime,
                    refreshTime,
                    status: response?.data?.qos?.keys?.QoS_Statistics?.status
                }, () => {
                    if (this.state.status === 1)
                        this.getStats()
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    }
    setInterface = (e) => {
        this.resetData();
        this.setState({
            selectedInterface: e.target.value
        }, this.getPollingData());
    }
    getNetworkView = () => {
        let data = {
            device_id: this.state.id,
        };
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true });
            apiCall(urlMapping.getNetworkView(data), (response) => {
                this.setState({ showLoader: false });
                if (response?.Success) {
                    let networkData = response?.data;
                    this.setState({ networkViewData: networkData }, () => {
                        apiCall(urlMapping.getQoSData(data), (response) => {
                            if (response?.success) {
                                this.setState({ QoSData: response?.data }, () => {
                                    this.getInterfacesdata();
                                })
                            }
                            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                this.setState({
                                    title: 'Network Error',
                                    message: 'Something went wrong. Please try again later.',
                                    showLoader: false,
                                    isAxiosError: true,
                                    delete: false,
                                    error: true
                                }, () => $("#errorMessage").modal("show"))
                            }
                        })
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({ networkViewData: [] });
                    }
                }
            });
        }
    };
    getInterfacesdata = () => {
        const selectedNetworks = this.state.networkViewData;
        let wanInterfaces = [];
        let lanInterfaces = [];
        let tunnelInterfaces = [];
        let wanData = this.state.QoSData?.QueueConfig?.filter(item => item.type === "wanConfig");

        wanData?.map((config) => {
            const networks = config.networkName.split(' ');
            networks.map((value) => {
                const network = selectedNetworks?.filter(item => item.networkName === value)[0];
                if (network) {
                    if (network.bridgeStatus === 'Enabled') {
                        wanInterfaces.push(`br-${network.networkName}`)
                    } else {
                        const interfaceName = selectedNetworks?.filter(item => item.networkName === value)[0].interfaceName.split(' ');
                        if (interfaceName) {
                            interfaceName.map((item) => {
                                if (item !== '') {
                                    wanInterfaces.push(item)
                                }
                                return item;
                            })
                        }
                    }
                }
                return value;
            })
            return config;
        })
        let lanData = this.state.QoSData?.QueueConfig?.filter(item => item.type === "lanConfig");
        // EMGT-264 : EM - Rate limiter interface is showing in Qos LAN queuing

        // this.state.QoSData?.RateLimit.map((values) => {
        //     if (lanData.filter(item => item.networkName === values.networkName).length < 1)
        //         lanData.push(values);
        //     return values;
        // })
        lanData?.map((config) => {
            const networks = config.networkName.split(' ');
            networks.map((value) => {
                const network = this.state.networkViewData?.filter(item => item.network_zone === 'lan')?.filter(item => item.networkName === value)[0];
                if (network) {
                    if (network.bridgeStatus === 'Enabled') {
                        lanInterfaces.push(`br-${network.networkName}`)
                    } else {
                        const interfaceName = selectedNetworks?.filter(item => item.networkName === value)[0].interfaceName.split(' ');
                        if (interfaceName) {
                            interfaceName.map((item) => {
                                if (item !== '') {
                                    lanInterfaces.push(item)
                                }
                                return item;
                            })
                        }
                        // lanInterfaces.push(network.networkName)
                    }
                }
                return value;
            })
            return config;
        })
        let tunData = this.state.QoSData?.QueueConfig?.filter(item => item.type === "tunnelConfig");
        tunData?.map((config) => {
            const networks = config.networkName.split(' ');
            networks.map((value) => {
                const network = selectedNetworks?.filter(item => item.networkName === value)[0];
                if (network) {
                    const interfaceName = selectedNetworks?.filter(item => item.networkName === value)[0].interfaceName.split(' ');
                    if (interfaceName) {
                        interfaceName.map((item) => {
                            if (item !== '') {
                                tunnelInterfaces.push(item)
                            }
                            return item;
                        })
                    }
                }
                return value;
            })
            return config;
        })
        this.setState({
            wanInterfaces,
            lanInterfaces,
            tunnelInterfaces
        }, () => this.getInterfaces())
    }
    getInterfaces = () => {
        const options = [];
        let { wanInterfaces, lanInterfaces, tunnelInterfaces } = this.state;
        let interfaces = [];
        switch (this.state.selectedTab) {
            case 'wan':
                interfaces = wanInterfaces;
                break;
            case 'lan':
                interfaces = lanInterfaces;
                break;
            case 'datatunnel':
                interfaces = tunnelInterfaces;
                break;
            default:
                interfaces = wanInterfaces;
        }
        interfaces.map((item) => {
            options.push(<option value={item}>
                {item}
            </option>)
            return item;
        })
        this.setState({
            interfacesOptions: options,
            selectedInterface: interfaces[0],
        }, this.getPollingData());
    }
    getStats = () => {
        // let end_time = Math.round(parseInt(new Date().getTime()) / 1000);
        // let start_time = Math.round(parseInt(new Date(moment(new Date()).subtract(5, "minutes")).getTime()) / 1000);
        // if (this.state.filterApplied) {
        let end_time = Math.round(parseInt(this.state.endTime.getTime()) / 1000);
        let start_time = Math.round(parseInt(this.state.startTime.getTime()) / 1000);
        // }
        if (this.state.selectedInterface) {
            let api_data = {
                device_id: this.state.id,
                interface_name: this.state.selectedInterface,
                start_time,
                end_time
            };
            this.setState({ showLoader: true });

            apiCall(urlMapping.getWanStats(api_data), (response) => {
                this.setState({ showLoader: false, showFilter: false });
                if (response?.success) {
                    if ((this.state.endTime - this.state.startTime) / 60000 < 2) {
                        this.setState({
                            showLoader: false,
                            title: 'QoS Statistics',
                            message: 'QoS refresh interval updated less than 2 minutes back. It might take some time to get the data!',
                            show: true,
                            error: true,
                        }, () => $("#errorMessage").modal("show"))
                    }
                    this.setState({ data: response?.data }, () => {
                        this.graphData();
                        const dummyData = {
                            "link_speed": 0,
                            "bytes": 0,
                            "minrate": "",
                            "pkts_count": 0,
                            "dropped_pkts": 0
                        };
                        // let queue1 = this.state.data.filter(item => item.queue_name === 'q1info');
                        // let linkData1 = queue1[0]?.queue_data;
                        // linkData1.link_speed = queue1.length > 0 && queue1[0]?.link_speed;
                        // let queue2 = this.state.data.filter(item => item.queue_name === 'q2info');
                        // let linkData2 = queue2[0]?.queue_data ;
                        // linkData2.link_speed = queue2.length > 0 && queue2[0]?.link_speed;
                        // let queue3 = this.state.data.filter(item => item.queue_name === 'q3info');
                        // let linkData3 = queue3[0]?.queue_data ;
                        // linkData3.link_speed = queue3.length > 0 && queue3[0]?.link_speed;
                        // let queue4 = this.state.data.filter(item => item.queue_name === 'q4info');
                        // let linkData4 = queue4[0]?.queue_data;
                        // linkData4.link_speed = queue4.length > 0 && queue4[0]?.link_speed;
                        // let linkData5 = {
                        //     "link_speed": 0,
                        //     "bytes": 0,
                        //     "minrate": "",
                        //     "ceilrate": "",
                        //     "pkts_count": 0,
                        //     "dropped_pkts": 0
                        // };
                        // let queue5 = this.state.data.filter(item => item.queue_name === 'q5info');
                        // if (queue5.length > 0) {
                        //     linkData5 = queue5[0]?.queue_data;
                        //     linkData5.link_speed = queue5.length > 0 && queue5[0]?.link_speed;
                        // }

                        let queue1 = this.state.data?.filter(item => item.queue_name === 'q1info');
                        let linkData1 = queue1.length > 0 ? queue1?.[0]?.queue_data : dummyData;
                        linkData1.link_speed = queue1?.length > 0 && queue1[0]?.link_speed;
                        let queue2 = this.state.data?.filter(item => item.queue_name === 'q2info');
                        let linkData2 = queue2?.length > 0 ? queue2?.[0]?.queue_data : dummyData;;
                        linkData2.link_speed = queue2?.length > 0 && queue2?.[0]?.link_speed;
                        let queue3 = this.state?.data.filter(item => item?.queue_name === 'q3info');
                        let linkData3 = queue3.length > 0 ? queue3?.[0]?.queue_data : dummyData;;
                        linkData3.link_speed = queue3?.length > 0 && queue3?.[0]?.link_speed;
                        let queue4 = this.state.data.filter(item => item.queue_name === 'q4info');
                        let linkData4 = queue4?.length > 0 ? queue4?.[0]?.queue_data : dummyData;
                        linkData4.link_speed = queue4?.length > 0 && queue4?.[0]?.link_speed;
                        let linkData5 = {
                            "link_speed": 0,
                            "bytes": 0,
                            "minrate": "",
                            "ceilrate": "",
                            "pkts_count": 0,
                            "dropped_pkts": 0
                        };
                        let queue5 = this.state.data.filter(item => item.queue_name === 'q5info');
                        if (queue5?.length > 0) {
                            linkData5 = queue5?.length > 0 ? queue5?.[0]?.queue_data : dummyData;;
                            linkData5.link_speed = queue5?.length > 0 && queue5?.[0]?.link_speed;
                        }
                        this.setState({
                            linkData1,
                            linkData2,
                            linkData3,
                            linkData4,
                            linkData5
                        })
                    });
                } else {
                    if ((this.state.endTime - this.state.startTime) / 60000 < 2) {
                        this.setState({
                            showLoader: false,
                            title: 'QoS Statistics',
                            message: 'QoS refresh interval updated less than 2 minutes back. It might take some time to get the data!',
                            show: true,
                            error: true,
                        }, () => $("#errorMessage").modal("show"))
                    }
                    if (parseInt(response?.response?.status) === 204 || response?.success === false) {
                        this.setState({
                            data: []
                        })
                    }
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({ showRefreshModal: true })
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            });
        } else {
            this.setState({
                data: []
            })
        }
    }
    getTime = (time) => {
        let date = moment(time * 1000);
        return date.format("HH:mm:ss");
    };

    getLinkData = (queue, clock) => {
        let qName = "q1info";
        switch (queue) {
            case 'Queue1':
                qName = "q1info";
                break;
            case 'Queue2':
                qName = "q2info";
                break;
            case 'Queue3':
                qName = "q3info";
                break;
            case 'Queue4':
                qName = "q4info";
                break;
            case 'Queue5':
                qName = "q5info";
                break;
            default:
                qName = "q1info";
        }
        this.state.data.map((item) => {
            if (item.queue_name === qName && this.getTime(item.clock) === clock) {
                const data = item.queue_data;
                data.link_speed = item.link_speed;
                switch (qName) {
                    case "q1info":
                        this.setState({
                            linkData1: data
                        });
                        break;
                    case "q2info":
                        this.setState({
                            linkData2: data
                        });
                        break;
                    case "q3info":
                        this.setState({
                            linkData3: data
                        });
                        break;
                    case "q4info":
                        this.setState({
                            linkData4: data
                        });
                        break;
                    case "q5info":
                        this.setState({
                            linkData5: data
                        });
                        break;
                    default:
                        this.setState({
                            linkData1: data
                        });
                }
            }
            return item;
        })
    }
    graphData = () => {
        let queue1 = [];
        let queue2 = [];
        let queue3 = [];
        let queue4 = [];
        let queue5 = [];
        let time = [];
        const start = Math.round(this.state.startTime / 1000);
        const end = Math.round(this.state.endTime / 1000);

        queue1 = this.state.data.filter(item => item.queue_name === "q1info");
        queue2 = this.state.data.filter(item => item.queue_name === "q2info");
        queue3 = this.state.data.filter(item => item.queue_name === "q3info");
        queue4 = this.state.data.filter(item => item.queue_name === "q4info");
        queue5 = this.state.data.filter(item => item.queue_name === "q5info");
        const modifiedQ1 = [];
        for (let i = start; i <= end; i++) {
            if (queue1.filter(item => (item.clock) === i).length > 0 ||
                queue2.filter(item => (item.clock) === i).length > 0 ||
                queue3.filter(item => (item.clock) === i).length > 0 ||
                queue4.filter(item => (item.clock) === i).length > 0 ||
                queue5.filter(item => (item.clock) === i).length > 0
            )
                time.push(this.getTime(i));
        }
        time.map((value) => {
            let found = false;
            if (queue1.filter(item => this.getTime(item.clock) === value).length > 0) {
                found = true;
                modifiedQ1.push(queue1.filter(item => this.getTime(item.clock) === value)[0].transmission_rate);
            }
            return value;
        })


        const modifiedQ2 = [];
        time.map((value) => {
            let found = false;
            if (queue2.filter(item => this.getTime(item.clock) === value).length > 0) {
                found = true;
                modifiedQ2.push(queue2.filter(item => this.getTime(item.clock) === value)[0].transmission_rate);
            }
            // if (!found) {
            //     modifiedQ2.push(0);
            // }
            return value;
        })

        const modifiedQ3 = [];
        time.map((value) => {
            let found = false;
            if (queue3.filter(item => this.getTime(item.clock) === value).length > 0) {
                found = true;
                modifiedQ3.push(queue3.filter(item => this.getTime(item.clock) === value)[0].transmission_rate);
            }
            return value;
        })

        const modifiedQ4 = [];
        time.map((value) => {
            let found = false;
            if (queue4.filter(item => this.getTime(item.clock) === value).length > 0) {
                found = true;
                modifiedQ4.push(queue4.filter(item => this.getTime(item.clock) === value)[0].transmission_rate);
            }
            return value;
        })

        const modifiedQ5 = [];
        time.map((value) => {
            let found = false;
            if (queue5.filter(item => this.getTime(item.clock) === value).length > 0) {
                found = true;
                modifiedQ5.push(queue5.filter(item => this.getTime(item.clock) === value)[0].transmission_rate);
            }
            return value;
        })
        const series = this.state.selectedTab === 'wan' ? [
            {
                name: "Queue1",
                data: modifiedQ1,
                color: '#6f03a4'
            },
            {
                name: "Queue2",
                data: modifiedQ2,
                color: '#e06303'
            },
            {
                name: "Queue3",
                data: modifiedQ3,
                color: '#5ad95e'
            },
            {
                name: "Queue4",
                data: modifiedQ4,
                color: '#fe00a8'
            },
            {
                name: "Queue5",
                data: modifiedQ5,
                color: '#fed300'
            },
        ] : [
            {
                name: "Queue1",
                data: modifiedQ1,
                color: '#6f03a4'
            },
            {
                name: "Queue2",
                data: modifiedQ2,
                color: '#e06303'
            },
            {
                name: "Queue3",
                data: modifiedQ3,
                color: '#5ad95e'
            },
            {
                name: "Queue4",
                data: modifiedQ4,
                color: '#fe00a8'
            },
        ]
        this.setState({ series, keyUpdate: Math.random() })
        let obj = this;
        let data1 = {
            chart: {
                type: "line",
                height: 260
            },
            title: {
                text: ""
            },
            credits: {
                enabled: false
            },
            // for menu button enable & disabled
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: "Bandwidth in bits"
                }
            },
            tooltip: {
                enabled: true,
                crosshairs: true,
                formatter: function () {
                    return (this.x + '<br>  <div className="dot-circle">&#9679;</div>' + this.series.name + " " + this.y + "bits");
                }
            },
            xAxis: {
                categories: time,
                type: 'datetime',
                labels: {
                    formatter: function () {
                        return '<a href="' + time[this.value] + '">' + (this.value) + "</a>";
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: "normal"
                },
                series: {
                    // pointStart: time[this.value],
                    // relativeXValue: true,
                    label: {
                        connectorAllowed: false
                    },
                    events: {
                        click: function (e) {
                            obj.getLinkData(e.point.series.name, e.point.category)
                        }
                    },
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 2,
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                }
            },
            legend: {
                align: "right",
                verticalAlign: "top",
                layout: "horizontal",
                x: -20,
                y: -10,
                itemStyle: {
                    fontSize: 10,
                    fontWeight: "normal"
                }
            },
            series: series
        };
        this.setState({ option: data1, keyUpdate: Math.random() });
    }
    tabChange = (tab) => {
        if (tab === 'wan') {
            $("#pills-LAN-queuing-statistics-tab").attr('class', 'nav-link ')
            $("#pills-Tunnel-queueing-statistics-tab").attr('class', 'nav-link')
            $("#pills-WAN-queuing-statistics-tab").attr('class', 'nav-link active')
            $("#pills-LAN-queuing-statistics").attr('class', 'tab-pane fade')
            $("#pills-Tunnel-queueing-statistics").attr('class', 'tab-pane fade')
            $("#pills-WAN-queuing-statistics").attr('class', 'tab-pane fade active show')
        }
        else if (tab === 'lan') {
            $("#pills-WAN-queuing-statistics-tab").attr('class', 'nav-link ')
            $("#pills-Tunnel-queueing-statistics-tab").attr('class', 'nav-link')
            $("#pills-LAN-queuing-statistics-tab").attr('class', 'nav-link active')
            $("#pills-WAN-queuing-statistics").attr('class', 'tab-pane fade')
            $("#pills-Tunnel-queueing-statistics").attr('class', 'tab-pane fade')
            $("#pills-LAN-queuing-statistics").attr('class', 'tab-pane fade active show')
        }
        else {
            $("#pills-WAN-queuing-statistics-tab").attr('class', 'nav-link ')
            $("#pills-LAN-queuing-statistics-tab").attr('class', 'nav-link')
            $("#pills-Tunnel-queueing-statistics-tab").attr('class', 'nav-link active')
            $("#pills-WAN-queuing-statistics").attr('class', 'tab-pane fade')
            $("#pills-LAN-queuing-statistics").attr('class', 'tab-pane fade')
            $("#pills-Tunnel-queueing-statistics").attr('class', 'tab-pane fade active show')
        }
    }
    onChangeTab = (tab) => {
        this.resetData();
        this.setState({
            selectedTab: tab
        }, () => this.getInterfaces())
    }
    onchangeTime = (e) => {
        this.setState({
            endTime: e.value,
            startTime: moment(e.value).subtract(this.state.refreshTime, "seconds")._d,
        })
    }
    resetData = () => {
        this.setState({
            linkData1: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "ceilrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
            linkData2: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "ceilrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
            linkData3: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "ceilrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
            linkData4: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
            linkData5: {
                "link_speed": 0,
                "bytes": 0,
                "minrate": "",
                "ceilrate": "",
                "pkts_count": 0,
                "dropped_pkts": 0
            },
        })
    }
    monthNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    }

    yearNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="ml-2" style={{ lineHeight: 1 }} />;
    }
    refreshStats = () => {
        if (!this.state.showIcon)
            this.setState({
                endTime: new Date(),
            }, () => {
                this.getPollingData();
            })
        else
            this.getStats();

    }
    resetValue = () => {
        this.setState({
            showIcon: false,
            filterApplied: false,
            endTime: new Date()
        }, () => {
            this.getPollingData()
        })
    }

    manageAutoRefresh = (e) => {
        this.setState({ autoRefresh: e.target.checked });
        if (e.target.checked === true) {
            interval = setInterval(() => {
                if (!this.state.showIcon)
                    this.setState({
                        toggleStatus: true,
                        endTime: new Date(),
                    }, () => {
                        this.getPollingData();
                    })
                else
                    this.setState({
                        toggleStatus: true
                    }, () => this.getStats());
            }, 5000);
        } else {
            clearInterval(interval);
            interval = null;
            this.setState({ toggleStatus: false })
        }
    };
    refreshTelemetry = () => {
        this.setState({ showLoader: true });
        let data = {
            device_ip: this.props?.data?.device_control_tunnel_ip,
            key: "QOS",
            device_id: this.state.id
        }
        apiCall(urlMapping.getTelemetry(data), (response) => {
            this.setState({ showLoader: false });
            if (response?.success) {
                this.setState({
                    title: 'Telemetry Refresh',
                    message: response?.message,
                    show: true,
                    error: false,
                }, () => $("#errorMessage").modal("show"))
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    });
                }
            }
        })
    }
    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
                // this.getNetworkView();
            }
        );
    }
    hideDropdown = (e) => {
        var popup = $("#parentDiv");
        if (
            !$("#open").is(e.target) &&
            !popup.is(e.target) &&
            popup.has(e.target).length === 0
        ) {
            this.setState({
                showFilter: false,
                // deviceModel: this.state.showIcon ? this.state.deviceModel : '',
                // modelList: this.state.showIcon ? this.state.modelList : []
            });
        }
    };
    render() {
        return (
            <div
            // onMouseDown={(e) => this.hideDropdown(e)}
            >
                {this.state.showLoader && <Loader />}
                {this.state.fullView ?
                    <>
                        <div className="du-box bg-white h-fill">
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <div>&nbsp;</div>
                                <div className="fwidth" key="fullscreen"
                                    onClick={() => this.setState({ fullView: false }, () => this.tabChange(this.state.selectedTab))}>
                                    <img src="./images/svg-icons/resize.svg" className="view-btn w-26" alt="" />
                                </div>
                            </div>
                            {this.state.status === 0 ?
                                <div className="no-data-qos">Configurations are disabled</div> :
                                (this.state.data.length > 0 ?
                                    <div className="graph-box mt-2"
                                    // key={this.state.keyUpdate}
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={this.state.option}
                                        />
                                    </div>
                                    : <div className="no-data">No data</div>)
                            }
                        </div> </> :
                    <div className="white-bg">
                        <div className="no-bg page-height">
                            <div className='page-header p-0 mb-0'>
                                <ul className="tab-nav nav nav-pills m-0 mb-4" role="tablist">
                                    <li className="nav-item">
                                        <a href="#/" className="nav-link active" data-toggle="pill" role="tab" aria-controls="pills-QoS-Statistics" aria-selected="false">QoS Statistics</a>
                                    </li>
                                </ul>
                                <ul className="tab-nav nav nav-pills m-0" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-WAN-queuing-statistics-tab" data-toggle="pill"
                                            href="#pills-WAN-queuing-statistics" role="tab" aria-controls="pills-WAN-queuing-statistics"
                                            onClick={() => this.onChangeTab("wan")}
                                            aria-selected="true">WAN Queuing Statistics</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-LAN-queuing-statistics-tab" data-toggle="pill"
                                            href="#pills-LAN-queuing-statistics" role="tab" aria-controls="pills-LAN-queuing-statistics"
                                            onClick={() => this.onChangeTab("lan")}
                                            aria-selected="false">LAN Queuing Statistics</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-Tunnel-queueing-statistics-tab" data-toggle="pill"
                                            href="#pills-Tunnel-queueing-statistics" role="tab" aria-controls="pills-Tunnel-queueing-statistics"
                                            onClick={() => this.onChangeTab('datatunnel')}
                                            aria-selected="false">Tunnel Queuing Statistics</a>
                                    </li>
                                </ul>
                                <div className="ta-box set-pos top-0 mb-0">
                                    <div className="action-btn">
                                        <button
                                            className="refreshT border-0"
                                            title="Refresh Telemetry data"
                                            onClick={this.refreshTelemetry}
                                        >
                                            <img
                                                style={{ width: "20px", left: "-3px" }}
                                                src="/images/refreshT.png"
                                                alt=""
                                            />
                                        </button>
                                        <button className="button-lst" onClick={() => this.refreshStats()} disabled={this.state.toggleStatus === true}>
                                            <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
                                        </button>
                                        <div className="btn-group">
                                            <button
                                                type="button"
                                                className="dropdown-toggle  filter-btn button-lst"
                                                disabled={this.state.toggleStatus === true}
                                                onClick={
                                                    (e) => this.setState((prevState) => ({ showFilter: !prevState.showFilter }))
                                                }
                                            >
                                                {(this.state.showIcon) ?
                                                    <img src="/images/svg-icons/info-icon.svg" alt="" />
                                                    :
                                                    <img src="/images/svg-icons/filter.svg" alt="" />
                                                }
                                                Filter
                                            </button>
                                            {this.state.showFilter && <div
                                                className={
                                                    this.state.showFilter
                                                        ? "dropdown-menu show  dropdown-menu-right filter-section width360"
                                                        : "dropdown-menu dropdown-menu-right filter-section width360"
                                                }
                                                id="parentDiv"
                                            >
                                                <div className="form-group">
                                                    <div>
                                                        <label>Start Time</label>
                                                    </div>
                                                    <Calendar
                                                        id="navigatorstemplate"
                                                        value={this.state.startTime}
                                                        className="w-full"
                                                        // onChange={(e) => this.setStartDate(e)}
                                                        showTime
                                                        hourFormat="12"
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        hideOnDateTimeSelect={true}
                                                        yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                                                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                                                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <div>
                                                        <label className='ws-nowrap'>Current time</label>
                                                    </div>
                                                    <Calendar
                                                        id="navigatorstemplate"
                                                        value={this.state.endTime}
                                                        onChange={(e) => this.onchangeTime(e)}
                                                        className="w-full"
                                                        showTime
                                                        hourFormat="12"
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        hideOnDateTimeSelect={true}
                                                        yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                                                    // monthNavigatorTemplate={this.monthNavigatorTemplate}
                                                    // yearNavigatorTemplate={this.yearNavigatorTemplate}

                                                    />
                                                </div>

                                                <div className="action-box">
                                                    <button
                                                        className="btn btn-light"
                                                        onClick={() => this.resetValue()}
                                                    >
                                                        Reset
                                                    </button>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => this.setState({ showIcon: true, filterApplied: true }, () => this.getStats())}
                                                    >
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="switch mr-2 ml-3 z-999">
                                            <input
                                                type="checkbox"
                                                checked={this.state.autoRefresh}
                                                onClick={(e) => this.manageAutoRefresh(e)}
                                            ></input>
                                            <span className="slider round"></span>
                                        </label>
                                        <span>Auto Refresh</span>
                                    </div>
                                </div>
                                <div className="tab-content mt-3 sub-white-bg" id="pills-tabContent">
                                    <div className="row mb-3">
                                        <div className='col-sm-4'>
                                            <div className='d-flex align-items-center'>
                                                <label className='ws-nowrap'>Interface name</label>
                                                <select
                                                    className="form-control "
                                                    onChange={(e) => this.setInterface(e)}
                                                    value={this.state.selectedInterface}
                                                >
                                                    {this.state.interfacesOptions}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade show active" id="pills-WAN-queuing-statistics" role="tabpanel" aria-labelledby="pills-WAN-queuing-statistics-tab">
                                        <div className='d-flex'>
                                            {this.state.status === 0 ?
                                                <div className="no-data-qos">Configurations are disabled</div> :
                                                this.state.data.length > 0 ? <div className="transmission-left-box graph-box">
                                                    <div className='d-flex align-items-center justify-content-between mb-3'>
                                                        {/* <p className='m-0'>
                                                        <strong>Transmission rate in Interface -br-lan</strong>
                                                    </p> */}
                                                        <div>&nbsp;</div>
                                                        <div className="fwidth" key="fullscreen"
                                                            onClick={() => this.setState({ fullView: true })}>
                                                            <img src="./images/svg-icons/fullscreen.svg" className="view-btn" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="graph-box mt-2"
                                                    // key={this.state.keyUpdate}
                                                    >
                                                        <HighchartsReact highcharts={Highcharts}
                                                            options={
                                                                this.state.option
                                                            } /> </div>
                                                </div> : <div className="transmission-left-box graph-box mt-2">
                                                    <div className="no-data">No data</div>
                                                </div>}

                                            {this.state.data.length > 0 && <div className='link-speed-right-box'>
                                                <p className='mb-3'>
                                                    <strong>Link Speed</strong>
                                                    <span className='dg-text ml-4'>{this.state.linkData1.link_speed} Kbps</span>
                                                </p>
                                                <div className='d-flex'>
                                                    <span>&nbsp;</span>
                                                    <span>Queue 1
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - TCP(ACK) DSCP(TOS-MIN-DELAY)' /></span>
                                                    <span>Queue 2
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DNS,SSH,DSCP-EF,DSCP-CS6,DSCP-AF42,Mgmt. Plane PACKET' /></span>
                                                    <span>Queue 3
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DSCP-CS1,UDP' /></span>
                                                    <span>Queue 4
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DEFAULT' /></span>
                                                    <span>Queue 5
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DATA TUNNEL PACKET' /></span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Min Rate</span>
                                                    <span className='dg-text'>{this.state.linkData1?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData5?.minrate}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Ceil Rate</span>
                                                    <span className='dg-text'>{this.state.linkData1?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData5?.ceilrate}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Sent Bytes</span>
                                                    <span className='dg-text'>{this.state.linkData1?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData5?.bytes}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Pkt Counts</span>
                                                    <span className='dg-text'>{this.state.linkData1?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData5?.pkts_count}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Dropped Pkts</span>
                                                    <span className='dg-text'>{this.state.linkData1?.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData2.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData3.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData4.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData5.dropped_pkts}</span>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-LAN-queuing-statistics" role="tabpanel" aria-labelledby="pills-LAN-queuing-statistics-tab">
                                        <div className='d-flex'>
                                            {this.state.data.length > 0 ? <div className="transmission-left-box graph-box">
                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                    {/* <p className='m-0'>
                                                        <strong>Transmission rate in Interface -br-lan</strong>
                                                    </p> */}
                                                    <div>&nbsp;</div>
                                                    <div className="fwidth" key="fullscreen"
                                                        onClick={() => this.setState({ fullView: true })}>
                                                        <img src="./images/svg-icons/fullscreen.svg" className="view-btn" alt="" />
                                                    </div>
                                                </div>
                                                <div className="graph-box mt-2"
                                                // key={this.state.keyUpdate}
                                                >
                                                    <HighchartsReact highcharts={Highcharts}
                                                        options={
                                                            this.state.option
                                                        } /> </div>
                                            </div> : <div className="transmission-left-box graph-box mt-2">
                                                <div className="no-data">No data</div>
                                            </div>}
                                            {this.state.data.length > 0 && <div className='link-speed-right-box'>
                                                <p className='mb-3'>
                                                    <strong>Link Speed</strong>
                                                    <span className='dg-text ml-4'>{this.state.linkData2.link_speed} Kbps</span>
                                                </p>
                                                <div className='d-flex'>
                                                    <span>&nbsp;</span>
                                                    <span>Queue 1
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - TCP(ACK) DSCP(TOS-MIN-DELAY)' /></span>
                                                    <span>Queue 2
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DNS,SSH,DSCP-EF,DSCP-CS6,DSCP-AF42,Mgmt. Plane PACKET' /></span>
                                                    <span>Queue 3
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DSCP-CS1,UDP' /></span>
                                                    <span>Queue 4
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DEFAULT' /></span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Min Rate</span>
                                                    <span className='dg-text'>{this.state.linkData1?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.minrate}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Ceil Rate</span>
                                                    <span className='dg-text'>{this.state.linkData1?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.ceilrate}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Sent Bytes</span>
                                                    <span className='dg-text'>{this.state.linkData1?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.bytes}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Pkt Counts</span>
                                                    <span className='dg-text'>{this.state.linkData1?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.pkts_count}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Dropped Pkts</span>
                                                    <span className='dg-text'>{this.state.linkData1?.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData2.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData3.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData4.dropped_pkts}</span>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-Tunnel-queueing-statistics" role="tabpanel" aria-labelledby="pills-Tunnel-queueing-statistics-tab">
                                        <div className='d-flex'>
                                            {this.state.data.length > 0 ? <div className="transmission-left-box graph-box">
                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                    {/* <p className='m-0'>
                                                        <strong>Transmission rate in Interface -br-lan</strong>
                                                    </p> */}
                                                    <div>&nbsp;</div>
                                                    <div className="fwidth" key="fullscreen"
                                                        onClick={() => this.setState({ fullView: true })}>
                                                        <img src="./images/svg-icons/fullscreen.svg" className="view-btn" alt="" />
                                                    </div>
                                                </div>
                                                <div className="graph-box mt-2"
                                                // key={this.state.keyUpdate}
                                                >
                                                    <HighchartsReact highcharts={Highcharts}
                                                        options={
                                                            this.state.option
                                                        } /> </div>
                                            </div> : <div className="transmission-left-box graph-box mt-2">
                                                <div className="no-data">No data</div>
                                            </div>}
                                            {this.state.data.length > 0 && <div className='link-speed-right-box'>
                                                <p className='mb-3'>
                                                    <strong>Link Speed</strong>
                                                    <span className='dg-text ml-4'>{this.state.linkData2.link_speed} Kbps</span>
                                                </p>
                                                <div className='d-flex'>
                                                    <span>&nbsp;</span>
                                                    <span>Queue 1
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - TCP(ACK) DSCP(TOS-MIN-DELAY)' /></span>
                                                    <span>Queue 2
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DNS,SSH,DSCP-EF,DSCP-CS6,DSCP-AF42,Mgmt. Plane PACKET' /></span>
                                                    <span>Queue 3
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DSCP-CS1,UDP' /></span>
                                                    <span>Queue 4
                                                        <img src="/images/svg-icons/info-button.svg" alt=''
                                                            title='Types of Packets - DEFAULT' /></span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Min Rate</span>
                                                    <span className='dg-text'>{this.state.linkData1?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.minrate}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.minrate}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Ceil Rate</span>
                                                    <span className='dg-text'>{this.state.linkData1?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.ceilrate}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.ceilrate}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Sent Bytes</span>
                                                    <span className='dg-text'>{this.state.linkData1?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.bytes}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.bytes}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Pkt Counts</span>
                                                    <span className='dg-text'>{this.state.linkData1?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData2?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData3?.pkts_count}</span>
                                                    <span className='dg-text'>{this.state.linkData4?.pkts_count}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>Dropped Pkts</span>
                                                    <span className='dg-text'>{this.state.linkData1?.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData2.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData3.dropped_pkts}</span>
                                                    <span className='dg-text'>{this.state.linkData4.dropped_pkts}</span>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>
        );

    }
}
const mapStateToProps = (state) => {
    return ({ authenticated: state.auth.authenticated, open: state.displaySidebarMenu.openSide });
}
export default withRouter(connect(mapStateToProps)(Statistics));