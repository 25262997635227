/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as Action from '../redux/actionTypes';
import urlMapping from "../redux/API/api";
import IntroPage from '../components/userGuide/IntroPage';
import apiCall from '../redux/API/apiCall';
import $ from 'jquery';
import { ErrorPopup } from './errorPopup';
import { Loader } from '../layOuts/Loader';
import ErrorHandlePopup from '../components/ErrorHandlePopup';
import InactivePopup from '../components/login/InactivePopup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from "axios";
import { Link } from 'react-router-dom';
import SystemAlertsPopup from './systemAlertsPopup';
const envURL = process.env.REACT_APP_LOGIN_URL + 'api-user/';
let refreshTokenInterval;
let callInActiveInterval;
class Header extends Component {
    constructor(props) {
        super(props);
        //LONG-1708 - License 5 Device Implementation
        this.state = {
            showRefreshModal: false,
            showIntro: true,
            descriptionData: '',
            showError: true,
            LicenceData: [],
            title: '',
            message: '',
            show: false,
            error: false,
            showLoader: false,
            passwordReset: false,
            validPassword: "",
            validNewPassword: "",
            validConfPassword: "",
            confPassword: "",
            newPassword: "",
            currPassword: "",
            showPassword1: false,
            showPassword2: false,
            showPassword3: false,
            closePopup: false,
            acceptEula: false,
            copied: false,
            showInActive: false,
            email: "",
            isAxiosError: false,
            checkError: false,
            newSystemAlerts: 0,
            showAlerts: false,
            alertsCount: ""
        }
    }
    eulaFile = "/documents/_CLICKWRAPLongshipEULA.pdf";
    //LONG-1708 - License 5 Device Implementation
    async componentDidMount() {
        this.checkSession();
        await this.checkLicenseValidity();
        this.getSystemAlertsCount();
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps?.count) {
            this.setState({ newSystemAlerts: nextProps?.count })
        }
    };

    getSystemAlertsCount = () => {
        apiCall(urlMapping.getSystemAlertsCount(), (response) => {
            if (response?.Success) {
                if (response?.data) {
                    let { newSystemAlerts } = this.state;
                    newSystemAlerts =
                        (response?.data?.new_memory_alerts || 0) +
                        (response?.data?.new_cpu_alerts || 0) +
                        (response?.data?.new_hard_disc_alerts || 0)
                        + (response?.data?.new_user_login_alerts || 0)
                        + (response?.data?.new_certificate_alerts | 0);
                    this.setState({ newSystemAlerts });
                    if (this.props.history.location.pathname !== '/troubleshoot') {
                        if (
                            (!this.props.ignoreMemory && parseInt(response?.data?.new_memory_alerts) > 0) ||
                            (!this.props.ignoreCPU && parseInt(response?.data?.new_cpu_alerts) > 0) ||
                            (!this.props.ignoreHardDisc && parseInt(response?.data?.new_hard_disc_alerts) > 0 ||
                                (parseInt(response?.data?.new_certificate_alerts) > 0)
                            )
                            // || (!this.props.ignoreUserLogin && parseInt(response?.data?.new_user_login_alerts) > 0)
                        ) {
                            this.setState({
                                title: 'Attention!',
                                message: 'Host system alerts need to be resolved!',
                                showAlerts: true,
                                alertsCount: response?.data,
                            }, () => $("#systemAlerts").modal("show"));
                        }
                    }
                }
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showErrorModal: true })
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    };

    //EMGT-322-EM page is getting blank
    checkSession = () => {
        const refresh_token = localStorage.getItem('refresh');
        const acces_token = localStorage.getItem('token')
        if (!refresh_token && !acces_token) {
            this.logOut();
        }
    };

    //LONG-1708 - License 5 Device Implementation
    checkLicenseValidity = async () => {
        await apiCall(urlMapping.checkLicenseValidity(), (response) => {
            if (response?.success) {
                this.setState({
                    showLicense: response?.data?.is_activated,
                    organization_id: response?.data?.organization_id
                })
                if (response?.data) {
                    this.setState({
                        passwordReset: response?.data?.is_default_user_password_changed
                    }
                        , () => {
                            if (response?.data?.is_default_user_password_changed === true)
                                $("#resetPassword").modal("hide");
                            else
                                $("#eulaAggrement").modal("show");
                        }
                    )
                }
                this.callInActive()
            }
            else {
                this.setState({
                    showLicense: false
                })
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    };

    callInActive = () => {
        localStorage.setItem("lastActiveTime", new Date().getTime());
        this.refreshToken();
        callInActiveInterval = setInterval(() => {
            function updateLastActiveTime() {
                localStorage.setItem("lastActiveTime", new Date().getTime());
            }
            window.onkeypress = updateLastActiveTime;
            window.onkeydown = updateLastActiveTime;
            window.onmousemove = updateLastActiveTime;
            window.onmousedown = updateLastActiveTime;
            window.onclick = updateLastActiveTime;
            window.ontouchstart = updateLastActiveTime;
            window.ontouchmove = updateLastActiveTime;
            window.ontouchend = updateLastActiveTime;
            window.onwheel = updateLastActiveTime;
            window.onscroll = updateLastActiveTime;
            window.onresize = updateLastActiveTime;
            window.onfocus = updateLastActiveTime;
            window.onblur = updateLastActiveTime;
            if (parseInt(new Date().getTime() - 600000) > parseInt(localStorage.getItem("lastActiveTime"))) {
                if (!localStorage.getItem("GUAC_AUTH")) {
                    clearInterval(refreshTokenInterval);
                    clearInterval(callInActiveInterval);
                    this.setState({
                        showInActive: true
                    })
                }
            }
        }, 30000);
    };

    refreshToken = () => {
        refreshTokenInterval = setInterval(() => {
            if (parseInt(localStorage.getItem("lastActiveTime")) > parseInt(new Date().getTime() - 300000)) {
                const refresh_token = localStorage.getItem('refresh');
                if (refresh_token) {
                    axios.post(`${envURL}token/refresh/`, { refresh: refresh_token })
                        .then((response) => {
                            if (response?.data?.access) {
                                localStorage.setItem('token', response?.data?.access);
                            }
                        })
                        .catch(() => {
                        });
                }
            }
        }, 300000)
    };

    passwordChange = () => {
        // Change password popup not visible on one click
        this.setState({
            closePopup: true,
            validPassword: "",
            validNewPassword: "",
            validConfPassword: "",
            email: "",
            confPassword: "",
            newPassword: "",
            currPassword: "",
            showPassword1: false,
            showPassword2: false,
            showPassword3: false,
        }, () => $("#resetPassword").modal("show"))
    };

    closePopup = () => {
        this.setState({
            closePopup: false
        })
        $("#resetPassword").modal("hide")
    };

    logOut = () => {
        const refresh_token = localStorage.getItem('refresh');
        const data = {
            refresh: refresh_token
        }
        clearInterval(refreshTokenInterval);
        clearInterval(callInActiveInterval);
        apiCall(urlMapping.logout(data), (response) => {
            if (response?.success) {
                this.props.dispatch({
                    type: Action.LOGOUT,
                });
                localStorage.clear();
                sessionStorage.clear();
                const modalElement = $(".modal");
                const backdropElement = $(".modal-backdrop");
                if (modalElement.length > 0) {
                    modalElement.modal("hide");
                }
                if (backdropElement.length > 0) {
                    backdropElement.remove();
                }
                this.props.history.push("/");
            } else {
                this.setState({
                    title: 'Network Error',
                    message: 'Something went wrong. Please try again later.',
                    showLoader: false,
                    isAxiosError: true,
                    delete: false,
                    error: true
                }, () => $("#errorMessage").modal("show"))
            }
        }, (error) => {
            this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
            }, () => $("#errorMessage").modal("show"));
        }
        );
    };

    //LONG-1708 - License 5 Device Implementation
    cancelLicense = () => {
        this.setState({
            pfxFile: undefined,
            copied: false
        })
    };

    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            pfxFile: undefined,
            showInActive: false,
        }, () => {
            $("#errorMessage").modal("hide");
        })
    };

    getSystemAlertsPage = () => {
        this.setState({
            show: false,
            showAlerts: false,
            error: false,
            delete: false,
            showInActive: false,
        }, () => {
            $("#systemAlerts").modal("hide");
            this.props.history.push({
                pathname: '/troubleshoot',
                state: { selectedTab: 'SystemAlerts', tabChange: true, newAlerts: true },
            })
        })
    };

    handleIgnore = (memory, cpu, hardDisc, userLogin) => {
        this.props.dispatch({
            type: Action.IGNORE_MEMORY,
            payload: memory
        });
        this.props.dispatch({
            type: Action.IGNORE_CPU,
            payload: cpu
        });
        this.props.dispatch({
            type: Action.IGNORE_HARD_DISC,
            payload: hardDisc
        });
        this.props.dispatch({
            type: Action.IGNORE_USER_LOGIN,
            payload: userLogin
        });
    };

    resetPopup3 = () => {
        this.setState({
            showInActive: false,
            showLoader: false
        }, () => this.checkLicenseValidity())
    };

    networkError = () => {
        this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
        }, () => $("#errorMessage").modal("show"));
    }

    //LONG-1708 - License 5 Device Implementation
    pfxFileHandleSelect = (e) => {
        this.setState({ pfxFile: e.target.files[0] })
    };

    //LONG-1708 - License 5 Device Implementation
    saveActivateLicense = () => {
        const formData = new FormData();
        formData.append("encrypted_pfx_file", this.state.pfxFile);
        this.setState({
            showLoader: true,
        });
        apiCall(urlMapping.validateLicence(formData), (response) => {
            if (response?.success) {
                $("#errorMessage").modal("hide");
                this.setState({
                    LicenceData: response?.data,
                    pfxFile: undefined,
                    showLoader: false,
                    title: 'Activate License',
                    message: response?.message,
                    show: true,
                    error: false,
                }, () => {
                    this.checkLicenseValidity();
                    $("#errorMessage").modal("show");
                })
            }
            else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        showLoader: false,
                        title: 'Activate License',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    };

    callIntro = () => {
        this.setState({
            showIntro: false
        })
    };

    //LONG-1708 - License 5 Device Implementation
    handleDescription = (e) => {
        this.setState({ descriptionData: e.target.value });
    };

    setValue = () => {
        setTimeout(() => {
            this.setState({
                showIntro: true
            })
        }, 100)
    };

    handleProceed = () => {
        $("#eulaAggrement").modal("hide");
        $("#resetPassword").modal("show");
    };

    //   LONG-3935 : License App || UUID on Popup
    //EMGT-399-EM - Not able to copy the Machine ID While activating Licence
    copyUUID = () => {
        this.setState({
            copied: true
        });
    };

    setEmail = (e) => {
        this.removeError(e)
        this.setState({
            email: (e.target.value).trim()
        })
    };

    setPassWords = (e) => {
        this.removeError(e)
        if (e.target.name === "newPass") {
            const element = document.getElementById("confPass");
            if (element) {
                element.style.display = "none";
                this.setState({
                    checkError: false
                });
            }
        }
        let name = e.target.name
        let password = (e.target.value).trim()
        if (name === "currPass") {
            this.setState({
                currPassword: password
            })
        }
        else if (name === "newPass")
            this.setState({
                newPassword: password
            })
        else {
            this.setState({
                confPassword: password
            })
        }
    };

    validateEmail = (e) => {
        if (e.target.value !== "") {
            if ((! /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))) {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({
                    checkError: true,
                });
            }
            else {
                this.removeError(e);
            }
        } else {
            this.removeError(e);
        }
    };

    validatePassword = (e) => {
        let { validPassword } = this.state;
        let { validNewPassword } = this.state;
        let { validConfPassword } = this.state;
        const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,512}$");
        const valid = regex.test(e.target.value);
        if (e.target.name === "currPass") {
            if (e.target.value.trim() === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                validPassword = 'Password cannot be empty!';
                this.setState({
                    checkError: true,
                    validPassword
                });
            }
        }
        else if (e.target.name === "newPass") {
            if (e.target.value !== "") {
                if (!!!valid) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = "block";
                    validNewPassword = 'Password must contain atleast 8 characters, 1 special character, 1 number, 1 uppercase and 1 lowercase!';
                    this.setState({
                        checkError: true,
                        validNewPassword
                    });
                }
                else if (this.state.confPassword !== "") {
                    if (this.state.confPassword !== this.state.newPassword) {
                        const element = document.getElementById("confPass");
                        element.style.display = "block";
                        validNewPassword = 'Confirm password does not match new password';
                        this.setState({
                            checkError: true,
                            validConfPassword
                        });
                    }
                    else {
                        this.removeError(e)
                    }
                }
                else {
                    this.removeError(e)
                }
            }
            else {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                validNewPassword = 'Password cannot be empty!';
                this.setState({
                    checkError: true,
                    validNewPassword
                });
            }
        }
        else {
            if (this.state.confPassword !== this.state.newPassword) {
                const element = document.getElementById("confPass");
                element.style.display = "block";
                validConfPassword = 'Confirm password does not match new password';
                this.setState({
                    checkError: true,
                    validConfPassword
                });
            }
            else {
                this.removeError(e)
            }
        }
        if (this.state.email !== "") {
            if ((! /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
                const element = document.getElementById('email');
                element.style.display = "block";
                this.setState({
                    checkError: true
                });
            }
        }
    };

    showPass = (passName) => {
        const x = document.getElementById(passName);
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (passName === "showCurrPass") {
            this.setState(prevState => ({
                showPassword1: !prevState.showPassword1
            }));
        }
        else if (passName === "showNewPass") {
            this.setState(prevState => ({
                showPassword2: !prevState.showPassword2
            }));
        }
        else {
            this.setState(prevState => ({
                showPassword3: !prevState.showPassword3
            }));
        }
    };

    removeError = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = "none";
            this.setState({
                checkError: false,
            });
        }
    };

    checkError = () => {
        let result = false;
        // EMGT-2516 - Checked error text for disabling confirm button
        const elements = document.getElementsByClassName('error-txt reset');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.checkError)
            result = true;
        if (this.state.currPassword === "" ||
            this.state.newPassword === "" ||
            this.state.confPassword === "" ||
            this.state.confPassword !== this.state.newPassword) {
            result = true;
        }
        return result
    };

    setPassword = () => {
        let data = {
            old_password: this.state.currPassword,
            new_password1: this.state.newPassword,
            new_password2: this.state.confPassword,
            email: this.state.email
        }
        $("#resetPassword").modal("hide");
        this.setState({
            showLoader: true,
        });
        apiCall(urlMapping.changePassword(data), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    title: 'Reset Password',
                    message: 'Password has been set. Please login with the new password.',
                    response: response,
                    show1: true,
                    error: false,
                }, () => {
                    $("#errorMessage").modal("show");
                });
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    let msg = "";
                    Object.keys(response?.response?.data?.message).map((item) => {
                        if (item) {
                            msg = response?.response?.data?.message?.[item]?.[0]
                        }
                    })
                    this.setState({
                        showLoader: false,
                        title: 'Reset Password',
                        message: msg,
                        show1: true,
                        delete: false,
                        error: true,
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    };

    resetPopup1 = () => {
        this.setState({
            show1: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
            this.logOut();
        })
    };

    resetPopup2 = () => {
        this.setState({
            show1: false,
            confPassword: "",
            newPassword: "",
            currPassword: "",
            email: ""
        }, () => {
            $("#errorMessage").modal("hide");
            $("#resetPassword").modal("show")
        })
    };



    render() {
        const { isSuperAdmin } = this.props
        return (
            <header className="header">
                {this.state.showLoader && <Loader />}
                {
                    <div className="container-fluid px-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="logo-box">
                                <img src="/images/logo-color.png" alt="" />
                                <div className="logo-txt">5G SD EDGE Manager</div>
                            </div>
                            <div className='d-flex'>
                                <div className="d-flex align-items-center">
                                    {
                                        (this.state?.showLicense === false)
                                            ? <span className='license-btn'>Your License is not active!
                                                {(this.props?.userRole !== "Viewer_Group" &&
                                                    this.props?.userRole !== "Operator_Group") &&
                                                    <button className='bg-none' data-toggle="modal" data-target=".licence"
                                                        onClick={() => this.setState({ pfxFile: undefined })} >
                                                        &nbsp;Click here to Activate!</button>}
                                            </span>
                                            : " "
                                    }
                                </div>
                                {/* <div className="d-flex align-items-center">
                                    {isSuperAdmin ?
                                        <span className='license-btn'>

                                            <button className='bg-none'
                                                onClick={this.startWizard} >
                                                Start Installation Wizard</button>
                                        </span>
                                        : " "}
                                </div> */}
                                <div className="d-flex align-items-center">
                                    <h1 className="page-title" title='SD Edge Manager host system alerts'>Host System Alerts</h1>
                                    {this.props.history.location.pathname !== '/troubleshoot' ? <ul className="alarms-list">
                                        <li>
                                            <Link to={{
                                                pathname: '/troubleshoot',
                                                state: { selectedTab: 'SystemAlerts', tabChange: true, newAlerts: true }
                                            }}
                                            >
                                                <h1 className='page-title'>{this.state.newSystemAlerts}</h1>
                                            </Link>
                                        </li>
                                    </ul> : <h1 className='page-title'>
                                        {this.state.newSystemAlerts}</h1>}
                                    <div className="action d-flex align-items-center justify-content-between">
                                        <div className="user-box d-flex align-items-center">
                                            &nbsp; <span>{
                                                this.props?.userRole === "Viewer_Group"
                                                    ? ("Viewer")
                                                    : this.props?.userRole === "Operator_Group"
                                                        ? ("Operator")
                                                        : isSuperAdmin
                                                            ? ("Super Admin")
                                                            : ("Admin")
                                            }
                                            </span>
                                            <span className="short-name" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">A</span>
                                            <i className="d-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                            <div className="user-list dropdown-menu">
                                                <ul>
                                                    <li onClick={() => this.logOut()}><img src="/images/svg-icons/exit.svg" alt="" /> Logout</li>
                                                    <li className={this.props.user === 'ma_admin' ? "disabled" : ""} onClick={(this.props.user !== '' && this.props.user !== 'ma_admin') ?
                                                        () => this.passwordChange() : undefined}><img src="/images/svg-icons/password.svg" alt="" /> Change Password</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <img className="help-icon" title="User Guide" data-toggle="modal" data-target=".bd-example-modal-lg" src="/images/svg-icons/help.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* user guide html */}
                {
                    <div className="modal fade bd-example-modal-lg" id="usermodal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg user-guide-popup">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"><img src="/images/logo.png" alt="" /></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setValue()}>
                                        <img src="/images/svg-icons/cancel-white.svg" alt="" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {/* first steps */}
                                    {this.state.showIntro ?
                                        <div className="intero-page">
                                            <div className="blue-bg">
                                                <h1 className="welcome">
                                                    Welcome <small>To Quick Start Guide</small>
                                                </h1>
                                                <p>Welcome to Inseego SD-WAN Solutions. This Setup Wizard helps you to add devices, configure templates and apply them to the devices. Please skip the process if you are familiar with the configurations, else click on "Let's Get Started" to go over the process.</p>
                                                <img className="down-arrow" src="/images/down-arrow.png" alt="" />
                                            </div>
                                            <div className="white-bg text-center p-5">
                                                <button className="btn btn-primary" onClick={() => this.callIntro()}>Let's Get Started</button>
                                            </div>
                                        </div>
                                        :
                                        <IntroPage props={this.state.showIntro} callIntro={() => this.setValue()} />}
                                    {/* close first steps */}
                                    <div className="overview-section">
                                        <div className="tab-content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* user guide html close */}

                {/* Licence html */}
                {
                    <div className="modal fade licence" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">License</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.cancelLicense()}>
                                        <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {/* LONG-3935 : License App || UUID on Popup */}
                                    <div className="form-group">
                                        <label>Please share this Machine ID with Inseego Inc to get a valid license</label>
                                        <input type="text" id="UUID" className="form-control"
                                            name="UUID"
                                            value={this.state.organization_id}
                                        />
                                        <div className="sh-icon">
                                            {this.state.copied === false ?
                                                <div className="copy-icon">
                                                    {/* EMGT-399-EM - Not able to copy the Machine ID While activating Licence */}
                                                    <CopyToClipboard text={this.state.organization_id}
                                                        onCopy={() => this.copyUUID()}>
                                                        <img className="copy" src="/images/svg-icons/copy.svg" alt=""
                                                        />
                                                    </CopyToClipboard>
                                                    <div className="tooltip">Copy Machine ID</div>
                                                </div>
                                                :
                                                <div className="copy-icon">
                                                    <img className="copy" src="/images/svg-icons/tick.svg" alt="" />
                                                    <div className="tooltip hideme">Copied</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='form-group mb-0'>
                                        <label>Add SIG File</label>
                                        <div className="drag-box">
                                            <input className="f1" type="file" accept={".sig"}
                                                onClick={(e) => e.target.files[0] && this.pfxFileHandleSelect(e)}
                                                onChange={(e) => this.pfxFileHandleSelect(e)} />
                                            <div className="csv-file">
                                                <img src="../images/svg-icons/outbox.svg" alt="" />
                                                <span>Click here to upload a SIG file</span>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.pfxFile !== undefined && <div className="droped-files">
                                        <span>{this.state.pfxFile.name}</span>
                                        <img className="delete-icon" src="/images/svg-icons/delete-red.svg" alt="" onClick={() => this.setState({ pfxFile: undefined })} />
                                    </div>}
                                    <div>
                                        {/* Condition for checking if pfx is uploaded */}
                                        {(this.state.pfxFile !== undefined &&
                                            !this.state.pfxFile.name.includes('.sig')) &&
                                            <label className='license-btn'>Please upload a SIG file!!</label>
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="reset" onClick={() => this.cancelLicense()} className="btn btn-light" data-dismiss="modal">Cancel</button>
                                    {/* Condition for checking if pfx is uploaded and then disabling the submit button */}
                                    <button type="button" onClick={() => this.saveActivateLicense()} disabled={(this.state.pfxFile === undefined || !this.state.pfxFile.name.includes('.sig'))} className="btn btn-primary" data-dismiss="modal">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* Licence html close */}

                {/* reset password  */}
                {
                    (this.state.passwordReset === false || this.state.closePopup) &&
                    <div>
                        <div className={this.state.passwordReset === false ? "modal fade show" : "modal fade"} id="resetPassword" tabIndex="-1" role="dialog" data-backdrop="static"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog  modal-sm modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <h5 className="modal-title">Reset Password</h5>
                                        {this.state.closePopup &&
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => this.closePopup()}>
                                                <img src="/images/svg-icons/cancel.svg" alt="" height="15px" width="15px" />
                                            </button>}
                                    </div>
                                    <div className="modal-body">
                                        <div className='form-group'>
                                            <label>Email</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name="email"
                                                autocomplete="off"
                                                value={this.state.email}
                                                onChange={(e) => this.setEmail(e)}
                                                onBlur={(e) => this.validateEmail(e)}
                                            />
                                            <div className="error-txt reset" id="email">Please enter a valid Email id</div>
                                        </div>
                                        <div className='form-group'>
                                            <label>Current Password</label>
                                            <span className="red-txt">*</span>
                                            <input
                                                type='password'
                                                className='form-control showIconSpacing'
                                                name="currPass"
                                                id="showCurrPass"
                                                value={this.state.currPassword}
                                                onChange={(e) => this.setPassWords(e)}
                                                // onChange={(e) => this.setState({ currPassword: e.target.value })}
                                                onBlur={(e) => this.validatePassword(e)}
                                            />
                                            <div className='sh-icon'>
                                                <img src={this.state.showPassword1 ? "/images/show.png" : "/images/hide.png"} alt=""
                                                    onClick={() => this.showPass("showCurrPass")} />
                                            </div>
                                            <div className="error-txt reset" id="currPass">{this.state.validPassword}</div>
                                        </div>
                                        <div className='form-group'>
                                            <label>New Password</label>
                                            <span className="red-txt">*</span>
                                            <input
                                                type='password'
                                                autocomplete="off"
                                                className='form-control showIconSpacing'
                                                name="newPass"
                                                id="showNewPass"
                                                value={this.state.newPassword}
                                                onChange={(e) => this.setPassWords(e)}
                                                onBlur={(e) => this.validatePassword(e)}
                                            />
                                            <div className='sh-icon'>
                                                <img src={this.state.showPassword2 ? "/images/show.png" : "/images/hide.png"} alt=""
                                                    onClick={() => this.showPass("showNewPass")} />
                                            </div>
                                            <div className="error-txt reset" id="newPass">{this.state.validNewPassword}</div>
                                        </div>
                                        <div className='form-group'>
                                            <label>Confirm Password</label>
                                            <span className="red-txt">*</span>
                                            <input
                                                type='password'
                                                className='form-control showIconSpacing'
                                                autocomplete="off"
                                                name="confPass"
                                                id="showConfPass"
                                                value={this.state.confPassword}
                                                onChange={(e) => this.setPassWords(e)}
                                                onBlur={(e) => this.validatePassword(e)}
                                            />
                                            <div className='sh-icon'>
                                                <img src={this.state.showPassword3 ? "/images/show.png" : "/images/hide.png"} alt=""
                                                    onClick={() => this.showPass("showConfPass")} />
                                            </div>
                                            <div className="error-txt reset" id="confPass">{this.state.validConfPassword}</div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {this.state.closePopup &&
                                            <button type="button" className="btn btn-light" data-dismiss="modal"
                                                onClick={() => this.closePopup()}>Cancel</button>
                                        }
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => this.setPassword()}
                                            disabled={this.checkError()}
                                        >Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* reset password close  */}

                {/* EULA Aggrement */}
                {
                    <div>
                        <div
                            className={"modal fade"}
                            id="eulaAggrement"
                            tabIndex="-1"
                            role="dialog"
                            data-backdrop="static"
                            aria-hidden="true"
                        >
                            <div
                                className="modal-dialog modal-lg modal-dialog-centered"
                                role="document"
                            >
                                <div className="modal-content modal-lg-content">
                                    <div className="modal-header border-0">
                                        <h5 className="modal-title">EULA Agreement</h5>
                                    </div>
                                    <div className="modal-body lg-body">
                                        <div style={{ height: '100%' }}>
                                            <iframe
                                                src={`${window.location.origin}${this.eulaFile}`}
                                                title="eula"
                                                height="100%"
                                                width="100%"
                                            />
                                            <div className="form-check flex">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={this.state.acceptEula}
                                                    onChange={(e) =>
                                                        this.setState({ acceptEula: e.target.checked })
                                                    }
                                                    checked={this.state.acceptEula}
                                                />
                                                <label className="form-check-label ">
                                                    I acknowledge that I have read, and do hereby accept the agreement.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={this.handleProceed}
                                            disabled={!this.state.acceptEula}
                                        >
                                            Proceed
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* EULA Aggrement Close */}

                {
                    (this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {
                    (this.state.show1) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={(this.state.error) ? () => this.resetPopup2() : () => this.resetPopup1()}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {
                    (this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {
                    this.state.showInActive && <InactivePopup
                        onSubmit={() => this.resetPopup3()}
                        networkError={() => this.networkError()}
                    />
                }
                {
                    this.state.showAlerts &&
                    <SystemAlertsPopup
                        title={this.state.title}
                        message={this.state.message}
                        ignoreMemory={this.props?.ignoreMemory}
                        ignoreCPU={this.props?.ignoreCPU}
                        ignoreHardDisc={this.props?.ignoreHardDisc}
                        ignoreUserLogin={this.props?.ignoreUserLogin}
                        count={this.state.alertsCount}
                        ignore={(memory, cpu, hardDisc, userLogin) => this.handleIgnore(memory, cpu, hardDisc, userLogin)}
                        redirect={() => this.getSystemAlertsPage()}
                    />
                }
            </header >
        )
    }
};

const mapStateToProps = state => {
    return ({
        open: state.displaySidebarMenu.openSide,
        logoutResult: state.auth.authenticated,
        isSuperAdmin: state.auth.isSuperAdmin,
        user: state.auth.user,
        ignoreMemory: state.displaySystemAlerts.ignoreMemory,
        ignoreCPU: state.displaySystemAlerts.ignoreCPU,
        ignoreHardDisc: state.displaySystemAlerts.ignoreHardDisc,
        ignoreUserLogin: state.displaySystemAlerts.ignoreUserLogin,
        userRole: String(state.auth.role)
    });
};

export default withRouter(connect(mapStateToProps)(Header));