import React, { Component } from 'react'
import { Loader } from '../../layOuts/Loader';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import DataTable from "react-data-table-component";
import apiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import moment from 'moment';
import { Link } from 'react-router-dom';
import WiFiView from './ViewTemplates/WiFiView';
import OSPFView from './AddTemplates/OSPF/OSPFView';
import StaticTemplate from './AddTemplates/StaticRoute/StaticTemplate';
import WanConfig from './AddTemplates/WanTemplates/WanConfig';
import WanConfigCR from './AddTemplates/WanTemplates/WanConfigCR';
import BGPView from './AddTemplates/BGP/BGPView';
import NetworkConfiguration from './AddTemplates/NetworkTemplates/NetworkConfiguration';
import FirewallView from './Firewall/FirewallView';
import ErrorHandlePopup from '../ErrorHandlePopup';
import QOSView from './AddTemplates/QOS/QOSView';
import WAView from './AddTemplates/WarningAlertsConfig/WAView';
import * as Common from '../../../src/utils/common';
import DNSView from './AddTemplates/DNS/DNSView';
import SystemTemplateView from './AddTemplates/SystemTemplate/SystemTemplateView';
import HubSpokeTemplate from './AddTemplates/Hub&Spoke/HubSpokeTemplate';
import ModemTemplate from './AddTemplates/modemTemplate/ModemTemplate';
import Select from 'react-select';
import CaptivePortalView from './AddTemplates/CaptivePortal/CaptivePortalView';
import IPSecTemplate from './AddTemplates/IPSec/IPSecTemplate';
import $ from 'jquery';
import { ErrorPopup } from '../../layOuts/errorPopup';
import DeviceConfiguration from './AddTemplates/DeviceConfiguration/DeviceConfiguration';
class TemplateInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoader: false,
            templateId: "",
            templateDetails: this.props.location.state ? this.props.location.state.data : {},
            dropVal: [],
            deviceListWithId: [],
            isAxiosError: false,
            appliedDeviceCol: [
                {
                    name: "Device Name  ",
                    selector: "device_name",
                    value: "Device Name ",
                    sortable: true,
                    cell: (row) => (<span title={row.device_name}>
                        <Link to={{
                            pathname: '/devicedetails',
                            state: { data: row, DeviceList: this.state.deviceListWithId, back: true, default: this.props.location.state.default }
                        }}
                            className="txt-blue">{row.device_name}</Link>

                    </span>
                    ),
                },

                {
                    name: " Model No",
                    selector: "device_model",
                    value: "Model No",
                    sortable: true,
                    cell: (row) => (<span title={row.device_model}>
                        {row.device_model}</span>
                    ),
                },
                {
                    name: " MAC Address ",
                    selector: "device_mac",
                    value: "Mac Address",
                    sortable: true,
                    cell: (row) => (<span title={row.device_mac.toUpperCase()}>
                        {row.device_mac.toUpperCase()}</span>
                    ),
                },
            ],

            appliedDeviceGroupCol: [
                {
                    name: "Group Name  ",
                    selector: "group_name",
                    value: "Group Name ",
                    sortable: true,
                    cell: (row) => (<span title={row.group_name}>
                        <Link to={{
                            pathname: '/devicegroupdetails',
                            state: {
                                data: row,
                                back: true,
                                editTunnelSelectedTab: undefined,
                                editTunnel_group_id: undefined
                            }
                        }}
                            className="txt-blue">{row.group_name}</Link>

                    </span>
                    ),
                },

                {
                    name: " Model No",
                    selector: "device_model",
                    value: "Model No",
                    sortable: true,
                    cell: (row) => (<span title={row.supported_device_models}>
                        {row.supported_device_models}</span>
                    ),
                },
            ],
        }

    }
    componentDidMount() {
        if (this.props.location.state) {
            let model = Common.getModels(this.props.location.state.data.device_model);
            this.setState({
                selectedModelCopy: model,
            })
            if (this.props.location.state && this.props.location.state.default && this.props.location.state.default === true) {
                this.bootstrapTemplateDropdownList();
            }
            else {
                this.templateDropdownList();
            }
            this.setState({
                templateId: this.props.location.state.data.id,
            }, () => this.getTemplateInfo())
        }
    }
    templateDropdownList = () => {
        this.setState({
            showLoader: true
        })
        apiCall(urlMapping.templatesDropdown(), (response) => {
            this.setState({
                showLoader: false
            })
            if (response?.success === true) {
                this.setState({
                    dropVal: response?.data
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    bootstrapTemplateDropdownList = () => {
        this.setState({
            showLoader: true
        })
        apiCall(urlMapping.bootstrapTemplatesDropdown(), (response) => {
            this.setState({
                showLoader: false
            })
            if (response?.success === true) {
                this.setState({
                    dropVal: response?.data
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    getTemplateInfo = () => {
        // Fix to get model when changed the device type from dropdown in bootstrap templates
        this.setState({ showLoader: true, dropdownClicked: false })
        let data = { template_id: this.state.templateId }
        apiCall(urlMapping.templateInfoById(data), (response) => {
            if (response?.success) {
                this.setState({
                    templateDetails: response?.data, showLoader: false, dropdownClicked: true,
                    selectedModel: Common.getModels((response?.data?.device_model)?.split(',')?.[0])
                }, () => this.getDeviceDetails())
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({ showLoader: false })
                }
            }
        })
    }
    getDeviceDetails = () => {
        let data = {}
        // eslint-disable-next-line array-callback-return
        Array.isArray(this.state.templateDetails.attached_devices) && this.state.templateDetails.attached_devices.map((item) => {
            data = { id: item.device_id, name: item.device_name }
            this.state.deviceListWithId.push(data)
        })
    }
    OnDropChange = (e) => {
        this.setState({ templateId: e.value }, () => this.getTemplateInfo())
    }
    getOptions = () => {
        let options = [];
        options = this.state.dropVal.sort((a, b) => a.template_name.localeCompare(b.template_name)).map((item) => {
            return { label: item.template_name, value: item.id }
        })
        options = options.filter(item => !item.label.includes('DeviceProvision'))
        return options;
    }
    filterOptions = (option, inputValue) => {
        const { label } = option;
        const otherKey = this.getOptions().filter(
            (opt) => opt.label.toLowerCase() === label.toLowerCase() && opt.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return label.toLowerCase().includes(inputValue.toLowerCase()) || otherKey.length > 0;
    }
    getTime = (time) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format('DD MMMM YYYY hh:mm:ss A');
    }
    render() {
        return (
            <div>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <Header />
                    <div className="no-bg page-height">
                        <div className="page-header bg d-flex align-items-center"

                        >
                            <div className="back-page-icon"
                                onClick={() => this.props.history.push(
                                    {
                                        pathname: this.props.location.state.default ? '/bootstrapTemplates' : '/templates',
                                        state: {
                                            searchValue: this.props?.location?.state?.searchValue,
                                            filterValue: this.props?.location?.state?.filterValue
                                        }
                                    }
                                )
                                }>
                                <img src="/images/svg-icons/back-page.svg" alt="" title="Back" />
                            </div>
                            <Select options={this.getOptions()}
                                filterOption={(o, i) => this.filterOptions(o, i)}
                                isSearchable
                                className="filter-dropdown"
                                classNamePrefix="react-select-container"
                                value={
                                    this.getOptions().filter(option =>
                                        option.value === this.state.templateId)
                                }
                                onChange={(e) => this.OnDropChange(e)}
                            />
                        </div>
                        <div className="bg-white radius-spacing">
                            <h1 className="page-title text-blue mb-2">Template Info</h1>
                            <div className="row">
                                <div className="col-md-2 col-sm-4">
                                    <div className="di-list">
                                        <small>Template Name</small>
                                        <span className="text-truncate d-block">{this.state.templateDetails.template_name}</span>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-4">
                                    <div className="di-list">
                                        <small>Feature Template</small>
                                        <span className="text-truncate d-block">{this.state.templateDetails.template_type}</span>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-4">
                                    <div className="di-list">
                                        <small>Device Attached</small>
                                        <span className="text-truncate d-block">{this.state.templateDetails.device_attached}</span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-4">
                                    <div className="di-list">
                                        <small>Created On</small>
                                        <span className="text-truncate d-block">{this.getTime(this.state.templateDetails.created_date)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-7 col-sm-12">
                                <div className="bg-white radius-spacing mt-2 mr-1 h-100">
                                    <h1 className="page-title text-blue mb-2">Feature Template </h1>
                                    <div className="table-height-auto">
                                        {
                                            this.state.templateDetails.template_type === "WiFiConfiguration" && <WiFiView
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                link={false}
                                                info={true}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {
                                            this.state.templateDetails.template_type === "StaticRoute" &&
                                            <div className="staticroute-table"><StaticTemplate
                                                templateData={this.state.templateDetails}
                                                info={true}
                                                link={false}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            /></div>
                                        }
                                        {this.state.templateDetails.template_type === "OSPFConfiguration" &&
                                            <OSPFView
                                                templateData={this.state.templateDetails}
                                                info={true}
                                                link={false}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {this.state.templateDetails.template_type === "BGPConfiguration" &&
                                            <BGPView
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                info={true}
                                                link={false}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {
                                            this.state.templateDetails.template_type === "NetworkConfiguration" &&

                                            <NetworkConfiguration
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                link={false}
                                                info={true}
                                                interfaces={[]}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {/* LONG-3168 : EM-Interface mismatch in edit and view page for WAN bootstrap template in CR */}
                                        {
                                            (this.state.templateDetails.template_type === "WANConfiguration" && this.props.location.state.model !== 'vCPE') && <WanConfig
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                info={true}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {
                                            (this.state.templateDetails.template_type === "WANConfiguration" && this.props.location.state.model === 'vCPE') && <WanConfigCR
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                info={true}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {this.state.templateDetails.template_type === "FirewallConfiguration" &&
                                            <FirewallView
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                info={true}
                                                link={false}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {this.state.templateDetails.template_type === "QoSConfiguration" &&
                                            <QOSView
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                info={true}
                                                link={false}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {this.state.templateDetails.template_type === "TriggerConfiguration" &&
                                            <WAView
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                info={true}
                                                link={false}
                                                // Trigger Configuration View & Edit issue fix
                                                selectedModel={!this.state.dropdownClicked ? this.props.location.state.model ?
                                                    this.props.location.state.model : this.state.selectedModelCopy : this.state.selectedModel} template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {this.state.templateDetails.template_type === "DNSFiltering" &&
                                            <DNSView
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                info={true}
                                                link={false}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {this.state.templateDetails.template_type === "SystemConfiguration" &&
                                            <SystemTemplateView
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                info={true}
                                                link={false}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {
                                            (this.state.templateDetails.template_type === "TunnelConfiguration" && this.state.templateDetails.hub_details) &&
                                            <HubSpokeTemplate
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                link={false}
                                                info={true}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {
                                            (this.state.templateDetails.template_type === "ModemConfiguration") &&
                                            <ModemTemplate
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                link={false}
                                                info={true}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                                selectedModelList={this.state.selectedModel}
                                            />
                                        }
                                        {
                                            (this.state.templateDetails.template_type === "CaptivePortal") &&
                                            <CaptivePortalView
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                link={false}
                                                info={true}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {
                                            this.state.templateDetails.template_type === "IPSecConfiguration" &&
                                            <IPSecTemplate
                                                templateData={this.state.templateDetails}
                                                link={false}
                                                info={true}
                                                interfaces={[]}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                            />
                                        }
                                        {
                                            this.state.templateDetails.template_type === "DeviceConfiguration" &&
                                            <DeviceConfiguration
                                                templateData={this.state.templateDetails}
                                                view={true}
                                                info={true}
                                                template_schema_json={this.state.templateDetails.template_schema_json}
                                                selectedModelList={this.state.selectedModel}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <div className="bg-white radius-spacing mt-2 ml-1">
                                    <h1 className="page-title text-blue mb-2">Applied Devices</h1>
                                    <div className="applied-device-table">
                                        <DataTable
                                            columns={this.state.appliedDeviceCol}
                                            data={this.state.templateDetails.attached_devices}
                                        />
                                    </div>
                                </div>
                                <div className="bg-white radius-spacing mt-2 ml-1">
                                    <h1 className="page-title text-blue mb-2">Applied Device Group</h1>
                                    <div className="applied-device-table">
                                        <DataTable
                                            columns={this.state.appliedDeviceGroupCol}
                                            data={this.state.templateDetails.attached_group_list}
                                        />
                                    </div>
                                    <div className="applied-device-scroll-bar">

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
    });
}
export default withRouter(connect(mapStateToProps)(TemplateInfo));
