import React, { Component } from "react";
import { connect } from "react-redux";
class ConfigurePF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gateway: '',
            hostname: '',
            dns_servers: [],
        }
    }
    static getDerivedStateFromProps = (props, state) => {
        if (props.gateway) {
            state.gateway = props.gateway;
            state.hostname = props.hostname;
            state.dns_servers = props.dns;
            state.interfaceData = props.interfaceData
        }
        return state;
    }
    render() {
        return (
            <div className='network-configuration'>
                <div className='step3' id="configure">
                    <div className=''>
                        <h1 className="page-title">Start Configuration</h1>
                        <span>
                            Review the configuration and start the Packetfence</span>
                        <div className='form-group align-items-center d-flex'>
                            <label className="col-sm-3 text-right">Default Gatway</label>
                            <input
                                type="text"
                                className='form-control col-sm-9'
                                placeholder=''
                                readOnly
                                value={this.state.gateway ? this.state.gateway : ""} />
                        </div>
                        <div className='form-group align-items-center d-flex'>
                            <label className="col-sm-3 text-right">Server Hostname</label>
                            <input
                                type="text"
                                className='form-control col-sm-9'
                                placeholder=''
                                readOnly
                                value={this.state.hostname ? this.state.hostname : ""} />
                        </div>
                        <div className='form-group align-items-center d-flex'>
                            <label className="col-sm-3 text-right">DNS Server</label>
                            <select className='form-control col-sm-9' disabled>
                                {this.state.dns_servers?.map((server) => {
                                    return (<option value={server}>{server}</option>)
                                })}
                            </select>
                        </div>
                        <div className='form-group align-items-center d-flex'>
                            <label className="col-sm-3 text-right">Username</label>
                            <input
                                type="text"
                                className='form-control col-sm-9'
                                placeholder=''
                                readOnly value="admin" />
                        </div>
                    </div>
                </div>
                <div className="s-footer">
                    <button
                        className="btn btn-primary"
                        disabled={this.props.configuring}
                        onClick={() => {
                            this.props.startConfigure()
                        }}>Start</button>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
}
export default connect(mapStateToProps)(ConfigurePF);
