/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Loader } from '../../../../layOuts/Loader';
import * as Common from "../../../../utils/common";
import * as Action from "../../../../redux/actionTypes";
class QOSView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplateId: this.props?.id ? this.props?.id : "",
      template_schema_json: {
        "Enabled": "1",
        "restartInt": "24",
        "QueueConfig": [{
          "type": "wanConfig",
          "name": 'wanConfig1',
          "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
          "networkName": "",
          "speed": "100",
          'speedtest': '0',
          'wanBandwidth': '',
          "setmark": "p123-AF42,p53-AF42",
          "rate1": "10",
          "ceil1": "30",
          "priority1": "1",
          "config1": "tcp-ack tos-min-delay",
          "rate2": "20",
          "ceil2": "50",
          "priority2": "2",
          "config2": "dscp-36 dscp-46 dscp-48 port-22 port-53 control_tunnel",
          "rate3": "30",
          "ceil3": "100",
          "priority3": "3",
          "config3": "dscp-8 udp",
          "rate4": "35",
          "ceil4": "100",
          "priority4": "4",
          "config4": "default",
          "rate5": "5",
          "ceil5": "100",
          "priority5": "0",
          "config5": "data_tunnel",
          "is_deleted": "0"
        },
        {
          "type": "lanConfig",
          "name": 'lanConfig1',
          "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
          "networkName": "",
          "speed": "100",
          'speedtest': '0',
          'wanBandwidth': '',
          "setmark": "",
          "rate1": "10",
          "ceil1": "30",
          "priority1": "1",
          "config1": "tcp-ack tos-min-delay",
          "rate2": "20",
          "ceil2": "50",
          "priority2": "2",
          "config2": "dscp-36 dscp-46 dscp-48 port-22 port-53",
          "rate3": "30",
          "ceil3": "100",
          "priority3": "3",
          "config3": "dscp-8 udp",
          "rate4": "40",
          "ceil4": "100",
          "priority4": "4",
          "config4": "default",
          "is_deleted": ""
        },
        {
          "type": "tunnelConfig",
          "name": 'tunnelConfig1',
          "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
          "networkName": "",
          "speed": "100",
          'speedtest': '0',
          'wanBandwidth': '',
          "setmark": "",
          "rate1": "10",
          "ceil1": "30",
          "priority1": "1",
          "config1": "tcp-ack tos-min-delay",
          "rate2": "20",
          "ceil2": "50",
          "priority2": "2",
          "config2": "dscp-36 dscp-46 dscp-48 port-22 port-53",
          "rate3": "30",
          "ceil3": "100",
          "priority3": "3",
          "config3": "dscp-8 udp",
          "rate4": "40",
          "ceil4": "100",
          "priority4": "4",
          "config4": "default",
          "is_deleted": ""
        }],
        "RateLimit": [{
          "type": "lanRateLimit",
          "name": "lanRateLimit1",
          "uuid": Math.random().toString(36).substring(2, 11).toUpperCase(),
          "networkName": "",
          "speed": "100",
          "srcIPs": "0.0.0.0/0",
          "is_deleted": "0"
        }]
      },
      networkViewData: [],
      availableNetworks: [],
      availableLanRateNetworks: []
    };
  }
  componentDidMount() {
    if (this.props.template_schema_json !== undefined) {
      this.setState({
        template_schema_json: this.props.template_schema_json,
      }, () => {
        if (this.props.apply) {
          if (this.props.networkViewData) {
            let { networkViewData } = this.props;
            let { availableNetworks } = this.state;
            let { availableLanRateNetworks } = this.state;
            let { template_schema_json } = this.state;
            template_schema_json.RateLimit?.map((item) => {
              item.networkName?.split(' ')?.map((network) => {
                availableLanRateNetworks = availableLanRateNetworks.filter(value => value.networkName !== network)
              })
            })
            template_schema_json.QueueConfig?.map((item) => {
              item.speedtest = item?.speedtest || '0';
              item.wanBandwidth = item?.speedtest || '';
              item.networkName?.split(' ')?.map((network) => (
                availableNetworks = availableNetworks.filter(value => value.networkName !== network)
              ));
            })
            template_schema_json.Enabled = template_schema_json?.Enabled || '1'
            this.setState({
              template_schema_json,
              availableNetworks: networkViewData,
              networkViewData,
              availableLanRateNetworks: networkViewData.filter(item => item.network_zone === 'lan')
            })
          }
        }
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.id) {
      this.setState({
        selectedTemplateId: nextProps?.id ? nextProps?.id : "",
      });
    }
    if (nextProps.networkViewData && nextProps.template_schema_json) {
      let { networkViewData } = nextProps;
      //EMGT-156-EM - Qos Page field validation
      let template_schema_json = {
        ...nextProps.template_schema_json,
      }
      template_schema_json.QueueConfig.map((item) => {
        item.speedtest = item?.speedtest || '0';
        item.wanBandwidth = item?.speedtest || '';
      })
      template_schema_json.Enabled = template_schema_json?.Enabled || '1'
      this.setState({
        template_schema_json,
        networkViewData,
        availableNetworks: networkViewData,
        availableLanRateNetworks: networkViewData
      })
    }
  }
  getNetworkName = (name) => {
    const network = this.state.networkViewData?.filter(item => item.networkName === name)?.[0];
    let networkName = ''
    if (network)
      networkName = network.name;
    return networkName;
  }
  handleChange = (e, row) => {
    const { template_schema_json } = this.state;
    let { availableNetworks } = this.state;
    let { availableLanRateNetworks } = this.state;
    if (e.target.checked) {
      if (row.type === 'lanRateLimit') {
        template_schema_json.RateLimit?.map((item) => {
          if (item.uuid === row.uuid) {
            item.networkName = item.networkName === '' ? e.target.id : item.networkName + ' ' + e.target.id;
            availableLanRateNetworks = availableLanRateNetworks?.filter(value => value.networkName !== e.target.id)
          }
          return item;
        })
      } else {
        template_schema_json.QueueConfig?.map((item) => {
          if (item.uuid === row.uuid) {
            item.networkName = item.networkName === '' ? e.target.id : item.networkName + ' ' + e.target.id;
            availableNetworks = availableNetworks?.filter(value => value.networkName !== e.target.id)
          }
          return item;
        })
      }
    } else {
      if (row.type === 'lanRateLimit') {
        template_schema_json.RateLimit?.map((item) => {
          if (item.uuid === row.uuid) {
            // LONG-2179 : Fix for unable to delete the interface name in Qos for Rate Limiter
            const networks = item.networkName?.split(' ');
            item.networkName = networks?.filter(value => value !== e.target.id)?.join(' ');
            const network = this.state.networkViewData?.filter(item => item.networkName === e.target.id)?.[0];
            if (network)
              availableLanRateNetworks.push(network)
          }
          return item;
        })
      } else {
        template_schema_json.QueueConfig?.map((item) => {
          if (item.uuid === row.uuid) {
            const networks = item.networkName?.split(' ');
            item.networkName = networks?.filter(value => value !== e.target.id)?.join(' ');
            const network = this.state.networkViewData?.filter(item => item.networkName === e.target.id)[0];
            if (network) {
              const alreadyPresent = availableNetworks?.find(i => i?.networkName === network?.networkName)
              if (!alreadyPresent)
                availableNetworks.push(network)
            }
          }
          return item;
        })
      }
    }
    this.setState({
      template_schema_json,
      availableNetworks,
      availableLanRateNetworks
    })
  }
  checkError = () => {
    let result = false;
    if (this.props.applying)
      result = true;
    this.state.template_schema_json?.RateLimit?.map((item) => {
      if (item.networkName === '')
        result = true;
    })
    this.state.template_schema_json?.QueueConfig?.map((item) => {
      if (item.networkName === '')
        result = true;
    })
    // LONG-2992 : Without selecting Interface name able to save the Qos Configuration  
    this.state.template_schema_json?.QueueConfig?.map(item => {
      if (item.networkName === '')
        result = true;
    })
    this.state.template_schema_json?.RateLimit?.map(item => {
      if (item.networkName === '')
        result = true;
    })
    return result;
  }
  render() {
    return (
      <div className="QOS-view-box">
        {this.state.showLoader && <Loader />}
        <div className="d-flex align-items-center">
          <span className="mr-5"><strong>Enable QoS</strong></span>
          <label className="switch gray ml-3"><input type="checkbox" checked={this.state.template_schema_json?.Enabled === '1'} />
            <span className="slider round" style={{ cursor: "not-allowed" }}></span></label>
        </div>
        <div className="d-flex mt-2 mb-2">
          <span className="mr-5 mt-1">Restart Interval</span>
          <input
            className={"form-control w-1 disabled"}
            type="name"
            name="restartInt"
            value={this.state.template_schema_json?.restartInt} />
          <span style={{ marginTop: "8px", marginLeft: "5px" }}>hr</span>
        </div>
        <div className={this.state.template_schema_json?.Enabled === '1' ? "gray-bg rounded p-3" : "gray-bg rounded p-3 disabled"}>
          <div className="page-sub-title mb-1 mt-0">
            <strong>WAN Configuration</strong>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="name">Name</th>
                <th className="inte-name">Interface Name<span className="red-txt">*</span></th>
                <th className="name">WAN Bandwidth</th>
                <th>Uplink Speed</th>
                <th>Advance Options</th>
              </tr>
            </thead>
            <tbody>
              {this.state.template_schema_json.QueueConfig?.filter(item => item.type === 'wanConfig')?.map((row) => {
                return (
                  <tr>
                    <td>{row?.name}</td>
                    <td>
                      {!this.props.view && !this.props.reapply ?
                        <div className="dropdown">
                          <ul className="chip-list z-index-9 radius">
                            {row.networkName?.split(' ')?.map((item) => {
                              if (item && this.getNetworkName(item))
                                return <li>{this.getNetworkName(item)}{this.getNetworkName(item) && <img src="/images/svg-icons/cancel.svg" id={item}
                                  onClick={(e) => this.handleChange(e, row)} alt="" />}</li>
                            })}
                          </ul>
                          <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          </div>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <ul>{
                              this.state.availableNetworks?.filter(item => item.network_zone === 'wan')?.map((item) => {
                                return <li >
                                  <input type="checkbox" id={item.networkName} checked={row.networkName?.split(' ')?.includes(item.networkName)}
                                    onClick={(e) => this.handleChange(e, row)} />
                                  {item.name}</li>
                              })
                            }
                            </ul>
                          </div>
                        </div>
                        : <div>
                          {row.networkName?.split(' ')?.map((item) => {
                            if (item)
                              return <li>{this.getNetworkName(item)}</li>
                          })}</div>}
                    </td>
                    <td>{row?.speedtest === "1" ? 'Based on speedtest' : (row?.wanBandwidth === '' ? 'Based on Default Speed' : `${row?.wanBandwidth} MB`)}</td>
                    <td>{row?.speed}%</td>
                    <td>
                      <div className="gray-bg">
                        <ul className="table-list">
                          <li className="head-list">
                            <span>Name</span>
                            <span>Min Rate</span>
                            <span>Ceil Rate</span>
                            <span>Priority</span>
                            <span>Config</span>
                          </li>
                          <li className="body-list">
                            <span>Queue1</span>
                            <span>{row?.rate1}%</span>
                            <span>{row?.ceil1}%</span>
                            <span>{row?.priority1}</span>
                            <span>{Common.getConfig(row?.config1)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue2</span>
                            <span>{row?.rate2}%</span>
                            <span>{row?.ceil2}%</span>
                            <span>{row?.priority2}</span>
                            <span>{Common.getConfig(row?.config2)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue3</span>
                            <span>{row?.rate3}%</span>
                            <span>{row?.ceil3}%</span>
                            <span>{row?.priority3}</span>
                            <span>{Common.getConfig(row?.config3)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue4</span>
                            <span>{row?.rate4}%</span>
                            <span>{row?.ceil4}%</span>
                            <span>{row?.priority4}</span>
                            <span>{Common.getConfig(row?.config4)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue5</span>
                            <span>{row?.rate5}%</span>
                            <span>{row?.ceil5}%</span>
                            <span>{row?.priority5}</span>
                            <span>{Common.getConfig(row?.config5)}</span>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>)
              })}
            </tbody>
          </table>
          <div className="page-sub-title mb-3">
            <strong>LAN Configuration</strong>
          </div>
          <p className="mb-2"><strong>Rate Limiter</strong></p>
          <table className="table">
            <thead>
              <tr>
                <th className="name">Name</th>
                <th className="inte-name">Interface Name<span className="red-txt">*</span></th>
                <th>Uplink Speed</th>
                <th>Source IP</th>
              </tr>
            </thead>
            <tbody>
              {this.state.template_schema_json?.RateLimit?.map((row) => {
                return (
                  <tr>
                    <td>{row.name}</td>
                    <td>
                      {!this.props.view && !this.props.reapply ?
                        <div className="dropdown">
                          <ul className="chip-list z-index-9 radius">
                            {row.networkName?.split(' ')?.map((item) => {
                              if (item && this.getNetworkName(item))
                                return <li>{this.getNetworkName(item)}{this.getNetworkName(item) && <img src="/images/svg-icons/cancel.svg" id={item}
                                  onClick={(e) => this.handleChange(e, row)} alt="" />}</li>
                            })}
                          </ul>
                          <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          </div>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <ul>{
                              this.state.availableLanRateNetworks?.filter(item => item.network_zone === 'lan')?.map((item) => {
                                return <li >
                                  <input type="checkbox" id={item.networkName} checked={row.networkName?.split(' ')?.includes(item.networkName)}
                                    onClick={(e) => this.handleChange(e, row)} />
                                  {item.name}</li>
                              })
                            }
                            </ul>
                          </div>
                        </div>
                        : <div>
                          {row.networkName?.split(' ')?.map((item) => {
                            if (item)
                              return <li>{this.getNetworkName(item)}</li>
                          })}</div>}
                    </td>
                    <td>{row?.speed}%</td>
                    <td>{row?.srcIPs}</td>
                  </tr>)
              })}
            </tbody>
          </table>
          <p className="mb-2 mt-3"><strong>LAN Queuing</strong></p>
          <table className="table">
            <thead>
              <tr>
                <th className="name">Name</th>
                <th className="inte-name">Interface Name<span className="red-txt">*</span></th>
                <th>Downlink Speed</th>
                <th>Advance Options</th>
              </tr>
            </thead>
            <tbody>
              {this.state.template_schema_json.QueueConfig?.filter(item => item.type === 'lanConfig')?.map((row) => {
                return (
                  <tr>
                    <td>{row.name}</td>
                    <td>
                      {!this.props.view && !this.props.reapply ?
                        <div className="dropdown">
                          <ul className="chip-list z-index-9 radius">
                            {row.networkName?.split(' ')?.map((item) => {
                              if (item && this.getNetworkName(item))
                                return <li>{this.getNetworkName(item)}{this.getNetworkName(item) &&
                                  <img src="/images/svg-icons/cancel.svg" id={item}
                                    onClick={(e) => this.handleChange(e, row)} alt="" />}</li>
                            })}
                          </ul>
                          <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          </div>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <ul>{
                              this.state.availableNetworks?.filter(item => item.network_zone === 'lan')?.map((item) => {
                                return <li >
                                  <input type="checkbox" id={item.networkName} checked={row.networkName?.split(' ')?.includes(item.networkName)}
                                    onClick={(e) => this.handleChange(e, row)} />
                                  {item.name}</li>
                              })
                            }
                            </ul>
                          </div>
                        </div>
                        : <div>
                          {row.networkName?.split(' ')?.map((item) => {
                            if (item)
                              return <li>{this.getNetworkName(item)}</li>
                          })}</div>}
                    </td>
                    <td>{row?.speed}%</td>
                    <td>
                      <div className="gray-bg">
                        <ul className="table-list">
                          <li className="head-list">
                            <span>Name</span>
                            <span>Min Rate</span>
                            <span>Ceil Rate</span>
                            <span>Priority</span>
                            <span>Config</span>
                          </li>
                          <li className="body-list">
                            <span>Queue1</span>
                            <span>{row?.rate1}%</span>
                            <span>{row?.ceil1}%</span>
                            <span>{row?.priority1}</span>
                            <span>{Common.getConfig(row?.config1)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue2</span>
                            <span>{row?.rate2}%</span>
                            <span>{row?.ceil2}%</span>
                            <span>{row?.priority2}</span>
                            <span>{Common.getConfig(row?.config2)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue3</span>
                            <span>{row?.rate3}%</span>
                            <span>{row?.ceil3}%</span>
                            <span>{row?.priority3}</span>
                            <span>{Common.getConfig(row?.config3)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue4</span>
                            <span>{row?.rate4}%</span>
                            <span>{row?.ceil4}%</span>
                            <span>{row?.priority4}</span>
                            <span>{Common.getConfig(row?.config4)}</span>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>)
              })}
            </tbody>
          </table>
          <div className="page-sub-title mb-1">
            <strong>Wireguard Configuration</strong>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="name">Name</th>
                <th className="inte-name">Interface Name<span className="red-txt">*</span></th>
                <th>Uplink Speed</th>
                <th>Advance Options</th>
              </tr>
            </thead>
            <tbody>
              {this.state.template_schema_json.QueueConfig?.filter(item => item?.type === 'tunnelConfig')?.map((row) => {
                return (
                  <tr>
                    <td>{row.name}</td>
                    <td>
                      {!this.props.view && !this.props.reapply ?
                        <div className="dropdown">
                          <ul className="chip-list z-index-9 radius">
                            {row.networkName?.split(' ')?.map((item) => {
                              if (item && this.getNetworkName(item))
                                return <li>{this.getNetworkName(item)}{this.getNetworkName(item) && <img src="/images/svg-icons/cancel.svg" id={item}
                                  onClick={(e) => this.handleChange(e, row)} alt="" />}</li>
                            })}
                          </ul>
                          <div className="chip-box add-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          </div>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <ul>{
                              this.state.availableNetworks.filter(item => item.network_zone === 'datatunnel' && item.ip_address !== '').map((item) => {
                                return <li >
                                  <input type="checkbox" id={item.networkName} checked={row.networkName?.split(' ')?.includes(item.networkName)}
                                    onClick={(e) => this.handleChange(e, row)} />
                                  {item.name}</li>
                              })
                            }
                            </ul>
                          </div>
                        </div>
                        : <div>
                          {row.networkName?.split(' ')?.map((item) => {
                            if (item)
                              return <li>{this.getNetworkName(item)}</li>
                          })}</div>}
                    </td>
                    <td>{row?.speed}%</td>
                    <td>
                      <div className="gray-bg">
                        <ul className="table-list">
                          <li className="head-list">
                            <span>Name</span>
                            <span>Min Rate</span>
                            <span>Ceil Rate</span>
                            <span>Priority</span>
                            <span>Config</span>
                          </li>
                          <li className="body-list">
                            <span>Queue1</span>
                            <span>{row?.rate1}%</span>
                            <span>{row?.ceil1}%</span>
                            <span>{row?.priority1}</span>
                            <span>{Common.getConfig(row?.config1)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue2</span>
                            <span>{row?.rate2}%</span>
                            <span>{row?.ceil2}%</span>
                            <span>{row?.priority2}</span>
                            <span>{Common.getConfig(row?.config2)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue3</span>
                            <span>{row?.rate3}%</span>
                            <span>{row?.ceil3}%</span>
                            <span>{row?.priority3}</span>
                            <span>{Common.getConfig(row?.config3)}</span>
                          </li>
                          <li className="body-list">
                            <span>Queue4</span>
                            <span>{row?.rate4}%</span>
                            <span>{row?.ceil4}%</span>
                            <span>{row?.priority4}</span>
                            <span>{Common.getConfig(row?.config4)}</span>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>)
              })}
            </tbody>
          </table>
          {!this.props.info && !this.props.view &&
            (
              <div className="s-footer">
                {/* LONG-2989-EM-When Open the Device Qos Template and click the Cancel button it was redirecting to the device Qos Bootstatp template page */}
                <button
                  className="btn btn-light"
                  onClick={(this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeApplyTemplate() :
                    () => this.props.closeAddTemplate()}
                >
                  {this.props.apply && !this.props.reapply ? "Back" : "Cancel"}
                </button>
                <button
                  className="btn btn-primary"
                  disabled={this.checkError()}
                  onClick={
                    this.props.apply
                      ? () => {
                        this.props.dispatch({
                          type: Action.SET_APPLY_TEMPLATE,
                        });
                        this.props.applyTemplate(this.state.template_schema_json);
                        this.setState({ applied: true });
                      }
                      : ""
                  }
                >
                  {this.props.apply
                    ? "Apply"
                    : this.state.selectedTemplateId !== ""
                      ? "Update"
                      : "Save"}
                </button>
              </div>
            )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    applying: state.displayAddTemplate.applying,
    openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
  };
};
export default connect(mapStateToProps)(QOSView);