/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { Loader } from "../../../layOuts/Loader";
import * as Action from "../../../redux/actionTypes";
import StaticTemplate from "./StaticRoute/StaticRoute";
import WanConfig from "./WanTemplates/WanConfig";
import WiFiTemplate from "./WiFiTemplate";
import * as Common from '../../../utils/common';
import NetworkConfiguration from "./NetworkTemplates/NetworkConfiguration";
import OSPFTemplate from "./OSPF/OSPFTemplate";
import BGPTemplate from "./BGP/BGPTemplate";
import FirewallTemplate from "../Firewall/FirewallTemplate";
import WATemplate from "./WarningAlertsConfig/WATemplate";
import QOSTemplate from "./QOS/QOSTemplate";
import ErrorHandlePopup from "../../ErrorHandlePopup";
import HubSpokeTemplate from "./Hub&Spoke/HubSpokeTemplate";
import WanConfigCR from "./WanTemplates/WanConfigCR";
import DNSTemplate from "./DNS/DNSTemplate";
import SystemTemplate from "./SystemTemplate/SystemTemplate";
import ModemTemplate from "./modemTemplate/ModemTemplate";
import DeviceConfiguration from "./DeviceConfiguration/DeviceConfiguration";
import CaptivePortal from "./CaptivePortal/CaptivePortal";
import IPSecTemplate from "./IPSec/IPSecTemplate";
import $ from 'jquery';
import { ErrorPopup } from "../../../layOuts/errorPopup";
class AddEditTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      showLoader: false,
      data: {
        template_type: "",
        template_name: "",
        template_desc: "",
        device_model: [],
        collection_version: "",
        fw_version: 1.0,
        default_type: ''
      },
      keyUpdate: "",
      template_schema_json: undefined,
      modelData: "",
      moduleData: [],
      id: "",
      dataTunnel: "",
      isAxiosError: false,
      selectedModelList: this.props?.selectedModel ? this.props?.selectedModel : null
    };
  }
  componentWillUnmount() {
    this.setState({
      data: {
        template_type: "",
        template_name: "",
        template_desc: "",
        device_model: [],
        collection_version: "",
        fw_version: 1.0,
        default_type: ""
      },
      keyUpdate: "",
      template_schema_json: undefined,
      modelData: [],
    });
  }
  componentDidMount() {
    if (this.props.templateData) {
      const data = this.props.templateData.device_model;
      this.setState({
        id: this.props.templateData.id,
        selectedModelList: this.props?.selectedModel ? this.props?.selectedModel : null,
        data: {
          template_type: this.props.templateData.template_type,
          template_name: this.props.templateData.template_name,
          template_desc: this.props.templateData.template_desc,
          device_model: data,
          collection_version: this.props.templateData.collection_version,
          fw_version: this.props.templateData.fw_version,
          default_type: this.props.templateData.default_type
        },
        template_schema_json: this.props.templateData.template_schema_json,
        modelData: data,
        template_type: this.props.template_type ? this.props.template_type : ''
      }, () => this.getModulesData());
    } else {
      this.setState({
        template_type: this.props.template_type ? this.props.template_type : ''
      }, () => this.getModulesData())
    }
  }
  getModulesData = () => {
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.getModuleList(), (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.success) {
        const { data } = this.state;
        data.collection_version = response?.data?.collection_version;
        this.setState({
          data,
          moduleData: response?.data?.supported_modules,
          chimay_templates_version: response?.data?.chimay_templates_version,
          cbr_templates_version: response?.data?.cbr_templates_version,
          mifi_templates_version: response?.data?.mifi_templates_version,
        }, () => {
          if (this.props?.template_type) {
            this.getModules();
            this.getModels();
          }
        });
      } else {
        if (parseInt(response?.response?.status) === 401) {
          this.setState({ showRefreshModal: true })
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };
  setTemplateValues = (e, flag) => {
    if (flag === "default")
      this.setState({ selectedModelList: null })
    this.removeError(e);
    let value = (e.target.value).trim();
    if (e.target.name === "template_type") {
      this.setState({
        template_schema_json: undefined
      })
      this.getModels(e);
    }
    if (value === "" && e.target.name !== "template_desc") {
      const element = document.getElementById(e.target.name);
      element.style.display = "block";
      this.setState({
        showError: true,
      });
    } else {
      let data = this.state.data;
      if (e.target.name === "device_model") {
        this.props.dispatch({
          type: Action.SET_DEVICE_MODEL,
          payload: value,
        });
      }
      if (data) {
        Object.keys(data).map((key) => {
          if (key === e.target.name) {
            data[key] = value === "select" ? "" : value;
          }
        });
        data[e.target.name] = value === "select" ? "" : value;
      }
    }
  };
  removeError = (e) => {
    const element = document.getElementById(e.target.name);
    if (element) element.style.display = "none";
    this.setState({
      showError: false,
    });
  };
  getModules = () => {
    let modules = this.state.moduleData;
    if (Object.keys(modules).length > 0) {
      const Options = Object.keys(modules).map((key) => {
        return <option value={key}>{Common.getTemplateType(key)}</option>;
      });
      return Options;
    }
  };
  getModels = (e) => {
    let modules = this.state.moduleData;
    let modelData = '';
    let { data } = this.state;
    if (Object.keys(modules).length > 0) {
      Object.keys(modules).map(key => {
        let type = e ? e.target.value : this.state.template_type;
        if (key === type) {
          modelData = modules[key].toString();
          data.device_model = modules[key];
        }
      });
    }
    this.setState({
      modelData,
      data
    })
  }
  checkName = (e) => {
    if ((e.target.value).trim() === '') {
      const element = document.getElementById(e.target.name);
      element.style.display = "block";
      this.setState({
        showError: true,
      });
    }
  }
  handleItemClick = (item) => {
    this.setState({ selectedModelList: this.state.selectedModelList === item ? null : item });
  };

  render() {
    return (
      <div>
        {this.state.showLoader && <Loader />}
        <div className={this.props.openAddTemplate ? "slide-overlay open" : "slide-overlay"}></div>
        <div className={this.props.openAddTemplate ? "sidebar-slide w-750 open" : "sidebar-slide w-750"}>
          <div className="s-header">
            <h1 className="ss-title">{this.props.templateData && this.props.templateData.id !== '' ? 'Edit Template' : 'New Template'}</h1>
            <button onClick={() => this.props.closeAddTemplate()}>
              <img src="/images/svg-icons/s-cancel.svg" alt="" />
            </button>
          </div>
          <div className="s-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Template Name
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Name of template">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <input type="text"
                    className="form-control"
                    name="template_name"
                    required={true}
                    defaultValue={this.state.data.template_name}
                    maxLength={50}
                    onChange={(e) => this.setTemplateValues(e)}
                    onBlur={(e) => this.checkName(e)} />
                  <div className="error-txt" id="template_name">Invalid value</div>
                </div>
                <div className="form-group">
                  <label>Feature Template
                    <span className="red-txt">*</span>
                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Template type">
                      <img src="/images/svg-icons/info.svg" alt="" />
                    </div>
                  </label>
                  <select className="form-control"
                    name="template_type"
                    disabled={this.state.id !== ""}
                    value={this.state.data.template_type}
                    onChange={(e) => this.setTemplateValues(e, "default")}
                  >
                    <option>Please Select</option>
                    {/* <option value="IPSecConfiguration">IPSec Configuration</option> */}
                    {this.getModules()}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group full-width">
                  <label>Description</label>
                  <textarea className="form-control descriptionH"
                    name="template_desc"
                    defaultValue={this.state.data.template_desc}
                    //LONG-3163-EM - Template description not getting delete after remove that description
                    onInput={(e) => this.setTemplateValues(e)} />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Device Model
                <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Model number of device">
                  <img src="/images/svg-icons/info.svg" alt="" />
                </div>
              </label>
              <label style={{ float: "right", top: "3px", right: "40px", position: "absolute" }}>Select All</label>
              {/*Added Select All button for selecting all models for custom template*/}
              <label className="switch" style={{ float: "right", top: "8px" }}>
                <input
                  type="checkbox"
                  name="all_device_models"
                  onChange={(e) => this.setState({ selectedModelList: null })}
                  checked={this.state.selectedModelList === null && this.state.modelData}
                  disabled={this.state.selectedModelList === null || this.state.id !== ""}
                />
                <span className="slider round"></span>
              </label>
              {/*Support added for creating template with particular model*/}
              {this.state.modelData &&
                <ul
                  className="dm-list d-block"
                  title={this.state.modelData}
                >
                  {this.state.modelData.split(',').map((item) => {
                    if (item)
                      return <li
                        key={item}
                        onClick={() => this.state.id !== ""
                          ? undefined
                          : this.handleItemClick(item)}
                        style={{ cursor: "pointer" }}
                        className={
                          (this.state.selectedModelList === null || item === this.state.selectedModelList)
                            ? "selectedModelList"
                            : ""
                        }
                      >{item}</li>
                  })}
                </ul>
              }
            </div>
            <div key={this.state.keyUpdate}>
              {this.state.data.template_type === 'StaticRoute' &&
                <StaticTemplate
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={this.state?.chimay_templates_version?.StaticRoute}
                  cbr={this.state?.cbr_templates_version?.StaticRoute}
                  mifi={this.state?.mifi_templates_version?.StaticRoute}
                  selectedModelList={this.state.selectedModelList}
                />
              }
              {this.state.data.template_type === 'IPSecConfiguration' &&
                <IPSecTemplate
                  create={true}
                  id={this.state.id}
                  apply={false}
                  info={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id, msg) => this.props.openApplyTemplate(id, msg)}
                  chimay={this.state?.chimay_templates_version?.IPSecConfiguration}
                  cbr={this.state?.cbr_templates_version?.IPSecConfiguration}
                  mifi={this.state?.mifi_templates_version?.IPSecConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'WANConfiguration' && (this.state.data.default_type === 'Cloud Router' ?
                <WanConfigCR
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  chimay={this.state?.chimay_templates_version?.WANConfiguration}
                  cbr={this.state?.cbr_templates_version?.WANConfiguration}
                  mifi={this.state?.mifi_templates_version?.WANConfiguration}
                  selectedModelList={this.state.selectedModelList}
                /> :
                <WanConfig
                  create={(this.state.id !== "") ? undefined : true}
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  chimay={this.state?.chimay_templates_version?.WANConfiguration}
                  cbr={this.state?.cbr_templates_version?.WANConfiguration}
                  mifi={this.state?.mifi_templates_version?.WANConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />)}
              {this.state.data.template_type === 'WiFiConfiguration' &&
                <WiFiTemplate
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={this.state?.chimay_templates_version?.WiFiConfiguration}
                  cbr={this.state?.cbr_templates_version?.WiFiConfiguration}
                  mifi={this.state?.mifi_templates_version?.WiFiConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'NetworkConfiguration' &&
                <NetworkConfiguration
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  fromAppliedList={this.props.fromAppliedList}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={this.state?.chimay_templates_version?.NetworkConfiguration}
                  cbr={this.state?.cbr_templates_version?.NetworkConfiguration}
                  mifi={this.state?.mifi_templates_version?.NetworkConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'BGPConfiguration' &&
                <BGPTemplate
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={this.state?.chimay_templates_version?.BGPConfiguration}
                  cbr={this.state?.cbr_templates_version?.BGPConfiguration}
                  mifi={this.state?.mifi_templates_version?.BGPConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'OSPFConfiguration' &&
                <OSPFTemplate
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={this.state?.chimay_templates_version?.OSPFConfiguration}
                  cbr={this.state?.cbr_templates_version?.OSPFConfiguration}
                  mifi={this.state?.mifi_templates_version?.OSPFConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'FirewallConfiguration' &&
                <FirewallTemplate
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={this.state?.chimay_templates_version?.FirewallConfiguration}
                  cbr={this.state?.cbr_templates_version?.FirewallConfiguration}
                  mifi={this.state?.mifi_templates_version?.FirewallConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'TriggerConfiguration' &&
                <WATemplate
                  // Trigger Configuration View & Edit issue fix
                  selectedModel={this.props.selectedModel ? (this.props.selectedModel.split(',').length === 1 ? this.props.selectedModel : undefined) : undefined}
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={1}
                  cbr={1}
                  mifi={1}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'QoSConfiguration' &&
                <QOSTemplate
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={this.state?.chimay_templates_version?.QoSConfiguration}
                  cbr={this.state?.cbr_templates_version?.QoSConfiguration}
                  mifi={this.state?.mifi_templates_version?.QoSConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'TunnelConfiguration' &&
                <HubSpokeTemplate
                  create={true}
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id, msg) => this.props.openApplyTemplate(id, msg)}
                  chimay={this.state?.chimay_templates_version?.DataTunnel}
                  cbr={this.state?.cbr_templates_version?.DataTunnel}
                  mifi={this.state?.mifi_templates_version?.DataTunnel}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'DNSFiltering' &&
                <DNSTemplate
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id, msg) => this.props.openApplyTemplate(id, msg)}
                  chimay={this.state?.chimay_templates_version?.DNSFilteringConfiguration}
                  cbr={this.state?.cbr_templates_version?.DNSFilteringConfiguration}
                  mifi={this.state?.mifi_templates_version?.DNSFilteringConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'SystemConfiguration' &&
                <SystemTemplate
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id, msg) => this.props.openApplyTemplate(id, msg)}
                  chimay={this.state?.chimay_templates_version?.SystemConfiguration}
                  cbr={this.state?.cbr_templates_version?.SystemConfiguration}
                  mifi={this.state?.mifi_templates_version?.SystemConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'ModemConfiguration' &&
                <ModemTemplate
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  link={true}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id, msg) => this.props.openApplyTemplate(id, msg)}
                  chimay={this.state?.chimay_templates_version?.ModemConfiguration}
                  cbr={this.state?.cbr_templates_version?.ModemConfiguration}
                  mifi={this.state?.mifi_templates_version?.ModemConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'CaptivePortal' &&
                <CaptivePortal
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={this.state?.chimay_templates_version?.CaptivePortal}
                  cbr={this.state?.cbr_templates_version?.CaptivePortal}
                  mifi={this.state?.mifi_templates_version?.CaptivePortal}
                  selectedModelList={this.state.selectedModelList}
                />}
              {this.state.data.template_type === 'DeviceConfiguration' &&
                <DeviceConfiguration
                  id={this.state.id}
                  apply={false}
                  view={this.props.view}
                  templateData={this.state.data}
                  template_schema_json={this.state.template_schema_json}
                  refreshList={() => this.props.refreshList()}
                  closeAddTemplate={() => this.props.closeAddTemplate()}
                  openApplyTemplate={(id) => this.props.openApplyTemplate(id)}
                  chimay={this.state?.chimay_templates_version?.DeviceConfiguration}
                  cbr={this.state?.cbr_templates_version?.DeviceConfiguration}
                  mifi={this.state?.mifi_templates_version?.DeviceConfiguration}
                  selectedModelList={this.state.selectedModelList}
                />}
            </div>
          </div>
        </div>
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    openAddTemplate: state.displayAddTemplate.openAddTemplate,
  };
};
export default connect(mapStateToProps)(AddEditTemplate);