import $ from 'jquery';
import React, { Component } from "react";

class SystemAlertsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props?.title || "Attention!",
            message: this.props?.message || "Host system alerts need to be resolved!",
            count: {
                new_memory_alerts: this.props?.count?.new_memory_alerts || 0,
                new_cpu_alerts: this.props?.count?.new_cpu_alerts || 0,
                new_hard_disc_alerts: this.props?.count?.new_hard_disc_alerts || 0,
                new_user_login_alerts: this.props?.count?.new_user_login_alerts || 0,
                new_certificate_alerts: this.props?.count?.new_certificate_alerts || 0
            },
            ignoreMemory: this.props?.ignoreMemory || false,
            ignoreCPU: this.props?.ignoreCPU || false,
            ignoreHardDisc: this.props?.ignoreHardDisc || false,
            ignoreUserLogin: this.props?.ignoreUserLogin || false,
            ignoreAll: (
                this.props?.ignoreMemory &&
                this.props?.ignoreCPU &&
                this.props?.ignoreHardDisc &&
                this.props?.ignoreUserLogin
            )
        };
    };

    componentDidMount() {
        $('.modal').modal('hide');
        $("#systemAlerts").modal("show");
    };

    componentWillUnmount() {
        $('.modal').modal('hide');
    };

    handleIgnoreAll = (value) => {
        if (value === "ignoreAll") {
            this.setState((prevState) => ({
                ignoreAll: !prevState.ignoreAll,
                ignoreMemory: !prevState.ignoreAll,
                ignoreCPU: !prevState.ignoreAll,
                ignoreHardDisc: !prevState.ignoreAll,
                ignoreUserLogin: !prevState.ignoreAll,
            }));
        } else {
            this.setState((prevState) => ({
                ignoreAll: prevState.ignoreMemory && prevState.ignoreCPU && prevState.ignoreHardDisc && prevState.ignoreUserLogin
            }));
        }
    };

    handleCheckboxChange = (field) => {
        this.setState((prevState) => ({ [field]: !prevState[field] }), () => this.handleIgnoreAll());
    };

    render() {
        return (
            <div
                className="modal fade systemAlerts"
                id="systemAlerts"
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="SystemAlerts"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="error-msg text-center">
                                <span className="error-icon">!</span>
                                <div className="txt1 font-weight-bold">{this.state?.title}</div>
                                <div className="txt2">{this.state?.message}</div>
                            </div>
                            <div className="mt-3">
                                <div className="row font-weight-bold text-center">
                                    <div className="col">Alerts</div>
                                    <div className="col">Count</div>
                                    <div className="col">Ignore</div>
                                </div>
                                <hr />
                                <div className="row text-center mb-2">
                                    <div className="col">Memory</div>
                                    <div className="col">{this.state?.count?.new_memory_alerts}</div>
                                    <div className="col">
                                        <label className="switch mt-1">
                                            <input
                                                type="checkbox"
                                                name="memoryOption"
                                                checked={this.state?.ignoreMemory}
                                                onChange={() => this.handleCheckboxChange("ignoreMemory")}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row text-center mb-2">
                                    <div className="col">CPU</div>
                                    <div className="col">{this.state?.count?.new_cpu_alerts}</div>
                                    <div className="col">
                                        <label className="switch mt-1">
                                            <input
                                                type="checkbox"
                                                name="cpuOption"
                                                checked={this.state?.ignoreCPU}
                                                onChange={() => this.handleCheckboxChange("ignoreCPU")}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row text-center mb-2">
                                    <div className="col">Hard Disk</div>
                                    <div className="col">{this.state?.count?.new_hard_disc_alerts}</div>
                                    <div className="col">
                                        <label className="switch mt-1">
                                            <input
                                                type="checkbox"
                                                name="hardDiskOption"
                                                checked={this.state?.ignoreHardDisc}
                                                onChange={() => this.handleCheckboxChange("ignoreHardDisc")}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row text-center mb-2">
                                    <div className="col">Certificate Renewal</div>
                                    <div className="col">{this.state?.count?.new_certificate_alerts}</div> 
                                    <div className='col'></div>                                  
                                </div>
                                {/* <div className="row text-center mb-2">
                                    <div className="col">User Login</div>
                                    <div className="col">{this.state?.count?.new_user_login_alerts}</div>
                                    <div className="col">
                                        <label className="switch mt-1">
                                            <input
                                                type="checkbox"
                                                name="hardDiskOption"
                                                checked={this.state?.ignoreUserLogin}
                                                onChange={() => this.handleCheckboxChange("ignoreUserLogin")}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div> */}
                                <hr />
                                <div className="row text-center mb-2">
                                    <div className="col font-weight-bold">Ignore All</div>
                                    <div className="col">
                                        <label className="switch mt-1">
                                            <input
                                                type="checkbox"
                                                name="ignoreAllOption"
                                                checked={this.state?.ignoreAll}
                                                onChange={() => this.handleIgnoreAll("ignoreAll")}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-top-0 justify-content-center pb-4">
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => this.props?.ignore(
                                    this.state?.ignoreMemory,
                                    this.state?.ignoreCPU,
                                    this.state?.ignoreHardDisc,
                                    this.state?.ignoreUserLogin)
                                }
                            >
                                Ignore
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => this.props?.redirect()}
                            >
                                View
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

export default SystemAlertsPopup;
