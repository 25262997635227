import React, { useMemo } from 'react';
import Select from 'react-select-virtualized';

const DropDown = ({ DeviceLookUpDeviceList = [], deviceOptions = [], deviceData, onDropChange }) => {
    const sortedDeviceOptions = useMemo(() => {
        return DeviceLookUpDeviceList.slice().sort((a, b) =>
            a.device_name.localeCompare(b.device_name)
        );
    }, [DeviceLookUpDeviceList]);

    const selectedValue = useMemo(() => {
        return deviceOptions.find(option => option.value === deviceData.device_id);
    }, [deviceOptions, deviceData.device_id]);

    const getOptionLabel = (item) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.icon}
            <span>{item.label}</span>
        </div>
    );
    return (
        <div>
            <Select
                options={sortedDeviceOptions.map(item => ({
                    value: item.device_id,
                    label: item.device_name,
                    icon: <i className={item.device_status === 1 ? 'status online' : 'status offline'}></i>,
                }))}
                placeholder={false}
                className='select-device'
                value={selectedValue}
                onChange={onDropChange}
                formatOptionLabel={getOptionLabel}
            />
        </div>
    );
}

export default React.memo(DropDown);