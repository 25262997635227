import * as Action from "../actionTypes";

const initialState = {
    openAddTunnel: false,
    openEditTunnel: false,
    tunnel_id: ""
};

export const displayAddTunnel = (state = initialState, action) => {
    switch (action.type) {
        case Action.OPEN_ADD_TUNNEL:
            return {
                ...state,
                openAddTunnel: true,
                openEditTunnel: false
            }
        case Action.CLOSE_ADD_TUNNEL:
            return {
                ...state,
                openAddTunnel: false,
                openEditTunnel: false
            }
        case Action.OPEN_EDIT_TUNNEL:
            return {
                ...state,
                openAddTunnel: false,
                openEditTunnel: true,
                tunnel_id: action.payload
            }
        case Action.CLOSE_EDIT_TUNNEL:
            return {
                ...state,
                openAddTunnel: false,
                openEditTunnel: false,
                tunnel_id: ""
            }
        default:
            return state;
    }
};

