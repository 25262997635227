/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import { Loader } from '../../layOuts/Loader';
import { Link } from "react-router-dom";
import * as Action from '../../redux/actionTypes';
import apiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import moment from 'moment';
import { ErrorPopup } from '../../layOuts/errorPopup';
import { LinkPopup } from '../../layOuts/linkPopup';
import $ from 'jquery';
import AddEditTemplates from './AddTemplates/AddEditTemplates';
import * as Constant from '../../utils/constants';
import ApplyTemplate from "./AddTemplates/ApplyTemplate";
import AttachedDeviceInfo from "./AttachedDeviceInfo";
import * as Common from '../../../src/utils/common';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../DataTableDemo.css';
import { Dropdown } from 'primereact/dropdown';
import Select from 'react-select-virtualized';
import ErrorHandlePopup from "../ErrorHandlePopup";
class DefaultTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            multipleSelect: "",
            multipleSelect1: "",
            toggledClearRows: false,
            showLoader: false,
            selectedTemplate: 1,
            title: '',
            message: '',
            searchValue: '',
            show: false,
            copyShow: false,
            error: false,
            delete: false,
            configured: false,
            pageNo: 1,
            count: 0,
            pageSize: 10,
            first: 1,
            last: 10,
            next: true,
            prev: true,
            firstDefault: 0,
            selectedOption: [],
            applyTemplateData: {},
            TemplateData: [],
            templateIdName: [],
            valid: false,
            showCopy: false,
            copyTemplate: {},
            deviceInfoData: [],
            order: '',
            sortOrder: 1,
            flag: 0,
            sortField: '',
            searchClicked: false,
            copyId: 0,
            sortOn: false,
            isAxiosError: false,
            selectedType: undefined,
            showIcon: false,
            showFilter: false,
            moduleData: [],
            templateColumns: [
                {
                    field: 'template_name', header: 'Template Name',
                    value: 'Template Name', body: this.templateNameTemplate,
                    frozen: false,
                    alignFrozen: 'left',
                    sortable: true,
                    style: { width: '200px', flexGrow: 1, minWidth: '180px' }
                },
                {
                    field: 'template_desc', header: 'Description', value: 'Description',
                    body: this.descriptionTemplate, frozen: false,
                    sortable: false,
                    style: { width: '300px', flexGrow: 1, minWidth: '280px' }
                },
                {
                    field: 'template_type', header: 'Feature Template', value: 'Feature Template', frozen: false,
                    sortable: true, body: this.tempTypeTemplate,
                    style: { width: '200px', flexGrow: 1, minWidth: '160px' }
                },
                {
                    field: 'device_model', header: 'Device Model', frozen: false, value: 'Device Model',
                    sortable: false, body: this.deviceModelTemplate,
                    style: { width: '220px', flexGrow: 1, minWidth: '200px' }
                },
                {
                    field: 'device_attached', header: 'Attached Devices', frozen: false, value: 'Attached Devices',
                    sortable: false, body: this.attachedDeviceTemplate,
                    style: { width: '170px', flexGrow: 1, minWidth: '150px' }
                },
                {
                    field: 'created_by', header: 'Created By', frozen: false, value: 'Created By',
                    sortable: false, body: this.createdByTemplate,
                    style: { width: '200px', flexGrow: 1, minWidth: '160px' }
                },
                {
                    field: 'updated_by', header: 'Updated By', frozen: false, value: 'Updated By',
                    sortable: false, body: this.updatedByTemplate,
                    style: { width: '200px', flexGrow: 1, minWidth: '160px' }
                },
                {
                    field: 'created_date', header: 'Created Date',
                    // body: this.pendingOpsTemplate, 
                    frozen: false, value: 'Created Date',
                    sortable: true, body: this.createdDateTemp,
                    style: { width: '220px', flexGrow: 1, minWidth: '200px' }
                },
                {
                    field: 'updated_date', header: 'Last Updated On', frozen: false, value: 'Last Updated On',
                    sortable: true, body: this.updateDateTemp,
                    style: { width: '220px', flexGrow: 1, minWidth: '200px' }
                },
                {
                    field: '', header: '', body: this.actionTemplate, value: 'Action',
                    style: { flexGrow: 1, flexBasis: '120px' },
                    sortable: false,
                    alignFrozen: 'right', frozen: true
                }
            ],
            addTemplate: false,
            templateDetails: undefined
        };
    }
    openAttachDevice = () => {
        this.props.dispatch({
            type: Action.OPEN_ATTACH,
        });
    }
    componentDidMount() {
        if (this.props?.location?.state && this.props?.location?.state?.searchValue) {
            this.setState({
                searchValue: this.props.location.state.searchValue
            }, () => {
                this.handleSearch()
            })
        }
        else if (this.props?.location?.state && this.props?.location?.state?.filterValue) {
            this.setState({
                selectedType: this.props.location.state.filterValue
            }, () => {
                this.getFilterList()
            })
        }
        else {
            this.getTemplateList()
        }
        let newArray = this.state.templateColumns;
        const localValue = JSON.parse(localStorage.getItem(Constant.DEFAULT_TEMPLATE_LIST));
        if (localValue !== undefined && localValue !== null) {
            newArray = []
            localValue.map((item) => {
                const column = this.valueReturn(item);
                if (column !== undefined)
                    newArray.push(column);
            })
        }
        this.props.dispatch({
            type: Action.CLOSE_ADD_TEMPLATE,
        });
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.setState({ selectedOption: newArray });
    }
    handleChange = (e) => {
        let array = [];
        this.state.selectedOption.map((option) => {
            array.push(option.value);
        });
        const result = e.target.value;
        if (result === "selectAll") {
            array = [];
            if (e.target.checked) {
                this.state.templateColumns.map((column) => {
                    array.push(column.value)
                })
            }
            else {
                array = ["Template Name", "Action"]
            }
        } else {
            if (e.target.checked)
                array.push(result);
            else
                array.splice(array.indexOf(result), 1);
        }
        let availableColumns = [
            "Template Name",
            "Description",
            "Feature Template",
            "Device Model",
            "Attached Devices",
            "Created By",
            "Updated By",
            "Created Date",
            "Last Updated On",
            "Action"
        ];
        array = availableColumns?.filter(field => array?.includes(field));
        if (array.includes("Action")) {
            array.splice(array.indexOf("Action"), 1);
            array.push("Action");
        }
        const selectedVlaues = [];
        array.map((item) => {
            const column = this.valueReturn(item);
            selectedVlaues.push(column);
        })
        localStorage.setItem(Constant.DEFAULT_TEMPLATE_LIST, JSON.stringify(array));
        this.setState({ selectedOption: selectedVlaues });
    }
    handleSearch = (sort) => {
        this.setState({
            showLoader: true,
            selectedType: undefined,
            showFilter: false,
            showIcon: false
        });
        let data = {
            template_data: this.state.searchValue,
            sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
            sorting_type: sort === 'sort' ? this.state.order : 'descending'
        };
        apiCall(urlMapping.searchDefaultTemplateList(this.state.pageNo, this.state.pageSize, data), (response) => {
            if (response) {
                const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
                const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
                if (response?.results) {
                    this.setState({
                        showLoader: false,
                        next: response?.next ? false : true,
                        prev: response?.previous ? false : true,
                        first: first,
                        last: last,
                        TemplateData: response?.results,
                        count: response?.count,
                    }, () => this.getTemplateById())
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            }
        })
    }
    templateSort = (columnSort) => {
        if (this.state.flag < 2) {
            this.setState({
                sortField: columnSort.sortField,
                sortOrder: columnSort.sortOrder,
                order: columnSort.sortOrder === 1 ? 'ascending' : 'descending',
                sortOn: true,
                flag: this.state.flag + 1,
            }, this.state.searchClicked ? () => this.handleSearch('sort') : () => this.getTemplateList("sort")
            )
        } else {
            this.setState({
                sortField: null,
                order: 'descending',
                sortOn: true,
                flag: 0,
            }, this.state.searchClicked ? () => this.handleSearch('sort') : () => this.getTemplateList("sort")
            )
        }
    }
    handleOnChange = (e) => {
        this.setState({
            searchValue: e.target.value
        });
        if (e.target.value === "") {
            this.setState({
                showFilter: false,
                showIcon: false,
                selectedType: undefined
            }, () => this.getTemplateList())
        }
    }
    handleKeypress = (e) => {
        if (e.key === 'Enter') {
            this.setState(
                {
                    pageNo: 1,
                    pageSize: 10,
                    searchClicked: true
                },
                () => { this.handleSearch() });
        }
    };
    clearSearch = () => {
        this.setState({
            searchValue: '',
            searchClicked: false,
            pageNo: 1,
        }, () => this.getTemplateList())
    }
    valueReturn = (item) => {
        return this.state.templateColumns.filter(column => column.value === item)[0];
    }
    componentDidUpdate() {
        if (this.state.pageNo - 1 * this.state.pageSize === this.state.count)
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.pageNo = this.state.pageNo - 1;
    }
    closeAddTemplate = () => {
        this.setState({
            templateDetails: undefined
        })
        this.props.dispatch({
            type: Action.CLOSE_ADD_TEMPLATE,
        });
    }
    getTemplateDetails = (id) => {
        this.setState({
            showLoader: true
        });
        apiCall(urlMapping.getTemplateDetails(id), (response) => {
            if (response?.success) {
                this.setState({
                    showLoader: false,
                    templateDetails: response?.data,
                    selectedModel: response?.data?.device_model
                }, () => {
                    this.handleAddTemplate();
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    getTime = (time) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format('DD MMMM YYYY hh:mm:ss A');
    }
    sortAlphaNum = (a, b) => {
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;
        var aA = a.replace(reA, "");
        var bA = b.replace(reA, "");
        if (aA === bA) {
            var aN = parseInt(a.replace(reN, ""), 10);
            var bN = parseInt(b.replace(reN, ""), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            return aA > bA ? 1 : -1;
        }
    }
    getTemplateList = (sort) => {
        this.setState({
            // LONG-3171-EM - Contents entered in the search bar is not getting cleared after refresh
            searchValue: "",
            showLoader: true,
            selectedType: undefined,
            showFilter: false,
            showIcon: false
        });
        let sorting_parameter = sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date'
        let sorting_type = sort === 'sort' ? this.state.order : 'descending'
        apiCall(urlMapping.defaultTemplateList(sorting_parameter, sorting_type, this.state.pageNo, this.state.pageSize), (response) => {
            this.setState({
                showLoader: false
            });
            if (response?.results) {
                const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
                const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
                this.setState({
                    next: response?.next ? false : true,
                    prev: response?.previous ? false : true,
                    first: first,
                    last: last,
                    TemplateData: response?.results,
                    count: response?.count
                }, () => this.getTemplateById())
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    getTemplateById = () => {
        let data = [];
        this.state.TemplateData.map((item) => {
            data = { id: item.id, name: item.template_name }
            let ids = this.state.templateIdName.map((element) => element.id)
            if (!ids.includes(data.id)) {
                this.state.templateIdName.push(data)
            }
        })
    }
    deleteTemplate = () => {
        let tempName = [];
        this.state.multipleSelect.forEach(element => {
            tempName.push(element.template_name)
        });
        let data = { template_name: tempName };
        this.setState({
            showLoader: true
        });
        apiCall(urlMapping.deleteTemplate(data), (response) => {
            this.setState({
                showLoader: false
            });
            if (response?.success === true) {
                let newPage = this.state.pageNo;
                if (this.state.pageNo > 1) {
                    newPage = (this.state.count -
                        (this.state.pageSize * (this.state.pageNo - 1)) - this.state.multipleSelect?.length === 0 &&
                        this.state.pageNo > 1) ?
                        this.state.pageNo - 1 : this.state.pageNo;
                }
                $("#errorMessage").modal("hide")
                this.setState({
                    title: 'Delete Template',
                    message: response?.message,
                    show: true,
                    error: false,
                    delete: false,
                    toggledClearRows: !this.state.toggledClearRows,
                    pageNo: newPage
                }, () => $("#errorMessage").modal("show"));
                this.getTemplateList();
            } else {
                $("#errorMessage").modal("hide");
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Delete Template',
                        message: response?.message,
                        show: true,
                        error: true,
                        delete: false
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
    }
    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    }
    handleAddTemplate = (templateData) => {
        if (templateData) {
            this.setState({ templateDetails: templateData })
        }
        this.props.dispatch({
            type: Action.OPEN_ADD_TEMPLATE,
        });
    }
    openApplyTemplate = (value) => {
        $("#exampleModal").modal("hide");
        if (value) {
            apiCall(urlMapping.getTemplateById(value), (response) => {
                if (response?.success) {
                    let templateType = response?.data?.template_type;
                    let createdTemplateVersionCBR = response?.data?.template_version?.cbr;
                    let createdTemplateVersionChimay = response?.data?.template_version?.chimay;
                    let createdTemplateVersionMifi = response?.data?.template_version?.mifi;
                    apiCall(urlMapping.getModuleList(), (response1) => {
                        if (response1?.success) {
                            if (templateType === 'TunnelConfiguration') {
                                templateType = 'DataTunnel'
                            } else if (templateType === 'DNSFiltering') {
                                templateType = 'DNSFilteringConfiguration'
                            }
                            let currentTemplateVersionCBR = response1?.data?.cbr_templates_version?.[templateType];
                            let currentTemplateVersionChimay = response1?.data?.chimay_templates_version?.[templateType];
                            let currentTemplateVersionMifi = response1?.data?.mifi_templates_version?.[templateType]
                            const versionCheck = Common.getModels(this.state.selectedModel) === 'vCPE' ?
                                createdTemplateVersionCBR === currentTemplateVersionCBR : (Common.getModels(this.state.selectedModel) === 'CHIMAY' ?
                                    createdTemplateVersionChimay === currentTemplateVersionChimay :
                                    createdTemplateVersionMifi === currentTemplateVersionMifi
                                )
                            if (templateType === 'TriggerConfiguration' || versionCheck) {
                                this.setState({ applyTemplateData: response?.data }, () => {
                                    this.props.dispatch({
                                        type: Action.OPEN_APPlY_TEMPLATE,
                                    });
                                })
                            }
                            else {
                                this.setState(
                                    {
                                        title: "Version Mismatch",
                                        message: 'Template version is outdated, as EDGE Manager is upgraded, Please create new template to proceed further',
                                        showPopup: true,
                                        error: true,
                                        delete: true,
                                        //   keyRotation: true
                                    },
                                    () => {
                                        $("#versionErrorMessage").modal("show");
                                    }
                                );
                            }
                        }
                        else if (response1?.isAxiosError) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                    })
                } else {
                    if (parseInt(response?.response?.status) === 401) {
                        this.setState({
                            showRefreshModal: true
                        });
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        }
    }
    // LONG-3916 : Disable Edit for Device Provision templates
    disableOption = (row) => {
        if (row.template_type === 'DeviceProvision' ||
            (this.props?.userRole === "Viewer_Group" ||
                this.props?.userRole === "Operator_Group"))
            return "disabled"
        else
            return undefined
    }
    disableClick = (row, value) => {
        if (row.template_type === 'DeviceProvision' ||
            (this.props?.userRole === "Viewer_Group" ||
                this.props?.userRole === "Operator_Group"))
            return ""
        // Disable Apply || Copy || Edit for Device Provision templates
        else {
            if (value === 'Apply') {
                return this.openApplyTemplate(row.id)
            }
            else if (value === 'Edit') {
                return this.getTemplateDetails(row.id)
            }
            else {
                return this.getCopyTemplateDetails(row)
            }
        }
    }
    openEditTemplate = (value) => {
        $("#exampleModal").modal("hide");
        if (value) {
            this.getTemplateDetails(value);
        }
    }
    confirmDelete = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Delete Templates' : 'Delete Template',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to delete templates?' : 'Are you sure you want to delete template?',
            show: true,
            error: true,
            delete: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    getCopyTemplateDetails = (row) => {
        let selectedTemplate = JSON.parse(JSON.stringify(row));
        selectedTemplate.template_id = '';
        selectedTemplate.template_desc = '';
        selectedTemplate.id = '';
        selectedTemplate.template_name = row.template_name + "_copy"
        this.setState({
            copyTemplate: selectedTemplate,
            showCopy: true,
        }, () => {
            $("#exampleModalCopy").modal("show");
        });
    }
    setCopyTemplateValues = (e) => {
        let data = this.state.copyTemplate
        let name = e.target.name
        let value = e.target.value
        if (name === "template_name") {
            data.template_name = value
        }
        if (name === "template_desc") {
            data.template_desc = value
        }
        this.setState({
            copyTemplate: data
        })
    }
    saveData = () => {
        let data = this.state.copyTemplate;
        this.setState({
            showLoader: true,
        });
        apiCall(urlMapping.createTemplate(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                $("#exampleModalCopy").modal("hide")
                this.setState({
                    title: 'Copy Template',
                    message: response?.message + ' To edit ',
                    copyShow: true,
                    error: false,
                    delete: false,
                    showCopy: false,
                    copyId: response?.data?.id,
                    copyTemplate: {},
                }, () => {
                    $("#exampleModal").modal("show");
                    this.getTemplateList();
                }
                );
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true
                    });
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    $("#exampleModalCopy").modal("hide")
                    this.setState({
                        title: 'Copy Template',
                        message: response?.message,
                        // LONG-2289 : Not able to make copy of bootstrap templates twice 
                        show: true,
                        error: true,
                        delete: false,
                        copyShow: true,
                        showCopy: false,
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        })
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            multipleSelect: "",
            showPopup: false,
        }, () => $("#errorMessage").modal("hide"))
    }
    openLink = (id) => {
        this.setState({
            title: 'Create Template',
            message: 'Template created Successfully.To apply Template to the device(s) ',
            showLink: true,
            id: id
        }, () => {
            $("#exampleModal").modal("show");
        });
    }
    closeLinkpopup = () => {
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.setState({ showLink: false, id: '', copyId: '' }, () => {
            this.getTemplateList();
            $("#exampleModal").modal("hide");
            $("#exampleModalCopy").modal("hide");
            $("#exampleModalNetwork").modal("hide");
        });
    }
    onSelectRow = (e) => {
        if (e.value.length === 0) {
            this.setState({
                multipleSelect: ''
            })
        } else {
            this.setState({
                multipleSelect: e.value
            })
        }
    }
    templateNameTemplate = (row) => {
        return (
            <span title={row.template_name}>
                {/* Hide view functionality for Device Provision templates */}
                {(row.template_type !== 'DeviceProvision') ?
                    <>
                        {/* Trigger Configuration View & Edit issue fix    */}
                        <Link to={{
                            pathname: '/templateInfo',
                            state: {
                                data: row, templateListWithIdName: this.state.templateIdName,
                                model: Common.getModels((row.device_model).split(',')[0]), default: true,
                                searchValue: this.state.searchValue,
                                filterValue: this.state.showIcon ? this.state.selectedType : undefined,
                            }
                        }}
                            className="txt-blue"> {row.template_name}</Link>
                    </> : <>{row.template_name}</>
                }
            </span>
        )
    }
    descriptionTemplate = (row) => {
        return (
            <span title={row.template_desc}>
                {(row.template_desc !== "" && row.template_desc !== "null" && row.template_desc !== null) ? row.template_desc : '-'}</span>
        )
    }
    tempTypeTemplate = (row) => {
        return (
            <span title={Common.getTemplateType(row.template_type)}>
                {Common.getTemplateType(row.template_type)}</span>
        )
    }
    deviceModelTemplate = (row) => {
        return (
            <div>
                {row.device_model !== '' ? <span className="dm-cell">
                    <ul className="dm-list">
                        <li title={row.device_model.split(',')[0]}>{row.device_model.split(',')[0]}</li>
                        {row.device_model.split(',')[1] && <li title={row.device_model.split(',')[1]}>
                            {row.device_model.split(',')[1]}</li>}
                    </ul>
                    {row.device_model.split(',').length - 2 > 0 && <span className="txt-blue show-full-model" title={row.device_model}>
                        {`+${row.device_model.split(',').length - 2}`}</span>}
                </span> : '-'
                }
            </div>
        )
    }
    attachedDeviceTemplate = (row) => {
        return (
            <span title={row.device_attached}>
                {row.device_attached > 0 ? <button className="txt-blue cursor-pointer"
                    onClick={() => { this.setState({ deviceInfoData: row }); this.openAttachDevice() }}>
                    {row.device_attached}</button>
                    : row.device_attached}
            </span>
        )
    }
    createdByTemplate = (row) => {
        return (
            <span title={row.created_by || "-"}>
                {row.created_by || "-"}</span>
        )
    }
    updatedByTemplate = (row) => {
        return (
            <span title={row.updated_by || "-"}>
                {row.updated_by || "-"}</span>
        )
    }
    createdDateTemp = (row) => {
        return (
            <span className="date-time" title={this.getTime(row.created_date)}>
                {this.getTime(row.created_date)}</span>
        )
    }
    updateDateTemp = (row) => {
        return (
            <span className="date-time" title={this.getTime(row.updated_date)}>
                {this.getTime(row.updated_date)}</span>
        )
    }
    actionTemplate = (row) => {
        return (
            <div className="da-box dropleft">
                <img className="a-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
                <div className="drop-box dropdown-menu">
                    <ul>
                        {/* LONG-3916 : Disable Edit for Device Provision templates */}
                        <li className={this.disableOption(row)} onClick={() => { this.disableClick(row, "Edit") }}><img className="edit" src="/images/svg-icons/edit.svg" alt="" />Edit</li>
                        {/* Disable Apply for Device Provision templates  */}
                        <li className={this.disableOption(row)} onClick={() => { this.disableClick(row, "Apply") }} ><img className="edit" src="/images/svg-icons/template.svg" alt="" /> Apply Template</li>
                        {/* Disable Copy for Device Provision templates */}
                        <li className={this.disableOption(row)} onClick={() => { this.setState({ multipleSelect: [row] }); this.disableClick(row, "Copy") }}><img className="edit" src="/images/svg-icons/copy.svg" alt="" />Copy Template</li>
                    </ul>
                </div>
            </div>
        )
    }
    getFilter = () => {
        this.setState({
            showLoader: true,
        });
        apiCall(urlMapping.getModuleList(), (response) => {
            if (response?.success) {
                this.setState({
                    moduleData: response?.data?.supported_modules,
                    showLoader: false,
                    showFilter: true
                });
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true, showLoader: false })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        moduleData: [],
                        showLoader: false,
                        showFilter: true
                    });
                }
            }
        });
    }
    getOptions = () => {
        if (this.state.moduleData.length !== 0) {
            let options = []
            options = Object.keys(this.state.moduleData).map((item) => {
                return { label: Common.getTemplateType(item), value: item }
            })
            return options
        }
    }
    selectedType = (e) => {
        if (e)
            this.setState({
                selectedType: { label: e?.label, value: e?.value }
            })
        else
            this.setState({
                selectedType: undefined
            })
    }
    resetValue = () => {
        this.setState({
            selectedType: undefined,
            showIcon: false,
            showFilter: false,
            pageNo: 1
        }, () => this.getTemplateList())
    }
    getFilterList = () => {
        this.setState({
            showLoader: true,
            searchValue: ""
        });
        let data = {
            template_type: this.state.selectedType.value,
            default_type: "True",
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize
        }
        apiCall(urlMapping.templateByType(data), (response) => {
            this.setState({ showLoader: false })
            if (response?.results) {
                const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
                const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
                this.setState({
                    next: response?.next ? false : true,
                    prev: response?.previous ? false : true,
                    first: first,
                    last: last,
                    TemplateData: response?.results,
                    count: response?.count,
                    showFilter: false,
                    showIcon: true
                }, () => this.getTemplateById())
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({ showRefreshModal: true })
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    }
    setPerPage = (e) => {
        this.setState({
            pageNo: 1,
            pageSize: e.value
        }, () => {
            (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
                (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTemplateList('sort') :
                    (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
                        this.state.showIcon ? this.getFilterList() :
                            this.getTemplateList()
        });
    };
    nextPageChangeHandle = () => {
        this.setState({
            pageNo: this.state.pageNo + 1
        }, () => {
            (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
                (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTemplateList('sort') :
                    (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
                        this.state.showIcon ? this.getFilterList() :
                            this.getTemplateList()
        });
    };
    prevPageChangeHandle = () => {
        this.setState({
            pageNo: this.state.pageNo - 1
        }, () => {
            (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
                (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTemplateList('sort') :
                    (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
                        this.state.showIcon ? this.getFilterList() :
                            this.getTemplateList()
        });
    };
    firstPageChangeHandle = () => {
        this.setState({
            pageNo: 1
        }, () => {
            (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
                (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTemplateList('sort') :
                    (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
                        this.state.showIcon ? this.getFilterList() :
                            this.getTemplateList()
        });
    }
    lastPageChangeHandle = () => {
        this.setState({
            pageNo: Math.ceil(this.state.count / this.state.pageSize)
        }, () => {
            (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
                (this.state.searchValue === "" && this.state.sortOn === true) ? this.getTemplateList('sort') :
                    (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
                        this.state.showIcon ? this.getFilterList() :
                            this.getTemplateList()
        });
    }
    render() {
        let colArray = this.state.selectedOption.length === 0 ? this.state.templateColumns : this.state.selectedOption;
        const dynamicColumns = colArray.map((col, i) => {
            return <Column key={col.field} field={col.field} resizable={col.resizable}
                sortable={col.sortable}
                header={col.header} body={col.body} style={col.style} alignFrozen={col.alignFrozen} frozen={col.frozen} />;
        });
        const template2 = {
            layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
            'RowsPerPageDropdown': (options) => {
                const dropdownOptions = [
                    { label: "10", value: 10 },
                    { label: "20", value: 20 },
                    { label: "30", value: 30 },
                    { label: "40", value: 40 },
                    { label: "50", value: 50 }
                ];
                return (
                    <React.Fragment>
                        <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Rows per page: </span>
                        <Dropdown value={this.state.pageSize} options={dropdownOptions} onChange={(e) => this.setPerPage(e)} />
                    </React.Fragment>
                );
            },
            'CurrentPageReport': (options) => {
                return (
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {this.state.first} - {this.state.last} of {this.state.count}
                    </span>
                )
            },
            'FirstPageLink': () => {
                return (
                    <button type="button"
                        className=" p-link1"
                        onClick={() => this.firstPageChangeHandle()}
                        disabled={this.state.prev}
                    >
                        <img
                            className={this.state.prev ? "p-link3" : "p-link2"}
                            src={this.state.prev ? "/images/svg-icons/arrow-pre-disabled.svg" : "/images/svg-icons/arrow-pre.svg"}
                            alt=""
                        />
                    </button>
                )
            },
            'PrevPageLink': () => {
                return (
                    <button type="button"
                        className=" p-link1"
                        onClick={() => this.prevPageChangeHandle()}
                        disabled={this.state.prev}
                    >
                        <i className="pi pi-chevron-left"></i>
                    </button>
                )
            },
            'NextPageLink': () => {
                return (
                    <button
                        type="button"
                        className="p-link1"
                        onClick={() => this.nextPageChangeHandle()}
                        disabled={this.state.next}
                    >
                        <i className="pi pi-chevron-right"></i>
                    </button>
                )
            },
            'LastPageLink': () => {
                return (
                    <button type="button"
                        onClick={() => this.lastPageChangeHandle()}
                        className=" p-link1"
                        disabled={this.state.next}
                    >
                        <img
                            className={this.state.next ? "p-link3" : "p-link2"}
                            src={this.state.next ? "/images/svg-icons/arrow-next-disabled.svg" : "/images/svg-icons/arrow-next.svg"}
                            alt=""
                        />
                    </button>
                )
            }
        };
        return (
            <div>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <Header />
                    <div className="white-bg  page-height">
                        <div className="ta-box">
                            <h1 className="page-title">Bootstrap Templates
                            </h1>
                            <div className="action-btn d-flex align-items-cenetr">
                                <div className="search-box">
                                    <input type="text" className="show"
                                        placeholder="Enter template name"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={this.state.searchValue}
                                        onKeyPress={(e) => this.handleKeypress(e)}
                                    />
                                    {this.state.searchValue &&
                                        <>
                                            <img className="clear-icon show" src="/images/svg-icons/cancel.svg" onClick={() => this.clearSearch()} alt="" />
                                        </>}
                                    <button className="search-btn" disabled={this.state.searchValue === ""}
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    pageNo: 1,
                                                    pageSize: 10,
                                                    searchClicked: true
                                                },
                                                () => { this.handleSearch() });
                                        }
                                        }
                                    >
                                        <img src="/images/svg-icons/search.svg" alt="" /></button>

                                </div>
                                <button
                                    onClick={() => this.state.showIcon
                                        ? this.getFilterList()
                                        : this.setState({ pageNo: 1 }, () => this.getTemplateList())}>
                                    <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>
                                <button className="dropdown-toggle filter-btn" data-toggle="dropdown"><img src="/images/svg-icons/columns.svg" alt="" /> Columns</button>
                                <div className="btn-group">
                                    <button
                                        type="button"
                                        className="dropdown-toggle  filter-btn"
                                        onClick={(e) => this.getFilter()}
                                    >
                                        {(this.state.showIcon) ?
                                            <img src="/images/svg-icons/info-icon.svg" alt="" />
                                            :
                                            <img src="/images/svg-icons/filter.svg" alt="" />
                                        }
                                        Filter
                                    </button>
                                    {this.state.showFilter && <div
                                        className={
                                            this.state.showFilter
                                                ? "dropdown-menu show  dropdown-menu-right filter-section width360 overflow-visible"
                                                : "dropdown-menu dropdown-menu-right filter-section width360 overflow-visible"
                                        }
                                        id="parentDiv"
                                    >
                                        <div className="form-group">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label>Template: </label>
                                            </div>
                                            <Select
                                                options={this.getOptions()}
                                                placeholder={"Select Template"}
                                                className="filter-dropdown"
                                                classNamePrefix="react-select-container"
                                                value={this.state.selectedType}
                                                onChange={(e) => this.selectedType(e)}
                                            />
                                        </div>
                                        <div className="action-box">
                                            <button
                                                className="btn btn-light"
                                                onClick={() => this.resetValue()}
                                            >
                                                Reset
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => this.setState({ pageNo: 1 }, () => this.getFilterList())}
                                                disabled={this.state.selectedType === undefined}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>}
                                </div>
                                <div className="filter-section dropdown-menu dropdown-menu-right" onChange={(e) => this.handleChange(e)}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="selectAll"
                                            checked={this.state.selectedOption.length === this.state.templateColumns.length}
                                            style={{ cursor: "pointer" }} />
                                        <label className="form-check-label">Select All</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Template Name" checked={true} disabled
                                            style={{ cursor: "not-allowed" }} />
                                        <label className="form-check-label">Template Name</label>
                                    </div>
                                    {
                                        this.state.templateColumns.map((item, index) => {
                                            if (item.value !== "Template Name" && item.value !== "Action")
                                                return (
                                                    <div className="form-check" key={index}>
                                                        <input className="form-check-input" type="checkbox" value={item.value}
                                                            checked={JSON.stringify(this.state.selectedOption).includes(item.value)}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                        <label className="form-check-label">{item.value}</label>
                                                    </div>
                                                )
                                        })
                                    }
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Action" checked={true} disabled
                                            style={{ cursor: "not-allowed" }} />
                                        <label className="form-check-label">Action</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.showCopy && <div className="modal fade exampleModal" id="exampleModalCopy" tabIndex="-1" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Copy Template</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label>Template Name</label>
                                            <input type="text" className="form-control"
                                                name="template_name"
                                                maxLength={50}
                                                value={this.state.copyTemplate.template_name}
                                                onChange={(e) => this.setCopyTemplateValues(e)} />
                                        </div>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea className="form-control"
                                                name="template_desc"
                                                value={this.state.copyTemplate.template_desc}
                                                onChange={(e) => this.setCopyTemplateValues(e)}></textarea>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-light" data-dismiss="modal">Cancel</button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={(this.state.copyTemplate.template_name).trim() === ''}
                                            onClick={() => this.saveData()}
                                        >Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className="card">
                            <DataTable
                                value={this.state.TemplateData}
                                onSort={(columnSort) => {
                                    this.templateSort(columnSort);
                                }}
                                sortField={this.state.sortField}
                                sortOrder={this.state.sortOrder}
                                scrollable scrollHeight="400px"
                                loading={this.state.loading}
                                removableSort
                                resizableColumns
                                columnResizeMode="fit"
                                showGridlines
                                paginator
                                paginatorTemplate={template2}
                                first={this.state.firstDefault}
                                rows={this.state.pageSize}
                                paginatorClassName="p-jc-end"
                                selectionMode="checkbox"
                                selection={this.state.multipleSelect}
                                onSelectionChange={e => this.onSelectRow(e)}
                                dataKey="template_id" scrollDirection="both" className="p-mt-3">
                                <Column selectionMode="multiple" style={{ flexGrow: 1, flexBasis: '80px' }} ></Column>
                                {dynamicColumns}
                            </DataTable>
                        </div>
                    </div>
                    {this.props.openAddTemplate &&
                        <AddEditTemplates
                            // Updated vales not retained in edit & reapply
                            selectedModel={this.state.selectedModel}
                            refreshList={() => this.getTemplateList()}
                            templateData={this.state.templateDetails}
                            closeAddTemplate={() => this.closeAddTemplate()}
                            openApplyTemplate={(id) => this.openLink(id)} />}
                    {this.props.openApplyTemplate && <ApplyTemplate
                        data={this.state.applyTemplateData}
                        selectedModel={this.state.selectedModel}
                        refreshList={() => this.getTemplateList()}
                        props={this.props}
                        handleAddTemplate={(value) => this.handleAddTemplate(value)} />}
                    {this.props.openAttach && <AttachedDeviceInfo data={this.state.deviceInfoData} />}
                </section>
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                        onSubmit={this.state.delete ? () => this.deleteTemplate() : undefined}
                    />
                }
                {(this.state.showLink) &&
                    <LinkPopup
                        title={this.state.title}
                        message={this.state.message}
                        open={() => this.openApplyTemplate(this.state.id)}
                        close={() => this.closeLinkpopup()}

                    />
                }
                {(this.state.copyShow) &&
                    <LinkPopup
                        title={this.state.title}
                        message={this.state.message}
                        open={() => this.openEditTemplate(this.state.copyId)}
                        close={() => this.closeLinkpopup()}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showPopup && (
                    <div className="modal fade errorMessage" id="versionErrorMessage" tabIndex="-1" data-backdrop="static"
                        role="dialog" aria-labelledby="errorMessageTitle" aria-hidden="true"
                    >
                        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-white border-0">
                                </div>
                                <div className="modal-body pt-5">
                                    <div className="error-msg">
                                        <span className={this.state.error ? "error-icon" : "success-icon"}>{this.state.error ? (this.state.info ? 'i' : '!') : ''}</span>
                                        <div className="txt1">{this.state.title}</div>
                                        <div className="txt2">{this.state.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-top-0 justify-content-center pb-4">
                                    {/* {(this.state.delete || this.state.approve) && <button type="button" className="btn btn-light"
                    data-dismiss="modal"
                    onClick={() => this.resetPopup()}>
                    {'Cancel'}</button>} */}
                                    {(this.state.delete || this.state.error) && <button type="button" className={this.state.error ? "btn btn-danger" : "btn btn-primary"}
                                        data-dismiss="modal"
                                        // disabled={this.state.scheduleLater === true && this.state.startDate === null}
                                        onClick={() => this.resetPopup()}>
                                        {'OK'}</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openStaticTemplate: state.displayAddTemplate.openStaticTemplate,
        openWGTemplate: state.displayAddTemplate.openWGTemplate,
        openAddTemplate: state.displayAddTemplate.openAddTemplate,
        openApplyTemplate: state.displayAddTemplate.openApplyTemplate,
        openAttach: state.displayAddTemplate.openAttach,
        userRole: String(state.auth.role)
    });
}
export default withRouter(connect(mapStateToProps)(DefaultTemplateList));