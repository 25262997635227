/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import moment from 'moment';
import DataTable from "react-data-table-component";
import $ from 'jquery';
import ErrorHandlePopup from '../ErrorHandlePopup';
import { ErrorPopup } from '../../layOuts/errorPopup';

class SystemAlerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            deviceEventsData: [],
            pageNo: 1,
            pageSize: 10,
            first: 1,
            last: 10,
            next: true,
            prev: true,
            count: 0,
            id: this.props.data,
            deviceSeverity: '',
            filterDeviceSeverity: '',
            filterNewAlerts: false,
            showFilter: false,
            filterEnabled: false,
            showIcon: false,
            isAxiosError: false,
            newAlerts: false,
            warningColumns: [
                {
                    name: "Severity",
                    selector: "severity",
                    value: "Severity",
                    cell: (row) => (
                        <div id={`col${row?.severity}`}>
                            <span
                                id={`icon${row?.severity}`}
                                className={!row?.acknowledged ? 'system-alert' : ''}
                            >
                                {row?.severity}
                            </span>
                        </div>
                    ),
                },
                {
                    name: "Alert Type",
                    selector: "system_alerts",
                    value: "Alert Type",
                    minWidth: "500px",
                    cell: (row) => (
                        <span
                            title={row?.system_alerts}
                            style={{ width: 'auto' }}
                            className={!row?.acknowledged ? 'system-alert' : ''}
                        >
                            {row?.system_alerts}
                        </span>
                    ),
                },
                {
                    name: "Created Date",
                    selector: "created_date",
                    value: "Create Date",
                    minWidth: "220px",
                    cell: (row) => (
                        <span
                            title={this.getTimeExact(row?.created_date)}
                            style={{ width: 'auto' }}
                            className={!row?.acknowledged ? 'system-alert' : ''}
                        >
                            {this.getTimeExact(row?.created_date)}
                        </span>
                    ),
                },
            ]
        }
    };

    componentDidMount() {
        if (this.props?.deviceSeverity || this.props.newAlerts) {
            this.setState({
                deviceSeverity: this.props?.deviceSeverity,
                newAlerts: this.props?.newAlerts,
                filterEnabled: this.props?.deviceSeverity || this.props?.newAlerts ? true : false
            }, () => this.getDeviceEventFilter())
        } else {
            this.deviceEvents()
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps?.location?.state?.getSystemAlertsCount)
            this.props?.location?.state?.getSystemAlertsCount();
    };

    refreshPage = () => {
        if (this.state.filterEnabled) {
            this.getDeviceEventFilter()
        }
        else {
            this.deviceEvents()
        }
    };

    deviceEvents = () => {
        this.setState({
            showLoader: true
        })
        ApiCall(urlMapping.getSystemAlerts(this.state.pageNo, this.state.pageSize), (response) => {
            this.setState({ showLoader: false })
            if (response?.results) {
                let alert_id = [];
                if (response?.results?.length > 0) {
                    response?.results.map((item) => item?.acknowledged === false && alert_id?.push(item.id));
                }
                this.setState({
                    deviceEventsData: response?.results,
                    count: response?.count,
                    showLoader: false
                }, () => {
                    if (alert_id?.length > 0) {
                        let data = {
                            alert_id: `[${alert_id.toString()}]`
                        }
                        ApiCall(urlMapping.readSystemAlertsCount(data), () => {
                            this.setState({ showLoader: false }, () => {
                                this.props.getSystemAlertsCount();
                            })
                        })
                    }
                })
            } else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    })
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    };

    paginationChange = (e) => {
        this.setState({
            pageNo: e
        }, () => {
            if(this.state.filterEnabled)
                this.getDeviceEventFilter() 

            else
             this.deviceEvents();
        });
    };

    pageSizeChange = (e) => {
        this.setState({
            pageSize: e
        }, () => {
            this.state.filterEnabled ? this.getDeviceEventFilter() : this.deviceEvents();
        });
    };

    getTimeExact = (time) => {
        let date = moment.unix(time);
        return date.format('DD MMMM YYYY hh:mm:ss A');
    };

    setFilterList = () => {
        this.setState((prevState) => ({
            showFilter: !prevState.showFilter,
        }));
    };

    filterOptions = (option, inputValue) => {
        const { label } = option;
        const otherKey = this.getOptions()?.filter(
            (opt) => opt?.label?.toLowerCase() === label?.toLowerCase() && opt?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
        );
        return label?.toLowerCase()?.includes(inputValue?.toLowerCase()) || otherKey?.length > 0;
    };

    setDeviceSeverity = (e) => {
        if (e.target.value !== 'select') {
            this.setState({
                filterDeviceSeverity: e.target.value
            })
        }
    };

    setNewAlerts = (e) => {
        this.setState({
            filterNewAlerts: e.target.checked,
        })
    }

    clearDropdown = () => {
            this.setState({ filterDeviceSeverity: '', filterNewAlerts: false })
    };

    resetValue = () => {
        if (this.props?.data) {
            this.setState({
                pageNo: this.state.filterEnabled ? 1 : this.state.pageNo,
                deviceName: '',
                groupName: '',
                deviceSeverity: '',
                newAlerts: false,
                showIcon: false,
                filterEnabled: false,
                showFilter: false
            }, () => {
                this.getAlertsAndWarning()
            })
        }
        else {
            this.setState({
                deviceName: '',
                groupName: '',
                deviceSeverity: '',
                newAlerts: false,
                showIcon: false,
                filterEnabled: false,
                pageNo: this.state.filterEnabled ? 1 : this.state.pageNo,
                showFilter: false
            }, () => {
                this.deviceEvents()
            })
        }
    };

    hideDropdown = (e) => {
        var popup = $("#parentDiv");
        if (!$('#open')?.is(e?.target) && !popup?.is(e?.target) && popup?.has(e?.target)?.length === 0) {
            this.setState({ showFilter: false, })
        }
    };

    getDeviceEventFilter = () => {
        this.setState({ showLoader: true })
        let data = '';
            data = {
                acknowledged: this.state.newAlerts === true ? 'False' : 'True',
                severity: this.state.deviceSeverity === 'Select' ? '' : this.state.deviceSeverity,
                pageNo: this.state.pageNo,
                pageSize: this.state.pageSize
            }
        ApiCall(urlMapping.getSystemAlertsFilter(data), (response) => {
            let alert_id = [];
            if (response?.results) {
                if (response?.results?.length > 0) {
                    response?.results?.map((item) => item?.acknowledged === false && alert_id?.push(item.id));
                }
                if (parseInt(response?.response?.status) === 200) {
                    const first = (this.state.pageSize * (this.state.pageNo - 1)) + 1;
                    const last = response?.next ? this.state.pageSize * this.state.pageNo : response?.count;
                    this.setState({
                        next: response?.next ? false : true,
                        prev: response?.previous ? false : true,
                        first: first,
                        last: last,
                        deviceEventsData: response?.results,
                        count: response?.count,
                        showFilter: false,
                        showIcon: true,
                        showLoader: false
                    }, () => {
                        if (alert_id?.length > 0) {
                            let data = {
                                alert_id: `[${alert_id.toString()}]`
                            }
                            ApiCall(urlMapping.readSystemAlertsCount(data), () => {
                                this.setState({ showLoader: false }, () => {
                                    this.props.getSystemAlertsCount();
                                })
                            })
                        }
                    })
                }
            }
            else {
                if (parseInt(response?.response?.status) === 401) {
                    this.setState({
                        showRefreshModal: true,
                        showLoader: false
                    })
                }
                else if (
                    response?.isAxiosError &&
                    response?.success === undefined &&
                    response?.response?.data?.success === undefined
                ) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({ showLoader: false })
                }
            }
        })
    };
    render() {
        return (
            <div onMouseDown={(e) => this.hideDropdown(e)}>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <div className="white-bg">
                    <div className="ta-box set-pos mb-0">
                        <div className="action-btn">
                            <button
                                onClick={() => this.refreshPage()}>
                                <img src="/images/svg-icons/refreshing.svg" alt="" />
                                Refresh
                            </button>
                            <div className="btn-group" >
                                <button
                                    type="button"
                                    className="dropdown-toggle  filter-btn"
                                    disabled={this.state?.deviceEventsData?.length === 0 && !this.state.showIcon}
                                    onClick={() => this.setState({
                                        filterDeviceSeverity: this.state.deviceSeverity,
                                        filterNewAlerts: this.state.newAlerts
                                    }, () => this.setFilterList())}>
                                    {
                                        this.state.showIcon
                                            ? <img src="/images/svg-icons/info-icon.svg" alt="" />
                                            : <img src="/images/svg-icons/filter.svg" alt="" />
                                    }
                                    Filter
                                </button>
                                {
                                    this.state.showFilter &&
                                    <div
                                        id="parentDiv"
                                        className={this.state.showFilter ? "dropdown-menu show  dropdown-menu-right filter-section width360 overflow-visible" : "dropdown-menu dropdown-menu-right filter-section width360 overflow-visible"}
                                    >
                                        <div className="form-group">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label>Severity</label>
                                                <span onClick={() => this.clearDropdown()}>Clear</span>
                                            </div>
                                            <div className="row text-left mb-2">
                                            <div className="col">New Alerts</div>
                                            <label className="switch mt-1">                                             
                                            <input
                                                type="checkbox"
                                                name="memoryOption"
                                                checked={this.state?.filterNewAlerts}
                                                onChange={(e) => this.setNewAlerts(e)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        </div>
                                            <select
                                                className="form-control"
                                                value={this.state.filterDeviceSeverity ? this.state.filterDeviceSeverity : "Select"}
                                                onChange={(e) => this.setDeviceSeverity(e)}>
                                                <option value="Select">Select Severity</option>
                                                <option value="Warning">Warning</option>
                                                <option value="Critical">Critical</option>
                                                <option value="Information">Information</option>
                                            </select>
                                        </div>
                                        <div className="action-box">
                                            <button
                                                className="btn btn-light"
                                                onClick={() => this.resetValue()}
                                            >
                                                Reset
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => this.setState({
                                                    pageNo: 1,
                                                    deviceSeverity: this.state.filterDeviceSeverity,
                                                    newAlerts: this.state.filterNewAlerts,
                                                    filterEnabled: true,
                                                }, () => this.getDeviceEventFilter())}
                                                disabled={
                                                    this.state.filterNewAlerts === false &&
                                                    (this.state.filterDeviceSeverity === "" ||
                                                    this.state.filterDeviceSeverity === "Select")
                                                }
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div >
                    <div className="">
                        <DataTable
                            columns={this.state.warningColumns}
                            data={this.state.deviceEventsData}
                            highlightOnHover
                            fixedHeader
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.count}
                            paginationDefaultPage={this.state.pageNo}
                            onChangePage={(e) => this.paginationChange(e)}
                            onChangeRowsPerPage={(e) => this.pageSizeChange(e)}
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        />
                    </div>
                </div>
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
    });
};

export default withRouter(connect(mapStateToProps)(SystemAlerts));

