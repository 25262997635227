import React, { Component } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
class Authorization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            copied: false,
            password: ''
        }
    }
    static getDerivedStateFromProps = (props, state) => {
        if (props.generalData) {
            state.generalData = props.generalData;
            state.alertingData = props.AlertingData;
            state.satusData = props.satusData;
            state.AdminData = props.AdminData;
            state.DBData = props.DBData;
        }
        return state;
    }
    showPassword = () => {
        const element = document.getElementById("password");
        if (element.type === "password") {
            element.type = "text";
        } else {
            element.type = "password";
        }
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    }
    copyPassword = () => {
        var copyText = document.getElementById("password");
        this.setState({
            copied: true,
        });
        navigator.clipboard.writeText(copyText.value);
    };
    setPassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    goToConfigure = () => {
        if (this.state.password !== '') {
            this.props.stepChange(3, this.state.password);
        }
    }
    render() {
        return (
            <div className='network-configuration'>
                <div className='step3' id="authorization">
                    <h1 className="page-title">Configure Packetfence</h1>
                    <span>Ask you to create an administrative user that will be able to access the web-based adminsitration interface once the services are functional</span>
                    <div className='d-grid gap-2 col-6 mx-auto pt-3'>
                        <div className='form-group'>
                            <label>Username</label>
                            <input
                                type="text"
                                className='form-control'
                                readOnly
                                placeholder=''
                                value="admin" />
                        </div>
                        <div className='form-group'>
                            <label>Password</label>
                            <input
                                id="password"
                                type="password"
                                className='form-control'
                                placeholder=''
                                autoComplete="false"
                                value={this.state.password}
                                onChange={this.setPassword} />
                            <div className='sh-icon'>
                                <img src={
                                    this.state.showPassword
                                        ? "/images/show.png"
                                        : "/images/hide.png"
                                } alt="" onClick={this.showPassword} />

                                {this.state.copied === false ? (
                                    <div className="copy-icon">
                                        <CopyToClipboard
                                            text={this.state.deviceData?.password ? this.state.deviceData.password : ""}
                                            onCopy={() => this.copyPassword()}>
                                            <img
                                                className="copy"
                                                src="/images/svg-icons/copy.svg"
                                                alt=""
                                            />
                                        </CopyToClipboard>
                                        <div className="tooltip">
                                            Copy Password
                                        </div>
                                    </div>
                                ) : (
                                    <div className="copy-icon">
                                        <img
                                            className="copy"
                                            src="/images/svg-icons/tick.svg"
                                            alt=""
                                        />
                                        <div className="tooltip hideme">
                                            Copied
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="s-footer">
                    {/* <button className="btn btn-light"
                        onClick={() => this.props.goToStep(1)}
                    >Previous</button> */}
                    <button
                        className="btn btn-primary"
                        disabled={this.state.password === ''}
                        onClick={() => {
                            this.props.nextStep();
                            this.goToConfigure();
                            // this.props.stepChange(1, this.state.deviceBData)
                        }}>Next</button>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
}
export default connect(mapStateToProps)(Authorization);
