// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.datatable-scroll-demo .p-datatable-frozen-tbody {
    font-weight: bold;
    align-items: center;
}

.datatable-scroll-demo .p-datatable-scrollable .p-frozen-column {
    font-weight: bold;
}
                


                    `, "",{"version":3,"sources":["webpack://./src/components/deviceList/DataTableDemo.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\n.datatable-scroll-demo .p-datatable-frozen-tbody {\n    font-weight: bold;\n    align-items: center;\n}\n\n.datatable-scroll-demo .p-datatable-scrollable .p-frozen-column {\n    font-weight: bold;\n}\n                \n\n\n                    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
